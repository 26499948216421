import { StyleSheet, Font } from '@react-pdf/renderer';
import { RobotoBold, RobotoRegular, RobotoLightItalic } from '@assets';

Font.register({
    family: 'Roboto',
    fonts: [
        {
            src: RobotoBold,
            fontWeight: 'bold'
        },
        {
            src: RobotoRegular,
            fontWeight: 'normal'
        },
        {
            src: RobotoLightItalic,
            fontStyle: 'italic'
        }
    ]
});

export default StyleSheet.create({
    h1_white: {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 20,
        textTransform: 'uppercase',
        color: '#fff'
    },
    total_green: {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 14,
        color: '#7FAA7C'
    },
    total_black: {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 14,
        color: '#383938'
    },
    h2: {
        color: '#383938',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 14,
        textTransform: 'uppercase',
        marginBottom: 9,
        marginTop: 50
    },
    h3_green: {
        color: '#7FAA7C',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 10
    },
    h3_black: {
        color: '#383938',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 10
    },
    h3_white: {
        color: '#fff',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 10
    },
    footer_font: {
        color: '#fff',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 10,
        textAlign: 'center',
        marginRight: '10px'
    },
    h5_black: {
        color: '#383938',
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        fontSize: 10
    },
    table_th: {
        color: '#383938',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 8,
        padding: 5,
        borderLeft: 2,
        borderTop: 2,
        flex: 1,
        textAlign: 'left'
    },
    table_td: {
        color: '#383938',
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        fontSize: 8,
        textAlign: 'right',
        width: '100%',
        padding: '0 5px 0 5px',
        borderLeft: 2,
        borderTop: 2,
        flex: 1,
        textAlign: 'left'
    },
    separator: { width: '100%', height: '7px', backgroundColor: '#77A349', paddingLeft: '20px', paddingRight: '20px', marginVertical: '10px' },
    row: {
        flexDirection: 'row'
    },
    container: {
        flexDirection: 'row',
        alignItems: 'stretch',
        backgroundColor: '#0E3F66',
        height: 56
    },
    content: {
        paddingVertical: 54,
        paddingHorizontal: 32
    },
    descriptionContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    section: {},
    description: {
        color: '#1F2229',
        fontWeight: 'semibold',
        fontSize: 8
    },
    item: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginBottom: 30
    },
    tableContainer: {
        marginTop: 40
    },
    table: {
        width: 'auto'
    },
    tableRow: {
        flexDirection: 'row'
    },
    tableCol: {
        width: '25%',
        borderTopWidth: 0,
        padding: 5
    },
    tableSummaryCol: {
        paddingTop: 9,
        paddingBottom: 13
    },
    tableBorder: {
        borderBottomWidth: 1,
        borderColor: '#E7E8EC'
    },
    tableHeader: {
        color: '#0E3F66',
        fontWeight: 'bold',
        fontSize: 8
    },
    tableCell: {
        color: '#1F2229',
        fontWeight: 'light',
        fontSize: 8
    },
    tableTotalCell: {
        color: '#0E3F66',
        fontSize: 12,
        fontWeight: 'bold'
    },
    textRight: {
        textAlign: 'right'
    },
    noteContainer: {
        marginTop: 40
    },
    noteTitle: {
        fontSize: 10,
        fontWeight: 'bold',
        marginBottom: 16,
        color: '#1F2229'
    },
    noteDescription: {
        fontSize: 8,
        color: '#545E60'
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        top: 10,
        right: 10,
        textAlign: 'center',
        color: 'grey'
    },
    productColumn: {
        width: '25%'
    },
    quantityColumn: {
        width: '10%'
    },
    taxColumn: {
        width: '10%'
    },
    priceColumn: {
        width: '15%'
    },
    productDescription: {
        fontFamily: 'Roboto',
        fontSize: 10,
        color: 'gray',
        margin: '0 0 5px 0',
        fontStyle: 'italic'
    }
});
