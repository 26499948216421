import styled, { css } from 'styled-components'

type ButtonProps = {
    secondary?: boolean
    disabled?: boolean
    bordered?: boolean
    outlined?: boolean
    linked?: boolean
}

const Button = styled.button<ButtonProps>`
    height: 40px;
    border-radius: 6px;
    padding: 10px 25px 10px 25px;
    background: ${({ secondary, disabled, bordered, outlined }) => (secondary ? '#FF8939' : disabled ? 'lightgray' : bordered ? 'white' : outlined ? 'transparent' : '#0E3F66')};
    color: ${({ bordered }) => (bordered ? '#0E3F66' : 'white')};
    border: ${({ bordered, outlined }) => (bordered ? '1px solid #0E3F66' : outlined ? '1px solid white' : 'none')};
    cursor: ${props => (props.disabled ? '' : 'pointer')};
    font-weight: 500;
    font-size: 14px;
    display: flex;
    transition: all ease-in-out 0.2s;
    align-items: center;

    ${({ linked }) =>
        (linked &&
            css`
                text-decoration: underline;
                background: transparent;
                color: #979797;
                border: none;
                padding: 0;
            `) ||
        ''}

    ${({ disabled }) =>
        (disabled &&
            css`
                background: #f7f7f7;
                border: 1px solid #f4f5f7;
                color: #81878e;
                :hover {
                    border: 1px solid #f4f5f7;
                }
            `) ||
        ''}

    &:active {
        transform: scale(0.96);
    }
`

export default Button
