import service from './service'

export const fetchDocuments = async (params = {}, errorCallback = () => {}) => {
    try {
        console.log('params', params)
        const res = await service.get('/documents', { params })
        return res.data
    } catch (err) {
        errorCallback(err)
    }
    return []
}

export const fetchDocument = async (id, errorCallback = () => {}) => {
    const res = await service.get(`/documents/${id}`)
    return res.data
}

export const updateDocument = async (document, errorCallback = () => {}) => {
    const res = await service.put(`/documents/file/${document._id}`, document)
    return res.data
}

export const updateDocumentFile = async (document, errorCallback = () => {}) => {
    const res = await service.put(`/documents/document/${document._id}`, document)
    return res.data
}

export const deleteDocument = async id => {
    await service.delete(`/documents/${id}`)
}

export const fetchDocumentFile = async (id, errorCallback = () => {}) => {
    const res = await service.get(`/documents/file/${id}`, { responseType: 'arraybuffer' })
    return res.data
}

export const getNewPaginatedList = async query => {
    const params = new URLSearchParams()
    params.append('data', JSON.stringify(query))
    return (await service.get(`/documents/pagination`, { params })).data
}
