import { useMemo } from 'react'

import { Dropdown } from '@components/bs'
import DropdownFooterButton from '@components/bs/Dropdown/parts/DropdownFooterButton'

const SelectEntityDropdown = props => {
    const {
        id,
        name,
        label = null,
        placeholder = 'Select item',
        loading = false,
        selected = [],
        multi = false,
        disabled = false,
        items = [],
        formErrors = [],
        onChange = () => { },
        footerButton = {
            icon: null,
            text: '',
            onClick: () => { }
        }
    } = props

    const selectedItems = useMemo(() => {
        if (!selected?.length) {
            return []
        }

        return selected.map(s => ({
            _id: s._id,
            label: s.name,
            value: s
        }))
    }, [selected, loading])

    const onSelectItem = itemId => {
        const item = items.find(i => i._id === itemId)?.value
        if (!item) {
            return
        }

        const existIndex = selected.findIndex(s => s._id === item._id)
        if (existIndex === -1) {
            return onChange([...selected, item])
        }

        let updatedSelected = [...selected]
        updatedSelected.splice(existIndex, 1)
        return onChange(updatedSelected)
    }

    return (
        <Dropdown
            id={id}
            name={name}
            label={label}
            loading={loading}
            disabled={disabled}
            placeholder={placeholder}
            selected={selectedItems}
            formErrors={formErrors}
            multi={multi}
            items={items}
            footer={footerButton.icon ? <DropdownFooterButton icon={footerButton.icon} text={footerButton.text} onClick={footerButton.onClick} /> : null}
            onChange={onSelectItem}
        />
    )
}

export default SelectEntityDropdown
