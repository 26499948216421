import React from 'react';

import { Feather } from '@components';

import styles from './styles.module.scss';

const ImportedLabel = (props) => {
    const {
        imported
    } = props;

    if (!imported) {
        return null;
    }

    return (
        <div className={styles.imported_label}>
            <Feather size={20} name='Link2' color='#0e3f66' />{' '}
        </div>
    );
}

export default ImportedLabel;
