import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';

import ReactDOM from 'react-dom/client';
import 'react-dates/initialize';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import appVersion from 'appVersion.json';

import App from './App';

import './polyfill';

import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

Sentry.init({
    dsn: process.env.SENTRY_DNS,
    ignoreErrors: [
        // Ignore ChunkLoadError (new version)
        'ChunkLoadError',
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.TYPE,
    release: appVersion.version,
    enabled: process.env.NODE_ENV === 'production',

    // Replays
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        new BrowserTracing(),
        new Sentry.Replay({
            maskAllText: true,
            blockAllMedia: true,
        }),
    ]
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// root.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorker.unregister();
