import * as types from './types';
import { _cloneDeep } from "../../../../utils/jsonUtil";

const initialState = {
    quotes: [],
    details: { count: 0, filter: '', limit: 0, page: 0, pages: 0 },
    sending: false,
    editting : false,
    editFields : {
        row : -1,
        col :[]
    },
    adding : false,
    deleting : false,
    deletingFields : []
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.INSERT_QUOTE:
            return {
                ...state,
                quotes : [...state.quotes, action.payload]
            }
        case types.DELETE_QUOTE:
            return {
                ...state,
                deleting: true,
                deletingFields : action.payload
            };
        case types.DELETE_QUOTE_FAIL:
            return {
                ...state,
                deleting: false
            };
        case types.DELETE_QUOTE_SUCCESS:
            const temp =[...state.quotes];
            action.payload.map(v =>{
                const i = temp.findIndex(t => t._id === v)
                temp.splice(i, 1)
            })
            return {
                ...state,
                quotes : temp,
                deleting: false
            };
        case types.EDIT_QUOTE :
            return {
                ...state,
                editting : true,
                editFields : {
                    row : action.payload.id,
                    col : Object.keys(action.payload.params)
                }
            }
        case types.EDIT_QUOTE_FAIL :
            return {
                ...state,
                editting : false
            }
        case types.EDIT_QUOTE_SUCCESS:
            const list = _cloneDeep(state.contacts)
            const index = list.findIndex(e => e._id === action.payload._id)
            list[index] = action.payload;
            return {
                ...state,
                quotes : list,
                editting : false
            }
        case types.ADD_QUOTE :
            return {
                ...state,
                adding : true
            }
        case types.ADD_QUOTE_FAIL :
            return {
                ...state,
                adding : false
            }
        case types.ADD_QUOTE_SUCCESS:
            return {
                ...state,
                quotes : [...state.contacts, action.payload],
                adding : false
            }
        case types.FETCH_LIST_QUOTES:
            return {
                ...state,
                sending: true
            };
        case types.FETCH_LIST_QUOTES_SUCCESS:
            return {
                ...state,
                quotes: action.payload.items,
                details: action.payload.details,
                sending: false
            };
        case types.FETCH_LIST_QUOTES_FAIL:
            return {
                ...state,
                sending: false
            };
        default:
            return state;
    }
};

export default reducer;