import { useQuery } from '@tanstack/react-query'
import { TStage, fetchStages } from 'service/stages'
import { DRAFT } from '@constants/status'

const useStages = (entity: 'quote' | 'project') => {
    const { data } = useQuery<any, any, TStage[]>([`${entity}-stages`], () => fetchStages(entity))
    const stages = new Map((data || []).map(s => [s.key, s]))
    function getName(currentProgress: string) {
        if (stages.size === 0) return
        if (!stages.has(currentProgress)) return
        const stage = stages.get(currentProgress)
        return stage?.name
    }

    function getColor(currentProgress: string) {
        if (stages.size === 0) return
        if (!stages.has(currentProgress)) return
        const stage = stages.get(currentProgress)
        return stage?.color
    }
    function can(currentProgress: string, action: string) {
        if (stages.size === 0) return
        if (!stages.has(currentProgress)) return
        const stage = stages.get(currentProgress)
        return (stage?.permissions || []).includes(action)
    }

    const isDraftBasedStage = (currentProgress: string) => {
        if (stages.size === 0) return
        const isDraft = stages.get(currentProgress)?.basedOn === DRAFT || currentProgress === DRAFT
        return isDraft
    }

    return { getName, getColor, stages: data, isDraftBasedStage, can }
}

export default useStages
