import React from 'react';

import { Feather } from '@components';

import '@scss/panels/_chevron-union-icon.scss';

const ChevronUnionIcon = () => (
    <div className='chevron-union-icon'>
        <Feather name='ChevronLeft' />
        <Feather name='ChevronRight' />
    </div>
);

export default ChevronUnionIcon;
