import service, { setAuthToken } from '@service/service';

import AuthService from 'service/auth.service';

import { loadSettings } from '../settings/actions';

import { AUTH_ERROR, LOGIN, LOGIN_FAILED, LOGIN_PERMISSION, LOGIN_SUCCESS, LOGOUT, SET_USER_ROLES, UPDATE_USER_PERMISSION, USER_LOADED } from './types';

const loginUser = (email, password) => async dispatch => {
    const data = {
        firstName: '',
        lastName: '',
        email: email,
        password: password
    };
    dispatch({ type: LOGIN });
    try {
        const auth = await AuthService.authenticate(data);
        dispatch({ type: USER_LOADED, payload: auth.user });
        dispatch({ type: LOGIN_SUCCESS, payload: { token: auth.token } });
        dispatch(loadSettings(auth.settings));
    } catch (error) {
        dispatch({
            type: LOGIN_FAILED,
            payload: {
                permission: !(error.response?.status === 403 && error.response?.data?.error === 'Access forbidden')
            }
        });
    }
};

const loginSuccess = token => async dispatch => {
    dispatch({
        type: LOGIN_SUCCESS,
        payload: {
            token
        }
    });
    dispatch(loadUser());
};

const loginPermission = permission => async dispatch => {
    dispatch({
        type: LOGIN_PERMISSION,
        payload: {
            permission
        }
    });
};

const loadUser = () => async dispatch => {
    setAuthToken(localStorage.getItem('token'));
    try {
        const user = await AuthService.loadUser();
        dispatch({
            type: USER_LOADED,
            payload: user
        });
        dispatch(loadSettings());
    } catch (e) {
        dispatch({
            type: AUTH_ERROR
        });
    }
};

const logout = () => async dispatch => {
    try {
        await service.post('/users/logout');
    } catch (e) {
        console.error(`=logout=`, logout);
    } finally {
        dispatch({
            type: LOGOUT
        });
    }
};

const updatedUserPermissions = (_id, permission) => async dispatch => {
    dispatch({
        type: UPDATE_USER_PERMISSION,
        payload: {
            _id,
            permission
        }
    });
};

const updateUserRoles = (_id, roles) => async dispatch => {
    dispatch({
        type: SET_USER_ROLES,
        payload: {
            _id,
            roles
        }
    });
};

export { loginUser, loadUser, logout, loginPermission, loginSuccess, updatedUserPermissions, updateUserRoles };
