import { useEntity } from '@hooks/useEntity';

import service from './service';

export const fetchProductLine = async (params = {}, errorCallback = () => {}) => {
    try {
        const res = await service.get('/plant-types', { params });
        return res.data;
    } catch (err) {
        errorCallback(err);
    }
    return [];
};

export const createProductLine = async data => {
    const res = await service.post('/plant-types/', data);
    return res.data;
};

export const getOneProductLine = async id => {
    const res = await service.get(`/plant-types/${id}`);
    return res.data;
};

export const updateProductLine = async data => {
    const res = await service.put(`/plant-types/${data._id}`, data);
    return res.data;
};

export const updateProductLines = async data => {
    const res = await service.put(`/plant-types`, data);
    return res.data;
};

export const deleteProductLine = async id => {
    const res = await service.delete(`/plant-types/${id}`);
    return res.data;
};

export const useProductLinesApi = () => useEntity('plant-types');
