import * as types from './types';

const initialState = {
    templates: [],
    sending: false,
    previewText: ''
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_TEMPLATES:
            return {
                ...state,
                sending: true
            };
        case types.FETCH_TEMPLATES_SUCCESS:
            return {
                ...state,
                templates: action.payload,
                sending: false
            };
        case types.CREATE_TEMPLATES_SUCCESS:
            return {
                ...state,
                templates: [...action.payload]
            };
        case types.DELETE_TEMPLATES_SUCCESS:
            const temaplatesList = state.templates.filter(el => el._id !== action.payload._id);
            return {
                ...state,
                templates: temaplatesList
            };
        case types.UPDATE_TEMPLATES_SUCCESS:
            return {
                ...state,
                templates: [...action.payload]
            };
        case types.SET_PREVIEW_TEXT:
            return {
                ...state,
                previewText: action.payload
            };
        default:
            return state;
    }
};

export default reducer;
