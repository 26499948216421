export const DEFAULT = 0;
export const GREEN_DOT = 1;
export const RED_DOT = 2;
export const BLUE_DOT = 3;
export const COMPETITOR_PLANT = 4;
export const PLANT = 5;
export const SITE = 6;
export const ORANGE_DOT = 7;
export const GRAY_DOT = 8;
export const NAVY_DOT = 9;
export const DEFAULT_PLANT = 11;
