import service from './service';
import download from 'js-file-download';

export const CONTRACTS = '/contracts';
export const PRODUCTS = '/products';
export const PLANTS = '/plants';
export const CUSTOMERS = '/customers';

/*
    Common upload function for all entities: Contracts, Plants,...

    - entity: Any of the values listed above:
        - CONTRACTS, PRODUCTS, ....

    - entityId: _id of the document for which attachment is uploaded

    - filename: The original name of the file

    - description: if provided by used

    -image: binary data of the file to be uploaded

    - onProgressChange: callback to show progress while uploading

*/
export const uploadFile = async (entity, entityId, filename, size, description, image, onProgressChange = () => { }) => {
    const config = {
        onUploadProgress: progressEvent =>
            onProgressChange(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    };

    const data = {
        filename,
        size,
        description,
        image
    };
    const res = await service.post(`${entity}/attachment/${entityId}`, data, config);
    return res.data;
};

export const downloadFile = async (entity, entityId, attachmentId, filename) => {
    const config = {
        responseType: 'blob',
        params: {
            attachmentId
        },
    };
    const res = await service.get(`${entity}/attachment/${entityId}`, config);
    download(res.data, filename);
    return true;
};

export const deleteAttachment = async (entity, entityId, attachmentId, filename) => {
    const res = await service.delete(`${entity}/attachment/${entityId}`, { params: { attachmentId, filename } });
    return res.data;
};
