import { useState, FC } from 'react'

import Arrow from '@assets/icons/Arrow.svg'

import { Button, Image } from '@components'
import { AccordionSelect } from '@components/SelectDropdown/AccordionSelect'

import { useListenForOutsideClicks } from '@hooks/useListenForOutsideClicks'

import '@scss/pages/_dashboard-filters.scss'

interface FilterProps {
    values?: { [key: string]: { value: string; label: string }[] }
    options?: { [key: string]: { value: string; label: string }[] }
    onChangeFilter?: (value: { [key: string]: { value: string; label: string }[] }) => void
}

const Filter: FC<FilterProps> = ({ values = {}, options = {}, onChangeFilter = () => {} }) => {
    const [viewMode, setViewMode] = useState(false)
    const [ref, setRef] = useState<HTMLDivElement | null>(null)

    useListenForOutsideClicks([ref], () => {
        if (viewMode) {
            setViewMode(false)
        }
    })

    const onChangeAccordionSelect = (value: { [key: string]: { value: string; label: string }[] }) => {
        onChangeFilter(value)
    }

    return (
        <div ref={setRef} className='Filters'>
            <Button customClass='btnold--action-view flex justify-content-between img-icon' onClick={() => setViewMode(!viewMode)}>
                <span>OTHER FILTERS</span>
                <Image src={Arrow} className={`arrow_icon ${viewMode ? 'arrow_icon--rotate' : ''}`} />
            </Button>
            {viewMode && <AccordionSelect className='filters-accordion-select' options={options} values={values} onChange={onChangeAccordionSelect} />}
        </div>
    )
}

export default Filter
