import React from 'react';

import { Image } from '@components';

const Icon = props => {
    const { src = '', onClick = () => {}, alignment = '' } = props;

    if (!src) {
        return null;
    }
   
    return <Image className={`input-icon icon-${alignment}`} onClick={onClick} src={src} />;
};

export default Icon;
