import React, { useEffect } from 'react';
import plantIcon from '@assets/icons/plant.svg';
import Image from '@components/Image';
import { Checkbox } from '@common'
import '@scss/panels/_tier-level-graph-tooltip.scss';
import { useOnClickOutside } from 'utils/onClickOutside';
import { TierLevelGraphTooltipModal } from './styled';

const MAX_PLANT_PANELS = 2;

const TierLevelGraphTooltip = ({
    show = false,
    left,
    onChildRef = React.createRef(),
    top,
    data,
    handleClose = () => { }
}) => {

    const plantPanelRef = React.useRef();
    const [plantPanelHeight, setPlantPanelHeight] = React.useState(null);

    const [originFix, setOriginFix] = React.useState();
    const originFixRef = React.useRef();

    useEffect(() => {
        setOriginFix(originFixRef.current.getBoundingClientRect());
        if (plantPanelRef.current) {
            setPlantPanelHeight(plantPanelRef.current.getBoundingClientRect().height);
        }
    }, []);

    useOnClickOutside(onChildRef, () => handleClose());

    const renderValueItem = (title, value, symbol) => {
        return (
            <div className='d-flex flex-column'>
                <h5 className='text-blue-100 font-medium'>{title}</h5>
                <h4 className='text-gray-300 my-2 py-1 font-medium'>
                    {value}
                    <span style={{ fontSize: '1.5rem', opacity: '50%' }}>&nbsp;{symbol}</span>
                </h4>
            </div>
        );
    };

    return (
        <div ref={onChildRef}>
            <TierLevelGraphTooltipModal
                show={show}
                top={top - originFix?.y}
                left={left - originFix?.x}
                className='TierLevelGraphTooltip text-blue-600 rounded'>
                <div className='d-flex align-items-center justify-content-between bg-gray-50 p-3 rounded'>
                    <h3 className='font-medium mr-5'>{data?.customer?.name}</h3>
                    <Checkbox checked={data?.customer?.isMultiplant}>MULTIPLANT</Checkbox>
                </div>
                <div
                    style={{
                        height: plantPanelHeight && data.length > 1 ? plantPanelHeight * MAX_PLANT_PANELS + 2 : null,
                        overflow: 'auto'
                    }}>
                    {data.map((pi, index) => (
                        <div className='p-3 pb-0' key={index} ref={plantPanelRef}>
                            <h4 className='my-2 py-1'>
                                <Image src={plantIcon} />
                                <span className='ml-2 text-uppercase'>{pi.plant.name}</span>
                            </h4>
                            <div className='d-flex justify-content-between'>
                                {renderValueItem('REVENUE FIN Y', Math.round(pi.customerInfo.revenuePlant), '$')}
                                {renderValueItem('VOLUME FIN Y', Math.round(pi.customerInfo.volumePlant), 't')}
                                {renderValueItem('INVOICE NUMBER', pi.customerInfo.invoicesCount, '')}
                            </div>
                        </div>
                    ))}
                </div>
            </TierLevelGraphTooltipModal>
            <div ref={originFixRef} style={{ position: 'absolute', top: 0, left: 0 }} />
        </div>
    );
};

export default TierLevelGraphTooltip;
