import React from 'react'
import { CSVLink } from 'react-csv'

const CSVLinkWrapper = (props) => {
    const { children, disabled = false } = props
    if(disabled) return children
    return (
        <CSVLink {...props}>
                { children }
        </CSVLink>
    )
}

export default CSVLinkWrapper