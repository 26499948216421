export const FETCH_LIST_CUSTOMERS = 'FETCH_LIST_CUSTOMERS';
export const FETCH_LIST_CUSTOMERS_SUCCESS = 'FETCH_LIST_CUSTOMERS_SUCCESS';
export const FETCH_LIST_CUSTOMERS_FAIL = 'FETCH_LIST_CUSTOMERS_FAIL';

export const LINK_CONTACT = 'LINK_CONTACT';
export const LINK_CONTACT_SUCCESS = 'LINK_CONTACT_SUCCESS';
export const LINK_CONTACT_FAIL = 'LINK_CONTACT_FAIL';

export const DELETE_CUSTOMERS = 'DELETE_CUSTOMERS';
export const DELETE_CUSTOMERS_SUCCESS = 'DELETE_CUSTOMERS_SUCCESS';
export const DELETE_CUSTOMERS_FAIL = 'DELETE_CUSTOMERS_FAIL';

export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const ADD_CUSTOMER_SUCCESS= 'ADD_CUSTOMER_SUCCESS';
export const ADD_CUSTOMER_FAIL = 'ADD_CUSTOMER_FAIL';

export const CLEAR_ERROR = 'CLEAR_ERROR';
