import service from '@service/service';

import { DEFAULT_FETCH_PARAMS } from 'consts/tableFilters';

import * as types from './types';

const fetchListPlants = (params = {}) => async dispatch => {
    dispatch({ type: types.FETCH_LIST_PLANTS });
    try {
        const plants = await service.get('/plants', { params: { ...DEFAULT_FETCH_PARAMS, ...params } });
        dispatch({ type: types.FETCH_LIST_PLANTS_SUCCESS, payload: plants.data });
    } catch (error) {
        dispatch({ type: types.FETCH_LIST_PLANTS_FAIL, payload: error?.response?.data });
    }
};

const fetchDefaultPlant = (params = {}) => async dispatch => {
    dispatch({ type: types.FETCH_DEFAULT_PLANT });
    try {
        const plants = await service.get('/plants/default-plant', { params });
        dispatch({ type: types.FETCH_DEFAULT_PLANT_SUCCESS, payload: plants.data });
    } catch (error) {
        dispatch({ type: types.FETCH_DEFAULT_PLANT_FAIL, payload: error?.response?.data });
    }
};

const clearError = () => dispatch => {
    dispatch({ type: types.CLEAR_ERROR })
}

export { fetchListPlants, fetchDefaultPlant, clearError };
