import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDefaultPlant } from '@hooks/useDefaultPlant';
import { useDefaultMap } from '@hooks/useDefaultMap';
import { useDateFormat } from '@hooks/useDateFormat';

import { useHideGlobalModal } from '@components/GlobalModal/hooks';
import { useNotify } from '@components';

import { useShowDeleteConfirmModal } from '@modals/DeleteConfirmModal/hooks';
import { useShowGeoZoneModal } from '@modals/GeoZoneModal/hooks';

import { clearError, fetchListGeoZones } from '@reducers/data/geoZones/actions';

import { createFilterStrings } from 'utils/filters';

import { createGeoZone, updateGeoZone, deleteGeoZone } from 'service/geoZoneApi';

import { getEmptyGeoZone } from './utils';
import { INITIAL_FETCH_SETTINGS } from './const';

/**
 * I didn't have time to implement as I wanted before, but it should work independent by entity.
 * We need to make it's as a common hook and pass the name of entity or Class API 
 */

export const useGeoZones = (props = {}) => {
    const { initialFetchSettings = INITIAL_FETCH_SETTINGS } = props;

    const { geoZones, details, sending, error } = useSelector(state => state.data.geoZones);

    const [limit, setLimit] = useState(initialFetchSettings.limit);
    const [page, setPage] = useState(initialFetchSettings.page);
    const [sort, setSort] = useState(initialFetchSettings.sort);
    const [filterColumns, setFilterColumns] = useState(initialFetchSettings.filterColumns);
    const [lastSavedGeoZone, setLastSavedGeoZone] = useState(null);
    const [loading, setLoading] = useState(initialFetchSettings.loading);
    const [searchQuery, setSearchQuery] = useState(initialFetchSettings.searchQuery);
    const [filterValues, setFilterValues] = useState(initialFetchSettings.filterValues);
    const [statusFilters, setStatusFilter] = useState(initialFetchSettings.statusFilters);

    const defaultMapCenter = useDefaultMap();
    const { dateFormat } = useDateFormat();
    const { getDefaultPlant } = useDefaultPlant();

    const dispatch = useDispatch();
    const { notifyError, notifySuccess } = useNotify();

    const showDeleteConfirmModal = useShowDeleteConfirmModal({ variant: 'secondary' });
    const showGeoZoneModal = useShowGeoZoneModal();
    const hideGlobalModal = useHideGlobalModal();

    const onSaveGeoZoneModal = async (e, context) => {
        await context?.value?.submit();
    };

    const onSaveGeoZone = async (geoZone, e) => {
        console.log('onsavegeozone', geoZone);
        try {
            setLoading(true);
            const saveGeoZone = geoZone?._id ? updateGeoZone : createGeoZone;
            const savedGeoZone = await saveGeoZone(geoZone);
            setLastSavedGeoZone(savedGeoZone);
            fetchGeoZones();
            notifySuccess('Geo Zone created successfully.');
        } catch (error) {
            notifyError(error.response?.data?.message, error.response?.data?.title);
        } finally {
            setLoading(false);
        }

        hideGlobalModal();
    };

    const onDeleteGeoZone = async geoZone => {
        try {
            setLoading(true);
            await deleteGeoZone(geoZone?._id);
            fetchGeoZones();
            notifySuccess('Geo Zone has been deleted.', 'Deleted');
        } catch (error) {
            notifyError(error.response?.data?.message, error.response?.data?.title);
        } finally {
            setLoading(false);
        }

        hideGlobalModal();
    };

    const openGeoZoneModal = (title, geoZone) => {
        console.log('title, geozone', title, geoZone);
        const modalProps = {
            title,
            onSaveHandler: onSaveGeoZoneModal
        };
        const geoZoneModalProps = {
            geoZone,
            mapCenter: geoZone?.center ? { lat: geoZone.center?.lat, lng: geoZone.center?.lng } : defaultMapCenter,
            onSaveGeoZone
        };
        console.log('-', geoZoneModalProps);
        showGeoZoneModal(modalProps, geoZoneModalProps);
    };

    const addGeoZoneModal = () => {
        const defaultPlant = getDefaultPlant();
        const geoZone = getEmptyGeoZone(defaultPlant);
        openGeoZoneModal('Add a new Geo Zone', geoZone);
    };

    const editGeoZoneModal = geoZone => {
        openGeoZoneModal('Edit Geo Zone', geoZone);
    };

    const deleteGeoZoneModal = geoZone => {
        const modalProps = {
            title: 'Delete Geo Zone',
            body: <span>{`Are you sure you want to delete ”${geoZone.name}”`}</span>,
            onDeleteHandler: () => onDeleteGeoZone(geoZone)
        };

        showDeleteConfirmModal(modalProps);
    };

    const handleSearch = query => {
        setSearchQuery(query);
    };

    const handleChangePage = data => {
        setPage(data);
    };

    const handleSort = ({ sort = '', order = 'asc' }) => {
        setSort({ sort, order });
    };

    const handleChangeLimit = e => {
        setLimit(e);
    };

    const onHeadlineStatusFilterHandler = statuses => {
        // @TODO: Implement filtering by active/inactive
        setStatusFilter(statuses);
    };

    const combineFilters = () => ({
        filterColumns: `${filterColumns}status,query`,
        filterValues: `${filterValues}${statusFilters.join(';')},${searchQuery}`,
        sort: sort.sort,
        order: sort.order,
        limit,
        page
    });

    const handleFilter = filter => {
        const { columns, values } = createFilterStrings(filter);
        setFilterColumns(columns);
        setFilterValues(values);
    };

    const fetchGeoZones = () => {
        dispatch(fetchListGeoZones({ ...combineFilters() }));
    };

    useEffect(() => {
        fetchGeoZones();
    }, [limit, page, sort, filterColumns, filterValues, searchQuery, statusFilters]);

    useEffect(() => {
        if (!error) {
            return;
        }

        notifyError(error.message, error.title);
        dispatch(clearError());
    }, [error]);

    return {
        lastSavedGeoZone,
        geoZones,
        limit,
        sort,
        details,
        loading: loading || sending,

        // Modals handlers
        addGeoZoneModal,
        editGeoZoneModal,
        deleteGeoZoneModal,

        // Table/list handlers
        handleFilter,
        handleChangePage,
        handleSort,
        handleChangeLimit,
        handleSearch
    };
};
