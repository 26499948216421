export default [
    {
        id: 1,
        title: 'Address Type',
        dataIndex: 'customType',
        hidden: false,
        width: 150,
        align: 'left'
    },
    {
        id: 2,
        title: 'Address',
        dataIndex: 'line',
        hidden: false,
        width: 150,
        align: 'left'
    },
    {
        id: 3,
        title: 'Zip',
        dataIndex: 'zip',
        hidden: false,
        width: 100,
        align: 'left'
    },
    {
        id: 4,
        title: 'Region',
        dataIndex: 'region',
        hidden: false,
        width: 150,
        align: 'left'
    },
    {
        id: 5,
        title: 'City',
        dataIndex: 'city',
        hidden: false,
        width: 150,
        align: 'left'
    },
    {
        id: 6,
        title: 'Country',
        dataIndex: 'country',
        hidden: false,
        width: 150,
        align: 'left'
    },
    {
        id: 7,
        title: 'State',
        dataIndex: 'state',
        hidden: false,
        width: 150,
        align: 'left'
    }
];
