import * as Sentry from "@sentry/browser";

import {
    AUTH_ERROR,
    LOGIN,
    LOGIN_FAILED,
    LOGIN_PERMISSION,
    LOGIN_SUCCESS,
    LOGOUT,
    REGISTER_FAILED,
    REGISTER_SUCCESS,
    SET_TENANT,
    SET_USER_ROLES,
    UPDATE_USER_PERMISSION,
    USER_LOADED
} from './types';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: localStorage.getItem('token') || null,
    permission: null,
    loading: false,
    user: null
};

export default function authReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case USER_LOADED:
            localStorage.setItem('roles', JSON.stringify(payload.userRoles || []));
						Sentry.setUser({ email: payload?.email, segment: payload?.tenant?.name });
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload
            };
        case SET_TENANT:
            return {
                ...state,
                tenant: payload.tenant
            };
        case LOGIN:
            return {
                ...state,
                loading: true
            };
        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            localStorage.setItem('token', payload.token);
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                permission: true,
                loading: false
            };

        case REGISTER_FAILED:
        case AUTH_ERROR:
        case LOGOUT:
        // @TODO: Finish it
            localStorage.removeItem('token');
            localStorage.removeItem('unit');
            localStorage.removeItem('roles');
            localStorage.removeItem('mapCoords');
            return {
                ...state,
                ...payload,
                user: null,
                token: null,
                isAuthenticated: null,
                loading: false
            };
        case LOGIN_FAILED:
            localStorage.removeItem('token');
            return {
                ...state,
                ...payload,
                token: null,
                isAuthenticated: false,
                loading: false
            };

        case LOGIN_PERMISSION:
            return {
                ...state,
                permission: payload.permission
            };
        case UPDATE_USER_PERMISSION:
            const user = state.user;
            const newRoles = user.userRoles.slice();
            const foundUserIndex = newRoles.findIndex(roles => roles._id === payload._id);

            if (foundUserIndex >= 0) {
                newRoles[foundUserIndex].permissions = payload.permission;
            }

            localStorage.setItem('roles', JSON.stringify(newRoles));

            return {
                ...state,
                user: { ...user, userRoles: newRoles }
            };
        case SET_USER_ROLES:
            const stateUser = state.user;

            if (stateUser._id !== payload._id) {
                return state;
            }

            let values = payload.roles;
            stateUser.userRoles = values;

            localStorage.setItem('roles', JSON.stringify(values));
            return {
                ...state,
                user: stateUser
            };

        default:
            return state;
    }
}
