import styled, { css } from "styled-components";

const HeaderFixedWrapper = styled.div<{ tableHeaderTopOffset?: number }>`
    ${({ tableHeaderTopOffset }) =>
        tableHeaderTopOffset &&
        css<{ tableHeaderTopOffset?: number }>`
            .k-grid {
                overflow: unset !important;
                position: unset !important;
            }
            .k-grid-aria-root {
                overflow: unset !important;
            }
            .k-grid-header {
                padding: 20px 1px 0 1px;
                margin: -20px -1px 0 -1px;
                position: sticky;
                top: ${props => props.tableHeaderTopOffset}px;
                z-index: 1;
                background-color: #fff;
                padding: 20px 1px 0 1px;
                margin: -20px -1px 0 -1px;
            }

            .k-grid-header-wrap {
                border: 1px solid #ecedf2;
            }

            input[type='checkbox'] {
                z-index: 0;
            }
            .checkmark {
                z-index: 0;
            }
        `}
`

export default HeaderFixedWrapper