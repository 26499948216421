import React, { useState } from 'react';
import styled from 'styled-components'
import { Space, Button } from 'antd';

import { SearchInput, Icon } from '@common';

const SearchIcon = styled(Icon)`
    width: 20px;
    height: 20px;
    padding-right: 3px;
    color: rgba(110,140,163,.6);
`

const ClearIcon = styled(Icon)`
    position: relative;
    top: 3px;
    width: 20px;
    height: 20px;
    color: rgba(110,140,163,.6);
`

const SearchForm = props => {
    const {
        count = 0,
        loading = false,
        placeholder = 'Search Customer or Project name or Number',
        onChange
    } = props;
    const [showCounter, setShowCounter] = useState(false);

    const handleOnSearch = (value) => {
        onChange(value);
        setShowCounter(value?.length > 0);
    };

    return (
        <Space direction='vertical' size={5}>
            <SearchInput placeholder={placeholder} onSearch={handleOnSearch} allowClear={{ clearIcon: <ClearIcon name='cross' /> }} prefix={<SearchIcon name='search' />} enterButton={<Button>Search</Button>} size='large' loading={loading} />
            {showCounter && !loading && <p style={{ marginLeft: 5, marginTop: 5 }}>{count} results found</p>}
        </Space>
    );
};

export default SearchForm;
