import { GridFilterCellProps } from '@progress/kendo-react-grid'
import { withCustomOptions } from './DynamicCheckboxFilter'
import { booleanFilterOptions } from './filter.constants'
import { IFilterDropdownItem } from './FilterDropdown/SelectDropdown'
import React from 'react'
import { CheckboxFilter } from './CheckboxFilter'

interface BooleanCheckboxFilterProps extends GridFilterCellProps {
    options?: IFilterDropdownItem[]
}

const castValue = (value: string[]) => {
    return value.map(v => v === 'true')
}

export const BooleanCheckboxFilter: React.FC<BooleanCheckboxFilterProps> = ({ onChange, value = [] }) => {
    const handleChange = (value: string[], event: React.ChangeEvent<HTMLSelectElement>) => {
        onChange({ value: castValue(value), operator: 'in', syntheticEvent: event })
    }

    return <CheckboxFilter options={booleanFilterOptions} onSubmit={handleChange} value={value || []} />
}
