import { _cloneDeep } from '../../../../utils/jsonUtil';
import * as types from './types';

const initialState = {
    salesPersons: [],
    details: { count: 0, filter: '', limit: 0, page: 0, pages: 0 },
    sending: false,
    editting: false,
    editFields: {
        row: -1,
        col: []
    },
    adding: false,
    deleting : false,
    deletingFields : []
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DELETE_SALESPERSON:
            return {
                ...state,
                deleting: true,
                deletingFields : action.payload
            };
        case types.DELETE_SALESPERSON_FAIL:
            return {
                ...state,
                deleting: false
            };
        case types.DELETE_SALESPERSON_SUCCESS:
            const temp =[...state.salesPersons];
            action.payload.map(v =>{
                const i = temp.findIndex(t => t._id === v)
                temp.splice(i, 1)
            })
            return {
                ...state,
                salesPersons : temp,
                deleting: false
            };
        case types.EDIT_SALESPERSON:
            return {
                ...state,
                editting: true,
                editFields: {
                    row: action.payload.id,
                    col: Object.keys(action.payload.params)
                }
            };
        case types.EDIT_SALESPERSON_FAIL:
            return {
                ...state,
                editting: false
            };
        case types.EDIT_SALESPERSON_SUCCESS:
            const list = _cloneDeep(state.salesPersons);
            const index = list.findIndex(e => e._id === action.payload._id);
            list[index] = action.payload;
            return {
                ...state,
                salesPersons: list,
                editting: false
            };
        case types.ADD_SALESPERSON:
            return {
                ...state,
                adding: true
            };
        case types.ADD_SALESPERSON_FAIL:
            return {
                ...state,
                adding: false
            };
        case types.ADD_SALESPERSON_SUCCESS:
            return {
                ...state,
                salesPersons: [...state.salesPersons, action.payload],
                adding: false
            };
        case types.FETCH_LIST_SALES_PERSON:
            return {
                ...state,
                sending: true
            };
        case types.FETCH_LIST_SALES_PERSON_SUCCESS:
            return {
                ...state,
                salesPersons: action.payload.items,
                details: action.payload.details,
                sending: false
            };
        case types.FETCH_LIST_SALES_PERSON_FAIL:
            return {
                ...state,
                sending: false
            };
        default:
            return state;
    }
};

export default reducer;
