import React from 'react';

import styled from 'styled-components';

interface FieldErrorI {
    error: string;
    variant: 'primary' | 'warning',
}

const FieldError = (props: FieldErrorI) => {
    const {
        error,
        variant = 'primary'
    } = props;

    if (!error) {
        return null;
    }

    return (
        <FieldErrorText variant={variant}>{error}</FieldErrorText>
    );
};

const FieldErrorText = styled.span`
    margin-top: 4px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 150%;
    color: ${({ variant }: Pick<FieldErrorI, 'variant'>) => (variant == 'primary' ? '#f7685b' : '#f38c05')};
`

export default FieldError;