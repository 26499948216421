import styled from 'styled-components'

import { Button } from '@components/UI/Styled/common'

const WidgetWrapper = styled.div<{ width: number }>`
    background-color: #fff;
    border-radius: 5px;

    ${({ width }) => `width: ${width}px`}
`

const CancelButton = styled(Button)`
    background: none;
    color: #979797;
    text-decoration: underline;
`

const ModalContent = styled.div`
    flex-grow: 1;
`

export { WidgetWrapper, CancelButton, ModalContent }
