import styled, { css } from 'styled-components'
import { StyledInputProps } from '../Pages/Project/types'
import { InputNumber as AntInputNumber } from 'antd'

export const baseInputStyles = css<StyledInputProps>`
    font-size: 15px;
    min-height: 40px;
    max-width: 400px;
    box-shadow: none;
    border: 1px solid lightgray;
    border: ${({ theme }) => theme?.input?.border};
    border-color: ${({ theme, error, warning }) => (error ? theme?.input?.error || '#f7685b' : warning ? theme?.input?.warning || '#f38c05' : null)};
    transition: all ease-in-out 0.2s;
    &:hover {
        border: 1px solid #b4c5ff;
        border-color: ${({ theme, error, warning }) => (error ? theme?.input?.error || '#f7685b' : warning ? theme?.input?.warning || '#f38c05' : null)};
    }
    &:focus,
    &:focus-within {
        border: 1px solid #b4c5ff;
        border-color: ${({ theme, error, warning }) => (error ? theme?.input?.error || '#f7685b' : warning ? theme?.input?.warning || '#f38c05' : null)};
        box-shadow: ${({ error, warning }) => (error || warning ? 'none' : '0px 0px 0px 2px rgb(124 149 239 / 24%)')};
        transform: scale(0.99);
        border-color: ${({ theme, error, warning }) => (error ? theme?.input?.error || '#f7685b' : warning ? theme?.input?.warning || '#f38c05' : null)};
        box-shadow: ${({ error, warning }) => (error || warning ? null : '0px 0px 0px 2px rgb(124 149 239 / 24%)')};
    }
    min-height: 40px;
    display: flex;
    border-radius: 5px;
    background-color: ${({ disabled = false }) => (disabled ? '#f7f7f7' : '#f5f8ff')};
    ::placeholder,
    ::-webkit-input-placeholder {
        color: rgba(110, 140, 163, 0.6);
    }
    :-ms-input-placeholder {
        color: rgba(110, 140, 163, 0.6);
    }
    :disabled {
        background: #f7f7f7;
        border: 1px solid #f4f5f7;
        color: #81878e;
    }
`

const StyledInput = styled.input<StyledInputProps>`
    ${baseInputStyles}
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
`

const StyledInputNumber = styled(AntInputNumber)<StyledInputProps>`
    ${baseInputStyles}
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    input {
        height: 38px;
        padding: 0 !important;
    }
`

export const InputWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    max-width: 400px !important;
`

const Input = (props: any) => {
    const { autoFill, ...restProps } = props
    if (autoFill) return <div>{props.value || '-'}</div>
    else
        return (
            <StyledInputWrapper>
                <StyledInput {...restProps} />
                <InputErrorMessage {...restProps}>{props?.message}</InputErrorMessage>
            </StyledInputWrapper>
        )
}

export const InputNumber = (props: any) => {
    const { autoFill, ...restProps } = props
    if (autoFill) return <div>{typeof props.value === 'number' ? props.value : '-'}</div>
    else
        return (
            <StyledInputWrapper>
                <StyledInputNumber {...restProps} />
                <InputErrorMessage {...restProps}>{props?.message}</InputErrorMessage>
            </StyledInputWrapper>
        )
}

const StyledInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const InputErrorMessage = styled.p<any>`
    color: ${({ theme, error, warning }) => (error ? theme?.input?.error || '#f7685b' : warning ? theme?.input?.warning || '#f38c05' : null)};
    margin-bottom: unset;
`

export const InputDefault = styled.div<{ disabled?: boolean; right?: number }>`
    position: absolute;
    right: ${({ right }) => right || 55}px;
    top: 0px;
    width: 40px;
    height: 100%;
    color: ${({ theme }) => theme.colors.gray2 || 'lightgray'};
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

export const InputInfo = styled.div<{ disabled?: boolean }>`
    position: absolute;
    right: 0px;
    top: 0px;
    width: 40px;
    height: 100%;
    background: #dbedff;
    border: 1px solid #e0e7ff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ disabled = false }) =>
        disabled
            ? `
        background: #f7f7f7;
        color: #81878e;
    `
            : ``}
`

export default Input
