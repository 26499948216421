import PropTypes from 'prop-types';

import SELECTION from './selection';

const Icon = ({ name, className = '', color = '', fontSize = 'inherit' }) => {
    return <i className={`icon-${name} ${className}`} style={{ color, fontSize }}></i>;
};

Icon.propTypes = {
    name: PropTypes.oneOf(SELECTION).isRequired,
    color: PropTypes.string,
    className: PropTypes.string
};

export default Icon;
