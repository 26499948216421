import React, { useMemo } from 'react';

import { MODEL_FOOTER_BUTTONS_CONFIG } from './config';

const ModalFooter = (props) => {
    const {
        name,
        content,
        context,
        customBtnConfig,
        buttons,
        type,
    } = props;

    const BTN_CONFIG = useMemo(() => {
        return Object.keys(MODEL_FOOTER_BUTTONS_CONFIG).reduce((state, k) => {
            const config = MODEL_FOOTER_BUTTONS_CONFIG[k];
            const customConfig = customBtnConfig[k] || {};
            return {
                ...state,
                [k]: {
                    ...config,
                    ...customConfig,
                }
            };
        }, {})
    }, [customBtnConfig]);

    const handleClickOnButton = (e) => {
        e.preventDefault();
        const actionName = e.target.value;
        const action = props[actionName];

        if (typeof action !== 'function') {
            return;
        }

        action(type, context);
    };

    const footerButtons = buttons.map((b) => {
        const btnConfig = BTN_CONFIG[b];
        if (!btnConfig) {
            return;
        }

        const btnKey = `${name}-modal-footer-${b}`;
        const btnClassName = `buttonModal__${btnConfig.classPrefix}`;

        const isDisabled = btnConfig.disabled && btnConfig.disabled(context) || false;

        return (
            <button
                onClick={handleClickOnButton}
                key={btnKey}
                name={btnKey}
                disabled={isDisabled}
                className={btnClassName}
                value={btnConfig.action}
                type='button'
                data-cy={`button-modal-${btnConfig.text}`}
            >
                {btnConfig.text}
            </button>
        );
    });

    const renderContent = () => {
        if (React.isValidElement(content)) {
            return React.cloneElement(content, { context });
        }
        return content;
    }

    return (
        <div className='OptionsModal__buttons buttonModal'>
            {renderContent()}
            <div className="footer-controls">
                {footerButtons}
            </div>
        </div>
    );
};

export default ModalFooter;
