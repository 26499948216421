import styled from 'styled-components'
import { stageColors, stageFontColors, stageLabels } from '@constants/stages'
import { PropsWithChildren } from 'react'

type ProgressStatusProps = {
    color: string
}

const StyledProgressStatus = styled.div<ProgressStatusProps>`
    text-align: center;
    width: 140px;
    min-width: 120px;
    font-weight: 500;
    font-size: 13px;
    height: 28px;
    border-radius: 4px;
    padding: 5px 20px;
    color: white; 
    background-color: ${({color})=>(color || stageColors.default)};
    text-transform: uppercase;
    word-break: keep-all;
    word-wrap: normal;
    white-space: nowrap;
`
const ProgressStatus = (props: PropsWithChildren<ProgressStatusProps>) => (
    <StyledProgressStatus {...props}>
        {props.children}
    </StyledProgressStatus>
)

export default ProgressStatus