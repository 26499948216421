import { Feather } from '@components/index'
import { MouseEventHandler } from 'react'

type TitleClearButtonProps = {
    style?: React.CSSProperties
    onClick: MouseEventHandler<HTMLDivElement>
}

const TitleClearButton = (props: TitleClearButtonProps) => (
    <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer', ...(props.style || {})}} onClick={props.onClick}>
        <Feather name='Delete' />
        <span style={{marginLeft: '5px'}}>Clear</span>
    </div>
)

export default TitleClearButton