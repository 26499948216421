import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from '@common';
import { UserType } from '../../Pages/Setup/UserSetup/Users/const';
import SelectEntityDropdown from '@panels/SelectEntityDropdown';
import { Button, FormRow, LabeledTextArea, LabeledTextField, Label } from '@components';
import { plus } from '@assets';
import { fetchListPlants } from '@reducers/data/plants/actions';
import { fetchListUserGroup } from '@reducers/data/userGroups/actions';
import { fetchListUserRoles } from '@reducers/data/userRoles/actions';
import { fetchPlants } from 'service/plantsApi';
import { useQuery } from '@tanstack/react-query';

const UserPanel = ({ user, onUpdateUserHandler, selected, onSaveHandler, onCloseHandler, setBreadcrumbOptions, breadcrumbOptions, setSidepanelOptions, sidepanelOptions }) => {
    const { userGroups } = useSelector(state => state.data.userGroups);
    const { userRoles } = useSelector(store => store.data.userRoles);
    const { user: currentUser } = useSelector(store => store.auth);
    const { users } = useSelector(store => store.data.users);
    const isAdmin = currentUser.userType === 'ADMIN' || currentUser.userType === 'PRICEBEE';

    const dispatch = useDispatch();

    const serializeFilter = (filter) => {

        const filterColumns = Object.keys(filter);
        const filterValues = Object.values(filter);

        return {
            filterColumns: filterColumns.join(','),
            filterValues: filterValues.join(',')
        };
    }
    const { data: plantOptionList } = useQuery([user?.userGroups], () => fetchPlants(
        { ...serializeFilter({ status: 'active', isCompetitorPlant: false }), ...(user.userGroups.length ? { 'geoZones._id': user.userGroups.map(ug => ug.geoZones).flat().map(gz => gz._id).join(';') } : {}) }
    ), {
        select: res => res.items.map(p => ({ _id: p._id, value: p._id, label: p.name }))
    });


    useEffect(() => {
        dispatch(fetchListUserRoles());
        dispatch(fetchListUserGroup());
    }, [])

    const onChangeUserGroup = userGroupsUpdates => {
        const validUserGroups = userGroupsUpdates.filter(ug => ug._id);
        if (!validUserGroups?.length) {
            const defaultUserGroup = userGroups.find(uG => uG?.isDefault === true);
            onUpdateUserHandler({ userGroups: [defaultUserGroup] });
            return;
        }
        onUpdateUserHandler({ userGroups: [...validUserGroups].filter(uG => uG?.isDefault !== true) });
        onUpdateUserHandler({ defaultPlant: null })
    };

    const onUpdateUserType = e => {
        const isTypeNotAdmin = e === 'ADMIN';
        if (isTypeNotAdmin) {
            onUpdateUserHandler({ userType: e });
            return;
        }
        const selectedUser = selected[0];
        const selectedUserTypeAdmin = selectedUser?.userType === 'ADMIN';
        if (selectedUserTypeAdmin) {
            const allUsers = [...users].filter(user => user._id !== selectedUser?._id);
            const isAdminExists = allUsers.some(user => user.userType === 'ADMIN');
            if (isAdminExists) {
                onUpdateUserHandler({ userType: e });
                return;
            }
            return;
        }
        onUpdateUserHandler({ userType: e });
    };

    const userRolesOptionList = useMemo(() => {
        if (!userRoles.length) {
            return [];
        }

        return userRoles.filter(user => user.status == 'active').map(userRole => ({ _id: userRole._id, value: userRole._id, label: userRole.name }));
    });

    return (
        <FormRow title='General Information'>
            <div className='row'>
                <div className='col-md-8'>
                    <LabeledTextField
                        placeholder='Add first name'
                        label='First name'
                        value={user.firstName}
                        onChange={e => onUpdateUserHandler({ firstName: e.target.value })}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-md-8'>
                    <LabeledTextField
                        placeholder='Add last name'
                        label='Last name'
                        value={user.lastName}
                        onChange={e => onUpdateUserHandler({ lastName: e.target.value })}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-md-8'>
                    <LabeledTextField placeholder='Add email' label='Email' value={user.email} onChange={e => onUpdateUserHandler({ email: e.target.value })} />
                </div>
            </div>
            <div className='row'>
                <div className='col-md-8'>
                    <Label label={'User roles'} />
                    <Dropdown
                        placeholder='Add user roles'
                        value={userRolesOptionList.find(userRole => userRole._id === user?.userRoles?.[0])?.value}
                        options={userRolesOptionList}
                        disabled={!isAdmin}
                        onChange={value => onUpdateUserHandler({ userRoles: [value] })}
                    />
                </div>
                <div className='col-md-8' style={{ marginTop: '20px', maxWidth: '400px' }}>
                    <SelectEntityDropdown
                        multi
                        label='User groups'
                        placeholder='Add user groups'
                        selected={user.userGroups}
                        onChange={onChangeUserGroup}
                        items={[...userGroups.filter(uG => !uG.isDefault).map(uG => ({ _id: uG._id, label: uG.name, value: uG }))]}
                        footerButton={{
                            icon: plus,
                            text: 'Create new user group',
                            onClick: () => {
                                setBreadcrumbOptions({ steps: [...breadcrumbOptions.steps, 'Add user group'], current: 1 });
                                setSidepanelOptions({ ...sidepanelOptions, type: 'User Group' });
                            }
                        }}
                        disabled={!isAdmin}
                    />
                </div>
                <div className='col-md-8' style={{ marginTop: '20px' }}>
                    <Label label={'User Type'} />
                    <Dropdown
                        placeholder='select user type'
                        value={user.userType}
                        options={[
                            { label: 'User', value: 'USER' },
                            { label: 'Admin', value: 'ADMIN' },
                            { label: 'PriceBee', value: 'PRICEBEE' }
                        ]}
                        disabled={!isAdmin}
                        onChange={onUpdateUserType}
                    />
                </div>
            </div>
            <div className='row' style={{ marginTop: '20px' }}>
                <div className='col-md-8'>
                    <Label label={'Default plant'} />
                    <Dropdown
                        placeholder='Add default plant'
                        value={plantOptionList?.find(plant => plant._id === user.defaultPlant)?.value}
                        options={plantOptionList || []}
                        disabled={!isAdmin || !user?.userGroups?.length}
                        onChange={value => onUpdateUserHandler({ defaultPlant: value })}
                    />
                </div>
            </div>
            <div className='row' style={{ marginTop: '20px' }}>
                <div className='col-md-8'>
                    <LabeledTextField
                        placeholder='Add direct phone'
                        label='Direct phone'
                        value={user.directPhone}
                        onChange={e => onUpdateUserHandler({ directPhone: e.target.value })}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-md-8'>
                    <LabeledTextField
                        placeholder='Add mobile phone'
                        label='Mobile phone'
                        value={user.mobilePhone}
                        onChange={e => onUpdateUserHandler({ mobilePhone: e.target.value })}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-md-8'>
                    <LabeledTextField placeholder='Add fax' label='Fax' value={user.fax} onChange={e => onUpdateUserHandler({ fax: e.target.value })} />
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <LabeledTextArea
                        placeholder='Add user description'
                        label='User description'
                        value={user.description}
                        onChange={e => onUpdateUserHandler({ description: e.target.value })}
                        rows={3}
                    />
                </div>
            </div>
            <div className='row mt-60 pb-8' style={{ marginBottom: '60px' }}>
                <div className='col-12'>
                    <div className='flex-start'>
                        <Button style={{ width: '120px' }} ripple customClass='btnold--primary center' onClick={onSaveHandler}>
                            Save
                        </Button>
                        <div className='ml-20'>
                            <Button style={{ width: '120px' }} ripple customClass='btnold--secondary center' onClick={onCloseHandler}>
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </FormRow>
    )
}

export default UserPanel;
