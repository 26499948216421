import { action, autorun, makeObservable, observable } from 'mobx'
import { Quote } from '@customTypes/quote'
import { Project } from '@customTypes/project'
import service from './service'

const STAGES_PREFIX = 'stages'
const STAGE_NOT_FOUND = 'Stage not found'
// const ERROR_SYSTEM_TYPE = 'Cann`t edit system stage'
// const ERROR_UNKNOWN_PERMISSION = 'Unknown permission'

export const QUOTE_PERMISSIONS = {
    changeStatus: 'Change Status',
    delete: 'Delete',
    edit: 'Edit',
    taxUpdated: 'Tax updated',
    sendEmail: 'Send via Email',
    saveAsNewVersion: 'Save as new version',
    autoChangeToSend: 'Automatic change to send stage',
    sendToPOS: 'Send to POS',
    undoToPOS: 'Undo to POS',
    sendForApproval: 'Send for approval',
    updateExpirationDate: 'Update Expiration date based on days to expiration',
}

export const PROJECT_PERMISSIONS = {
    changeStatus: 'Change Status',
    saveAsNewVersion: 'Save as new version',
    delete: 'Delete',
    edit: 'Edit',
    taxUpdated: 'Tax updated',
    updateQuotesOnSave: 'Update project quotes on clicking save',
}

export const QUOTE_TABS = {
    general: 'General',
    deliveryAndTransport: 'Delivery and transport',
    products: 'Products',
    history: 'History',
    close: 'Close quote',
    send: 'Send',
    documents: 'Documents'
}

export const PROJECT_TABS = {
    general: 'General',
    deliveryAndTransport: 'Delivery and transport',
    products: 'Products',
    contacts: 'Contacts',
    quotes: 'Quotes',
    note: 'Note',
    history: 'History',
    documents: 'Documents'
}

export type QUOTE_AND_PROJECT_TABS_TYPE = { [k in keyof typeof QUOTE_TABS]?: { value: boolean } } & { [k in keyof typeof PROJECT_TABS]?: { value: boolean } } & {
    [k: string]: { value: boolean }
}

export const SYSTEM_STAGES = {
    draft: 'Draft',
    sent: 'Sent',
    won: 'Won',
    lost: 'Lost',
    cancelled: 'Cancelled'
}

export type TStage = {
    _id?: string
    key: string
    basedOn?: string
    name: string
    type: 'system' | 'custom'
    order: number
    color?: string
    permissions: string[]
    allowedNextStages: string[]
    allowedTabs: string[]
    readOnlyPermissions: string[]
}

export interface IQuoteStage extends TStage {
    permissions: Array<keyof typeof QUOTE_PERMISSIONS>
    allowedNextStages: Array<keyof typeof SYSTEM_STAGES>
    allowedTabs: Array<keyof typeof QUOTE_TABS>
    readOnlyPermissions: Array<keyof typeof QUOTE_PERMISSIONS>
    basedOn?: 'draft' | 'sent' | 'won' | 'lost' | 'cancelled'
}

export interface IProjectStage extends TStage {
    permissions: Array<keyof typeof PROJECT_PERMISSIONS>
    allowedNextStages: Array<keyof typeof SYSTEM_STAGES>
    allowedTabs: Array<keyof typeof PROJECT_TABS>
    readOnlyPermissions: Array<keyof typeof PROJECT_PERMISSIONS>
    basedOn?: 'draft' | 'sent' | 'won' | 'lost' | 'cancelled'
}

export const QUOTE_SYSTEM_STAGES: IQuoteStage[] = [
    {
        key: 'draft',
        name: SYSTEM_STAGES.draft,
        type: 'system',
        order: 100,
        color: '#53BCFF',
        permissions: ['changeStatus', 'delete', 'edit', 'taxUpdated', 'sendEmail', 'saveAsNewVersion', 'autoChangeToSend'],
        allowedNextStages: ['sent'],
        allowedTabs: ['general', 'deliveryAndTransport', 'products', 'history', 'send', 'documents'],
        readOnlyPermissions: ['sendToPOS', 'undoToPOS']
    },
    {
        key: 'sent',
        name: SYSTEM_STAGES.sent,
        type: 'system',
        order: 200,
        color: '#FF8939',
        permissions: ['changeStatus', 'edit', 'taxUpdated', 'sendEmail', 'saveAsNewVersion'],
        allowedNextStages: ['draft', 'won', 'lost', 'cancelled'],
        allowedTabs: Object.keys(QUOTE_TABS) as Array<keyof typeof QUOTE_TABS>,
        readOnlyPermissions: ['delete', 'sendToPOS', 'undoToPOS', 'autoChangeToSend']
    },
    {
        key: 'won',
        name: SYSTEM_STAGES.won,
        type: 'system',
        order: 300,
        color: '#2CD47A',
        permissions: ['changeStatus', 'sendEmail', 'sendToPOS', 'undoToPOS'],
        allowedNextStages: ['lost', 'cancelled'],
        allowedTabs: Object.keys(QUOTE_TABS) as Array<keyof typeof QUOTE_TABS>,
        readOnlyPermissions: ['delete', 'edit', 'taxUpdated', 'saveAsNewVersion', 'autoChangeToSend']
    },
    {
        key: 'lost',
        name: SYSTEM_STAGES.lost,
        type: 'system',
        order: 400,
        color: '#F7685B',
        permissions: ['changeStatus', 'sendEmail', 'sendToPOS', 'undoToPOS'],
        allowedNextStages: ['won', 'cancelled'],
        allowedTabs: Object.keys(QUOTE_TABS) as Array<keyof typeof QUOTE_TABS>,
        readOnlyPermissions: ['delete', 'edit', 'taxUpdated', 'saveAsNewVersion', 'autoChangeToSend']
    },
    {
        key: 'cancelled',
        name: SYSTEM_STAGES.cancelled,
        type: 'system',
        order: 500,
        color: '#0E3F66',
        permissions: ['changeStatus', 'sendEmail', 'sendToPOS', 'undoToPOS'],
        allowedNextStages: ['won', 'lost'],
        allowedTabs: Object.keys(QUOTE_TABS) as Array<keyof typeof QUOTE_TABS>,
        readOnlyPermissions: ['delete', 'edit', 'taxUpdated', 'saveAsNewVersion', 'autoChangeToSend']
    }
]

export const PROJECT_SYSTEM_STAGES: IProjectStage[] = [
    {
        key: 'draft',
        name: SYSTEM_STAGES.draft,
        type: 'system',
        order: 100,
        color: '#53BCFF',
        permissions: ['changeStatus', 'delete', 'edit', 'taxUpdated'],
        allowedNextStages: ['sent'],
        allowedTabs: Object.keys(PROJECT_TABS) as Array<keyof typeof PROJECT_TABS>,
        readOnlyPermissions: []
    },
    {
        key: 'sent',
        name: SYSTEM_STAGES.sent,
        type: 'system',
        order: 200,
        color: '#FF8939',
        permissions: ['changeStatus', 'edit', 'taxUpdated'],
        allowedNextStages: ['draft', 'won', 'lost', 'cancelled'],
        allowedTabs: Object.keys(PROJECT_TABS) as Array<keyof typeof PROJECT_TABS>,
        readOnlyPermissions: ['delete']
    },
    {
        key: 'won',
        name: SYSTEM_STAGES.won,
        type: 'system',
        order: 300,
        color: '#2CD47A',
        permissions: ['changeStatus'],
        allowedNextStages: [],
        allowedTabs: Object.keys(PROJECT_TABS) as Array<keyof typeof PROJECT_TABS>,
        readOnlyPermissions: ['delete', 'edit', 'taxUpdated']
    },
    {
        key: 'lost',
        name: SYSTEM_STAGES.lost,
        type: 'system',
        order: 400,
        color: '#F7685B',
        permissions: ['changeStatus'],
        allowedNextStages: [],
        allowedTabs: Object.keys(PROJECT_TABS) as Array<keyof typeof PROJECT_TABS>,
        readOnlyPermissions: ['delete', 'edit', 'taxUpdated']
    },
    {
        key: 'cancelled',
        name: SYSTEM_STAGES.cancelled,
        type: 'system',
        order: 500,
        color: '#0E3F66',
        permissions: ['changeStatus'],
        allowedNextStages: [],
        allowedTabs: Object.keys(PROJECT_TABS) as Array<keyof typeof PROJECT_TABS>,
        readOnlyPermissions: ['delete', 'edit', 'taxUpdated']
    }
]

type StageConstructorProps<K> = {
    entity: Project | Quote
    stages: TStage[]
}

class Stage<K> {
    currentStage: string = ''
    stages: Map<string, TStage> = new Map()
    static staticStages = new Map()
    onError = (e: Error) => {
        console.error(e.message)
    }

    constructor({ entity, stages }: StageConstructorProps<K>) {
        makeObservable(this, {
            currentStage: observable,
            setStage: action
        })
        autorun(() => {
            this.setStage(entity.currentProgress)
        })
        this.stages = new Map(stages.map(s => [s.key, s]))
    }

    setStage(value: string) {
        this.currentStage = value
    }

    can(key: keyof K, manualStage?: string) {
        try {
            const currentStage = manualStage || this.currentStage
            if (typeof currentStage === 'undefined') return false
            if (!this.stages.has(currentStage)) return false
            const stage = this.stages.get(currentStage)
            const permissions = stage?.permissions
            if (!permissions) return false
            return permissions.includes(key as string) || false
        } catch (e) {
            this.onError(e as Error)
        }
        return false
    }

    get label() {
        try {
            if (typeof this.currentStage === 'undefined') return
            if (!this.stages.has(this.currentStage)) throw new Error(`${STAGE_NOT_FOUND}: ${this.currentStage}`)
            const stage = this.stages.get(this.currentStage)
            return stage?.name
        } catch (e) {
            this.onError(e as Error)
        }
    }

    get color() {
        try {
            if (typeof this.currentStage === 'undefined') return
            if (!this.stages.has(this.currentStage)) throw new Error(`${STAGE_NOT_FOUND}: ${this.currentStage}`)
            const stage = this.stages.get(this.currentStage)
            return stage?.color
        } catch (e) {
            this.onError(e as Error)
        }
    }

    get allowedNextStages() {
        try {
            if (typeof this.currentStage === 'undefined') return
            if (!this.stages.has(this.currentStage)) throw new Error(`${STAGE_NOT_FOUND}: ${this.currentStage}`)
            const stage = this.stages.get(this.currentStage)
            return stage?.allowedNextStages
        } catch (e) {
            this.onError(e as Error)
        }
    }

    get allowedTabs() {
        try {
            if (typeof this.currentStage === 'undefined') return
            if (!this.stages.has(this.currentStage)) throw new Error(`${STAGE_NOT_FOUND}: ${this.currentStage}`)
            const stage = this.stages.get(this.currentStage)
            return stage?.allowedTabs
        } catch (e) {
            this.onError(e as Error)
        }
    }

    allowedTabsByStage(manualStage: string): string[] | undefined {
        try {
            if (typeof manualStage === 'undefined') return []
            if (!this.stages.has(manualStage)) return []
            const stage = this.stages.get(manualStage)
            return stage?.allowedTabs || []
        } catch (e) {
            this.onError(e as Error)
        }
    }

    get name() {
        try {
            if (typeof this.currentStage === 'undefined') return
            if (!this.stages.has(this.currentStage)) throw new Error(`${STAGE_NOT_FOUND}: ${this.currentStage}`)
            const stage = this.stages.get(this.currentStage)
            return stage?.name
        } catch (e) {
            this.onError(e as Error)
        }
    }

    getName(currentProgress: string) {
        try {
            if (this.stages.size === 0) return
            if (!this.stages.has(currentProgress)) throw new Error(`${STAGE_NOT_FOUND}: ${currentProgress}`)
            const stage = this.stages.get(currentProgress)
            return stage?.name
        } catch (e) {}
    }

    getColor(currentProgress: string) {
        try {
            if (this.stages.size === 0) return
            if (!this.stages.has(currentProgress)) throw new Error(`${STAGE_NOT_FOUND}: ${currentProgress}`)
            const stage = this.stages.get(currentProgress)
            return stage?.color
        } catch (e) {}
    }
}

export async function fetchStages(entityKey: string) {
    return await service.get<TStage[]>(entityKey + '-' + STAGES_PREFIX).then(({ data }) => {
        return data
    })
}

export async function createStage(entityKey: string, data: Omit<TStage, '_id'>) {
    const res = await service.post(entityKey + '-' + STAGES_PREFIX, data)
    return res.data
}

export async function removeStage(entityKey: string, id: string) {
    await service.delete(entityKey + '-' + STAGES_PREFIX + `/${id}`)
}

export async function updateStage(entityKey: string, id: string, data: Partial<TStage>) {
    await service.put(entityKey + '-' + STAGES_PREFIX + `/${id}`, data)
}

export class QuoteStage extends Stage<typeof QUOTE_PERMISSIONS> {
    constructor(props: Omit<StageConstructorProps<typeof QUOTE_PERMISSIONS>, 'entityKey'>) {
        super({ ...props })
    }
}

export class ProjectStage extends Stage<typeof PROJECT_PERMISSIONS> {
    constructor(props: Omit<StageConstructorProps<typeof PROJECT_PERMISSIONS>, 'entityKey'>) {
        super({ ...props })
    }
}

export default Stage
