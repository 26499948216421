import { useState, useEffect } from 'react'
import useRequest from '@hooks/useRequest'

const useTableSettings = props => {
    const { tableKey, columns } = props

    const DEFAULT_SORT = { sort: props.sort || 'created', order: 'asc' }

    const [sort, setSort] = useState()
    const [settings, setSettings] = useState()

    const [fetchSettings, fetchSettingsErrors, fetchSettingsLoading] = useRequest({
        url: `/settings/${tableKey}`,
        method: 'get',
        onSuccess: data => {
            setSettings(data)
        },
        onFail: () => setSettings(null),
        enabled: tableKey !== undefined
    })

    const [updateSettings] = useRequest({
        url: `/settings/${tableKey}`,
        method: 'post'
    })

    useEffect(() => {
        fetchSettings()
    }, [columns])

    useEffect(() => {
        if (typeof settings === 'undefined') return
        let sort = DEFAULT_SORT
        if (settings && Array.isArray(settings.views) && settings.views.length > 0) {
            const view = settings.views.find(v => v.name === 'autosave')
            if (view && view.sort) {
                sort = { sort: view.sort.field, order: view.sort.order }
            }
        }
        setSort(sort)
    }, [settings])

    function updateSort(sort) {
        if (!sort || !sort.sort || !sort.order) setSort(DEFAULT_SORT)
        else setSort(sort)
        const autosaveView = settings?.views?.find(v => v.name === 'autosave')
        if (settings === null || !autosaveView) {
            const newSettings = {
                key: tableKey,
                lastShown: 'autosave',
                views: [
                    {
                        name: 'autosave',
                        columns,
                        sort: { field: sort?.sort, order: sort?.order }
                    }
                ]
            }
            updateSettings({ body: newSettings })
        } else {
            const newViews = [...settings.views]
            autosaveView.sort = { field: sort?.sort, order: sort?.order }
            updateSettings({ body: { views: newViews } })
        }
    }

    return { sort, updateSort }
}

export default useTableSettings
