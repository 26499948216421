import React, { useCallback, useRef } from 'react'
import { Popover } from '@nextui-org/react'
import styled from 'styled-components'
import filterIcon from '@assets/icons/filter.svg'
import { OptionList } from './SelectOptionList'
import { IconWrapper } from './IconWrapper'

export type IDropdownItemValue = string | number

export interface IFilterDropdownItem {
    value: string
    label: string
}

type DropdownProps = {
    options?: IFilterDropdownItem[]
    onChange?: React.ChangeEventHandler<HTMLSelectElement> | undefined
    value?: any
    error?: any
}

export const SelectDropdown: React.FC<DropdownProps> = ({ options = [], onChange, value }) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const innerSelectRef = useRef<HTMLSelectElement>(null)

    const changeSelectValue = useCallback((newValue: string) => {
        if (!innerSelectRef.current) return
        innerSelectRef.current.value = newValue
        const event = new Event('change', { bubbles: true })
        innerSelectRef.current.dispatchEvent(event)
    }, [])

    const handleSelect = useCallback((option: IFilterDropdownItem) => {
        changeSelectValue(option.value)
        setIsOpen(false)
    }, [])

    return (
        <Popover offset={4} shouldFlip isOpen={isOpen} onOpenChange={e => setIsOpen(e)} data-cy='dropdown'>
            <Popover.Trigger>
                <IconWrapper data-cy='dropdown-trigger'>
                    <FilterIcon src={filterIcon} />
                    <InnerSelect value={value} onChange={onChange} ref={innerSelectRef}>
                        <Options options={options} />
                    </InnerSelect>
                </IconWrapper>
            </Popover.Trigger>
            <Popover.Content css={{ borderRadius: '5px', maxHeight: 400 }}>
                <DropdownMenu>
                    <OptionList onSelect={handleSelect} options={options} value={value} />
                </DropdownMenu>
            </Popover.Content>
        </Popover>
    )
}

const Options = (props: { options: IFilterDropdownItem[] }) => (
    <>
        {props.options.map(({ value }) => (
            <option value={value} key={value}></option>
        ))}
    </>
)

const DropdownMenu = styled.div`
    padding: 5px;
    min-width: 200px;
    width: 100%;
`

const FilterIcon = styled.img`
    width: 20px;
    height: 20px;
`

const InnerSelect = styled.select`
    display: none;
`
