import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Person } from '@assets';

import { FormRow, LabeledTextField, Button, Image, } from '@components';

import SelectGeoZoneDropdown from '@panels/SelectEntityDropdown/SelectGeoZoneDropdown';

import { fetchListGeoZones } from '@reducers/data/geoZones/actions';

import { checkIfNameExists } from 'service/userGroups';

import '@scss/panels/_user-groups.scss';

const UserGroupsPannel = ({ openModal, onHandleChange, onSave, group, onClose, breadcrumbOptions, groupsList = [], validationErrors, setValidationErrors }) => {
    const users = useSelector(state => state.data.users.users);
    const dispatch = useDispatch();

    const validateFiled = async () => {
        const errors = [];
        const groupName = group?._id ? groupsList.find(g => g._id === group._id)?.name : null;
        if (!group?.name) {
            errors.push({ id: 'groupNameID', message: 'Name is required!' });
            setValidationErrors(errors);
            return errors;
        }
        if (groupName !== group.name) {
            const data = await checkIfNameExists(group?.name);
            if (data) {
                errors.push({ id: 'groupNameID', message: 'already exists!' });
            }
        }
        if (group.geoZones.length <= 0) {
            errors.push({ id: 'geoZones', message: 'GeoZones is required!' });
        }
        setValidationErrors(errors);
        return errors;
    };

    useEffect(() => {
        dispatch(fetchListGeoZones());
    }, []);

    const handleSave = async () => {
        const errors = await validateFiled();
        if (errors.length > 0) {
            return;
        } else {
            onSave();
            onClose();
        }
    };

    const handleClose = () => {
        setValidationErrors([]);
        onClose();
    };

    return (
        <div className={'UsersGroup__content'}>
            <FormRow>
                <div className='row'>
                    <div className='col-md-12'>
                        <LabeledTextField
                            placeholder='Add group name'
                            label='Group Name'
                            name='groupNameID'
                            id='groupNameID'
                            value={group.name}
                            onChange={e => {
                                onHandleChange({ ...group, name: e.target.value });
                                setValidationErrors([]);
                            }}
                            readOnly={group?.isDefault}
                            formErrors={validationErrors}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <LabeledTextField
                            readOnly={group?.isDefault}
                            placeholder='Add Purpose'
                            label='Purpose'
                            value={group.purpose}
                            onChange={e => onHandleChange({ ...group, purpose: e.target.value })}
                        />
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-md-12'>
                        <SelectGeoZoneDropdown
                            id='geoZones'
                            name='geoZones'
                            formErrors={validationErrors}
                            disabled={group?.isDefault}
                            selected={group?.geoZones || []}
                            onChange={gZs => onHandleChange({ ...group, geoZones: gZs })}
                        />
                        {validationErrors?.find(err => err?.id === 'geoZones') ? (
                            <span style={{ fonSize: '12px', color: '#f7685b', fontWeight: 300, lineHeight: '150%', marginTop: '4px' }}>GeoZone required!</span>
                        ) : null}
                    </div>
                </div>
                <div className='row' style={{ marginTop: '25px' }}>
                    <div className='col-md-12'>
                        <span className='u-group-header'>GROUP MEMBERS</span>
                        <div className='u-group-members' onClick={!group?.isDefault ? () => openModal() : null}>
                            <Image src={Person} />
                            <span>Add members</span>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12' style={{ marginTop: '25px' }}>
                        <div className='members-header'>
                            MEMBERS <span className='members-counter'>({group?.users?.length})</span>
                        </div>
                        {group?.users?.length > 0 ? (
                            <div className='members-wrapper'>
                                {group?.users.map(member => {
                                    const u = users.find(el => el?._id === member);
                                    return (
                                        <span className='members-item' key={member?._id}>
                                            {u?.firstName} {u?.lastName}
                                        </span>
                                    );
                                })}
                            </div>
                        ) : (
                            '-'
                        )}
                    </div>
                </div>
            </FormRow>
            {!group?.isDefault && (
                <div className='UGroup__footer'>
                    <div className='flex-start'>
                        <span className='footer-btn' onClick={() => handleClose()}>
                            {breadcrumbOptions?.steps?.length > 1 ? `Back to ${breadcrumbOptions.steps[breadcrumbOptions.steps.length - 2]}` : 'Cancel'}
                        </span>
                        <div className='ml-20'>
                            <Button style={{ width: '120px' }} ripple customClass='btnold--secondary center' onClick={() => handleSave()}>
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserGroupsPannel;
