import { Feather } from '@components'
import styles from './search.module.scss'
import Show from '@components/UI/Show'
import { useEffect, useRef } from 'react'

const Search = ({ value, onChange, onClear, style={}, inputStyle={} }) => {
    const inputRef = useRef(null)
    useEffect(()=>{
        setTimeout(()=>{inputRef.current && inputRef.current.focus({ preventScroll: true })}, 0)
    },[])
    return (
        <div className={styles.search} style={style}>
            <div className={styles.icon}>
                <Feather name='Search'/>
            </div>
            <input
                ref={inputRef}
                autoComplete='off'
                name='search'
                placeholder='Search'
                value={ value }
                onChange={ onChange }
                style={inputStyle}
            />
            <Show condition={!(value==='' || value===null || typeof value === 'undefined')}>
                <div className={styles.clear} onClick={onClear}>
                    <Feather name='X' />
                </div>
            </Show>

        </div>
    )
}

export default Search