export const FETCH_LIST_PRODUCTS = 'FETCH_LIST_PRODUCTS';
export const FETCH_LIST_PRODUCTS_SUCCESS = 'FETCH_LIST_PRODUCTS_SUCCESS';
export const FETCH_LIST_PRODUCTS_FAIL = 'FETCH_LIST_PRODUCTS_FAIL';

export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_PRODUCT_SUCCESS= 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAIL = 'ADD_PRODUCT_FAIL';

export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const EDIT_PRODUCT_SUCCESS= 'EDIT_PRODUCT_SUCCESS';
export const EDIT_PRODUCT_FAIL = 'EDIT_PRODUCT_FAIL';

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_SUCCESS= 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL';

export const CLEAR_ERROR = 'CLEAR_ERROR';
