import { ColumnsIcon, Dropdown, Checkbox } from '@common'
import styled from 'styled-components'
import { TableColumn, View } from '@customTypes/table'

type ColumnsControlProps = {
    columns: TableColumn[]
    view?: View
    dataProps?: any
    updateView: (view: Partial<View>, settings: { lastShown: string }) => void
}

const ColumnsControl = ({ columns = [], dataProps = {}, view, updateView }: ColumnsControlProps) => {
    const viewColumns = view
        ? columns
            .filter(c => c?.type !== 'actions')
            .map(
                c =>
                    view.columns.find(vc => vc.dataIndex === c.dataIndex) ||
                    ({
                        id: c.id,
                        dataIndex: c.dataIndex,
                        width: c.width,
                        hidden: c.hidden,
                        order: c.id,
                        title: typeof c.title === 'string' ? c.title : c.title && c.title({ dataProps })
                    } as TableColumn)
            )
        : []

    function handleChange(dataIndex: string) {
        const columnIndex = viewColumns.findIndex(c => c.dataIndex === dataIndex)
        if (columnIndex === -1 || typeof columnIndex === 'undefined') return
        const newColumns = viewColumns.map(c => ({ ...c }))
        if (!newColumns) return
        newColumns[columnIndex].hidden = !newColumns[columnIndex].hidden
        onColumnSelect(newColumns)
    }

    function onColumnSelect(newColumns: TableColumn[]) {
        updateView({ columns: newColumns, name: 'autosave' }, { lastShown: 'autosave' })
    }

    function handleSelectAll() {
        if (!view) return
        if (columns.length === viewColumns.filter(c => !c.hidden).length) {
            const newColumns = viewColumns.map(c => ({ ...c, hidden: true }))
            onColumnSelect(newColumns)
        } else {
            const newColumns = viewColumns.map(c => ({ ...c, hidden: false }))
            onColumnSelect(newColumns)
        }
    }

    function handleResetWidth() {
        const columnsWidth: { [key: string]: number } = columns.reduce((acc, cur) => ({ ...acc, [cur.dataIndex]: cur.width }), {})
        const newColumns = (view?.columns || []).map(c => ({ ...c, width: columnsWidth[c.dataIndex] || c.width }))
        updateView({ columns: newColumns, name: 'autosave' }, { lastShown: 'autosave' })
    }

    function getTitle(column: TableColumn) {
        const originalColumn = columns.find(c => c.dataIndex === column.dataIndex)
        if (!originalColumn) return
        if (typeof originalColumn.title === 'string') return originalColumn.title
        if (typeof originalColumn.title === 'function') {
            const title = originalColumn.title({ dataProps })
            if (!title) return originalColumn.defaultTitle || '-'
            return title
        }
    }

    const checked = Object.fromEntries(viewColumns.map(c => [c.dataIndex, !c.hidden]) || [])

    return (
        <Dropdown
            renderTrigger={
                <IconContainer>
                    <ColumnsIcon />
                </IconContainer>
            }
            renderContent={
                <ColumnsList>
                    <li key='selectAll' style={{ borderBottom: '1px solid #c7d0d7', paddingBottom: '10px' }}>
                        <Checkbox id='selectAll' onChange={handleSelectAll} checked={columns.length === viewColumns.filter(c => !c.hidden).length}>Select All</Checkbox>
                    </li>
                    {columns.map(column => (
                        <li key={column.dataIndex}>
                            <Checkbox id={column.dataIndex} onChange={() => handleChange(column.dataIndex)} checked={checked[column.dataIndex]}>{getTitle(column)}</Checkbox>
                        </li>
                    ))}
                    <li key='resetWidth' style={{ borderTop: '1px solid #c7d0d7', paddingTop: '10px' }} onClick={handleResetWidth}>
                        <a style={{ cursor: 'pointer', color: '#0e3f66' }}>Reset columns width</a>
                    </li>
                </ColumnsList>
            }
        />
    )
}

export default ColumnsControl

const ColumnsList = styled.ul`
    padding: 0 15px;
    margin: 10px 0;
    list-style: none;
    li {
        margin: 10px 0;
        display: flex;
    }
`

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #0e3f66;
    background: #fff;
    width: 40px;
    height: 40px;
    cursor: pointer;
`
