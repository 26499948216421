import React from 'react';
import { Image } from '@components';

import StatusSelector from './StatusSelector';

import '@scss/panels/_headline.scss';

const Headline = (props) => {
    const {
        icon,
        title,
        children = null,
        initialStatuses = [],
        onFilterChange = () => { },
        hideFilters = false
    } = props;

    return (
        <div className='Headline'>
            <div className='tab' data-cy='headline-title'>
                <Image src={icon} className='icon' />
                {title}
            </div>
            <div className='actions'>
                {children}
                {!hideFilters && (
                    <StatusSelector
                        initialStatuses={initialStatuses}
                        onFilterChange={onFilterChange}
                    />
                )}
            </div>
        </div>
    );
};

export default Headline;
