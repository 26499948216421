import React, { useState, useEffect } from 'react';

import { Label } from '@components';
import { Dropdown } from '@components/bs';
import '@scss/modals/_quote-preview-selector-modal.scss';
import { useSelector } from 'react-redux';

const QuotePreviewSelectorModal = props => {
    const { quotePreview, onQuotePreviewChange } = props;

    const templates = useSelector(state => state.data.tc.templates);
    const [dropdownOptions, setDropdownOptions] = useState([]);
    useEffect(() => {
        onQuotePreviewChange(null);
        const value = [...templates.map(el => ({ _id: el._id, value: el._id, label: el.templateName, isDefault: el.defaultTemplate }))];
        const defaultTemplate = value.find(el => el.isDefault);
        if (defaultTemplate) {
            onQuotePreviewChange(defaultTemplate);
        }
        setDropdownOptions(value);
    }, []);

    return (
        <div className='QuotePreviewSelectorModal'>
            <div className='row mt-1 option-list'>
                <div className='w-full'>
                    <label
                        style={{
                            fontSize: '13px',
                            fontWeight: '500',
                            lineHeight: '15px',
                            letterSpacing: '1.2px',
                            color: '#6e8ca3',
                            marginBottom: '10px',
                            height: '15px'
                        }}>
                        SELECT T&Cs
                    </label>
                    <Dropdown
                        placeholder='Quote PDF'
                        selected={quotePreview?.template[0]}
                        items={dropdownOptions.map(op => ({ _id: op._id, label: op.label, value: op }))}
                        onChange={onQuotePreviewChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default QuotePreviewSelectorModal;
