import BSButton from 'react-bootstrap/Button';

import styles from './styles.module.scss';

const BUTTON_VARIANT_LIST = {
    primary: styles.button__primary,
    'outline-light': styles.button__outline_light
};

export const Button = props => {
    const { children, className = '', type = 'button', variant = 'primary', ...restProps } = props;

    return (
        <BSButton variant={variant} type={type} className={`bs-btn ${styles.button} ${BUTTON_VARIANT_LIST[variant] || ''} ${className}`} {...restProps}>
            {children}
        </BSButton>
    );
};
