export const getPropertySafely = (obj, path, fallback) => {
    const dot = path?.indexOf('.');
    if (obj === undefined) {
        return fallback || undefined;
    }

    if (dot === -1) {
        if (obj && path.length && path in obj) {
            return obj[path];
        }
        return fallback || undefined;
    }

    return getPropertySafely(obj[path?.substr(0, dot)], path?.substr(dot + 1), fallback);
};

export const isEmpty = obj => {
    return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
};

export const deepEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            areObjects && !deepEqual(val1, val2) ||
            !areObjects && val1 !== val2
        ) {
            return false;
        }
    }
    return true;
};

export const isObject = (object) => {
    return object != null && typeof object === 'object';
};

export const assign = (obj, keyPath, value) => {
    const lastKeyIndex = keyPath.length - 1;

    for (const i = 0; i < lastKeyIndex; ++i) {
        const key = keyPath[i];
        if (!(key in obj)) {
            obj[key] = {}
        }
        obj = obj[key];
    }

    obj[keyPath[lastKeyIndex]] = value;
};
