import React, { useMemo } from 'react';
import '@scss/components/_tier-level-graph.scss';
import { useState, useEffect } from 'react';
import { useRef } from 'react';

const ZONE1_COLOR = '#f5faff';
const ZONE2_COLOR = '#e0f1ff';
const ZONE3_COLOR = '#cce8ff';
const ZONE4_COLOR = '#b8dfff';
const ZONE5_COLOR = '#a3d6ff';

const W = 520;
const H = 110;
const WXS = 30;
const WXE = 505;
const WYS = 10;
const WYE = 105;
const LAST_ZONE_WIDTH = 75;
const ZONEW = WXE - WXS - LAST_ZONE_WIDTH;
const DRAG_MARKER_W = 10;

const ZONE_COLORS = [ZONE1_COLOR, ZONE2_COLOR, ZONE3_COLOR, ZONE4_COLOR, ZONE5_COLOR];

const TierLevelGraphSvg = ({
    data = [],
    tierBands,
    onUpdateTiersBands = () => { },
    onMarkerClick = () => { }
}) => {

    const [tiersScale, setTiersScale] = useState(1);
    const [minZoneWidth, setMinZoneWidth] = useState(tierBands[4] * 0.05);
    const [tiers, setTiers] = useState(tierBands);
    const [dataScale, setDataScale] = useState({ x1: 0, x2: 0, y: 0, ymax: 0 });
    const [samples, setSamples] = useState(data);
    const [offset, setOffset] = useState(0);
    const [dragIndex, setDragIndex] = useState();
    const [scale, setScale] = useState(1);

    const svgElement = useRef();

    useEffect(() => {
        const onResize = () => {
            const { width } = svgElement.current.getBoundingClientRect() || 0;
            setScale(W / width);
        };
        window.addEventListener('resize', onResize);
        onResize();
        return () => window.removeEventListener('resize', onResize);
    }, []);

    useEffect(() => {
        setTiers(tierBands);
        setTiersScale(ZONEW / tierBands[4]);
        setMinZoneWidth(tierBands[4] * 0.05);
    }, [tierBands]);

    useEffect(() => {
        const minMax = findMinMax;
        const ds = {
            x1: ZONEW / tierBands[4],
            x2: LAST_ZONE_WIDTH / ((minMax.maxX - tierBands[4]) * 1.05),
            y: (WYE - WYS) / (minMax.maxY * 1.05),
            ymax: minMax.maxY
        };
        setDataScale(ds);
    }, [data]);

    const onMouseDown = evt => {
        if (evt.target.classList.contains('draggable')) {
            setDragIndex(parseInt(evt.target.id));
            const tmp = getMousePosition(evt);
            setOffset({ x: tmp.x - parseFloat(evt.target.getAttributeNS(null, 'x')) });
        } else if (evt.target.classList.contains('marker')) {
            onMarkerClick(evt);
        }
    };

    const onMouseMove = e => {
        e.preventDefault();
        if (dragIndex != -1) {
            var coord = getMousePosition(e);
            const newX = coord.x - offset.x - WXS + DRAG_MARKER_W / 2;
            const newValue = Math.round(newX / tiersScale);
            if (newValue > tiers[dragIndex - 1] + minZoneWidth && newValue < tiers[dragIndex + 1] - minZoneWidth) {
                const tmp = [...tiers];
                tmp[dragIndex] = newValue;
                setTiers(tmp);
            }
        }
    };

    const onMouseUp = () => {
        if (dragIndex != -1) {
            onUpdateTiersBands([...tiers]);
            setDragIndex(-1);
        }
    };

    const findMinMax = useMemo(() => {
        let minX = Number.MAX_SAFE_INTEGER,
            maxX = 0,
            minY = Number.MAX_SAFE_INTEGER,
            maxY = 0;
        for (const sample of data) {
            if (sample[0] < minX) {
                minX = sample[0];
            }
            if (sample[0] > maxX) {
                maxX = sample[0];
            }
            if (sample[1] > maxY) {
                maxY = sample[1];
            }
            if (sample[1] < minY) {
                minY = sample[1];
            }
        }
        return { minX, maxX, minY, maxY };
    }, [data]);

    const getMousePosition = evt => {
        if (svgElement.current) {
            var CTM = svgElement.current.getScreenCTM();
            return {
                x: (evt.clientX - CTM.e) / CTM.a,
                y: (evt.clientY - CTM.f) / CTM.d
            };
        }
    };

    const formatXAxisLabel = val => {
        if (val == 0) {
            return '';
        }
        if (val > 1000000) {
            return (val / 1000000).toFixed(1) + 'm';
        } else if (val > 100000) {
            return '0.' + (val / 10000).toFixed(0) + 'm';
        } else if (val > 10000) {
            return (val / 1000).toFixed(1) + 'k';
        } else {
            return (val / 1000).toFixed(2) + 'k';
        }
    };

    const formatYAxisLabel = val => {
        if (val == 0) {
            return '';
        }
        if (val > 1000000) {
            return (val / 1000000).toFixed(0) + 'm';
        } else if (val > 100000) {
            return '0.' + (val / 10000).toFixed(0) + 'm';
        } else if (val > 10000) {
            return (val / 1000).toFixed(0) + 'k';
        } else if (val > 1000) {
            return (val / 1000).toFixed(0) + 'k';
        } else {
            return (val).toFixed(0)
        }
    };

    return (
        <div className='TierLevelGraph'>
            <svg ref={svgElement} className='TierLevelGraph' width='100%' height={H / scale} viewBox={`0 0 ${W} ${H}`}>
                <defs>
                    <path
                        className='marker'
                        id='marker'
                        d='M5.5 10C7.98528 10 10 7.98528 10 5.5C10 3.01472 7.98528 1 5.5 1C3.01472 1 1 3.01472 1 5.5C1 7.98528 3.01472 10 5.5 10Z'
                        fill='#0E3F66'
                        stroke='white'
                        strokeWidth='2'
                    />
                </defs>
                <g onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={onMouseUp}>
                    {/* Mouse event area    */}
                    <rect x='0' y='0' height={H} width={W} fill='transparent' />
                    {/* Zone painting   */}
                    {tiers.map((tier, idx) =>
                        idx < 4 ? (
                            <rect
                                key={idx}
                                x={WXS + tier * tiersScale}
                                y={WYS}
                                height={WYE - WYS}
                                width={(tiers[idx + 1] - tier) * tiersScale}
                                fill={ZONE_COLORS[idx]}
                            />
                        ) : (
                            <rect
                                key={idx}
                                x={WXE - LAST_ZONE_WIDTH}
                                y={WYS}
                                height={WYE - WYS}
                                width={LAST_ZONE_WIDTH}
                                fill={ZONE_COLORS[idx]}
                            />
                        )
                    )}

                    {/* Horizontal grid   */}
                    {[...Array(5)].map((e, i) => (
                        <g key={i}>
                            <line
                                x1='10'
                                y1={WYS + (i * (WYE - WYS)) / 5}
                                x2={WXE}
                                y2={10 + (i * (WYE - WYS)) / 5}
                                stroke='white'
                            />
                            <g transform={`translate(0,${WYS + 5 + (i * (WYE - WYS)) / 5})`}>
                                <text className='value-text' textAnchor='left' transform={`scale(${scale})`}>
                                    {formatYAxisLabel(dataScale.ymax - (i * dataScale.ymax) / 5)}
                                </text>
                            </g>
                        </g>
                    ))}

                    {/* Transparent dragging points */}
                    {tiers.map((tier, idx) => {
                        if (idx > 0 && idx < 4) {
                            return (
                                <rect
                                    key={idx}
                                    id={idx}
                                    className='draggable'
                                    x={WXS - DRAG_MARKER_W / 2 + tier * tiersScale}
                                    y={WYS}
                                    height={WYE - WYS}
                                    width={DRAG_MARKER_W}
                                    fill='transparent'
                                />
                            );
                        }
                    })}

                    {/* Text    */}
                    {tiers.map((tier, idx) => (
                        <g key={idx} transform={`translate(${WXS + tier * tiersScale},${H})`}>
                            <text key={idx} className='value-text' textAnchor='middle' transform={`scale(${scale})`}>
                                {formatXAxisLabel(tier)}
                            </text>
                        </g>
                    ))}
                    <g transform={`translate(${WXE},${H})`}>
                        <text className='value-text' textAnchor='middle' transform={`scale(${scale})`}>
                            {formatXAxisLabel(findMinMax.maxX)}
                        </text>
                    </g>

                    {/* Sample  */}
                    {dataScale &&
                        data &&
                        data.length > 0 &&
                        data.map((sample, idx) => {
                            const x = sample[0] > tierBands[4] ? (sample[0] - tierBands[4]) * dataScale.x2 + WXE - WXS - LAST_ZONE_WIDTH : sample[0] * dataScale.x1;
                            return (
                                <g
                                    key={idx}
                                    className="marker"
                                    transform={`translate(${WXS + x - 1.5}, ${WYS + WYE - WYS - 1.5 - sample[1] * dataScale.y
                                        })`}>
                                    <use
                                        key={idx}
                                        className='marker'
                                        href='#marker'
                                        id={idx}
                                        transform={`scale(${scale})`}
                                    />
                                </g>
                            );
                        })}
                </g>
            </svg>
        </div>
    );
};

export default TierLevelGraphSvg;
