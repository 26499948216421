import styled from 'styled-components';

const QuoteDetailsStyles = styled.div`
    .Transport_Panel {
        width: 100%;

        .map-container {
            position: relative;
            height: 550px;

            &__overview {
                position: absolute;
                display: flex;
                justify-content: space-between;
                padding: 10px;
                top: 20px;
                left: 20px;
                background: white;
                height: 100px;
                width: 500px;
                height: 80px;
                border-radius: 5px;
                box-shadow: 0px 13px 30px rgba(0, 0, 0, 0.15);

                .Label {
                    margin-bottom: 0;
                }

                .vert-separator {
                    border-right: 1px solid #e0e7ff;
                }

                &--value {
                    color: #404040;
                    font-size: 28px;
                    font-family: 'Rubik';
                }

                &--unit {
                    color: #9f9f9f;
                    margin-left: 5px;
                }
            }
        }

        .separator {
            width: 90%;
            border-bottom: 1px solid #e0e7ff;
            margin-top: 30px;
        }

        .field {
            position: relative;
            &--unit {
                position: absolute;
                background-color: #dbedff;
                border: 1px solid #e0e7ff;
                border-radius: 0px 5px 5px 0px;
                right: 8px;
                top: 26px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 38px;
                // padding-left: 4px;
                width: 51px;
            }
            .unit--disabled {
                background: #e8e9eb;
                height: 38px;
                border-left: 1px solid #f4f5f7;
                font-weight: 500;
                font-size: 15px;
                line-height: 18px;
                text-align: center;
                color: #C7C7C7;
            }
        }

        .truck-selector {
            position: relative;
            .ton-range {
                font-size: 14px;
                font-weight: 500;
                color: #979797;
                position: absolute;
                right: 67px;
                top: 34px;
            }
            .TransportPanel {
                flex: 1;
            }
            .TransportPanelLarge {
                flex: 2;
            }
            .TransportPanelSmall {
                flex: 0.5;
            }
            .TransportPanelCheckbox {
                display: flex;
                align-items: center;
            }
            .round-up {
                color: #6e8ca3;
                font-family: Rubik;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-left: 10px;
            }
        }

        .footer {
            height: 100px;
            padding: 20px;
            display: flex;
            justify-content: space-between;
            margin-top: 50px;

            &--label {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;

                h2 {
                    display: flex;
                    margin-left: 10px;
                }
            }

            &--middle {
                width: 100%;
            }

            &--sum {
                position: relative;

                .calculated {
                    font-size: 14px;
                    font-weight: 500;
                    color: #979797;
                    position: absolute;
                    right: 20px;
                    bottom: 5px;
                }

                .TextField {
                    width: 220px;
                }
            }

            .TextField {
                border-bottom: 1px solid #6e8ca3;
                border-radius: 0;

                .TextField {
                    border-bottom: none;
                }

                &__input {
                    background: inherit;
                    font-size: 28px;
                    width: 140px;

                    &--normal {
                        border: none;
                        &:hover {
                            border: none;
                        }
                        &:focus-within {
                            box-shadow: none;
                        }
                    }
                }
            }

            .Label {
                margin-bottom: 5px;
            }
        }
    }
`

export default QuoteDetailsStyles