import service from '@service/service';;

import * as types from './types';

const fetchListGeoZones = (params = {}) => async dispatch => {
    dispatch({ type: types.FETCH_LIST_GEO_ZONES });
    try {
        const geoZones = await service.get('/geo-zones', { params });
        dispatch({ type: types.FETCH_LIST_GEO_ZONES_SUCCESS, payload: geoZones.data });
    } catch (error) {
        dispatch({ type: types.FETCH_LIST_GEO_ZONES_FAIL, payload: error?.response?.data });
    }
};


const savedGeoZone = (geoZone) => async dispatch => {
    dispatch({ type: types.ADD_GEO_ZONE });
    try {
        dispatch({ type: types.ADD_GEO_ZONE_SUCCESS, payload: geoZone });
    } catch (error) {
        dispatch({ type: types.ADD_GEO_ZONE_FAIL });
    }
}

const addGeoZone = (params = {}) => async dispatch => {
    dispatch({ type: types.ADD_GEO_ZONE });
    try {
        const geoZones = await service.post('/geo-zones', params);
        dispatch({ type: types.ADD_GEO_ZONE_SUCCESS, payload: geoZones.data });
    } catch (error) {
        dispatch({ type: types.ADD_GEO_ZONE_FAIL });
    }
};

const editGeoZone = (id, params = {}) => async dispatch => {
    dispatch({ type: types.EDIT_GEO_ZONE, payload: { id, params } });
    try {
        const geoZones = await service.put(`/geo-zones/${id}`, params);
        dispatch({ type: types.EDIT_GEO_ZONE_SUCCESS, payload: geoZones.data });
    } catch (error) {
        dispatch({ type: types.EDIT_GEO_ZONE_FAIL });
    }
};

const deleteGeoZones = id => dispatch => {
    dispatch({ type: types.DELETE_GEO_ZONE, payload: id });
    const listDeletePromise = [];
    id.forEach(i => {
        listDeletePromise.push(service.delete(`/geo-zones/${i}`));
    });
    Promise.all(listDeletePromise)
        .then(res => {
            const deletedElement = res.map(r => r.data._id);
            dispatch({ type: types.DELETE_GEO_ZONE_SUCCESS, payload: deletedElement });
        })
        .catch(err => {
            dispatch({ type: types.DELETE_GEO_ZONE_FAIL });
        });
};

const clearError = () => dispatch => {
    dispatch({ type: types.CLEAR_ERROR });
};

export { fetchListGeoZones, savedGeoZone, addGeoZone, editGeoZone, deleteGeoZones, clearError };
