import { ComboBox } from '@progress/kendo-react-dropdowns'

import { createGlobalStyle } from 'styled-components'

import { TIER_OPTIONS } from 'views/Pages/MasterData/Customers/const'

const CustomerTierModal = ({ tier, updateTier }: { tier: string; updateTier: (value: string) => void }) => (
    <>
        <GlobalStyles />
        <ComboBox
            id='edit-tier'
            placeholder='Select tier'
            size='large'
            textField='label'
            dataItemKey='value'
            value={TIER_OPTIONS.find(x => x.value === tier) ?? null}
            data={TIER_OPTIONS}
            onChange={(e: { target: { value: { value: string } } }) => updateTier(e.target?.value?.value || '')}
            suggest
        />
    </>
)

export default CustomerTierModal

const GlobalStyles = createGlobalStyle`
    .k-animation-container {
        z-index: 2000 !important;
    }
`
