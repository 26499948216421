import { PropsWithChildren } from 'react'
import styled from 'styled-components'

type QuoteLinesHeaderItem = {
    label: any,
    style?: React.CSSProperties
}

import Dropdown from '@components/UI/Dropdown'

const PlantsInfo = ({plants=[]}: any) => {
    const options = plants.map((p: any)=>({label:p.name, value: p._id}))
    return (
        <>
            {
                options.length === 1 ?
                    <h2 className='font-medium text-gray-300'>{options[0].label}</h2>
                    :   <Dropdown
                            renderValue={({label}: any)=>(<h2 className='font-medium text-gray-300'>{label}</h2>)}
                            placeholder='Various'
                            options={ options }
                            style = { {border: 'none', background: 'none', height: 'auto', paddingLeft: 0, zIndex: 3} }
                            iconWrapperStyle = { {border: 'none', background: 'none', minHeight: 0} }
                            freeze = { true }
                        />
            }

        </>
    )
}

export const QuoteLinesHeaderItem = ({label, children, style}: PropsWithChildren<QuoteLinesHeaderItem>) => {
    return (
        <Wrapper style={style}>
            <Label>
                { label }
            </Label>
            <Content>
                { children }
            </Content>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
`

const Label = styled.div`
    color: #6e8ca3;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
`

const Content = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-top: 5px;
`

const Divider = styled.div`
    height: 100%; 
    border-left: 1px solid #e0e7ff;
    margin: 0 50px;
`

export default { Divider, QuoteLinesHeaderItem, PlantsInfo }
