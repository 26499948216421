import { Link } from 'react-router-dom';

const NumberSeriesExpirationContent = () => {
    return (
        <span>
            Your number series have expired. <br />
            Please, update them in <Link to='/Setup'>settings</Link> ➝ number series section <br />
            <a href='https://tkb-international.gitbook.io/tkb-pricing-solution-guides/guides/settings/financial-management/number-series' target='_blank' rel='noreferrer'>
                Check out how here
            </a>{' '}
            or e-mail our support team at <a href='mailto:support@tkbinternational.com'>support@tkbinternational.com</a>
        </span>
    );
};

export default NumberSeriesExpirationContent;
