import { Entry } from '@customTypes/entry';
import { Quote } from '@customTypes/quote';
import getCostPerTon from '@utils/getCostPerTon';
import getTax from 'views/Pages/Project/utils/getTax';

function getTotal(entries: any, quote: Quote) {
    const total = entries.reduce((total: any, entry: Entry)=>{
        if(!entry.active) return total
        let { totalPickedUpPrice, totalDelivered, pickedUpPriceIncludingTax, deliveredIncludingTax, quantity } = total
        const uom = entry.uomValue || entry.uom?.value || entry.product.uom?.label || ''
        quantity[uom] = (quantity[uom] || 0) + (entry.qty || 0)
        const pickedUpPrice = ((entry.price || 0) * (entry.qty || 0))
        totalPickedUpPrice = totalPickedUpPrice + pickedUpPrice
        const costPerTon = getCostPerTon(entry, { plantsInfo: quote?.plantsInfo })
        const isDeliveredFlag = entry.delivered
        let delivered = (((costPerTon * (isDeliveredFlag? 1 : 0)) + (entry.price || 0)) * (entry.qty || 0))
        if(entry.calculation) delivered = pickedUpPrice
        totalDelivered =  totalDelivered + delivered
        const tax = getTax(entry, quote)
        pickedUpPriceIncludingTax = pickedUpPriceIncludingTax + ( pickedUpPrice * (entry.taxApplied ? 1 + (tax || 0) : 1) )
        deliveredIncludingTax = deliveredIncludingTax + ( delivered * (entry.taxApplied ? 1 + (tax || 0) : 1) )
        return { totalPickedUpPrice, totalDelivered, pickedUpPriceIncludingTax, deliveredIncludingTax, quantity }
    }, {
        totalPickedUpPrice: 0,
        totalDelivered: 0,
        pickedUpPriceIncludingTax: 0,
        deliveredIncludingTax: 0,
        quantity: {}
    }) || { quantity: {} }
    return total
}

export default getTotal