import { useEffect } from 'react'

import { useLocation, useParams } from 'react-router-dom'

import { setAuthToken } from '@service/service'

import { Spinner } from '@components'

const QuoteApproval = () => {
    const { id } = useParams<{ id: string }>()

    const location = useLocation()
    const status = new URLSearchParams(location.search).get('status')
    const token = new URLSearchParams(location.search).get('token')
    const approver = new URLSearchParams(location.search).get('approver')

    useEffect(() => {
        if (token) {
            setAuthToken(token)
            localStorage.setItem('token', token)
            window.location.replace(`/quote/${id}?status=${status}&approver=${approver}`)
        }
    }, [id, token, status])

    return <Spinner />
}

export default QuoteApproval
