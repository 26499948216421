export const FILTER_BY_OPTIONS = {
    QUOTES: {
        label: 'All Quotes',
        value: 'QUOTES'
    },
    PROJECTS: {
        label: 'Projects Quotes',
        value: 'PROJECTS'
    }
};
