import { useEffect, useState } from 'react';
import { fetchEntities } from 'service/entitiesApi';

export const useEntityManager = key => {
    const [entities, setEntities] = useState([]);
    const [entityGroupByKey, setEntityGroupByKey] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const getEntitySettings = async (key) => {
        setIsLoading(true);
        const quoteEntities = await fetchEntities(key, {
            filterColumns: 'status',
            filterValues: 'active'
        });
        setEntities(quoteEntities);

        quoteEntities.items.forEach(e => {
            entityGroupByKey[e.originalFieldName] = e;
        });

        setEntityGroupByKey(entityGroupByKey);
        setIsLoading(false);

        return entityGroupByKey;
    };

    useEffect(() => {
        if (!key) {
            return;
        }
        getEntitySettings(key);
    }, [key]);

    return {
        isLoading,
        entities,
        entityGroupByKey,
        getEntitySettings,
    };
};
