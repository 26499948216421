import { Address } from "@customTypes/address";
import { PlantsInfo, QuoteTaxes } from "@customTypes/quoteProjectCommon";

type GetTaxDataProps = {plantsInfo: PlantsInfo[], address: Address | null, taxes: QuoteTaxes, taxesBasedOn: string}

function getTax(row: any, dataProps: GetTaxDataProps) {
    const plantId = row.plantInfo?.plant?._id || row.plantInfo?.plant || row.product?.plantInfo?.plant?._id;
    const plantInfo: PlantsInfo | undefined = dataProps?.plantsInfo?.find((p: any) => (p.plant?._id || p.plant) === plantId);
    const plantState = plantInfo?.plant?.address?.state?.split('-')[1]
    const address = dataProps.address
    const addressState = address?.state?.split('-')[1]
    const deliveredTaxRatesSum = dataProps?.taxes?.origin?.rates?.reduce((acc: number, rate: {rate: number, name: string})=>{
        if(addressState && rate.name.startsWith(addressState)) return Math.round((acc + rate.rate + Number.EPSILON) * 100000) / 100000
        else return acc
    }, 0);
    const posTaxRatesSum =plantInfo?.taxes?.origin?.rates?.reduce((acc: number, rate: {rate: number, name: string})=>{
        if(plantState && rate.name.startsWith(plantState)) return Math.round((acc + rate.rate + Number.EPSILON) * 100000) / 100000
        else return acc
    }, 0);
    const deliveredTax = deliveredTaxRatesSum || dataProps?.taxes?.origin?.totalRate;
    const posTax = posTaxRatesSum || plantInfo?.taxes?.origin?.totalRate;

    const taxesBasedOn = dataProps?.taxesBasedOn;
    if (taxesBasedOn === 'pos') return posTax;
    else if (taxesBasedOn === 'delivery') {
        if (row.delivered) return deliveredTax || 0;
        else return posTax;
    }
}

export function getFullTax(row: any, dataProps?: any) {
    const plantId = row.plantInfo?.plant?._id || row.plantInfo?.plant || row.product?.plantInfo?.plant?._id;
    const plantInfo = dataProps?.plantsInfo?.find((p: any) => p.plant?._id === plantId);

    const plantState = plantInfo?.plant?.address?.state?.split('-')[1]
    const address = dataProps.address
    const addressState = address?.state?.split('-')[1]

    const deliveredTaxRatesSum = dataProps?.taxes?.origin?.rates?.reduce((acc: number, rate: {rate: number, name: string})=>{
        if(addressState && rate.name.startsWith(addressState)) return Math.round((acc + rate.rate + Number.EPSILON) * 100000) / 100000
        else return acc
    }, 0);

    const posTaxRatesSum =plantInfo?.taxes?.origin?.rates?.reduce((acc: number, rate: {rate: number, name: string})=>{
        if(plantState && rate.name.startsWith(plantState)) return Math.round((acc + rate.rate + Number.EPSILON) * 100000) / 100000
        else return acc
    }, 0);

    let deliveredOriginTax = dataProps?.taxes?.origin;

    if(deliveredTaxRatesSum) {
        deliveredOriginTax = {
            totalRate: deliveredTaxRatesSum,
            rates: dataProps?.taxes?.origin?.rates?.filter((rate: any)=>rate.name.startsWith(addressState))
        }
    }

    let posOriginTax = plantInfo?.taxes?.origin;

    if(posTaxRatesSum) {
        posOriginTax = {
            totalRate: posTaxRatesSum,
            rates: plantInfo?.taxes?.origin?.rates?.filter((rate: any)=>rate.name.startsWith(plantState))
        }
    }

    const taxesBasedOn = dataProps?.taxesBasedOn;
    if (taxesBasedOn === 'pos') return posOriginTax;
    else if (taxesBasedOn === 'delivery') {
        if (row.delivered) return deliveredOriginTax || 0;
        else return posOriginTax;
    }
}

export default getTax;
