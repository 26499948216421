import React, { useEffect, useState, Suspense, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { Spinner, Divider } from '@components';

import Modal from './Modal';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

import { useGlobalModal, useHideGlobalModal } from './hooks';

import '@scss/components/_optionsModal.scss';

const GlobalModal = () => {
    const { modal } = useGlobalModal();
    const hideGlobalModal = useHideGlobalModal();
    const location = useLocation();
    const [modalContext, setModalContext] = useState({});

    const {
        title,
        name,
        body,
        width,
        isOpen,
        className = '',
        type = '',
        btnConfig = {},
        padding = true,
        footerContent = null,
        hideHeader = false,
        hideFooter = false,
        buttons = [],
        componentProps,
        LazyComponent
    } = modal;

    const globalModalRef = useRef(null);

    const updateModalContext = (state) => {
        setModalContext((prevState) => ({
            ...prevState,
            ...state
        }));
    };

    const resetModalContext = (state = {}) => {
        setModalContext(state);
    };

    const onCloseHandler = () => {
        if (typeof modal?.onCloseHandler === 'function') {
            modal.onCloseHandler();
            return;
        }

        hideGlobalModal();
    };

    const onCancelHandler = () => {
        if (typeof modal?.onCancelHandler === 'function') {
            modal.onCancelHandler();
            return;
        }

        hideGlobalModal();
    };

    const onSaveHandler = () => {
        if (typeof modal?.onSaveHandler === 'function') {
            modal.onSaveHandler();
            return;
        }

        hideGlobalModal();
    };

    const context = {
        value: modalContext,
        set: updateModalContext,
        reset: resetModalContext,
    };

    useEffect(() => {
        hideGlobalModal();
    }, [location]);

    if (!isOpen) {
        return null;
    }

    return (
        <Modal modalRef={globalModalRef} className={className} width={width}>
            {hideHeader !== true && (
                <>
                    <ModalHeader
                        onClose={onCloseHandler}
                        title={title}
                    />
                    <Divider />
                </>
            )}
            <ModalBody modalRef={globalModalRef} padding={padding}>
                <Suspense fallback={<Spinner />}>
                    {LazyComponent ? (
                        <LazyComponent {...componentProps} context={context} />
                    ) : (
                        body
                    )}
                </Suspense>
            </ModalBody>
            {!hideFooter && (
                <>
                    <Divider full />
                    <ModalFooter
                        name={name}
                        type={type}
                        content={footerContent}
                        context={context}
                        buttons={buttons}
                        customBtnConfig={btnConfig}
                        onCloseHandler={onCloseHandler}
                        onCancelHandler={onCancelHandler}
                        onContinueHandler={modal.onContinueHandler}
                        onDeleteHandler={modal.onDeleteHandler}
                        onSaveHandler={modal.onSaveHandler}
                        onApplyHandler={modal.onApplyHandler}
                    />
                </>
            )}
        </Modal>
    );
};

export default GlobalModal;
