import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useOnClickOutside } from 'utils/onClickOutside';

import Button from '../Button';

import '@scss/components/_popover.scss';

const Panel = styled.div`
    top: ${p => p.top}px !important;
    left: ${p => p.left}px !important;
    width: ${p => p.width}px !important;
    position: fixed;
    z-index: 100;
    box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 25%);
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: visible;
`;

export default function Popover(props) {
    const {
        element = null,
        elementWidth,
        options = null,
        popup = null,
        isSetup = false,
        popupLeft = null,
        popupWidth = null,
        parentComponent = null,
        openDirection = 'auto',
        style = {},
        extraStyle = {}
    } = props
    const [displayMenu, setDisplayMenu] = useState(false);
    const [location, setLocation] = useState({});

    const onRef = React.createRef();
    const boxRef = React.createRef();

    const [changedDomElements, setChangedDomElements] = useState([]);
    useEffect(() => {
        if (displayMenu) {
            const tmp = document.getElementsByClassName('overflow-overlay');
            if (tmp && HTMLCollection.prototype.isPrototypeOf(tmp)) {
                let changed = [];
                Array.from(tmp).forEach(element => {
                    if (!element.classList.contains('overflow-hidden')) {
                        changed.push(element);
                        element.classList.add('overflow-hidden');
                    }
                });
                setChangedDomElements(changed);
            }
        } else {
            changedDomElements.map(element => {
                element.classList.remove('overflow-hidden');
            });
        }
    }, [displayMenu]);

    useEffect(() => {
        if (displayMenu) {
            const coords = onRef.current.getBoundingClientRect();
            let tabPosition = { x: 0, y: 0 };
            if (parentComponent) {
                tabPosition = parentComponent.getBoundingClientRect();
            }
            let x = coords.x - tabPosition.x;
            let y = coords.y + coords.height + 5 - tabPosition.y;
            const menu = boxRef.current.getBoundingClientRect();
            if ((openDirection === 'auto') && ((coords.height + coords.y + menu.height) > window.innerHeight)) {
                y = y - menu.height - coords.height - 20;
            }
            if (coords.x + menu.width > window.innerWidth) {
                x = window.innerWidth - menu.width - 50;
                if (isSetup) {
                    x -= 370;
                }
            }
            setLocation({ x: popupLeft ? popupLeft : x, y, width: popupWidth ? popupWidth : coords.width });
        }
    }, [displayMenu]);

    const closeMenu = () => {
        setDisplayMenu(false);
    };

    if (onRef) {
        useOnClickOutside(onRef, () => {
            if (displayMenu) setDisplayMenu(false);
        });
    }

    const getConvertToId = (title) => {
        const lowerCaseText = title.toLowerCase().trim();
        return lowerCaseText.replace(/\s+/g, '-');
    }

    return (
        <div className='Popover' ref={onRef} style={{ width: elementWidth, ...style }}>
            <div
                className='cursor_pointer'
                style={{ height: '100%' }}
                onClick={() => {
                    const el = document.getElementById('search-contact');
                    if (el) {
                        el.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                            inline: 'center'
                        });
                    }
                    setDisplayMenu(!displayMenu);
                }}>
                {element}
            </div>
            {displayMenu && (
                <Panel left={location.x} top={location.y} width={location.width} ref={boxRef} nottoggle={'true'}>
                    {options
                        ? options.map((el, idx) => {
                            if (el.separator) {
                                if (!options[idx - 1]?.separator) {
                                    return <hr key={idx}></hr>;
                                } else {
                                    return '';
                                }
                            } else {
                                if (el.customRenderer) {
                                    return el.customRenderer(closeMenu);
                                } else {
                                    return (
                                        <Button
                                            disabled={Boolean(el?.disabled || false)}
                                            customClass='Popover__item btnold--norounded'
                                            style={{ width: popupWidth }}
                                            key={idx}
                                            ripple
                                            data-cy={`action-button-${getConvertToId(el.title)}`}
                                            onClick={() => {
                                                setDisplayMenu(false);
                                                setTimeout(() => {
                                                    el.action();
                                                }, 100);
                                            }}>
                                            <span className='title'>{el.title}</span>
                                        </Button>
                                    );
                                }
                            }
                        })
                        : popup}
                </Panel>
            )}
        </div>
    );
}
