import React from 'react';

const WithCondition = ({ condition, children }) => {
    if (condition) {
        return children;
    }
    return null;
};

export default WithCondition;
