import styled from 'styled-components'

const Summary = ({
    summary,
    uom
}: {
    summary: { totalTripTime: number; tripsPerTruck: number; trucksPerDay: number; totalNbOfTrips: number; qtyPerDay: number; nbOfTrips: number }
    uom: string
}) => {
    const ITEMS = [
        [
            { title: 'Total trip time', value: summary.totalTripTime, unit: 'mins' },
            { title: 'Trips per truck', value: summary.tripsPerTruck, unit: 'per day' },
            { title: 'Trucks per day', value: summary.trucksPerDay, unit: 'per day' }
        ],
        [
            { title: 'Total number of trips', value: summary.totalNbOfTrips, unit: 'per job' },
            { title: 'Qty per day', value: summary.qtyPerDay, unit: uom },
            { title: 'Number of trips', value: summary.nbOfTrips, unit: 'per day' }
        ]
    ]

    return (
        <Footer>
            {ITEMS.map(cells => (
                <Row key={cells[0].title}>
                    {cells.map(cell => (
                        <Item key={cell.title}>
                            <Title>{cell.title}</Title>
                            <Value>
                                {cell.value} {cell.unit}
                            </Value>
                        </Item>
                    ))}
                </Row>
            ))}
        </Footer>
    )
}

export default Summary

const Footer = styled.div`
    margin: 50px 0 50px 0;
    display: flex;
    flex-direction: column;
    gap: 50px;
`

const Row = styled.div`
    display: flex;
`

const Item = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 20px;
`

const Title = styled.span`
    color: #6e8ca3;
    font-family: Rubik;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.213px;
    text-transform: uppercase;
`

const Value = styled.span`
    color: var(--Text-Body, #404040);
    font-family: Rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 120% */
`
