import React, { useRef, useState } from 'react';

import { useOnClickOutside } from 'utils/onClickOutside';

import '@scss/components/_formrow-action.scss';

const FormRowAction = ({ children, onClick, style, error }) => {
    const [active, setActive] = useState(false);
    const onRef = useRef();

    if (onRef) {
        useOnClickOutside(onRef, () => {
            if (active) setActive(false);
        });
    }

    const handleClick = () => {
        if (onClick) onClick();
        setActive(true);
    };

    return (
        <div style={style} ref={onRef} onClick={(e) => handleClick()} className={`formrow-action-container ${active ? 'formrow-action-container-active' : ''} ${error ? 'formrow-action-container-error' : ''}`}>
            {children}
        </div>
    );
};

export default FormRowAction;
