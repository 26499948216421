import { Popover } from '@nextui-org/react'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import filterIcon from '@assets/icons/filter.svg'
import { IconWrapper } from './FilterDropdown/IconWrapper'
import { Button, Checkbox } from '@common'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

interface OptionItem {
    label: string
    value: string
}

interface CheckboxFilterProps {
    value: string[]
    options: OptionItem[]
    onSubmit: (value: string[], event: React.ChangeEvent<HTMLSelectElement>) => void
}

export const CheckboxFilter: React.FC<CheckboxFilterProps> = ({ options, onSubmit, value = [] }) => {
    const [isOpen, setIsOpen] = useState(false)
    const checkboxRef = React.useRef<HTMLSelectElement>(null)
    const [checkedList, setCheckedList] = React.useState<string[]>(value || [])
    const [listChanged, setListChanged] = React.useState<boolean>(false)

    useEffect(() => {
        setListChanged(true)
    }, [checkedList])

    // we need to create a fake change event because that stupid kendo UI needs one.
    const onClickFilter: React.MouseEventHandler<HTMLButtonElement> = event => {
        event.stopPropagation()
        event.preventDefault()
        if (!checkboxRef.current) return
        const newEvent = new Event('change', { bubbles: true })
        checkboxRef.current.dispatchEvent(newEvent)
    }

    // this is used to handle the fake change event to submit the values!
    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.stopPropagation()
        event.preventDefault()
        onSubmit(checkedList, event)
        setListChanged(false)
    }

    const onClick = useCallback((event: CheckboxChangeEvent) => {
        const { name, checked } = event.target
        if (checked) {
            setCheckedList(prevValue => [...prevValue, name as string])
        } else {
            setCheckedList(prevValue => prevValue.filter(v => v !== name))
        }
    }, [])

    const onSelectAll = useCallback((e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            setCheckedList(options.map(({ value }) => value))
        } else {
            setCheckedList([])
        }
    }, [])

    const onClickClear: React.MouseEventHandler<HTMLButtonElement> = useCallback(event => {
        event.stopPropagation()
        event.preventDefault()
        setCheckedList([])
    }, [])

    return (
        <Popover offset={4} shouldFlip isOpen={isOpen} onOpenChange={e => setIsOpen(e)} data-cy='dropdown'>
            <Popover.Trigger>
                <IconContainer>
                    <IconWrapper data-cy='dropdown-trigger'>
                        <FilterIcon src={filterIcon} />
                        <InnerSelect ref={checkboxRef} onChange={handleSelectChange}>
                            <option value={'true'}></option>
                            <option value={'false'}></option>
                        </InnerSelect>
                    </IconWrapper>
                </IconContainer>
            </Popover.Trigger>
            <Popover.Content css={{ borderRadius: '5px', maxHeight: 400 }}>
                <Content>
                    <FirstRow>
                        <CheckboxWrapper>
                            <Checkbox onChange={onSelectAll} checked={options.length === checkedList.length} />
                            <Label>Select all</Label>
                        </CheckboxWrapper>
                        <Highlight>{highlitedLabel(checkedList.length)}</Highlight>
                    </FirstRow>
                    <CheckBoxRows value={checkedList} options={options} onClick={onClick} />
                    <ButtonContainer>
                        <Button bordered type='button' onClick={onClickClear}>
                            Clear
                        </Button>
                        <Button type='button' onClick={onClickFilter} disabled={!listChanged}>
                            Filter
                        </Button>
                    </ButtonContainer>
                </Content>
            </Popover.Content>
        </Popover>
    )
}

const highlitedLabel = (checkedCount: number) => {
    if (checkedCount > 1) {
        return `${checkedCount} items selected`
    }
    return `${checkedCount} item selected`
}

interface CheckboxRowsProps {
    value: string[]
    options: OptionItem[]
    onClick: (event: CheckboxChangeEvent) => void
}

const CheckBoxRows: React.FC<CheckboxRowsProps> = ({ onClick, options, value }) => {
    return (
        <RowsContainer>
            {options.map(({ label, value: v }) => {
                return (
                    <Row key={v}>
                        <CheckboxFilterRow label={label} value={v} checked={value && value.includes(v)} onClick={onClick} />
                    </Row>
                )
            })}
        </RowsContainer>
    )
}

const RowsContainer = styled.div`
    flex-grow: 1;
    overflow-y: auto;
`

interface CheckboxFilterRowProps {
    label: string
    value: string
    checked?: boolean
    onClick?: (event: CheckboxChangeEvent) => void
}

const CheckboxFilterRow: React.FC<CheckboxFilterRowProps> = ({ label, value, checked, onClick }) => {
    return (
        <CheckboxWrapper>
            <Checkbox checked={checked} onChange={onClick} name={value} />
            <Label>{label}</Label>
        </CheckboxWrapper>
    )
}

const InnerSelect = styled.select`
    display: none;
`

const IconContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

const Content = styled.div`
    max-height: 317px;
    min-width: 200px;
    width: 310px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    overflow: hidden;
`

const FilterIcon = styled.img`
    width: 20px;
    height: 20px;
`

const Row = styled.div`
    padding: 5px 15px;
    height: 38px;
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: space-between;
`

const FirstRow = styled(Row)`
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`

export const CheckboxWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 10px;
`

const Label = styled.div`
    font-size: 15px;
    display: flex;
    align-items: center;
`

const Highlight = styled.span`
    height: 28px;
    padding: 5px 10px;
    background-color: #dbedffbf;
    color: #0e3f66;
    border-radius: 4px;
    font-weight: 500;
    font-size: 15px;
    line-height: 17.78px;
`

const ButtonContainer = styled.div`
    height: 52px;
    padding: 0 10px;
    // top shadow
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;

    & > button {
        flex-grow: 1;
        justify-content: center;
    }
`
