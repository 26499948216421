import React, { useState } from 'react'
import styled from 'styled-components'
import { Space } from 'antd'
import { Switch } from '@components/bs'
import { useNotify } from '@components';
import { Button, Dropdown } from '@common'
import { ENABLE_TENANT_DOMAIN_EMAIL, ENABLE_USER_EMAIL, USER_SENT_EMAIL } from 'consts/settings';
import { useQueryClient } from '@tanstack/react-query';
import { saveFinancialSetting } from '@service/financialsApi';
import { useFinancialSettings } from '@hooks/useFinancialSettings';
import AuthService from '@service/auth.service';

export const USER_CREATED_EMAIL = 'createdBy'
export const USER_RESPONSIBLE_EMAIL = 'userResponsible'

const TENANT_EMAILS: any = {
    'Wayne Davis Concrete': '@waynedavisconcrete.com',
    'Peckham': '@peckham.com',
    'KMM': '@kraemermm.com',
    'DEMO': '@yourdomain',
    'Solterra': '@yourdomain'
}

const SentDomain = () => {
    const enableTenantDomainEmail = useFinancialSettings(ENABLE_TENANT_DOMAIN_EMAIL);
    const enableUserEmail = useFinancialSettings(ENABLE_USER_EMAIL);
    const userEmail = useFinancialSettings(USER_SENT_EMAIL);
    const [enableSentFromTenantDomain, setEnableSentFromTenetDomain] = useState(JSON.parse(enableTenantDomainEmail))
    const [enableSentFromUserEmail, setEnableSentFromUserEmail] = useState(JSON.parse(enableUserEmail))
    const [userSentEmail, setUserEmail] = useState(userEmail)
    const { notifyError, notifySuccess } = useNotify();

    const queryClient = useQueryClient()
    const onSaveClick = async () => {
        saveFinancialSetting([
            { key: ENABLE_TENANT_DOMAIN_EMAIL, value: enableSentFromTenantDomain },
            { key: ENABLE_USER_EMAIL, value: enableSentFromUserEmail },
            { key: USER_SENT_EMAIL, value: userSentEmail },
        ])
            .then(() => {
                queryClient.invalidateQueries(['financial-settings']);
                notifySuccess('Values are updated.');
            })
            .catch(err => {
                notifyError(err);
            });
    };

    const handleSwitch = (type: string, value: boolean) => {
        const onSavePrimary = type == 'domain' ? setEnableSentFromTenetDomain : setEnableSentFromUserEmail
        const onSaveSecondary = type == 'domain' ? setEnableSentFromUserEmail : setEnableSentFromTenetDomain
        if (value) {
            onSavePrimary(value)
            onSaveSecondary(!value)
        } else {
            onSavePrimary(value)
        }
    }

    return (
        <FieldManagementSpace>
            <Space direction='vertical'>
                <Headline>Send quote mails from no-reply{TENANT_EMAILS[AuthService?.user?.tenant?.name]}</Headline>
                <Switch
                    type='switch'
                    id='domain-sent'
                    label='Feature on/off'
                    className='text-uppercase'
                    checked={enableSentFromTenantDomain}
                    onChange={(event: any) => handleSwitch('domain', event.target.checked)}
                />
                <Subtitle>This would turn on sending all quote mails from no-reply{TENANT_EMAILS[AuthService?.user?.tenant?.name]}</Subtitle>
            </Space>
            <Space direction='vertical' size='middle'>
                <Space direction='vertical'>
                    <Headline>Send quote mails from user's mail</Headline>
                    <Switch
                        type='switch'
                        id='user-sent'
                        label='Feature on/off'
                        className='text-uppercase'
                        checked={enableSentFromUserEmail}
                        onChange={(event: any) => handleSwitch('user', event.target.checked)}
                    />
                    <Subtitle>This would turn on sending all quote mails from user's mail</Subtitle>
                </Space>
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Dropdown
                        value={userSentEmail}
                        options={[{ label: 'User Responsible', value: USER_RESPONSIBLE_EMAIL }, { label: 'User created', value: USER_CREATED_EMAIL }]}
                        onChange={(val: string) => setUserEmail(val)}
                    />
                </Space>
            </Space>
            <Space>
                <Button onClick={onSaveClick}>Save</Button>
            </Space>
        </FieldManagementSpace>
    )
}

const Headline = styled.h2`
font-size: 1.6rem;
font-weight: 500;
font-family: 'Rubik', sans-serif !important;
text-transform: uppercase;
color: #0e3f66;
border-bottom: 1px solid rgba(0, 0, 0, 0.1);
margin-bottom: 1rem;
padding-bottom: 1rem;
`

const FieldManagementSpace = styled.div`
background-color: #ffffff;
margin-top: 2rem;
padding: 3rem;
border-radius: 0.5rem;
display: flex;
flex-direction: column;
gap: 50px
`;

const Subtitle = styled.span``

export default SentDomain