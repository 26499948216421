import * as types from './types';
import { _cloneDeep } from "../../../../utils/jsonUtil";

const initialState = {
    pricePlanning: [],
    details: { count: 0, filter: '', limit: 0, page: 0, pages: 0 },
    sending: false,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_LIST_PRICE_PLANNING:
            return {
                ...state,
                sending: true
            };
        case types.FETCH_LIST_PRICE_PLANNING_SUCCESS:
            return {
                ...state,
                pricePlanning: action.payload.items,
                details: action.payload.details,
                sending: false
            };
        case types.FETCH_LIST_PRICE_PLANNING_FAIL:
            return {
                ...state,
                sending: false
            };
        default:
            return state;
    }
};

export default reducer;
