import * as types from './types';

const initialState = {
    plants: [],
    defaultPlant: null,
    details: { count: 0, filter: '', limit: 0, page: 0, pages: 0 },
    loading: false,
		empty: false,
    error: ''
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_LIST_PLANTS:
            return {
                ...state,
                loading: true,
								empty: false
            };
        case types.FETCH_LIST_PLANTS_SUCCESS:
            return {
                ...state,
                plants: action.payload.items,
                details: action.payload.details,
								empty: !Boolean(action?.payload?.items?.length),
                loading: false
            };
        case types.FETCH_LIST_PLANTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case types.FETCH_DEFAULT_PLANT:
            return {
                ...state,
                loading: true
            };
        case types.FETCH_DEFAULT_PLANT_SUCCESS:
            return {
                ...state,
                defaultPlant: action.payload,
                loading: false
            };
        case types.FETCH_DEFAULT_PLANT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case types.CLEAR_ERROR:
            return {
                ...state,
                error: ''
            }
        default:
            return state;
    }
};

export default reducer;
