import React, { useState, useEffect } from 'react';
import '../../../scss/components/_tags.scss';
import { useOnClickOutside } from '../../../utils/onClickOutside';
import Feather from '../Feather/index.js';

MultiSelect.defaultProps = {
    clearable: true,
    singleSelect: false,
    jsonValue: false,
    defaultValue: '',
    disableChip: false,
    name: '',
    disabled: false,
    onAddTag: () => {},
    limit: null,
    placeholder: '',
    onChange: () => {},
    options: [
        {
            label: 'Empty',
            value: '',
            disabled: true,
            style: { textAlign: 'center' }
        }
    ]
};

function MultiSelect({
    options,
    width,
    closeIcon,
    clearable,
    onChange,
    singleSelect,
    jsonValue,
    defaultValue,
    className,
    placeholder,
    disableChip,
    name,
    onAddTag,
    attr,
    disabled,
    limit
}) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [value, setValue] = useState([]);
    const [searchText, setSearchText] = useState('');
    const optionRender = options.filter(opt => opt.label.toLowerCase().includes(searchText.toLowerCase()));
    const input_ref = React.createRef(null);
    const [selectedValue, setSelectedValue] = useState(0);
    useEffect(() => {
        setSelectedValue(0);
    }, [searchText]);
    let stopPropagation = true;
    if (options === null || options === '' || options === false) {
        options = [];
    }

    useEffect(() => {
        let preDefinedValue = [];
        if (defaultValue !== '' || defaultValue.length > 0) {
            if (typeof defaultValue === 'string') {
                const valueArr = defaultValue.split(',');
                preDefinedValue = options.filter(itm => valueArr.indexOf(itm.value) !== -1);
                if (singleSelect && preDefinedValue.length > 1) {
                    preDefinedValue = [preDefinedValue[0]];
                }
            } else if (Array.isArray(defaultValue) && defaultValue.length > 0 && typeof defaultValue[0] !== 'string') {
                preDefinedValue = options.filter(opt => defaultValue.some(pval => opt.value === pval.value));
                if (singleSelect && preDefinedValue.length > 1) {
                    preDefinedValue = [preDefinedValue[0]];
                }
            } else if (Array.isArray(defaultValue) && defaultValue.length > 0) {
                preDefinedValue = options.filter(opt => defaultValue.some(pval => opt.value === pval));
                if (singleSelect && preDefinedValue.length > 1) {
                    preDefinedValue = [preDefinedValue[0]];
                }
            }
        }
        setValue(preDefinedValue);
    }, [defaultValue]);

    const printOptions = opts => {
        const optsArr = [];
        function addInArr(opts) {
            for (const [i, opt] of opts.entries()) {
                optsArr.push(
                    <option
                        {...(!singleSelect && { 'data-msl': true })}
                        style={{
                            ...(opt.style && opt.style)
                        }}
                        onClick={e => {
                            !opt.disabled && addValue(opt);
                        }}
                        title={opt.label}
                        key={opt.value + i + 10}
                        className={`msl-option overflow-auto ${optionRender[selectedValue] === opt ? 'tag--selected' : ''} ${
                            checkValueExist(opt, value) ? 'msl-option-active' : ''
                        } ${opt.disabled ? 'msl-option-disable' : ''} ${opt.classes !== undefined ? opt.classes : ''}`}
                        value={opt.value}>
                        {opt.label}
                    </option>
                );
            }
        }
        addInArr(opts);
        return optsArr;
    };

    const setNewValue = val => {
        setValue(val);
        if (jsonValue) {
            onChange(val);
        } else {
            let stringvalue = [];
            val.map(itm => stringvalue.push(itm.value));
            onChange(stringvalue);
        }
    };

    useEffect(() => {
        setMenuOpen(false);
        setSearchText('');
    }, [value]);
    const openMenu = () => {
        setMenuOpen(true);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    const inputRefFocus = (e, focus) => {
        let parentNode = null;
        let inputNode = null;
        if (e.target.hasAttribute('data-msl')) {
            parentNode = e.target;
        } else if (e.target.parentNode.hasAttribute('data-msl')) {
            parentNode = e.target.parentNode;
        } else if (e.target.parentNode.parentNode.hasAttribute('data-msl')) {
            parentNode = e.target.parentNode.parentNode;
        } else if (e.target.parentNode.parentNode.parentNode.hasAttribute('data-msl')) {
            parentNode = e.target.parentNode.parentNode.parentNode;
        } else if (e.target.parentNode.parentNode.parentNode.parentNode.hasAttribute('data-msl')) {
            parentNode = e.target.parentNode.parentNode.parentNode.parentNode;
        }
        if (parentNode !== null) {
            inputNode = parentNode.querySelector('.msl-input');
        }

        if (inputNode !== null) {
            focus ? inputNode.focus() : inputNode.blur();
        }
    };

    const openable = e => {
        if (e.target.hasAttribute('data-msl')) {
            return true;
        }
        return false;
    };

    const handleOutsideClick = e => {
        if (openable(e)) {
            if (!menuOpen) {
                document.addEventListener('click', handleOutsideClick);
            }
            inputRefFocus(e, true);
        } else {
            closeMenu();
            document.removeEventListener('click', handleOutsideClick);
        }
    };

    const handleClickInput = e => {
        if (stopPropagation) {
            handleOutsideClick(e);
        }
    };
    const checkValueExist = (value, arr) => {
        const a = arr.some(itm => itm.value === value.value);
        return a;
    };

    const addValue = newValObj => {
        let tmp = [...value];
        if (singleSelect) {
            tmp = [newValObj];
        } else {
            if (!checkValueExist(newValObj, value)) {
                if (limit === null) {
                    tmp.push(newValObj);
                } else if (limit > value.length) {
                    tmp.push(newValObj);
                }
            } else {
                tmp = tmp.filter(itm => itm.value !== newValObj.value);
            }
        }
        setNewValue(tmp);
    };

    const deleteValue = i => {
        const tmp = [...value];
        tmp.splice(i, 1);
        setNewValue(tmp);
    };

    const clearValue = () => {
        setNewValue([]);
    };

    const showSearchOption = () => {
        if (!singleSelect && !disableChip) {
            return true;
        } else if (singleSelect && !value.length) {
            return true;
        } else if (!singleSelect && disableChip && !value.length) {
            return true;
        }
        return false;
    };

    useOnClickOutside(input_ref, () => closeMenu());
    return (
        <div className='flex justify-content-between w-full'>
            <div
                {...attr}
                ref={input_ref}
                onClick={handleClickInput}
                style={{ width }}
                className={`w-full msl-wrp msl-vars ${className} ${disabled ? 'msl-disabled' : ''}`}>
                <input name={name} type='hidden' value={value.map(itm => itm.value)} />

                <div data-msl className={`msl ${menuOpen ? 'msl-active' : ''} `}>
                    <div
                        data-msl
                        className='msl-input-wrp'
                        style={{
                            marginRight: (clearable && 60) || clearable ? 40 : 5
                        }}>
                        {!singleSelect &&
                            !disableChip &&
                            value.map((val, i) => (
                                <div key={`msl-chip-${i + 11}`} className='msl-chip' style={{ background: val.color }}>
                                    {val.label}
                                    <div
                                        role='button'
                                        aria-label='delete-value'
                                        onClick={() => deleteValue(i)}
                                        className='msl-btn msl-chip-delete msl-flx'>
                                        <Feather name={'X'} />
                                    </div>
                                    <span />
                                </div>
                            ))}
                        {!singleSelect && disableChip && value.length === 1 ? (
                            <span
                                className='msl-single-value'
                                data-msl
                                style={{
                                    width: width - ((clearable && 60) || clearable ? 40 : 5)
                                }}>
                                {value[0].label}d
                            </span>
                        ) : (
                            disableChip &&
                            value.length > 1 && (
                                <span
                                    className='msl-single-value'
                                    data-msl
                                    style={{
                                        width: width - ((clearable && 60) || clearable ? 40 : 5)
                                    }}>
                                    {value.length} Selected
                                </span>
                            )
                        )}
                        {singleSelect && value.length === 1 && (
                            <span
                                className='msl-single-value'
                                data-msl
                                style={{
                                    width: width - ((clearable && 60) || clearable ? 40 : 5)
                                }}>
                                {value[0].label}
                            </span>
                        )}
                        {showSearchOption() && (
                            <input
                                type='text'
                                data-msl
                                placeholder={placeholder}
                                value={searchText}
                                className='msl-input'
                                onChange={e => {
                                    setSearchText(e.target.value);
                                    setMenuOpen(true);
                                }}
                                onKeyPress={() => {
                                    if (event.keyCode === 13) {
                                        if (optionRender.length > 0) {
                                            !selectedValue.disabled && addValue(optionRender[selectedValue]);
                                            setSearchText('');
                                            setSelectedValue(0);
                                        } else {
                                            onAddTag(searchText);
                                            setValue([...value, { label: searchText, value: searchText }]);
                                            setSearchText('');
                                        }
                                    }
                                }}
                                onKeyDown={() => {
                                    if (event.keyCode === 40) {
                                        if (selectedValue < optionRender.length - 1) {
                                            setSelectedValue(selectedValue + 1);
                                            if (document.getElementsByClassName('msl-option'))
                                                document
                                                    .getElementsByClassName('msl-option')
                                                    [selectedValue + 1].scrollIntoView(true);
                                        }
                                    } else if (event.keyCode === 38) {
                                        if (selectedValue > 0) {
                                            setSelectedValue(selectedValue - 1);
                                            if (document.getElementsByClassName('msl-option'))
                                                document
                                                    .getElementsByClassName('msl-option')
                                                    [selectedValue - 1].scrollIntoView(true);
                                        }
                                    }
                                }}
                            />
                        )}
                    </div>
                    {clearable && (
                        <div className='msl-actions msl-flx'>
                            {clearable && value.length > 0 && closeIcon && (
                                <div
                                    role='button'
                                    aria-label='close-menu'
                                    onClick={clearValue}
                                    className='msl-btn msl-clear-btn msl-flx'>
                                    <Feather name={'X'} />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className='msl-options overflow-auto'>{printOptions(optionRender)}</div>
            </div>
            {/* <Feather
                name='ChevronDown'
                onClick={() => setMenuOpen(true)}
                className='cursor_pointer'
                color='#00B4B7'
                style={{ width: '20px', height: '20px' }}
            /> */}
        </div>
    );
}

export default MultiSelect;
