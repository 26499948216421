import '@scss/components/_checkbox-switch.scss'

const CheckboxSwitch = ({ checked, onChange }: { checked: boolean; onChange: () => void }) => (
    <div className='switch-container'>
        <input className='switch-input' type='checkbox' id='checkbox' checked={checked} onChange={onChange} />
        <label htmlFor='checkbox' className={`switch-label ${checked && 'switch-checked-label'}`} />
    </div>
)

export default CheckboxSwitch
