import React from 'react';

import { Icon } from '@components';

const CustomControl = (props) => {
    const {
        iconName,
        className = '',

        onClick = () => {}
    } = props;

    return (
        <button className={`btn-map-control ${className}`} onClick={onClick}>
            <Icon name={iconName} />
        </button>
    );
};

export default CustomControl;
