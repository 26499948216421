import { allStatuses, UNDEFINED } from '@constants/status';
import 'scss/components/_status-badge.scss';
import { Image } from '../';

const StatusBadgeMini = ({ status = UNDEFINED, customClass = '', icon = null }) => {
    const descriptor = allStatuses.find(x => x.value === status);

    const icn = icon ? icon : descriptor?.icon;
    return (
        <div className={`StatusBadge StatusBadge__general noselect mini ${descriptor?.cls} ${customClass}`}>
            {icn && <Image src={icn} />}
            {descriptor?.label[0]}
        </div>
    );
};

export default StatusBadgeMini;
