import React from 'react';

const ModalBody = (props) => {
    const {
        children,
        padding,
        modalRef
    } = props;

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { modalRef });
        }
        return child;
    });

    return (
        <div className={`OptionsModal__content ${padding ? 'OptionsModal__content--padding' : ''}`}>
            {childrenWithProps}
        </div>
    );
};

export default ModalBody;
