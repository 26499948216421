import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, LabeledTextArea, Feather } from '@components';
import { loadSettings } from '@reducers/settings/actions';
import { useQuery } from '@tanstack/react-query';
import { fetchAllFinancialSettings } from 'service/financialsApi';

const CloseQuoteLoss = (props) => {
    const {
        summary,
        onUpdateSummary = () => { }
    } = props;

    const dispatch = useDispatch();

    const { data: settings = [] } = useQuery(['settings'], ()=>fetchAllFinancialSettings())

    const reasonsList = useMemo(() => {
        const reasons = settings.find(x => x.key === 'field-mgmt-quotes-reasons');
        if (!reasons || !reasons?.value?.length) {
            return [];
        }

        return reasons.value.split('\n').map(x => {
            return {
                label: x.split(',')[0],
                value: x.split(',')[0]
            };
        });
    }, [settings.length]);;

    const oReasonChange = (e) => {
        onUpdateSummary({ reason: e.value });
    };

    const onNoteChange = (e) => {
        onUpdateSummary({ note: e.target.value });
    };

    useEffect(() => {
        dispatch(loadSettings());
    }, []);

    return (
        <div className='CloseQuoteLoss'>
            <div className='mb-20 flex-start'>
                <Feather name='AlertTriangle' size={24} style={{ color: 'red' }} />
                <span style={{ marginLeft: '10px', fontSize: '14px', fontFamily: 'Rubik', fontWeight: 500 }}>
                    Quote will be saved as lost. Please specify a reason for loss and optional note.
                </span>
            </div>
            <div className='mb-30' style={{ maxWidth: '300px' }}>
                <Dropdown
                    label='Result reason'
                    options={reasonsList}
                    value={summary?.reason}
                    onChange={oReasonChange}
                />
            </div>
            <LabeledTextArea
                label='Note'
                value={summary?.note || ''}
                rows={4}
                onChange={onNoteChange}
            />
        </div>
    );
};

export default CloseQuoteLoss;
