import service from './service';

export const fetchTrucks = async (params = {}) => {
    const res = await service.get(`/trucks`, { params });
    return res.data;
}

export const fetchTruck = async id => {
    const res = await service.get(`/trucks/${id}`);
    return res.data;
};

export const fetchNextTruckNo = async () => {
    const res = await service.get('/trucks/no');
    return res.data.truckNo;
};

export const fetchDefaultTruck = async () => {
    const res = await service.get('/trucks/default');
    return res.data.truck;
};

export const updateTruck = async truck => {
    const res = await service.put(`/trucks/${truck._id}`, truck);
    return res.data;
};

export const saveTruck = async truck => {
    const res = await service.post('/trucks/', truck);
    return res.data;
};

export const deleteTruck = async id => {
    await service.delete(`/trucks/${id}`);
};

export const bulkDeleteTrucks = async ids => {
    const res = await service.post(`/trucks/delete`, ids);
    return res.data;
};

export const changeStatus = async (id, status, errorCallback = () => {}) => {
    try {
        const res = await service.put(`/trucks/status/${id}`, { status });
        return res.data;
    } catch (error) {
        console.log(error);
        errorCallback(error);
    }
    return {};
};
