export const BTN_CONTINUE = 'CONTINUE';
export const BTN_SAVE = 'SAVE';
export const BTN_WIN = 'WIN';
export const BTN_LOSE = 'LOSE';
export const BTN_CLOSE = 'CLOSE';
export const BTN_CANCEL = 'CANCEL';
export const BTN_DELETE = 'DELETE';
export const BTN_CANCEL_SECONDARY = 'CANCEL_SECONDARY';
export const BTN_CANCELLED = 'CANCELLED';
export const BTN_APPLY = 'APPLY';

export const MODEL_FOOTER_BUTTONS_CONFIG = {
    [BTN_CONTINUE]: {
        text: 'Continue',
        action: 'onContinueHandler',
        classPrefix: 'orange',
    },
    [BTN_WIN]: {
        text: 'Win and Close',
        action: 'onSaveHandler',
        classPrefix: 'green',
    },
    [BTN_LOSE]: {
        text: 'Lose and Close',
        action: 'onSaveHandler',
        classPrefix: 'red',
    },
    [BTN_CANCELLED]: {
        text: 'Cancel and Close ',
        action: 'onSaveHandler',
        classPrefix: 'grey',
    },
    [BTN_SAVE]: {
        text: 'Save',
        action: 'onSaveHandler',
        classPrefix: 'orange',
    },
    [BTN_CLOSE]: {
        text: 'Close',
        action: 'onCloseHandler',
        classPrefix: 'blue',
    },
    [BTN_DELETE]: {
        text: 'Yes, delete',
        action: 'onDeleteHandler',
        classPrefix: 'blue',
    },
    [BTN_CANCEL]: {
        text: 'Cancel',
        action: 'onCancelHandler',
        classPrefix: 'blue',
    },
    [BTN_CANCEL_SECONDARY]: {
        text: 'Cancel',
        action: 'onCancelHandler',
        classPrefix: 'white',
    },
    [BTN_APPLY]: {
        text: 'Apply',
        action: 'onApplyHandler',
        classPrefix: 'orange',
    },
}
