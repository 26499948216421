import React, { useMemo, useEffect } from 'react';

import { useGeoZones } from '@hooks/geoZones';

import Map from '@assets/icons/Map.svg';

import SelectEntityDropdown from './index';

const SelectGeoZoneDropdown = (props) => {
    const {
        id = 'geoZones',
        name,
        label = 'Geo zone',
        placeholder = 'Select geo zone',
        selected = [],
        multi = true,
        disabled = false,
        formErrors = [],
        onChange = () => { }
    } = props;

    const {
        lastSavedGeoZone,
        geoZones,
        loading,

        addGeoZoneModal
    } = useGeoZones();

    const items = useMemo(() => {
        if (!geoZones?.length) {
            return;
        }

        return geoZones.map(qZ => ({
            _id: qZ._id,
            label: qZ.name,
            value: qZ
        }));
    }, [geoZones, loading]);

    useEffect(() => {
        if (!lastSavedGeoZone) {
            return;
        }

        onChange([...selected, lastSavedGeoZone]);
    }, [lastSavedGeoZone]);

    return (
            <SelectEntityDropdown
                id={id}
                name={name}
                multi={multi}
                disabled={disabled}
                label={label}
                loading={loading}
                placeholder={placeholder}
                selected={selected}
                onChange={onChange}
                formErrors={formErrors}
                items={items}
                footerButton={{
                    icon: Map,
                    text: 'Create new geo zone',
                    onClick: addGeoZoneModal
                }}
            />
    );
}

export default SelectGeoZoneDropdown;
