import React, { useState, useEffect } from 'react';

import { Label, TextArea, FieldError } from '../';

import '@scss/components/_textarea.scss';

const LabeledTextArea = props => {
    const {
        name = null,
        label = null,
        placeholder = '',
        customClass = '',
        disabled = false,
        noBorder = false,
        readOnly = false,
        value = null,
        rows = 5,
        autoFill = false,
        style = {},
        onChange = () => { },
        formErrors = [],
        id = ''
    } = props;

    const [error, setError] = useState(null);
    useEffect(() => {
        setError(formErrors?.find(x => x?.id === id)?.message);
    }, [formErrors]);

    return (
        <div className={`TextArea ${customClass}`}>
            <Label htmlFor={name} label={label} />
            <TextArea
                style={style}
                autoFill={autoFill}
                name={name}
                onChange={onChange}
                noBorder={noBorder}
                placeholder={placeholder}
                value={value || ''}
                disabled={disabled}
                readOnly={readOnly}
                rows={rows}
                error={error}
            />
            <FieldError error={error} variant={'primary'} />
        </div>
    );
};

export default LabeledTextArea;
