import React from 'react';
import styled from 'styled-components';
import '../../../scss/components/_table-progressbar.scss';

const ProgressBar = styled.div`
    width : ${p => p.percent}%;
`;

const TableProgressBar = ({ percent = 0, ...props }) => {
  return (
    <div className="table-progress-bar">
      <ProgressBar percent={percent} className='table-progress-bar__percent' />
      <span className='table-progress-bar__text'>{percent}%</span>
    </div>
  );
};

export default TableProgressBar;
