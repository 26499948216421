import * as types from './types';

const initialState = {
    trucks: [],
    details: { count: 0, filter: '', limit: 0, page: 0, pages: 0 },
    sending: false,
    editting: false,
    editFields: {
        row: -1,
        col: []
    },
    adding: false,
    deleting: false,
    deletingFields: []
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DELETE_TRUCK:
            return {
                ...state,
                deleting: true,
                deletingFields: action.payload
            };
        case types.DELETE_TRUCK_FAIL:
            return {
                ...state,
                deleting: false
            };
        case types.DELETE_TRUCK_SUCCESS:
            const temp = [...state.trucks];
            action.payload.map(v => {
                const i = temp.findIndex(t => t._id === v);
                temp.splice(i, 1);
            });
            return {
                ...state,
                trucks: temp,
                deleting: false
            };
        case types.EDIT_TRUCK:
            return {
                ...state,
                editting: true,
                editFields: {
                    row: action.payload.id,
                    col: Object.keys(action.payload.params)
                }
            };
        case types.EDIT_TRUCK_FAIL:
            return {
                ...state,
                editting: false
            };
        case types.EDIT_TRUCK_SUCCESS:
            const list = [...state.trucks];
            const index = list.findIndex(e => e._id === action.payload._id);
            list[index] = action.payload;
            return {
                ...state,
                trucks: list,
                editting: false
            };
        case types.ADD_TRUCK:
            return {
                ...state,
                adding: true
            };
        case types.ADD_TRUCK_FAIL:
            return {
                ...state,
                adding: false
            };
        case types.ADD_TRUCK_SUCCESS:
            return {
                ...state,
                trucks: [...state.trucks, action.payload],
                adding: false
            };
        case types.FETCH_LIST_TRUCKS:
            return {
                ...state,
                sending: true
            };
        case types.FETCH_LIST_TRUCKS_SUCCESS:
            return {
                ...state,
                trucks: action.payload.items,
                details: action.payload.details,
                sending: false
            };
        case types.FETCH_LIST_TRUCKS_FAIL:
            return {
                ...state,
                sending: false,
                error: action.payload
            };
        case types.CLEAR_ERROR:
            return {
                ...state,
                error: ''
            };
        default:
            return state;
    }
};

export default reducer;
