import * as Sentry from '@sentry/react'
import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ConfigProvider } from 'antd'

import { OptionsModal, Intercom } from '@components'
import { Button } from '@components/bs'
import ErrorBoundary from '@components/ErrorBoundary'
import GlobalModal from '@components/GlobalModal'
import PreviewDocument from '@components/PreviewDocument'

import { loadUser } from '@reducers/auth/actions'

import createStore from './store/store'

import ProtectedRoute from './routing/ProtectedRoute'

import NotificationProvider from './views/Components/Notification/NotificationProvider'
import AccessRestricted from './views/Pages/Others/AccessRestricted'
import PreviewQuote from './views/Pages/Sales/Quotes/QuoteDetails/PreviewQuote'
import PDF from './views/Pages/Setup/TCSettings/PDFPreview/PDF'
import QuoteApproval from 'views/Pages/QuoteApproval/QuoteApproval'

import NetworkService from './networkService'

import theme, { antTheme } from './theme'

import ScrollToTop from './ScrollToTop'

import styles from './App.module.scss'
import './scss/style.scss'

import { MODAL_CONFIG_APP_VERSION, MODAL_TYPE_APP_VERSION } from '@modals/config'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { getBrowserCache, hardReload } from 'utils/browser'

const Loading = () => (
    <div className='animated fadeIn pt-3 text-center'>
        <div className='sk-spinner sk-spinner-pulse'></div>
    </div>
)

const store = createStore

// Containers
const Login = React.lazy(() => import('./views/Pages/Login/Login'))
const Register = React.lazy(() => import('./views/Pages/Register/Register'))
const DefaultLayout = React.lazy(() => import('./views/Containers/DefaultLayout'))
const PasswordSet = React.lazy(() => import('./views/Pages/Register/PasswordSet'))
const NotFound = React.lazy(() => import('./views/Pages/Others/NotFound'))
const ForgotPassword = React.lazy(() => import('./views/Pages/Login/ForgotPassword'))
const NewPassword = React.lazy(() => import('./views/Pages/Login/NewPassword'))
const QuotePdf = React.lazy(() => import('./views/Pages/QuotePdf'))

NetworkService.setupInterceptors(store)

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: 'always',
            retry: 3,
            retryDelay: 1,
            staleTime: 5 * 60 * 1000,
            useErrorBoundary: error => error.response?.status >= 500
        }
    }
})

const App = () => {
    const { appVersion, isVersionUpdated } = getBrowserCache()
    const [modalOptions, setModalOptions] = useState({ type: '', isOpen: false, title: '', buttons: [] })

    useEffect(() => {
        const appLoad = async () => {
            await store.dispatch(loadUser())

            if (store.getState().auth.isAuthenticated && isVersionUpdated) {
                setModalOptions({
                    ...MODAL_CONFIG_APP_VERSION,
                    buttons: [],
                    showHeader: false,
                    messageTitle: 'Update available',
                    message: `Version ${appVersion}`
                })
            }
        }
        appLoad()
    }, [])

    return (
        <ConfigProvider theme={antTheme}>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <Provider store={store}>
                        <Intercom />
                        <NotificationProvider>
                            <BrowserRouter>
                                <Sentry.ErrorBoundary fallback={ErrorBoundary}>
                                    {/* <AxiosInterceptorNavigate /> */}
                                    <GlobalModal />
                                    <ScrollToTop>
                                        <React.Suspense fallback={<Loading />}>
                                            <Switch>
                                                <Route exact path='/login' component={Login} />
                                                <Route exact path='/forgot-password' component={ForgotPassword} />
                                                <Route exact path='/new-password' component={NewPassword} />
                                                <Route exact path='/register' component={Register} />
                                                <Route exact path='/quote-details/preview/:id' component={PreviewQuote} />
                                                <Route exact path='/document-details/preview/:id' component={PreviewDocument} />
                                                <Route exact path='/preview-template' component={PDF} />
                                                <Route exact path='/set-your-password' component={PasswordSet} />
                                                <Route exact path='/not-found' component={NotFound} />
                                                <Route exact path='/restricted' component={AccessRestricted} />
                                                <Route exact path='/quote-approval/:id' component={QuoteApproval} />
                                                <Route exact path='/quote-pdf/:id' component={QuotePdf} />
                                                <ProtectedRoute path='/' component={DefaultLayout} />
                                            </Switch>
                                        </React.Suspense>
                                    </ScrollToTop>
                                    <OptionsModal {...modalOptions} onCloseHandler={() => setModalOptions({ isOpen: false })} disabledClose>
                                        {modalOptions.type === MODAL_TYPE_APP_VERSION ? (
                                            <div className={styles.updateModal} data-cy='updateModal'>
                                                <h1 className={styles.updateModalTitle}>{modalOptions.messageTitle}</h1>
                                                <p className={styles.updateModalDescription}>{modalOptions.message}</p>
                                                <Button variant='secondary' data-cy='updateNow' className={styles.updateModalButton} onClick={hardReload}>
                                                    Update Now
                                                </Button>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </OptionsModal>
                                </Sentry.ErrorBoundary>
                            </BrowserRouter>
                        </NotificationProvider>
                    </Provider>
                </QueryClientProvider>
            </ThemeProvider>
        </ConfigProvider>
    )
}

export default App
