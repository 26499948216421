import React, { useState, useEffect } from 'react';
import '@scss/panels/_product-price-overview-graph.scss';
import { Tooltip, MultiRangeSlider } from '@components';
import TooltipContent from './TooltipContent';

const CIRCLE_MIND = 20;
const CIRCLE_MAXD = 40;

const ProductPriceOverviewGraph = ({ data, markers, initialRange, onZoom }) => {
    const [verticalData, setVerticalData] = useState([]);
    const [minMax, setMinMax] = useState({
        min: 0,
        max: 0
    });

    useEffect(() => {
        let floorMax = Math.max(...markers.floorPrices),
            targetMax = Math.max(...markers.targetPrices),
            floorMin = Math.min(...markers.floorPrices),
            targetMin = Math.min(...markers.targetPrices);

        let min = initialRange.min < data.price.min ? initialRange.min : data.price.min;
        let max = initialRange.max > data.price.max ? initialRange.max : data.price.max;

        if (floorMin < min) min = floorMin;
        if (targetMin < min) min = targetMin;

        if (floorMax > max) max = floorMax;
        if (targetMax > max) max = targetMax;

        setMinMax({ min, max });
        const rangeArr = range(min, max);
        setVerticalData(rangeArr);
    }, [data, markers, initialRange]);

    const range = (start, end) => {
        const step = (end - start) / 4;
        return Array(5)
            .fill()
            .map((_, idx) => Math.round((start + idx * step) * 10) / 10);
    };

    const rangeToPercent = number => {
        return ((number - minMax.min) / (minMax.max - minMax.min)) * 100;
    };

    const getSize = value => {
        const x = CIRCLE_MIND + ((value - data.value.min) / (data.value.max - data.value.min)) * (CIRCLE_MAXD - CIRCLE_MIND);
        return Number.isNaN(x) ? 0 : x;
    };

    const tiersLength = verticalData && verticalData.length !== 0 && verticalData.length - 1;

    return (
        <div className='price--graph'>
            <div className='price--graph-header'>
                <div className='price--graph-first'>
                    <span>Number of customers</span>
                </div>
                {data?.tiers.map((a, idx) => (
                    <div key={`head-${idx}`} className='price--graph-number'>
                        {a.customersNo}
                    </div>
                ))}
            </div>
            <div className='price--graph-body'>
                {/* {initialRange.min !== 0 && initialRange.max !== 0 && <MultiRangeSlider
                    min={initialRange.min}
                    max={initialRange.max}
                    onChange={({ min, max }) => onZoom({ min, max })}
                />} */}
                <div className='price--graph-vertical'>
                    {verticalData.map((a, i) => (
                        <div key={`vertical-label-${i}`} className='price--graph-price'>
                            {a}$
                        </div>
                    ))}
                </div>
                {data?.tiers.map((item, index) => (
                    <div key={`tier-${index}`} className='price--graph-tier'>
                        {Array.from(Array(tiersLength).keys()).map((b, idx) => (
                            <div key={`tier-cell-${idx}`} className='price--graph-tier-cell' />
                        ))}
                        <div className='price--graph-data'>
                            {item.values
                                .sort((a, b) => a.value < b.value)
                                .map((itemData, k) => {
                                    if (itemData.value != 0) {
                                        return (
                                            <Tooltip
                                                key={`tooltip-${k}`}
                                                direction='top'
                                                content={<TooltipContent itemData={itemData} />}
                                                style={{
                                                    position: 'absolute',
                                                    left: 0,
                                                    right: 0,
                                                    margin: 'auto',
                                                    width: getSize(itemData.value),
                                                    height: getSize(itemData.value),
                                                    bottom: `calc(${rangeToPercent(itemData.price)}%)`
                                                }}>
                                                <span
                                                    style={{
                                                        bottom: `calc(${rangeToPercent(itemData.price)}% - ${getSize(itemData.value) / 2}px)`,
                                                        width: getSize(itemData.value),
                                                        height: getSize(itemData.value),
                                                        position: 'absolute',
                                                        left: 0,
                                                        right: 0,
                                                        margin: 'auto',
                                                        background:
                                                            itemData.price < markers.floorPrices[index] || itemData.price > markers.targetPrices[index] ? '#ff9900' : '#2ea2ff',
                                                        border: `1px solid ${itemData.price < markers.floorPrices[index] || itemData.price > markers.targetPrices[index] ?  '#df7e38' : '#267ac3'}`,
                                                        zIndex: k
                                                    }}
                                                />
                                            </Tooltip>
                                        );
                                    }
                                })}
                        </div>
                        {
                            <div className='price--graph-prices'>
                                <div className='price--graph-cube' style={{ bottom: `calc(${rangeToPercent(markers.floorPrices[index])}% - 3px)` }}></div>
                                <div className='price--graph-cube' style={{ bottom: `calc(${rangeToPercent(markers.targetPrices[index])}% - 3px)` }}></div>
                            </div>
                        }
                    </div>
                ))}
                <div className='price--graph-label'>SALES PRICE ($)</div>
            </div>
            <div className='price--graph-footer'>
                <div className='price--graph-first' />
                <div>
                    {Array.from(Array(5).keys()).map(a => (
                        <div key={`footer-tier-${a}`} className='price--graph-tier'>
                            <span>Tier {a + 1}</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className='price--graph-horizontal-label'>
                <span>TIER LEVELS</span>
            </div>
        </div>
    );
};

export default ProductPriceOverviewGraph;
