import React from 'react'

import { useQuery } from '@tanstack/react-query';

import FinancialSettingsRepository from 'service/FinancialSettingsRepository';

import * as SETTINGS from 'consts/settings';
import { USER_RESPONSIBLE_EMAIL } from 'views/Pages/Setup/Notifications/SentDomain';
import {
    CREDIT_LIMIT_NOTIFICATION_QUOTE_STAGE_DEFAULT,
    CREDIT_LIMIT_VALIDATION_QUOTE_STAGE_DEFAULT,
    CREDIT_LIMIT_VALIDATION_USER_DEFAULT,
    ENABLE_CREDIT_LIMIT_NOTIFICATION_DEFAULT,
    ENABLE_CREDIT_LIMIT_NOTIFICATION_QUOTE_STAGE_DEFAULT,
    ENABLE_CREDIT_LIMIT_VALIDATION_QUOTE_STAGE_DEFAULT
} from 'views/Pages/Setup/Notifications/CreditLimit';

const DEFAULT_SETTINGS = <{ [key: string]: any }>{
    [SETTINGS.CURRENCY]: SETTINGS.CURRENCY_DEFAULT,
    [SETTINGS.DATE_FORMAT]: SETTINGS.DATE_FORMAT_DEFAULT,
    [SETTINGS.TAX_POINT_OF_SALE]: SETTINGS.TAX_POS_DEFAULT,
    [SETTINGS.SEPARATOR]: SETTINGS.SEPARATOR_DEFAULT,
    [SETTINGS.UNIT]: SETTINGS.DEFAULT_UNIT,
    [SETTINGS.DECIMALS]: SETTINGS.DECIMALS_DEFAULT,
    [SETTINGS.THOUSAND_SEPARATOR]: SETTINGS.THOUSAND_SEPARATOR_DEFAULT,
    [SETTINGS.ENABLE_TENANT_DOMAIN_EMAIL]: SETTINGS.ENABLE_TENANT_DOMAIN_EMAIL_DEFAULT,
    [SETTINGS.ENABLE_USER_EMAIL]: SETTINGS.ENABLE_USER_EMAIL_DEFAULT,
    [SETTINGS.USER_SENT_EMAIL]: USER_RESPONSIBLE_EMAIL,
    [SETTINGS.ENABLE_CREDIT_LIMIT_NOTIFICATION]: ENABLE_CREDIT_LIMIT_NOTIFICATION_DEFAULT,
    [SETTINGS.CREDIT_LIMIT_NOTIFICATION_QUOTE_STAGE]: CREDIT_LIMIT_NOTIFICATION_QUOTE_STAGE_DEFAULT,
    [SETTINGS.ENABLE_CREDIT_LIMIT_NOTIFICATION_QUOTE_STAGE]: ENABLE_CREDIT_LIMIT_NOTIFICATION_QUOTE_STAGE_DEFAULT,
    [SETTINGS.ENABLE_CREDIT_LIMIT_VALIDATION_QUOTE_STAGE]: ENABLE_CREDIT_LIMIT_VALIDATION_QUOTE_STAGE_DEFAULT,
    [SETTINGS.CREDIT_LIMIT_VALIDATION_QUOTE_STAGE]: CREDIT_LIMIT_VALIDATION_QUOTE_STAGE_DEFAULT,
    [SETTINGS.CREDIT_LIMIT_VALIDATION_USER]: CREDIT_LIMIT_VALIDATION_USER_DEFAULT,
    [SETTINGS.ENABLE_DOCUMENT_STORAGE]: SETTINGS.ENABLE_DOCUMENT_STORAGE_DEFAULT
};

const FIELD_MGMT_LIST = [
    'field-mgmt-products-type1',
    'field-mgmt-products-type2',

    'field-mgmt-customers-segments',
    'field-mgmt-customers-types',

    'field-mgmt-documents-type1',
    'field-mgmt-documents-type2',

    'field-mgmt-quotes-reasons',
    'field-mgmt-quotes-cancel-reason',

    'field-mgmt-project-project-type',
    'field-mgmt-project-bid-source'
]

export const useFinancialSettings = (key?: string | null) => {
    const { data: financialSettings } = useQuery<any>(['financial-settings', key], () => FinancialSettingsRepository.getPaginatedList(), {
        select: resData => {
            if (!resData?.length) {
                Object.keys(DEFAULT_SETTINGS).forEach(k => localStorage.setItem(k, DEFAULT_SETTINGS[k]))
                return DEFAULT_SETTINGS
            }

            return resData.reduce((prev: any, current: any) => {
                if (!current?.key?.length) {
                    return prev
                }

                const settingKey: string = current.key
                if (FIELD_MGMT_LIST.indexOf(settingKey) === -1) {
                    const settingValue = typeof current?.value !== 'undefined' ? current.value : DEFAULT_SETTINGS[settingKey] || null

                    const isDefaultKey = DEFAULT_SETTINGS[settingKey]
                    if (isDefaultKey) localStorage.setItem(settingKey, settingValue)

                    prev[settingKey] = settingValue
                    return prev
                }

                prev[settingKey] = current.value?.split('\n')?.map((x: string) => ({ label: x.split(',')[0], value: x.split(',')[0] })) || []
                return prev
            }, DEFAULT_SETTINGS)
        },
        placeholderData: DEFAULT_SETTINGS,
        staleTime: 15 * (60 * 1000), // 15 mins
        cacheTime: 15 * (60 * 1000) // 15 mins
    })

    if (!key) {
        return financialSettings
    }

    if (key && (!financialSettings || !financialSettings[key])) {
        return null
    }

    return financialSettings[key]
}
