import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.API_HOST,
	timeout: 5000000,
	headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
        common: {
            Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    }
});

export const setAuthToken = (token: any) => {
	if (token) {
		instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	} else {
		delete instance.defaults.headers.common['Authorization'];
	}
};

export default instance;
