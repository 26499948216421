import React from 'react';

import { CollapsiblePanel} from '@components';

import '@scss/panels/_guides.scss';

const Guides = (props) => {
    const { children } = props;

    return (
        <CollapsiblePanel title='Knowledge Center'>
            <div className='guides'>
                <p>{children}</p>
            </div>
        </CollapsiblePanel>
    );
};

export default Guides;
