export const FETCH_LIST_SALES_PERSON = 'FETCH_LIST_SALES_PERSON';
export const FETCH_LIST_SALES_PERSON_SUCCESS = 'FETCH_LIST_SALES_PERSON_SUCCESS';
export const FETCH_LIST_SALES_PERSON_FAIL = 'FETCH_LIST_SALES_PERSON_FAIL';

export const ADD_SALESPERSON = 'ADD_SALESPERSON';
export const ADD_SALESPERSON_SUCCESS= 'ADD_SALESPERSON_SUCCESS';
export const ADD_SALESPERSON_FAIL = 'ADD_SALESPERSON_FAIL';

export const EDIT_SALESPERSON = 'EDIT_SALESPERSON';
export const EDIT_SALESPERSON_SUCCESS= 'EDIT_SALESPERSON_SUCCESS';
export const EDIT_SALESPERSON_FAIL = 'EDIT_SALESPERSON_FAIL';

export const DELETE_SALESPERSON = 'DELETE_SALESPERSON';
export const DELETE_SALESPERSON_SUCCESS= 'DELETE_SALESPERSON_SUCCESS';
export const DELETE_SALESPERSON_FAIL = 'DELETE_SALESPERSON_FAIL';
