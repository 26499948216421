import React from 'react';
import { Button, CollapsiblePanel, Feather, DataPair } from '@components';
import './_style.scss';

const UserResponsible = ({ user, readOnly, page, onModalOpenClick = () => {} }) => {
    return (
        <CollapsiblePanel title='User responsible'>
            <div className='UserResponsible'>
                <p style={{ margin: 0 }}>{`Please choose a user responsible for this ${page}`}</p>
                <div className='mt-20 flex-start'>
                    <Button
                        disabled={readOnly}
                        onClick={() => {
                            if (!readOnly) onModalOpenClick();
                        }}
                        customClass='btnold--collapse center'
                        ripple={{ color: '#0e3f66', duration: 550 }}>
                        <Feather name='Target' />
                        <span>User responsible</span>
                    </Button>
                </div>
                <DataPair titleOne='User' valueOne={`${user?.firstName || ''} ${user?.lastName || ''}`} />
            </div>
        </CollapsiblePanel>
    );
};

export default UserResponsible;
