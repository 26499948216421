import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Map } from '@components/Map';

import '@scss/components/_geocoder.scss';
import '@scss/modals/_map-modal.scss';

const MAP_STYLES = {
    width: 400,
    height: 400
};

const MAP_OPTIONS = {
    typeControl: true,
    autocomplete: true
};

const MapModal = props => {
    const {
        lng = 0,
        lat = 0,
        zoom = 10,
        markers = null,
        addMarkerToMapCenter = true,

        onChange = () => {}
    } = props;

    const [marker, setMarker] = useState({ position: { lat: lat, lng: lng }, draggable: true, icon: 0 });
    const [mapCenter, setMapCenter] = useState({ lat: lat, lng: lng });

    const mapRef = useRef();
    const mapServicesRef = useRef();

    const defaultPlantId = useSelector(store => store.auth.user.defaultPlant);
    const { plants } = useSelector(state => state.data.plants);
    const defaultPlant = plants?.find(plant => plant._id === defaultPlantId);

    const DEFAULT_MAP = useMemo(() => {
        if (!defaultPlant?.address) {
            return {};
        }
        const { lat, lng } = defaultPlant?.address;
        return { lat, lng };
    }, [defaultPlant]);

    const onMapMouseDown = e => {
        onChange({ lng: e.lng, lat: e.lat, address_components: e.address_components, formatted_address: e.formatted_address, placeName: e.placeName });
        setMarker({ ...marker, position: { lat: e.lat, lng: e.lng }, icon: 0 });
        setMapCenter({ lat: e.lat, lng: e.lng });
    };

    useEffect(() => {
        let latitude = lat;
        let longitude = lng;
        if (!lat || !lng || lat == 0 || lng == 0) {
            latitude = DEFAULT_MAP.lat;
            longitude = DEFAULT_MAP.lng;
            setMarker({ ...marker, icon: 10 });
        }

        if (addMarkerToMapCenter) {
            setMarker({ ...marker, position: { lat: latitude, lng: longitude } });
            setMapCenter({ lat: latitude, lng: longitude });
        }

        if (mapServicesRef.current && markers?.length) {
            mapServicesRef.current.addMarkerToMap([...markers.map(marker => ({ id: marker.id, lng: marker.lng, lat: marker.lat, draggable: false, icon: marker.icon }))]);
        }
    }, [defaultPlant, markers]);

    // useEffect(() => {
    //     if (!mapRef.current || !markers?.length) {
    //         return;
    //     }
    //     mapServicesRef.current.addMarkerToMap([...markers.map(marker => ({ id: marker.id, lng: marker.lng, lat: marker.lat, draggable: false, icon: marker.icon }))]);
    // }, [mapRef.current, markers]);

    return (
        <div>
            <p className='info-text'>Type in the address or right click on the map to drop a pin</p>
            <div className='Map' style={{ height: '450px' }}>
                <Map
                    ref={mapRef}
                    mapServicesRef={mapServicesRef}
                    marker={marker}
                    mapCenter={mapCenter}
                    style={MAP_STYLES}
                    viewSelector='bottom'
                    zoom={zoom}
                    markers={markers}
                    onMouseDown={onMapMouseDown}
                    mapOptions={MAP_OPTIONS}></Map>
            </div>
        </div>
    );
};

export default MapModal;
