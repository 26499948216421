import * as types from './types';

const initialState = {
    tecNotifications: null,
    sending: false,
    saving: false,
    error: null
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SAVE_TEC_NOTIFICATIONS:
            return {
                ...state,
                saving: true
            };
        case types.SAVE_TEC_NOTIFICATIONS_FAIL:
            return {
                ...state
            };
        case types.SAVE_TEC_NOTIFICATIONS_SUCCESS:
            localStorage.setItem('tecValidation', JSON.stringify(action.payload?.validation || false));
            return {
                ...state,
                tecNotifications: action.payload
            };
        case types.GET_TEC_NOTIFICATIONS:
            return {
                ...state,
                sending: true
            };
        case types.GET_TEC_NOTIFICATIONS_SUCCESS:
            localStorage.setItem('tecValidation', JSON.stringify(action.payload?.validation || false));
            return {
                ...state,
                tecNotifications: action.payload,
                sending: false
            };
        case types.GET_TEC_NOTIFICATIONS_FAIL:
            return {
                ...state,
                sending: false,
                error: action.payload
            };
        case types.RESET_TEC_NOTIFICATIONS:
            return {
                ...state,
                saving: false,
                sending: false
            };
        default:
            return state;
    }
};

export default reducer;
