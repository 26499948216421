import { useState, useEffect } from 'react';
import '@scss/components/_range-slider.scss';

const RangeSlider = ({ min, max, step, value, onChange }) => {
    const [tickIndexInvisible, setTickIndexInvisible] = useState(2);
    const ticks = [
        {
            id: 'left',
            index: 0,
            value: 1,
            offset: '89px'
        },
        {
            id: 'middle',
            index: 1,
            value: 5,
            offset: '50px'
        },
        { id: 'right', index: 2, value: 10, offset: '11px' }
    ];

    useEffect(() => {
        setTickIndexInvisible(ticks.find(tick => tick.value === value)?.index);
    }, [value]);

    return (
        <>
            <input className='range-slider' type='range' onChange={onChange} min={min} max={max} step={step} value={value} />
            {ticks.map(({ id, index, offset }) => tickIndexInvisible !== index && <div className='range-slider-tick' key={id} style={{ '--tick-offset': offset }} />)}
        </>
    );
};

export default RangeSlider;
