import React from 'react';
import { withRouter } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Button } from '@components/bs';
import { Feather, WithCondition } from '@components';

import { APP_ENVIRONMENTS } from 'consts/environments';

import { getBrowserCache, hardReload } from 'utils/browser';

import 'scss/components/_error-boundary.scss';
import { saveAllTracers } from '@service/trace';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: true, showErrorDetails: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true, error };
	}

	componentDidMount() {
		saveAllTracers('crash')
		this.unlisten = this.props.history.listen((location, action) => {
			if (this.state.hasError) {
				this.setState({ hasError: false, showErrorDetails: false });
			}
		});
	}

	componentWillUnmount() {
		this.unlisten();
	}

	componentDidCatch(error, errorInfo) {
		console.log(error, errorInfo);
		this.setState({ errorInfo });
	}

	toggleErrorDetails = () => {
		this.setState(prev => ({
			showErrorDetails: !prev.showErrorDetails
		}))
	}

	home = () => {
		this.props.resetError();
		this.props.history.push('/Dashboard');
	}

	reload = () => {
		window.location.reload();
	}

	render() {
		const { hasError } = this.state;
		const errorInfo = this.state.errorInfo || { componentStack: this.props.componentStack };
		const error = this.state.error || this.props.error;
		const disableDetailsBtn = APP_ENVIRONMENTS.IS_PROD;

		if (hasError) {
			if (/ChunkLoadError/.test(error.name)) {
				const { appVersion } = getBrowserCache();

				return (
					<Container className='ErrorBoundary'>
						<Row className='justify-content-md-center text-center align-center'>
							<div className='update-app-version' data-cy='error-boundary-update-wrapper'>
								<h1>Update available</h1>
								<p>Version {appVersion}</p>
								<Button variant='secondary' data-cy='error-boundary-update-now' onClick={hardReload}>
									Update Now
								</Button>
							</div>
						</Row>
					</Container>
				)
			}

			return (
				<Container className="ErrorBoundary">
					<Row className="justify-content-md-center text-center">
						<Col md="6">
							Try to refresh the page, return to the previous screen, or view the details.
							<br />
							Contact to <a href='mailto:support@tkbinternational.com' data-cy='support-email-link'>PriceBee Support</a> if your issue is not resolved.
						</Col>
					</Row>
					<Row className="justify-content-md-center text-center">
						<Col md="6">
							<Button
								variant='outline-light'
								onClick={this.reload}
							>
								Refresh
								<Feather name='RefreshCcw' className='icon' />
							</Button>
							<Button
								variant='outline-light'
								onClick={this.home}
							>
								On Home
								<Feather name='Home' className='icon' />
							</Button>
							<WithCondition condition={!disableDetailsBtn}>
								<Button
									variant='outline-light'
									onClick={this.toggleErrorDetails}
								>
									Details
									<Feather name='Code' className='icon' />
								</Button>
							</WithCondition>
						</Col>
					</Row>
					<WithCondition condition={this.state.showErrorDetails}>
						<Row className="justify-content-md-center stack">
							<Col md="6">
								<summary>{error && error.message}</summary>
								<pre>{error && error.stack?.trim()}</pre>
								<summary>Component Stack</summary>
								<pre>{errorInfo && errorInfo.componentStack?.trim()}</pre>
							</Col>
						</Row>
					</WithCondition>
				</Container>
			)
		}

		return this.props.children;
	}
}

export default withRouter(ErrorBoundary);
