import { useCallback } from 'react'

import { useFinancialSettings } from './useFinancialSettings'

import { DECIMALS, THOUSAND_SEPARATOR, DECIMALS_COMMA, THOUSAND_SEPARATOR_DEFAULT } from 'consts/settings'

export const useThousandSeparator = () => {
    const thousandSeparator: string = useFinancialSettings(THOUSAND_SEPARATOR)
    const decimals: string = useFinancialSettings(DECIMALS)

    const formatNumber = useCallback(
        (num: number) => {
            const options = {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            }

            let x = !num || Number.isNaN(num) ? 0 : num
            const isNegativeNumber = x < 0
            if (isNegativeNumber) x = Math.abs(x)

            let formattedValue = Number(x).toLocaleString('en-US', options)

            if (thousandSeparator === THOUSAND_SEPARATOR_DEFAULT) {
                formattedValue = formattedValue.replace(/,/g, ' ')
            }

            if (decimals === DECIMALS_COMMA) {
                formattedValue = formattedValue.replace(/\./g, ',')
            }

            return isNegativeNumber ? `-${formattedValue}` : formattedValue
        },
        [thousandSeparator, decimals]
    )

    return { formatNumber }
}
