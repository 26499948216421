import service from './service';

export const fetchAllFinancialSettings = async (errorCallback = () => { }) => {
    try {
        const res = await service.get(`/financial-settings`);
        return res.data;
    } catch (err) {
        errorCallback(err);
    }
    return [];
};

export const fetchFinancialSettings = async (keys, errorCallback = () => { }) => {
    try {
        const res = await service.get(`/financial-settings/${keys.join(':')}`);
        return res.data;
    } catch (err) {
        errorCallback(err);
    }
    return [];
};

export const settingsCountUsage = async (key, errorCallback = () => { }) => {
    const res = await service.get(`/financial-settings/count/${key}`);
    return res.data;
};

export const saveFinancialSetting = async (keyValues, errorCallback = () => { }) => {
    try {
        const res = await service.post(`/financial-settings/`, keyValues);
        return res.data;
    } catch (err) {
        errorCallback(err);
    }
    return [];
};

export const saveStandatdView = async (data, errorCallback = () => { }) => {
    try {
        const res = await service.post(`/financial-settings/standard-view`, data);
        return res.data;
    } catch (err) {
        errorCallback(err);
    }
    return [];
};

export const fetchCustomerTerms = async (params) => {
    const res = await service.get(`/financial-settings/terms/all`, { params });
    return res.data;
};

export const saveCustomerTerms = async (params) => {
    const res = await service.post(`/financial-settings/terms`, params);
    return res.data;
}

export const updateCustomerTerms = async (params) => {
    const res = await service.put(`/financial-settings/terms`, params);
    return res.data;
}

export const deleteCustomerTerms = async (params) => {
    const res = await service.delete(`/financial-settings/terms`, { params: { ids: params } });
    return res.data;
}