export const DRAFT = { value: 'draft', label: 'Draft', index: 0 }
export const IN_APPROVAL = { value: 'in-approval', label: 'In approval', index: 1 }
export const APPROVED = { value: 'approved', label: 'Approved', index: 1 }
export const REJECTED = { value: 'draft', label: 'Rejected', index: 1 }
// export const REJECTED = { value: 'rejected', label: 'Rejected', index: 1 }
export const REVOKED = { value: 'draft', label: 'Revoked', index: 1 }
// export const REVOKED = { value: 'revoked', label: 'Revoked', index: 1 }
export const SCHEDULED = { value: 'scheduled', label: 'Scheduled', index: 3 }
export const SENT = { value: 'sent', label: 'Sent', index: 3 }
export const NEGOTIATION = { value: 'negotiation', label: 'Negotiation', index: 4 }
export const SIGNED = { value: 'signed', label: 'Estimation', index: 5 }
export const NOT_SIGNED = { value: 'not-signed', label: 'Not signed', index: 5 }
export const WON = { value: 'won', label: 'Won', index: 6 }
export const LOST = { value: 'lost', label: 'Lost', index: 6 }
export const CANCELLED = { value: 'cancelled', label: 'Cancelled', index: 6 }

export default [DRAFT, IN_APPROVAL, APPROVED, REJECTED, REVOKED, SENT, SCHEDULED, SIGNED, NEGOTIATION, WON, LOST, CANCELLED];

export const CLOSING_STATES = [SENT, SIGNED, NEGOTIATION, NOT_SIGNED]
export const EDITING_STATES = [DRAFT, APPROVED, REJECTED, REVOKED, SCHEDULED, SIGNED, NEGOTIATION, SENT]

export const DEV_STATUSES = [DRAFT, SENT, SIGNED, NEGOTIATION]