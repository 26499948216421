import * as types from './types';

const initialState = {
    projects: [],
    details: { count: 0, filter: '', limit: 0, page: 0, pages: 0 },
    sending: false,
    error: ''
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LINK_CONTACT:
            return {
                ...state,
                sending: true
            };
        case types.LINK_CONTACT_SUCCESS:
            return {
                ...state,
                sending: false
            };
        case types.LINK_CONTACT_FAIL:
            return {
                ...state,
                sending: false
            };
        case types.FETCH_LIST_PROJECTS:
            return {
                ...state,
                sending: true
            };
        case types.FETCH_LIST_PROJECTS_SUCCESS:
            return {
                ...state,
                projects: action.payload.items,
                details: action.payload.details,
                sending: false
            };
        case types.FETCH_LIST_PROJECTS_FAIL:
            return {
                ...state,
                sending: false,
                error: action.payload
            };
        case types.CLEAR_ERROR:
            return {
                ...state,
                error: ''
            };
        default:
            return state;
    }
};

export default reducer;
