import * as React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import Feather from '../Feather'

/**
 * Some basic styles
 */

// Notice how we're wrapping 'motion-div'
// styled-components generates a className and injects it into the motion.div
const Base = styled(motion.div)`
    display: flex;
    color: white;
    border-radius: 4px;
    overflow: hidden;
    width: 350px;
    margin-bottom: 24px;
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.25);
`

const Main = styled.div`
    padding: 20px 28px 24px 20px;
    display: flex;
    flex: 1;

    & > div:first-child {
        margin-right: 20px;
    }
`

const Description = styled.div`
    line-height: 1.3;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.75);
`

const Title = styled.div`
    line-height: 1.3;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 4px;
`

const Buttons = styled.div`
    display: flex;
    flex-direction: column;

    & > button:not(:first-child) {
        border-top: 1px solid #404040;
    }
`

const Button = styled.button`
    pointer-events: all;
    transition: background-color 0.15s ease-in-out;
    flex: 1;
    padding: 20px;
    background-color: transparent;
    outline: 0;
    border: 0;
    color: white;
    width: 100%;
    cursor: pointer;

    &:hover {
        background-color: transparent;
    }
    svg {
        width: 15px;
    }
`

interface NotificationProps {
    id: string
    onClose: () => void
    title: string
    description: string
    type: string
    duration: number
    key: string
    onMore?: () => void
}

function Notification({ title, description, type, onClose, onMore }: NotificationProps) {
    return (
        <Base
            className={`notification-${type || 'info'}`}
            initial={{ opacity: 0, scale: 0.8, x: 300 }} // animate from
            animate={{ opacity: 1, scale: 1, x: 0 }} // animate to
            exit={{ opacity: 0, scale: 0.8, x: 300 }} // animate exit
            transition={{
                type: 'spring',
                stiffness: 500,
                damping: 40
            }}
            // positionTransition // auto animates the element when it's position changes
        >
            <Main>
                <div>
                    <Title data-cy='notification-title'>{title}</Title>
                    <Description>{description}</Description>
                </div>
            </Main>
            <Buttons>
                <Button onClick={onClose}>
                    <Feather name='X' />
                </Button>
                {onMore && <Button onClick={onMore}>More...</Button>}
            </Buttons>
        </Base>
    )
}

export default Notification
