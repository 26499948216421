import React from 'react';

import logo from '@assets/icons/Logo_TKB_white_new.png';
import attentionImg from '@assets/icons/Attention_Huge-icon.svg';

import '@scss/components/_accessRestricted.scss';
import styled from 'styled-components';

export const AccessRestricted = () => {
    return (
        <AccessRestrictedPage>
            <AccessRestrictedWrapper>
                <AccessRestrictedContent>
                    <img src={attentionImg} alt='Attention' />
                    <AccessRestrictedTitle>You don't have access</AccessRestrictedTitle>
                    <AccessRestrictedSubTitle>Your account has been archived. Please contact <a href='mailto:support@tkbinternational.com '>support@tkbinternational.com</a></AccessRestrictedSubTitle>
                </AccessRestrictedContent>
                <AccessRestrictedFooter>
                    <img src={logo} alt='logo' />
                </AccessRestrictedFooter>
            </AccessRestrictedWrapper>
        </AccessRestrictedPage>
    );
};

const AccessRestrictedPage = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

`;
const AccessRestrictedWrapper = styled.div`
    width: 782px;
    border-radius: 5px;
    box-shadow: 0px 4px 15px 4px #093E661F;
    background-color: #fff;
    display: flex;
    flex-direction: column;
`;
const AccessRestrictedContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 85px 78px;
    gap: 20px;
`;
const AccessRestrictedFooter = styled.div`
    border-radius: 0px 0px 6px 6px;
    background-color: #0E3F66;
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const AccessRestrictedTitle = styled.h2`
    font-family: Rubik;
    font-size: 30px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0px;
    color: #0E3F66;
`;
const AccessRestrictedSubTitle = styled.p`
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: center;
    color: #0E3F66;
`;

export default AccessRestricted;
