import styled from 'styled-components'

const Version = styled.div`
    position: fixed;
    color: #f3f3f3;
    opacity: 0.5;
    left: 4px;
    font-size: 11px;
    bottom: 2px;
    z-index: 1;
    text-shadow: 0px 0px 4px #333333;
    text-stroke: 1px #ffffff;
`

export default Version
