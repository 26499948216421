import React, { useEffect, useRef, useState } from 'react'

import useRequest from '@hooks/useRequest'

import { useNotify } from '@components/index'

import MultiSelectTable from '@components/UI/Table/multi-select-table'
import Search from '@components/UI/Search'
import Show from '@components/UI/Show'

import productColumns from './selectProductsColumns'
import TableSummary from '@components/UI/Table/table-summary'
import useTableSettings from '@components/UI/Table/useTableSettings'
import { useDebounceValue } from '@hooks/useDebounceValue'
import { useQuery } from '@tanstack/react-query'
import { fetchProductsEntity } from 'service/productsApi'
import { fetchProductLine } from 'service/productLineApi'
import { ProductType } from '@customTypes/product'
import { STANDARD } from '@constants/productLines'

const LIMIT = 50

const SelectProducts = ({ selectedProducts = [], setSelectedProducts, simplified = false, style = {}, plants, quote, entries }: any) => {
    const DEFAULT_FETCH_QUERY = {
        filterColumns: ['product.status', 'query', 'plants'],
        filterValues: ['active', '', plants?.join(';')],
        limit: LIMIT
    }

    const [filter, setFilter] = useState(DEFAULT_FETCH_QUERY)
    const [searchValue, setSearchValue] = useState('')
    const { notifyError } = useNotify()
    const selectedFUllProducts: any = useRef(new Map())
    const debauncedFilter = useDebounceValue(filter, 500)

    const { sort, updateSort }: any = useTableSettings({
        tableKey: `table:select-products`,
        columns: productColumns,
        autoSaveView: true
    })

    const { data: standardProductLines } = useQuery<any, any, ProductType[]>(
        ['product-lines', 'standard'],
        () => fetchProductLine({ filterColumns: 'key', filterValues: STANDARD }),
        {
            select: data => data.items
        }
    )

    const params = {
        filterColumns: debauncedFilter.filterColumns.join(','),
        filterValues: debauncedFilter.filterValues.join(','),
        limit: filter.limit,
        ...sort
    }

    const {
        data,
        error: productsErrors,
        isLoading: productsLoading
    } = useQuery(['products', debauncedFilter, sort], () => fetchProductsEntity(params, true), {
        select: data => {
            const filteredItems = [...data.items].reduce((acc, cur) => {
                if (cur.productType?.key === STANDARD && cur.plant._id !== (plants || [])[0]) return acc
                return [...acc, cur]
            }, [])
            return { items: filteredItems, details: data.details }
        },
        enabled: Boolean(standardProductLines) && Boolean(sort)
    })

    useEffect(() => {
        if (!productsErrors || !Array.isArray(productsErrors) || productsErrors.length === 0) return
        notifyError(productsErrors[0].message)
    }, [productsErrors])

    useEffect(() => {
        if (!searchValue || searchValue === '') {
            setFilter(DEFAULT_FETCH_QUERY)
            return
        }
        const newFilter = { ...debauncedFilter }
        const queryIndex = newFilter.filterColumns.findIndex((c: string) => c === 'query')
        newFilter.filterValues[queryIndex] = searchValue
        setFilter(newFilter)
    }, [searchValue])

    function handleChangeSearch(e: any) {
        setSearchValue(e.target.value)
    }

    function handleClearSearch() {
        setSearchValue('')
    }

    function onSort(field: any) {
        updateSort({ sort: field, order: sort.order === 'desc' ? 'asc' : 'desc' })
    }

    function onChange(selectedIds: any = []) {
        selectedIds.forEach((id: string, i: number) => {
            const product = data?.items.find((item: any) => item._id === id)
            if (product) selectedFUllProducts.current.set(id, product)
        })
        selectedFUllProducts.current.forEach((_: any, key: string) => {
            if (selectedIds.includes(key)) return
            selectedFUllProducts.current.delete(key)
        })
        setSelectedProducts(Array.from(selectedFUllProducts.current.values()))
    }

    const exceptedProducts = []
    const filteredProducts = data?.items?.filter((product: any) => {
        if (!entries || entries.length === 0) return true
        for (let i = 0; i < entries.length; i++) {
            const entry = entries[i]
            if (product._id === entry.product._id) {
                exceptedProducts.push(product)
                return false
            }
        }
        return true
    })

    const productsCount = data?.details?.count - exceptedProducts.length || 0

    return (
        <div style={{ paddingBottom: '40px', position: 'relative', ...style }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', justifyContent: 'space-between' }}>
                <TableSummary title='All Products' total={productsCount} selected={selectedProducts.length} />
                <Search style={{ width: '260px' }} value={searchValue} onChange={handleChangeSearch} onClear={handleClearSearch} />
            </div>
            <MultiSelectTable
                data={filteredProducts}
                columns={productColumns}
                style={{ height: '400px' }}
                selected={selectedProducts.map((product: any) => product._id)}
                onChange={onChange}
                sort={sort}
                onSort={onSort}
                filter={debauncedFilter}
                dataProps={{addedItems: entries}}
            />
            <Show condition={productsLoading}>
                <div style={{ position: 'absolute', left: '40%', top: '40%' }}>
                    <h2 style={{ background: 'white' }}>Loading...</h2>
                </div>
            </Show>
        </div>
    )
}

export default SelectProducts
