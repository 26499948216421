import { OPEN_GLOBAL_MODAL, HIDE_GLOBAL_MODAL } from './types';


// TODO: Check if we can remove `async`
export const openGlobalModal = (payload) => async (dispatch) => {
    dispatch({ type: OPEN_GLOBAL_MODAL, payload });
};

export const closeGlobalModal = () => async (dispatch) => {
    dispatch({ type: HIDE_GLOBAL_MODAL });
};
