export const FETCH_LIST_CONTACTS = 'FETCH_LIST_CONTACTS';
export const FETCH_LIST_CONTACTS_SUCCESS = 'FETCH_LIST_CONTACTS_SUCCESS';
export const FETCH_LIST_CONTACTS_FAIL = 'FETCH_LIST_CONTACTS_FAIL';

export const ADD_CONTACT = 'ADD_CONTACT';
export const ADD_CONTACT_SUCCESS= 'ADD_CONTACT_SUCCESS';
export const ADD_CONTACT_FAIL = 'ADD_CONTACT_FAIL';

export const EDIT_CONTACT = 'EDIT_CONTACT';
export const EDIT_CONTACT_SUCCESS= 'EDIT_CONTACT_SUCCESS';
export const EDIT_CONTACT_FAIL = 'EDIT_CONTACT_FAIL';

export const DELETE_CONTACT = 'DELETE_CONTACT';
export const DELETE_CONTACT_SUCCESS= 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAIL = 'DELETE_CONTACT_FAIL';

export const CLEAR_ERROR = 'CLEAR_ERROR';
