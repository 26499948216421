import { createContext, ReactNode, useContext } from 'react'

import { TableColumn } from '@customTypes/table'

interface TableContextType {
    tableFields: TableColumn[]
    displayFields: TableColumn[]
}

const TableContext = createContext<TableContextType | null>(null)

interface Props {
    tableFields: TableColumn[]
    displayFields: TableColumn[]
    children: ReactNode
}
export const TableProvider = ({ tableFields, displayFields, children }: Props) => {
    const value = {
        tableFields,
        displayFields
    }
    return <TableContext.Provider value={value}>{children}</TableContext.Provider>
}

export const useTableState = (): TableContextType => {
    const context = useContext(TableContext)

    if (context === null) {
        throw new Error(`Received null while calling useContext(TableContext), did you forget to put the provider?`)
    }

    return context
}
