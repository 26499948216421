import styled from 'styled-components'

export const Button = styled.button`
    height: 40px;
    border-radius: 6px;
    padding: 10px 25px 10px 25px;
    background: ${props => props.secondary ? "#FF8939" : props.disabled ? "lightgray" : "#0E3F66"};
    color: white;
    border: none;
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
    font-weight: 600;
    font-size: 14px;
`

export const Input = styled.input`
    font-size: 15px;
    border: ${props => (props.error ? '1px solid #f7685b' : '1px solid #e0e7ff')};
    min-height: 40px;
    display: flex;
    border-radius: 5px;
    background: linear-gradient(0deg, rgba(224, 231, 255, 0.2), rgba(224, 231, 255, 0.2)), #ffffff;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    width: ${props => props.w100 ? '100%' : ''};
    ::placeholder,
    ::-webkit-input-placeholder {
        color: rgba(110, 140, 163, 0.6);
    }
    :-ms-input-placeholder {
        color: rgba(110, 140, 163, 0.6);
    }
`
export const InputLabel = styled.div`
        position: absolute;
        background-color: #dbedff;
        border: 1px solid #e0e7ff;
        border-radius: 0px 5px 5px 0px;
        right: 0px;
        top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 41px;
        min-width: 41px;
`

export const InputNumber = styled(Input).attrs({ type: 'number' })`
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;
`

export const Label = styled.label`
    color: #6E8CA3;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
`

