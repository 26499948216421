import { Customer } from '@customTypes/customer'
import Repository, { PaginatedResponse } from './Repository'

class CustomersRepository extends Repository<Customer> {
    constructor(route: string) {
        super(route)
    }

    getCustomerContacts = async (customerId?: string, query?: any): Promise<PaginatedResponse<Customer>> => {
        const params = new URLSearchParams()
        params.append('data', JSON.stringify(query))
        return (await this.api.get<PaginatedResponse<Customer>>(`${this.route}/${customerId}/contacts/pagination`, { params })).data
    }
}

export default new CustomersRepository('customers')
