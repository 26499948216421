import service from './service'

export const fetchListUsers = async (params = {}) => {
    const res = await service.get(`/users`, { params })
    return res.data
}

export const updateUser = async user => {
    const res = await service.put(`/users/${user._id}`, user)
    return res.data
}

export const reInviteUser = async userId => {
    const res = await service.post(`/users/reinvite/${userId}`)
    return res.data
}

export const setUserStatus = async (userId, status) => {
    const res = await service.post(`/users/${userId}/status/${status}`)
    return res.data
}

export const createUser = async user => {
    const res = await service.post('/users/', user)
    return res.data
}

export const deleteUser = async id => {
    await service.delete(`/users/${id}`)
}

export const archiveUser = async id => {
    const res = await service.post(`/users/archive/${id}`)
    return res.data
}

export const resetPassword = async body => {
    const res = await service.post(`/users/forgot-password`, body)
    return res.data
}

export const fetchUser = async id => {
    const res = await service.get(`/users/${id}`)
    return res.data
}

export const mergeUsers = async body => {
    const res = await service.post(`/users/merge`, body)
    return res.data
}

export const generateMeltingSpotToken = async () => {
    const res = await service.get(`/users/melting-spot-token`)
    return res.data
}
