import React, { useState, useRef } from 'react';
import { Feather, Dropdown, Currency } from '@components';
import { useOnClickOutside } from 'utils/onClickOutside';

const Pagination = ({ customStyle, tableData, pagination, parentComponent, limit, data, details, controlPanel, onChangeLimit, onChangePage, onToggleAllCheckBox, onSelected }) => {
    const [downloadMenu, setDownloadMenu] = useState(false);
    const downloadRef = useRef(null);
    useOnClickOutside(downloadRef, () => setDownloadMenu(false));

    return (
        <div className='control control-detail-page'>
            <div className='control__block'>
                <div className='control__block__page'>
                    {tableData.filter(e => e.isCheck).length === 0 ? (
                        pagination && (
                            <>
                                <div className='row-per-page'>
                                    <span>Rows per page:</span>
                                    <Dropdown
                                        parentComponent={parentComponent}
                                        options={[
                                            { label: '10', value: 10 },
                                            { label: '20', value: 20 },
                                            { label: '50', value: 50 }
                                        ]}
                                        value={limit}
                                        onChange={e => onChangeLimit(e.value)}
                                        border={false}
                                        clearDisable
                                    />
                                </div>
                                <div className='switch-page'>
                                    <span>Rows</span>
                                    <div className='switch-page__page'>
                                        {data.length ? (details.page - 1) * limit + 1 : 0} - {(details.page - 1) * limit + data.length}
                                    </div>
                                    <Feather name={'ChevronLeft'} className={`${details.page === 1 ? 'disabled' : ''}`} onClick={() => onChangePage(details.page - 1)} />
                                    <Feather
                                        name={'ChevronRight'}
                                        className={`${((details.pages || (Math.ceil(details.count / details.limit))) === details.page) ? 'disabled' : ''}`}
                                        onClick={() => onChangePage(details.page + 1)}
                                    />
                                </div>
                            </>
                        )
                    ) : (
                        <div className='selected_item'>
                            <Feather
                                name='X'
                                onClick={() => {
                                    onToggleAllCheckBox(false);
                                    onSelected([]);
                                }}
                            />
                            <div className='selected_item__number'>
                                <span>{tableData.filter(e => e.isCheck).length}</span>
                            </div>
                            <span>item(s) selected</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Pagination;
