import React from 'react';

const Modal = (props) => {
    const {
        modalRef,
        children,
        width = 400,
    } = props;

    const styles = { maxWidth: `${width}px` };

    const className = `OptionsModal OptionsModal--visible ${props.className}`;

    return (
        <div className={className} ref={modalRef}>
            <div className='OptionsModal__wrapper' style={styles}>
                {children}
            </div>
        </div>
    );
};

export default Modal;
