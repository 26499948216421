import service from './service';

export const checkIfNameExists = async name => {
    const res = await service.get(`/user-groups/name-exist/${name}`);
    return res.data;
};

export const fetchUserGroups = async (params) => {
    const res = await service.get(`/user-groups`, params);
    return res.data;
};

export const fetchUserGroup = async id => {
    const res = await service.get(`/user-groups/${id}`);
    return res.data;
};

export const updateUserGroup = async params => {
    const res = await service.put(`/user-groups/${params._id}`, params);
    return res.data;
};

export const saveUserGroup = async params => {
    const res = await service.post('/user-groups', params);
    return res.data;
};

export const deleteUserGroup = async id => {
    const res = await service.delete(`/user-groups/${id}`);
    return res.data;
};
