import * as types from './types';

const initialState = {
    financialYears: [],
    details: { count: 0, filter: '', limit: 0, page: 0, pages: 0 },
    loading: false
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_LIST_FINANCIAL_YEARS:
            return {
                ...state,
                loading: true
            };
        case types.FETCH_LIST_FINANCIAL_YEARS_SUCCESS:
            return {
                ...state,
                financialYears: action.payload.items,
                details: action.payload.details,
                loading: false
            };
        case types.FETCH_LIST_FINANCIAL_YEARS_FAIL:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default reducer;
