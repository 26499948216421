import React, { useState, useMemo } from 'react';

import { Radio } from '@components';
import '@scss/modals/_price-planning-option-select-modal.scss';

const PricePlanningOptionSelectModal = ({ mssg = '', options, selectedOption, onSelectedOptionChange = () => {} }) => {
    return (
        <div className='PricePlanningOptionSelectModal'>
            <div className='PricePlanningOptionSelectModal--mssg'>{mssg}</div>
            {options.map((o, i) => (
                <Radio
                    className='mb-10'
                    key={`radio-preview-${i}`}
                    id={`radio-preview-${i}`}
                    label={o.label}
                    name='optionSelect'
                    value={o.value}
                    checked={selectedOption === o.value}
                    onChange={onSelectedOptionChange}
                />
            ))}
        </div>
    );
};

export default PricePlanningOptionSelectModal;
