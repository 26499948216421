import React, { RefObject } from 'react'
import styled from 'styled-components'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react'

import { DEFAULT_COL_DEF } from './const'

import StyledAgGrid from './styled'

import 'ag-grid-community/styles/ag-grid.css' // Core CSS
import 'ag-grid-community/styles/ag-theme-alpine.css' // Theme
import { ColDef } from 'ag-grid-community'

export interface AgGridProps extends AgGridReactProps {
    isLoading?: boolean
    gridRef?: RefObject<AgGridReact>
}

const AgGrid: React.ComponentType<AgGridProps> = props => {
    const { columnDefs, rowData, isLoading, gridRef, ...agGridProps } = props

    const loadingData = new Array(20).fill({})

    const loadingColumns: ColDef[] = (columnDefs || []).map(c => ({
        ...c,
        cellRenderer: () => (
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <div className='agg-table-loading'></div>
            </div>
        )
    }))

    return (
        <AgGridWrapper className='ag-theme-alpine'>
            <StyledAgGrid
                defaultColDef={DEFAULT_COL_DEF}
                pagination={true}
                rowSelection='single'
                rowData={isLoading ? loadingData : rowData}
                columnDefs={isLoading ? loadingColumns : columnDefs}
                ref={gridRef}
                {...agGridProps}
            />
        </AgGridWrapper>
    )
}

const AgGridWrapper = styled.div`
    width: 100%;
    height: 100%;
`

export default AgGrid
