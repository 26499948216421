import * as types from './types';

const initialState = {
    plantTierSettings: [],
    details: { count: 0, filter: '', limit: 0, page: 0, pages: 0 },
    loading: false
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_LIST_PLANT_TIER_SETTINGS:
            return {
                ...state,
                loading: true
            };
        case types.FETCH_LIST_PLANT_TIER_SETTINGS_SUCCESS:
            return {
                ...state,
                plantTierSettings: action.payload.items,
                details: action.payload.details,
                loading: false
            };
        case types.FETCH_LIST_PLANT_TIER_SETTINGS_FAIL:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default reducer;
