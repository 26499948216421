import { Button, Dropdown, Input, Checkbox } from "@common";
import { SettingsType, View } from "@customTypes/table";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNotify } from "@components/index";

type MyViewsProps = {
    views?: View[]
    updateView: (update: Partial<View>, settingsPayload?: Partial<SettingsType>) => void
    view?: View,
    defaultView: View
}

const MyViews = ({ views, updateView, view, defaultView }: MyViewsProps) => {
    const [checked, setChecked] = useState<View['name'][]>([])
    const [inputView, setInputView] = useState(false)
    const [newViewValue, setNewViewValue] = useState('')
    const { notifyWarning } = useNotify()
    // const viewsObj = Object.fromEntries(views?.map(v=>([v.name, v])) || [])

    useEffect(() => {
        if (view && view.name !== 'autosave') setChecked([view.name])
        else setChecked([])
    }, [view])

    function handleSelect(name: string) {
        setInputView(false)
        const existIndex = checked.findIndex(c => c === name)
        const newChecked = [...checked]
        if (existIndex > -1) {
            newChecked.splice(existIndex, 1)
            setChecked(newChecked)
        }
        else {
            newChecked.push(name)
            setChecked(newChecked)
        }
    }

    function handleSave() {
        const viewNames = views?.map(v => v.name)
        if (viewNames?.includes(newViewValue)) return notifyWarning('View with the same title already saved', 'Duplicate')
        if (checked.length === 0 && newViewValue) {
            updateView({ ...view, name: newViewValue }, { lastShown: newViewValue })
        } else if (checked.length === 1) {
            updateView({ ...view, name: checked[0] }, { lastShown: checked[0] })
        }
        setInputView(false)
        setNewViewValue('')
    }

    function handleLoad() {
        if (checked.length === 1) {
            updateView({}, { lastShown: checked[0] })
        }
    }

    function handleDelete() {
        const newViews = [...(views || [])].filter(nv => !checked.includes(nv.name))
        const settingsUpdate: SettingsType = { views: newViews }
        if (checked.includes(view?.name || '')) settingsUpdate.lastShown = 'autosave'
        updateView({}, settingsUpdate)
        setChecked([])
    }

    function handleNew() {
        setInputView(true)
        setChecked([])
    }

    function handleChangeNewViewValue(e: React.ChangeEvent<HTMLInputElement>) {
        setNewViewValue(e.target.value)
    }

    function handleResetView() {
        updateView(defaultView, { lastShown: 'autosave' })
    }

    return (
        <Dropdown
            renderTrigger={<Button bordered>My Views</Button>}
            renderContent={
                <MyViewsContent>
                    <MyViewsHeader>
                        <a style={{ cursor: 'pointer' }} onClick={handleResetView}>
                            Default view
                        </a>
                    </MyViewsHeader>
                    <ul>
                        {views?.filter(v => v.name !== 'autosave' && Boolean(v.name))
                            .map((view) => (
                                <li key={view.name}>
                                    <Checkbox
                                        id={view.name}
                                        onChange={() => handleSelect(view.name)}
                                        type="checkbox"
                                        checked={checked.includes(view.name)}
                                    >
                                        {view.name}
                                    </Checkbox>
                                </li>
                            ))}
                    </ul>
                    <div style={{ marginTop: '10px' }}>
                        {inputView ?
                            <Input value={newViewValue} onChange={handleChangeNewViewValue} />
                            : <Button bordered onClick={handleNew}>New +</Button>
                        }
                    </div>
                    <MyViewsControls>
                        <Button
                            onClick={handleDelete}
                            disabled={inputView || checked.length === 0}
                        >
                            Delete
                        </Button>
                        <Button
                            onClick={handleLoad}
                            disabled={checked.length > 1 || inputView || checked.length === 0}
                        >
                            Load
                        </Button>
                        <Button
                            onClick={handleSave}
                            disabled={(!newViewValue?.length && !checked.length) || checked.length > 1}
                        >
                            Save
                        </Button>
                    </MyViewsControls>
                </MyViewsContent>
            }
        />
    )
}

export default MyViews

const MyViewsContent = styled.div`
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    color: rgb(14, 63, 102);
    ul {
        list-style: none;
        input[type="checkbox" i] {
            accent-color: #ff8939;
        }
        li {
            display: flex;
            align-items: center;
            gap: 5px;
            margin: 10px 0;
            padding: 5px;
            border-bottom: 1px solid rgba(14, 63, 102, 0.05)
        }
    }
`

const MyViewsControls = styled.div`
    display: flex;
    gap: 5px;
    margin-top: 15px;
`

const MyViewsHeader = styled.div`
    a {
        color: rgba(14, 63, 102, 1);
    }
    border-bottom: 1px solid rgba(14, 63, 102, 0.05);
`
