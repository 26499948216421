import React from 'react';

import { useProductLinesApi } from 'service/productLineApi';

export const READYMIX = 'readymix';
export const ASPHALT = 'asphalt';
export const AGGREGATES = 'aggregates';

const validPlantType = plantType => {
    const plant = plantType?.key || plantType
    if (typeof plant === 'string') return plant.toLowerCase();
};

export const usePlantType = () => {
    const productLinesApi = useProductLinesApi();

    const { data, isLoading: loading } = productLinesApi.getPaginatedList();


    const isTypeReadymix = plantType => validPlantType(plantType) === READYMIX;
    const isTypeOther = plantType => !(validPlantType(plantType) === READYMIX || validPlantType(plantType) === AGGREGATES || plantType.key === ASPHALT);
    const isTypeAsphalt = plantType => validPlantType(plantType) === ASPHALT;
    const isTypeAggregates = plantType => validPlantType(plantType) === AGGREGATES;

    return {
        plantTypes: data?.items || [],
        loading,

        isTypeReadymix,
        isTypeOther,
        isTypeAsphalt,
        isTypeAggregates
    };
};
