import React, { useState } from 'react';
import { Dropdown } from '@components'

const SelectCompetitorModal = ({
    competitors,
    selected,
    onCompetitorSelect = () => { }
}) => {
    return (
        <Dropdown
            label='Select competitor'
            value={selected}
            options={competitors.map(x => { return { value: x._id, label: x.name } })}
            onChange={e => onCompetitorSelect(e.value)}
        />
    )
}

export default SelectCompetitorModal;