import React from 'react'
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

const Link = styled(RouterLink)`
	text-decoration: underline !important;
	cursor: pointer !important;

	&:hover, &:visited {
		text-decoration: underline !important;
		cursor: pointer !important;
	}
`

export default Link
