import * as types from './types';
import service from '../../../service/service';
import { _cloneDeep } from '../../../utils/jsonUtil';

const toggleVisibleFields = (key, fields) => {
    return dispatch => {
        dispatch({ type: types.TOGGLE_VISIBLE_CUSTOMER_FIELD, payload: { key, fields } });
    };
};

const hideAllFields = (key) => {
    return dispatch => {
        dispatch({ type: types.HIDE_ALL_FIELDS, payload: { key } });
    };
};

const restoreDefaultView = (key, defaultColumns) => {
    return dispatch => {
        dispatch({ type: types.RESTORE_DEFAULT_VIEW, payload: { key, defaultColumns } });
    };
};


const showAllFields = (key) => {
    return dispatch => {
        dispatch({ type: types.SHOW_ALL_FIELDS, payload: { key } });
    };
};

const loadVisibleFields = (key, fields) => {
    return dispatch => {
        dispatch({ type: types.LOAD_VISIBLE_FIELDS, payload: { key, fields } });
    };
};

const getMyViews = (key, defaultcolumns, section) => {
    return dispatch => {
        dispatch({ type: types.GET_MY_VIEWS });
        service
            .get(`/settings/${key}?section=${section}`)
            .then(res => {
                const views = res.data.views;
                views.map(view => {
                    let updatedColumns = [];
                    view.columns.map((col, index) => {
                        const newCol = { ...defaultcolumns[index], ...col };
                        updatedColumns.push(newCol);
                    });
                    view.columns = updatedColumns;
                });
                dispatch({ type: types.GET_MY_VIEWS_SUCCESS, payload: { ...res.data, views } });
            })
            .catch(error => dispatch({ type: types.GET_MY_VIEWS_FAIL, payload: error }));
    };
};

const loadMyViews = (key, fields) => {
    return dispatch => {
        dispatch({ type: types.LOAD_MY_VIEWS, payload: { key, fields } });
    };
};

const setMyView = (defaultcolumns, key, body = {}, skipDispatch = false) => {
    return dispatch => {
        !skipDispatch && dispatch({ type: types.SET_MY_VIEW });
        service
            .post(`/settings/${key}`, body)
            .then(res => {
                const views = res.data.views;
                views.map(view => {
                    let updatedColumns = [];
                    view.columns.map((col, index) => {
                        const newCol = { ...defaultcolumns[index], ...col };
                        updatedColumns.push(newCol);
                    });
                    view.columns = updatedColumns;
                });
                !skipDispatch && dispatch({ type: types.SET_MY_VIEW_SUCCESS, payload: { ...res.data, views } });
            })
            .catch(error => {
                !skipDispatch && dispatch({ type: types.SET_MY_VIEW_FAIL, payload: error });
            });
    };
};
export { loadVisibleFields, toggleVisibleFields, getMyViews, loadMyViews, setMyView, hideAllFields, showAllFields, restoreDefaultView };
