import React, { useState, useEffect, useRef } from 'react';

import Search from '@components/UI/Search'

import { Icon, Checkbox } from '@common'

import Show from '../Show';
import { FieldError } from '@components'
import styles from './dropdown.module.scss'

const Dropdown = (props) => {
    const {
        options = [],
        value,
        renderValue,
        readOnly = false,
        rowIndex = 0,
        onChange = () => { },
        label,
        multiple = false,
        placeholder,
        placeholderStyle,
        menuStyle,
        style = {},
        iconWrapperStyle = {},
        freeze = false,
        icon,
        error = false,
        searchEnabled = false,
        selectAllEnabled = false,
        renderInput = null,
        autoFill = false,
        id,
        formErrors
    } = props;

    const [selected, setSelected] = useState([])
    const [search, setSearch] = useState('')
    const checkbox = useRef();

    const [formError, setError] = useState(null);
    useEffect(() => {
        setError(formErrors?.find(x => x.id === id)?.message);
    }, [formErrors]);
    
    useEffect(() => {
        if (value === null) return setSelected([]);
        if (typeof value === 'undefined') return;
        if (!multiple) return setSelected([value]);
        setSelected(value);
    }, [value, multiple]);

    const optionsHash = new Map();
    options.map(o => optionsHash.set(o.value, o.label));

    let filteredOptions = []
    if (search && search.length > 0) {
        filteredOptions = options.filter(o => (o.label.toLowerCase().indexOf(search.toLowerCase()) >= 0))
    } else filteredOptions = options

    const onCheckboxChange = (id, i) => {
        if (freeze) {
            return;
        }

        const index = selected.indexOf(id);
        if (!multiple) {
            checkbox.current.checked = false;
            if (options[i]?.action) return options[i].action()
            onChange(id);
            setSelected([id]);
            return;
        }

        let result;
        if (index < 0) {
            result = [...selected, id];
        } else {
            result = [...selected];
            result.splice(index, 1);
        }

        onChange(result);
        setSelected(result);
    }

    function handleSelectAll() {
        if (selectedAll()) {
            const result = selected.filter(value => {
                const option = filteredOptions.find(o => o.value === value)
                if (!option) return true
                return false
            })
            onChange(result);
            setSelected(result)
            return
        }
        onChange([...filteredOptions.map(o => o.value)]);
        return setSelected([...filteredOptions.map(o => o.value)])
    }

    function selectedAll() {
        let c = 0
        filteredOptions.forEach(o => {
            if (selected.includes(o.value)) c = c + 1
        })
        if (c === 0) return false
        if (c === filteredOptions.length) return true
        return false
    }

    function getDropdownStyle() {
        const style = `${styles['dropdown']}`
        if (renderInput) {
            return style
        } else {
            return `${styles['dropdown']} ${readOnly && styles['dropdown-disabled']} ${(error || !!formError) ? styles['error'] : ''}`
        }
    }

    return (
        <>
            {label &&
                <div className='Label'>
                    {label}
                </div>
            }
            {autoFill ? (<div><Show condition={selected.length > 2}>
                {selected.length} Selected
            </Show>
                <Show condition={selected.length > 0 && selected.length <= 2}>
                    {selected.map((s, i) => (
                        <span
                            key={optionsHash.get(s) || i}
                            className={`${styles['item']} ${readOnly && styles['item-disabled']}`}
                        >
                            {optionsHash.get(s)}
                        </span>
                    ))}
                </Show>
                <Show condition={selected.length == 0}>
                    <div>-</div>
                </Show>
            </div>) :
                (<div className={getDropdownStyle()} style={style}>
                    {!readOnly && <input ref={checkbox} type='checkbox' />}
                    <Show condition={renderInput}>
                        {renderInput && renderInput()}
                    </Show>
                    <Show condition={!renderInput}>
                        <Show condition={selected.length === 0}>
                            <div className={`${styles['placeholder']}`} style={placeholderStyle}>
                                {renderValue ? renderValue({ label: placeholder }) : placeholder}
                            </div>
                        </Show>
                        <Show condition={selected.length > 2}>
                            {selected.length} Selected
                        </Show>
                        <Show condition={selected.length > 0 && selected.length <= 2}>
                            {selected.map((s, i) => (
                                <span
                                    key={optionsHash.get(s) || i}
                                    className={`${styles['item']} ${readOnly && styles['item-disabled']}`}
                                >
                                    {renderValue ? renderValue({ label: optionsHash.get(s) }) : optionsHash.get(s)}
                                </span>
                            ))}
                        </Show>
                        <div className={`${styles['icon-wrapper']} ${readOnly && styles['icon-wrapper-disabled']}`} style={iconWrapperStyle}>
                            <Show condition={icon}>
                                {icon}
                            </Show>
                            <Show condition={!icon}>
                                {/* <img className='arrow_icon' src='/static/media/Arrow.7251893fe57b2d4b4435c037979aa041.svg' alt='icon' /> */}
                                <Icon name='arrow-down' style={{ color: readOnly ? 'lightgray' : 'rgb(14, 63, 102)', width: '24px', height: '24px' }} />
                            </Show>
                        </div>
                    </Show>
                    <ul style={menuStyle}>
                        <Show condition={searchEnabled}>
                            <li className={`${styles['search']}`}>
                                <Search
                                    inputStyle={{ width: '100%', border: 'none', background: 'none' }}
                                    value={search}
                                    onChange={(e) => { setSearch(e.target.value) }}
                                    onClear={() => setSearch('')}
                                />
                            </li>
                        </Show>
                        <Show condition={selectAllEnabled}>
                            <li className={`${styles['select-all']}`} style={{ top: searchEnabled ? null : 0 }}>
                                <Checkbox
                                    checked={selectedAll()}
                                    onChange={handleSelectAll}
                                >Select all</Checkbox>
                            </li>
                        </Show>
                        <Show condition={filteredOptions.length === 0}>
                            <li style={{ color: 'lightgray' }}>No data</li>
                        </Show>
                        {filteredOptions.map((o, i) => (
                            <li key={o.value}>
                                {multiple ? (
                                    <Checkbox disabled={o.disabled} rowIndex={rowIndex} id={o.value} checked={selected.includes(o.value)} onChange={() => onCheckboxChange(o.value, i)}>{o.label}</Checkbox>
                                ) : (
                                    // <div onClick={() => {!o.disabled && onCheckboxChange()}}>{o.label}</div>
                                    <div
                                        style={{ color: o.disabled ? 'lightgray' : 'inherit' }}
                                        onClick={() => { !o.disabled && onCheckboxChange(o.value, i) }}
                                    >
                                        {o.label}
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>)}
            <FieldError error={formError} variant={'primary'} />
        </>
    );
};

export default Dropdown;
