import { CheckCircle, InformationOrange } from '@assets';

import { Button, CollapsiblePanel, Currency, Feather, Image } from '@components';

import { calculateTripDuration } from '@panels/CalculateTransport/utils';

import { isEmpty } from 'utils/object';

import TransportShortInfo from './TransportShortInfo';

import './_style.scss';
import { fetchTrucks } from 'service/trucksApi';
import { useQuery } from '@tanstack/react-query';

import calculateTransportPrice from '@utils/calculateTransportPrice'

const RIPPLE_PROPS = {
    color: '#0e3f66',
    duration: 550
};

const TransportData = props => {
    const { page, readOnly, mapRoutes = {}, selectedMapRoutes = {}, handleReset = () => {}, onClick = () => {}, truckList } = props;
    const { data: trucksData } = useQuery(['truck'], () => fetchTrucks({limit: 500}));
    const trucks = trucksData?.items || [];

    function calculateTransportInfo({ transport, plant }) {
        if (!transport || !plant) return;

        const isDelivered = transport.costPerTon || transport.costPerTon === 0 ? true : false;
        const selectedTruck = trucks.find(t => t._id === transport?.truck);
        const statusIcon = !isDelivered ? InformationOrange : CheckCircle;
        const index = selectedMapRoutes[plant._id] || 0;

        /**
         * needed to display correct transport time value before the user click on calculate transport
         * without this it will display only loading time + unloading time default value
         * because the google roads are not calculated yet
         */
        const calculatedTripDuration =
            transport?.calculatedTripTime >= 0 ? transport?.calculatedTripTime : calculateTripDuration(mapRoutes[plant._id] ? mapRoutes[plant._id][index] : {});

        const calculatedTripTime = calculatedTripDuration * 2 + transport.loadingTime + transport.unloadingTime;

        const calculatedTransportPrice = !isDelivered ? calculateTransportPrice(selectedTruck, transport, calculatedTripDuration) : transport?.costPerTon;
        const calcBtnColor = !isDelivered ? '#FF8939' : '#20B263';
        const unit = selectedTruck?.material?.defaultUOM?.value || '';

        return {
            color: isEmpty(page?.address) ? undefined : calcBtnColor,
            truckName: selectedTruck?.name || '',
            type: 'Truck',
            costPerTon: <Currency value={calculatedTransportPrice} suffix={unit} />,

            timeTitle: (
                <>
                    Transport time <Image src={statusIcon} className='status-icon' />
                </>
            ),
            timeValue: `${calculatedTripTime || 0} min`,
            timeClassName: !isDelivered ? 'default' : 'calculated'
        };
    }

    return (
        <CollapsiblePanel title='Transport information'>
            <div className='TransportData'>
                <div className='mb-20'>Calculate the transport cost.</div>
                <div className='mt-20 flex-start'>
                    <Button disabled={readOnly} onClick={onClick} customClass='btnold--collapse center' ripple={RIPPLE_PROPS} data-cy='calculateTransport'>
                        <Feather name='Target' />
                        <span>Calculate Transport</span>
                    </Button>
                    <Button disabled={readOnly} onClick={handleReset} customClass='btnold--collapse center ml-1' ripple={RIPPLE_PROPS}>
                        <span>Apply default</span>
                    </Button>
                </div>
                {Boolean(page?.address) &&
                    page?.plantsInfo?.map((item, i) => (
                        <div key={i}>
                            <TransportShortInfo
                                key={i}
                                transportInfo={calculateTransportInfo({ transport: item.transport, plant: item.plant, item })}
                                pageAddress={page?.address}
                                index={i}
                                plantName={item?.plant?.name}
                            />
                            {i !== page?.plantsInfo?.length - 1 && <div style={{ background: '#E0E7FF', height: '1px', width: '100%', marginTop: '16px' }} />}
                        </div>
                    ))}
            </div>
        </CollapsiblePanel>
    );
};

export default TransportData;
