import { GridCellProps } from '@progress/kendo-react-grid'
import { forwardRef } from 'react'

export interface CustomCellProps extends GridCellProps {
    column?: any
    enterEdit?: any
    dataProps?: any
    edit: { _id: string; field: string } | null
    readOnly?: boolean
}

const cellStyle = { height: '100%', width: '100%', display: 'flex', alignItems: 'center' }

const CustomCell = forwardRef((props: CustomCellProps, ref) => {
    const inEdit = props.edit ? props.field === props.edit.field && props.dataItem._id === props.edit._id : false
    const value = props.dataItem[props.field || '']
    function handleEdit() {
        if( typeof props.dataProps?.handleCellClick === 'function') props.dataProps?.handleCellClick(props)
        if (props.readOnly) return
        props.column?.editable && props.enterEdit && props.enterEdit(props.dataItem, props.field)
    }
    if (inEdit) return props.column.renderEdit ? props.column.renderEdit({ row: props.dataItem, dataProps: props.dataProps }) : null
    if (props.column.render)
        return (
            <div onClick={handleEdit} style={{ ...cellStyle, justifyContent: props.column.align ? props.column.align : 'left', cursor: props.column?.editable ? 'pointer' : '' }}>
                {props.column.render({ row: props.dataItem, dataProps: props.dataProps })}
            </div>
        )
    return (
        <div onClick={handleEdit} style={{ ...cellStyle, justifyContent: props.column.align ? props.column.align : 'left', cursor: props.column?.editable ? 'pointer' : '' }}>
            {typeof value === 'string' || typeof value === 'number' ? value : ''}
        </div>
    )
})

export default CustomCell
