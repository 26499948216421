import React from 'react';

import '@scss/components/_radio.scss';

const Radio = props => {
    const {
        id = '',
        name = '',
        label = '',
        checked = false,
        value = null,
        readOnly = false,
        switcher = false,
        className = '',
        
        onChange = () => {},
    } = props;

    const radioClassName = `${switcher ? 'Switch' : 'Radio'} ${className}`;

    const onRadioChange = e => {
        const { value, name } = e.target;
        onChange(value, name);
    };

    return (
        <div className={radioClassName}>
            <input id={id} name={name} type='radio' value={value} checked={checked} disabled={readOnly} onChange={onRadioChange} />
            <label htmlFor={id}>{label}</label>
        </div>
    );
};

export default Radio;
