import styled, { css } from 'styled-components'

type ButtonProps = {
    secondary?: boolean
    absolute?: boolean
}

const IconButton = styled.button<ButtonProps>`
    position: ${({ absolute }) => (absolute ? 'absolute' : 'static')};
    background-color: ${({ secondary }) => (secondary ? '#FF8939' : '#ffffff')};
    border: 1px solid #e0e7ff;
    border-radius: 5px;
    border-right: none;
    border-top: none;
    border-bottom: 0;
    right: 1px;
    bottom: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    width: 40px;

    ${({ absolute }) =>
        absolute
            ? css`
                  &:hover {
                      background-color: rgba(255, 255, 255, 0.5);
                      border-color: #bfc7e0;
                  }
              `
            : ''}

    &:active {
        transform: scale(0.96);
    }
`

export default IconButton
