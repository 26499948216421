import { saveAs } from '@progress/kendo-file-saver'
import { AxiosInstance } from 'axios'

import service from './service'
import { FilterType } from 'filters/filter.types'

export type OperationResponse = {
    status: string
}

export type InsertManyResponse = {
    createdCount: number
    duplicatedCount: number
}

export type DeleteManyResponse = {
    deletedCount: number
    notDeletedCount: number
}

export type PaginatedResponse<T> = {
    details: Details
    items: T[]
    next?: NextPage
}

export type Details = {
    filter?: string
    count: number
    page?: number
    pages?: number | null
    limit?: number
}

export type NextPage = {
    page: number
    limit: number
}

export const getSessionConfig = (sessionId?: string) => {
    if (!sessionId) {
        return {}
    }
    return {
        headers: {
            'Session-ID': sessionId
        }
    }
}
class Repository<T extends {}> {
    protected readonly api: AxiosInstance
    protected readonly route: string

    constructor(route: string, customService: AxiosInstance = service) {
        this.api = customService
        this.route = route
    }

    get headers(): Record<string, string> {
        return this.api.defaults.headers.common
    }

    get importEndpoint(): string {
        return `${this.api.defaults.baseURL}/${this.route}/import`
    }

    getPaginatedList = async (params?: any): Promise<PaginatedResponse<T>> => {
        return (await this.api.get<PaginatedResponse<T>>(this.route, { params })).data
    }

    getSimplifiedList = async (params: any): Promise<T[]> => {
        return (await this.api.get<T[]>(`${this.route}/simplified`, { params })).data
    }

    export = async (query?: any): Promise<void> => {
        const { dateFormat, ...restParams } = query
        const params = new URLSearchParams()
        params.append('data', JSON.stringify(restParams))
        params.append('dateFormat', JSON.stringify(dateFormat))
        const res = await this.api.get<any>(`${this.route}/export`, { responseType: 'blob', params })
        const contentDisposition = res.headers['content-disposition']
        const filenameMatch = contentDisposition.match(/filename="([^"]+)/)
        const filename = filenameMatch[1]
        saveAs(res.data, filename)
    }

    getAll = async (params: any): Promise<T[]> => {
        return (await this.api.get<T[]>(`${this.route}/all`, { params })).data
    }

    getById = async (id: string): Promise<T> => {
        return (await this.api.get<T>(`${this.route}/${id}`)).data
    }

    create = async (data: Partial<T>, sessionId?: string): Promise<T> => {
        const config = getSessionConfig(sessionId)
        return (await this.api.post<T>(this.route, data, config)).data
    }

    update = async (id: string, data: Partial<T>, sessionId?: string): Promise<T> => {
        const config = getSessionConfig(sessionId)
        return (await this.api.put<T>(`${this.route}/${id}`, data, config)).data
    }

    remove = async (id: string): Promise<void> => {
        await this.api.delete<void>(`${this.route}/${id}`)
    }

    activate = async (id: string, data: T): Promise<T> => {
        return (await this.api.post<T>(`${this.route}/activate/${id}`, data)).data
    }

    changeStatus = async (id: string, status: string): Promise<T> => {
        return (await this.api.put<T>(`${this.route}/status/${id}`, { status })).data
    }

    bulkRemove = async (ids: string[]): Promise<string> => {
        return (await this.api.post<string>(`${this.route}/delete`, ids)).data
    }

    getRestrictedFields = async (): Promise<string[]> => {
        return (await this.api.get<string[]>(`${this.route}/restricted-fields`)).data
    }

    nextNo = async (params: any): Promise<string> => {
        return (await this.api.get<string>(`${this.route}/no`, { params })).data
    }

    getNewPaginatedList = async (query?: FilterType<T>) => {
        const params = new URLSearchParams()
        params.append('data', JSON.stringify(query))
        return (await this.api.get(`${this.route}/pagination`, { params })).data
    }
}

export default Repository
