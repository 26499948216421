import { useEffect, useMemo, useState } from 'react';

import { Feather } from '@components';
import { Table } from '@common';

import '@scss/components/_select-entity-table.scss';

const MAX_ROWS = 50;

const DEFAULT_SORTING = {
    sort: '',
    order: 'asc'
};

const SelectEntityTable = props => {
    const {
        options,
        columns,
        autosave = false,
        dataProps = {},

        onChange = () => { },
        onSort = () => { },
        onCancel = () => { }
    } = props
    const height = 440
    const rowHeight = 50
    const [searchText, setSearchText] = useState('')
    const [sort, setSort] = useState(DEFAULT_SORTING)
    const [filtered, setFiltered] = useState(options)
    const [selectedRowId, setSelectedRowId] = useState()
    const [selected, setSelected] = useState([]);
    const [limitedResults, setLimitedResults] = useState()

    useEffect(() => {
        const tmp = document.getElementsByClassName('overflow-overlay')
        if (tmp && HTMLCollection.prototype.isPrototypeOf(tmp)) {
            Array.from(tmp).forEach(element => {
                element.classList.add('overflow-hidden')
            })
        }
        return () => {
            const tmp = document.getElementsByClassName('overflow-overlay')
            if (tmp && HTMLCollection.prototype.isPrototypeOf(tmp)) {
                Array.from(tmp).forEach(element => {
                    element.classList.remove('overflow-hidden')
                })
            }
        }
    }, [])

    useEffect(() => {
        onSearch()
    }, [searchText])

    useEffect(() => {
        if (!autosave || sort.autoSaveView || !sort?.sort?.length || !sort?.order?.length) {
            return
        }

        onSort(sort)
    }, [sort])

    useEffect(() => {
        onChange(selectedRowId)
    }, [selectedRowId])

    // @TODO: Ask @Dan, if this logic still available
    // issue https://app.clickup.com/t/861m5vwr2
    // useEffect(() => {
    //     setSearchText('');
    //     if (options.length > MAX_ROWS) {
    //         setLimitedResults(options.length);
    //         setFiltered(options.slice(0, MAX_ROWS));
    //     } else {
    //         setLimitedResults(null);
    //         setFiltered(options);
    //     }
    // }, [options]);

    const handleSort = ({ sort, order, autoSaveView }) => {
        if (!sort?.length || !order?.length) {
            return
        }
        setSort({ sort, order, autoSaveView })
    }

    const onSearch = () => {
        const _filtered =
            options?.filter(option => {
                const isCustomerNoIncludesInSearchText = option?.object?.contactNo?.toLowerCase().trim().includes(searchText?.toLowerCase()?.trim())
                const isSearchTextIncludesInSearchText = option?.filter?.toLowerCase().trim().includes(searchText?.toLowerCase()?.trim())
                return isSearchTextIncludesInSearchText || isCustomerNoIncludesInSearchText
            }) || []
        setSelectedRowId()
        if (_filtered.length > MAX_ROWS) {
            setLimitedResults(_filtered.length)
            setFiltered(_filtered.slice(0, MAX_ROWS))
        } else {
            setFiltered(_filtered)
            setLimitedResults(null)
        }
    }

    const selectedRowIndex = useMemo(() => filtered?.findIndex(x => x._id === selectedRowId) || 0, [filtered, selectedRowId])

    function handleSelect(newSelected) {
        if (Object.keys(newSelected).length > 2) return //ignore select all checkbox
        const result = Object.keys(newSelected)
            .filter(id => newSelected[id] === true)
            .filter(id => !selected.includes(id))
        setSelected(result)
        onChange(result[0])
    }

    return (
        <div className='SelectEntityTable'>
            <div className='SelectEntityTable__panel--table-only'>
                <div className='search-block'>
                    <div className='search_form'>
                        <input
                            autoFocus
                            type='search'
                            autoComplete='off'
                            name='search'
                            placeholder='Add search'
                            className='search_form__input'
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                            onKeyDown={event => {
                                if (event.keyCode === 38) {
                                    setSelectedRowId(filtered?.[selectedRowIndex - 1]?._id || filtered?.[0]?._id);
                                    event.preventDefault();
                                } else if (event.keyCode === 40 && filtered?.[selectedRowIndex + 1]) {
                                    setSelectedRowId(filtered?.[selectedRowIndex + 1]?._id);
                                    event.preventDefault();
                                } else if (event.keyCode === 27) {
                                    onCancel();
                                }
                            }}
                        />
                        {limitedResults && (
                            <div style={{ width: '400px', padding: '8px' }}>
                                <span>
                                    Showing {MAX_ROWS} of total {limitedResults}. Please use filter.
                                </span>
                            </div>
                        )}
                        <button className='search_form__button' onClick={onSearch}>
                            <Feather name='Search' className='search_form__icon' />
                        </button>
                    </div>
                </div>
                <div className='table_block mb-20'>
                    <div className='header-bottom-line'></div>
                    <Table
                        data={filtered}
                        columns={columns}
                        dataProps={dataProps}
                        style={{ height }}
                        rowHeight={rowHeight}
                        fixedScroll={true}
                        sortable={true}
                        sort={sort}
                        onSort={handleSort}
                        selected={(selected || []).reduce((prev, value) => {
                            prev[value] = true
                            return prev
                        }, {})}
                        setSelected={handleSelect}
                    />
                </div>
            </div>
        </div>
    );
};

export default SelectEntityTable;
