import moment from 'moment'

import service from './service'

export const fetchQuotes = async (params = {}) => {
    const res = await service.get(`/quotes`, { params })
    return res.data
}

export const countQuotes = async (params = {}) => {
    const res = await service.get(`/quotes/count`, { params })
    return res.data
}

export const fetchQuote = async id => {
    const res = await service.get(`/quotes/${id}`)
    return res.data
}

export const activateFromQuoteHistory = async (id, from) => {
    const res = await service.post(`/quotes/activate/${id}`, { from })
    return res.data
}

export const fetchNextQuoteNo = async () => {
    const res = await service.get('/quotes/no')
    return res.data.quoteNo
}

export const fetchPriceIndicators = async (product, customer, plant, address, uom) => {
    const location = typeof address?.lng !== 'undefined' && typeof address?.lat != 'undefined' ? `${address.lng},${address.lat}` : undefined
    const res = await service.get('/quotes/price-indicators', { params: { product, customer, plant, location, uom } })
    return res.data
}

export const fetchBulkIndicators = async (plantsInfoIds, customer, address, competitorPlantsIds) => {
    const location = typeof address?.lng !== 'undefined' && typeof address?.lat != 'undefined' ? `${address.lng},${address.lat}` : undefined
    const res = await service.post('/quotes/bulk-indicators', { plantsInfoIds, competitorPlantsIds }, { params: { customer, location } })
    return res.data
}

export const fetchCompetitorIndicators = async (product, competitors, address) => {
    const res = await service.post('/quotes/competitor-indicators', { product, competitors, address })
    return res.data
}

export const fetchQuoteReport = async quote => {
    const res = await service.post(`/quotes/report/${quote._id}`, quote)
    return res.data
}

export const updateQuote = async quote => {
    const res = await service.put(`/quotes/${quote._id}`, quote)
    return res.data
}

export const saveQuote = async quote => {
    const res = await service.post('/quotes/', quote)
    return res.data
}

export const changeProgress = async (id, progress, quote) => {
    const res = await service.put(`/quotes/progress/${id}`, { progress, quote })
    return res.data
}

export const createSnapshot = async id => {
    const res = await service.post(`/quotes/snapshot/${id}`)
    return res.data
}

export const duplicateQuote = async id => {
    const res = await service.post(`/quotes/duplicate/${id}`)
    return res.data
}

export const closeQuote = async quote => {
    const res = await service.post(`/quotes/close/${quote._id}`, quote)
    return res.data
}

export const deleteQuote = async id => {
    await service.delete(`/quotes/${id}`)
}

export const sendQuote = async (id, data) => {
    const res = await service.post(`/quotes/send/${id}`, data)
    return res.data
}

export const sendComment = async (id, data) => {
    const res = await service.post(`/quotes/comment/${id}`, data)
    return res.data
}

export const viewComments = async (id, data) => {
    const res = await service.put(`/quotes/comment/${id}`, data)
    return res.data
}

export const bulkDeleteQuotes = async ids => {
    const res = await service.post(`/quotes/delete`, ids)
    return res.data
}

export const bulkUpdateQuotes = async (_id, update) => {
    const res = await service.post(`/quotes/update`, { _id, update })
    return res.data
}

export const attemptQuoteLock = async (id, errorCallback = () => {}) => {
    try {
        const res = await service.post(`/quotes/lock/${id}`)
        return res.data.success === true
    } catch (error) {
        errorCallback(error)
    }
    return false
}

export const unlockQuote = async (id, errorCallback = () => {}) => {
    try {
        const res = await service.post(`/quotes/unlock/${id}`)
        return res.data.success === true
    } catch (error) {
        errorCallback(error)
    }
    return false
}

export const changeStatus = async (quote, status) => {
    const res = await service.put(`/quotes/status/${quote._id}`, { ...quote, status })
    return res.data
}

export const getAllQuotes = async query => {
    const res = await service.get('/quotes?' + query)
    return res.data
}

export const populateQuote = async (id, version) => {
    if (!id) return
    const res = await service.post(`/quotes/populate/${id}`, version)
    return res.data
}

export const approveQuote = async (id, { currentProgress, approver }) => {
    const res = await service.put(`/quotes/approve/${id}`, { currentProgress, approver })
    return res.data
}
