import React from 'react';

import { Table } from '@components';

import tableColumns from './tableColumns';

const ChooseAddressModal = (props) => {
    const {
        addresses,
        selected,

        onSelected
    } = props;

    return (
        <div>
            <p> Select the customer delivery address from the list</p>
            <div>
                <Table
                    tableKey='table:choose-address-modal'
                    paddingRowNo={2}
                    columns={tableColumns}
                    data={addresses}
                    onSelected={onSelected}
                    selected={selected}
                    resizing={false}
                    action={false}
                    onlyOneSelectionEnabled
                    checkBox
                />
            </div>
        </div>
    );
};

export default ChooseAddressModal;
