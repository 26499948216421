export const stageColors = {
    'draft': '#e0e7ff',
    'sent': '#ff8939',
    'signed': 'orange',
    'negotiation': '#2ea2ff',
    'in-approval': '#b0b0b0',
    'approved': '#2ed47a',
    'won': '#E0F9EB',
    'lost': '#fee8e6',
    'cancelled': '#47525d',
    'default': '#6e8ca3',
}

export const stageFontColors = {
    'draft': '#0E3F66',
    'sent': 'white',
    'signed': 'white',
    'negotiation': 'white',
    'in-approval': 'white',
    'approved': 'white',
    'won': '#60C487',
    'lost': '#f7685b',
    'cancelled': 'white',
    'default': 'white',
}

export const stageLabels = {
    'draft': 'Draft',
    'sent': 'Sent',
    'signed': 'Estimation',
    'negotiation': 'Negotiation',
    'in-approval': 'In approval',
    'approved': 'Approved',
    'won': 'Won',
    'lost': 'Lost',
    'cancelled': 'Cancelled',
    'default': 'Unknown stage',
}