import { StyleSheet, Font } from '@react-pdf/renderer';
import { RobotoBold, RobotoRegular, RobotoLightItalic } from '@assets';

Font.register({
    family: 'Roboto',
    fonts: [
        {
            src: RobotoBold,
            fontWeight: 'bold'
        },
        {
            src: RobotoRegular,
            fontWeight: 'normal'
        },
        {
            src: RobotoLightItalic,
            fontStyle: 'italic'
        }
    ]
});

export default StyleSheet.create({
    h1_white: {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 16,
        textTransform: 'uppercase',
        color: '#fff'
    },
    total_red: {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 14,
        color: '#E25D5B'
    },
    total_black: {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 14,
        color: '#383938'
    },
    h2: {
        color: '#383938',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 14,
        textTransform: 'uppercase',
        marginBottom: 9
    },
    h3_red: {
        color: '#383938',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 12,
        textTransform: 'uppercase'
    },
    h3_black: {
        color: '#383938',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 12,
        textTransform: 'uppercase'
    },
    h5_black: {
        color: '#383938',
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        fontSize: 12
    },
    table_th: {
        color: '#383938',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 10,
        padding: '5px',
        textAlign: 'left',
        flex: '1 1 auto'
    },
    table_td: {
        color: '#383938',
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        fontSize: 10,
        padding: '0 5px 0 5px',
        textAlign: 'left',
        flex: '1 1 auto'
    },
    separator: { width: '100%', height: '2px', backgroundColor: '#E25D5B', paddingLeft: '20px', paddingRight: '20px', marginVertical: '10px' },
    row: {
        flexDirection: 'row'
    },
    container: {
        flexDirection: 'row',
        alignItems: 'stretch',
        backgroundColor: '#0E3F66',
        height: 56
    },
    content: {
        paddingVertical: 54,
        paddingHorizontal: 32
    },
    descriptionContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    section: {},
    description: {
        color: '#1F2229',
        fontWeight: 'semibold',
        fontSize: 8
    },
    item: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginBottom: 30
    },
    tableContainer: {
        marginTop: 40
    },
    table: {
        width: 'auto'
    },
    tableRow: {
        flexDirection: 'row'
    },
    tableBorder: {
        borderBottomWidth: 1,
        borderColor: '#E7E8EC'
    },
    tableHeader: {
        color: '#0E3F66',
        fontWeight: 'bold',
        fontSize: 8
    },
    tableCell: {
        color: '#1F2229',
        fontWeight: 'light',
        fontSize: 8
    },
    tableTotalCell: {
        color: '#0E3F66',
        fontSize: 12,
        fontWeight: 'bold'
    },
    textRight: {
        textAlign: 'right'
    },
    noteContainer: {
        marginTop: 40
    },
    noteTitle: {
        fontSize: 10,
        fontWeight: 'bold',
        marginBottom: 16,
        color: '#1F2229'
    },
    noteDescription: {
        fontSize: 8,
        color: '#545E60'
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 10,
        right: 10,
        zIndex: 9999,
        textAlign: 'center',
        color: 'grey'
    },
    footer: {
        bottom: 0,
        marginTop: 20,
        zIndex: 1
    },
    productColumn: {
        width: '30%'
    },
    quantityColumn: {
        width: '10%'
    },
    taxColumn: {
        width: '10%'
    },
    priceColumn: {
        width: '15%'
    },
    productDescription: {
        fontFamily: 'Roboto',
        fontSize: 10,
        color: 'gray',
        margin: '0 0 5px 0',
        fontStyle: 'italic'
    }
});
