import React from 'react';

import Label from '../Label';
import Dropdown from '../Dropdown';
import TextField from '../TextField/index';

import '@scss/components/_textFieldWithOptions.scss';

export default function TextFieldWithOptions({
    label,
    optionValue,
    textValue,
    readOnly = false,
    onOptionChange = () => { },
    onTextChange = () => { },
    options,
    placeholder,
    parentComponent,
    customClass,
    autoFill = false,
    id,
    formErrors
}) {
    if (!options) {
        throw new Error('Text Field with Options must have options defined!');
    }

    return (
        <div className='TextFieldWithOptions'>
            <Label label={label} customClass='TextFieldWithOptions__label' />
            <div className='TextFieldWithOptions__input'>
                <TextField
                    autoFill={autoFill}
                    type='text'
                    readOnly={readOnly}
                    label={label}
                    id={id}
                    formErrors={formErrors}
                    value={textValue}
                    onChange={onTextChange}
                    placeholder={placeholder}
                    className={customClass}
                />
                <Dropdown
                    value={optionValue}
                    readOnly={readOnly}
                    parentComponent={parentComponent}
                    customClass={`TextFieldWithOptions__options ${readOnly ? 'options-disabled' : ''}`}
                    options={options}
                    onChange={onOptionChange}
                />
            </div>
        </div>
    );
}
