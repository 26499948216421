import React from 'react';

import { Image } from '@components';
import { Button } from '@components/bs';

import styles from './styles.module.scss';

const DropdownFooterButton = (props) => {
    const {
        icon = null,
        className = '',
        text = '',

        onClick = () => { }
    } = props;

    const handleOnClick = (e) => {
        e.preventDefault();
        onClick(e);
    };

    return (
        <Button
            onClick={handleOnClick}
            // variant='outline-light'
            className={`${styles.dropdown_footer_button} ${className}`}
        >
            <Image src={icon} />
            {text}
        </Button>
    );
};

export default DropdownFooterButton;
