import moment from 'moment';
import { getPropertySafely } from './object';

export const tableFilter = (data, filter, tableColumns, dataProps) => {
    return tableFilterAndSort(data, filter, tableColumns, dataProps, null);
};

export const tableFilterAndSort = (data, filter, tableColumns, dataProps, sort) => {
    const filterStepResults = filter
        ? data.filter(x => {
              let cond = true;
              filter.map(f => {
                  const colDesc = tableColumns.find(col => col.dataIndex === f.dataIndex);
                  const filterValue = colDesc?.filterValue({ row: x, dataProps });
                  if (colDesc?.type == 'number') {
                      if (filterValue) {
                          if (f.text.includes('<')) {
                              const text = f.text.replace('<', '')?.trim();
                              const number = Number.parseFloat(text);
                              if (filterValue >= number) {
                                  cond = false;
                              }
                          } else if (f.text.includes('>')) {
                              const text = f.text.replace('>', '')?.trim();
                              const number = Number.parseFloat(text);
                              if (filterValue <= number) {
                                  cond = false;
                              }
                          } else if (f.text.includes('=')) {
                              const text = f.text.replace('=', '')?.trim();
                              const number = Number.parseFloat(text);
                              if (number != filterValue) {
                                  cond = false;
                              }
                          }
                      } else {
                          cond = false;
                      }
                  } else if (colDesc?.type == 'datepicker' || colDesc?.type == 'date') {
                      if (filterValue) {
                          if (f.text.includes('<')) {
                              const date = f.text.replace('<', '')?.trim();
                              const mdate = moment(date, dataProps.dateFormat, true);
                              if (moment(filterValue, dataProps.dateFormat, true).isSameOrAfter(mdate, 'day')) {
                                  cond = false;
                              }
                          } else if (f.text.includes('>')) {
                              const date = f.text.replace('>', '')?.trim();
                              const mdate = moment(date, dataProps.dateFormat, true);
                              if (moment(filterValue, dataProps.dateFormat, true).isSameOrBefore(mdate, 'day')) {
                                  cond = false;
                              }
                          } else if (f.text.includes('=')) {
                              const date = f.text.replace('=', '')?.trim();
                              const mdate = moment(date, dataProps.dateFormat, true);
                              if (!moment(filterValue, dataProps.dateFormat, true).isSame(mdate, 'day')) {
                                  cond = false;
                              }
                          }
                      } else {
                          cond = false;
                      }
                  } else if (colDesc?.type == 'list') {
                      const fv = '^(' + filterValue?.toLowerCase() + ')$';
                      const re = new RegExp(fv, 'gi');
                      if (!f.text?.toLowerCase().match(re)) {
                          cond = false;
                      }
                  } else if (colDesc?.type == 'status') {
                      if (f.text?.toLowerCase() !== filterValue?.toLowerCase()) {
                          cond = false;
                      }
                  } else {
                      if (!filterValue?.toLowerCase()?.includes(f.text?.toLowerCase())) {
                          cond = false;
                      }
                  }
              });
              return cond;
          })
        : data;
    return sort
        ? filterStepResults.sort((a, b) => {
              const colDesc = tableColumns.find(col => col.dataIndex === sort?.sort);
              const aValue = sort.sortValue ? sort.sortValue({ row: a }) : getPropertySafely(a, sort.sort);
              const bValue = sort.sortValue ? sort.sortValue({ row: b }) : getPropertySafely(b, sort.sort);

              const type = colDesc?.type || 'string';
              const mult = sort?.order == 'asc' ? 1 : -1;

              if (!aValue) return mult;
              if (!bValue) return -mult;

              switch (type) {
                  case 'number':
                      return aValue > bValue ? -mult : mult;
                  case 'string':
                      if (typeof aValue?.name === 'string' && typeof bValue?.name === 'string') {
                          return aValue?.name?.toLowerCase() > bValue?.name?.toLowerCase() ? mult : -mult;
                      }
                      return aValue?.toLowerCase() > bValue?.toLowerCase() ? mult : -mult;
                  case 'date':
                      return new Date(aValue).valueOf() > new Date(bValue).valueOf() ? -mult : mult;
                  default:
                      return aValue > bValue ? mult : -mult;
              }
          })
        : filterStepResults;
};
