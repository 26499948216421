import React, { useEffect, useMemo, useState } from 'react';
import { PDFViewer, Document } from '@react-pdf/renderer';

import { fetchQuoteReport, fetchQuote } from 'service/quotesApi';
import { getQuoteProductLinesFields } from 'service/settingsApi';
import { getTemplateById } from 'service/tc';
import { setAuthToken } from '@service/service';

import { Loading } from '@components';

import { useQuoteTemplate } from '@hooks/useQuoteTemplate';
import { useEntityManager } from '@hooks/useEntityManager';
import { useDateFormat } from '@hooks/useDateFormat';
import { useCurrency } from '@hooks/useCurrency';
import { useQuery } from '@hooks/useQuery';

import '@scss/components/_pdf-viewer.scss';

const PreviewQuote = props => {
    const { id } = props.match.params;

    const queryParams = useQuery();
    const [QuoteTemplate, QuoteTemplateBlob] = useQuoteTemplate();
    const { entityGroupByKey, getEntitySettings } = useEntityManager();

    const templateOptions = useMemo(() => {
        return {
            pickedUp: queryParams.pickedUp,
            delivered: queryParams.delivered,
            templateId: queryParams.templateId && queryParams.templateId !== 'null' ? decodeURIComponent(queryParams.templateId) : null
        };
    }, [queryParams]);

    const [data, setData] = useState({
        customer: { name: '', address: { city: '', country: '', zip: '', line: '' } },
        quoteNo: '',
        address: { city: '', country: '', zip: '', line: '' },
        project: { name: '' },
        quoteEntries: [],
        userResponsible: { firstName: '', lastName: '' }
    });
    const [loading, setLoading] = useState(false);

    const [templateText, setTemplateText] = useState(null);
    const [isBreake, setIsBreake] = useState(false);

    const { currencyFormat } = useCurrency();
    const { dateFormat } = useDateFormat();

    const getQuote = async id => {
        try {
            const q = await fetchQuote(id);
            const [reportData, displayFields] = await Promise.all([fetchQuoteReport(q), getQuoteProductLinesFields(q?._id)]);

            setData({ ...reportData, displayFields: displayFields });
        } catch (error) {
            console.error(`=GetQuote_Error=`, error);
        }
    };

    const isPageShouldBrake = async text => {
        const rootEl = document.getElementById('root');
        const divEl = document.createElement('div');
        divEl.id = 'tcBlock';
        divEl.style.cssText = 'maxWidth:966px;width:966px;height:auto;position:absolute;';
        divEl.innerHTML = text;
        rootEl.appendChild(divEl);
        const elOffsetHeight = document.getElementById('tcBlock').offsetHeight;
        divEl.remove();
        return (elOffsetHeight * 100) / 1754 > 60 ? true : false;
    };

    const getTemplateText = async id => {
        setLoading(true);
        const text = await getTemplateById(id);
        const isBreake = await isPageShouldBrake(text);
        setTemplateText(text);
        setIsBreake(isBreake);
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        setAuthToken(localStorage.getItem('token'));
        getEntitySettings('quote.quoteEntries');
        getQuote(id);
        if (templateOptions.templateId) {
            getTemplateText(templateOptions.templateId);
        }
        setLoading(false);
    }, []);


    if ((!QuoteTemplate && !loading) || !entityGroupByKey) {
        return (
            <div className='loader-page-container' id='loaderw'>
                <div className='loader-container'>
                    <Loading />
                </div>
            </div>
        );
        }

    return (
        <PDFViewer className='pdf-viewer'>
            <Document title='Quote'>
                <QuoteTemplate
                    data={{ ...data, dateFormat }}
                    options={{ ...templateOptions, templateText, isBreake }}
                    currencyFormat={currencyFormat}
                    showTECAlert={false}
                    entityGroupByKey={entityGroupByKey}
                />
            </Document>
        </PDFViewer>
    );
};

export default PreviewQuote;
