import { useEffect, useState } from 'react';

import { useEntity } from '@hooks/useEntity';

import { ACTIVE, ARCHIVED, INACTIVE } from 'consts/status';

import { uomwTypes } from 'utils/unit';

import { usePlantType } from './usePlantType';

export const useUOM = (status, select) => {
    const { getPaginatedList } = useEntity('uom-settings');
    const { isTypeReadymix } = usePlantType();
    const [filteredUOMSettings, setFilteredUOMSettings] = useState([]);

    const activeUnit = localStorage.getItem('unit');

    const { data: uomSettings } = getPaginatedList(
        {
            filterColumns: 'status',
            filterValues: status || [ACTIVE, INACTIVE, ARCHIVED].join(';')
        },
        data => (!select ? data?.items?.filter(i => i.unit?.key === activeUnit).map(i => ({ value: i._id, label: i.value, uom: i?.unit?.key })) : select(data))
    );

    useEffect(() => {
        if (uomSettings) {
            const filteredSettingsByActiveUnit = uomSettings.filter(s => s.uom === activeUnit);
            setFilteredUOMSettings(filteredSettingsByActiveUnit);
        }
    }, [uomSettings]);

    const getUnitByKey = unitKey => {
        const uomByKey = uomSettings.find(s => s.key === unitKey);
        // uomByKey used to retrieve old data with uom stored as a string
        if (uomByKey) return uomByKey;
        else {
            // uomByObjectId used to retrieve data with uom stored as a ObjectId
            const uomByObjectId = uomSettings.find(s => s._id === unitKey);
            return uomByObjectId || uomSettings[0] || {};
        }
    };
    const getUnitByPlantType = (plantType = null) => {
        if (!uomSettings) return uomwTypes[2];
        if (uomSettings.length) {
            let unitByPlantTypeKey = 't';

            if (isTypeReadymix(plantType)) {
                unitByPlantTypeKey = activeUnit === 'imperial' ? 'yd3' : 'm3';
            }
            const uomSetting = uomSettings.find(s => s.key === unitByPlantTypeKey || s.label === unitByPlantTypeKey);
            return uomSetting || uomSettings[0];
        }

        return uomwTypes[2];
    };

    const getDefaultUnitPlantType = (plantType) => {
        if (!uomSettings) return uomwTypes[2];
        if (uomSettings.length) {
            const defaultUOMId = plantType?.defaultUOM?._id || plantType?.defaultUOM
            const uomSetting = uomSettings.find(us => us.value == defaultUOMId)
            return uomSetting;
        }
    }

    const getRouteUnitByMeasure = (unit = null) => {
        if (uomSettings?.length) {
            const unitValue = unit || activeUnit;

            let distanceValue;
            if (unitValue === 'imperial') {
                distanceValue = uomSettings.find(s => s.key === 'mi' || s.label === 'mi');
            } else {
                distanceValue = uomSettings.find(s => s.key === 'km' || s.label === 'km');
            }

            return distanceValue || {};
        }
        return {};
    };

    const getAverageSpeedMeasure = (unit = null) => {
        if (uomSettings?.length) {
            const unitValue = unit || activeUnit;

            let averageSpeed;
            if (unitValue === 'imperial') {
                averageSpeed = uomSettings.find(s => s.key === 'mph' || s.label === 'mph');
            } else {
                averageSpeed = uomSettings.find(s => s.key === 'kph' || s.label === 'kph');
            }

            return averageSpeed || {};
        }
        return {};
    };

    return {
        uomSettings,
        filteredUOMSettings,

        getUnitByKey,
        getUnitByPlantType,
        getRouteUnitByMeasure,
        getAverageSpeedMeasure,
        getDefaultUnitPlantType
    };
};
