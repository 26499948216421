import React, { useState } from 'react';

import { Switcher } from '@components';

import { FILTER_BY_OPTIONS } from './const';

const QuoteSwitcher = (props) => {
    const {
        initialFilterBy = FILTER_BY_OPTIONS.QUOTES,
        handleOnChangeFilterBy = () => {}
    } = props;
    const [filterBy, setFilterBy] = useState(initialFilterBy);

    const onChangeFilterBy = (value) => {
        const filterByValue = FILTER_BY_OPTIONS[value];
        setFilterBy(filterByValue);
        handleOnChangeFilterBy && handleOnChangeFilterBy(filterByValue);
    };

    return (
        <Switcher
            name='filterBy'
            selected={filterBy.value}
            options={FILTER_BY_OPTIONS}
            onChange={onChangeFilterBy}
        />
    );
};

export default QuoteSwitcher;
