import React from 'react'
import styled from 'styled-components'

const Bar = styled.div<{ background: string, width: number, fullyFilled?: boolean }>`
    width: ${p => p.fullyFilled ? p.width : (p.width === 100 ? p.width - 4 : p.width)}%;
    background-color: ${p => p.background};
    height: 20px;
    border-radius: 0px 6px 6px 0px;
    position: absolute;
    left: 0;
    top: 0;
`

export default Bar
