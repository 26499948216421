import { useContext } from 'react'

import { NotifyContext } from './NotificationProvider'

import { NOTIFICATION_LIST } from './const'

const useNotify = () => {
    const notify = useContext(NotifyContext)

    const notifyError = (desc?: string, title?: string, duration?: number) => {
        notify({
            title: title || NOTIFICATION_LIST.ERROR.title,
            description: desc?.toString() || NOTIFICATION_LIST.ERROR.description,
            duration: duration || NOTIFICATION_LIST.ERROR.duration,
            type: NOTIFICATION_LIST.ERROR.type
        })
    }

    const notifyWarning = (desc?: string, title?: string, duration?: number) => {
        notify({
            title: title || NOTIFICATION_LIST.WARNING.title,
            description: desc?.toString() || NOTIFICATION_LIST.WARNING.description,
            duration: duration || NOTIFICATION_LIST.WARNING.duration,
            type: NOTIFICATION_LIST.WARNING.type
        })
    }

    const notifySuccess = (desc?: string, title?: string, duration?: number) => {
        notify({
            title: title || NOTIFICATION_LIST.SUCCESS.title,
            description: desc?.toString() || NOTIFICATION_LIST.SUCCESS.description,
            duration: duration || NOTIFICATION_LIST.SUCCESS.duration,
            type: NOTIFICATION_LIST.SUCCESS.type
        })
    }

    const notifyInfo = (desc?: string, title?: string, duration?: number) => {
        notify({
            title: title || NOTIFICATION_LIST.INFO.title,
            description: desc?.toString() || NOTIFICATION_LIST.INFO.description,
            duration: duration || NOTIFICATION_LIST.INFO.duration,
            type: NOTIFICATION_LIST.INFO.type
        })
    }

    return {
        notify,

        notifyError,
        notifyWarning,
        notifySuccess,
        notifyInfo
    }
}

export default useNotify
