import { useMemo, useState } from 'react'

import { useQuery } from '@tanstack/react-query'

import { fetchPriceBeeCustomers } from 'service/customersApi'

import { useMigrateCustomers } from './useMigrateCustomers'

import customerColumns from 'views/Pages/Sales/Quotes/QuoteDetails/customerColumns'
import { OptionsModal } from '@components/index'
import { BTN_CANCEL, BTN_MERGE } from '@components/OptionsModal/config'

import { BlurredModal } from '@modals/index'

import { SelectEntity } from '@common'

import { Customer } from '@customTypes/customer'

const MergeCustomers = ({
    importedCustomer,
    setIsCustomersModalOpened,
    setNeedRefetch
}: {
    importedCustomer: Customer
    setIsCustomersModalOpened: (value: boolean) => void
    setNeedRefetch: (value: boolean) => void
}) => {
    const [selectedCustomersIds, setSelectedCustomersIds] = useState<string[]>([])
    const [isConfirmationMessageOpened, setIsConfirmationMessageOpened] = useState(false)

    const { data: customersNames } = useQuery(['customers'], () => fetchPriceBeeCustomers({ onlyNames: true }))

    const mutation = useMigrateCustomers(() => {
        setIsCustomersModalOpened(false)
        setNeedRefetch(true)
    })

    const modalOptions = {
        buttons: [BTN_CANCEL, BTN_MERGE],
        isOpen: true,
        width: 1300,
        type: 'mCustomerSelection',
        title: 'Select customer(s) to merge with',
        disabledButtons: { merge: !selectedCustomersIds.length }
    }

    const handleCustomerSelect = (customerIds: string[]) => {
        setSelectedCustomersIds(customerIds)
    }

    const handleConfirmation = () => {
        const confirmation = window.confirm(
            `By clicking merge all quotes, projects and contacts will be assigned to ${importedCustomer.name} and ${selectedCustomers
                .map(customer => customer.name)
                .join(', ')} will become inactive`
        )
        confirmation && mutation.mutate({ from: selectedCustomersIds, to: importedCustomer._id })

        setIsConfirmationMessageOpened(false)
    }

    const selectedCustomers = useMemo(() => {
        const selected: Customer[] = []
        selectedCustomersIds.forEach((customerId: string) => {
            const customer = customersNames?.find((customer: Customer) => customer._id === customerId)
            customer && selected.push(customer)
        })
        return selected
    }, [selectedCustomersIds])

    isConfirmationMessageOpened && handleConfirmation()

    return (
        <BlurredModal>
            <OptionsModal
                {...modalOptions}
                onCloseHandler={() => setIsCustomersModalOpened(false)}
                onContinueHandler={() => setIsConfirmationMessageOpened(true)}
                onCancelHandler={() => setIsCustomersModalOpened(false)}>
                <SelectEntity
                    setSelected={handleCustomerSelect}
                    selected={selectedCustomersIds}
                    columns={customerColumns}
                    entityName='customers'
                    fetch={fetchPriceBeeCustomers}
                    multiselect={true}
                />
            </OptionsModal>
        </BlurredModal>
    )
}

export default MergeCustomers
