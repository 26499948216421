import styled from 'styled-components'

import { ExpiredQuote } from '@assets'

const ExpiredCell = ({ expired, days }: { expired: boolean; days: number }) => {
    if (expired) {
        return (
            <ExpiredBox>
                <span>
                    Yes
                    <ExpiredIcon src={ExpiredQuote} alt='expired' />
                </span>
                <ExpiredDays>
                    Expired for {days} day{days > 1 ? 's' : ''}
                </ExpiredDays>
            </ExpiredBox>
        )
    }
    return (
        <ExpiredBox>
            <span>No</span>
            <ExpiredDays>
                Will be expired in {days} day{days > 1 ? 's' : ''}
            </ExpiredDays>
        </ExpiredBox>
    )
}

export default ExpiredCell

const ExpiredBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const ExpiredDays = styled.span`
    color: #7c8090;
    font-size: 11px;
`

const ExpiredIcon = styled.img`
    margin-left: 4px;
`
