import moment from 'moment';
import { Document, Image, Page, pdf, Text, View } from '@react-pdf/renderer';
import getTotal from '@project/utils/getTotal'
import { LogoKMMPDF } from '@assets';
import { Quote } from '@customTypes/quote'
import { TCT, dateFormatT, defaultIndexTypeT, displayFieldsT } from './types'

import getCostPerTon from '@utils/getCostPerTon';
import getTax from 'views/Pages/Project/utils/getTax';

import Entry from 'views/Pages/Setup/TCSettings/PDFPreview/Entry';

import { DISPLAY_FIELDS } from './columns';

import styles from './kmm-style';

interface QuoteKMMI {
    data: Quote & displayFieldsT & dateFormatT & defaultIndexTypeT & TCT
    options: any
    entityGroupByKey: any
    currencyFormat: any
}

export const QuoteKMM = (props: QuoteKMMI) => {
    const { data, options, entityGroupByKey, currencyFormat } = props;

    const displayFields = data.displayFields || DISPLAY_FIELDS;

    return (
        <Document title='Quote'>
            <Page size='A4' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'relative' }}>
                <View>
                    <View style={{ paddingHorizontal: 35, marginBottom: '20px' }} fixed>
                        <View style={{ flexDirection: 'column', paddingVertical: '10px' }}>
                            <View style={{ width: '60%' }}>
                                <Image src={LogoKMMPDF} style={{ width: '170px' }} />
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'column', lineHeight: 1.5 }}>
                                <Text style={{ ...styles.h3_black, marginBottom: 0 }}>1020 CLIFF ROAD WEST</Text>
                                <Text style={styles.h3_black}>BURNSVILLE, MN, 55337</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{ paddingHorizontal: 35 }}>
                        <View style={{ flexDirection: 'column', marginBottom: 10 }}>
                            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                <View style={{ lineHeight: 1.5 }}>
                                    <View>
                                        <Text style={styles.h3_red}>Quote: {data.quoteNo}</Text>
                                    </View>
                                    <View style={{ marginBottom: '10px' }}>
                                        <Text style={styles.h5_black}>{`Date: ${moment().format(data.dateFormat)}`}</Text>
                                        <Text style={styles.h5_black}>{`Expiration date: ${moment(data.expirationDate).format(data.dateFormat)}`}</Text>
                                        {data?.priceValidFrom && <Text style={styles.h5_black}>{`Price valid period: ${moment(data.priceValidFrom).format(data.dateFormat)} - ${moment(data.priceValidTo).format(data.dateFormat)}`}</Text>}
                                    </View>

                                    <View>
                                        <Text style={styles.h3_black}>Project name:</Text>
                                        <Text style={styles.h5_black}>{data?.project?.name || data?.name}</Text>
                                    </View>

                                    <View>
                                        <Text style={styles.h5_black}>{data?.project?.address?.line || data.address?.line || ''}</Text>
                                        <Text style={styles.h5_black}>
                                            {[data?.project?.address?.city || data.address?.city || null, data?.project?.address?.zip || data.address?.zip || null]
                                                .filter(el => el !== null)
                                                .join(', ')}
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ flexDirection: 'column', lineHeight: 1.5 }}>
                                    <View>
                                        <Text style={styles.h3_red}>Customer:</Text>
                                        <Text style={styles.h5_black}>{data?.customer?.name}</Text>
                                    </View>
                                    <Text style={styles.h5_black}>
                                        Attention: {data?.generalContact?.firstName} {data?.generalContact?.lastName}
                                    </Text>
                                    <View>
                                        <Text style={styles.h5_black}>{(data.customer?.addresses?.indexOf(0) && data.customer.addresses[0]?.line) || ''}</Text>
                                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Text style={styles.h5_black}>
                                                {[
                                                    (data.customer?.addresses?.indexOf(0) && data.customer?.addresses[0]?.city) || null,
                                                    (data.customer?.addresses?.indexOf(0) && data.customer?.addresses[0]?.state) || null,
                                                    (data.customer?.addresses?.indexOf(0) && data.customer?.addresses[0]?.zip) || null
                                                ]
                                                    .filter(el => el !== null)
                                                    .join(', ')}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.separator}></View>
                            <View style={{ border: '1px solid black', padding: 5 }}>
                                <View style={{ flexDirection: 'row' }}>
                                    {displayFields.product && (
                                        <Text style={{ ...styles.table_th, ...styles.productColumn }}>{entityGroupByKey?.product?.fieldName || 'Product'}</Text>
                                    )}
                                    {displayFields.qty && <Text style={{ ...styles.table_th, ...styles.quantityColumn }}>{entityGroupByKey?.qty?.fieldName || 'Quantity'}</Text>}
                                    {displayFields.price && (
                                        <Text style={{ ...styles.table_th, ...styles.priceColumn }}>{entityGroupByKey?.price?.fieldName || 'Picked up unit price'}</Text>
                                    )}
                                    {displayFields.transportCostPerUnit && (
                                        <Text style={{ ...styles.table_th, ...styles.priceColumn }}>{entityGroupByKey?.transportCostPerUnit?.fieldName || 'Transport cost per unit'}</Text>
                                    )}
                                    {displayFields.deliveredUnitPrice && (
                                        <Text style={{ ...styles.table_th, ...styles.priceColumn }}>
                                            {entityGroupByKey?.deliveredUnitPrice?.fieldName || 'Delivered unit price'}
                                        </Text>
                                    )}
                                    {displayFields.deliveredUnitPriceInclTax && (
                                        <Text style={{ ...styles.table_th, ...styles.priceColumn }}>
                                            {entityGroupByKey?.deliveredUnitPriceInclTax?.fieldName || 'Delivered unit price incl. tax'}
                                        </Text>
                                    )}
                                    {displayFields.totalPickedUpPrice && (
                                        <Text style={{ ...styles.table_th, ...styles.priceColumn }}>
                                            {entityGroupByKey?.totalPickedUpPrice?.fieldName || 'Total picked up price'}
                                        </Text>
                                    )}
                                    {displayFields.totalDelivered && (
                                        <Text style={{ ...styles.table_th, ...styles.priceColumn }}>{entityGroupByKey?.totalDelivered?.fieldName || 'Total delivered'}</Text>
                                    )}
                                    {displayFields.tax && (
                                        <Text style={{ ...styles.table_th, ...styles.priceColumn }}>{entityGroupByKey?.tax?.fieldName || 'Tax'}</Text>
                                    )}
                                    {displayFields.totalDeliveredInclTax && (
                                        <Text style={{ ...styles.table_th, ...styles.priceColumn }}>
                                            {entityGroupByKey?.totalDeliveredInclTax?.fieldName || 'Total delivered incl. tax'}
                                        </Text>
                                    )}
                                    {displayFields.totalPickedUpPriceInclTax && (
                                        <Text style={{ ...styles.table_th, ...styles.priceColumn }}>
                                            {entityGroupByKey?.totalPickedUpPriceInclTax?.fieldName || 'Total picked up price incl. tax'}
                                        </Text>
                                    )}
                                </View>
                                {data.quoteEntries.map((x, i) => {
                                    const costPerTon = getCostPerTon(x, { plantsInfo: data?.plantsInfo }) || 0;
                                    const isDelivered = Boolean(costPerTon) && Boolean(x.delivered);
                                    const tax = getTax(x, data);
                                    const price = x.price || 0;
                                    const taxRatePercent = tax ? (tax * 100).toFixed(3) : 0;
                                    const totalDelivered = price ? (price + costPerTon) * (x?.qty || 0) : 0;
                                    const deliveredUnitPriceInclTax = price ? (price + costPerTon) * (1 + tax) : 0;
                                    const totalDeliveredInclTax = price ? (price * (x?.qty || 0) + costPerTon * (x?.qty || 0)) * (x.taxApplied ? 1 + tax : 1) : 0;
                                    const qty = typeof x.qty === 'undefined' ? '-' : x?.qty?.toLocaleString()

                                    return (
                                        <View key={`qe-${i}`} style={{ flexDirection: 'row', width: '100%' }}>
                                            {displayFields.product && (
                                                <div style={{ ...styles.table_td, ...styles.productColumn }}>
                                                    <Text>
                                                        {x.product?.productNo} - {x.placedName || x.product.name}
                                                    </Text>
                                                    {displayFields.description && <Text style={{ ...styles.productDescription }}>{x.product?.description}</Text>}
                                                </div>
                                            )}
                                            {displayFields.qty && (
                                                <Text style={{ ...styles.table_td, ...styles.quantityColumn }}>
                                                    {qty + ' ' + (Number(qty) >= 0 ? (x?.uomValue || x?.uom || x?.product?.uom?.value || '') : '')}
                                                </Text>
                                            )}
                                            {displayFields.price && <Text style={{ ...styles.table_td, ...styles.priceColumn }}>{currencyFormat(x.price)}</Text>}
                                            {displayFields.transportCostPerUnit && (
                                                <Text style={{ ...styles.table_td, ...styles.priceColumn }}>
                                                    {isDelivered ? currencyFormat(costPerTon) : '-'}
                                                </Text>
                                            )}
                                            {displayFields.deliveredUnitPrice && (
                                                <Text style={{ ...styles.table_td, ...styles.priceColumn }}>
                                                    {isDelivered ? currencyFormat(x.price + costPerTon) : '-'}
                                                </Text>
                                            )}
                                            {displayFields.deliveredUnitPriceInclTax && (
                                                <Text style={{ ...styles.table_td, ...styles.priceColumn }}>
                                                    {isDelivered ? currencyFormat(deliveredUnitPriceInclTax) : '-'}
                                                </Text>
                                            )}
                                            {displayFields.totalPickedUpPrice && (
                                                <Text style={{ ...styles.table_td, ...styles.priceColumn }}>{currencyFormat((x?.price || 0) * (x?.qty || 0))}</Text>
                                            )}
                                            {displayFields.totalDelivered && (
                                                <Text style={{ ...styles.table_td, ...styles.priceColumn }}>{isDelivered ? currencyFormat(totalDelivered) : '-'}</Text>
                                            )}
                                            {displayFields.tax && (
                                                <Text style={{ ...styles.table_td, ...styles.priceColumn }}>{tax ? (tax * 100).toFixed(3) + '%' : '-'}</Text>
                                            )}
                                            {displayFields.totalDeliveredInclTax && (
                                                <Text style={{ ...styles.table_td, ...styles.priceColumn }}>
                                                    {isDelivered ? currencyFormat(totalDeliveredInclTax) : '-'}
                                                </Text>
                                            )}
                                            {displayFields.totalPickedUpPriceInclTax && (
                                                <Text style={{ ...styles.table_td, ...styles.priceColumn }}>
                                                    {currencyFormat(price * (x?.qty || 0) * (x.taxApplied ? 1 + tax : 1))}
                                                </Text>
                                            )}
                                        </View>
                                    );
                                })}
                            </View>
                            <View style={styles.separator}></View>
                        </View>
                        <View style={styles.separator}></View>
                    </View>
                    {data.tc && (
                        <View style={{ paddingHorizontal: 35, marginBottom: 20 }} wrap={false}>
                            <View style={{ marginTop: 20 }}>
                                <Text style={styles.h3_black}>NOTE</Text>
                                <View style={{ marginTop: 5 }}>
                                    <Text style={styles.description}>{data.tc}</Text>
                                </View>
                            </View>
                            <View style={styles.separator}></View>
                        </View>
                    )}
                </View>
                <View break={options?.isBreake}>
                    <View wrap={options?.isBreake} style={{ position: 'relative' }}>
                        <View style={{ paddingHorizontal: 35, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: 30 }}>
                            {options.templateText && (
                                <View style={{ marginTop: 20 }}>
                                    <Text style={styles.h3_black}>TERMS & CONDITIONS</Text>
                                    <Entry entry={options.templateText} />
                                </View>
                            )}
                        </View>
                        <View style={{ flexDirection: 'row', paddingHorizontal: 35 }}>
                            <View style={{ width: '40%' }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <Text style={{ ...styles.h3_black, marginRight: 5 }}>QUOTED BY:</Text>
                                    <Text style={styles.h5_black}>{data.userResponsible ? `${data.userResponsible?.firstName} ${data.userResponsible?.lastName}` : ''}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', marginTop: 1 }}>
                                    <Text style={{ ...styles.h3_black, marginRight: 5 }}>DIRECT:</Text>
                                    <Text style={styles.h5_black}>{data?.userResponsible?.directPhone || ''}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', marginTop: 1 }}>
                                    <Text style={{ ...styles.h3_black, marginRight: 5 }}>MOBILE:</Text>
                                    <Text style={styles.h5_black}>{data?.userResponsible?.mobilePhone || ''}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', marginTop: 1 }}>
                                    <Text style={{ ...styles.h3_black, marginRight: 5 }}>FAX:</Text>
                                    <Text style={styles.h5_black}>{data?.userResponsible?.fax}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', marginTop: 1 }}>
                                    <Text style={{ ...styles.h3_black, marginRight: 5 }}>EMAIL:</Text>
                                    <Text style={styles.h5_black}>{data?.userResponsible?.email || ''}</Text>
                                </View>
                            </View>
                            <View style={{ width: '60%' }}>
                                <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                    <Text style={styles.h3_black}>APPROXIMATE START DATE:</Text>
                                    <View style={{ width: '120', borderBottom: '1 solid #000', marginLeft: 5 }}></View>
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 13.5 }}>
                                    <Text style={styles.h3_black}>CUSTOMER JOB/PROJECT NUMBER:</Text>
                                    <View style={{ width: '120', borderBottom: '1 solid #000', marginLeft: 5 }}></View>
                                </View>
                                <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 13.5 }}>
                                    <Text style={styles.h3_black}>JOB AWARDED BY/SIGNATURE:</Text>
                                    <View style={{ width: '120', borderBottom: '1 solid #000', marginLeft: 5 }}></View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <View fixed>
                    <View style={{ ...styles.footer }}>
                        <View
                            // @ts-ignore
                            style={{
                                width: '100%',
                                height: '50px',
                                backgroundColor: '#E25D5B',
                                marginTop: 15,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: 1,
                                position: '',
                                textAlign: 'center'
                            }}>
                            <Text style={{ ...styles.h1_white, textAlign: 'center', marginTop: 2 }}>KRAEMER MINING & MATERIALS, INC.</Text>
                            <Text style={{ ...styles.h1_white, textAlign: 'center' }}>DISPATCH CENTER - 952-890-1361</Text>
                            <Text
                                style={{ position: 'absolute', bottom: '5px', right: '10px', fontSize: 8, zIndex: 1000, color: '#fff' }}
                                render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
                                fixed
                            />
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export const QuoteKMMBlob = async (props: QuoteKMMI) => {
    return await pdf(QuoteKMM(props)).toBlob();
};
