import { ThemeConfig } from "antd"

export const theme = {
    input: {
        background: '#f5f8ff',
        border: '1px solid #e0e7ff',
        error: '#f7685b',
    },
    colors: {
        orange: '#ff8939',
        green: '#2CD47A',
        red: '#F7685B',
        darkGray: '#979797',
        gray: '#C1C0C0',
        gray2: '#81878E',
        gray3: '#F0F1F6',
        gray4: '#CCCCCC',
        lightGray: '#F5F5F4',
        lightBlue: '#6E8CA3',
        blue: '#dbedff',
        blue2: '#53BCFF',
        blue3: '#A6ABBD',
        blue4: '#F8FBFE',
        darkBlue: '#0E3F66',
        white: 'white'
    },
    gutters: {
        title: '20px'
    }
}

export const antTheme: ThemeConfig = {
    components: {
        DatePicker: {
            colorPrimary: '#ff8939',
            fontSize: 15
        },
        Button: {
            colorPrimary: '#0e3f66',
            colorPrimaryHover: '#0e3f66'
        }
    }
}

export default theme