import styled, { css } from 'styled-components'
import { NumericTextBox as KendoNumericTextBox } from '@progress/kendo-react-inputs'
import { useState, useEffect } from 'react'
import { baseInputStyles } from './Input'
import FieldError from './FieldError'

const NumericTextBox = (props: any) => {
    const [error, setError] = useState(null);
    useEffect(() => {
        setError(props.formErrors?.find((x: any) => x.id === props.id)?.message);
    }, [props.formErrors]);
    return (
        <>
            <NumericTextBoxWrapper {...props} error={!!error} />
            <FieldError error={error || ''} variant='primary' />
        </>
    )
}

const NumericTextBoxWrapper = styled(KendoNumericTextBox)`
    ${baseInputStyles}
    border-radius: 5px;
    border: ${({ error }) => (error ? '1px solid #f7685b' : '1px solid #e0e7ff')};
    background: linear-gradient(0deg, rgba(224, 231, 255, 0.2) 0%, rgba(224, 231, 255, 0.2) 100%);
    padding: 10px 15px;
    position: relative;
    && input {
        font-family: 'Rubik';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        padding-block: 0;
        padding-inline: 0;
        ::placeholder,
        ::-webkit-input-placeholder {
            color: rgba(110, 140, 163, 0.6) !important;
        }
        :-ms-input-placeholder {
            color: rgba(110, 140, 163, 0.6) !important;
        }
    }

    ${({ prefix }) =>
        (prefix &&
            css`
                padding-right: 50px;
            `) ||
        ''}
`

export default NumericTextBox
