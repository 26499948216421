export const CURRENCY = 'currency'
export const DATE_FORMAT = 'date-format'
export const TIME_ZONE = 'time-zone'
export const DECIMALS = 'decimals'
export const QUOTE_EXPIRY = 'quote-expiry'
export const TAX_TAXABLE = 'salestax-enabled'
export const TAX_POINT_OF_SALE = 'salestax-point'
export const QUOTE_PRODUCT_MARGIN_UNIT = 'quote-product-margin-unit'
export const QUOTE_TC_TEXT = 'quote-tc-text'
export const UNIT = 'unit'
export const SEPARATOR = 'separator'
export const THOUSAND_SEPARATOR = 'thousandSeparator'
export const ENABLE_TENANT_DOMAIN_EMAIL = 'enableTenantDomainEmail'
export const ENABLE_USER_EMAIL = 'enableUserEmail'
export const USER_SENT_EMAIL = 'userSentEmail'

export const ENABLE_CREDIT_LIMIT_NOTIFICATION = 'enableCreditLimitNotificationSetting';
export const CREDIT_LIMIT_NOTIFICATION_QUOTE_STAGE = 'creditLimitNotificationQuoteStageSetting';
export const ENABLE_CREDIT_LIMIT_NOTIFICATION_QUOTE_STAGE = 'enableCreditLimitNotificationQuoteStageSetting';
export const ENABLE_CREDIT_LIMIT_VALIDATION_QUOTE_STAGE = 'enableCreditLimitValidationQuoteStageSetting';
export const CREDIT_LIMIT_VALIDATION_QUOTE_STAGE = 'creditLimitValidationQuoteStageSetting';
export const CREDIT_LIMIT_VALIDATION_USER = 'creditLimitValidationUserSetting';

export const DATE_FORMAT_DEFAULT = 'DD/MM/YYYY'
export const DECIMALS_DEFAULT = 'dec-0.1'
export const DECIMALS_COMMA = 'dec-0,1'
export const TAX_POS_DEFAULT = 'delivery'
export const CURRENCY_DEFAULT = 'usd'
export const DEFAULT_UNIT = 'metric'
export const SEPARATOR_DEFAULT = 'dec-0.1'
export const THOUSAND_SEPARATOR_DEFAULT = 'space'
export const STANDARD_PRODUCTS_VIEW = 'standardProductsView'

export const INCLUDES_TRANSPORT_COST = 'includesTransportCost'
export const ENABLE_TENANT_DOMAIN_EMAIL_DEFAULT = false
export const ENABLE_USER_EMAIL_DEFAULT = false

export const ENABLE_DOCUMENT_STORAGE = 'enableDocumentStorage'
export const ENABLE_DOCUMENT_STORAGE_DEFAULT = false

export const EXPIRATION_DATES = [
    {
        label: '30',
        value: 30
    },
    {
        label: '60',
        value: 60
    }
]

export const currencies = [
    {
        value: 'usd',
        code: '$'
    },
    {
        value: 'nzd',
        code: '$'
    },
    {
        value: 'eur',
        code: '€'
    }
]

export const prefixes = [currencies[0].value, currencies[1].value]
export const suffixes = [currencies[2].value]

export const granularityOptions = [
    { value: 'product', label: 'Per Product' },
    { value: 'quote', label: 'Per Quote' }
]
