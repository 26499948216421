import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import RichText from './RichText';

const Entry = ({ entry, style = {} }) => {
    return (
        <View>
            <RichText style={style} note={entry} />
        </View>
    );
};

export default Entry;
