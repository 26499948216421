import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { openGlobalModal, closeGlobalModal } from '@reducers/globalModal/actions';

import { getGlobalModal } from '@reducers/globalModal/selectors';

export const useGlobalModal = () => {
    return {
        modal: useSelector(getGlobalModal, shallowEqual)
    };
};

export const useShowGlobalModal = (props) => {
    const dispatch = useDispatch();

    const showModal = useCallback((params = {}) => {
            dispatch(openGlobalModal({ ...props, body: params?.message, ...params }));
        },
        [dispatch]
    );

    return showModal;
};

export const useHideGlobalModal = () => {
    const dispatch = useDispatch();

    const hideGlobalModal = useCallback(() => {
        dispatch(closeGlobalModal());
    }, [dispatch]);

    return hideGlobalModal
};
