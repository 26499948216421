const TableSummary = ({ title = '', total = 0, selected = 0, selectable = true }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <h2 style={{ color: '#0E3F66' }}>{title}</h2>
        <div style={{ width: '2px', height: '19px', background: '#E0E7FF', margin: '0 8px' }}></div>
        <div style={{ color: '#6E8CA3', fontSize: '16px' }}>{total} total</div>
        {selectable && (
            <>
                <div style={{ width: '2px', height: '19px', background: '#E0E7FF', margin: '0 8px' }}></div>
                <div style={{ padding: '5px 10px', background: 'rgba(219, 237, 255, 0.75)', borderRadius: '4px', fontSize: '15px', color: '#0E3F66' }}>{selected} selected</div>
            </>
        )}
    </div>
)

export default TableSummary
