export const currencyFormat = (num, decseparator, currency, thousandSeparator) => {
    const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency
    };

    let x = !num || Number.isNaN(num) ? 0 : num;

    let priceBySettings = Number(x).toLocaleString(decseparator === 'dec-0.1' ? 'en' : 'de', options);
    if (thousandSeparator === 'space') {
        const signToReplace = decseparator === 'dec-0.1' ? '[,]' : '[.]'
        const regExp = new RegExp(signToReplace, 'g')
        priceBySettings = priceBySettings.replace(regExp, ' ');
    }

    if (decseparator === 'dec-0,1') {
        const signToReplace = decseparator === 'dec-0.1' ? '[.]' : '[,]'
        const regExp = new RegExp(signToReplace, 'g')
        priceBySettings = priceBySettings.replace(regExp, ',');
    }
    return priceBySettings;
};

export const currencyByLocalstorage = (num, onlyNumber) => {
    if (currency && decimals && thousandSeparator) {
        const isDollar = currency?.length ? ['usd', 'nzd'].some(term => currency.includes(term)) : true;

        const options = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        };
        let x = !num || Number.isNaN(num) ? 0 : num;
        const isNegativeNumber = x < 0;
        if (isNegativeNumber) {
            x = Math.abs(x);
        }

        const price = Number(x).toLocaleString('en-US', options);

        // default price is: 1,111,111.00
        const getPriceBySettings = price => {
            let priceBySettings = price;

            if (thousandSeparator === 'space') {
                priceBySettings = priceBySettings.replace(/,/g, ' ');
            }

            if (decimals === 'dec-0,1') {
                priceBySettings = priceBySettings.replace(/\./g, ',');
            }
            return priceBySettings;
        };

        const validPrice = getPriceBySettings(price);

        if (onlyNumber) {
            return validPrice;
        }

        const resultPrice = isDollar ? '$' + validPrice : validPrice + ' €';
        if (!isNegativeNumber) {
            return resultPrice;
        }

        return `-${resultPrice}`;
    }

    return Number.parseFloat(num).toFixed(2);;
};
