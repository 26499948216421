import React from 'react'
import styled, { css } from 'styled-components'
import { Tabs, TabsProps } from 'antd'

type StyledTabsProps = {
    mb0?: boolean
    pt0?: boolean
    remainingHeight?: number
}

const TabsStyles = styled.div<StyledTabsProps>`
    .ant-tabs-nav-wrap {
        background-color: white !important;
    }
    .ant-tabs-tab {
        color: #6e8ca3 !important;
        padding: 15px;
        background-color: white;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #0e3f66 !important;
    }
    .ant-tabs-ink-bar {
        position: absolute;
        background: #ff8939;
        pointer-events: none;
    }
    .ant-tabs-nav-more {
        background-color: white !important;
    }
    .ant-tabs-nav {
        margin-bottom: ${({ mb0 }) => (mb0 ? 0 : '20px')};
    }
    .ant-tabs {
        font-family: Rubik !important;
    }

    .ant-tabs-tab-btn:focus:not(:focus-visible),
    .ant-tabs-tab-remove:focus:not(:focus-visible),
    .ant-tabs-tab-btn:active,
    .ant-tabs-tab-remove:active {
        color: inherit;
    }

    ${({ remainingHeight }) =>
        (remainingHeight &&
            css`
                .ant-tabs-content-holder {
                    height: calc(100vh - ${remainingHeight}px);
                    overflow-y: scroll;
                    overflow-x: hidden;

                    ::-webkit-scrollbar {
                        height: 8px;
                        width: 0;
                    }
                }
            `) ||
        ''}
`

export default TabsStyles
