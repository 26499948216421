export const BTN_CONTINUE = 'CONTINUE'
export const BTN_SAVE = 'SAVE'
export const BTN_CLOSE = 'CLOSE'
export const BTN_CANCEL = 'CANCEL'
export const BTN_APPLY = 'APPLY'
export const BTN_ADD = 'Add'
export const BTN_OK = 'OK'
export const BTN_DELETE = 'Yes, delete'
export const BTN_MERGE = 'Merge'

export const BTN_TEXT_LIST = {
    [BTN_CONTINUE]: 'Continue',
    [BTN_SAVE]: 'Save',
    [BTN_CLOSE]: 'Close',
    [BTN_CANCEL]: 'Cancel',
    [BTN_APPLY]: 'Apply',
    [BTN_ADD]: 'Add',
    [BTN_OK]: 'OK',
    [BTN_DELETE]: 'Yes, delete',
    [BTN_MERGE]: 'Merge'
}
