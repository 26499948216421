import { useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { Feather } from '@components';

import '@scss/components/_action-menu.scss';

const ActionMenu = props => {
    const { label = null, options, iconName = 'MoreVertical', readOnly = false, parentComponent = null, width = 150, offset = { x: 0, y: 0 }, customClass, position } = props;

    const [isOpen, setOpen] = useState(false);
    const [location, setLocation] = useState({ x: 0, y: 0 });

    const onRef = useRef();

    const updatePosition = () => {
        const coords = onRef.current?.getBoundingClientRect();
        let tabPosition = { x: 0, y: 0 };
        if (parentComponent) {
            tabPosition = parentComponent.getBoundingClientRect();
        }
        if(!coords || !offset ||!tabPosition)return
        let x = coords.x - tabPosition.x + coords.width - width - 10 + offset.x;
        let y = coords.y + coords.height + 5 - tabPosition.y + offset.y;
        setLocation(position ? position : { x, y });
    };

    useEffect(() => {
        window.addEventListener('scroll', updatePosition);
        return () => window.removeEventListener('scroll', updatePosition);
    }, []);

    const showDropdown = () => {
        if (isOpen) {
            setOpen(false);
        } else {
            updatePosition();
            setOpen(true);
        }
    };

    useEffect(() => {
        const handleClickOutside = event => {
            if (onRef.current && !onRef.current.contains(event.target)) {
                setOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    return (
        <div className='ActionMenu' ref={onRef}>
            {!readOnly && (
                <div className={`d-flex ${customClass}`}>
                    {label && <span onClick={showDropdown}>{label}</span>}
                    <Feather className='icon' name={iconName} onClick={showDropdown} />
                </div>
            )}
            {isOpen ? (
                createPortal(
                    <div className='ActionMenu__dropdown-menu' style={{ left: location.x, top: location.y, width }}>
                        {options.map((item, idx) => (
                            <li
                                className={`item ${item.disabled ? 'item--disabled' : ''}`}
                                key={idx}
                                value={item.value}
                                onClick={() => {
                                    if (!item.disabled) {
                                        item.action();
                                        setOpen(false);
                                    }
                                }}>
                                <a data-custom-action={item.label} onClick={item.action}>
                                    {item.label}
                                </a>
                            </li>
                        ))}
                    </div>,
                    document.body
                )
            ) : (
                <></>
            )}
        </div>
    );
};

export default ActionMenu;
