import React, { SyntheticEvent } from 'react'
import { CheckboxFilter } from './CheckboxFilter'
import { statusFilterOptions } from './filter.constants'
import { GridFilterCellProps } from '@progress/kendo-react-grid'

export const StatusFilter: React.FC<GridFilterCellProps> = ({ onChange, value }) => {
    const handleChange = (value: string[], event: React.ChangeEvent<HTMLSelectElement>) => {
        onChange({ value, operator: 'in', syntheticEvent: event })
    }

    return <CheckboxFilter options={statusFilterOptions} onSubmit={handleChange} value={value} />
}
