import { useMemo, useState, useEffect } from 'react'

import BSDropdown from 'react-bootstrap/Dropdown'
import Label from 'react-bootstrap/FormLabel'
import { FieldError } from '@common'
import { Spinner } from '@components'

import DropdownItem from './parts/DropdownItem'
import DropdownMenu from './parts/DropdownMenu'
import DropdownToggle from './parts/DropdownToggler'

import styles from './style.module.scss'

export const Dropdown = props => {
    const { selected, placeholder, label, items, disabled = false, loading = false, multi = false, footer = null, onChange, onSearch, menuToggler = null, formErrors, id } = props

    const [showMenu, setShowMenu] = useState(false)
    // this state is for handling searchReset of child component
    const [searchReset, setSearchReset] = useState(0)

    const dropdownToggle = useMemo(() => {
        const baseClassName = `${styles.dropdown_toggle}`

        if (!selected || (multi && !selected.length) || (!multi && selected.value && !selected.label)) {
            return {
                className: `${baseClassName} ${styles.dropdown_empty}`,
                text: disabled ? '-' : placeholder
            }
        }

        const selectedLabel =
            (selected.value ? items.find(item => item.value === selected.value)?.label : items.find(item => item.value === (selected.label || selected))?.label) ||
            label ||
            selected.label

        return {
            className: `${baseClassName} ${styles.dropdown_filled}`,
            text: !multi ? selectedLabel : selected.map(s => s.label).join(', ')
        }
    }, [selected, disabled])

    const isCheckedItem = i => {
        if ((!multi && !selected) || (multi && !selected?.length)) {
            return false
        }

        if (!multi) {
            return selected._id === i._id
        }

        return selected.find(s => s._id === i._id)
    }

    const handleOnToggle = () => {
        if (disabled) {
            return
        }

        setShowMenu(prev => !prev)
    }

    const handleOnChange = value => {
        onChange(value)
        setSearchReset(prev => prev + 1)

        if (multi) {
            return
        }

        handleOnToggle()
    }

    const [error, setError] = useState(null);
    useEffect(() => {
        setError(formErrors?.find(x => x.id === id)?.message);
    }, [formErrors]);

    const renderItems = () => {
        if (!items?.length) {
            return null
        }

        return items.map((i, k) => (
            <BSDropdown.Item id={i._id} key={k} as={DropdownItem} multi={multi} value={i.value} disabled={disabled} checked={isCheckedItem(i)} onChange={handleOnChange}>
                {multi ? <div style={{ position: 'relative', left: '30px', top: '-8px' }}>{i.label}</div> : i.label}
            </BSDropdown.Item>
        ))
    }

    return (
        <>
            {label && <Label className='Label'>{label}</Label>}{' '}
            <BSDropdown className={`${error ? styles.dropdown_error : ''} ${styles.dropdown}`} show={showMenu} disabled={disabled} onToggle={handleOnToggle}>
                <BSDropdown.Toggle {...(menuToggler ? { as: DropdownToggle } : '')} className={dropdownToggle.className} disabled={disabled}>
                    {menuToggler ? (
                        menuToggler
                    ) : (
                        <>
                            <span>{dropdownToggle.text}</span>
                            {!disabled && <div className={`${styles.icon_wrapper} ${false && styles.icon_wrapper_disabled}`} />}
                            {!disabled && (
                                <img
                                    className={`${styles.toggle_icon} ${showMenu ? styles.toggle_icon_open : ''} arrow_icon`}
                                    src='/static/media/Arrow.7251893fe57b2d4b4435c037979aa041.svg'
                                    alt='icon'
                                />
                            )}
                        </>
                    )}
                </BSDropdown.Toggle>
                <BSDropdown.Menu
                    as={DropdownMenu}
                    resetSearch={searchReset}
                    className={styles.dropdown_menu}
                    footer={footer}
                    multi={multi}
                    displaySearch={onSearch}
                    onSearch={onSearch}>
                    {loading ? <Spinner /> : renderItems()}
                </BSDropdown.Menu>
            </BSDropdown>
            <FieldError error={error} variant='primary' />
        </>
    )
}
