const ColumnsIcon = () => (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M18 4H8C7.33696 4 6.70107 4.26339 6.23223 4.73223C5.76339 5.20107 5.5 5.83696 5.5 6.5V7.5C5.5 7.63261 5.55268 7.75979 5.64645 7.85355C5.74021 7.94732 5.86739 8 6 8C6.13261 8 6.25979 7.94732 6.35355 7.85355C6.44732 7.75979 6.5 7.63261 6.5 7.5V6.5C6.5 6.10218 6.65804 5.72064 6.93934 5.43934C7.22064 5.15804 7.60218 5 8 5H12.5V20H8C7.60218 20 7.22064 19.842 6.93934 19.5607C6.65804 19.2794 6.5 18.8978 6.5 18.5V17.5C6.5 17.3674 6.44732 17.2402 6.35355 17.1464C6.25979 17.0527 6.13261 17 6 17C5.86739 17 5.74021 17.0527 5.64645 17.1464C5.55268 17.2402 5.5 17.3674 5.5 17.5V18.5C5.5 19.163 5.76339 19.7989 6.23223 20.2678C6.70107 20.7366 7.33696 21 8 21H18C18.663 21 19.2989 20.7366 19.7678 20.2678C20.2366 19.7989 20.5 19.163 20.5 18.5V6.5C20.5 5.83696 20.2366 5.20107 19.7678 4.73223C19.2989 4.26339 18.663 4 18 4ZM19.5 18.5C19.5 18.8978 19.342 19.2794 19.0607 19.5607C18.7794 19.842 18.3978 20 18 20H13.5V5H18C18.3978 5 18.7794 5.15804 19.0607 5.43934C19.342 5.72064 19.5 6.10218 19.5 6.5V18.5ZM6.5 10.5V12H8C8.13261 12 8.25979 12.0527 8.35355 12.1464C8.44732 12.2402 8.5 12.3674 8.5 12.5C8.5 12.6326 8.44732 12.7598 8.35355 12.8536C8.25979 12.9473 8.13261 13 8 13H6.5V14.5C6.5 14.6326 6.44732 14.7598 6.35355 14.8536C6.25979 14.9473 6.13261 15 6 15C5.86739 15 5.74021 14.9473 5.64645 14.8536C5.55268 14.7598 5.5 14.6326 5.5 14.5V13H4C3.86739 13 3.74021 12.9473 3.64645 12.8536C3.55268 12.7598 3.5 12.6326 3.5 12.5C3.5 12.3674 3.55268 12.2402 3.64645 12.1464C3.74021 12.0527 3.86739 12 4 12H5.5V10.5C5.5 10.3674 5.55268 10.2402 5.64645 10.1464C5.74021 10.0527 5.86739 10 6 10C6.13261 10 6.25979 10.0527 6.35355 10.1464C6.44732 10.2402 6.5 10.3674 6.5 10.5Z'
            fill='#0E3F66'
        />
    </svg>
)

export default ColumnsIcon
