import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { usePermissions } from '@hooks/usePermissions';

const PermissionComponent = ({ context, action, overwriteRoleAccess = [], children }) => {
    const user = useSelector(store => store.auth.user);
    const [hasAccess, setHasAccess] = useState(false);

    const permissions = usePermissions();
    useEffect(() => {
        if (context && action) {
            const hasPerm = permissions(context, { action });
            setHasAccess(hasPerm);
        }
    }, [context, action, user?.userRoles]);

    return overwriteRoleAccess.length > 0 ? (
        overwriteRoleAccess.includes(user?.userType) ? (
            <React.Fragment>{children}</React.Fragment>
        ) : null
    ) : (
        hasAccess && <React.Fragment>{children}</React.Fragment>
    );
};

export default PermissionComponent;
