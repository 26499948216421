import axios from 'axios';

import service from './service';

export const fetchTcTemplates = async () => {
    const res = await service.get(`/tc/`);
    return res.data;
};

export const createTcTemplate = async data => {
    const res = await service.post('/tc/', data);
    return res.data;
};

export const deleteTcTemplate = async id => {
    const res = await service.delete(`/tc/${id}`);
    return res.data;
};

export const updateTcTemplate = async (template, id) => {
    const res = await service.put(`/tc/${id}`, template);
    return res.data;
};

export const getTemplateById = async id => {
    const res = await service.get(`/tc/file/${id}`);
    return res.data;
};

export const getTcFileDetails = async () => {
    const res = await service.get('/tc/files');
    if (!res.data.length) return null;
    const file = res.data[0];
    const name = file.name.split('/').pop();
    return { id: file.id, fileName: file.name, name };
};

export const getTcUploadFileUrl = async () => {
    const res = await service.get('/tc/files/signed-upload-url');
    return res.data;
};

export const uploadTcFile = async (url, file) => {
    return await axios.put(url, file, { headers: { 'Content-Type': 'application/pdf' } })
};

export const getTcDownloadFileUrl = async (fileName) => {
    const res = await service.post(`/tc/files/signed-download-url`, { fileName });
    return res.data;
};

export const getTcFile = async () => {
    const tcFileDetails = await getTcFileDetails();
    if (!tcFileDetails) {
        return null;
    }

    const tcFileDownloadUrl = await getTcDownloadFileUrl(tcFileDetails.fileName);
    const res = await axios.get(tcFileDownloadUrl.url, {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/pdf',
            Accept: 'application/pdf'
        }
    });

    return res.data;
};

export const deleteTcFile = async (fileName) => {
    await service.post(`/tc/files/delete`, { fileName });
};
