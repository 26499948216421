export const phoneNumber = phone => {
    return phone.replace(/[^0-9-+ ]/gi, '')
}

export const numberWithDecimals = number => {
    return number.replace(/[^0-9|^.]/gi, '')
}

export const numberWithoutDecimals = number => {
    return number.replace(/[^0-9]/gi, '')
}

export const checkValidFloat = x => {
    const regex = /^[0-9]*\.?[0-9]*$/
    return x === '' || regex.test(x)
}

export const isNumber = input => {
    return /^-?\d*(\.\d*)?$/.test(input)
}
