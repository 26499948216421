import styled, { css } from 'styled-components'
import { IDropdownItemValue, IFilterDropdownItem } from './SelectDropdown'

interface OptionListProps {
    options: IFilterDropdownItem[]
    onSelect: (item: IFilterDropdownItem) => void
    value?: IDropdownItemValue
}

export const OptionList: React.FC<OptionListProps> = ({ options, onSelect, value }) => {
    return (
        <ul>
            {options?.map((option: IFilterDropdownItem) => (
                <DropdownItem key={option.value} onClick={() => onSelect(option)} selected={option.value === value}>
                    {option.label}
                </DropdownItem>
            ))}
        </ul>
    )
}

const DropdownItem = styled.li<{ selected?: boolean }>`
    padding: 8px 10px;
    color: #404040;
    font-size: '15px';
    width: 100%;
    border-radius: 5px;
    :hover {
        background-color: #dbedff;
        cursor: pointer;
    }

    ${({ selected }) =>
        selected
            ? css`
                  background-color: #dbedff;
              `
            : ''}
`
