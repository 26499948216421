import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Checkbox } from '@common'
import { Feather, Image, FieldError } from '../';

import { useOnClickOutside } from 'utils/onClickOutside';

import Arrow from '@assets/icons/Arrow.svg';

import styles from '@scss/components/_drcheckbox.module.scss';

const Panel = styled.ul`
    top: ${p => p.top}px !important;
    left: ${p => p.left}px !important;
    width: ${p => p.width}px !important;
`;

const DropdownCheckBox = ({
    value = null,
    id = '',
    name = '',
    formErrors,
    options,
    onRef = React.createRef(),
    onChildRef = React.createRef(),
    holdChild = true,
    onChange = (props) => { },
    multiple = false,
    onlyOneSub = false,
    onClickOutSide = () => { },
    parentComponent = null,
    label = 'UNSET',
    style = {},
    readOnly = false,
    disabled = false,
    returnFullObject = false,
    placeholder = ''
}) => {
    const [viewMode, setViewMode] = useState(true);
    const [location, setLocation] = useState({});
    const [selected, setSelected] = useState();
    const refList = options.map(r => React.createRef());
    const [sub_location, set_sub_location] = useState({});
    const [show_sub, set_show_sub] = useState(-1);
    const [selected_parent, set_selected_parent] = useState(value);
    const [changedDomElements, setChangedDomElements] = useState([]);

    const [error, setError] = useState(null);
    useEffect(() => {
        setError(formErrors?.find(x => x.id === id)?.message);
    }, [formErrors]);

    useEffect(() => {
        if (!viewMode) {
            const tmp = document.getElementsByClassName('overflow-overlay');
            if (tmp && HTMLCollection.prototype.isPrototypeOf(tmp)) {
                let changed = [];
                Array.from(tmp).forEach(element => {
                    if (!element.classList.contains('overflow-hidden')) {
                        changed.push(element);
                        element.classList.add('overflow-hidden');
                    }
                });
                setChangedDomElements(changed);
            }
        } else {
            changedDomElements.map(element => {
                element.classList.remove('overflow-hidden');
            });
        }
    }, [viewMode]);

    useEffect(() => {
        const coords = onRef.current.getBoundingClientRect();
        let tabPosition = { x: 0, y: 0 };
        if (parentComponent) {
            tabPosition = parentComponent.getBoundingClientRect();
        }
        let x = coords.x - tabPosition.x;
        let y = coords.y + coords.height + 35 - tabPosition.y;
        const menu_height = Math.min(options.length, 4) * 40;
        const diff = coords.y + coords.height + 5 + menu_height - window.innerHeight;
        if (diff > 0) {
            y -= diff;
        }
        setLocation({ x, y, width: coords.width });

        set_show_sub(-1);
        set_selected_parent(value);
    }, [viewMode]);

    useEffect(() => {
        if (value && options && options.length > 0) {
            if (multiple) {
                setSelected(
                    value.map(val => {
                        for (const opt of options) {
                            let obj;
                            if (opt.sub) {
                                obj = opt?.sub && opt?.sub.find(x => x.value === val);
                            } else {
                                if (opt.value === val) {
                                    obj = opt;
                                }
                            }
                            if (obj) {
                                if (returnFullObject) {
                                    return { ...obj };
                                } else {
                                    return { value: obj.value, label: obj.label };
                                }
                            }
                        }
                    })
                );
            } else {
                setSelected(value);
            }
        } else {
            setSelected([]);
        }
    }, [value, options]);

    if (onChildRef) {
        useOnClickOutside(onChildRef, () => {
            setViewMode(true);
        });
    }

    if (onRef) {
        if (viewMode) useOnClickOutside(onRef, () => onClickOutSide());
        else useOnClickOutside(onRef, () => { });
    }

    const showOptionsPanel = e => {
        setViewMode(viewMode => {
            return !viewMode;
        });
    };

    const onToggleSubPanel = (index, opt, e) => {
        if (!opt.sub) {
            if (!multiple) {
                setSelected([opt]);
                setViewMode(!viewMode);
                onChange(returnFullObject ? opt : opt.value, e);
                return;
            }
            if (!selected) {
                setSelected([opt]);
                onChange([returnFullObject ? opt : opt.value, e]);
                return;
            }

            let temp = [...selected];

            const index = temp.findIndex(e => e.value === opt.value);
            if (index === -1) {
                temp = [...temp, opt];
            } else {
                temp.splice(index, 1);
            }

            if (onlyOneSub) {
                options.forEach(main => {
                    if (main?.sub?.findIndex(x => x.value === opt.value) != -1) {
                        main.sub.forEach(x => {
                            if (x.value != opt.value) {
                                const index = temp.findIndex(e => e.value === x.value);
                                if (index != -1) {
                                    temp.splice(index, 1);
                                }
                            }
                        });
                    }
                });
            }

            setSelected(temp);
            onChange(
                temp.map(x => (returnFullObject ? x : x.value)),
                e
            );

            return;
        }
        set_selected_parent([opt]);

        const coords = refList[index].current.getBoundingClientRect();

        let tabPosition = { x: 0, y: 0 };
        if (parentComponent) {
            tabPosition = parentComponent.getBoundingClientRect();
        }
        let x = coords.x + coords.width - tabPosition.x;
        let y = coords.y - tabPosition.y;

        const menu_height = Math.min(opt.sub?.length, 4) * 40;
        const diff = coords.y + coords.height + 5 + menu_height - window.innerHeight;
        if (diff > 0) {
            y -= diff;
        }

        set_sub_location({ x, y, width: coords.width });

        set_show_sub(index);
    };

    const onToggleSelectAll = (ind, opt, e) => {
        onToggleSubPanel(ind, opt);

        if (!opt.sub) {
            return;
        }

        if (!opt?.sub?.every(x => selected.findIndex(a => a.value == x.value) != -1)) {
            const unSelected = opt.sub.filter(os => selected.every(s => s.value !== os.value));
            const tempSelected = [...selected, ...unSelected];
            setSelected(tempSelected);
            onChange(
                tempSelected.map(x => (returnFullObject ? x : x.value)),
                e
            );
        } else {
            let removingSubMenu = [...opt.sub];
            const updatedSelected = selected.filter(s => removingSubMenu.every(rs => rs.value !== s.value));
            setSelected(updatedSelected);
            onChange(
                updatedSelected.map(x => (returnFullObject ? x : x.value)),
                e
            );
        }
    };

    return (
        <div className={`${styles['drop-checkbox']} ${readOnly || disabled ? styles['drop--disabled'] : ''}`} style={{ ...style }} ref={onRef}>
            {label != 'UNSET' && <p className={styles['drop-checkbox__label']}>{label}</p>}
            <div
                className={`${styles['idle']} ${error ? styles['idle--error'] : ''} ${!viewMode ? styles['idle--focus'] : ''} ${readOnly || disabled ? '' : styles['idle--normal']}`}
                onClick={e => {
                    if (!readOnly && !disabled) {
                        e.preventDefault();
                        showOptionsPanel();
                    }
                }}>
                <div className={`${styles['selected-items']} ${!selected?.length ? styles['selected-empty'] : ''}`}>
                    {selected &&
                        selected.map(val => (
                            <div className={styles['item']} key={val?.value}>
                                <span>{val?.label}</span>
                                {!readOnly ||
                                    (disabled && (
                                        <Feather
                                            name='X'
                                            onClick={() => {
                                                const temp = [...selected];
                                                const index = temp.findIndex(t => t.value === val.value);
                                                temp.splice(index, 1);
                                                setSelected(temp);
                                                onChange(multiple ? temp.map(x => (returnFullObject ? x : x.value)) : returnFullObject ? temp[0] : temp[0].value);
                                            }}
                                        />
                                    ))}
                            </div>
                        ))}
                </div>
                {!selected?.length && <span className={styles['checkbox-placeholder']}>{placeholder}</span>}
                <div className={styles['icon-wrapper']}>
                    <Image src={Arrow} className={`${styles['arrow_icon']} ${!viewMode ? styles['arrow_icon--rotate'] : ''}`} />
                </div>
            </div>
            {!viewMode && (
                <Panel left={location.x} top={location.y} width={location.width} className={styles['panel']} ref={onChildRef} onClick={() => (holdChild ? setViewMode(false) : {})}>
                    <ul className={styles['panel__checkbox-list']}>
                        {options.map((opt, index) => (
                            <li
                                key={opt.value}
                                className={`${selected ? (selected.findIndex(e => e.value === opt.value) !== -1 ? styles['selected'] : '') : ''}`}
                                ref={refList[index]}
                                onClick={e => onToggleSubPanel(index, opt, e)}>
                                <div className='justify-flex-start'>
                                    <Checkbox
                                        id={`toggle-all-${opt.value}`}
                                        name={name}
                                        checked={
                                            !onlyOneSub && opt?.sub
                                                ? opt.sub?.every(x => selected.findIndex(a => a.value == x.value) != -1)
                                                : selected.findIndex(e => e.value === opt.value) !== -1
                                        }
                                        onChange={e => onToggleSubPanel(index, opt, e)}
                                        onClick={e => onToggleSelectAll(index, opt, e)}
                                        className='w-full'
                                        style={{ width: '100%' }}
                                        label={opt.label}
                                    >{opt.label}</Checkbox>
                                </div>
                                {opt.sub && <Feather name='ChevronRight' onClick={e => onToggleSubPanel(index, opt, e)} />}
                                {show_sub === index && (
                                    <Panel
                                        left={sub_location.x}
                                        top={sub_location.y}
                                        width={sub_location.width}
                                        className={`${styles['panel']} ${styles['sub']}`}
                                        onClick={e => {
                                            e.stopPropagation();
                                            if (holdChild) setViewMode(false);
                                        }}>
                                        <ul className={styles['panel__checkbox-list']}>
                                            {selected_parent &&
                                                selected_parent[0]?.sub?.map(s => (
                                                    <li
                                                        key={s.value}
                                                        className={`${selected ? (selected.findIndex(e => e.value === s.value) !== -1 ? styles['selected'] : '') : ''}`}
                                                        ref={refList[index]}>
                                                        <div className='w-full justify-flex-start'>
                                                            <Checkbox
                                                                id={`toggle-sub-panel-${s.value}`}
                                                                checked={selected ? selected.findIndex(e => e.value === s.value) !== -1 : false}
                                                                onChange={e => onToggleSubPanel(index, s, e)}
                                                                onClick={e => onToggleSubPanel(index, s, e)}
                                                            >{s.label}</Checkbox>
                                                        </div>
                                                    </li>
                                                ))}
                                        </ul>
                                    </Panel>
                                )}
                            </li>
                        ))}
                    </ul>
                </Panel>
            )}
            <FieldError error={error} variant='primary' />
        </div>
    );
};

export default DropdownCheckBox;
