import styled from 'styled-components'
import { Dropdown } from '@common'

const UnitDropdown = styled(Dropdown)`
    width: 65px !important;
    border-radius: 0px 4px 4px 0px;
    border-left: 1px solid #e0e7ff;
    background: #dbedff;

		& > .dropdown-trigger {
			width: 65px;
		}
`

export default UnitDropdown
