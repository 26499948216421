import React from 'react';

import '@scss/components/_progressBarMini.scss';

export default function ProgressBarMini({ percentage = 0, color = '#2ED47A' }) {
    return (
        <div style={{ width: '100px' }}>
            <div style={{ width: `${percentage}%`, height: '10px', background: `${color}` }}></div>
        </div>
    );
}
