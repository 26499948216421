import { useHistory } from 'react-router-dom'

import styled from 'styled-components'

import { Image } from '@components'

import { MeltingSpot, Analytics } from '@assets'

const HeaderIcons = () => {
    const history = useHistory()

    const handleAnalyticsClick = () => {
        history.push('/Analytics/ProjectActivity')
    }

    const handleMeltingSpotLogin = () => {
        history.push('/MeltingSpot')
    }

    return (
        <IconsWrapper>
            <IconWrapper>
                <Image src={MeltingSpot} onClick={handleMeltingSpotLogin} />
            </IconWrapper>
            <IconWrapper>
                <Image src={Analytics} onClick={handleAnalyticsClick} />
            </IconWrapper>
        </IconsWrapper>
    )
}

export default HeaderIcons

const IconsWrapper = styled.div`
    display: flex;
    gap: 10px;
`

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #0a66af;
    }
`
