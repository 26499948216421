export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN = 'LOGIN';
export const SET_TENANT = 'SET_TENANT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_PERMISSION = 'LOGIN_PERMISSION';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const UPDATE_USER_PERMISSION = 'UPDATE_USER_PERMISSION';
export const SET_USER_ROLES = 'SET_USER_ROLES';
