import { _cloneDeep } from "../../../../utils/jsonUtil";
import * as types from './types';

const initialState = {
    products: [],
    details: { count: 0, filter: '', limit: 0, page: 0, pages: 0 },
    sending: false,
    editting: false,
    editFields: {
        row: -1,
        col: []
    },
    adding: false,
    deleting : false,
    deletingFields : [],
    error: ''
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DELETE_PRODUCT:
            return {
                ...state,
                deleting: true,
                deletingFields : action.payload
            };
        case types.DELETE_PRODUCT_FAIL:
            return {
                ...state,
                deleting: false
            };
        case types.DELETE_PRODUCT_SUCCESS:
            const temp =[...state.products];
            action.payload.map(v =>{
                const i = temp.findIndex(t => t._id === v)
                temp.splice(i, 1)
            })
            return {
                ...state,
                products : temp,
                deleting: false
            };
        case types.EDIT_PRODUCT:
            return {
                ...state,
                editting: true,
                editFields: {
                    row: action.payload.id,
                    col: Object.keys(action.payload.params)
                }
            };
        case types.EDIT_PRODUCT_FAIL:
            return {
                ...state,
                editting: false
            };
        case types.EDIT_PRODUCT_SUCCESS:
            const list = _cloneDeep(state.products);
            const index = list.findIndex(e => e._id === action.payload._id);
            list[index] = action.payload;
            return {
                ...state,
                products: list,
                editting: false
            };
        case types.ADD_PRODUCT:
            return {
                ...state,
                adding: true
            };
        case types.ADD_PRODUCT_FAIL:
            return {
                ...state,
                adding: false
            };
        case types.ADD_PRODUCT_SUCCESS:
            return {
                ...state,
                products: [...state.products, action.payload],
                adding: false
            };

        case types.FETCH_LIST_PRODUCTS:
            return {
                ...state,
                sending: true
            };
        case types.FETCH_LIST_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.payload.items,
                details: action.payload.details,
                sending: false
            };
        case types.FETCH_LIST_PRODUCTS_FAIL:
            return {
                ...state,
                sending: false,
                error: action.payload
            };
        case types.CLEAR_ERROR:
            return {
              ...state,
              error: ''
            }
        default:
            return state;
    }
};

export default reducer;
