import React from 'react'
import { CheckboxFilter } from './CheckboxFilter'
import { GridFilterCellProps } from '@progress/kendo-react-grid'
import useStage from '@hooks/useStage'

export const stageFilterFactory =
    (entity: 'quote' | 'project') =>
    ({ onChange, value }: GridFilterCellProps) => {
        const { stages } = useStage(entity)
        const handleChange = (value: string[], event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange({ value, operator: 'in', syntheticEvent: event })
        }

        const stageFilterOptions =
            stages?.map(stage => ({
                label: stage.name,
                value: stage.key
            })) || []

        return <CheckboxFilter options={stageFilterOptions} onSubmit={handleChange} value={value} />
    }

export const StageFilter: React.FC<GridFilterCellProps> = stageFilterFactory('project')

export const QuoteStageFilter: React.FC<GridFilterCellProps> = stageFilterFactory('quote')
