import { useMutation } from '@tanstack/react-query'

import { mergeCustomers } from 'service/customersApi'

import useNotify from '@components/Notification/useNotify'

export const useMigrateCustomers = (onSuccess: () => void) => {
    const migrateCustomers = ({ from, to }: { from: string[]; to: string }) => {
        return mergeCustomers({ from, to })
    }

    const notify = useNotify()

    return useMutation(migrateCustomers, {
        onSuccess: data => {
            onSuccess()
            notify.notifySuccess(`${data.to} customer merged with ${data.from.join(', ')} customer${data.from.length > 1 ? 's' : ''}`, 'Merged successfully')
        },
        onError: () => notify.notifyError('Failed to merge customers')
    })
}
