import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import './style.scss';

const TooltipComponent = ({ content, placement, children }) => (
    <OverlayTrigger placement={placement} overlay={<Tooltip className='bs-tooltip'>{content}</Tooltip>}>
        <span className='d-inline-block'>{children}</span>
    </OverlayTrigger>
);

TooltipComponent.propTypes = {
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node, PropTypes.element]).isRequired,
    placement: PropTypes.string,
    children: PropTypes.node.isRequired
};

TooltipComponent.defaultProps = {
    placement: 'auto'
};

export default TooltipComponent;
