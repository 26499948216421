import { useEntity } from '@hooks/useEntity'

import service from './service'

export const fetchProducts = async (params = {}) => {
    const res = await service.get(`/products`, { params })
    return res.data
}

export const fetchProductsEntity = async (query, simplified = false) => {
    const params = new URLSearchParams()
    params.append('data', JSON.stringify(query))
    return (await service.get(`/products/select-entity${simplified ? '-simplified' : ''}`, { params })).data
}

export const fetchAssemblyProductsInfo = async (data = {}, params = {}) => {
    const res = await service.post(`/products/assembly-products-info`, data, { params })
    return res.data
}

export const fetchProduct = async (id, errorCallback = () => {}) => {
    const res = await service.get(`/products/${id}`)
    return res.data
}

export const fetchNextProductNo = async () => {
    const res = await service.get('/products/no')
    return res.data.productNo
}

export const updateProduct = async (product, errorCallback = () => {}) => {
    const res = await service.put(`/products/${product._id}`, product)
    return res.data
}

export const saveProduct = async product => {
    const res = await service.post('/products/', product)
    return res.data
}

export const attemptProductLock = async (id, errorCallback = () => {}) => {
    try {
        const res = await service.post(`/products/lock/${id}`)
        return res.data.success === true
    } catch (error) {
        errorCallback(error)
    }
    return false
}

export const unlockProduct = async (id, errorCallback = () => {}) => {
    try {
        const res = await service.post(`/products/unlock/${id}`)
        return res.data.success === true
    } catch (error) {
        errorCallback(error)
    }
    return false
}

export const changeStatus = async (id, status, errorCallback = () => {}) => {
    try {
        const res = await service.put(`/products/status/${id}`, { status })
        return res.data
    } catch (error) {
        console.log(error)
        errorCallback(error)
    }
    return {}
}

export const changeProductPriceStatus = async (id, priceId, status, errorCallback = () => {}) => {
    try {
        const res = await service.put(`/products/${id}/price/status/${priceId}`, { status })
        return res.data
    } catch (error) {
        console.log(error)
        errorCallback(error)
    }
    return {}
}

export const deleteProduct = async id => {
    await service.delete(`/products/${id}`)
}

export const bulkDeleteProducts = async ids => {
    const res = await service.post(`/products/delete`, ids)
    return res.data
}

export const bulkUpdateProducts = async payload => {
    const res = await service.post(`/products/update`, { payload })
    return res.data
}

export const useProductsApi = mutateCallback => useEntity('products', { mutateCallback })
