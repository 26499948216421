import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled from 'styled-components';

import Ripple from '../Ripple';

import 'react-tabs/style/react-tabs.css';
import '@scss/components/_tabs.scss';
import '@scss/components/_tabcomponent.scss';

const StyledTab = styled.div`
    width: 100%;
`;

const TabComponent = ({
    tabListStyle = {},
    tabPanelStyle = {},
    tabContentStyle = {},
    tabsIcons = [],
    initial = 0,
    onChange = () => { },
    tabsFormat = content => content,
    ...props
}) => {

    const [index, setIndex] = useState();

    let data;
    if (Array.isArray(props.children)) {
        data = [...props.children].filter(_=>_).flat();
    } else {
        data = [props.children];
    }
    const panel = data.map((comp, i) => (
        <TabPanel style={{transform: `translateX(${i > index ? 100 : -100}%`, ...tabPanelStyle}} key={i}>
            <div className={`${index === i ? 'react-tabs__tab_content p-none' : ''}`}>{comp}</div>
        </TabPanel>
    ));

    return (
        <div className='content' style={{...tabContentStyle}}>
            <StyledTab className='user-tabWrapper'>
                <Tabs defaultIndex={initial}>
                    <TabList className='tab-list'
                        style={tabListStyle}
                    >
                        {props.tabs.map((tab, i) => {
                            return (
                                <Tab
                                    disabled={typeof tab === 'object' && tab.disabled}
                                    className='ripple__container'
                                    key={i}
                                    onClick={e => {
                                        setIndex(i);
                                        onChange(i);
                                    }}>
                                    {typeof tab === 'object' && tab.render ? tab.render : <span>{tab}</span>}
                                    <Ripple color={'#ff89397a'} duration={750} />
                                    {tabsIcons[i]}
                                </Tab>
                            );
                        })}
                    </TabList>
                    {tabsFormat(panel)}
                </Tabs>
            </StyledTab>
        </div>
    );
};

export default TabComponent;
