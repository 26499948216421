import { reorderIcon } from "@progress/kendo-svg-icons";
import { SvgIcon } from "@progress/kendo-react-common";
import { GridCellProps } from "@progress/kendo-react-grid";

export const DragHandleCell = (gridCellprops: GridCellProps) => {
  const {id, style, colSpan, className, ...props} = gridCellprops
  return (
    <td
      id={id}
      colSpan={colSpan}
      className={className}
      style={{
        touchAction: "none",
        borderBottom: '1px solid lightgray',
        ...(style || {})
      }}
    >
      <span
        style={{
          cursor: "move",
          background: 'white',
        }}
        data-drag-handle={true}
      >
        <SvgIcon
          style={{
            pointerEvents: "none",
          }}
          icon={reorderIcon}
        />
      </span>
    </td>
  );
};