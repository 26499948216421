export const FETCH_LIST_CONTRACTS = 'FETCH_LIST_CONTRACTS';
export const FETCH_LIST_CONTRACTS_SUCCESS = 'FETCH_LIST_CONTRACTS_SUCCESS';
export const FETCH_LIST_CONTRACTS_FAIL = 'FETCH_LIST_CONTRACTS_FAIL';

export const ADD_CONTRACT = 'ADD_CONTRACT';
export const ADD_CONTRACT_SUCCESS= 'ADD_CONTRACT_SUCCESS';
export const ADD_CONTRACT_FAIL = 'ADD_CONTRACT_FAIL';

export const EDIT_CONTRACT = 'EDIT_CONTRACT';
export const EDIT_CONTRACT_SUCCESS= 'EDIT_CONTRACT_SUCCESS';
export const EDIT_CONTRACT_FAIL = 'EDIT_CONTRACT_FAIL';

export const DELETE_CONTRACT = 'DELETE_CONTRACT';
export const DELETE_CONTRACT_SUCCESS= 'DELETE_CONTRACT_SUCCESS';
export const DELETE_CONTRACT_FAIL = 'DELETE_CONTRACT_FAIL';

export const LINK_CONTRACT = 'LINK_CONTRACT';
export const LINK_CONTRACT_SUCCESS = 'LINK_CONTRACT_SUCCESS';
export const LINK_CONTRACT_FAIL = 'LINK_CONTRACT_FAIL';

export const CLEAR_ERROR = 'CLEAR_ERROR';
