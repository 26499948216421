import LabeledTextField from '@components/LabeledTextField'

import { useFinancialSettings } from '@hooks/useFinancialSettings'

type PurchaseOrderProps = {
    formErrors?: any[]
    readOnly?: Boolean
    autoFill?: Boolean
    onChange: (value: string | undefined) => void
    value: any
    originalValue: any
    label?: string
}

const PurchaseOrder = ({ formErrors = [], readOnly = false, autoFill = false, value, onChange, originalValue, label }: PurchaseOrderProps) => {
    const purchaseOrderLength = useFinancialSettings('purchase-order')

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (purchaseOrderLength !== null && e.target.value.length > purchaseOrderLength && (originalValue || '').length <= purchaseOrderLength) return
        onChange(e.target.value)
    }

    return (
        <LabeledTextField
            inputStyle={{ paddingRight: '40px' }}
            id='purchaseOrder'
            placeholder='Add purchase order'
            formErrors={formErrors}
            label={label}
            readOnly={readOnly}
            autoFill={autoFill}
            value={value}
            onChange={handleChange}
            extraInfo={!readOnly && !autoFill && purchaseOrderLength !== null && purchaseOrderLength - (value || '').length > 0 ? purchaseOrderLength - (value || '').length : null}
        />
    )
}

export default PurchaseOrder
