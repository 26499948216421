import React from 'react';

import { Checkbox } from '@common';

import styles from './styles.module.scss';

const DropdownItem = React.forwardRef((props, ref) => {
    const {
        id,
        name,
        checked,
        value,
        multi,
        children,

        onChange
    } = props;

    const handleOnChange = (e) => {
        e.preventDefault();
        onChange(value);
    };

    if (!multi) {
        return (
            <a href='#' role='button' ref={ref} className={`${styles.dropdown_item} ${styles.dropdown_item_single}`} onClick={handleOnChange}>
                {children}
            </a>
        )
    }

    return (
        <Checkbox
            id={id}
            name={name}
            checked={checked}
            onChange={onChange.bind(onChange, id)}
            className={`${styles.dropdown_item} ${styles.dropdown_item_multi} ${checked ? styles.dropdown_item_active : ''}`}
        >{children}</Checkbox>
    );
});

export default DropdownItem;
