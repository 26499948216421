import * as types from './types';

const initialState = {
    contracts: [],
    details: { count: 0, filter: '', limit: 0, page: 0, pages: 0 },
    sending: false,
    editting : false,
    editFields : {
        row : -1,
        col :[]
    },
    adding : false,
    deleting : false,
    deletingFields : []
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.LINK_CONTRACT:
            return {
                ...state,
                sending: true
            };
        case types.LINK_CONTRACT_SUCCESS:
            return {
                ...state,
                sending: false
            };
        case types.LINK_CONTRACT_FAIL:
            return {
                ...state,
                sending: false
            };
        case types.DELETE_CONTRACT:
            return {
                ...state,
                deleting: true,
                deletingFields : action.payload
            };
        case types.DELETE_CONTRACT_FAIL:
            return {
                ...state,
                deleting: false
            };
        case types.DELETE_CONTRACT_SUCCESS:
            const temp =[...state.contracts];
            action.payload.map(v =>{
                const i = temp.findIndex(t => t._id === v)
                temp.splice(i, 1)
            })
            return {
                ...state,
                contracts : temp,
                deleting: false
            };
        case types.EDIT_CONTRACT :
            return {
                ...state,
                editting : true,
                editFields : {
                    row : action.payload.id,
                    col : Object.keys(action.payload.params)
                }
            }
        case types.EDIT_CONTRACT_FAIL :
            return {
                ...state,
                editting : false
            }
        case types.EDIT_CONTRACT_SUCCESS:
            const list = [...state.contracts]
            const index = list.findIndex(e => e._id === action.payload._id)
            list[index] = action.payload;
            return {
                ...state,
                contracts : list,
                editting : false
            }
        case types.ADD_CONTRACT :
            return {
                ...state,
                adding : true
            }
        case types.ADD_CONTRACT_FAIL :
            return {
                ...state,
                adding : false
            }
        case types.ADD_CONTRACT_SUCCESS:
            return {
                ...state,
                contracts : [...state.contracts, action.payload],
                adding : false
            }
        case types.FETCH_LIST_CONTRACTS:
            return {
                ...state,
                sending: true
            };
        case types.FETCH_LIST_CONTRACTS_SUCCESS:
            return {
                ...state,
                contracts: action.payload.items,
                details: action.payload.details,
                sending: false
            };
        case types.FETCH_LIST_CONTRACTS_FAIL:
            return {
                ...state,
                sending: false,
                error: action.payload
            };
        case types.CLEAR_ERROR:
            return {
              ...state,
              error: ''
            }
        default:
            return state;
    }
};

export default reducer;
