export const FETCH_LIST_USER_GROUPS = 'FETCH_LIST_USER_GROUPS';
export const FETCH_LIST_USER_GROUPS_SUCCESS = 'FETCH_LIST_USER_GROUPS_SUCCESS';
export const FETCH_LIST_USER_GROUPS_FAIL = 'FETCH_LIST_USER_GROUPS_FAIL';

export const ADD_USER_GROUPS = 'ADD_USER_GROUPS';
export const ADD_USER_GROUPS_SUCCESS = 'ADD_USER_GROUPS_SUCCESS';
export const ADD_USER_GROUPS_FAIL = 'ADD_USER_GROUPS_FAIL';

export const EDIT_USER_GROUPS = 'EDIT_USER_GROUPS';
export const EDIT_USER_GROUPS_SUCCESS = 'EDIT_USER_GROUPS_SUCCESS';
export const EDIT_USER_GROUPS_FAIL = 'EDIT_USER_GROUPS_FAIL';

export const DELETE_USER_GROUPS = 'DELETE_USER_GROUPS';
export const DELETE_USER_GROUPS_SUCCESS = 'DELETE_USER_GROUPS_SUCCESS';
export const DELETE_USER_GROUPS_FAIL = 'DELETE_USER_GROUPS_FAIL';

export const CLEAR_ERROR = 'CLEAR_ERROR';