import React from 'react'
import styled, { keyframes } from 'styled-components'

import { usePrevious } from '@hooks/usePrevious'

const fillCircle = (startOffset: number, endOffset: number) => keyframes`
  from {
    stroke-dashoffset: ${startOffset};
  }
  to {
    stroke-dashoffset: ${endOffset};
  }
`

interface AnimatedCircleProps {
    startOffset: number
    endOffset: number
}

const AnimatedCircle = styled.circle<AnimatedCircleProps>`
    animation: ${({ startOffset, endOffset }) => fillCircle(startOffset, endOffset)} 1s forwards;
`

interface PieChartProps {
    percentage?: number
    width?: number
    height?: number
}

const PieChart: React.FC<PieChartProps> = props => {
    const { percentage = 0, width = 80, height = 80 } = props

    const radius = Math.min(width, height) / 2
    const thickness = radius * 0.35
    const circumference = 2 * Math.PI * radius
    const previousPercentage = usePrevious(percentage) || 0

    const startOffset = ((100 - previousPercentage) / 100) * circumference
    const endOffset = ((100 - percentage) / 100) * circumference

    const gradientStartOffset = '0%'
    const gradientEndOffset = `${percentage}%`

    // Adjust the viewBox to account for the padding
    const padding = thickness
    const viewBox = `-${padding} -${padding} ${width + 2 * padding} ${height + 2 * padding}`

    return (
        <svg width={width} height={height} viewBox={viewBox}>
            <defs>
                {/* Gradient Definition */}
                <linearGradient id='gradient' gradientUnits='userSpaceOnUse'>
                    <stop offset={gradientStartOffset} stopColor='#FFAB71' />
                    <stop offset={gradientEndOffset} stopColor='#FA771E' />
                </linearGradient>

                {/* Filter for inner shadow */}
                <filter id='innerShadow' x='-50%' y='-50%' width='200%' height='200%'>
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                    <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                    <feMorphology radius='1' operator='erode' in='SourceAlpha' result='effect1_innerShadow' />
                    <feOffset />
                    <feGaussianBlur stdDeviation='1.5' />
                    <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0.0358854 0 0 0 0 0.0987677 0 0 0 0 0.220833 0 0 0 0.2 0' />
                    <feBlend mode='normal' in2='shape' result='effect1_innerShadow' />
                </filter>
            </defs>

            {/* Background Circle */}
            <circle
                cx={width / 2}
                cy={height / 2}
                r={radius}
                fill='none'
                stroke='#C9E1F3'
                strokeWidth={thickness * 2}
                filter='url(#innerShadow)' // Applying the inner shadow filter
            />

            {/* Foreground Circle - based on percentage */}
            <AnimatedCircle
                cx={width / 2}
                cy={height / 2}
                r={radius}
                fill='none'
                stroke='url(#gradient)' // Referencing the gradient here
                strokeWidth={thickness * 2}
                strokeDasharray={circumference.toString()}
                strokeDashoffset={startOffset.toString()} // initially set to startOffset
                transform={`rotate(-90 ${width / 2} ${height / 2})`}
                startOffset={startOffset}
                endOffset={endOffset}
            />

            {/* Center hole */}
            <circle cx={width / 2} cy={height / 2} r={thickness} fill='#ffffff' />
        </svg>
    )
}

export default PieChart
