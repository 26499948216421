import styled from 'styled-components'

import { observer } from 'mobx-react-lite'

import { Currency, Image, Feather } from '@components'

import { useEntityManager } from '@hooks/useEntityManager'
import { useThousandSeparator } from '@hooks/useThousandSeparator'
import { useStore } from '../../services/context'

import getTotal from '../../utils/getTotal'

import { ClipboardAnalysis } from '@assets'

import { Entity } from '@project/types'

const QuoteLinesFooter = ({ entity }: { entity?: Entity }) => {
    const store: any = useStore(entity)
    const quoteViolations = store?.violations?.quote
    const { entityGroupByKey }: any = useEntityManager('quote.quoteEntries')
    const total = getTotal(store?.entries, store?.entity)
    let { totalPickedUpPrice, totalDelivered, pickedUpPriceIncludingTax, deliveredIncludingTax } = total

    const { formatNumber } = useThousandSeparator()

    return (
        <Footer>
            <div>
                <h2 className='font-medium text-blue-600'>
                    <Image src={ClipboardAnalysis} />
                    <span className='ml-2'>Quote Balance</span>
                </h2>
                <h2 className='bold text-blue-100' style={{ margin: '20px' }}>
                    TOTALS
                </h2>
            </div>
            <div className='quote-violations'>
                {quoteViolations &&
                    quoteViolations.map((x: any, i: number) => (
                        <div className='flex' key={`violations-${i}`}>
                            <Feather name='AlertTriangle' />
                            <span>{x.description}</span>
                        </div>
                    ))}
            </div>
            <div className='footer__prices'>
                <div className='footer__prices--block'>
                    <span className='footer__prices--block--title'>Quantity</span>
                    <div className='footer__prices--block--value' style={{ fontWeight: 400, display: 'flex', flexDirection: 'column' }}>
                        {Object.keys(total?.quantity || {}).map((uom, i) => (
                            <div key={i} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <span>{formatNumber(total.quantity[uom])}</span>
                                <span style={{ marginLeft: '10px' }}>{uom}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='footer__prices--block'>
                    <span className='footer__prices--block--title'>{entityGroupByKey?.totalPickedUpPrice?.fieldName || 'Total picked-up price'}</span>
                    <span className='footer__prices--block--value'>
                        <Currency value={totalPickedUpPrice} />
                    </span>
                </div>
                <div className='footer__prices--block'>
                    <span className='footer__prices--block--title'>{entityGroupByKey?.totalDelivered?.fieldName || 'Total delivered'}</span>
                    <span className='footer__prices--block--value'>
                        <Currency value={totalDelivered} />
                    </span>
                </div>
                <div className='footer__prices--block'>
                    <span className='footer__prices--block--title'>{entityGroupByKey?.totalPickedUpPriceInclTax?.fieldName || 'Total picked up price including tax'}</span>
                    <span className='footer__prices--block--value'>
                        <Currency value={pickedUpPriceIncludingTax} />
                    </span>
                </div>
                {/* <div className='footer__prices--separator'></div> */}
                <div className='footer__prices--block'>
                    <span className='footer__prices--block--title'>{entityGroupByKey?.totalDeliveredInclTax?.fieldName || 'Total delivered incl. Tax'}</span>
                    <span className='footer__prices--block--value'>
                        <Currency value={deliveredIncludingTax} />
                    </span>
                </div>
            </div>
        </Footer>
    )
}

export default observer(QuoteLinesFooter)

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
    background-color: #e3eaf9;
    margin-top: 20px;
    .footer__prices--block {
        width: 420px;
        margin-bottom: 5px;
        line-height: 1.6;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        font-family: 'Rubik', sans-serif;
    }
    .footer__prices--block--title {
        color: #6e8ca3;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
    }
    .footer__prices--block--value {
        color: #414141;
        font-size: 22px;
        font-weight: 500;
        display: flex;
        justify-content: flex-end;
    }
`
