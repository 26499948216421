import { Customer } from '@customTypes/customer'
import { useMemo } from 'react'

/**
 * Utility to return true/false if only one selected customer is imported and customer
 *
 * @example
 * const [isCustomerImported, customer] = useIsCustomerImported(customers, selected);
 */

export const useIsCustomerImported = (customers: Customer[], selected: { [id: string]: boolean | number[] }): [boolean, Customer] => {
    const isCustomerImported = useMemo(() => {
        const selectedCustomers = customers?.filter((customer: Customer) => selected[customer._id])
        if (selectedCustomers?.length > 1) return [false, {}] as [boolean, Customer]

        const importedCustomers = selectedCustomers?.filter(customer => customer.imported?.hasOwnProperty('_id'))

        if (importedCustomers?.length === 1) return [true, importedCustomers[0]] as [boolean, Customer]
        else return [false, {}] as [boolean, Customer]
    }, [selected, customers])
    return isCustomerImported
}
