export const FETCH_LIST_TRUCKS = 'FETCH_LIST_TRUCKS';
export const FETCH_LIST_TRUCKS_SUCCESS = 'FETCH_LIST_TRUCKS_SUCCESS';
export const FETCH_LIST_TRUCKS_FAIL = 'FETCH_LIST_TRUCKS_FAIL';

export const ADD_TRUCK = 'ADD_TRUCK';
export const ADD_TRUCK_SUCCESS = 'ADD_TRUCK_SUCCESS';
export const ADD_TRUCK_FAIL = 'ADD_TRUCK_FAIL';

export const EDIT_TRUCK = 'EDIT_TRUCK';
export const EDIT_TRUCK_SUCCESS = 'EDIT_TRUCK_SUCCESS';
export const EDIT_TRUCK_FAIL = 'EDIT_TRUCK_FAIL';

export const DELETE_TRUCK = 'DELETE_TRUCK';
export const DELETE_TRUCK_SUCCESS = 'DELETE_TRUCK_SUCCESS';
export const DELETE_TRUCK_FAIL = 'DELETE_TRUCK_FAIL';

export const CLEAR_ERROR = 'CLEAR_ERROR';