import service from './service';

export const fetchGeoZones = async params => {
	const res = await service.get(`/geo-zones`, params);
	return res.data;
};

export const checkIfNameExists = async (id, name) => {
	try {
		const res = await service.get(`/geo-zones/name-exist/${id}/${name}`);
		return res.data ? 'Name already exist' : true;
	} catch (e) {
		return 'Name already exist';
	}
};

export const fetchGeoZone = async id => {
	const res = await service.get(`/geo-zones/${id}`);
	return res.data;
};

export const updateGeoZone = async geoZone => {
	console.log('geoZone', geoZone);
	const res = await service.put(`/geo-zones/${geoZone._id}`, geoZone);
	return res.data;
};

export const createGeoZone = async geoZone => {
	console.log('created geozone', geoZone);
	const res = await service.post('/geo-zones', geoZone);
	return res.data;
};

export const deleteGeoZone = async id => {
	await service.delete(`/geo-zones/${id}`);
};

export const getAllByPoint = async (point) => {
	const res = await service.get(`/geo-zones/point/${point.lng}/${point.lat}`);
	return res.data;
};
