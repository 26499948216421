import React from 'react'
import styled from 'styled-components'

type StyledTytleProps = {
    mt0?: boolean
    mb0?: boolean
    noUppercase?: boolean
}

const Title = styled.h1<StyledTytleProps>`
    color: #0e3f66;
    font-size: 15px;
    font-weight: 500;
    border-bottom: 1px solid #e0e7ff;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: ${({ noUppercase }) => (noUppercase ? '' : 'uppercase')};
    margin-top: 20px;
    margin-bottom: 20px;
`

export default Title