export const FETCH_TEMPLATES = 'FETCH_TEMPLATES';
export const FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS';
export const FETCH_TEMPLATES_FAIL = 'FETCH_TEMPLATES_FAIL';

export const CREATE_TEMPLATES = 'CREATE_TEMPLATES';
export const CREATE_TEMPLATES_SUCCESS = 'CREATE_TEMPLATES_SUCCESS';
export const CREATE_TEMPLATES_FAIL = 'CREATE_TEMPLATES_FAIL';

export const DELETE_TEMPLATES = 'DELETE_TEMPLATES';
export const DELETE_TEMPLATES_SUCCESS = 'DELETE_TEMPLATES_SUCCESS';
export const DELETE_TEMPLATES_FAIL = 'DELETE_TEMPLATES_FAIL';

export const UPDATE_TEMPLATES = 'UPDATE_TEMPLATES';
export const UPDATE_TEMPLATES_SUCCESS = 'UPDATE_TEMPLATES_SUCCESS';
export const UPDATE_TEMPLATES_FAIL = 'UPDATE_TEMPLATES_FAIL';

export const SET_PREVIEW_TEXT = 'SET_PREVIEW_TEXT';
