import React, { useRef, useEffect, MutableRefObject } from 'react'
import styled from 'styled-components'

import { Button } from '@components/UI/Styled/common'

import { WidgetWrapper, CancelButton, ModalContent } from './styled'

interface WidgetProps {
    title?: string
    children: React.ReactNode | React.ReactNode[]
    loading?: boolean
    width?: number

    onSave: React.MouseEventHandler<HTMLButtonElement>
    onClose: React.MouseEventHandler<HTMLButtonElement>
}

const Widget: React.FunctionComponent<WidgetProps> = props => {
    const ref = useRef<HTMLDivElement>(null)

    const { title = '', width = 400, children, loading, onSave, onClose } = props

    const onClickOutside = (e: MouseEvent) => {
        let targetElement = e.target as Node | null // Start with the target as a Node

        // Loop through the clicked element and its parents
        while (targetElement) {
            // If the target is an HTMLElement, check if it has the popover content class
            if (targetElement instanceof HTMLElement && targetElement.classList.contains('nextui-popover-content')) {
                // Click inside the dropdown, ignore it
                return
            }
            targetElement = targetElement.parentNode
        }

        if (ref.current && !ref.current.contains(e.target as Node)) {
            onClose(e as any)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', onClickOutside)
        return () => {
            document.removeEventListener('mousedown', onClickOutside)
        }
    }, [])

    return (
        <WidgetWrapper width={width} ref={ref}>
            <ModalContent>{children}</ModalContent>
            <ModalFooter>
                <CancelButton onClick={onClose}>Cancel</CancelButton>
                <Button onClick={onSave} disabled={loading}>
                    {loading ? 'Loading' : 'Apply'}
                </Button>
            </ModalFooter>
        </WidgetWrapper>
    )
}

export default Widget

const ModalFooter = styled.div`
    background-color: #f9faff;
    padding: 8px 25px;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px -1px 0px #dddbda;
    display: flex;
    justify-content: flex-end;
`
