import styled from 'styled-components'

type InputIconProps = {
    dark?: boolean
}

const InputIcon = styled.div<InputIconProps>`
    position: absolute;
    right: 0px;
    top: 0px;
    width: 40px;
    height: 100%;
    background: ${({dark})=>dark?'#dbedff':'#ffffff'};
    border: 1px solid #e0e7ff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default InputIcon