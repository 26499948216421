import * as types from './types';

const initialState = {
    settings: []
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_LIST_SETTINGS:
            return {
                ...state
            };
        case types.FETCH_LIST_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: action.payload.data
            };
        case types.FETCH_LIST_SETTINGS_FAIL:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
};

export default reducer;
