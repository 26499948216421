import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { Space } from 'antd'
import { Switch } from '@components/bs'
import { useNotify } from '@components';
import { Button, Dropdown, Icon, Label, baseInputStyles, IconWrapper } from '@common'
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { saveFinancialSetting } from '@service/financialsApi';
import { useFinancialSettings } from '@hooks/useFinancialSettings';
import { fetchStages } from 'service/stages'
import { fetchListUsers } from 'service/users'
import { IDropdownItem } from '@common/Dropdown/dropdown';
import * as SETTINGS from 'consts/settings';
import { SelectRecipient } from '../TEC/SelectRecipient';

export const ENABLE_CREDIT_LIMIT_NOTIFICATION_DEFAULT = 'false';
export const CREDIT_LIMIT_NOTIFICATION_QUOTE_STAGE_DEFAULT = '';
export const ENABLE_CREDIT_LIMIT_NOTIFICATION_QUOTE_STAGE_DEFAULT = 'false';
export const ENABLE_CREDIT_LIMIT_VALIDATION_QUOTE_STAGE_DEFAULT = 'false';
export const CREDIT_LIMIT_VALIDATION_QUOTE_STAGE_DEFAULT = '';
export const CREDIT_LIMIT_VALIDATION_USER_DEFAULT = '';

const CreditLimit = () => {
    const enableCreditLimitNotificationSetting = useFinancialSettings(SETTINGS.ENABLE_CREDIT_LIMIT_NOTIFICATION);
    const creditLimitNotificationQuoteStageSetting = useFinancialSettings(SETTINGS.CREDIT_LIMIT_NOTIFICATION_QUOTE_STAGE);
    const enableCreditLimitNotificationQuoteStageSetting = useFinancialSettings(SETTINGS.ENABLE_CREDIT_LIMIT_NOTIFICATION_QUOTE_STAGE);
    const enableCreditLimitValidationQuoteStageSetting = useFinancialSettings(SETTINGS.ENABLE_CREDIT_LIMIT_VALIDATION_QUOTE_STAGE);
    const creditLimitValidationQuoteStageSetting = useFinancialSettings(SETTINGS.CREDIT_LIMIT_VALIDATION_QUOTE_STAGE);
    const creditLimitValidationUsersSetting = useFinancialSettings(SETTINGS.CREDIT_LIMIT_VALIDATION_USER);

    const [enableCreditLimitNotification, setEnableCreditLimitNotification] = useState(JSON.parse(enableCreditLimitNotificationSetting))
    const [creditLimitNotificationQuoteStage, setCreditLimitNotificationQuoteStage] = useState(creditLimitNotificationQuoteStageSetting)
    const [enableCreditLimitNotificationQuoteStage, setEnableCreditLimitNotificationQuoteStage] = useState(JSON.parse(enableCreditLimitNotificationQuoteStageSetting))
    const [enableCreditLimitValidationQuoteStage, setEnableCreditLimitValidationQuoteStage] = useState(JSON.parse(enableCreditLimitValidationQuoteStageSetting))
    const [creditLimitValidationQuoteStage, setCreditLimitValidationQuoteStage] = useState(creditLimitValidationQuoteStageSetting)
    const [creditLimitValidationUsers, setCreditLimitValidationUsers] = useState((creditLimitValidationUsersSetting || '').split(';'))

    const onSelectItem = (selectedItem: any) => {
        return setCreditLimitValidationUsers(selectedItem?.map((s: any) => s.value));
    };

    const { data: quoteStagesData } = useQuery<any, any, IDropdownItem[]>(['quote-stages'], () => fetchStages('quote'), {
        select: data => data.map(({ key, name }: { key: string, name: string }) => ({ label: name, value: key }))
    })
    const { data: users } = useQuery(['users'], () => fetchListUsers(), {
        select: data => data.items
    })

    const usersOptions = useMemo(() => {
        return users?.map((u: any) => ({
            value: u._id,
            label: `${u.firstName} ${u.lastName}`,
            from: 'User',
            email: u.email,
            userRoles: u?.userRoles,
            position: u.position
        }))
    }, [users])

    const { notifyError, notifySuccess } = useNotify();

    const queryClient = useQueryClient()
    const onSaveClick = async () => {
        saveFinancialSetting([
            { key: SETTINGS.ENABLE_CREDIT_LIMIT_NOTIFICATION, value: enableCreditLimitNotification },
            { key: SETTINGS.CREDIT_LIMIT_NOTIFICATION_QUOTE_STAGE, value: creditLimitNotificationQuoteStage },
            { key: SETTINGS.ENABLE_CREDIT_LIMIT_NOTIFICATION_QUOTE_STAGE, value: enableCreditLimitNotificationQuoteStage },
            { key: SETTINGS.ENABLE_CREDIT_LIMIT_VALIDATION_QUOTE_STAGE, value: enableCreditLimitValidationQuoteStage },
            { key: SETTINGS.CREDIT_LIMIT_VALIDATION_QUOTE_STAGE, value: creditLimitValidationQuoteStage },
            { key: SETTINGS.CREDIT_LIMIT_VALIDATION_USER, value: creditLimitValidationUsers.join(';') },
        ])
            .then(() => {
                queryClient.invalidateQueries(['financial-settings']);
                notifySuccess('Values are updated.');
            })
            .catch(err => {
                notifyError(err);
            });
    };

    return (
        <FieldManagementSpace>
            <Space direction='vertical'>
                <Headline>Tax exempt certificate validation</Headline>
                <Switch
                    type='switch'
                    id='domain-sent'
                    label='Feature on/off'
                    className='text-uppercase'
                    checked={enableCreditLimitNotification}
                    onChange={(event: any) => setEnableCreditLimitNotification(event.target.checked)}
                />
                <Subtitle>This feature allows to check that quote balance has exceeded customer's available credit</Subtitle>
            </Space>
            <Space direction='vertical' size='middle'>
                <Space direction='vertical'>
                    <Headline>Transactional email settings</Headline>
                    <Switch
                        type='switch'
                        id='user-sent'
                        label='NOTIFICATION'
                        className='text-uppercase'
                        checked={enableCreditLimitNotificationQuoteStage}
                        onChange={(event: any) => setEnableCreditLimitNotificationQuoteStage(event.target.checked)}
                    />
                    <Subtitle>User responsible for a quote will receive email notification when quote with quote balance that exceeds customer's available credit enters stage selected below</Subtitle>
                    <Label>Quote stage</Label>
                    <Dropdown options={quoteStagesData || []} onChange={setCreditLimitNotificationQuoteStage} value={creditLimitNotificationQuoteStage} />
                </Space>
                <Space direction='vertical' style={{ width: '100%' }}>
                    <Switch
                        type='switch'
                        id='user-sent'
                        label='VALIDATION'
                        className='text-uppercase'
                        checked={enableCreditLimitValidationQuoteStage}
                        onChange={(event: any) => setEnableCreditLimitValidationQuoteStage(event.target.checked)}
                    />
                    <Subtitle>Validator will receive email notification when quote with quote balance that exceeds customer's available credit enters stage selected below</Subtitle>
                    <Space style={{ width: '100%' }} direction='vertical'>
                        <Label>Quote stage</Label>
                        <Dropdown options={quoteStagesData} onChange={setCreditLimitValidationQuoteStage} value={creditLimitValidationQuoteStage} />
                    </Space>
                    <Space style={{ width: '400px' }} direction='vertical'>
                        <Label>Approver</Label>
                        <SelectRecipient
                            multiple
                            options={usersOptions || []}
                            value={creditLimitValidationUsers || []}
                            onChange={onSelectItem}
                            readOnly={false} label={''}
                        />
                    </Space>
                </Space>
            </Space>
            <Space>
                <Button onClick={onSaveClick}>Save</Button>
            </Space>
        </FieldManagementSpace>
    )
}

const Headline = styled.h2`
font-size: 1.6rem;
font-weight: 500;
font-family: 'Rubik', sans-serif !important;
text-transform: uppercase;
color: #0e3f66;
border-bottom: 1px solid rgba(0, 0, 0, 0.1);
margin-bottom: 1rem;
padding-bottom: 1rem;
`

const FieldManagementSpace = styled.div`
background-color: #ffffff;
margin-top: 2rem;
padding: 3rem;
border-radius: 0.5rem;
display: flex;
flex-direction: column;
gap: 50px
`;

const Subtitle = styled.span``

export default CreditLimit;