// export const getUnitByPlantType = plantType => {
//     const unit = localStorage.getItem('unit');

//     if (plantType?.includes('readymix')) {
//         if (unit === 'imperial') {
//             return <span>yd&sup3;</span>;
//         }
//         return <span>m&sup3;</span>;
//     }

//     return <span>t</span>;
// };

// Plant type = aggr. asphalt => T
// Plant type = readymix => YD3, M3

export const uomwTypes = [
    { value: 'm3', label: <span className='unit'>m&sup3;</span> },
    { value: 'yd3', label: <span className='unit'>yd&sup3;</span> },
    { value: 't', label: <span className='unit'>t</span> }
];

// export const getUnitByUnit = unit => {
//     const uom = uomwTypes.find(u => u.value === unit);

//     return uom?.label || 't';
// };

// export const getRouteUnitByMeasure = (unit = null) => {
//     const lsUnit = localStorage.getItem('unit');
//     const val = unit || lsUnit;
//     return val === 'imperial' ? 'mi' : 'km';
// };

// export const getAverageSpeedMeasure = (unit = null) => {
//     const lsUnit = localStorage.getItem('unit');
//     return (unit || lsUnit) === 'imperial' ? 'mph' : 'kph';
// };

export const milesToKm = miles => {
    return Math.ceil(miles * 1.6);
};

export const kmToMiles = km => {
    return Math.ceil(km / 1.6);
};
