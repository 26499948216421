export const FETCH_LIST_COMPETITORS = 'FETCH_LIST_COMPETITORS';
export const FETCH_LIST_COMPETITORS_SUCCESS = 'FETCH_LIST_COMPETITORS_SUCCESS';
export const FETCH_LIST_COMPETITORS_FAIL = 'FETCH_LIST_COMPETITORS_FAIL';

export const DELETE_COMPETITORS = 'DELETE_COMPETITORS';
export const DELETE_COMPETITORS_SUCCESS = 'DELETE_COMPETITORS_SUCCESS';
export const DELETE_COMPETITORS_FAIL = 'DELETE_COMPETITORS_FAIL';

export const ADD_COMPETITOR = 'ADD_COMPETITOR';
export const ADD_COMPETITOR_SUCCESS = 'ADD_COMPETITOR_SUCCESS';
export const ADD_COMPETITOR_FAIL = 'ADD_COMPETITOR_FAIL';

export const CLEAR_ERROR = 'CLEAR_ERROR';
