import * as types from './types';
import service from '@service/service';

const fetchListUserRoles = (params = {}) => async dispatch => {
    dispatch({ type: types.FETCH_LIST_USER_ROLES });
    try {
        const plants = await service.get('/user-roles', { params });
        dispatch({ type: types.FETCH_LIST_USER_ROLES_SUCCESS, payload: plants.data });
    } catch (error) {
        dispatch({ type: types.FETCH_LIST_USER_ROLES_FAIL });
    }
};

export { fetchListUserRoles };
