import { DataPair } from '@components';

import { isEmpty } from 'utils/object';

const TransportShortInfo = props => {
    const { transportInfo = {}, pageAddress, index = 0, plantName = '' } = props;

    if (isEmpty(pageAddress)) {
        return null;
    }

    return (
        <>
            <DataPair titleOne={`Plant ${index + 1} Name`} valueOne={plantName} titleTwo='Transport type' valueTwo={transportInfo.truckName} />
            <DataPair
                titleOne='COST'
                valueOne={transportInfo.costPerTon}
                titleTwo={transportInfo.timeTitle}
                valueTwo={transportInfo.timeValue}
                className={transportInfo.timeClassName}
            />
        </>
    );
};

export default TransportShortInfo;
