import axios from 'axios'

import { Quote } from '@customTypes/quote'

import { UploadSignedUrl } from './DocumentsRepository'

import Repository from './Repository'

class QuotesRepository extends Repository<Quote> {
    constructor() {
        super('quotes')
    }

    getPdfUploadFileUrl = async (id: string | undefined): Promise<UploadSignedUrl> => {
        return (await this.api.get<UploadSignedUrl>(`${this.route}/${id}/signed-upload-url`)).data
    }

    getPdfDownloadFileUrl = async (id: string | undefined): Promise<string> => {
        return (await this.api.get<{ url: string }>(`${this.route}/${id}/signed-download-url`)).data.url
    }

    uploadPdf = async (signedUrl: string, pdfBlob: Blob): Promise<void> => {
        const fileOptions = { type: pdfBlob.type, lastModified: new Date().getTime() };
        const file = new File([pdfBlob], signedUrl, fileOptions);
        await axios.put<void>(signedUrl, file, { headers: { 'Content-Type': 'application/pdf' } })
    }
}

export default new QuotesRepository()
