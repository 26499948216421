import React, { useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useFinancialSettings } from '@hooks/useFinancialSettings'

const ProtectedRoute = ({ component: Component, ...rest }) => {
    useFinancialSettings()
    const history = useHistory();
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const permission = useSelector(state => state.auth.permission);

    useEffect(() => {
        if (permission === false) {
            history.push('/restricted');
            return;
        }
    }, [permission]);

    return (
        <Route {...rest} render={props => (isAuthenticated ? <Component {...props} /> : <Redirect to='/login' />)} />
    );
};

export default ProtectedRoute;
