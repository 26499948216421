import React from 'react'

import '@scss/pages/_masterData.scss'
const MasterDataSingle = (props) => {
    return (
        <div className='MasterDataSingle'>
            {props.children}
        </div>
    )
}

export default MasterDataSingle;