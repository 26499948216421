import React from 'react';

import { BTN_CANCEL, BTN_SAVE } from '@components/GlobalModal/ModalFooter/config';
import { useShowGlobalModal } from '@components/GlobalModal/hooks';

export const useShowGeoZoneModal = (props = {}) => {
    const showGeoZoneModal = useShowGlobalModal({
        className: 'GeoZoneModal',
        title: 'Geo Zone',
        width: 640,
        LazyComponent: React.lazy(() => import('./index')),
        buttons: [BTN_CANCEL, BTN_SAVE],
        ...props
    });

    return (modalProps, componentProps) => {
        return showGeoZoneModal({
            ...{
                ...modalProps,
            },
            componentProps
        });
    };
};
