import * as types from './types';

const initialState = {
    users: [],
    details: { count: 0, filter: '', limit: 0, page: 0, pages: 0 },
    sending: false,
    editting: false,
    editFields: {
        row: -1,
        col: []
    },
    adding: false,
    deleting: false,
    deletingFields: []
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_LIST_USERS:
            return {
                ...state,
                sending: true
            };
        case types.FETCH_LIST_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload.items,
                details: action.payload.details,
                sending: false
            };
        case types.FETCH_LIST_USERS_FAIL:
            return {
                ...state,
                sending: false
            };
        default:
            return state;
    }
};

export default reducer;
