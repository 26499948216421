import styled from "styled-components"
import { baseInputStyles } from ".."
import { DatePicker } from "antd"
import moment from "moment"

export const AntDatePicker = (props: any) => {
    if (props.autoFill) {
        return <div>{props.value ? moment(props?.value).format(props?.format) : '-'}</div>
    } else {
        return <StyledDatePicker {...props} />
    }
}

const StyledDatePicker = styled(DatePicker)`
    ${baseInputStyles}
`