import { DateTime } from 'luxon'
import { DateOperator, FieldFilters } from './filter.types'
import { FilterDescriptor } from '@progress/kendo-data-query'

export const kendoUIDateFilterMapping = {
    eq: (field: string, value: Date) => ({
        [field]: {
            operations: {
                [DateOperator.GREATER_THAN_OR_EQUAL]: DateTime.fromJSDate(value).startOf('day').toJSDate(),
                [DateOperator.LESS_THAN_OR_EQUAL]: DateTime.fromJSDate(value).endOf('day').toJSDate()
            }
        }
    }),
    neq: (field: string, value: Date) => ({
        [field]: {
            operations: {
                ne: {
                    end: DateTime.fromJSDate(value).endOf('day').toJSDate(),
                    begin: DateTime.fromJSDate(value).startOf('day').toJSDate()
                }
            }
        }
    }),
    gte: (field: string, value: Date) => ({
        [field]: {
            operations: {
                [DateOperator.GREATER_THAN_OR_EQUAL]: DateTime.fromJSDate(value).startOf('day').toJSDate()
            }
        }
    }),
    lte: (field: string, value: Date) => ({
        [field]: {
            operations: {
                [DateOperator.LESS_THAN_OR_EQUAL]: DateTime.fromJSDate(value).endOf('day').toJSDate()
            }
        }
    }),
    gt: (field: string, value: Date) => ({
        [field]: {
            operations: {
                [DateOperator.GREATER_THAN]: DateTime.fromJSDate(value).endOf('day').toJSDate()
            }
        }
    }),
    lt: (field: string, value: Date) => ({
        [field]: {
            operations: {
                [DateOperator.LESS_THAN]: DateTime.fromJSDate(value).startOf('day').toJSDate()
            }
        }
    })
}

type KendoReturnType = FieldFilters<{ _id: string }> | {}
type Operator = 'gte' | 'lte' | 'eq' | 'lt' | 'gt'

export const fromKendoUIDateFilterAdapter = ({ field, operator, value }: FilterDescriptor): KendoReturnType => {
    console.log(operator, value)
    const filter = kendoUIDateFilterMapping[operator as Operator]

    if (!filter) {
        return {}
    }

    return filter(field as string, value)
}
