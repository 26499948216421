import React from 'react';

import { Feather } from '@components';

import '@scss/components/_optionsModal.scss';

const ModalHeader = (props) => {
    const {
        title,
        type,

        onClose
    } = props;

    const handleClickOnClose = () => {
        onClose(type)
    };

    return (
        <div className='OptionsModal__title'>
            <h4>{title}</h4>
            <button onClick={handleClickOnClose}>
                <Feather className='hover-pointer' name='X' />
            </button>
        </div>
    );
};

export default ModalHeader;
