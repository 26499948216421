import { OPEN_GLOBAL_MODAL, HIDE_GLOBAL_MODAL } from './types';

const initialState = {
    isOpen: false
};

const globalModal = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case OPEN_GLOBAL_MODAL:
            return {
                isOpen: true,
                ...payload
            };
        case HIDE_GLOBAL_MODAL:
            return {
                isOpen: false
            };
        default:
            return state;
    }
};

export default globalModal;
