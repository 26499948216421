import React from 'react';
import { Space } from 'antd'

import '@scss/components/_breadcrumbs.scss';

const Breadcrumbs = ({ steps = [], current = -1, separator = '>', className = '', setActiveStep = () => { } }) => {
	return (
		<Space className={className} size={9} align='center'>
			{steps.map((step, i) => (
				<React.Fragment key={`${step}-${i}`}>
					<h5
						onClick={() => setActiveStep({ current: i, steps: [...steps.slice(0, i + 1)] })}
						className={`breadcrumb-item breadcrumb-item-${current === i ? 'active' : 'not-active'}`}>
						{step}
					</h5>
					{i < steps.length - 1 ? <h5 className='breadcrumb-item-not-active'>{separator}</h5> : null}
				</React.Fragment>
			))}
		</Space>
	);
};

export default Breadcrumbs;
