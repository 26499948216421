import service, { setAuthToken } from '@service/service';

import { data } from 'consts/permissions';

import { PRICEBEE } from 'views/Pages/Setup/UserSetup/Users/const';

type PermissionAction = {
    action?: string,
    fields?: string[]
};

const permissions = new Proxy(data, {
    get: (target: any, property: string) => {
        for (let k in target) if (new RegExp(k).test(property)) return target[k];

        return null;
    }
});

type AuthUser = {
    email: string,
    password: string
};

class AuthService {
    private static get token() {
        try {
            return localStorage.getItem('token');
        } catch (error) {
            console.error(`get token`, error)
            return null;
        }
    }

    private static set token(rawToken) {
        try {
            if (!rawToken) {
                setAuthToken();
                return;
            }
            setAuthToken(rawToken);
            localStorage.setItem('token', rawToken);
        } catch (error) {
            console.error('set token', error)
        }
    }

    static get user() {
        try {
            const jsonUser = localStorage.getItem('user');
            return typeof jsonUser === 'string' ? JSON.parse(jsonUser) : null;
        } catch (error) {
            console.error(`get user`, error)
            return null;
        }
    }

    private static set user(data) {
        try {
            localStorage.setItem('user', JSON.stringify(data));
        } catch (error) {
            console.error(`set user`, error)
            throw new Error('Bad user');
        }
    }

    static get settings() {
        try {
            const jsonSettings = localStorage.getItem('settings');
            return typeof jsonSettings === 'string' ? JSON.parse(jsonSettings) : null;
        } catch (error) {
            console.error(error)
            return null;
        }
    }

    private static set settings(data) {
        try {
            localStorage.setItem('settings', JSON.stringify(data));
        } catch (error) {
            console.error(error)
            throw new Error('Bad settings');
        }
    }

    static get isAuthenticated() {
        return Boolean(this.user) && Boolean(this.token);
    }

    static get isSuperAdmin(): boolean {
        return this.user?.userType === PRICEBEE
    }

    private static loginUser = async (data: AuthUser) => {
        const res = await service.post('/users/login', data);
        this.token = res.data.token;
        this.user = res.data.user;
        return { token: res.data.token, user: res.data.user };
    }

    static loadUser = async () => {
        const { data } = await service.get('/users/me');
        this.user = data;
        return data;
    }

    static loadSettings = async () => {
        const { data } = await service.get('/financial-settings/');
        this.settings = data;
        return data;
    }

    static async authenticate(data: AuthUser) {
        const resData = await this.loginUser(data);
        const [user, settings] = await Promise.all([this.loadUser(), this.loadSettings()]);
        return {
            token: resData.token,
            user,
            settings
        }
    }

    static hasPerm(field: String, actions: PermissionAction) {
        if (this.user?.email === process.env.SUPER_ADMIN_EMAIL) {
            return true;
        }
        let hasPerm = false;
        if (actions) {
            // Actions permissions checking
            if (actions.action) {
                const key = permissions[actions.action];
                const obj = (this.user?.userRoles || [])[0]?.permissions[key];
                for (let k in obj) {
                    if (obj[k].value === field) {
                        hasPerm = obj[k].allow.includes(actions.action);
                        break;
                    }
                }
            }

            // Fields permissions checking
            if (!!actions.fields?.length) {
                // !!IMPORTANT static only for basicAccess
                const permission = this.user?.userRoles[0]?.permissions.basicAccess.find((o: any) => o.value === field);
                hasPerm = true;

                if (Array.isArray(permission?.fields)) {
                    for (let f in actions.fields) {
                        if (permission.fields?.includes(actions.fields[f])) {
                            hasPerm = false;
                            break;
                        }
                    }
                }
            }
        }

        return hasPerm;
    }

    static logout = () => {
        this.token = null;
        const appVersion = localStorage.getItem('version');
        localStorage.clear();
        appVersion && localStorage.setItem('version', appVersion);
    }
}

export default AuthService;
