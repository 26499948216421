export const FETCH_LIST_QUOTES = 'FETCH_LIST_QUOTES';
export const FETCH_LIST_QUOTES_SUCCESS = 'FETCH_LIST_QUOTES_SUCCESS';
export const FETCH_LIST_QUOTES_FAIL = 'FETCH_LIST_QUOTES_FAIL';

export const ADD_QUOTE = 'ADD_QUOTE';
export const ADD_QUOTE_SUCCESS= 'ADD_QUOTE_SUCCESS';
export const ADD_QUOTE_FAIL = 'ADD_QUOTE_FAIL';

export const EDIT_QUOTE = 'EDIT_QUOTE';
export const EDIT_QUOTE_SUCCESS= 'EDIT_QUOTE_SUCCESS';
export const EDIT_QUOTE_FAIL = 'EDIT_QUOTE_FAIL';

export const DELETE_QUOTE = 'DELETE_QUOTE';
export const DELETE_QUOTE_SUCCESS= 'DELETE_QUOTE_SUCCESS';
export const DELETE_QUOTE_FAIL = 'DELETE_QUOTE_FAIL';

export const INSERT_QUOTE = 'INSERT_QUOTE';

