import React from 'react'
import { DateTime } from 'luxon'

import { useDateFormat } from '@hooks/useDateFormat'
import { LUXON_DATES_FORMAT } from '@hooks/timezone'

type DateProps = {
    value?: string | null | undefined
}

const Date: React.FC<DateProps> = props => {
    const { value } = props

    const { dateFormat }: { dateFormat: string } = useDateFormat() as any
    const luxonDateFormat = LUXON_DATES_FORMAT[dateFormat as keyof typeof LUXON_DATES_FORMAT]

    if (!value) {
        return <span>–</span>
    }

    return <span>{DateTime.fromISO(value).toFormat(luxonDateFormat)}</span>
}

export default Date
