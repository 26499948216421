import service from '@service/service';

import * as types from './types';
const fetchTECNotifications =
    (params = {}) =>
    async dispatch => {
        dispatch({ type: types.GET_TEC_NOTIFICATIONS });
        try {
            const tecNoticications = await service.get('/notification-settings', { params });
            dispatch({ type: types.GET_TEC_NOTIFICATIONS_SUCCESS, payload: tecNoticications.data });
        } catch (error) {
            dispatch({ type: types.GET_TEC_NOTIFICATIONS_FAIL, payload: error?.response?.data });
        }
    };

const savedTECNotifications = payload => async dispatch => {
    dispatch({ type: types.SAVE_TEC_NOTIFICATIONS });
    try {
        const tecNoticications = await service.post('/notification-settings', payload);
        dispatch({ type: types.SAVE_TEC_NOTIFICATIONS_SUCCESS, payload: tecNoticications.data });
    } catch (error) {
        dispatch({ type: types.SAVE_TEC_NOTIFICATIONS_FAIL });
    } finally {
        setTimeout(() => {
            dispatch({ type: types.RESET_TEC_NOTIFICATIONS });
        }, 1000);
    }
};

export { fetchTECNotifications, savedTECNotifications };
