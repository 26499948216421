import styled from 'styled-components'
import { AgGridReact } from 'ag-grid-react';

const StyledAgGrid = styled(AgGridReact)`
    .agg-table-loading {
        width: 100%;
        height: 10px;
        background-image: linear-gradient(to right, transparent 50%, rgba(0, 0, 0, 0.05) 50%);
        background-size: 200% 100%;
        animation: loading 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
    @keyframes loading {
        0% {
            background-position: 0;
        }
        50% {
            background-position: -30%;
        }
        80% {
            background-position: -100%;
        }
        100% {
            background-position: -200%;
        }
    }
`

export default StyledAgGrid
