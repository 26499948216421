import React, { useEffect, useRef, useMemo, useState } from 'react';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { useDateFormat } from '@hooks/useDateFormat';
import { useDebounceHandler } from '@hooks/useDebounceHandler';

import { fetchTECNotifications } from '@reducers/data/notificationSettings/actions';

import DocumentsRepository from 'service/DocumentsRepository';
import { fetchFinancialSettings } from 'service/financialsApi';
import { updateQuote } from 'service/quotesApi';
import QuotesRepository from 'service/QuotesRepository';
import ProjectsRepository from 'service/ProjectsRepository';
import CustomersRepository from 'service/CustomersRepository';

import AuthService from 'service/auth.service'

import { Alert, Button, Dropdown } from '../bs';
// import Button from '../Button';
import FormRow from '../FormRow';
import Icon from '../Icon';
import Label from '../Label';
import LabeledTextField from '../LabeledTextField';
import useNotify from '../Notification/useNotify';
import Spinner from '../Spinners';

import { formatBytes } from 'utils/size';

import PurchaseOrder from 'views/Pages/Sales/Quotes/QuoteDetails/General/PurchaseOrder';

import styles from './DocumentManager.module.scss';

const _defaultDocumentData = { notes: [] };

const DEFAULT_FETCH_PARAMS = {
    filterColumns: ['status'],
    filterValues: ['active'],
    limit: 100,
    page: 1
}

const serializeFilter = (filter) => ({
    ...filter,
    filterColumns: filter.filterColumns.join(','),
    filterValues: filter.filterValues.join(',')
});

const DocumentManager = props => {
    const {
        currentDocumentManager = null,
        entity = '',

        onQuoteUpdate,

        updateQuoteFromList = () => { },
        close = () => { },
        onSave = () => { }
    } = props;

    const { tecNotifications } = useSelector(state => state.data.notificationSettings);
    const user = AuthService.user
    const { dateFormat } = useDateFormat();

    const { notifySuccess, notifyError } = useNotify();
    const inputFileRef = useRef();

    const dispatch = useDispatch();

    const [documentData, setDocumentData] = useState(_defaultDocumentData);
    const [note, setNote] = useState('');
    const [file, setFile] = useState();
    const [documentTypeOneItems, setDocumentTypeOneItems] = useState([]);
    const [documentTypeTwoItems, setDocumentTypeTwoItems] = useState([]);
    const [activeQuote, setActiveQuote] = useState();
    const [newPurchaseOrder, setNewPurchaseOrder] = useState('');
    const [loading, setLoading] = useState(false);
    const [customersSrchQry, setCustomersSrchQry] = useState('');
    const [quotesSrchQry, setQuotesSrchQry] = useState('');
    const [projectsSrchQry, setProjectsSrchQry] = useState('');

    const { data: project } = useQuery(
        ['projects', documentData.quote?._id],
        () => {
            const projectId = documentData.quote?.value?.project?._id || documentData.quote?.value?.project
            return ProjectsRepository.getById(projectId)
        },
        {
            enabled: !!documentData.quote?.value?.project
        }
    );

    const { data: customers = props.customers } = useQuery(
        ['customers', customersSrchQry, currentDocumentManager.project?._id],
        () => {
            const serializedFilter = serializeFilter({
                ...DEFAULT_FETCH_PARAMS,
                query: customersSrchQry,
                filterColumns: [...DEFAULT_FETCH_PARAMS.filterColumns, 'projects'],
                filterValues: [...DEFAULT_FETCH_PARAMS.filterValues, currentDocumentManager.project?._id]
            });
            return CustomersRepository.getSimplifiedList(serializedFilter);
        },
        {
            enabled: !!currentDocumentManager.project?._id && entity !== 'project',
            select: data => data?.items
        }
    );
    const { data: quotes = props.quotes } = useQuery(
        ['quotes', quotesSrchQry, currentDocumentManager.customer?._id],
        () => {
            const serializedFilter = serializeFilter({
                ...DEFAULT_FETCH_PARAMS,
                filterColumns: [...DEFAULT_FETCH_PARAMS.filterColumns, 'customers', 'query'],
                filterValues: [...DEFAULT_FETCH_PARAMS.filterValues, currentDocumentManager.customer?._id, quotesSrchQry]
            });
            return QuotesRepository.getSimplifiedList(serializedFilter);
        },
        {
            enabled: !!currentDocumentManager.customer?._id && !props?.quotes?.length,
            select: data => data?.items
        }
    );
    const { data: projects = props.projects } = useQuery(
        ['projects', projectsSrchQry, currentDocumentManager.customer?._id],
        () => {
            const serializedFilter = serializeFilter({
                ...DEFAULT_FETCH_PARAMS,
                query: projectsSrchQry,
                filterColumns: [...DEFAULT_FETCH_PARAMS.filterColumns, 'customers'],
                filterValues: [...DEFAULT_FETCH_PARAMS.filterValues, currentDocumentManager.customer?._id]
            });
            return ProjectsRepository.getSimplifiedList(serializedFilter);
        },
        {
            enabled: !!currentDocumentManager.customer?._id && !props?.projects?.length,
            select: data => data?.items
        }
    );

    const onCustomerSearch = useDebounceHandler(setCustomersSrchQry, 1000);
    const onQuotesSearch = useDebounceHandler(setQuotesSrchQry, 1000);
    const onProjectsSearch = useDebounceHandler(setProjectsSrchQry, 1000);

    const isNotValid = useMemo(() => !documentData.name || !documentData.docTypeOne || !file, [documentData?.name, documentData?.docTypeOne, file]);

    const fetchSettings = async () => {
        const docTypeOne = await fetchFinancialSettings(['field-mgmt-documents-type1']);
        const docTypeTwo = await fetchFinancialSettings(['field-mgmt-documents-type2']);

        docTypeOne.forEach(val => {
            setDocumentTypeOneItems(val.value.split('\n').map(val => val.split(', ')[0]));
        });
        docTypeTwo.forEach(val => {
            setDocumentTypeTwoItems(val.value.split('\n').map(val => val.split(', ')[0]));
        });
    };

    const onFieldChange = (key, val) => {
        if (key === 'purchaseOrder') {
            setNewPurchaseOrder(val);
        }

        return setDocumentData({
            ...documentData,
            [key]: val
        });
    };

    const handleFileChange = e => {
        if (!e.target.files) {
            return;
        }

        const file = e.target.files[0];
        if (file.size > 10000000) {
            alert('File is too big!');
            return;
        }

        const manager = currentDocumentManager ? Object.values(currentDocumentManager)[0] : {};

        const documentName = documentData?.name || `[${file.type}],[${manager.name}],[${moment().format('MM-YYYY')}]`;

        setDocumentData({
            ...documentData,
            name: documentName
        });

        setFile(file);
    };

    const createDocument = async () => {
        try {
            setLoading(true);
            const defaultNotes = documentData.notes || [];

            const signedData = await DocumentsRepository.getSignedUrl();
            await DocumentsRepository.upload(signedData.url, file);

            let formData = {
                originalName: signedData.name
            };

            Object.keys(documentData).forEach(docKey => {
                if (docKey === 'customer') {
                    return formData.customer = documentData.customer._id;
                }
                if (docKey === 'project') {
                    return formData.project = documentData.project._id;
                }
                if (docKey === 'quote') {
                    return formData.quote = documentData.quote._id;
                }

                formData[docKey] = documentData[docKey];
            });

            if (note) {
                formData.notes = [
                    ...defaultNotes,
                    {
                        text: note,
                        createdBy: user?._id,
                        readBy: [user?._id]
                    }
                ];
            }
            if (entity == 'project') {
                formData.project = currentDocumentManager.project._id
            }
            const savedDocument = await DocumentsRepository.register(formData);

            // Update Quote PurchaseOrder if it's not set yet
            if (documentData?.quote?._id) {
                let updatedQuoteData = {
                    _id: documentData.quote._id,
                    quoteNo: documentData?.quote?.value?.quoteNo
                };
                if (quotes[0] && newPurchaseOrder !== '') {
                    updatedQuoteData.purchaseOrder = documentData.purchaseOrder;
                }

                if (documentData.docTypeOne === 'Tax Exempt Certificate') {
                    if (!documentData.quote?.taxable && tecNotifications.validation) {
                        updatedQuoteData.taxExemptCertificate = savedDocument._id;
                    }
                }

                const newQuote = await updateQuote(updatedQuoteData);
                updateQuoteFromList(updatedQuoteData);

                if (onQuoteUpdate) {
                    onQuoteUpdate(newQuote);
                }
            }

            notifySuccess('Document Successfully Saved!');
            resetForm();
            setLoading(false);
            onSave(savedDocument._id);
            close();
        } catch (error) {
            console.warn('createDocument: ', error);
            notifyError('Document has not been Saved!');
            setLoading(false);
        }
    };

    const removeFile = () => {
        setFile(null);
        if (inputFileRef?.current?.value) {
            inputFileRef.current.value = null;
        }
    };

    const resetForm = () => {
        setDocumentData(_defaultDocumentData);
        setNote('');
        setNewPurchaseOrder('');
        removeFile();
        close();
    };

    const onQuoteChange = quoteData => {
        const updatedDocData = {
            quote: {
                _id: quoteData._id,
                label: `${quoteData?.name} ${quoteData?.quoteNo}`,
                value: quoteData
            }
        };

        if (quoteData.purchaseOrder) {
            updatedDocData.purchaseOrder = quoteData.purchaseOrder;
        } else {
            setActiveQuote({
                _id: quoteData._id
            });
            updatedDocData.purchaseOrder = '';
            setNewPurchaseOrder('');
        }

        setDocumentData({
            ...documentData,
            ...updatedDocData
        });
    };

    useEffect(() => {
        fetchSettings();
        dispatch(fetchTECNotifications());
        // setParentComponent(document.querySelector('.MasterDataGrid__Main'));
        return () => {
            resetForm();
        };
    }, []);

    useEffect(() => {
        if (typeof project === 'undefined') return;
        const projectData =
            project === null
                ? null
                : {
                    _id: project._id,
                    label: `${project.name} ${project.projectNo}`,
                    value: project
                };
        setDocumentData(document => ({
            ...document,
            project: projectData
        }));
    }, [project]);

    useEffect(() => {
        const _docData = Object.assign({}, documentData);

        if (quotes?.length === 1 && onQuoteUpdate) {
            _docData.quote = {
                _id: quotes[0]._id,
                label: `${quotes[0]?.name} ${quotes[0]?.quoteNo}`,
                value: quotes[0]
            };
            if (quotes[0].purchaseOrder) {
                _docData.purchaseOrder = quotes[0].purchaseOrder;
            }
        }


        if (customers?.length === 1) {
            _docData.customer = {
                _id: customers[0]._id,
                label: `${customers[0]?.name} ${customers[0]?.customerNo}`,
                value: customers[0]
            };
        }

        setDocumentData(_docData);
    }, [quotes, customers, projects]);

    return (
        <div>
            {loading && <Spinner />}
            <FormRow>
                {entity !== 'project' && !documentData?.customer && (
                    <Alert variant='warning' className='mb-20'>
                        <b>Customer</b> does not exist for this <b>Quote</b>. In order to add Document, please add <b>Customer</b> to the <b>Quote</b>.
                    </Alert>
                )}
                <div className='row'>
                    <div className='col-md-8'>
                        <LabeledTextField
                            id='name'
                            label='document name'
                            placeholder='Add document name'
                            value={documentData?.name || ''}
                            onChange={e => onFieldChange('name', e.target.value)}
                        />
                        <div className='FormGroup'>
                            <Label label='Document type 1' />
                            <Dropdown
                                placeholder='Add document type'
                                selected={documentData?.docTypeOne ? { _id: documentData?.docTypeOne, label: documentData?.docTypeOne, value: documentData?.docTypeOne } : null}
                                items={documentTypeOneItems.map(x => ({ _id: x, label: x, value: x }))}
                                onChange={value => onFieldChange('docTypeOne', value)}
                            />
                        </div>
                        <div className='FormGroup'>
                            <Label label='Document type 2' />
                            <Dropdown
                                placeholder='Add document type'
                                selected={documentData?.docTypeTwo ? { _id: documentData?.docTypeTwo, label: documentData?.docTypeTwo, value: documentData?.docTypeTwo } : null}
                                items={documentTypeTwoItems.map(x => ({ _id: x, label: x, value: x }))}
                                onChange={value => onFieldChange('docTypeTwo', value)}
                            />
                        </div>
                        <div className='FormGroup'>
                            <Label label='Customer name & number' />
                            <Dropdown
                                placeholder='Choose customer'
                                selected={documentData?.customer || null}
                                // Customers are not selectable atm
                                disabled={customers?.length === 1 || true}
                                items={customers?.map(c => ({ _id: c?._id, label: `${c?.name} ${c?.customerNo}`, value: c }))}
                                onChange={customer =>
                                    onFieldChange('customer', {
                                        _id: customer._id,
                                        label: `${customer?.name} ${customer?.customerNo}`,
                                        value: customer
                                    })
                                }
                                onSearch={onCustomerSearch}
                            />
                        </div>
                        <div className='FormGroup'>
                            <Label label='Quote name & number' />
                            <Dropdown
                                placeholder='Choose quote'
                                selected={documentData?.quote || null}
                                disabled={!quotes?.length || (quotes?.length <= 1 && onQuoteUpdate)}
                                items={quotes?.map(q => ({ _id: q?._id, label: `${q?.name} ${q?.quoteNo}`, value: q }))}
                                onChange={onQuoteChange}
                                onSearch={onQuotesSearch}
                            />
                        </div>
                        <PurchaseOrder
                            id='purchaseOrder'
                            label='po number'
                            placeholder='Add document po number'
                            value={documentData?.purchaseOrder || ''}
                            onChange={value => onFieldChange('purchaseOrder', value)}
                        />
                        <div className='FormGroup'>
                            <Label label='Project name & number' />
                            <Dropdown
                                placeholder='Choose project'
                                selected={documentData?.project || null}
                                disabled={true}
                                items={projects?.map(p => ({ _id: p?._id, label: `${p?.name} ${p?.projectNo}`, value: p }))}
                                onChange={project =>
                                    onFieldChange('project', {
                                        _id: project._id,
                                        label: `${project?.name} ${project?.projectNo}`,
                                        value: project
                                    })
                                }
                                onSearch={onProjectsSearch}
                            />
                        </div>
                        <LabeledTextField
                            id='note'
                            label='notes'
                            placeholder='Add document note'
                            value={note || ''}
                            onChange={e => setNote(e.target.value)}
                            maxLength='250'
                        // disabled={!!document}
                        />

                        {documentData?.created && (
                            <div className='FormGroup'>
                                <Label label='Date Created' />
                                <div>{moment(documentData?.created).format(dateFormat)}</div>
                            </div>
                        )}

                        {/* Attachments Logic */}
                        <h5 className='sidepanel--title'>ATTACHMENTS</h5>

                        {file && (
                            <Row className='justify-content-between align-items-center'>
                                <Col xs={10}>
                                    <p className='nm'>
                                        <b>{file.name}</b> <span>({formatBytes(file.size)})</span>
                                    </p>
                                    <p>
                                        <span>{moment().format('MMM DD, YYYY HH:mm A')}</span>
                                    </p>
                                </Col>
                                <Col xs={2} className='text-center cursor-pointer' onClick={removeFile}>
                                    <Icon name='documentRemove' />
                                </Col>
                            </Row>
                        )}

                        <Row className='justify-content-between align-items-center'>
                            <Col xs={10}>
                                <div className={styles.uploadBlock}>
                                    <Button variant='outline-primary' className='d-block w-100' onClick={() => inputFileRef.current.click()}>
                                        <Icon name='upload' />
                                        Upload New File
                                    </Button>
                                    <div className={styles.uploadBlock_helper}>The maximum file size limit for uploads – 10 MB.</div>
                                    <input type='file' ref={inputFileRef} onChange={handleFileChange} style={{ display: 'none' }} accept='application/pdf' />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </FormRow>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingRight: '30px',
                    backgroundColor: '#f9faff',
                    padding: '20px 20px 20px 20px',
                    borderTop: '1px solid #e0e7ff'
                }}>
                <Button variant='link' onClick={resetForm}>
                    <span>Cancel</span>
                </Button>
                <Button onClick={createDocument} disabled={isNotValid || (!documentData?.customer && entity != 'project')}>
                    <span>Save</span>
                </Button>
            </div>
        </div>
    );
};

export default DocumentManager;
