import React from 'react';

import RSButtonGroup from 'react-bootstrap/ButtonGroup';
import RSToggleButton from 'react-bootstrap/ToggleButton';

import 'scss/components/_toggle-button.scss';

const ToggleButton = (props) => {
    const {
        children,
        className = '',
        ...restProps
    } = props;

    return (
        <RSButtonGroup className={`toggle-button-group ${className}`}>
            <RSToggleButton
                className={`toggle-button`}
                {...restProps}
            >
                {children}
            </RSToggleButton>
        </RSButtonGroup>
    );
}

export default ToggleButton;
