import * as types from './types';

const initialState = {
    competitors: [],
    details: { count: 0, filter: '', limit: 0, page: 0, pages: 0 },
    sending: false,
    adding: false,
    deleting: false,
    deletingFields: [],
    error: ''
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DELETE_COMPETITORS:
            return {
                ...state,
                deleting: true,
                deletingFields: action.payload
            };
        case types.DELETE_COMPETITORS_FAIL:
            return {
                ...state,
                deleting: false
            };
        case types.DELETE_COMPETITORS_SUCCESS:
            const temp = [...state.competitors];
            action.payload.map(v => {
                const i = temp.findIndex(t => t._id === v)
                temp.splice(i, 1)
            })
            return {
                ...state,
                competitors: temp,
                deleting: false
            };
        case types.FETCH_LIST_COMPETITORS:
            return {
                ...state,
                sending: true
            };
        case types.FETCH_LIST_COMPETITORS_SUCCESS:
            return {
                ...state,
                competitors: action.payload.items,
                details: action.payload.details,
                sending: false
            };
        case types.FETCH_LIST_COMPETITORS_FAIL:
            return {
                ...state,
                sending: false,
                error: action.payload
            };
        case types.CLEAR_ERROR:
            return {
              ...state,
              error: ''
            };
        default:
            return state;
    }
};

export default reducer;
