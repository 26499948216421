import * as types from './types';

const initialState = {
    customers: [],
    details: { count: 0, filter: '', limit: 0, page: 0, pages: 0 },
    sending: false,
    adding : false,
    deleting : false,
    deletingFields : [],
    error: ''
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DELETE_CUSTOMERS:
            return {
                ...state,
                deleting: true,
                deletingFields : action.payload
            };
        case types.DELETE_CUSTOMERS_FAIL:
            return {
                ...state,
                deleting: false
            };
        case types.DELETE_CUSTOMERS_SUCCESS:
            const temp =[...state.customers];
            action.payload.map(v =>{
                const i = temp.findIndex(t => t._id === v)
                temp.splice(i, 1)
            })
            return {
                ...state,
                customers : temp,
                deleting: false
            };
        case types.LINK_CONTACT:
            return {
                ...state,
                sending: true
            };
        case types.LINK_CONTACT_SUCCESS:
            return {
                ...state,
                sending: false
            };
        case types.LINK_CONTACT_FAIL:
            return {
                ...state,
                sending: false
            };
        case types.FETCH_LIST_CUSTOMERS:
            return {
                ...state,
                sending: true
            };
        case types.FETCH_LIST_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customers: action.payload.items,
                details: action.payload.details,
                sending: false
            };
        case types.FETCH_LIST_CUSTOMERS_FAIL:
            return {
                ...state,
                sending: false,
                error: action.payload
            };
        case types.CLEAR_ERROR:
            return {
              ...state,
              error: ''
            }
        default:
            return state;
    }
};

export default reducer;
