import React from 'react';
import { Feather } from '@components';
import '@scss/pages/_setup.scss';

const Setup = ({ title = '', children }) => {
    return (
        <div className='Setup'>
            <div className='Setup__Header'>
                <h1>{title}</h1>
                <Feather name='Info' />
            </div>
            <div className='Setup__Content'>{children}</div>
        </div>
    );
};

export default Setup;
