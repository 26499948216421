import { useCallback } from 'react';
import lodashDebounce from 'lodash/debounce';

export const useDebounceHandler = (handler, delay = 1000, externalValues = []) => {
    const debouncedHandler = useCallback(
		lodashDebounce((...params) => handler(...params), delay),
		externalValues
	);

    return debouncedHandler;
}
