import { useEffect, useState } from 'react';

import { MasterDataSingle } from '@containers/MasterData';

import { FormRow, Table } from '@components';
import { useLocalTable } from '@hooks/useLocalTable';
import { useSelector } from 'react-redux';
import { fetchDocuments } from 'service/documentsApi';
import tableColumns from './tableColumns';
import { useDateFormat } from '@hooks/useDateFormat';

const ProjectDocuments = ({ shouldRefetch, refetch, project, readOnly, openNotes = () => { }, controlsRender = null }) => {
    const [data, setData] = useState([]);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const { user } = useSelector(store => store.auth)

    const { dateFormat } = useDateFormat();

    const localTable = useLocalTable({ data, tableColumns, filterData: { dateFormat } });

    const getDocuments = async query => {
        if (!project?._id) return;
        setIsDataLoading(true);
        const filterColumns = ['status', 'projects']
        const filterValues = ['active', project._id]

        if (query || searchQuery) {
            filterColumns.push('query');
            filterValues.push(query || searchQuery);
        }

        if (project?.quotes?.length) {
            filterColumns.push('quotes');
            filterValues.push(project.quotes.join(';'));
        }

        const params = {
            filterColumns: filterColumns.join(','),
            filterValues: filterValues.join(',')
        }

        const resData = await fetchDocuments(params);

        setData(resData?.items);
        setIsDataLoading(false);
        refetch(false);
    };

    useEffect(() => {
        getDocuments();
    }, [project]);

    useEffect(() => {
        if (shouldRefetch) {
            getDocuments();
        }
    }, [shouldRefetch]);

    const handleSearchQuery = async searchText => {
        setSearchQuery(searchText);
        getDocuments(searchText);
    };

    return (
        <>
            <MasterDataSingle>
                <FormRow title='Project Documents'>
                    <Table
                        columns={tableColumns}
                        dataProps={{
                            dateFormat,
                            readOnly,
                            openNotes,
                            user
                        }}
                        tableKey={'table:project-details-documents'}
                        parentComponent={document.getElementsByClassName('react-tabs__tab-panel--selected')?.[0]}
                        paddingRowNo={2}
                        data={localTable.tableData}
                        sort={localTable.sort}
                        limit={localTable.limit}
                        onChangeLimit={localTable.setLimit}
                        onFilter={localTable.setFilter}
                        onSort={localTable.setSort}
                        onChangePage={localTable.onChangePageHandler}
                        details={localTable.details}
                        reorder
                        // readOnly
                        visibleColumn
                        isSelectView
                        overrideMinWidthRule
                        showWidthInfo
                        pagination
                        searchAsFilter
                        action
                        resizing
                        onQuery={handleSearchQuery}
                        loadingData={isDataLoading}
                        controlsRender={controlsRender
                        }
                    />
                </FormRow>
            </MasterDataSingle>
        </>
    );
};

export default ProjectDocuments;
