import React from 'react'
import { Popover, PopoverPlacement } from '@nextui-org/react'
import styled, { css } from 'styled-components'

import { baseInputStyles } from '../Input'

import Show from '../Show'
import { Icon } from '@common'

export type IDropdownItemValue = string | number

export interface IDropdownItem {
    value?: string | number
    label: string | React.ReactNode
    disabled?: boolean
    action?: () => void
}

type DropdownProps = {
    options?: IDropdownItem[]
    icon?: any
    onChange?: any
    value?: any
    placeholder?: any
    renderTrigger?: any
    placement?: PopoverPlacement
    error?: any
    disabled?: any
    renderContent?: any
    inline?: boolean
    contentStyle?: React.CSSProperties
    autoFill?: boolean
}

const Dropdown: React.FC<DropdownProps> = ({
    options = [],
    icon,
    onChange,
    value,
    placeholder,
    renderTrigger,
    placement = 'bottom-left',
    error,
    inline = false,
    disabled,
    renderContent,
    contentStyle = {},
    autoFill
}) => {
    const [isOpen, setIsOpen] = React.useState(false)

    function handleSelect(event: React.MouseEvent<HTMLLIElement, MouseEvent>, option: any) {
        event.stopPropagation()
        if (option.disabled) return
        if (option.action) {
            option.action(option.value)
            setIsOpen(false)
            return
        }

        if (onChange) {
            onChange(option.value)
            setIsOpen(false)
            return
        }
    }

    if (autoFill) {
        return <div>{(options || []).find(o => o.value === value)?.label || '-'}</div>
    } else {
        return (
            <Popover disableAnimation placement={placement} offset={4} shouldFlip isOpen={isOpen} onOpenChange={e => !disabled && setIsOpen(e)} data-cy='dropdown'>
                <Popover.Trigger>
                    {renderTrigger ? (
                        renderTrigger
                    ) : (
                        <DropdownTriger inline={inline} error={error} disabled={disabled} data-cy='dropdown-trigger'>
                            <Show condition={placeholder && !value}>
                                <DropdownPlaceholder>{placeholder}</DropdownPlaceholder>
                            </Show>
                            {inline ? (value ? (options || []).find(o => o.value === value)?.label : '-') : (options || []).find(o => o.value === value)?.label}
                            <IconWrapper inline={inline}>
                                <Show condition={icon}>{icon}</Show>
                                <Show condition={!icon}>
                                    <Icon name='arrow-down' style={{ height: '24px', width: '24px' }} />
                                </Show>
                            </IconWrapper>
                        </DropdownTriger>
                    )}
                </Popover.Trigger>
                <Popover.Content css={{ borderRadius: '5px', maxHeight: 400 }} className='dropdown-content'>
                    <DropdownMenu style={contentStyle}>
                        {renderContent ? (
                            React.cloneElement(renderContent, { setIsOpen: setIsOpen })
                        ) : (
                            <ul>
                                {options?.map((o: any, i: number) => (
                                    <DropdownItem key={i} onClick={e => handleSelect(e, o)} disabled={o.disabled}>
                                        {o.label}
                                    </DropdownItem>
                                ))}
                            </ul>
                        )}
                    </DropdownMenu>
                </Popover.Content>
            </Popover>
        )
    }
}

export const IconWrapper = styled.div<{ inline?: boolean }>`
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px solid #e0e7ff;
    border-radius: 5px;
    width: 40px;

    ${({ inline }) =>
        inline
            ? css`
                  width: 16px;
                  border: none;
                  background: transparent;
                  right: 4px;
              `
            : ''}
`

const DropdownTriger = styled.div<{ inline: boolean; disabled?: boolean; error?: boolean }>`
    ${baseInputStyles}
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 15px;
    cursor: pointer;

    ${({ disabled }) =>
        disabled
            ? css`
                  background: #f7f7f7;
                  border: 1px solid #f4f5f7;
                  color: #81878e;
                  :hover {
                      border: 1px solid #f4f5f7;
                  }
              `
            : ''}

    ${({ inline }) =>
        inline
            ? css`
                  width: 65px;
                  position: absolute;
                  right: 1px;
                  min-height: calc(100% - 2px);
                  border: none;
                  background: rgb(219, 237, 255);
                  /* top: 1px; */
                  border-radius: 0px 5px 5px 0px;
                  font-size: 16px;
                  justify-content: center;
                  padding: 0 6px 0 0;
                  z-index: 1;
                  height: 100%;
                  top: 0;
                  &:hover {
                      border: none;
                  }
                  &:focus,
                  &:focus-within {
                      border: none;
                      box-shadow: none;
                  }
              `
            : ''}
`

const DropdownMenu = styled.div`
    padding: 5px;
    min-width: 200px;
    width: 100%;
`

const DropdownItem = styled.li<{ disabled: boolean }>`
    padding: 8px 10px;
    color: #404040;
    font-size: '15px';
    /* min-width: 110px; */
    width: 100%;
    border-radius: 5px;
    :hover {
        background-color: #dbedff;
        cursor: pointer;
    }

    ${({ disabled }) =>
        disabled
            ? css`
                  color: lightgray;
                  :hover {
                      background: #f4f5f7;
                  }
              `
            : ''}
`

const DropdownPlaceholder = styled.span`
    color: rgba(110, 140, 163, 0.6);
`

export default Dropdown
