import { Document, Image, Page, pdf, Text, View } from '@react-pdf/renderer';
import moment from 'moment';

import { LogoSolterra } from '@assets'

import { currencyByLocalstorage } from 'utils/currencyUtil';

import Entry from '../Pages/Setup/TCSettings/PDFPreview/Entry';

import { DISPLAY_FIELDS } from './columns';

import styles from './solterra-style';

export const QuoteSolterra = props => {
    const {
        data,
        options,
        entityGroupByKey,

        currencyFormat = () => {}
    } = props;

    const displayFields = data.displayFields || DISPLAY_FIELDS;

    const total = () => {
        let total = 0;

        data.quoteEntries.map(x => {
            if (options.delivered) {
                total += (x.price + (data?.transport?.costPerTon || 0)) * x.qty * (x.taxApplied ? (100 + data?.taxes?.rate || 0) / 100 : 1);
            } else {
                total += x.price * x.qty;
            }
        });

        return currencyFormat(total);
    };

    return (
        <Document title='Quote'>
            <Page size='A4' style={{ flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <View>
                    <View style={{ paddingHorizontal: 35 }} fixed>
                        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (totalPages > 1 ? `${pageNumber}.` : '')} fixed />
                        <View style={{ flexDirection: 'row', paddingVertical: '10px', justifyContent: 'space-between' }}>
                            <View style={{ width: '60%' }}>
                                <Image src={LogoSolterra} style={{ width: '170px' }} />
                            </View>
                            <View style={{ marginTop: '20px' }}>
                                <Text style={styles.h3_black}>11001 N 24TH AVE, SUITE 601-603</Text>
                                <Text style={styles.h3_black}>PHOENIX, AZ 85029</Text>
                            </View>
                        </View>
                        <View style={{ paddingTop: '30px', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <View style={{ flexDirection: 'column', width: '60%' }}>
                                <View style={{ marginBottom: '10px' }}>
                                    <Text style={styles.h3_black}>{`Date: ${moment().format(data.dateFormat)}`}</Text>
                                </View>
                                <View>
                                    <Text style={styles.h3_green}>Customer:</Text>
                                    <Text style={styles.h3_black}>{data.customer?.name || ''}</Text>
                                </View>
                                <View style={{ marginTop: '10px' }}>
                                    <Text style={styles.h5_black}>{(data.customer?.addresses?.indexOf(0) && data.customer.addresses[0]?.line) || ''}</Text>
                                    <Text style={styles.h5_black}>{(data.customer?.addresses?.indexOf(0) && data.customer.addresses[0]?.city) || ''}</Text>
                                    <Text style={styles.h5_black}>{(data?.customer?.addresses?.indexOf(0) && data.customer.addresses[0]?.zip) || ''}</Text>
                                    <Text style={styles.h5_black}>{(data?.customer?.addresses?.indexOf(0) && data.customer.addresses[0]?.country) || ''}</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'column', width: '40%' }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <Text style={styles.h3_green}>Quote:</Text>
                                    <Text style={{ ...styles.h3_green, marginLeft: '5px' }}>{data.quoteNo}</Text>
                                </View>
                                {data?.project && (
                                    <View style={{ marginTop: '20px' }}>
                                        <Text style={styles.h3_black}>Project name</Text>
                                        <Text style={styles.h5_black}>{data?.project?.name || ''}</Text>
                                    </View>
                                )}
                                <View style={{ marginTop: '5px' }}>
                                    <Text style={styles.h3_black}>Delivery address</Text>
                                    <Text style={styles.h5_black}>{data.address?.line || ''}</Text>
                                    <Text style={styles.h5_black}>
                                        {data.address?.city || ''} {data.address?.zip || ''}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.separator}></View>
                    </View>
                    <View style={{ paddingHorizontal: 35 }}>
                        <View>
                            <View style={{ flexDirection: 'row' }}>
                                {displayFields.product && <Text style={{ ...styles.table_th, ...styles.productColumn }}>{entityGroupByKey?.product?.fieldName || 'Product'}</Text>}
                                {displayFields.qty && <Text style={{ ...styles.table_th, ...styles.quantityColumn }}>{entityGroupByKey?.qty?.fieldName || 'Quantity'}</Text>}
                                {displayFields.price && options.pickedUp && <Text style={{ ...styles.table_th, ...styles.priceColumn }}>{entityGroupByKey?.price?.fieldName || 'Picked up unit price'}</Text>}
                                {displayFields.deliveredUnitPrice && options.delivered && <Text style={styles.table_th}>{entityGroupByKey?.deliveredUnitPrice?.fieldName || 'Delivered unit price'}</Text>}
                                {displayFields.taxApplied && <Text style={{ ...styles.table_th, ...styles.taxColumn }}>{entityGroupByKey?.taxApplied?.fieldName || 'Tax rate'}</Text>}
                                {displayFields.deliveredUnitPriceInclTax && options.delivered && <Text style={styles.table_th}>{entityGroupByKey?.deliveredUnitPriceInclTax?.fieldName || 'Delivered unit price incl. tax'}</Text>}
                                {displayFields.totalPickedUpPrice && options.pickedUp && <Text style={{ ...styles.table_th, ...styles.priceColumn }}>{entityGroupByKey?.totalPickedUpPrice?.fieldName || 'Total picked up price'}</Text>}
                                {displayFields.totalDelivered && options.delivered && <Text style={styles.table_th}>{entityGroupByKey?.totalDelivered?.fieldName || 'Total delivered'}</Text>}
                                {displayFields.totalDeliveredInclTax && options.delivered && (
                                    <Text style={{ ...styles.table_th, ...styles.priceColumn }}>{entityGroupByKey?.totalDeliveredInclTax?.fieldName || 'Total delivered incl. tax'}</Text>
                                )}
                                {displayFields.totalPickedUpPriceInclTax && options.pickedUp && (
                                    <Text style={{ ...styles.table_th, ...styles.priceColumn }}>{entityGroupByKey?.totalPickedUpPriceInclTax?.fieldName || 'Total picked up price incl. tax'}</Text>
                                )}
                            </View>
                            {data.quoteEntries.map((x, i) => (
                                <View key={`qe-${i}`} style={{ flexDirection: 'row', width: '100%' }}>
                                    {displayFields.product && (
                                        <div style={{ ...styles.table_td, ...styles.productColumn, display: 'flex', flexDirection: 'column' }}>
                                            <Text style={{ ...(i === data.quoteEntries.length - 1 ? { borderBottom: 2 } : {}) }}>{x.product.name}</Text>
                                            {displayFields.description && (
                                                <Text style={{ ...styles.productDescription, ...(i === data.quoteEntries.length - 1 ? { borderBottom: 2 } : {}) }}>
                                                    {x.product?.description}
                                                </Text>
                                            )}
                                        </div>
                                    )}
                                    {displayFields.qty && (
                                        <Text style={{ ...styles.table_td, ...(i === data.quoteEntries.length - 1 ? { borderBottom: 2 } : {}) }}>
                                            {x.qty + (x?.uomValue || x?.uom?.value || x?.product?.uom?.value || '')}
                                        </Text>
                                    )}
                                    {displayFields.price && options.pickedUp && (
                                        <Text style={{ ...styles.table_td, ...styles.quantityColumn, ...(i === data.quoteEntries.length - 1 ? { borderBottom: 2 } : {}) }}>
                                            {currencyByLocalstorage(x.price)}
                                        </Text>
                                    )}
                                    {displayFields.deliveredUnitPrice && options.delivered && (
                                        <Text style={{ ...styles.table_td, ...styles.priceColumn, ...(i === data.quoteEntries.length - 1 ? { borderBottom: 2 } : {}) }}>
                                            {currencyByLocalstorage(x.price + (data.transport?.costPerTon || 0))}
                                        </Text>
                                    )}
                                    {displayFields.taxApplied && (
                                        <Text style={{ ...styles.table_td, ...styles.taxColumn, ...(i === data.quoteEntries.length - 1 ? { borderBottom: 2 } : {}) }}>
                                            {x.taxApplied ? `${data.taxes?.rate}%` || 0 : '-'}
                                        </Text>
                                    )}
                                    {displayFields.deliveredUnitPriceInclTax && options.delivered && (
                                        <Text style={{ ...styles.table_td, ...styles.priceColumn, ...(i === data.quoteEntries.length - 1 ? { borderBottom: 2 } : {}) }}>
                                            {currencyByLocalstorage((x.price + data.transport?.costPerTon || 0) * (x.taxApplied ? (100 + data.taxes?.rate || 0) / 100 : 1))}
                                        </Text>
                                    )}
                                    {displayFields.totalPickedUpPrice && options.pickedUp && (
                                        <Text
                                            style={{
                                                ...styles.table_td,
                                                ...styles.priceColumn,
                                                ...(i === data.quoteEntries.length - 1 ? { borderBottom: 2 } : {})
                                            }}>
                                            {currencyByLocalstorage(x.price * x.qty)}
                                        </Text>
                                    )}
                                    {displayFields.totalDelivered && options.delivered && (
                                        <Text style={{ ...styles.table_td, ...styles.priceColumn, ...(i === data.quoteEntries.length - 1 ? { borderBottom: 2 } : {}) }}>
                                            {currencyByLocalstorage(x.qty * (x.price + (data.transport?.costPerTon || 0)))}
                                        </Text>
                                    )}
                                    {displayFields.totalDeliveredInclTax && options.delivered && (
                                        <Text
                                            style={{
                                                ...styles.table_td,
                                                ...styles.priceColumn,
                                                ...(i === data.quoteEntries.length - 1 ? { borderBottom: 2 } : {})
                                            }}>
                                            {currencyByLocalstorage((x.price + data.transport?.costPerTon || 0) * x.qty * (x.taxApplied ? (100 + data.taxes?.rate || 0) / 100 : 1))}
                                        </Text>
                                    )}
                                    {displayFields.totalPickedUpPriceInclTax && options.pickedUp && (
                                        <Text style={{ ...styles.table_td, ...styles.priceColumn, ...(i === data.quoteEntries.length - 1 ? { borderBottom: 2 } : {}) }}>
                                            {currencyByLocalstorage((x.price + 0) * x.qty * (x.taxApplied ? (100 + data.taxes?.rate || 0) / 100 : 1))}
                                        </Text>
                                    )}
                                </View>
                            ))}
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginRight: 20, marginTop: 10 }}>
                            <Text style={styles.total_green}>Total:</Text>
                            <Text style={{ ...styles.total_black, marginLeft: 7 }}>{total()}</Text>
                        </View>
                        <View style={styles.separator}></View>
                    </View>
                    <View style={{ paddingHorizontal: 35 }} wrap={false}>
                        {data.tc && (
                            <View style={{ marginTop: 20 }}>
                                <Text style={styles.h3_black}>NOTE</Text>
                                <View style={{ marginTop: 5 }}>
                                    <Text style={styles.description}>{data.tc}</Text>
                                </View>
                            </View>
                        )}
                    </View>
                    <View style={{ paddingHorizontal: 35 }} wrap={false}>
                        {options.templateText && (
                            <View style={{ marginTop: '10' }}>
                                <Text style={styles.h3_black}>TERMS & CONDITIONS</Text>
                                <Entry entry={options.templateText} />
                            </View>
                        )}
                    </View>
                </View>
                <View fixed>
                    <View style={{ paddingHorizontal: 35 }}>
                        <View style={{ marginTop: '30px' }}>
                            <Text style={{ ...styles.h3_black, marginTop: '10px' }}>CUSTOMER JOB/PROJECT NUMBER: ___________</Text>
                            <Text style={{ ...styles.h3_black, marginTop: '10px' }}>FAX: </Text>
                            <Text style={{ ...styles.h3_black, marginTop: '10px' }}>EMAIL: </Text>
                            <Text style={{ ...styles.h3_black, marginTop: '10px' }}>JOB AWARDED (Signature): ______________________</Text>
                        </View>
                    </View>
                    <View
                        style={{
                            width: '100%',
                            height: '60px',
                            marginTop: 10,
                            backgroundColor: '#77A349',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start'
                        }}>
                        <View style={{ marginTop: '5px', marginLeft: '10px', flexDirection: 'column' }}>
                            <Text style={styles.footer_font}>Sam Grasmick</Text>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={styles.footer_font}>DIRECT: 480 895 3555</Text>
                                <Text style={styles.footer_font}>CELL: 602 320 5638</Text>
                                <Text style={styles.footer_font}>FAX: 480 895 2999</Text>
                                <Text style={styles.footer_font}>EMAIL: SGRASMICK@SOLTERRAMATERIALS.COM</Text>
                            </View>
                        </View>
                        <View style={{ marginBottom: '5px', marginLeft: '10px', flexDirection: 'column' }}>
                            <Text style={styles.footer_font}>Randall England</Text>
                            <View style={{ flexDirection: 'row', justifyContent: 'start', gap: '10px' }}>
                                <Text style={styles.footer_font}>CELL: 480 290 0866</Text>
                                <Text style={styles.footer_font}>EMAIL: RENGLAND@SOLTERRAMATERIALS.COM</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export const QuoteSolterraBlob = async props => {
    return await pdf(QuoteSolterra(props)).toBlob();
};
