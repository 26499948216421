import React, { useEffect, useState } from 'react'

import { useOnClickOutside } from 'utils/onClickOutside'

import { DropdownPanel } from './styled'

import Arrow from '@assets/icons/Arrow.svg'
import { Image, Label, Ripple } from '../'

import '@scss/components/_dropdown.scss'

const ITEM_HEIGHT = 36

const Dropdown = props => {
    const {
        id,
        label = 'UNSET',
        options,
        formErrors = [],
        style = {},
        disabled = false,
        placeholder = 'Select item',
        value = null,
        onRef = React.createRef(),
        parentComponent,
        customClass = '',
        border = true,
        readOnly = false,
        readOnlyOptions = [],
        extraStyle = {},
        customRenderClass = '',
        clearDisable = false,

        onChange = () => {},
        onClickOutSide = () => {}
    } = props

    const [displayMenu, setDisplayMenu] = useState(false)
    const [location, setLocation] = useState({ x: 0, y: 0 })
    const [selectedProps, setSelectedProps] = useState()
    const [maxHeight, setMaxHeight] = useState()
    const [clearVisible, setClearVisible] = useState(false)

    const [changedDomElements, setChangedDomElements] = useState([])

    const [error, setError] = useState(null)
    useEffect(() => {
        setError(formErrors?.find(x => x.id === id)?.message)
    }, [formErrors])

    useEffect(() => {
        if (displayMenu) {
            const tmp = document.getElementsByClassName('overflow-overlay')
            if (tmp && HTMLCollection.prototype.isPrototypeOf(tmp)) {
                let changed = []
                Array.from(tmp).forEach(element => {
                    if (!element.classList.contains('overflow-hidden')) {
                        changed.push(element)
                        element.classList.add('overflow-hidden')
                    }
                })
                setChangedDomElements(changed)
            }
        } else {
            changedDomElements.map(element => {
                element.classList.remove('overflow-hidden')
            })
        }
    }, [displayMenu])

    useEffect(() => {
        if (displayMenu) {
            const coords = onRef.current.getBoundingClientRect()

            let tabPosition = { x: 0, y: 0 }
            if (parentComponent) {
                tabPosition = parentComponent.getBoundingClientRect()
            }

            let x = coords.x - tabPosition.x
            let y = coords.y + coords.height + 5 - tabPosition.y
            const menu_height = Math.min(options.length, 8) * ITEM_HEIGHT
            const diff = coords.y + coords.height + 5 + menu_height - window.innerHeight
            if (diff > 0) {
                y -= diff
            }

            setLocation({ x, y, width: coords.width })
        }
    }, [displayMenu, parentComponent])

    useEffect(() => {
        setSelectedProps(value !== null ? options?.find(opt => opt.value === value) : {})
        setMaxHeight(Math.min(options.length, 8) * ITEM_HEIGHT)
    }, [options])

    useEffect(() => {
        setSelectedProps(value !== null ? options?.find(opt => opt.value === value) : {})
    }, [value])

    if (onRef) {
        useOnClickOutside(onRef, () => {
            if (displayMenu) onClickOutSide()
        })
    }

    return (
        <div
            className={`Dropdown ${disabled ? 'Dropdown--disabled' : ''} ${displayMenu && selectedProps?.value ? 'Dropdown--chosen' : ''} ${customClass}`}
            tabIndex='0'
            style={{ ...style, background: selectedProps?.bg }}
            autoFocus
            onMouseLeave={() => setClearVisible(false)}
            onBlur={() => setDisplayMenu(false)}>
            {label != 'UNSET' && <Label label={label} />}
            <div
                id={id}
                onMouseOver={() => setClearVisible(true)}
                ref={onRef}
                className={`selected ${!readOnly ? 'selected--editable' : ''} ${border ? 'Dropdown--border' : ''} ${error ? 'Dropdown--error' : ''} ${
                    displayMenu ? 'Dropdown--focus' : ''
                } `}
                style={{ color: selectedProps?.color, ...extraStyle }}
                onClick={() => (!disabled && !readOnly ? setDisplayMenu(!displayMenu) : {})}>
                {selectedProps ? (
                    selectedProps.render ? (
                        selectedProps.render
                    ) : selectedProps.label ? (
                        <div className={`text-break ${customRenderClass}`}>{selectedProps?.label}</div>
                    ) : readOnly ? (
                        <div></div>
                    ) : (
                        <div className='placeholder'>{placeholder}</div>
                    )
                ) : readOnly || disabled ? (
                    ' '
                ) : (
                    <div>{placeholder}</div>
                )}
                <div className='icon-wrapper'>
                    <Image src={Arrow} className={`arrow_icon ${displayMenu ? 'arrow_icon--rotate' : ''}`} />
                </div>
            </div>
            <span className='Dropdown Dropdown__error-message'>{error}</span>
            {displayMenu && (
                <DropdownPanel left={location.x} top={location.y} width={location.width} height={maxHeight} className='Dropdown__menu'>
                    {options?.map((opt, index) => {
                        const isDisabledItem = !!readOnlyOptions.find(o => o === opt.value)

                        return (
                            <li
                                className={`ripple__container Dropdown__item ${selectedProps?.value === opt.value ? 'Dropdown__menu--active' : ''} ${
                                    isDisabledItem ? 'Dropdown__menu--disabled' : ''
                                }`}
                                key={`${opt.value}-${index}`}
                                onClick={e => {
                                    setTimeout(() => {
                                        if (!isDisabledItem) {
                                            onChange(opt, { target: { name: id, value: opt.value } })
                                            setDisplayMenu(false)
                                            setClearVisible(false)
                                        }
                                    }, 100)
                                }}>
                                {opt.render ? opt.render : <span>{opt.label}</span>}
                                <Ripple color={'#c1c1c1'} duration={750} />
                            </li>
                        )
                    })}
                </DropdownPanel>
            )}
            {/* {!clearDisable && !readOnly && value && clearVisible && (
                <span
                    className="Dropdown clear"
                    onClick={() => { console.log('clicked'); onChange(''); }}
                >Clear</span>
            )} */}
        </div>
    )
}

export default Dropdown
