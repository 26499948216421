import React, { useState, useEffect } from 'react';
import { CollapsiblePanel, Button, Feather, Image, TextArea, useNotify, ProgressBar } from '@components';
import moment from 'moment';
import { Contract, DocumentRemove, Download } from '@assets';
import '@scss/panels/_file_attachments.scss';
import { uploadFile, deleteAttachment, downloadFile } from 'service/attachmentsApi';
import { DATE_FORMAT, DATE_FORMAT_DEFAULT } from 'consts/settings';
import { useSelector } from 'react-redux';
import { useDateFormat } from '@hooks/useDateFormat';

const FileAttachments = ({ entity, entityId, attachments = [], askDeleteConfirmation = false, setModalOptions = () => {}, onUpdateAttachments = () => {} }) => {
    const [newAttachment, setNewAttachment] = useState();
    const [uploadProgress, setUploadProgress] = useState(0);

    const { dateFormat } = useDateFormat();

    const { notify } = useNotify();

    const toBase64 = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

    const onFileSelect = async event => {
        const file = event.target.files[0];
        setUploadProgress(0);
        setNewAttachment({ image: await toBase64(file), description: '', filename: file.name, size: file.size });
        event.target.value = '';
    };

    const removeUploadedImage = async attachmentId => {
        try {
            const updatedDocument = await deleteAttachment(entity, entityId, attachmentId, attachments.find(x => x._id === attachmentId).filename);
            notify({
                title: 'Delete',
                description: 'Attachment has been deleted!',
                duration: 5000,
                type: 'success'
            });
            onUpdateAttachments({ attachments: updatedDocument.attachments, updated: updatedDocument.updated });
        } catch (err) {
            notify({
                title: 'Error',
                description: err.response.data,
                duration: 5000,
                type: 'error'
            });
        }
    };

    const onCommentChange = description => {
        setNewAttachment({ ...newAttachment, description });
    };

    const downloadImage = async attachmentId => {
        await downloadFile(entity, entityId, attachmentId, attachments.find(x => x._id === attachmentId).filename);
    };

    const onStartUpload = async () => {
        const updatedDocument = await uploadFile(entity, entityId, newAttachment.filename, newAttachment.size, newAttachment.description, newAttachment.image, setUploadProgress);
        notify({
            title: 'Saved',
            description: 'Attachment has been uploaded!',
            duration: 5000,
            type: 'success'
        });
        setNewAttachment(null);
        onUpdateAttachments({ attachments: updatedDocument.attachments, updated: updatedDocument.updated });
    };

    return (
        <CollapsiblePanel icon={Contract} title='Attachments' fixed={false}>
            <div className='FileAttachments'>
                {attachments.map((file, i) => (
                    <div key={`image-${i}`} className='d-flex flex-column mt-20 image-section'>
                        <div className='d-flex justify-content-between align-items-baseline'>
                            <div className='title'>
                                {file.filename} {'  '} <span>({(file.size / 1024).toFixed(2)} KB)</span>
                            </div>
                            <div className='d-flex'>
                                <Button onClick={() => downloadImage(file._id)} customClass={'mr-10 btnold--borderless-view p-0'}>
                                    <Image src={Download} />
                                </Button>
                                <Button onClick={() => removeUploadedImage(file._id)} customClass={'btnold--borderless-view p-0'}>
                                    <Image src={DocumentRemove} />
                                </Button>
                            </div>
                        </div>
                        <div className='upload-time'>{moment(file.uploaded).format(dateFormat)}</div>
                        <div className='col-md-8 mt-20 p-0'>
                            <span className='description'>{file.description}</span>
                        </div>
                    </div>
                ))}

                {newAttachment && (
                    <div className='d-flex flex-column mt-20'>
                        <div className='d-flex justify-content-between align-items-start'>
                            <div className='title'>
                                {newAttachment.filename} {'  '} <span>({(newAttachment.size / 1024).toFixed(2)} KB)</span>
                            </div>
                            <div>
                                <Button onClick={() => setNewAttachment(null)} customClass={'btnold--borderless-view p-0'}>
                                    <Feather name='X' />
                                </Button>
                            </div>
                        </div>
                        <div className='upload-time'>{moment().format('lll')}</div>
                        <div>
                            <TextArea value={newAttachment.description} onChange={e => onCommentChange(e.target.value)} rows={2} />
                        </div>
                    </div>
                )}
                {newAttachment && (
                    <div className='mt-20 p-0'>
                        <Button customClass='btnold--primary w-small center' ripple disabled={uploadProgress != 0} onClick={onStartUpload}>
                            Upload
                        </Button>
                        {uploadProgress != 0 && (
                            <div className='upload-progress mt-20'>
                                <ProgressBar value={uploadProgress} />
                            </div>
                        )}
                    </div>
                )}

                {!newAttachment && (
                    <div className={`upload-btn-wrapper ${attachments.length > 0 ? 'mt-30' : ''}`}>
                        <div>
                            <Button customClass='btnold--transparent' disabled={!entityId}>
                                <Feather name='Upload' />
                                <span>Upload New File</span>
                            </Button>
                            <input disabled={!entityId} type='file' name='attachments[]' onChange={onFileSelect} />
                        </div>
                    </div>
                )}
            </div>
        </CollapsiblePanel>
    );
};

export default FileAttachments;
