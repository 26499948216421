import { ThemeConfig } from 'antd'

const theme = {
    input: {
        background: '#f5f8ff',
        border: '1px solid #e0e7ff',
        error: '#f7685b',
    },
    colors: {
        orange: '#ff8939',
        green: '#2CD47A',
        red: '#F7685B',
        darkGray: '#979797',
        gray: '#C1C0C0',
        lightGray: '#F5F5F4',
        lightBlue: '#6E8CA3',
        blue: '#dbedff',
        darkBlue: '#0E3F66',
        white: 'white'
    },
    gutters: {
        title: '20px'
    }
}

export const antTheme: ThemeConfig = {
    components: {
        DatePicker: {
            colorPrimary: '#ff8939',
            fontSize: 15
        },
        Avatar: {

        },
        Layout: {
            siderBg: '#0e3f66'
        },
        Menu: {
            activeBarHeight: 4,
            activeBarWidth: 100,

            itemBg: '#0e3f66',
            itemHeight: 43,
            itemBorderRadius: 0,
            itemColor: '#ffffff',
            itemHoverColor: '#ffffff',
            itemHoverBg: '#0a66af',
            itemActiveBg: '#0a66af',
            itemSelectedBg: '#0a66af',
            itemSelectedColor: '#ffffff',

            horizontalItemBorderRadius: 0,
            horizontalItemHoverBg: '#0a66af',
            horizontalItemHoverColor: '#ffffff',
            horizontalItemSelectedBg: '#0a66af',
            horizontalItemSelectedColor: '#ffffff',
            horizontalLineHeight: '60px !important',

            dropdownWidth: 171,

            subMenuItemBg: '#ffffff',
            subMenuItemBorderRadius: 0,

            itemPaddingInline: 0,
            iconMarginInlineEnd: 8,
            itemMarginBlock: 0,
            itemMarginInline: 0,
        },
        Button: {
            // fontWeight: 500,

            colorPrimary: '#0e3f66',
            colorPrimaryHover: '#0e3f66',

            defaultBg: '#ff8939',
            // defaultBorderColor: 'transparent !important',
            defaultColor: '#ffffff !important',
            // defaultGhostBorderColor: '#ff8939',
            // defaultGhostColor: '#ff8939',
            // defaultShadow: '#ff8939'
        },
        Input: {
            activeBorderColor: '#b4c5ff',
            activeShadow: '0px 0px 0px 2px rgba(124, 149, 239, 0.24)',
            hoverBorderColor: '#e0e7ff',
            addonBg: 'linear-gradient(0deg, rgba(224, 231, 255, 0.2), rgba(224, 231, 255, 0.2))'
        },
        InputNumber: {
            /* here is your component tokens */
        },
        Form: {
            /* here is your component tokens */
        },
    }
}

export default theme
