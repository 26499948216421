import React from 'react'

type ShowProps = {
    condition: boolean
}

const Show= ({ condition, children }:React.PropsWithChildren<ShowProps>) => {
    if (!condition) return null
    return <>{children}</>
}

export default Show
