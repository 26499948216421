import React, { useEffect, useState } from 'react';
import { DropdownCheckBox, DataPair } from '@components';

const TransportCostModal = ({
    options,
    value,
    onChange = () => { }
}) => {

    return (
        <div>
            <DropdownCheckBox
                label='Transport Cost'
                options={options || []} value={value}
                onChange={onChange} />

        </div>
    );
};

export default TransportCostModal;
