import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { Autocomplete, DrawingManager, GoogleMap, Marker, Rectangle, useJsApiLoader } from '@react-google-maps/api'

import { FieldError } from '@components'

import { Loader } from '@common'

import blueDot from '@assets/icons/maps/complete-dot-blue.svg'
import grayDot from '@assets/icons/maps/complete-dot-gray.svg'
import greenDot from '@assets/icons/maps/complete-dot-green.svg'
import navyDot from '@assets/icons/maps/complete-dot-navy.svg'
import orangeDot from '@assets/icons/maps/complete-dot-orange.svg'
import redDot from '@assets/icons/maps/complete-dot-red.svg'
import deliverMarker from '@assets/icons/maps/deliver-marker.svg'
import icon_default from '@assets/icons/maps/marker-1.svg'
import pin_competitor from '@assets/icons/maps/pin_competitor.svg'
import pin_plant from '@assets/icons/maps/pin_plant.svg'
import pin_job from '@assets/icons/maps/under-construction.png'
import pin_default_plant from '@assets/icons/maps/pin_default_plant.svg'

import { geocoding } from 'service/googleMapApi'

import { coordinatesToLatLng } from '@utils/googlemap'

import CustomControl from './CustomControl'
import MapControl from './MapControl'

import { calculateTripDuration, calculateTripDistance } from '@panels/CalculateTransport/utils'
import { milesToKm } from 'utils/unit'

import { useUOM } from '@hooks/useUOM'

import '@scss/components/_google-map.scss'

const MAP_STYLES = {
    width: '100%',
    height: '100%',
    color: 'red'
}

export const DEFAULT = 0
export const GREEN_DOT = 1
export const RED_DOT = 2
export const BLUE_DOT = 3
export const COMPETITOR_PLANT = 4
export const PLANT = 5
export const SITE = 6
export const ORANGE_DOT = 7
export const GRAY_DOT = 8
export const NAVY_DOT = 9

const DRAWING_CUSTOM_CONTROLS_CLASS = 'drawing-custom-controls'

const icons_percentage = [icon_default, greenDot, redDot, blueDot, pin_competitor, pin_plant, pin_job, orangeDot, grayDot, navyDot, deliverMarker, pin_default_plant]

const GOOGLE_API_LIBRARIES = ['drawing', 'places', 'geometry', 'routes', 'visualization']

const GOOGLE_AUTOCOMPLETE_TYPES = ['geocode']

export const Map = forwardRef((props: any, ref: any) => {
    const {
        mapCenter,
        zoom,
        marker,
        onMouseDown = () => {},
        mapOptions,
        error,
        readOnly = false,
        onChangePolygon = () => {},
        onMapLoad = () => {},
        gridMap,
        pins,
        setDrawnArea,
        setDrawingControl,
        onPinClick,
        disableGeocoder = false,
        handleSelectedRouteChanged = () => {},
        onWaypointsChanged = () => {},
        mapRoutes,
        waypoints = [],
        calculateTransport = false,
        selectedRouteIndex = 0,
        mapServicesRef,
        noReverseGeocoding = false
    } = props

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.GOOGLE_MAP_API || '',
        language: 'en',
        libraries: GOOGLE_API_LIBRARIES as any
    })

    const [mapZoom, setMapZoom] = useState(zoom)
    const [autocomplete, setAutocomplete] = useState<any>(null)
    const [drawingMode, setDrawingMode] = useState<any>(null)
    const [map, setMap] = useState<any>(null)
    const [polygon, setPolygon] = useState<any>(null)
    const [markers, setMarkers] = useState<any>([])
    const [route, setRoute] = useState<any>({
        pointA: '',
        pointB: '',
        plantId: ''
    })
    const [mapTypeId, setMapTypeId] = useState(mapOptions?.mapTypeId ?? 'roadmap')
    const [address, setAddress] = useState('')

    const { getRouteUnitByMeasure } = useUOM()
    const unit = getRouteUnitByMeasure()?.label

    const onLoad = React.useCallback(function callback(map: any) {
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback() {
        setMap(null)
    }, [])

    /**
     * Update map zoom after zoom prop changed when fitBounds is triggerd from parent component
     * It avoids zoom gaps with +/- zoom buttons
     */
    useEffect(() => {
        setMapZoom(zoom)
    }, [zoom])

    const onZoomInClick = () => {
        setMapZoom((prev: any) => prev + 1)
    }

    const onZoomOutClick = () => {
        setMapZoom((prev: any) => prev - 1)
    }

    const drawingModeClass = useMemo(() => {
        if (!window.google?.maps?.drawing || drawingMode !== window.google?.maps?.drawing?.OverlayType?.POLYGON) {
            return DRAWING_CUSTOM_CONTROLS_CLASS
        }
        return `${DRAWING_CUSTOM_CONTROLS_CLASS} active`
    }, [drawingMode])

    const onPolygonControlClick = () => {
        if (drawingMode === google.maps.drawing.OverlayType.POLYGON) {
            setDrawingControl && setDrawingControl(false)
            onHandControlClick()
            return
        }

        setDrawingControl && setDrawingControl(true)
        polygon && onDeleteControlClick()

        setDrawingMode(google.maps.drawing.OverlayType.POLYGON as any)
    }

    const onHandControlClick = () => {
        setDrawingMode(null)
    }

    const onDeleteControlClick = () => {
        polygon.setMap(null)
        setPolygon(null)
        onChangePolygon(null)
        setDrawnArea && setDrawnArea()
    }

    const onLoadAutocomplete = (autocomplete: any) => {
        setAutocomplete(autocomplete)
    }

    const onPolygonComplete = (polygon: any) => {
        setPolygon(polygon)
        onChangePolygon(polygon)
        onHandControlClick()
        setDrawnArea && setDrawnArea(polygon)
    }

    const onLoadDrawingManager = () => {
        if (!props.polygon) {
            return
        }
        const googleLatLng = coordinatesToLatLng(props.polygon)
        const googlePolygon = new google.maps.Polygon({
            ...(mapOptions?.drawingManager?.options?.polygonOptions || {}),
            paths: googleLatLng
        })
        googlePolygon.setMap(ref?.current?.state?.map)
        onPolygonComplete(googlePolygon)
    }

    const onPlaceChanged = async () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace()
            let { results } = await geocoding({ place_id: place.place_id })
            const LatLng = results[0]?.geometry?.location
            const formatted_address = results[0]?.formatted_address
            const address_components = results[0]?.address_components
            setAddress(formatted_address)
            onMouseDown({ ...LatLng, address_components, formatted_address, placeName: place?.name })
        }
    }

    const reverseGeocoder = async (e: any) => {
        if (noReverseGeocoding) return
        const latLng = e.latLng.toJSON()
        onMouseDown(latLng)
        const { results } = await geocoding({ latlng: `${latLng.lat},${latLng.lng}` })
        const input: any = document.querySelector('input.pac-target-input')
        if (!input) return
        input.value = results?.[1]?.formatted_address
    }

    const getTooltipInfo = (route: any) => {
        const duration = calculateTripDuration(route)
        let distance = calculateTripDistance(route)
        if (unit === 'km') distance = milesToKm(distance)
        return `<div class='info-window'><div class='info-window--duration'>${duration} minutes</div><div class='info-window--distance'>${distance} ${unit}</div></div>`
    }

    /**
     * Calculate and display alternative routes on the map and ability to choose one
     */
    const [dots, setDots] = useState<any>([]) // to store all the waypoints on the map
    const [isAddingWaypoints, setIsAddingWaypoints] = useState(false) // to know if we are adding waypoints on the map to disable autozoom

    const getMultipleRoutes = async (infoWindow: any, polylines: any) => {
        const bounds = new google.maps.LatLngBounds() // needed to set all routes entirely visible in the map

        /**
         * Create a polyline for each route and add it to the map
         */
        mapRoutes.forEach((itinerary: any, index: any) => {
            const decodedPath = itinerary.overview_path ?? google.maps.geometry.encoding.decodePath(itinerary.overview_polyline)

            const polyline = new google.maps.Polyline({
                path: decodedPath,
                strokeColor: selectedRouteIndex === index ? '#4fadf9' : '#bcbdbf',
                strokeWeight: 6,
                strokeOpacity: 1,
                zIndex: selectedRouteIndex === index ? 99 : 97 - index - 1
            })
            polyline.setMap(map)
            polylines.push(polyline)

            /**
             * Add a border to each polyline to look like Goggle Maps design
             */
            const borderPolyline = new google.maps.Polyline({
                path: decodedPath,
                strokeColor: selectedRouteIndex === index ? '#3265cb' : '#939497',
                strokeWeight: 8,
                strokeOpacity: 1,
                zIndex: selectedRouteIndex === index ? 98 : 97 - index - 2
            })
            borderPolyline.setMap(map)
            polylines.push(borderPolyline)

            /**
             * Add event listener to each polyline to display a tooltip with trip duration
             */
            google.maps.event.addListener(polyline, 'mouseover', (event: any) => {
                infoWindow.setContent(getTooltipInfo(itinerary))
                infoWindow.setPosition(event.latLng)
                infoWindow.setOptions({
                    pixelOffset: new google.maps.Size(0, -10)
                })
                infoWindow.open(map)
            })

            google.maps.event.addListener(polyline, 'mouseout', () => infoWindow.close())

            /**
             * Add event listener to each polyline to select a route
             */
            google.maps.event.addListener(polyline, 'click', () => {
                handleSelectedRouteChanged({ plantId: route.plantId, selectedIndex: index })
            })

            /**
             * Add event listener to each polyline to add a waypoint on double click
             */
            google.maps.event.addListener(polyline, 'dblclick', (event: any) => {
                const marker = new google.maps.Marker({
                    position: event.latLng,
                    map: map,
                    draggable: true
                })

                setDots((prev: any) => [...prev, { plantId: route.plantId, marker }])
                setIsAddingWaypoints(true)
            })

            /**
             *  Add routes bounds to the map bounds to fit all routes
             */
            bounds.union(itinerary.bounds)
        })
        !isAddingWaypoints && map.fitBounds(bounds)
    }

    useEffect(() => {
        if (!calculateTransport || !map || !mapRoutes || !mapRoutes.length) return

        const polylines: any = []
        const infoWindow = new google.maps.InfoWindow() // needed to see the tooltip with trip informations
        map.setOptions({ disableDoubleClickZoom: true }) // disable double click zoom on the map to avoid auto zoomwhen adding a waypoint
        const trafficLayer = new google.maps.TrafficLayer() // needed to display traffic informations on the map
        trafficLayer.setMap(map)
        getMultipleRoutes(infoWindow, polylines)

        /**
         * Clean up to remove all polylines events listeners and polylines to update selected route color
         */
        return () => {
            infoWindow && infoWindow.close()
            polylines.forEach((polyline: any) => {
                google.maps.event.clearInstanceListeners(polyline)
                polyline.setMap(null)
            })
        }
    }, [calculateTransport, map, mapRoutes, selectedRouteIndex, route])

    /**
     * Add previous stored waypoints on the map to retrieve associated markers
     */
    useEffect(() => {
        if (!route?.plantId) return

        waypoints.forEach((waypoint: any) => {
            const existingDot = dots.find((dot: any) => dot.plantId === route.plantId)

            if (waypoint?.lat && waypoint?.lng && !existingDot) {
                const marker = new google.maps.Marker({
                    position: typeof waypoint.lat === 'function' && typeof waypoint.lng === 'function' ? waypoint.toJSON() : { lat: waypoint.lat, lng: waypoint.lng },
                    map: map,
                    draggable: true
                })
                setDots((prev: any) => [...prev, { plantId: route.plantId, marker }])
            }
        })
    }, [waypoints, route?.plantId])

    useEffect(() => {
        /**
         * Add event listener to each waypoint to update the trip informations
         * when dragging a waypoint
         */
        dots.forEach((marker: any) => {
            google.maps.event.addListener(marker.marker, 'dragend', () => {
                onWaypointsChanged(dots)
            })
        })
    }, [dots])

    useImperativeHandle(
        mapServicesRef,
        () => ({
            addMarkerToMap(markersToShow: any) {
                setMarkers([...markersToShow])
            },
            buildRoute(from: any, to: any, plantId: any) {
                setRoute({ ...route, pointA: from, pointB: to, plantId })
            },
            removeAllMarkers() {
                setMarkers([])
            }
        }),
        [markers]
    )

    useEffect(() => {
        if (!isLoaded) return
        onMapLoad()
    }, [isLoaded])

    if (!isLoaded) {
        return <Loader height='100%' />
    }

    return (
        <>
            <GoogleMap
                ref={ref}
                mapContainerClassName={`google-map-container ${error ? 'error' : ''}`}
                mapContainerStyle={MAP_STYLES}
                onLoad={onLoad}
                onUnmount={onUnmount}
                center={mapCenter}
                zoom={mapZoom}
                onClick={disableGeocoder ? () => {} : reverseGeocoder}
                clickableIcons={false}
                options={{
                    fullscreenControl: false,
                    streetViewControl: false,
                    zoomControl: false,
                    rotateControl: true,
                    mapTypeControl: false,
                    mapTypeId: mapTypeId,
                    controlSize: 20
                }}>
                {/* Address search bar */}
                {mapOptions?.autocomplete && (
                    <Autocomplete onLoad={onLoadAutocomplete} onPlaceChanged={onPlaceChanged} types={GOOGLE_AUTOCOMPLETE_TYPES}>
                        <input
                            value={address || ''}
                            onChange={e => setAddress(e.target.value || '')}
                            type='text'
                            placeholder='Start typing your address...'
                            className='autocompleteBox'
                        />
                    </Autocomplete>
                )}

                {/* unused now TO CHECK */}
                {markers.map((marker: any) => (
                    <Marker key={marker.id} position={{ lat: marker.lat, lng: marker.lng }} icon={{ url: icons_percentage[marker.icon], scale: 7 }} draggable={marker.draggable} />
                ))}

                {/* unused now TO CHECK */}
                {marker && <Marker position={marker.position} draggable={marker.draggable} icon={{ url: icons_percentage[marker.icon], scale: 7 }} onDragEnd={reverseGeocoder} />}

                {/* Display polygon drawing manager */}
                {mapOptions?.drawingManager && !readOnly && (
                    <>
                        <DrawingManager drawingMode={drawingMode} options={mapOptions.drawingManager.options} onLoad={onLoadDrawingManager} onPolygonComplete={onPolygonComplete} />
                        <MapControl position={google.maps.ControlPosition.TOP_RIGHT}>
                            <div className={drawingModeClass}>
                                <CustomControl iconName='polygon' onClick={onPolygonControlClick} />
                            </div>
                        </MapControl>
                    </>
                )}

                {/* +/- zoom buttons */}
                <MapControl position={google.maps.ControlPosition.BOTTOM_RIGHT}>
                    <div className='zoom-custom-controls'>
                        <CustomControl iconName='plus' onClick={onZoomInClick} />
                        <CustomControl iconName='minus' onClick={onZoomOutClick} />
                    </div>
                </MapControl>

                {/* map type buttons (roadmap / satellite) */}
                {mapOptions?.typeControl && (
                    <MapControl position={google.maps.ControlPosition.BOTTOM_LEFT}>
                        <div className='zoom-custom-controls'>
                            <CustomControl iconName='map' onClick={() => setMapTypeId(mapTypeId === 'satellite' ? 'roadmap' : 'satellite')} />
                        </div>
                    </MapControl>
                )}

                {/* Draw gridMap if enabled */}
                {gridMap && gridMap.length ? (
                    gridMap.map((square: any, i: number) => <Rectangle key={`${square.bounds.lat}-${i}`} bounds={square.bounds} options={square.options} />)
                ) : (
                    <></>
                )}

                {/* Draw quote pins */}
                {pins && pins.length ? (
                    pins.map((pin: any) => (
                        <Marker
                            key={pin.id}
                            position={{ lat: pin.position.lat, lng: pin.position.lng }}
                            icon={{ url: pin.icon }}
                            title={pin.title}
                            draggable={false}
                            onClick={e => onPinClick(pin.id, e.domEvent)}
                        />
                    ))
                ) : (
                    <></>
                )}
            </GoogleMap>
            <FieldError error={error} />
        </>
    )
})

export default Map
