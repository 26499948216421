import { CSSProperties, ReactNode } from 'react'

import { ConfigProvider, Select, Space, Tag } from 'antd'

import styled, { createGlobalStyle } from 'styled-components'

import { Checkbox } from '@common'

const AntdDropdown = ({
    options,
    value,
    label,
    placeholder,
    onChange,
    multiple = false,
    selectAllEnabled = false,
    style = { width: '100%' },
    disabled = false,
    allowClear = false,
    onClear = () => {}
}: {
    value: string | string[] | null | undefined
    placeholder: string
    options: { label: string; value: string }[]
    onChange: (value: any) => void
    label?: string
    style?: CSSProperties
    multiple?: boolean
    selectAllEnabled?: boolean
    disabled?: boolean
    allowClear?: boolean
    onClear?: () => void
}) => {
    const tagRender = (props: { label: ReactNode; closable: boolean; onClose: () => void }) => {
        const { label, closable, onClose } = props
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault()
            event.stopPropagation()
        }
        return (
            <AntdTag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ color: 'rgba(0, 0, 0, 0.88)' }}>
                {label}
            </AntdTag>
        )
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    borderRadius: 2,
                    colorBgContainer: 'rgba(224, 231, 255, 0.2)'
                }
            }}>
            <GlobalStyle />
            {label && <Label>{label}</Label>}
            <Wrapper>
                {multiple ? (
                    <Select
                        mode='multiple'
                        allowClear
                        style={style}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        options={options}
                        disabled={disabled}
                        tagRender={tagRender}
                        showSearch={false}
                        maxTagCount={5}
                        optionRender={option => (
                            <SpaceWrapper>
                                <Checkbox checked={value?.includes(option.value as string)}>{option.label}</Checkbox>
                            </SpaceWrapper>
                        )}
                    />
                ) : (
                    <Select
                        placeholder={placeholder}
                        value={value}
                        defaultValue={value}
                        style={style}
                        onChange={onChange}
                        options={options}
                        disabled={disabled}
                        allowClear={allowClear}
                        onClear={onClear}
                    />
                )}
            </Wrapper>
        </ConfigProvider>
    )
}

export default AntdDropdown

const GlobalStyle = createGlobalStyle`
    .ant-select-item-option-state {
        display: none !important;
        visibility: hidden !important;
    }
`

const Label = styled.span`
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 1.2px;
    color: #6e8ca3;
    text-transform: uppercase;
`

const Wrapper = styled.div`
    margin-top: 0.5rem;
    width: 100%;
`

const SpaceWrapper = styled(Space)`
    dispaly: flex;
    margin: 5px;
`

const AntdTag = styled(Tag)`
    display: flex;
    gap: 10px;
    margin: 2px;
    padding: 3px 7px;
    white-space: nowrap;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #0e3f66;
    background: #dbedff;
    border-radius: 5px;
`
