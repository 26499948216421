import { STANDARD } from '@constants/productLines'
import { Entry } from '@customTypes/entry'
import { Plant } from '@customTypes/plant'
import { Transport } from '@customTypes/transport'
import { Truck } from '@customTypes/truck'

function calculateTransportPrice(truck: Truck, transport: Transport, plant: Partial<Plant>, tripDuration: number, entries?: Entry[]) {
    if (!truck || !transport || (!tripDuration && tripDuration !== 0)) return null

    let roundTrip = transport?.roundTrip || tripDuration * 2
    if (truck?.roundTripAdjustment >= 0) roundTrip = (transport?.roundTrip || tripDuration * 2) * (1 + truck.roundTripAdjustment / 100)

    const filtered =
        entries?.filter(
            (entry: Entry) =>
                entry?.uom === truck?.material?.defaultUOM?._id && entry.product?.productType?.key !== STANDARD && entry.qty && entry?.qty > 0 && entry?.price && entry.price > 0
        ) || []

    const estimatedQty = filtered.reduce((acc: number, el) => acc + (el.qty || 0), 0)

    const totalTripTime = Math.round(((transport?.roundTrip ?? roundTrip) + transport?.loadingTime + transport?.unloadingTime) * 100) / 100

    let nbOfTrucks =
        Math.round(
            ((transport?.estimatedQty || estimatedQty || 0) /
                (transport?.jobDuration ?? 1) /
                transport.tonage /
                (((transport?.workingHours || plant?.hours || 8) * 60) / totalTripTime)) *
                100
        ) / 100

    if (transport?.roundUp) nbOfTrucks = Math.ceil(nbOfTrucks)

    let transportPrice = 0
    if (!transport?.jobDuration) {
        transportPrice = (((transport?.pricePerHour ?? truck?.pricePerHour ?? 0) / 60) * totalTripTime) / transport.tonage
    } else if (transport?.jobDuration && transport?.roundUp) {
        transportPrice =
            ((nbOfTrucks || 1) * transport.jobDuration * (transport?.workingHours ?? plant?.hours ?? 8) * (transport?.pricePerHour ?? truck?.pricePerHour ?? 0)) /
            (transport?.estimatedQty || estimatedQty || 1)
    } else if (transport?.jobDuration && !transport?.roundUp) {
        transportPrice = (totalTripTime * (transport?.pricePerHour ?? truck?.pricePerHour ?? 0)) / (transport.tonage * 60)
    }
    transportPrice = Math.round(transportPrice * 100) / 100

    return transportPrice
}

export default calculateTransportPrice
