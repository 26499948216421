import * as React from 'react'

interface CellRenderProps {
    originalProps: any
    td: React.ReactElement<HTMLTableCellElement>
    enterEdit: (dataItem: any, field: string | undefined) => void
    editField: string | undefined
}

export const CellRender = (props: CellRenderProps) => {
    const dataItem = props.originalProps.dataItem || {}
    const cellField = props.originalProps.field
    const inEditField = dataItem[props.editField || '']
    const additionalProps =
        cellField && cellField === inEditField
            ? {
                  ref: (td: any) => {
                      const input = td && td.querySelector('input')
                      const activeElement = document.activeElement

                      if (!input || !activeElement || input === activeElement || !activeElement.contains(input)) {
                          return
                      }

                      if (input.type === 'checkbox') {
                          input.focus()
                      } else {
                          input.select()
                      }
                  }
              }
            : {
                  onClick: () => {
                      props.enterEdit(dataItem, cellField)
                  }
              }

    const clonedProps: any = { ...props.td.props, ...additionalProps, ...{ style: { background: props.originalProps.dataItem?.extraRowProps?.style?.background } } }
    const childNodes: any = props.td.props.children
    return React.cloneElement(props.td, clonedProps, childNodes)
}

import { NormalizedDragEvent, useDraggable, useDroppable } from '@progress/kendo-react-common'
import { GridRowProps } from '@progress/kendo-react-grid'
import { ReorderContext } from '.'

interface DraggableRowProps extends GridRowProps {
    elementProps: any
    tr: React.ReactElement<HTMLTableRowElement>
    exitEdit: () => void
    editField: string | undefined
}

export const RowRender = (props: DraggableRowProps) => {
    const { elementProps, tr, exitEdit, editField, ...originalProps } = props
    const { id } = props.tr.props
    const extraRowProps = originalProps.dataItem?.extraRowProps
    const trProps = {
        id,
        onBlur: () => {
            setTimeout(() => {
                const activeElement = document.activeElement
                if (activeElement && activeElement.className.indexOf('k-calendar') < 0) {
                    props.exitEdit()
                }
            })
        },
        ...(extraRowProps || {})
    }
    // const childNodes: any = props.tr.props.children
    const [dropped, setDropped] = React.useState(false)
    const [dragged, setDragged] = React.useState(false)
    const [direction, setDirection] = React.useState<'before' | 'after' | null>(null)
    const [initial, setInitial] = React.useState({ x: 0, y: 0 })
    const { dragStart, reorder } = React.useContext(ReorderContext)

    const element = React.useRef<HTMLTableRowElement>(null)

    const handlePress = (event: NormalizedDragEvent) => {
        setInitial({
            x: event.clientX - event.offsetX,
            y: event.clientY - event.offsetY
        })
    }

    const handleDragStart = (event: NormalizedDragEvent) => {
        if (!event.originalEvent.target || !(event.originalEvent.target as HTMLElement).dataset.dragHandle) {
            return
        }
        setDragged(true)
        dragStart(props.dataItem)
    }

    const handleDrag = (event: NormalizedDragEvent) => {
        if (!element.current || !dragged) {
            return
        }
        element.current.style.transform = `translateY(${event.clientY - initial.y + event.scrollY}px)`
    }

    const handleDragEnd = () => {
        setDragged(false)
        setDropped(false)
        setInitial({ x: 0, y: 0 })
    }

    const handleRelease = () => {
        if (!element.current) {
            return
        }
        ;(element.current.style.transform as any) = null
    }

    const handleDragEnter = () => {
        setDropped(true)
        setDirection(null)
    }

    const handleDragOver = (event: NormalizedDragEvent) => {
        if (!element.current) {
            return
        }
        const rect = element.current.getBoundingClientRect()
        setDirection(rect.top + rect.height / 2 <= event.clientY ? 'after' : 'before')
    }

    const handleDragLeave = () => {
        setDropped(false)
        setDirection(null)
    }

    const handleDrop = () => {
        reorder(props.dataItem, direction)
        setDropped(false)
        setDirection(null)
    }

    useDraggable(
        element,
        {
            onPress: handlePress,
            onDragStart: handleDragStart,
            onDrag: handleDrag,
            onDragEnd: handleDragEnd,
            onRelease: handleRelease
        },
        { autoScroll: dragged }
    )

    useDroppable(element, {
        onDragEnter: handleDragEnter,
        onDragOver: handleDragOver,
        onDragLeave: handleDragLeave,
        onDrop: handleDrop
    })

    return (
        <React.Fragment>
            {dropped && direction === 'before' && (
                <tr
                    {...{
                        ...trProps,
                        style: {
                            outlineStyle: 'solid',
                            outlineWidth: 1,
                            outlineColor: '#ff8939'
                        }
                    }}
                />
            )}
            <tr
                {...props.elementProps}
                ref={element}
                {...{
                    ...trProps,
                    style: {
                        backgroundColor: '#fff',
                        userSelect: 'none',
                        pointerEvents: dragged ? 'none' : undefined,
                        opacity: dragged ? '0.8' : undefined
                    }
                }}
            />
            {dropped && direction === 'after' && (
                <tr
                    {...{
                        ...trProps,
                        style: {
                            outlineStyle: 'solid',
                            outlineWidth: 1,
                            outlineColor: '#ff8939'
                        }
                    }}
                />
            )}
        </React.Fragment>
    )
}
