import '@scss/components/_dataPair.scss';

const DataPair = props => {
    const {
        titleOne,
        valueOne,

        titleTwo,
        valueTwo,

        className = ''
    } = props;

    return (
        <div className={`data-pair d-flex ${className}`}>
            <div className='col-md-6 p-none'>
                <div className='row data-pair__title'>
                    <span>{titleOne}</span>
                </div>
                <div className='row data-pair__value'>
                    <span>{valueOne}</span>
                </div>
            </div>
            <div className='col-md-6 p-none'>
                <div className='row data-pair__title'>
                    <span>{titleTwo}</span>
                </div>
                <div className='row data-pair__value'>
                    <span>{valueTwo}</span>
                </div>
            </div>
        </div>
    );
};

export default DataPair;
