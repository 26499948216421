import { CSSProperties, ReactNode } from 'react'

import { Image } from '@components'

import StatusSelector from '@panels/Headline/StatusSelector'

import '@scss/panels/_user_setup.scss'

const Setup = (props: { title?: string; icon: string; children: ReactNode; panelRight?: boolean; className?: string; onFilterChange?: () => void; style: CSSProperties }) => {
    const { title = '', icon, children, panelRight = null, className = '', onFilterChange = () => {}, style } = props

    return (
        <div className={`UserSetup ${className}`} style={style}>
            {title || panelRight ? (
                <div className='UserSetup__Header'>
                    <div className='headline'>
                        {icon && (
                            <div className='image'>
                                <Image src={icon} className='icon' />
                            </div>
                        )}
                        <h1>{title}</h1>
                    </div>
                    {onFilterChange && <StatusSelector base={false} onFilterChange={onFilterChange} />}
                    {panelRight}
                </div>
            ) : null}
            <div className='UserSetup__Content'>{children}</div>
        </div>
    )
}

export default Setup
