import React, { useCallback } from 'react'

import { DECIMALS, THOUSAND_SEPARATOR, CURRENCY } from 'consts/settings'

import { useFinancialSettings } from './useFinancialSettings';

export const useCurrency = () => {
    const thousandSeparator: string = useFinancialSettings(THOUSAND_SEPARATOR)
    const decimals: string = useFinancialSettings(DECIMALS)
    const currency: string = useFinancialSettings(CURRENCY)
    const isDollar: boolean = currency?.length ? ['usd', 'nzd'].some(term => currency.includes(term)) : true
    const sign: string = isDollar ? '$' : '€'
    const currencyFormat = useCallback(
        (num: number, nbDecimals?: number) => {
            const options = {
                minimumFractionDigits: nbDecimals ?? 2,
                maximumFractionDigits: nbDecimals ?? 2
            }

            let x = !num || Number.isNaN(num) ? 0 : num
            const isNegativeNumber = x < 0
            if (isNegativeNumber) {
                x = Math.abs(x)
            }

            let priceBySettings = Number(x).toLocaleString('en-US', options)
            if (thousandSeparator === 'space') {
                priceBySettings = priceBySettings.replace(/,/g, ' ')
            }

            if (decimals === 'dec-0,1') {
                priceBySettings = priceBySettings.replace(/\./g, ',')
            }

            const resultPrice = isDollar ? '$' + priceBySettings : priceBySettings + ' €'
            if (!isNegativeNumber) {
                return resultPrice
            }

            return `-${resultPrice}`
        },
        [thousandSeparator, currency, decimals]
    )
    const roundedFormat = useCallback(
        (num: number) => {
            if (typeof num !== 'number') return
            return Math.round((num + Number.EPSILON) * 100) / 100
        },
        []
    )

    return { currencyFormat, isDollar, sign, currency, roundedFormat }
}
