import { useMemo } from 'react'

import { useAuth } from '@hooks/useAuth'

export const useDefaultMap = () => {
    const { user } = useAuth()

    const DEFAULT_MAP = useMemo(() => {
        if (!user?.tenant?.settings?.length) {
            return { lat: 0, lng: 0 }
        }

        const { settings } = user.tenant

        const lat = Number.parseFloat(settings.find((k: { key: string }) => k.key === 'map_default_lat')?.value || 0)
        const lng = Number.parseFloat(settings.find((k: { key: string }) => k.key === 'map_default_lng')?.value || 0)

        return { lat, lng }
    }, [user])

    return DEFAULT_MAP
}
