import { PropsWithChildren } from 'react'
import { ThemeColors } from '@customTypes/theme'
import styled from 'styled-components'
import { Tooltip } from 'antd'

type CubePropsType = {
    tooltip?: JSX.Element | string
    placement?: 'top' | 'right' | 'left'
    onClick?: (e: any) => void
    style?: React.CSSProperties
    id?: string
}

const Cube = ({ tooltip, placement, ...props }: PropsWithChildren<CubePropsType & StyledCubePropsType>) => {
    if (!tooltip) return <StyledCube {...props} />
    else
        return (
            <Tooltip color='#0e3f66' title={tooltip} placement={placement || 'top'}>
                <StyledCube {...props} />
            </Tooltip>
        )
}

type StyledCubePropsType = {
    color?: ThemeColors
    fontColor?: ThemeColors
}

export const StyledCube = styled.button<StyledCubePropsType>`
    border: none;
    outline: none;
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme, fontColor = 'white' }) => theme.colors[fontColor]};
    background-color: ${({ theme, color = 'white' }) => theme.colors[color]};
    font-size: 14px;
    font-weight: 600;
    font-family: 'Rubik';
    padding: 6px;
`

export default Cube
