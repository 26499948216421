import React, { useMemo } from 'react';

import { DropdownCheckBox } from '@components';

const CompetitorSelectModal = (props) => {
    const {
        competitors = [],
        value,

        onChange = () => { }
    } = props;

    const competitorOptions = useMemo(() => {
        return competitors.map(comp => {
            return { 
                value: comp._id, 
                label: comp.name, 
                sub: comp.plants.map(x => ({ value: x._id, label: x.name })) 
            }
        })
    }, [competitors.length]);

    return (
        <div>
            <DropdownCheckBox
                label="Competitor's plant"
                multiple
                options={competitorOptions}
                value={value || []}
                onChange={onChange}
            />
        </div>
    )
}

export default CompetitorSelectModal;
