import { Quote } from '@customTypes/quote'
import useStages from '@hooks/useStage'
import styled from 'styled-components'

const Legend = ({ quotes, height }: { quotes: Partial<Quote>[]; height?: string }) => {
    const { getName, getColor } = useStages('quote')

    const progresses = quotes?.map((quote: Partial<Quote>) => quote.currentProgress)
    const unique = new Set(progresses)

    const legend = Array.from(unique).map(stage => {
        const name = getName(stage || '')
        const color = getColor(stage || '') || ''
        return { key: stage, name, color }
    })

    return (
        <Container height={height}>
            {legend.map(stage => (
                <LegendItem key={stage.key}>
                    <Dot color={stage.color} />
                    {stage.name}
                </LegendItem>
            ))}
        </Container>
    )
}

export default Legend

const Container = styled.div<{ height?: string }>`
    height: ${props => (props.height ? props.height : '0')};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
`

const LegendItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 10px 0 0 5px;
    display: flex;
    column-gap: 5px;
    row-gap: 5px;
    font-weight: 700;
    color: #67859e;
`

const Dot = styled.span<{ color: string }>`
    background-color: ${props => (props.color ? props.color : 'white')};
    width: 15px;
    height: 15px;
    border-radius: 50%;
`
