import styled from 'styled-components'

export const Label = styled.label`
    color: #6E8CA3;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 8px;
`

export default Label