import theme from '@project/theme'
import Icon from './Icon'
import Popup from '@components/UI/Dropdown/popup'
import { Flex } from 'antd'

type PropsType = { name?: string }

const InfoTip = ({ children, name = 'info' }: React.PropsWithChildren<PropsType>) => {
    return (
        <Popup
            content={children}
            placement='top'
            style={{}}
            trigger={
                <Flex align='center' style={{ cursor: 'pointer', height: 16 }}>
                    <Icon name={name} style={{ width: 16, height: 16, color: theme.colors.blue3 }} />
                </Flex>
            }
        />
    )
}

export default InfoTip
