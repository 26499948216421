import { useQuery } from '@tanstack/react-query'

import service from '@service/service';

export const isNull = field => {
    if (field === null || typeof field === 'undefined') {
        return true;
    }

    return false;
};

export const shouldShowTECValidationAlert = quote => {
    const { data: tecValidation } = useQuery(['notification-settings'], () => service.get('/notification-settings'),
        {
            select: res => res.data
        }
    )

    if (!tecValidation || !quote?.customer || (quote?.customer && quote.customer?.taxExempt === true)) {
        return null;
    }

    if (quote?.taxable !== true && !quote?.taxExemptCertificate) {
        return true;
    }

    return false;
};
