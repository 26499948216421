import { ImportedLabel, StatusBadge, Image } from '@components'

import { Show } from '@common'

import { MergedIcon } from '@assets'

import '@scss/components/_merged-icon.scss'

export default [
    {
        id: 1,
        title: 'Customer No',
        dataIndex: 'customerNo',
        sort: true,
        hidden: false,
        width: 130,
        filter: true,
        required: true,
        render: ({ row }) => row.customerNo
    },
    {
        id: 2,
        title: 'Status',
        dataIndex: 'status',
        sort: true,
        hidden: false,
        filter: true,
        width: 125,
        align: 'center',
        render: ({ row }) => row.status && <StatusBadge status={row.status} />
    },
    {
        id: 3,
        title: 'Customer Name',
        dataIndex: 'name',
        sort: true,
        hidden: false,
        width: 300,
        filter: true,
        render: ({ row }) => (
            <div className='flex-start flex-v-center'>
                {row?.name}
                <ImportedLabel imported={row?.imported} />
                <Show condition={row?.merged && Array.isArray(row?.merged) && row?.merged.length > 1}>
                    <div className='merged-icon'>
                        <Image src={MergedIcon} />
                    </div>
                </Show>
            </div>
        )
    },
    {
        id: 4,
        title: 'Email',
        dataIndex: 'email',
        sort: true,
        hidden: false,
        width: 250,
        filter: true,
        render: ({ row }) => `${row.email || ''}`
    },
    {
        id: 5,
        title: 'Phone number',
        dataIndex: 'phoneNumber',
        sort: true,
        hidden: false,
        width: 150,
        filter: true,
        render: ({ row }) => `${row.phoneNumber || ''}`
    },
    {
        id: 6,
        title: 'Previous Customer Record',
        dataIndex: 'previousCustomerRecord',
        hidden: false,
        width: 250,
        sort: true,
        filter: true,
        render: ({ row }) => row?.merged?.[0]?.previousName || ''
    }
]
