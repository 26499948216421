import { Switch as AntSwitch, SwitchProps } from 'antd'
import styled from 'styled-components'

const StyledSwitch = styled.div`
    display: inline;
    .ant-switch-checked {
        background-color: #ff8939;
    }
    .ant-switch-checked:hover:not(.ant-switch-disabled) {
        background-color: #ff8939;
    }
`

const Switch = ({ ...props }: SwitchProps) => (
    <StyledSwitch>
        <AntSwitch {...props} />
    </StyledSwitch>
)

export default Switch
