import React from 'react'
import '@scss/components/_number-box.scss'

const NumberBox = ({ number }) => {
    return (
        <div>
            <span className="number-box">{`${number}`}</span>
        </div>
    )
}

export default NumberBox;
