import React from 'react'
import Store from './store'
import QuoteStore from '@quote/store'
import { Instance } from 'mobx-state-tree'
import { Entity } from '@project/types'
import { DateRangePickerProvider } from '@modals/DateRangePicker/DateRangePicker.provider'

const StoreContext = React.createContext<Instance<typeof Store | null>>(null)
const QuoteStoreContext = React.createContext<Instance<typeof QuoteStore> | null>(null)

type StoreProviderProps = {
    store: Instance<typeof Store>
}

type QuoteStoreProviderProps = {
    store: Instance<typeof QuoteStore>
}

export const StoreProvider = ({ children, store }: React.PropsWithChildren<StoreProviderProps>) => {
    return (
        <StoreContext.Provider value={store}>
            <DateRangePickerProvider>{children}</DateRangePickerProvider>
        </StoreContext.Provider>
    )
}

export const QuoteStoreProvider = ({ children, store }: React.PropsWithChildren<QuoteStoreProviderProps>) => {
    return (
        <QuoteStoreContext.Provider value={store}>
            <DateRangePickerProvider>{children}</DateRangePickerProvider>
        </QuoteStoreContext.Provider>
    )
}

export const useStore = (entity: Entity = 'project') =>
    entity === 'project' ? React.useContext<Instance<typeof Store>>(StoreContext as any) : React.useContext<Instance<typeof QuoteStore>>(QuoteStoreContext as any)
