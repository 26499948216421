import React from 'react'
import { Modal as AntModal, Flex } from 'antd'
import styled from 'styled-components'

import { Button } from '@components/UI/Styled/common'
import { Show, Icon } from '@common'

export interface ModalProps {
    title?: string | JSX.Element
    show?: boolean
    okLabel?: string | null
    cancelLabel?: string | null
    loading?: boolean
    children: React.ReactNode
    renderFooter?: React.ReactNode
    contentStyle?: React.CSSProperties
    destroyOnClose?: boolean
    style?: React.CSSProperties
    width?: number
    afterOpenChange?: (open: boolean) => void
    handleClose: () => void
    handleSave?: () => void
    extra?: React.ReactNode
    disableOk?: boolean
}

const Modal: React.FunctionComponent<ModalProps> = props => {
    const {
        show = false,
        handleClose,
        title = '',
        children,
        handleSave,
        okLabel,
        cancelLabel,
        loading,
        destroyOnClose,
        renderFooter = null,
        contentStyle = {},
        style = {},
        width = 700,
        afterOpenChange,
        extra,
        disableOk
    } = props

    return (
        <StyledModal
            open={show}
            title={title}
            onCancel={handleClose}
            onOk={handleSave}
            destroyOnClose={destroyOnClose}
            closeIcon={<Icon name='close' />}
            afterOpenChange={afterOpenChange}
            width={width}
            footer={
                <>
                    <Show condition={Boolean(renderFooter)}>{renderFooter}</Show>
                    <Show condition={!renderFooter}>
                        <Flex justify={extra? 'space-between': 'flex-end'} align='center'>
                            {extra}
                            <div style={{ background: '#F9FAFF' }}>
                                <Show condition={cancelLabel !== null}>
                                    <Button onClick={handleClose} style={{ background: 'none', color: '#979797', textDecoration: 'underline' }}>
                                        {cancelLabel || 'Cancel'}
                                    </Button>
                                </Show>
                                <Show condition={okLabel !== null}>
                                    <Button onClick={handleSave} disabled={loading || disableOk}>
                                        {loading ? 'Loading' : okLabel || 'Save'}
                                    </Button>
                                </Show>
                            </div>
                        </Flex>
                    </Show>
                </>
            }>
            {children}
        </StyledModal>
    )
}

const StyledModal = styled(AntModal)`
    .ant-modal-close-x {
        width: 16px;
        height: 16px;
    }
    .ant-modal-content {
        padding: 0;
    }
    .ant-modal-title {
        padding-top: 20px;
        padding-bottom: 8px;
        border-bottom: 1px solid rgb(224, 231, 255);
        color: rgb(14, 63, 102);
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 15px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .ant-modal-body {
        padding: 0px 20px 0 20px;
    }
    .ant-modal-footer {
        padding: 10px 20px;
        background: #f9faff;
        border-top: 1px solid #dddbda;
        border-radius: 0 0 8px 8px;
        margin-top: 20px;
    }
`
export default Modal
