import React, { Component, useMemo } from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { fetchAllFinancialSettings } from 'service/financialsApi';
import { DATE_FORMAT, DATE_FORMAT_DEFAULT } from 'consts/settings';

import '@scss/components/_datepicker.scss';

const DateInput = props => {
    const {
        id,
        name,
        inputValue,
        dateInputRef,
        inputClassName = 'datepicker__input',
        disabled,

        inputOnClick
    } = props;

    const { data: settings } = useQuery(['settings'], () => fetchAllFinancialSettings());
    const dateFormat = useMemo(() => {
        return settings?.find(x => x?.key === DATE_FORMAT)?.value || DATE_FORMAT_DEFAULT;
    }, [settings]);

    return (
        <input
            id={id}
            name={name}
            type='text'
            disabled={disabled}
            ref={dateInputRef}
            className={inputClassName}
            value={inputValue ? inputValue.format(dateFormat) : ''}
            onClick={inputOnClick}
            readOnly
        />
    );
};

const mapStateToProps = state => {
    return {
        settings: state.settings.settings
    };
};

export default connect(mapStateToProps)(DateInput);
