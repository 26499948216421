import styled from 'styled-components'

export const TitleButton = styled.button<{ error?: boolean; }>`
    border: none;
    outline: none;
    font-weight: 500;
    font-family: Rubik;
    color: #0e3f66;
    text-transform: uppercase;
    background-color: #f5f8ff;
    border: 1px solid #e0e7ff;
    border-bottom: ${({ error }) => (error ? '1px solid #f7685b' : 0)} !important;
    border-radius: 5px 5px 0 0;
    border-color: ${({ error }) => (error ? '#f7685b' : '#e0e7ff')} !important;
    align-self: flex-end;
    font-size: 13px;
    padding: 5px 20px;
    transition: all ease-in-out 0.2s;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:active {
        transform: scale(0.96);
    }
`
export default TitleButton