import service from './service';

export const fetchEntities = async (key, params = {}, errorCallback = () => {}) => {
    try {
        let entityUrl = '/entities';

        if (key) {
            entityUrl += '/' + key;
        }

        const res = await service.get(entityUrl, { params });
        return res.data;
    } catch (err) {
        errorCallback(err);
    }
    return [];
};

export const createEntity = async data => {
    const res = await service.post('/entities/', data);
    return res.data;
};

export const getOneEntity = async id => {
    const res = await service.get(`/entities/${id}`);
    return res.data;
};

export const updateEntity = async data => {
    const res = await service.put(`/entities/${data._id}`, data);
    return res.data;
};

export const deleteEntity = async id => {
    const res = await service.delete(`/entities/${id}`);
    return res.data;
};
