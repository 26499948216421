import { createReducer } from '../../../utils/reducerUtil';
import * as types from './types';
import { _cloneDeep } from '../../../utils/jsonUtil';
const initialState = {
    fields: {},
    views: {},
    lastShown: {},
    defaultView: {},
    loading: true
};

export const loadVisibleFields = (state, payload) => {
    return {
        ...state,
        fields: {
            ...state.fields,
            [payload.key]: payload.fields
        },
        defaultView: {
            ...state.fields,
            [payload.key]: payload.fields
        }
    };
};

export const toggleVisibleFields = (state, payload) => {
    const updatedList = _cloneDeep(state.fields);
    const updatedField = updatedList[payload.key].find(e => e.dataIndex === payload.fields.dataIndex);
    updatedField.hidden = !updatedField.hidden;
    return {
        ...state,
        views: state.views,
        fields: updatedList,
        defaultView: updatedList
    };
};

export const hideAllFields = (state, payload) => {
    const updatedList = _cloneDeep(state.fields);
    updatedList[payload.key].filter(x => x.dataIndex != '' && !Boolean(x.virtual) && !x.alwaysVisible).forEach(x => x.hidden = true)
    return {
        ...state,
        views: state.views,
        fields: updatedList,
        defaultView: updatedList
    };
};

export const showAllFields = (state, payload) => {
    const updatedList = _cloneDeep(state.fields);
    updatedList[payload.key].filter(x => x.dataIndex != '' && !Boolean(x.virtual) && !x.alwaysVisible).forEach(x => x.hidden = false)
    return {
        ...state,
        views: state.views,
        fields: updatedList,
        defaultView: updatedList
    };
};

export const getMyViews = state => {
    return {
        ...state,
        loading: true
    };
};

export const getMyViewsSuccess = (state, payload) => {
    return {
        ...state,
        loading: false,
        views: {
            [payload.key]: payload.views
        },
        lastShown: {
            [payload.key]: payload.lastShown
        }
    };
};

export const getMyViewsFail = state => {
    return {
        ...state,
        loading: false,
    };
};

export const loadMyViews = (state, payload) => {
    return {
        ...state,
        fields: {
            ...state.fields,
            [payload.key]: _cloneDeep(payload.fields.columns)
        },
        lastShown: {
            ...state.lastShown,
            [payload.key]: payload.fields.lastShown
        }
    };
};

export const setMyView = (state, payload) => {
    return {
        ...state,
        loading: true
    };
};

export const setMyViewSuccess = (state, payload) => {
    return {
        ...state,
        loading: false,
        views: {
            ...state.views,
            [payload.key]: payload.views
        }
    };
};

export const setMyViewFail = (state, payload) => {
    return {
        ...state,
        loading: false
    };
};

export const restoreDefaultView = (state, payload) => {
    return {
        ...state,
        fields: {
            ...state.fields,
            [payload.key]: _cloneDeep(payload.defaultColumns)
        },
        lastShown: {
            ...state.lastShown,
            [payload.key]: {}
        }
    }
}

export default createReducer(initialState, {
    [types.TOGGLE_VISIBLE_CUSTOMER_FIELD]: toggleVisibleFields,
    [types.HIDE_ALL_FIELDS]: hideAllFields,
    [types.SHOW_ALL_FIELDS]: showAllFields,
    [types.LOAD_VISIBLE_FIELDS]: loadVisibleFields,
    [types.GET_MY_VIEWS]: getMyViews,
    [types.GET_MY_VIEWS_SUCCESS]: getMyViewsSuccess,
    [types.GET_MY_VIEWS_FAIL]: getMyViewsFail,
    [types.LOAD_MY_VIEWS]: loadMyViews,
    [types.SET_MY_VIEW]: setMyView,
    [types.SET_MY_VIEW_SUCCESS]: setMyViewSuccess,
    [types.SET_MY_VIEW_FAIL]: setMyViewFail,
    [types.RESTORE_DEFAULT_VIEW]: restoreDefaultView
});
