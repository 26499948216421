import React from 'react'
import {Checkbox} from '@common'
import styles from './table.module.scss'
import Show from '@components/UI/Show'

const MultiSelectTable = (props) => {
    const { data, columns = [], style, selected = [], onChange, sort, onSort,
        headerStyle, renderRowMenu, dataProps, gridStyle
    } = props
    const tableData = data?.map(x => ({
        _id: x._id,
        object: x,
        // label: x.name,
        // filter: `${x.plantNo} ${x.name}`
    }))
    const onCheckboxChange = (value, id) => {
        const index = selected.indexOf(id)
        let result;
        if (index < 0) {
            result = [...selected, id]
        } else {
            result = [...selected]
            result.splice(index, 1)
        }
        onChange(result)
    }

    function handleSelectAll() {
        if (selectedAll()) {
            const result = selected.filter(selectedRowId => {
                const row = tableData.find(row => row._id === selectedRowId)
                if (!row) return true
                return false
            })
            onChange(result)
            return
        }
        return onChange([...tableData.map(row => row._id)].filter((_id)=>!dataProps.addedItems?.find(entry=>entry._id === _id)))
    }

    if (!Array.isArray(data) || !Array.isArray(tableData) || !Array.isArray(columns)) {
        return null;
    }

    function selectedAll() {
        let c = 0
        tableData.forEach(row => {
            if (selected.includes(row._id)) c = c + 1
        })
        if (c === 0) return false
        if (c === (tableData.length - (dataProps.addedItems?.length || 0))) return true
        return false
    }

    const gridTemplateColumns = `${onChange?'38px':''} ${columns.map((c) => (c.width ? c.width + 'px' : 'auto')).join(' ')} ${renderRowMenu?'60px':''}`
    
    return (
        <div style={{ position: 'relative', ...style }}>
            <div className={styles['grid-header']} style={{ gridTemplateColumns, ...headerStyle }}>
                <Show condition={onChange}>
                    <div className={styles['grid-header-item']}>
                        <Checkbox
                            onChange={handleSelectAll}
                            checked={selectedAll()}
                        />
                    </div>
                </Show>
                {columns.map((c, i) => (
                    <div 
                        key={i} 
                        className={styles['grid-header-item']} 
                        style={{minWidth: c.width}}
                        onClick={()=>onSort && onSort(c.dataIndex)}
                    >
                        <div>{c.title}</div>
                        <Show condition={onSort}>
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
                                <i className={`${styles["arrow"]} ${styles["up"]} ${(sort?.sort === c.dataIndex && sort?.order === 'desc')?styles["arrow-active"]:''}`}></i>
                                <i className={`${styles["arrow"]} ${styles["down"]} ${(sort?.sort === c.dataIndex && sort?.order === 'asc')?styles["arrow-active"]:''}`}></i>
                            </div>
                        </Show>
                    </div>
                ))}
            </div>
            <div className={styles['grid']} style={{ gridTemplateColumns, ...gridStyle }}>
                {tableData.map((row, i) => (
                    <React.Fragment key={i}>
                        <Show condition={onChange}>
                            <div className={styles['grid-item']}>
                                <Checkbox
                                    id={row._id}
                                    onChange={onCheckboxChange}
                                    checked={selected.includes(row._id)}
                                    disabled={dataProps.addedItems?.find(({_id})=>(row._id===_id))}
                                />
                            </div>
                        </Show>
                        {columns.map((c, i) => (
                            <div key={i} className={styles['grid-item']} style={{ minWidth: c.width, color: dataProps.addedItems?.find(({_id})=>(row._id===_id))?'lightgray': 'inherit'}}>
                                {c.render ? c.render({ row, dataProps }) : (row.object[c.dataIndex] || '')}
                            </div>
                        ))}
                        <Show condition={renderRowMenu}>
                            <div className={styles['grid-item']}>
                                { renderRowMenu && renderRowMenu(row, dataProps) }
                            </div>
                        </Show>
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}

export default MultiSelectTable