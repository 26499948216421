import { useCallback, useState } from 'react'
import {
    Grid,
    GridCellProps,
    GridColumn as Column,
    GridHeaderSelectionChangeEvent,
    GridRowProps,
    GridSelectionChangeEvent,
    GridSortChangeEvent,
    getSelectedState,
    GridHeaderCellProps,
    GRID_COL_INDEX_ATTRIBUTE
} from '@progress/kendo-react-grid'
import { HeaderThElement, useTableKeyboardNavigation } from '@progress/kendo-react-data-tools'
import { getter } from '@progress/kendo-react-common'
import styled, { css } from 'styled-components'

import { Checkbox, Icon, Button } from '@common'

import { TableColumn, TableProps } from '@customTypes/table'
import { Entry } from '@customTypes/entry'

import CustomCell from './CustomCell'
import { CellRender, RowRender } from './renderers'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { DragHandleCell } from './dragHandleCell'
import React from 'react'
import { DragAndDrop } from '@progress/kendo-react-common'

export const LOADING_DATA = new Array(10)
export const DATA_ITEM_KEY: string = '_id'
export const SELECTED_FIELD: string = 'selected'
export const EDIT_FIELD = 'inEdit'
export const EXPANDED_FIELD = 'expanded'

export const idGetter: (value: any) => string = getter(DATA_ITEM_KEY)

type ContextProps = {
    reorder: (dataItem: any, direction: 'before' | 'after' | null) => void
    dragStart: (dataItem: any) => void
}

export const ReorderContext = React.createContext<ContextProps>({
    reorder: () => { },
    dragStart: () => { }
})

const Table = (props: TableProps) => {
    const {
        columns,
        data,
        dataProps,
        onSort,
        sortable = false,
        sort,
        isLoading = false,
        onColumnReorder,
        _grid,
        selected,
        setSelected,
        readOnly,
        detail,
        onColumnResize,
        menu,
        footer,
        style,
        resizable = true,
        filterable = false,
        onFilterChange,
        updateData,
        ...restProps
    } = props

    const [edit, setEdit] = useState<{ _id: string; field: string } | null>(null)
    const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({})
    const [activeItem, setActiveItem] = React.useState<any | null>(null)

    const reorder = (dataItem: any, direction: 'before' | 'after' | null) => {
        if (!updateData) return
        if (activeItem === dataItem) {
            return
        }
        let reorderedData = data.slice()
        let prevIndex = reorderedData.findIndex((p: any) => p._id === activeItem._id)
        let nextIndex = reorderedData.findIndex((p: any) => p._id === dataItem._id) + (direction === 'before' ? -1 : 0)
        if (prevIndex > nextIndex) {
            nextIndex++
        }
        reorderedData.splice(prevIndex, 1)
        reorderedData.splice(nextIndex, 0, activeItem || reorderedData[0])

        updateData(reorderedData)
    }

    const dragStart = (dataItem: any) => {
        setActiveItem(dataItem)
    }

    const onSelectionChange = useCallback(
        (event: GridSelectionChangeEvent) => {
            if (typeof selected === 'undefined' || !setSelected) return
            const newSelectedState = getSelectedState({
                event,
                selectedState: selected,
                dataItemKey: DATA_ITEM_KEY
            })
            setSelected(newSelectedState)
        },
        [selected, data]
    )

    const onHeaderSelectionChange = useCallback((event: GridHeaderSelectionChangeEvent) => {
        if (typeof selected === 'undefined' || !setSelected) return
        const checkboxElement: any = event.syntheticEvent.target
        const checked = checkboxElement.checked
        const newSelectedState: any = {}
        event.dataItems.forEach(item => {
            const id = idGetter(item)
            if (!id) return
            newSelectedState[id] = checked
        })
        setSelected(newSelectedState)
    }, [])

    function onSortChange(event: GridSortChangeEvent) {
        if (sortable && onSort) onSort(event.sort[0])
    }

    const enterEdit = (dataItem: Entry, field: string = '') => {
        setEdit({ _id: dataItem._id || '', field })
    }

    const exitEdit = () => {
        setEdit(null)
    }

    function onExpandChange(props: GridCellProps) {
        const newExpanded = { ...expanded }
        newExpanded[props.dataItem?._id] = !expanded[props.dataItem?._id]
        setExpanded(newExpanded)
    }

    if (!columns || columns.length === 0) return null

    const expandColumn = detail && (
        <Column
            key={'expand'}
            field={EXPANDED_FIELD}
            headerCell={() => <></>}
            resizable={false}
            reorderable={false}
            orderIndex={-2}
            cell={(props: GridCellProps) => (
                <td style={{ cursor: 'pointer' }} className='k-table-td' onClick={() => onExpandChange(props)}>
                    <Icon name='arrow-down' style={{ width: '15px', color: '#0E3F66', transform: `rotate(${props.dataItem?.expanded ? '0deg' : '-90deg'})` }} />
                </td>
            )}
            width={46}
            minResizableWidth={46}
        />
    )

    const checkboxColumn =
        typeof selected !== 'undefined' && restProps.selectable?.enabled !== false ? (
            <Column
                key={'selectAll'}
                field={SELECTED_FIELD}
                width={50}
                minResizableWidth={50}
                className='k-table-td'
                resizable={false}
                reorderable={false}
                orderIndex={-1}
                filterable={false}
                cell={(props: GridCellProps) => {
                    const isSelected = selected[props.dataItem?._id] === true
                    return (
                        <td className='k-table-td'>
                            <Checkbox
                                checked={isSelected}
                                onChange={(onChangeProps: CheckboxChangeEvent) => {
                                    props.selectionChange && props.selectionChange({ syntheticEvent: onChangeProps.nativeEvent as any })
                                }}
                            />
                        </td>
                    )
                }}
                headerCell={(props: GridHeaderCellProps) => {
                    return (
                        <Checkbox
                            checked={props.selectionValue}
                            onChange={(onChangeProps: CheckboxChangeEvent) => {
                                props.selectionChange({ syntheticEvent: onChangeProps.nativeEvent as any })
                            }}
                        />
                    )
                }}
                sortable={false}
                headerSelectionValue={data?.findIndex(item => typeof selected !== 'undefined' && !selected[idGetter(item)]) === -1 && (data?.length || 0) > 0}
            />
        ) : null

    const loadingColumns = [
        expandColumn,
        checkboxColumn,
        ...columns
            .filter(c => !c.hidden)
            .map((column, i) => (
                <Column
                    key={column.id}
                    field={column.dataIndex}
                    title={(column.title as string) || column.defaultTitle}
                    width={column.width}
                    orderIndex={column.order || undefined}
                    filterable={false}
                    cell={() => (
                        <td className='k-table-td'>
                            <div className='k-table-loading'></div>
                        </td>
                    )}
                />
            )),
        <Column key='empty' field='' width='auto' resizable={false} reorderable={false} orderIndex={1000} />
    ]

    const Cell = (props: GridCellProps & { column: TableColumn }) => {
        const column = props.column
        const navigationAttributes = useTableKeyboardNavigation(props.id)
        return (
            <StyledColumn
                // props.style applies styles that lock the column at a specific position
                style={props.style}
                className={props.className} // this adds classes needed for locked columns
                colSpan={props.colSpan}
                role='gridcell'
                background={props.dataItem?.extraRowProps?.style?.background}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
                {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
                {...navigationAttributes}>
                {props.render && props.render(<CustomCell {...{ ...props, column, enterEdit, dataProps, edit, readOnly }} />, props)}
            </StyledColumn>
        )
    }

    const getColumns = (column: TableColumn, i: number) => (
        <Column
            key={column.id}
            field={column.dataIndex}
            title={(column.title as string) || column.defaultTitle || ' '}
            width={column.width}
            sortable={column.sort}
            locked={Boolean(column.pinned)}
            headerCell={
                column.header &&
                (props => (
                    <span className='k-cell-inner' style={{ justifyContent: column.align }}>
                        <span className='k-link'>
                            <span className='k-column-title'>{column.header(props)}</span>
                        </span>
                    </span>
                ))
            }
            orderIndex={column.order || undefined}
            filter={column.filterType}
            filterable={column.filterable}
            editable={Boolean(column.editable)}
            filterCell={column.filterCell}
            cell={props => <Cell {...props} column={column} />}>
            {column.columns?.map(getColumns)}
        </Column>
    )

    const dragColumn = <Column key='dragKey' title='' width='50px' cell={DragHandleCell} />

    const viewColumns = [expandColumn, checkboxColumn, ...columns.filter(c => !c.hidden).map(getColumns)]

    if (updateData) viewColumns.unshift(dragColumn)

    viewColumns.push(<Column key='empty' field='empty' title=' ' width='auto' filterable={false} sortable={false} resizable={false} reorderable={false} orderIndex={1001} />)
    if (menu) viewColumns.push(<Column key='menu' field='menu' title=' ' width={50} resizable={false} reorderable={false} orderIndex={1000} />)

    const customCellRender: any = (td: React.ReactElement<HTMLTableCellElement>, props: GridCellProps) => {
        if (menu && props.field === 'menu') return <td className='k-table-td'>{menu(props)}</td>
        return <CellRender originalProps={props} td={td} enterEdit={enterEdit} editField={EDIT_FIELD} />
    }

    const customRowRender: any = (tr: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
        if (footer && props.dataItem.type === 'footer')
            return (
                <tr className='k-table-row k-master-row'>
                    <td colSpan={(viewColumns || []).length} style={{ padding: 0 }}>
                        {footer(props)}
                    </td>
                </tr>
            )
        return <RowRender elementProps={tr.props} tr={tr} exitEdit={exitEdit} editField={EDIT_FIELD} {...props} />
    }

    const updatedData =
        data?.map((d: any) => ({
            ...d,
            [SELECTED_FIELD]: selected && selected[d._id],
            [EXPANDED_FIELD]: expanded[d._id]
        })) || []

    if (footer) updatedData.push({ type: 'footer' })

    return (
        <ReorderContext.Provider value={{ reorder: reorder, dragStart: dragStart }}>
            <DragAndDrop>
                <StyledGrid
                    {...restProps}
                    resizable={resizable}
                    filterable={filterable}
                    onFilterChange={onFilterChange}
                    onColumnResize={onColumnResize}
                    data={isLoading ? LOADING_DATA : updatedData}
                    sortable={Boolean(onSort)}
                    onSortChange={onSortChange}
                    sort={Boolean(onSort) ? [{ field: sort?.field || 'created', dir: sort?.order }] : undefined}
                    reorderable={true}
                    onColumnReorder={onColumnReorder}
                    ref={_grid}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: false,
                        drag: false,
                        cell: false,
                        mode: 'multiple'
                    }}
                    onSelectionChange={onSelectionChange}
                    onHeaderSelectionChange={onHeaderSelectionChange}
                    cellRender={customCellRender}
                    rowRender={customRowRender}
                    detail={detail}
                    expandField={EXPANDED_FIELD}
                    style={style}>
                    {isLoading ? loadingColumns : viewColumns}
                </StyledGrid>
            </DragAndDrop>
        </ReorderContext.Provider>
    )
}

export default Table

const resetTableStyles = `
    table,
    thead,
    tbody,
    tfoot,
    tr,
    th,
    td {
        margin: 0;
        padding: 0;
        border: none;
        border-collapse: inherit;
        border-spacing: 0;
        border-color: inherit;
        vertical-align: middle;
        text-align: left;
        font-weight: inherit;
        -webkit-border-horizontal-spacing: 0;
        -webkit-border-vertical-spacing: 0;
    }
`

export const StyledGrid = styled(Grid)`
    ${resetTableStyles}
    ${({ style }) =>
        (style?.height &&
            css`
                height: calc(100vh - ${style?.height}px) !important;
            `) ||
        ''}
    table {
        border-spacing: 0 !important;
    }
    .k-checkbox:checked {
        background-color: #ff8939 !important;
    }
    tr.k-table-row {
        background-color: white;
    }
    tr.k-table-row:hover {
        background-color: white;
    }
    .k-selected td {
        background-color: white !important;
    }
    tr.k-table-row:nth-child(even) {
        background-color: white;
    }
    tr.k-table-row:hover(even) {
        background-color: white;
    }
    .k-table-loading {
        width: 100%;
        height: 10px;
        background-image: linear-gradient(to right, transparent 50%, rgba(0, 0, 0, 0.05) 50%);
        background-size: 200% 100%;
        animation: loading 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
    @keyframes loading {
        0% {
            background-position: 0;
        }
        50% {
            background-position: -30%;
        }
        80% {
            background-position: -100%;
        }
        100% {
            background-position: -200%;
        }
    }

    .k-table-th {
        vertical-align: middle !important;
    }
    td.k-table-td.expand {
        width: 30px;
        padding: 0;
        text-align: center;
        justify-content: center;
        display: flex;
        align-items: center;

        &.cursor-pointer {
            cursor: pointer;
        }
    }
    .k-table-td,
    .k-grid-content-sticky {
        border: none !important;
        border-bottom: 1px solid lightgray !important;
    }
    .k-grid-header {
        padding-right: 0;
        th,
        .k-table-th.k-grid-header-sticky,
        th.k-grid-header-sticky.left {
            color: #0e3f66;
            font-weight: 500;
            padding: 0px 15px;
            height: 50px;
            background: #fff;
            border-right: 1px solid #f7f8fc;
            border-left: 1px solid #f7f8fc;
        }
        .k-table-th.k-grid-header-sticky {
            color: #0e3f66;
        }
    }
    .k-grid-content {
        overflow-y: auto;
        /* margin-right: -8px; */

        ::-webkit-scrollbar-thumb:hover {
            background-color: #AFB3C4
        }

        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
            
        ::-webkit-scrollbar-thumb {
            background-color: #E2E5EC;
        }
    }
    .k-grid-container {
        td {
            background-color: white;
            padding: 0px 15px;
            height: 50px;
        }
    }
    .k-sort-icon {
        color: #0e3f66;
    }
    .k-column-title {
        white-space: break-spaces;
    }

    td.actions.right {
        padding: 0;
        margin: 0;
        display: inline-flex;
        border: none !important;
        border-top: 1px solid #ecedf2 !important;
        border-bottom: 1px solid #ecedf2 !important;
        background: #fff;
        padding-block: 0;
        padding-inline: 0;
        box-shadow: -2px 0px 10px 0px rgba(166, 171, 189, 0.3);
    }

    td.actions.right > div {
        justify-content: center !important;
        padding: 0 !important;
        margin: 0 !important;
        width: 25px !important;
        background: #fff;
        border: none;
    }

    th.actions.right {
        background: #fff;
        display: block;
        border: none;
        opacity: 0;
        visibility: hidden;
    }

    th.k-grid-header-sticky.left:has(+ th:not(.k-grid-header-sticky)),
    td.k-grid-content-sticky.left:has(+ td:not(.k-grid-content-sticky)) {
        box-shadow: 10px 0 5px -2px rgba(228, 229, 238, 0.6);
        border-right-color: transparent;
    }

    .k-filtercell-operator > button {
        display: none;
    }
`

export const TableControls = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
    button {
        font-weight: 700;
    }
`

export const ControlBtn = styled(Button)`
    font-size: 12px;
    font-weight: 700;
    padding-left: 0;
    padding-right: 0;
    width: 127px;
    justify-content: center;
    align-items: center;
`

export const TableControlsItem = styled.div`
    display: flex;
    gap: 20px;
`

export const StyledTh = styled(HeaderThElement)`
    background-color: white !important;
    border: none !important;
    border-bottom: 1px solid lightgray !important;
    &:hover {
        background-color: white !important;
    }
    &.k-grid-content-sticky {
        background-color: #fafafa !important;
    }
`

export const StyledExpandTh = styled(HeaderThElement)`
    background-color: white !important;
    border: none !important;
    border-bottom: 1px solid lightgray !important;
    &:hover {
        background-color: white !important;
    }
    &.k-grid-content-sticky {
        background-color: #fafafa !important;
    }
`

export const StyledColumn = styled.td<any>`
    background-color: ${({ background }) => background || 'white'} !important;
    border: none !important;
    border-bottom: 1px solid lightgray !important;
    &:hover {
        background-color: ${({ background }) => background || 'white'} !important;
    }
    &.k-grid-content-sticky {
        background-color: ${({ background }) => background || 'white'} !important;
    }
`
