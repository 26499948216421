export const options = {
    pickedUp: true,
    templateId: 'template Id placeholder',
    plantsInfo: []
};

export const data = {
    displayFields: {
        product: true,
        qty: true,
        uom: true,
        price: true,
        transportCostPerUnit: true,
        deliveredUnitPrice: true,
        deliveredUnitPriceInclTax: true,
        totalPickedUpPrice: true,
        totalTransport: true,
        totalDelivered: true,
        totalPickedUpPriceInclTax: true,
        totalDeliveredInclTax: true,
        taxApplied: true
    },
    expirationDate: '2023-02-02T15:04:58.889Z',
    dateFormat: 'MM-DD-YYYY',
    customer: {
        name: 'Denys',
        addresses: [{ line: 'street', city: 'city', zip: 'zip', country: 'country' }]
    },
    quoteNo: 'quoteNo',
    project: {
        name: 'Project name'
    },
    address: {
        line: 'street',
        city: 'city',
        zip: 'zip'
    },
    quoteEntries: [
        {
            _id: '634d600e2340114ecf671c86',
            qty: 5600,
            transportPrice: 0,
            competitorData: [],
            wonLoss: true,
            taxApplied: false,
            product: {
                taxExempt: false,
                _id: '61ba2d8f864b143c054ea1e1',
                productNo: '105',
                name: 'LIME 1',
                productType: 'Aggregates',
                uom: {
                    key: 't',
                    value: 'T'
                },
                category1: '61ba2c2cf2ec6c049f706a9f'
            },
            priceIndicators: {
                category: {
                    min: 4,
                    max: 35
                },
                tierPriceRange: {
                    min: 0,
                    max: 22
                },
                _id: '634d60a34a08735b8e926967',
                lastQuotedPrice: 18,
                averageQuotedPrice: null,
                listPrice: 0,
                productionCost: 39
            },
            price: 18
        },
        {
            _id: '634d600e2340114ecf671c86',
            qty: 5600,
            transportPrice: 0,
            competitorData: [],
            wonLoss: true,
            taxApplied: false,
            product: {
                taxExempt: false,
                _id: '61ba2d8f864b143c054ea1e1',
                productNo: '105',
                name: 'LIME 1',
                productType: 'Aggregates',
                uom: {
                    key: 't',
                    value: 'T'
                },
                category1: '61ba2c2cf2ec6c049f706a9f'
            },
            priceIndicators: {
                category: {
                    min: 4,
                    max: 35
                },
                tierPriceRange: {
                    min: 0,
                    max: 22
                },
                _id: '634d60a34a08735b8e926967',
                lastQuotedPrice: 18,
                averageQuotedPrice: null,
                listPrice: 0,
                productionCost: 39
            },
            price: 18
        },
        {
            _id: '634d600e2340114ecf671c86',
            qty: 5600,
            transportPrice: 0,
            competitorData: [],
            wonLoss: true,
            taxApplied: false,
            product: {
                taxExempt: false,
                _id: '61ba2d8f864b143c054ea1e1',
                productNo: '105',
                name: 'LIME 1',
                productType: 'Aggregates',
                uom: {
                    key: 't',
                    value: 'T'
                },
                category1: '61ba2c2cf2ec6c049f706a9f'
            },
            priceIndicators: {
                category: {
                    min: 4,
                    max: 35
                },
                tierPriceRange: {
                    min: 0,
                    max: 22
                },
                _id: '634d60a34a08735b8e926967',
                lastQuotedPrice: 18,
                averageQuotedPrice: null,
                listPrice: 0,
                productionCost: 39
            },
            price: 18
        },
        {
            _id: '634d600e2340114ecf671c86',
            qty: 5600,
            transportPrice: 0,
            competitorData: [],
            wonLoss: true,
            taxApplied: false,
            product: {
                taxExempt: false,
                _id: '61ba2d8f864b143c054ea1e1',
                productNo: '105',
                name: 'LIME 1',
                productType: 'Aggregates',
                uom: {
                    key: 't',
                    value: 'T'
                },
                category1: '61ba2c2cf2ec6c049f706a9f'
            },
            priceIndicators: {
                category: {
                    min: 4,
                    max: 35
                },
                tierPriceRange: {
                    min: 0,
                    max: 22
                },
                _id: '634d60a34a08735b8e926967',
                lastQuotedPrice: 18,
                averageQuotedPrice: null,
                listPrice: 0,
                productionCost: 39
            },
            price: 18
        }
    ],
    userResponsible: {
        firstName: 'User',
        lastName: 'Responsiable',
        directPhone: '+1 00 000 00 00',
        phone: '+1 00 000 00 00',
        fax: 'fax:000',
        email: 'user@gmail.com'
    }
};

export const currencyFormat = num => {
    const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'usd'
    };
    let x = !num || Number.isNaN(num) ? 0 : num;
    return Number(x).toLocaleString('dec-0.1' === 'dec-0.1' ? 'en' : 'de', options);
};