import '@scss/panels/_multiple-header.scss';
import React from 'react';

const MultipleHeader = React.forwardRef(({ children, style = {} }, ref) => {
    return (
        <div ref={ref} className='MultipleHeader' style={style}>
            {children.map((comp, idx) => (
                <div key={idx} className='MultipleHeader__component'>
                    {comp}
                </div>
            ))}
        </div>
    );
});

export default MultipleHeader;
