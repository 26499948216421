import React from 'react';

import '@scss/components/_widgetStats.scss';

export default function WidgetStats({ title, value, sub, align }) {
    return (
        <div className='WidgetStats'>
            <h4 className='WidgetStats__Title'>{title}</h4>
            <p className={`WidgetStats__Value ${align}`}>
                {value}
                {sub && <sub>{sub}</sub>}
            </p>
        </div>
    );
}
