import styled from 'styled-components'

const Loader = ({ width, height }: { width?: string; height?: string }) => {
    return (
        <LoaderWrapper height={height}>
            <Spinner width={width} />
        </LoaderWrapper>
    )
}

const LoaderWrapper = styled.div<{ height?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${props => (props.height ? props.height : '80%')};
`

const Spinner = styled.div<{ width?: string }>`
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: ${props => (props.width ? props.width : '40px')};
    height: ${props => (props.width ? props.width : '40px')};
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  `

export default Loader
