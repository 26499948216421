import React from 'react'
import { Button, CollapsiblePanel, Feather } from '@components'
import { MapImage } from '../../Components/NewMapImage/index'
import { Show, Icon } from '@common'

const LocationDetails = ({ title = '', addressType = 'delivery', lat = 0, readOnly = false, lng = 0, onMapClick = () => { }, warning = '' }) => {
    return (
        <CollapsiblePanel title={title} style={{ paddingBottom: 0 }}>
            <div style={{ marginTop: '30px' }}>
                <div className='mb-20'>Leave a pin on the map or type the specific address manually in the {addressType} address.</div>
                <div>
                    <MapImage lng={lng} lat={lat} zoom={10} />
                </div>
                <div className='mt-30'>
                    <Button disabled={readOnly} customClass='btnold--transparent center' ripple={{ color: '#0e3f66', duration: 550 }} onClick={onMapClick}>
                        <Feather name='Map' />
                        <span>Map</span>
                    </Button>
                </div>
                <Show condition={warning}>
                    <div style={{ marginTop: '20px', color: 'red', display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                        <div>
                            <Icon name='warning' style={{ color: 'red', width: '20px', height: '20px' }} />
                        </div>
                        <div>{warning}</div>
                    </div>
                </Show>
            </div>
        </CollapsiblePanel>
    )
}

export default LocationDetails
