import React from 'react';
import '@scss/components/_won-lost-tag.scss';

const WonLostTag = ({ type, active = true, onClick = () => {} }) => {
    return (
        <div className={`WonLostTag ${active ? `active ${type === 'won' ? 'won' : 'loss'}` : ''}`} onClick={onClick}>
            <div className={`WonLostTag__corner WonLostTag__corner--left ${type === 'won' ? 'won' : 'loss'}`}></div>
            <span>{type === 'won' ? 'Won' : 'Loss'}</span>
            <div className={`WonLostTag__corner WonLostTag__corner--right ${type === 'won' ? 'won' : 'loss'}`}></div>
        </div>
    );
};

export default WonLostTag;
