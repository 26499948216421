import React from 'react';
import styled from 'styled-components';

import '@scss/components/_progressbar.scss';

const Bar = styled.div`
    width: ${p => p.percent}%;
`;

const ProgressBar = ({ value = 0, label = '', centered = false, className = '' }) => {
    return (
        <div className={`Progressbar ${centered ? 'centered' : 'left-align'} ${className}`}>
            <Bar className='Progressbar__percent' percent={value} />
            <span className={`Progressbar__text ${centered ? '' : 'Progressbar__text--absolute'}`}>
                {label} {Math.round(value)}%
            </span>
        </div>
    );
};

export default ProgressBar;
