import React, { useEffect, useMemo, useRef, useState } from 'react'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { LabeledTextField } from '@components'
import { parseAddressComponent } from '@utils/googlemap'
import { useOnClickOutside } from 'utils/onClickOutside'
import { geocoding } from 'service/googleMapApi'
import '@scss/panels/_address-panel.scss'
import { isoCountryCodes } from '@constants/isoCountryCodes'
import { Flex } from 'antd'
import { useUOM } from '@hooks/useUOM'
import { kmToMiles } from 'utils/unit'
import { Show } from '@common'
import { numberWithoutDecimals } from 'utils/regexUtils'

const AddressPanel = (props: any) => {
    const {
        formErrors,
        readOnly = false,
        noBorder = false,
        address,
        showDeliveryInstructions = false,
        showlatLng = false,
        enableGeolocator = false,
        autoFill = false,
        readOnlyItems = {},
        isImported = false,
        onChange = () => {},
        onComplite = () => {},
        historyVersion = false,
        plants = []
    } = props
    const plantAddress = plants[0]?.address

    const { getRouteUnitByMeasure } = useUOM()
    const unit = getRouteUnitByMeasure()?.label || ''

    const countryCode = useMemo(() => {
        const plantCountry = plantAddress?.country
        if (!plantCountry) return
        const codeCountry = Object.entries(isoCountryCodes).find(([key, value]) => value.includes(plantCountry))
        if (!codeCountry) return
        const code = codeCountry[0].toLowerCase()
        return code
    }, [plants])

    const [openVisibleDropdown, setOpenVisibleDropdown] = useState(false)
    const [addressLine, setAddressLine] = useState(address?.line || '')
    const [zip, setZip] = useState(address?.zip || '')

    useEffect(() => {
        setZip(address?.zip)
    }, [address?.zip])

    const options: google.maps.places.AutocompletionRequest = {
        input: ''
    }
    if (countryCode) {
        options.componentRestrictions = { country: countryCode }
    }
    if (plantAddress) {
        options.origin = { lat: plantAddress.lat, lng: plantAddress.lng }
        options.location = new google.maps.LatLng({ lat: plantAddress.lat, lng: plantAddress.lng })
        options.radius = 10000
    }

    const prevDebounce = useRef<number | undefined>()

    const getDebounceValue = (): number => {
        const value = Math.floor(Math.random() * 100)
        if (value === prevDebounce.current) return getDebounceValue()
        prevDebounce.current = value
        return value
    }

    const debounce = useMemo(getDebounceValue, [plantAddress?.region])

    const { placePredictions, getPlacePredictions } = usePlacesService({
        apiKey: process.env.GOOGLE_MAP_API,
        language: 'en',
        options,
        debounce
    })

    const onAddressResult = async (item: google.maps.places.AutocompletePrediction) => {
        const { results } = await geocoding({ place_id: item.place_id })
        const {
            address_components,
            formatted_address,
            geometry: { location }
        } = results[1] || results[0]
        const address = parseAddressComponent(address_components, formatted_address)
        onComplite({ _id: props.address?._id, ...address, ...location })
        onChange({ _id: props.address?._id, ...address, ...location })
        setAddressLine({ ...address, ...location }?.line || '')
    }

    const visible_ref = useRef(null)

    useOnClickOutside(visible_ref, () => {
        onComplite(props.address)
        setOpenVisibleDropdown(false)
    })

    const handleLocationFocus = () => {
        if (placePredictions?.length) {
            setOpenVisibleDropdown(true)
        }
    }

    return (
        <div>
            <div className='row'>
                {enableGeolocator ? (
                    <div className='col-md-8'>
                        <LabeledTextField
                            autoFill={(autoFill && readOnly) || (isImported && readOnlyItems?.addresses?.readOnly)}
                            onFocus={handleLocationFocus}
                            noBorder={noBorder}
                            readOnly={readOnly}
                            label={'Street Address'}
                            id='addresses'
                            formErrors={formErrors}
                            placeholder={'Add street Address'}
                            value={address?.line || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleLocationFocus()
                                onChange({ ...address, line: e.target.value })
                                getPlacePredictions({ input: e.target.value })
                            }}
                            dataCy='streetAddress'
                        />
                        {openVisibleDropdown && (
                            <div ref={visible_ref} className='searchPageResults' style={{ marginTop: '-16px' }}>
                                {placePredictions.length > 0 ? (
                                    <ul className='searchList'>
                                        {placePredictions
                                            ?.sort((a, b) => (a.distance_meters || 9999999999) - (b.distance_meters || 99999999999))
                                            .map((item, index) => (
                                                <li
                                                    key={`search-${index}`}
                                                    className={`searchListItem`}
                                                    onClick={() => {
                                                        onAddressResult(item)
                                                        setOpenVisibleDropdown(false)
                                                    }}>
                                                    <Flex gap={8}>
                                                        {item.description}
                                                        <Show condition={Boolean(item.distance_meters)}>
                                                            <div style={{ background: '#dbedff', borderRadius: 6, padding: '0px 5px', color: '#0e3f6699' }}>
                                                                {(unit !== 'km'
                                                                    ? kmToMiles((item.distance_meters as number) / 1000)
                                                                    : Math.ceil((item.distance_meters as number) / 1000)) || ''}{' '}
                                                                {unit}
                                                            </div>
                                                        </Show>
                                                    </Flex>
                                                </li>
                                            ))}
                                    </ul>
                                ) : (
                                    <p>No results found</p>
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    <div className='col-md-4'>
                        <LabeledTextField
                            autoFill={readOnly}
                            readOnly={readOnly}
                            noBorder={noBorder}
                            label={'Street address'}
                            placeholder={'Add street address'}
                            value={address?.line || ''}
                            dataCy='streetAddress'
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                onChange({ ...address, line: e.target.value })
                            }}
                        />
                    </div>
                )}
                <div className='col-md-4'>
                    <LabeledTextField
                        autoFill={readOnly}
                        readOnly={readOnly}
                        noBorder={noBorder}
                        label={'ZIP'}
                        placeholder={'Add ZIP'}
                        value={zip}
                        onTextBlur={() => {
                            onChange({ ...address, zip })
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const value = numberWithoutDecimals(e.target.value) || ''
                            // if (value.length > 6) return
                            setZip(value)
                        }}
                        data-cy='zip'
                    />
                </div>
                <div className='col-md-4'>
                    <LabeledTextField
                        autoFill={true}
                        readOnly={readOnly}
                        noBorder={noBorder}
                        label={'City'}
                        placeholder={'Add city'}
                        value={address?.city ? address.city : autoFill ? '-' : ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onChange({ ...address, city: e.target.value })
                        }}
                        data-cy='city'
                    />
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-md-4'>
                    <LabeledTextField
                        autoFill={true}
                        readOnly={readOnly}
                        noBorder={noBorder}
                        label={'County'}
                        placeholder={'Add county'}
                        value={address?.county ? address.county : autoFill ? '-' : ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onChange({ ...address, county: e.target.value })
                        }}
                        data-cy='country'
                    />
                </div>
                <div className='col-md-4'>
                    <LabeledTextField
                        autoFill={true}
                        readOnly={readOnly}
                        noBorder={noBorder}
                        label={'State'}
                        placeholder={'Add state'}
                        value={address?.state ? address.state : autoFill ? '-' : ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onChange({ ...address, state: e.target.value })
                        }}
                        data-cy='state'
                    />
                </div>
                <div className='col-md-4'>
                    <LabeledTextField
                        autoFill={true}
                        readOnly={readOnly}
                        noBorder={noBorder}
                        label={'Country'}
                        placeholder={'Add country'}
                        value={address?.country ? address.country : autoFill ? '-' : ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onChange({ ...address, country: e.target.value })
                        }}
                        data-cy='country'
                    />
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-md-4'>
                    <LabeledTextField
                        autoFill={true}
                        readOnly={readOnly}
                        noBorder={noBorder}
                        label={'Region'}
                        placeholder={'Add region'}
                        value={address?.region ? address.region : autoFill ? '-' : ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onChange({ ...address, region: e.target.value })
                        }}
                        data-cy='region'
                    />
                </div>
                {showlatLng && (
                    <div className='col-md-4'>
                        <LabeledTextField
                            autoFill={true}
                            id='latLng'
                            formErrors={formErrors}
                            readOnly={readOnly}
                            noBorder={noBorder}
                            label={'Latitude'}
                            placeholder={'Add latitude'}
                            value={address?.lat ? address.lat : autoFill ? '-' : ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                onChange({ ...address, lat: e.target.value })
                            }}
                            data-cy='latitude'
                        />
                    </div>
                )}
            </div>
            {showlatLng && (
                <div className='row mt-3'>
                    <div className='col-md-4'>
                        <LabeledTextField
                            autoFill={true}
                            id='latLng'
                            formErrors={formErrors}
                            readOnly={readOnly}
                            noBorder={noBorder}
                            label={'Longitude'}
                            placeholder={'Add longitude'}
                            value={address?.lng ? address.lng : autoFill ? '-' : ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                onChange({ ...address, lng: e.target.value })
                            }}
                            data-cy='longitude'
                        />
                    </div>
                </div>
            )}
            {showDeliveryInstructions && (
                <div className='row mt-3'>
                    <div className='col'>
                        <LabeledTextField
                            id='note'
                            autoFill={historyVersion}
                            formErrors={formErrors}
                            readOnly={historyVersion || readOnly}
                            noBorder={noBorder}
                            label={'Delivery Instructions'}
                            placeholder={'Add delivery Instructions'}
                            value={address?.note}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                onChange({ ...address, note: e.target.value })
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default AddressPanel
