import React from 'react';

import { Button, CollapsiblePanel, Feather, DataPair } from '@components';

import './style.scss';

const rippleProps = {
    color: '#0e3f66',
    duration: 550
};

const CompetitorInfo = props => {
    const {
        value = [],
        readOnly,

        onModalOpenClick = () => {}
    } = props;

    const openCompetitorModal = () => {
        if (readOnly) {
            return;
        }

        onModalOpenClick();
    };

    return (
        <CollapsiblePanel title='Competitor info'>
            <div className='CompetitorInfo'>
                <p>Please choose a competitor to determine Proximity Advantage and analyse Won & Lost</p>
                <div className='mt-20 flex-start'>
                    <Button disabled={readOnly} onClick={openCompetitorModal} customClass='btnold--collapse center' ripple={rippleProps}>
                        <Feather name='Target' />
                        <span>Competitor</span>
                    </Button>
                </div>
                {value.map((pl, idx) => (
                    <DataPair key={idx} titleOne={`competitor ${idx + 1}`} titleTwo='competitor plant' valueOne={pl?.competitor?.name} valueTwo={pl?.name} />
                ))}
            </div>
        </CollapsiblePanel>
    );
};

export default CompetitorInfo;
