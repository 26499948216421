import React from 'react';

import { Radio } from '@components';

import '@scss/components/_switcher.scss';

const Switcher = (props) => {
    const {
        selected,
        name,
        options = [],

        onChange = () => { },
    } = props;

    return (
        <ul className='Switcher'>
            {Object.values(options).map((o, index) => (
                <li key={`radio-switcher-${o.value}`}>
                    <Radio
                        id={`radio-switcher-${o.value}`}
                        label={o.label}
                        name={name}
                        value={o.value}
                        checked={selected === o.value}
                        onChange={onChange}
                        switcher
                    />
                    {(index === 0 && options.length > 1) ? <div className='border' /> : null}
                </li>
            ))}
        </ul>
    );
};

export default Switcher;
