import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { DateRange } from 'react-day-picker'

export type DateRangeType = DateRange & { field?: string | null }

interface DateRangePickerContextType {
    isDateRangePickerOpened: boolean
    setIsDateRangePickerOpened: (value: boolean) => void
    dateRange: DateRangeType | undefined
    setDateRange: (value: DateRangeType | undefined) => void
    title: string
}

const DateRangePickerContext = createContext<DateRangePickerContextType | null>(null)

interface Props {
    children: ReactNode
}

export const DateRangePickerProvider = ({ children }: Props) => {
    const defaultRange: DateRange = {
        from: undefined,
        to: undefined
    }

    const [dateRange, setDateRange] = useState<DateRangeType | undefined>(defaultRange)
    const [isDateRangePickerOpened, setIsDateRangePickerOpened] = useState(false)
    const [title, setTitle] = useState('')

    const value = {
        isDateRangePickerOpened,
        setIsDateRangePickerOpened,

        dateRange,
        setDateRange,

        title
    }

    useEffect(() => {
        setTitle(!dateRange?.from || !dateRange?.to ? 'Create period' : 'Edit period')
    }, [dateRange?.from, dateRange?.to])

    return <DateRangePickerContext.Provider value={value}>{children}</DateRangePickerContext.Provider>
}

export const useDateRangePickerState = (): DateRangePickerContextType => {
    const context = useContext(DateRangePickerContext)

    if (context === null) {
        throw new Error(`Received null while calling useContext(DateRangePickerContext), did you forget to put the provider?`)
    }

    return context
}
