import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Divider, Feather, Image } from '@components';
import '@scss/components/_collapsiblePanel.scss';

export default ({ title, opened = false, fixed = true, children, icon = null, style = {} }) => {
    const [isOpen, setIsOpen] = useState(opened);
    const [elementHeight, setElementHeight] = useState(0);
    const elementTitle = useRef(null);
    const elementPanel = useRef(null);

    useEffect(() => {
        if (!elementTitle.current || !elementPanel.current) return;
        const elStyles = getComputedStyle(elementPanel.current, null);
        const paddingTop = elStyles.getPropertyValue('padding-top');
        const paddingBottom = elStyles.getPropertyValue('padding-bottom');
        setElementHeight(elementTitle.current.offsetHeight + parseInt(paddingTop) + parseInt(paddingBottom));
    }, [elementTitle, isOpen]);

    const toggle = useCallback(() => {
        if (fixed) {
            return;
        }
        setIsOpen(curr => !curr);
    }, [setIsOpen]);

    return (
        <div className='CollapsiblePanel' ref={elementPanel} style={isOpen || fixed ? { ...style } : { maxHeight: `${elementHeight}px`, transition: '0.3s ease', ...style }}>
            <div
                className={`noselect CollapsiblePanel__title ${!isOpen ? 'animateChevron' : null}`}
                style={fixed ? { cursor: 'default' } : null}
                ref={elementTitle}
                onClick={toggle}>
                <div>{icon && <Image className='main-icon' src={icon} />}</div>
                <span>{title}</span>
                {!fixed && <Feather name={`${'ChevronDown'}`} className={`icon ${!isOpen ? 'icon--rotate' : ''}`} />}
            </div>
            <div className={`opac ${isOpen || fixed ? '' : 'inactive'}`}>
                <Divider full />
            </div>
            <div className='CollapsiblePanel__content'>{children}</div>
        </div>
    );
};
