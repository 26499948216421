import { forwardRef } from 'react';

const DropdownToggle = forwardRef(({ children, onClick }, ref) => (
    <a
        href=''
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}>
        {children}
    </a>
));

export default DropdownToggle;