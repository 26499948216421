import React from 'react'
import { Popover } from "@nextui-org/react";
import styled from 'styled-components';

const Popup = ({content, trigger, placement, style}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <Popover disableAnimation placement={placement || "bottom-right"} isOpen={isOpen} onOpenChange={setIsOpen}>
            <Popover.Trigger>
                { trigger }
            </Popover.Trigger>
            <Popover.Content css={{overflow: 'visible', marginBottom: '10px'}}>
                <PopupContent style={style}>
                    { content }
                </PopupContent>
            </Popover.Content>
        </Popover>
    )
}

const PopupContent = styled.ul`
    padding: 10px;
    background-color: #0E3F66;
    color: white;
    overflow: visible;
    border-radius: 4px;
    :before {
        content:"";
        border-radius: 0;
        border-style: solid;
        border-width: 10px 15px 10px 0;
        border-color: transparent #0E3F66 transparent transparent;
        position: absolute;
        bottom: -16px;
        left: 46%;
        overflow: visible;
        transform: rotate(-90deg);
        border-radius: 5px;
    }
`

export default Popup