import React, { useEffect, useState, useRef } from 'react'

import { useQuery } from '@tanstack/react-query'

import { useOnClickOutside } from 'utils/onClickOutside'

import { PieChart, StatusBadge } from '@components'

import Repository from 'service/Repository'

import PROGRESS from '@panels/QuotesHeader/const'

import { LocationDetailsTooltipModal } from './styled'

import '@scss/panels/_location-details-tooltip.scss'

const LocationDetailsTooltip = ({ show = false, left, onChildRef = React.createRef(), top, quote, handleClose = () => {}, overflow }) => {
    const [originFix, setOriginFix] = useState()
    const originFixRef = useRef()

    const apiService = new Repository('/plants')
    const { data: plants } = useQuery(['/plants'], () => apiService.getAll(), {
        select: res => res.data
    })

    useEffect(() => {
        setOriginFix(originFixRef.current.getBoundingClientRect())
    }, [])

    useOnClickOutside(onChildRef, () => handleClose())

    const competitors = () => {
        let tempUniqueCompetitors = []
        if (quote?.competitorPlants?.length > 0) {
            const competingPlants = plants?.filter(p => quote.competitorPlants.some(cp => cp === p._id))
            competingPlants?.forEach(plant => {
                if (!tempUniqueCompetitors.some(uc => uc._id === plant.competitor._id)) {
                    tempUniqueCompetitors.push({ competitor: plant.competitor, plant: plant.name })
                }
            })
        }
        return tempUniqueCompetitors
    }

    const progress = quote => {
        if (quote?.currentProgress) {
            if (quote.currentProgress === 'won') {
                return { status: 'won', customText: `${quote.summary.wonPercentage}% Won` }
            } else if (quote.currentProgress === 'lost') {
                return { status: 'lost' }
            } else {
                return { status: quote.currentProgress, customText: PROGRESS.find(i => i.value === quote.currentProgress).label }
            }
        } else {
            if (quote?.summary && quote.summary.hasOwnProperty('wonPercentage')) {
                if (quote.summary.wonPercentage > 0) {
                    return { status: 'won', customText: `${quote.summary.wonPercentage}% Won` }
                } else {
                    return { status: 'lost' }
                }
            } else {
                return { status: 'pending' }
            }
        }
    }

    const wonLossPercentage = () => {
        return quote?.summary?.wonPercentage || 0
    }

    return (
        <div ref={onChildRef}>
            <LocationDetailsTooltipModal
                show={show}
                top={top - originFix?.y}
                left={left - originFix?.x}
                className='LocationDetailsTooltip text-gray-300 rounded'
                style={{ '--tooltip-overflow': overflow ? `${overflow}px` : '13px' }}>
                <div className='bg-gray-50 p-3 rounded'>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <h3 className='font-medium mr-5 mb-2'>{quote?.name}</h3>
                            <div className='d-flex pt-3'>
                                <StatusBadge status={progress(quote).status} customText={progress(quote).customText} />
                            </div>
                        </div>
                        <div style={{ maxWidth: '13ch' }} className='text-blue-600 text-uppercase'>
                            {quote?.customer?.name}
                        </div>
                    </div>
                </div>

                <div className='p-4 font-12'>
                    <div className='d-flex'>
                        <div className='d-flex pr-5' style={{ width: '45%' }}>
                            <div className='pr-2 mx-2 mt-auto' style={{ marginBottom: '-5px' }}>
                                <PieChart percentage={wonLossPercentage()} />
                            </div>
                            <div>
                                <h5 className='pb-3 text-blue-100 font-medium text-uppercase'>customer win/loss rate</h5>
                                <h1 className='text-gray-300 font-medium'>
                                    {wonLossPercentage()}
                                    <span style={{ fontSize: '1.5rem', opacity: '50%' }}>&nbsp;%</span>
                                </h1>
                            </div>
                        </div>
                        <div>
                            <h5 className='pb-3 text-blue-100 font-medium text-uppercase'>COMPETITORS</h5>
                            {competitors().map((c, i) => (
                                <h4 key={`c_${i}`} className='bold'>
                                    {c.competitor.name} - {c.plant}
                                </h4>
                            ))}
                        </div>
                    </div>

                    <div className='pt-5'>
                        <h5 className='text-blue-100 font-medium text-uppercase'>products</h5>
                        <div style={{ overflowY: 'auto', maxHeight: '5.5rem' }}>
                            {quote?.quoteEntries.map((q, i) => (
                                <p key={`${i}_qe`} className='my-2'>
                                    {q.product?.name}
                                </p>
                            ))}
                        </div>
                    </div>
                </div>
            </LocationDetailsTooltipModal>

            <div ref={originFixRef} style={{ position: 'absolute', top: 0, left: 0 }} />
        </div>
    )
}

export default LocationDetailsTooltip
