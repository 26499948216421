import {
    Quote,
    UserRole,
    Competitor,
    Settings_PlantTiers,
    TruckMD,
    Customer,
    Project,
    Contract,

    Plant_Asphalt,
		Plant_Standard,
    Plant_Aggregates,
    Plant_Concrete,

    Product_Other
} from '@assets';

export const CUSTOMERS = 'customers';
export const CONTRACTS = 'contracts';
export const TRUCKS = 'trucks';
export const PROJECTS = 'projects';

export const DEFAULT_PLANT_TYPES = {
    ASPHALT: 'asphalt',
    READYMIX: 'readymix',
    AGGREGATES: 'aggregates',
    CONCRETE: 'concrete',
    OTHER: 'other',
    STANDARD: 'standard'
};

export const QUOTES = 'quotes';
export const CONTACTS = 'contacts';
export const COMPETITORS = 'competitors';
export const WONLOST = 'wonlost';
export const USER_ROLE = 'user-role';
export const SETTINGS_PLANT_TIERS = 'plantTierSettings';
export const LIST_PRICE = 'listPrice';

export default [
    { type: PROJECTS, title: 'Project', icon: Project },
    { type: CONTRACTS, title: 'Contract', icon: Contract },
    { type: CUSTOMERS, title: 'Customer', icon: Customer },
    { type: CONTACTS, title: 'Contact', icon: Customer },
    { type: COMPETITORS, title: 'Competitor', icon: Competitor },

    { type: DEFAULT_PLANT_TYPES.AGGREGATES, title: 'Aggregates', icon: Plant_Aggregates },
    { type: DEFAULT_PLANT_TYPES.READYMIX, title: 'Readymix', icon: Plant_Concrete },
    { type: DEFAULT_PLANT_TYPES.ASPHALT, title: 'Asphalt', icon: Plant_Asphalt },
    { type: DEFAULT_PLANT_TYPES.STANDARD, title: 'Standard', icon: Plant_Standard },
    { type: DEFAULT_PLANT_TYPES.OTHER, title: 'Other', icon: Product_Other },

    { type: QUOTES, title: 'Quote', icon: Quote },
    { type: USER_ROLE, title: 'User Role', icon: UserRole },
    { type: WONLOST, title: 'Jobs won/lost', icon: Quote },
    { type: SETTINGS_PLANT_TIERS, title: 'Plant tier settings', icon: Settings_PlantTiers },
    { type: LIST_PRICE, title: 'List price', icon: Product_Other },
    { type: TRUCKS, title: 'Truck', icon: TruckMD }
];
