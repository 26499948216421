import React from 'react';

import '@scss/components/_field-error.scss';

const FieldError = (props) => {
    const {
        error,
        variant = 'primary'
    } = props;

    if (!error) {
        return null;
    }

    return (
        <span className={`FieldError ${variant}`}>{error}</span>
    );
};

export default FieldError;
