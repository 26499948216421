import * as types from './types';

import AuthService from 'service/auth.service';

const loadSettings = (preloadSettings) => async dispatch => {
    if (preloadSettings) {
        dispatch({ type: types.FETCH_LIST_SETTINGS_SUCCESS, payload: { data: preloadSettings } });
        return;
    }

    dispatch({ type: types.FETCH_LIST_SETTINGS });
    try {
        // const settings = await AuthService.loadSettings();
        // @TODO: Finish it
        const settings = await service.get('/financial-settings/');
        dispatch({ type: types.FETCH_LIST_SETTINGS_SUCCESS, payload: settings });
    } catch (error) {
        dispatch({ type: types.FETCH_LIST_SETTINGS_FAIL, payload: error?.response?.data });
    }
};

export { loadSettings };
