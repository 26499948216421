import Form from 'react-bootstrap/Form';
import styles from './style.module.scss';
export const Switch = props => {
    const { type, className, feedback, feedbackType, ...restProps } = props;
    return (
        <div>
            <Form.Check type={type} className={styles.switchCheck + ` ${className}`} {...restProps}></Form.Check>
            {feedback && <span className={styles.switchHelper + ` ${feedbackType || ''}`}>{feedback}</span>}
        </div>
    );
};
