import { Show } from '@components/index'
import styled from 'styled-components'
import moment from 'moment'
import { Tooltip } from 'antd'
import { useEffect, useRef } from 'react'
import { Icon } from '.'
import { Quote } from '@customTypes/quote'
import { CANCELLED, LOST, SENT, WON } from '@constants/status'
import { ProjectStage, QuoteStage } from '@service/stages'

const ProgressBar = (props: any) => {
    const { versions = [], changeVersion, v, stage, currentProgress } = props
    const wrapper = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (wrapper.current) wrapper.current.scrollLeft = wrapper.current.scrollWidth
    }, [])

    function getPlace(i: number, quote: Quote): 'first' | 'middle' | 'last' {
        const lastV = versions[versions.length - 1]?.v
        if (i === 0) return 'first'
        if ([WON, LOST, CANCELLED].includes(quote.currentProgress) && lastV === quote.v) return 'last'
        return 'middle'
    }

    const filteredVersions = versions
        .filter((v: any, i: number) => {
            if (v.version === versions[i + 1]?.version && v.currentProgress === versions[i + 1]?.currentProgress) return false
            return true
        })
        .map((version: any) => ({ ...version, active: version.v === v }))

    const mockVersion = {
        currentProgress: currentProgress || 'draft',
        version: 1,
        active: true,
        created: new Date(),
        updated: new Date()
    }

    if (filteredVersions.length === 0) filteredVersions.push(mockVersion)

    return (
        <ProgressWrapper ref={wrapper}>
            {filteredVersions.map((quote: any, i: number) => (
                <Progress
                    key={i}
                    stage={stage}
                    place={getPlace(i, quote)}
                    onClick={() => changeVersion(quote)}
                    active={v === quote.v}
                    quote={quote}
                    currentQuoteVersion={props?.currentQuoteVersion}
                />
            ))}
        </ProgressWrapper>
    )
}

export default ProgressBar

const ProgressItemContent = ({ quote, currentQuoteVersion, stage }: { quote: Quote; currentQuoteVersion: Quote; stage: QuoteStage & ProjectStage }) => {
    let titleColor = stage.getColor(quote.currentProgress)
    let titleTooltip = currentQuoteVersion?.summary?.reason
    const label = stage.getName(quote.currentProgress)
    return (
        <>
            <Tooltip color='#0e3f66' title={titleTooltip}>
                <ProgressItemTitle style={{ background: titleColor }}>
                    <span data-cy='currentProject'>{label}</span>
                </ProgressItemTitle>
                <ProgressItemVersion>
                    <span data-cy='version'>Version {quote?.version}</span>
                    <Show
                        condition={
                            Boolean(quote.sentByEmailDate) &&
                            (quote.currentProgress === SENT || quote.currentProgress === WON || quote.currentProgress === LOST || quote.currentProgress === CANCELLED)
                        }>
                        <RevisionLabel>
                            <Icon style={{ height: 12 }} name='mail' />
                        </RevisionLabel>
                    </Show>
                    <Show condition={quote.revision}>
                        <RevisionLabel>R</RevisionLabel>
                    </Show>
                </ProgressItemVersion>
                <ProgressItemInfo>{moment(quote?.updated).format('ddd, MMM D')}</ProgressItemInfo>
                <ProgressItemInfo>{moment(quote?.updated).format('YYYY')}</ProgressItemInfo>
            </Tooltip>
        </>
    )
}

export const Progress = (props: any) => {
    const restProps = { ...props }
    delete restProps.content
    switch (props.place) {
        case 'first':
            return <ProgressItemFirst {...restProps}>{props.content ? props.content(props) : <ProgressItemContent {...restProps} />}</ProgressItemFirst>
        case 'middle':
            return <ProgressItemMiddle {...restProps}>{props.content ? props.content(props) : <ProgressItemContent {...restProps} />}</ProgressItemMiddle>
        case 'last':
            return (
                <ProgressItemLast {...props}>
                    <ProgressItemContent {...props} />
                </ProgressItemLast>
            )
        default:
            return (
                <ProgressItemMiddle {...props}>
                    <ProgressItemContent {...props} />
                </ProgressItemMiddle>
            )
    }
}

const RevisionLabel = styled.div`
    background: white;
    color: #0e3f66;
    font-weight: 500;
    font-size: 12px;
    padding: 0 4px;
    border-radius: 6px;
    margin-left: 4px;
    width: 22px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
`

export const ProgressItemTitle = styled.div`
    height: 20px;
    width: 100px;
    background: #6e8ca3;
    font-size: 13px;
    font-weight: 500;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 6px;
    text-transform: uppercase;
`

export const ProgressItemVersion = styled.div`
    font-size: 13px;
    font-weight: 400;
    height: 18px;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-right: 4px;
    display: flex;
    align-items: center;
`

export const ProgressItemInfo = styled.div`
    font-size: 12px;
    font-weight: 300;
    height: 18px;
    margin-right: 4px;
`

export const ProgressWrapper = styled.div`
    display: flex;
    color: white;
    overflow: auto;
    ::-webkit-scrollbar {
        height: 8px;
    }
    /* padding: 10px; */
`

const ProgressItem = styled.div`
    height: 100px;
    background: ${({ active }: any) => (active ? '#0e3f66' : '#bfc5d2')};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 60px;
    padding-top: 10px;
    cursor: pointer;
`

const ProgressItemFirst = styled(ProgressItem)`
    width: 170px;
    min-width: 170px;
    clip-path: polygon(120px 0, 100% 50%, 120px 100%, 0 100%, 0 0);
`

const ProgressItemMiddle = styled(ProgressItem)`
    width: 215px;
    min-width: 215px;
    margin-left: -42px;
    clip-path: polygon(165px 0, 100% 50%, 165px 100%, 0 100%, 50px 50%, 0 0);
`

const ProgressItemLast = styled(ProgressItem)`
    width: ${({ quote }: any) => (quote.revision ? '200px' : '170px')};
    min-width: 180px;
    margin-left: -42px;
    padding-right: 10px;
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 50px 50%, 0 0);
`
