import React, { useEffect, useState } from 'react';

import { TextField } from '@components';

import styles from './styles.module.scss';

const DropdownMenu = React.forwardRef((props, ref) => {
    const {
        children,
        style,
        className = '',
        displaySearch = false,
        footer = null,
        'aria-labelledby': labeledBy,
        resetSearch,

        onSearch
    } = props;

    const [searchValue, setSearchValue] = useState('');

    const handleOnSearch = e => {
        const inputSearchValue = e.target.value;
        setSearchValue(inputSearchValue);
        onSearch(inputSearchValue);
    };

    useEffect(() => {
        if (onSearch) {
            handleOnSearch({ target: { value: '' } });
        }
    }, [resetSearch, onSearch]);

    return (
        <div ref={ref} style={style} className={`bs-dropdown ${className} ${styles.dropdown_menu}`} aria-labelledby={labeledBy}>
            {displaySearch && (
                <div className={styles.dropdown_menu_search}>
                    <TextField placeholder='Search' value={searchValue} onChange={handleOnSearch} />
                </div>
            )}
            <div className={styles.dropdown_menu_content}>
                <ul className={styles.dropdown_menu_list}>{children}</ul>
            </div>
            {footer && <div className={styles.dropdown_menu_footer}>{footer}</div>}
        </div>
    );
});

export default DropdownMenu;
