import { Image } from '@components';

import { allStatuses, UNDEFINED } from '@constants/status';

import 'scss/components/_status-badge.scss';

const StatusBadge = ({ status = UNDEFINED, customText = '', customClass = '', icon = null }) => {
    const descriptor = allStatuses.find(x => x.value === status);
    const icn = icon !== undefined ? icon : descriptor?.icon;
    return (
        <div className={`StatusBadge StatusBadge__general noselect ${descriptor?.cls} ${customClass}`}>
            {icn && <Image src={icn} />}
            {customText ? customText : descriptor?.label}
        </div>
    );
};

export default StatusBadge;
