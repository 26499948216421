import { CSSProperties } from 'react'
import { Selectitem } from './Selectitem'

import '@scss/components/_select-dropdown.scss'

export const AccordionSelect = (props: {
    values: { [key: string]: { value: string; label: string }[] }
    options: { [key: string]: { value: string; label: string }[] }
    className: string
    onChange: (value: { [key: string]: { value: string; label: string }[] }) => void
    style?: CSSProperties
}) => {
    const {
        values,
        options,
        style,
        className,

        onChange
    } = props

    const onUpdateSelectItem = (value: { [key: string]: { value: string; label: string }[] }) => {
        onChange(value)
    }

    const clearAll = () => {
        onUpdateSelectItem(options)
    }

    return (
        <div className={`dropdown select-dropdown ${className}`} style={style}>
            <div className='dropdown-header'>
                <span className='filter-text'>Select filters</span>
                <span className='clear-all-text' onClick={clearAll}>
                    Clear all
                </span>
            </div>
            <div className='dropdown-options'>
                {Object.entries(options)?.map((group, i) => {
                    return <Selectitem key={group?.toString() + i} onUpdateSelectItem={onUpdateSelectItem} values={values} groupName={group?.[0]} option={group?.[1]} />
                })}
            </div>
        </div>
    )
}

export default AccordionSelect
