import { BTN_CANCEL, BTN_CANCEL_SECONDARY, BTN_DELETE, BTN_CONTINUE } from '@components/GlobalModal/ModalFooter/config';
import { useShowGlobalModal } from '@components/GlobalModal/hooks';

const DEFAULT_BUTTONS = [BTN_CANCEL, BTN_CONTINUE];

const BUTTONS_VARIANTS = {
    'primary': [BTN_CANCEL, BTN_CONTINUE],
    'secondary': [BTN_CANCEL_SECONDARY, BTN_DELETE]
};

export const useShowDeleteConfirmModal = (props = {}) => {
    const {
        variant = 'primary',
    } = props;

    const showDeleteConfirm = useShowGlobalModal({
        title: 'Confirm',
        buttons: BUTTONS_VARIANTS[variant] || DEFAULT_BUTTONS,
        ...props
    });

    return (modalProps) => showDeleteConfirm({ ...modalProps });
};