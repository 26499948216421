import { useRef, useState } from 'react'

import styled from 'styled-components'

import { Lock_gray } from '@assets'

import { CurrencyField, Image } from '@components'
import { Show, AntdTooltip } from '@common'

import { useOnClickOutside } from 'utils/onClickOutside'

import { Label, TextField } from '../'

import '@scss/components/_label.scss'
import '@scss/components/_textfield.scss'

import './style.scss'

const LabeledTextField = props => {
    const {
        id = '',
        label = '',
        name,
        type = 'text',
        placeholder = '',
        className = '',
        disabled = false,
        readOnly = false,
        value = '',
        autoFill = false,
        noBorder = false,
        formErrors,
        onChange = () => {},
        onTextBlur = () => {},
        onFocus = () => {},
        inputStyle = {},
        error,
        autoCompleteList = [],
        isInputLocked = false,
        isCurrency = false,
        maxLength = '',
        dataCy = '',
        extraInfo,
        style = {},
        tooltipContent = ''
    } = props

    const [showAutocomplete, setShowAutocomplete] = useState(false)
    const isWithAutocomplete = autoCompleteList.length > 0

    const formGroupRef = useRef()

    useOnClickOutside(formGroupRef, () => {
        setShowAutocomplete(false)
    })

    const onInputChange = e => {
        onChange(e)
    }

    const onAutoCompleteChange = value => {
        onChange({ target: { value } })
        setShowAutocomplete(false)
    }

    const onInputFocus = e => {
        onFocus(e)

        if (isWithAutocomplete) {
            setShowAutocomplete(true)
        }
    }

    const getInput = () => {
        if (!isInputLocked) {
            return !isCurrency ? (
                <TextField
                    id={id}
                    label={label}
                    name={name}
                    autoFill={autoFill}
                    noBorder={noBorder}
                    onChange={onInputChange}
                    placeholder={isInputLocked ? '' : placeholder}
                    value={isInputLocked ? '' : value}
                    disabled={disabled || readOnly}
                    type={type}
                    error={error}
                    formErrors={formErrors}
                    onBlur={onTextBlur}
                    onFocus={onInputFocus}
                    maxLength={maxLength}
                    dataCy={dataCy}
                    inputStyle={inputStyle}
                    extraInfo={extraInfo}
                />
            ) : (
                <CurrencyField
                    id={id}
                    label={label}
                    name={name}
                    noBorder={noBorder}
                    onChange={onInputChange}
                    placeholder={isInputLocked ? '' : placeholder}
                    defaultValue={isInputLocked ? 0 : value}
                    disabled={disabled || readOnly}
                    type={type}
                    formErrors={formErrors}
                    onBlur={onTextBlur}
                    onFocus={onInputFocus}
                    dataCy={dataCy}
                />
            )
        }

        return null
    }

    return (
        <div ref={formGroupRef} className={`FormGroup ${isWithAutocomplete ? 'with-autocomplete' : ''} ${isInputLocked ? 'locked-input' : ''} ${className}`} style={style}>
            <Show condition={label}>
                {tooltipContent ? (
                    <TooltipWrapper>
                        <Label label={label} />
                        <AntdTooltip placement='top' tooltipContent={tooltipContent}>
                            <InformationGray>i</InformationGray>
                        </AntdTooltip>
                    </TooltipWrapper>
                ) : (
                    <Label label={label} />
                )}
            </Show>
            {getInput()}
            {showAutocomplete && (
                <ul className={isWithAutocomplete ? 'autocomplete-list' : ''}>
                    {autoCompleteList.map(item => (
                        <li key={item.label} onClick={() => onAutoCompleteChange(item.value)}>
                            {item.label}
                        </li>
                    ))}
                </ul>
            )}
            {isInputLocked && (
                <div className='status__withLock--icon'>
                    <Image src={Lock_gray} size={13} />
                </div>
            )}
        </div>
    )
}

export default LabeledTextField

const TooltipWrapper = styled.div`
    display: flex;
    align-items: baseline;
    gap: 10px;
`

const InformationGray = styled.div`
    background: rgba(110, 140, 163, 0.6);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: white;
    font-weight: 500;
    display: flex;
    justify-content: center;
`
