import { currencies, CURRENCY, CURRENCY_DEFAULT, prefixes, SEPARATOR, SEPARATOR_DEFAULT, suffixes, THOUSAND_SEPARATOR, THOUSAND_SEPARATOR_DEFAULT } from 'consts/settings';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import '@scss/components/_textfield.scss';

const CurrencyField = React.forwardRef((props, ref) => {
    const {
        id = '',
        name = '',
        placeholder = '',
        customClass = '',
        defaultValue = '',
        noBorder = false,
        className = '',
        disabled = false,
        readOnly = false,
        onBlur,
        onFocus,
        onKeyDown,
        maxLength,
        onChange = () => {},
        style = {},
        onClick = () => {},
        onKeyPress = () => {},
        onKeyUp = () => {},
        formErrors = [],
        ...restProps
    } = props;
    const { settings } = useSelector(store => store.settings);
    const currency = settings.find(x => x.key === CURRENCY)?.value || CURRENCY_DEFAULT;
    const separator = settings.find(x => x.key === SEPARATOR)?.value || SEPARATOR_DEFAULT;
    const thousandSeparator = settings.find(x => x.key === THOUSAND_SEPARATOR)?.value || THOUSAND_SEPARATOR_DEFAULT;
    const activeCurrency = currencies.find(c => c.value === currency);

    const valueByCurrency = price => {
        return `${prefixes.includes(currency) ? activeCurrency.code : ''}${price}${suffixes.includes(currency) ? ' ' + activeCurrency.code : ''}`;
    };

    const [value, setValue] = useState(valueByCurrency(defaultValue));

    const inputRef = useRef(ref);

    const [error, setError] = useState(null);
    useEffect(() => {
        setError(formErrors?.find(x => x.id === id)?.message);
    }, [formErrors]);

    const setValidValue = async validNum => {
        const val = Number(validNum).toLocaleString('en-US');
        // console.log('val: ', val);
        // default price is: 1,111,111.00
        const getPriceBySettings = price => {
            let priceBySettings = price.toString();

            if (thousandSeparator === 'space') {
                priceBySettings = priceBySettings.replace(/,/g, ' ');
            }

            if (separator === 'dec-0,1') {
                priceBySettings = priceBySettings.replace('.', ',');
            }
            return priceBySettings;
        };

        const validPrice = valueByCurrency(getPriceBySettings(val));

        // console.log('validPrice: ', validPrice);

        await setValue(validPrice);
        onChange(validPrice);
        return validPrice;
    };

    // Comma || SPACE
    const onCurrencyChange = e => {
        const val = parseInt(e.target.value.replace(/\D/g, '')) || 0;
        return setValidValue(val);
    };

    // Handle backspace keypress
    const handleDeleteKey = async e => {
        if (e.keyCode === 8 && suffixes.includes(currency)) {
            e.preventDefault();
            // If suffix removed
            const val = e.target.value.replace(/\D/g, '') || 0;
            const settedVal = await setValidValue(val);

            const cursorPosition = settedVal.toString().length - 2;
            if (settedVal.replace(/\D/g, '') === val && cursorPosition < e.target.selectionStart) {
                inputRef?.current?.setSelectionRange(cursorPosition, cursorPosition);
            }
        }

        onKeyUp(e);
    };

    return (
        <div className={`TextField ${className}`} style={style}>
            <input
                id={id}
                ref={inputRef}
                name={name}
                type='text'
                placeholder={placeholder}
                className={`TextField__input ${customClass} ${noBorder ? 'TextField__input--no-border' : ''}
                ${disabled || readOnly ? 'TextField__input--disabled' : error ? 'TextField__input--error' : !readOnly ? 'TextField__input--normal' : ''}`}
                onChange={onCurrencyChange}
                value={value}
                onKeyUp={handleDeleteKey}
                onBlur={onBlur}
                autoComplete='none'
                maxLength={maxLength}
                onKeyDown={onKeyDown}
                disabled={disabled || readOnly}
                onClick={onClick}
                onKeyPress={onKeyPress}
                onFocus={onFocus}
                {...restProps}
            />
            {error && <span className='TextField TextField__error-message'>{error}</span>}
        </div>
    );
});

export default CurrencyField;
