import { QuoteDefault, QuoteBlob } from './quotePDF';
import { QuoteSolterra, QuoteSolterraBlob } from './solterra';
import { QuoteKMM, QuoteKMMBlob } from './kmm';
import { QuotePeckham, QuotePeckhamBlob } from './peckham.tsx';
import { QuoteWDC, QuoteWDCBlob } from './wdc';

export const REPORT_TEMPLATE_LIST = {
    'tkb-quote': [QuoteDefault, QuoteBlob],
    'kmm-quote': [QuoteKMM, QuoteKMMBlob],
    'solterra-quote': [QuoteSolterra, QuoteSolterraBlob],
    'peckham-quote': [QuotePeckham, QuotePeckhamBlob],
    'wdc-quote': [QuoteWDC, QuoteWDCBlob],
};
