import React from 'react';
import { LabeledTextArea, Dropdown } from '@components';

const QuoteApprovalModal = ({ approvalNote, setApprovalNote, approvers = [], quoteApprover, onChangeQuoteApprover = () => {}, showApprover = false, title = '' }) => {
    return (
        <div>
            <p>{title}</p>
            {showApprover && (
                <div>
                    <Dropdown label='Select approver' value={quoteApprover} options={approvers} onChange={onChangeQuoteApprover} />
                </div>
            )}
            <div className='mt-3'>
                <LabeledTextArea label='Note' readOnly={false} value={approvalNote} rows={3} onChange={setApprovalNote} />
            </div>
        </div>
    );
};

export default QuoteApprovalModal;
