import download from 'js-file-download';
import queryString from 'query-string';

import service from './service';

export const exportAll = async (entity, extra, fileName) => {
    const config = {
        responseType: 'blob'
    };
    const urlSearchParams = extra ? `?${queryString.stringify(extra)}` : '';
    const res = await service.get(`${entity}/export-all${urlSearchParams}`, config);
    download(res.data, fileName || `${entity}-all.csv`);

}