export const ADMIN = 'ADMIN'
export const PRICEBEE = 'PRICEBEE'
export const USER = 'USER'

export const UserType = {
    [ADMIN]: ADMIN,
    [PRICEBEE]: PRICEBEE,
    [USER]: USER
}

export const USER_MERGE_FIELDS = [{ label: 'Contacts' }, { label: 'Customers' }, { label: 'Projects' }, { label: 'Quotes' }]
