import { useState } from "react";

type FilterColumn = {
    filterColumns: string[],
    filterValues: string[],
    limit: number,
    page: number
}

type UseFilterListProps = {
    DEFAULT_FETCH_PARAMS: FilterColumn
    filter: FilterColumn
    updateFilter: (props: FilterColumn)=>void
}

const useFilterList = ({ DEFAULT_FETCH_PARAMS, filter, updateFilter }: UseFilterListProps) => {
    const [filterList, setFilterList] = useState<{ value: string; dataIndex: string }[]>([]);

    function addFilterToList() {
        setFilterList([...filterList, { value: '', dataIndex: '' }]);
    }

    function applyFilterList(data: typeof filterList) {
        const newFilterMap = new Map();
        const filterListColumns = data.map(f => f.dataIndex);
        const filterListValues = data.map(f => f.value);
        const allColumns = DEFAULT_FETCH_PARAMS.filterColumns.concat(filterListColumns);
        const defaultParamsValues = DEFAULT_FETCH_PARAMS.filterColumns.reduce((values: string[], currentColumn)=>{
            const index = filter.filterColumns.findIndex(column=>column===currentColumn)
            if(index === -1) return values
            const value = filter.filterValues[index]
            return [...values, value]
        }, [])
        const allValues = defaultParamsValues.concat(filterListValues);
        allColumns.forEach((column, i) => {
            newFilterMap.set(column, allValues[i]);
        });
        const newFilterColumns = Array.from(newFilterMap.keys());
        const newFilterValues = Array.from(newFilterMap.values());
        updateFilter({...filter, filterColumns: newFilterColumns, filterValues: newFilterValues, page: 1});
    }

    function clearAllFilterList() {
        const newFilterColumns: (typeof filter)['filterColumns'] = [];
        const newFilterValues: (typeof filter)['filterValues'] = [];
        filter.filterColumns.forEach((column, i) => {
            if (filterList.some(c => c.dataIndex === column)) return;
            newFilterColumns.push(column);
            newFilterValues.push(filter.filterValues[i]);
        });
        setFilterList([]);
        updateFilter({ ...filter, filterColumns: newFilterColumns, filterValues: newFilterValues });
    }

    function removeFilterFromList(index: number) {
        const newFilterList = filterList.map(f => ({ ...f }));
        const newFilterColumns: (typeof filter)['filterColumns'] = [];
        const newFilterValues: (typeof filter)['filterValues'] = [];
        filter.filterColumns.forEach((column, i) => {
            if (newFilterList[index].dataIndex === column) return;
            newFilterColumns.push(column);
            newFilterValues.push(filter.filterValues[i]);
        });
        newFilterList.splice(index, 1);
        setFilterList(newFilterList);
        updateFilter({ ...filter, filterColumns: newFilterColumns, filterValues: newFilterValues });
    }

    return { filterList, setFilterList, addFilterToList, applyFilterList, clearAllFilterList, removeFilterFromList}
}

export default useFilterList
