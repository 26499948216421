import React from 'react';
import { useLocation } from 'react-router-dom';

export const useQuery = () => {
    const location = useLocation();
    if (!location.search?.length) {
        return {};
    }

    const searchQueryString = location.search.substring(1);

    return JSON.parse('{"' + decodeURI(searchQueryString).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
}
