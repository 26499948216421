import { useLayoutEffect } from 'react'

import { useAuth } from '@hooks/useAuth'

import { APP_ENVIRONMENTS } from 'consts/environments'

const Intercom = () => {
    if (!APP_ENVIRONMENTS.IS_PROD) {
        return null;
    }

    const { user } = useAuth()
    const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID

    useLayoutEffect(() => {
        if (!user) return
            ; (function () {
                var w = window
                var ic = w.Intercom
                if (typeof ic === 'function') {
                    ic('reattach_activator')
                    ic('update', w.intercomSettings)
                } else {
                    var d = document
                    var i = function () {
                        i.c(arguments)
                    }
                    i.q = []
                    i.c = function (args) {
                        i.q.push(args)
                    }
                    w.Intercom = i
                    var l = function () {
                        var s = d.createElement('script')
                        s.type = 'text/javascript'
                        s.async = true
                        s.src = 'https://widget.intercom.io/widget/' + INTERCOM_APP_ID
                        var x = d.getElementsByTagName('script')[0]
                        x.parentNode.insertBefore(s, x)
                    }
                    if (document.readyState === 'complete') {
                        l()
                    } else if (w.attachEvent) {
                        w.attachEvent('onload', l)
                    } else {
                        w.addEventListener('load', l, false)
                    }
                }
            })()
        window.Intercom('boot', {
            app_id: INTERCOM_APP_ID,
            email: user.email,
            name: `${user.firstName} ${user.lastName}`,
            user_id: user._id
        })

        return () => {
            window.Intercom('shutdown')
        }
    }, [user])
}

export default Intercom
