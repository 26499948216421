import React, { useEffect, useState } from 'react'

import { FieldError } from '@components'

import EyeIcon from './parts/EyeIcon'
import Icon from './parts/Icon'

import { ERRORS_MESSAGES } from './const'

import '@scss/components/_textfield.scss'

/** @type {React.ForwardRefRenderFunction<HTMLInputElement, TextFieldProps>} */
const TextField = React.forwardRef((props, ref) => {
    const {
        id = '',
        name = '',
        type = 'text',
        placeholder = '',
        customClass = '',
        value = '',
        noBorder = false,
        className = '',
        disabled = false,
        readOnly = false,
        autoFill = false,
        autoComplete = 'off',
        onBlur,
        onFocus,
        onKeyDown,
        maxLength,
        onChange = () => {},
        onClick = () => {},
        onKeyPress = () => {},
        onKeyUp = () => {},
        formErrors = [],
        style = {},
        iconProps = [],
        dataCy = '',
        inputStyle = {},
        extraInfo,
        ...restProps
    } = props

    const [inputType, setInputType] = useState(type)

    const switchPasswordVisibility = () => {
        setInputType(type === inputType ? 'text' : 'password')
    }

    const [error, setError] = useState(null)
    useEffect(() => {
        if (props.error) {
            return
        }

        setError(formErrors?.find(x => x.id === id)?.message)
        const entityExist = formErrors?.find(x => x.id === 'entity-exist')
        if (entityExist) {
            setError(entityExist?.message)
        }
    }, [formErrors])

    useEffect(() => {
        if (!props.error) {
            return
        }

        setError(props.error?.message || ERRORS_MESSAGES[props.error?.type] || props.error?.type)
    }, [props.error])

    const entityExist =
        formErrors?.length && error
            ? formErrors.find(x => {
                  if (x.type === 'error') {
                      return false
                  }
                  if (x.type === 'warning') {
                      return true
                  }
                  return x.message?.includes(' already exists!') && !x.message?.includes('Email already exists!') && !x.message?.includes('Phone number already exists!')
              })
            : null

    return (
        <div className={`TextField ${className}`} style={style}>
            <input
                id={id}
                ref={ref}
                name={name}
                value={value || value === 0 ? value : autoFill ? '-' : ''}
                onBlur={onBlur}
                autoComplete={autoComplete}
                maxLength={maxLength}
                onKeyDown={onKeyDown}
                disabled={disabled || readOnly}
                className={`TextField__input ${customClass} ${noBorder ? 'TextField__input--no-border' : ''}
                ${disabled || readOnly ? 'TextField__input--disabled' : error ? 'TextField__input--error' : !readOnly ? 'TextField__input--normal' : ''}
                ${iconProps.length === 1 ? `TextField__input--${iconProps[0].alignment}` : iconProps.length > 1 ? 'TextField__input--both' : ''} ${
                    autoFill ? 'TextField__input--auto-fill' : ''
                } ${entityExist ? 'TextField__input--error-entity-exist' : ''}`}
                placeholder={placeholder}
                onChange={onChange}
                onClick={onClick}
                onKeyPress={onKeyPress}
                onKeyUp={onKeyUp}
                onFocus={onFocus}
                type={inputType}
                aria-invalid={error ? 'true' : 'false'}
                data-cy={dataCy}
                style={inputStyle}
                {...restProps}
            />
            <div style={{ position: 'absolute', top: 0, right: 0, height: '100%', display: 'flex', alignItems: 'center', paddingRight: '14px', color: '#81878E' }}>{extraInfo}</div>
            <EyeIcon originalType={type} inputType={inputType} onSwitchPasswordVisibility={switchPasswordVisibility} />
            {iconProps.map((icon, i) => (
                <Icon {...icon} key={i} />
            ))}
            <FieldError error={error} variant={entityExist ? 'warning' : 'primary'} />
        </div>
    )
})

export default TextField
