import { useState, useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { GridColumnReorderEvent, GridColumnResizeEvent } from '@progress/kendo-react-grid'
import { SortDescriptor } from '@progress/kendo-data-query'

import { getSettingsByKey, updateTableSettings as updateTableSettingsApi } from 'service/settingsApi'

import { SettingsType, SortType, TableColumn, TableSettingsHookProps, View } from '@customTypes/table'

export const DEFAULT_SORT: SortType = { field: 'created', order: 'asc' }

type Filter = {
    filterColumns: string[]
    filterValues: string[]
    limit: number
    page: number
}

export type SerializedFilter = {
    filterColumns: string
    filterValues: string
    limit: number
    page: number
}

type TableSetting = {
    view?: View
    columns: (TableColumn & { orderIndex?: number })[]
    settings?: SettingsType
    defaultView: View
    sort?: SortType

    serializeFilter: (filter: Filter) => SerializedFilter
    updateView: (update: Partial<View>, settingsPayload?: Partial<SettingsType>) => void

    onSort: (sort: SortDescriptor) => void
    onColumnReorder: (event: GridColumnReorderEvent) => void
    onColumnResize: (event: GridColumnResizeEvent) => void

    isTableSettingsLoading?: boolean
}

const useTableSettings = (props: TableSettingsHookProps): TableSetting => {
    const queryClient = useQueryClient()
    const { tableKey, columns, limit = 20, autoSaveView = false, section = '', dataProps } = props
    const defaultView = {
        sort: DEFAULT_SORT,
        name: 'autosave',
        limit,
        columns: columns.map(c => ({
            id: c.id,
            dataIndex: c.dataIndex,
            hidden: c.hidden,
            sort: c.sort,
            title: c.title,
            width: c.width
        }))
    }
    const [view, setView] = useState<View>()
    const { data: settings, isLoading } = useQuery<SettingsType>([tableKey], () => getSettingsByKey(tableKey, { section }), { enabled: !tableKey.includes('undefined'), refetchOnMount: "always" })
    const { mutate: updateSettings } = useMutation<any, unknown, SettingsType>(body => updateTableSettingsApi(tableKey, { ...body, section }))

    useEffect(() => {
        if (typeof settings === 'undefined') return
        const view = settings.views?.find(v => v.name === settings.lastShown)
        if (view) {
            setView(view)
        } else {
            setView(defaultView)
        }
    }, [settings])

    function updateView(update: Partial<View>, settingsPayload: Partial<SettingsType> = {}) {
        if (typeof settings === 'undefined') return
        //LOAD
        if (Object.keys(update).length === 0 && settingsPayload.lastShown && Object.keys(settingsPayload).length === 1) {
            const viewIndex = settings?.views?.findIndex(v => v.name === settingsPayload.lastShown)
            if (viewIndex === -1) return
            setView(settings?.views[viewIndex])
            updateSettings({ ...settings, lastShown: settingsPayload.lastShown }, { onSuccess: data => queryClient.setQueryData([tableKey], data) })
            return
        }
        const newView = { ...(view || {}), ...update } as View
        //NEW SETTINGS
        if (settings === null || !Object.keys(settings).length) {
            const newSettings = { key: tableKey, lastShown: update.name || view?.name, views: [newView] }
            setView(newView)
            updateSettings({ ...newSettings, ...settingsPayload }, { onSuccess: data => queryClient.setQueryData([tableKey], data) })
        }
        //UPDATE SETTINGS
        else {
            const currentViewIndex = settings?.views?.findIndex(v => v.name === (update.name || view?.name))
            const oldViews = settings?.views || []
            const newViews = [...oldViews]
            if (currentViewIndex > -1) {
                newViews[currentViewIndex] = newView
                setView(newView)
                updateSettings({ views: newViews, ...settingsPayload }, { onSuccess: data => queryClient.setQueryData([tableKey], data) })
            } else {
                newViews.push(newView)
                setView(newView)
                updateSettings({ views: newViews, ...settingsPayload }, { onSuccess: data => queryClient.setQueryData([tableKey], data) })
            }
        }
    }

    function onColumnReorder(event: GridColumnReorderEvent) {
        const newColumns = (view?.columns || []).map(c => ({ ...c }))
        newColumns.forEach(column => {
            const tableColumn = event.columns.find(c => c.field === column.dataIndex)
            if (tableColumn) (column as TableColumn).order = tableColumn.orderIndex
        })
        updateView({ columns: newColumns, name: 'autosave' }, { lastShown: 'autosave' })
    }

    function onColumnResize(event: GridColumnResizeEvent) {
        const gridColumn = event.columns.find((c: any) => c.ariaColumnIndex === event.index + 1)
        if (!gridColumn) return
        const newColumns = (view?.columns || []).map(c => ({ ...c }))
        const tableColumn = newColumns.find(c => c.dataIndex === gridColumn.field)
        if (!tableColumn) return
        tableColumn.width = event.newWidth
        if (event.end) updateView({ columns: newColumns, name: 'autosave' }, { lastShown: 'autosave' })
    }

    function onSort(sort: SortDescriptor) {
        updateView({ sort: { field: sort?.field || DEFAULT_SORT.field, order: sort?.dir || DEFAULT_SORT.order }, name: 'autosave' }, { lastShown: 'autosave' })
    }

    function serializeFilter(filter: Filter) {
        return {
            ...filter,
            filterColumns: filter.filterColumns.join(','),
            filterValues: filter.filterValues.join(',')
        }
    }

    const visibleColumns =
        columns.map((c: any, i: number) => ({
            ...c,
            width: view?.columns[i]?.width || c.width,
            hidden: view?.columns[i]?.hidden,
            order: view?.columns[i]?.order,
            title: typeof c.title === 'string' ? c.title : typeof c.title === 'function' ? c.title(dataProps || {}) : undefined,
            header: typeof c.header === 'function' && c.header({dataProps})
        })) || []

    return {
        view,
        updateView,
        settings,
        defaultView,
        onColumnReorder,
        onColumnResize,
        sort: view?.sort,
        onSort,
        serializeFilter,
        columns: visibleColumns,
        isTableSettingsLoading: isLoading
    }
}

export default useTableSettings
