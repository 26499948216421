import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';

import { useDateFormat } from '@hooks/useDateFormat';

import { useQuoteTemplate } from '@hooks/useQuoteTemplate';

import { data, options, currencyFormat } from '../template_mocks';

import '@scss/components/_pdf-viewer.scss';

const PDF_VIEWER_STYLES = {
    width: '100%',
    height: '100%'
};

const PDF = (props) => {
    const { report } = props;

    const { dateFormat } = useDateFormat();

    const [QuoteTemplate] = useQuoteTemplate();

    if (!QuoteTemplate) {
        return null;
    }

    return (
        <PDFViewer
            showToolbar={false}
            style={PDF_VIEWER_STYLES}
            className='pdf-viewer'
        >
            <QuoteTemplate
                data={{ ...data, dateFormat }}
                options={{ ...options, templateText: report }}
                currencyFormat={currencyFormat}
            />
        </PDFViewer>
    );
};

export default PDF;
