import React from 'react';

import 'scss/components/_button.scss';

import Ripple from '../Ripple';

export default function Button({
    label = '',
    htmlType = 'button',
    disabled = false,
    children,
    customClass = '',
    ripple = {},
    show = true,
    ...props
}) {
    if(!show) return null
    return (
        <button
            type={htmlType}
            className={`btnold noselect ${customClass} ${disabled ? 'btnold--disabled' : ''}`}
            style={{
                position: 'relative',
                ...props.style
            }}
            {...props}>
            {children !== undefined ? children : label}
            {ripple && <Ripple color={ripple.color || '#FFFFFF'} duration={ripple.duration || 550} />}
        </button>
    );
}
