import { Input } from 'antd'
import styled from 'styled-components'

import { baseInputStyles } from './Input'

const { Search } = Input

export type SearchInputProps = {
    width?: number
    error?: boolean
}

export const SearchInput = styled(Search)<SearchInputProps>`
    font-size: 15px;
    min-height: 40px;
    width: 480px;
    /* ${baseInputStyles} */
`

export default SearchInput
