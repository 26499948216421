import service from './service';

export const getAllSettings = async (errorCallback = () => { }) => {
    try {
        const res = await service.get(`/settings/`);
        return res.data;
    } catch (error) {
        errorCallback(error);
    }
    return {};
}

export const getSettingsByKey = async (key, params, errorCallback = () => { }) => {
    try {
        const res = await service.get(`/settings/${key}`, { params });
        return res.data;
    } catch (error) {
        errorCallback(error);
    }
    return {};
}

export const createSettings = async (settings, errorCallback = () => { }) => {
    try {
        const res = await service.post(`/settings/`, settings);
        return res.data;
    } catch (error) {
        errorCallback(error);
    }
    return {}
}

export const updateSettingsByKey = async (key, body, errorCallback = () => { }) => {
    try {
        const res = await service.put(`/settings/${key}`, body);
        return res.data;
    } catch (error) {
        errorCallback(error);
    }
    return false;
}

export const updateTableSettings = async (key, body, errorCallback = () => { }) => {
    try {
        const res = await service.post(`/settings/${key}`, body);
        return res.data;
    } catch (error) {
        errorCallback(error);
    }
    return false;
}

export const getQuoteProductLinesFields = async (quoteId) => {
    if (!quoteId) {
        return null;
    }

    const tableViewList = await getSettingsByKey(`table:quote-${quoteId}-quote-lines`, { section: 'quote-lines' });
    const tableView = tableViewList?.views?.find(v => v.name === 'autosave');
    if (!tableView?.columns?.length) {
        return null;
    }

    return tableView.columns.reduce((result, curr) => {
        if (curr.hidden) {
            return result;
        }

        return {
            ...result,
            [curr.dataIndex]: true
        };
    }, {});
};
