// import AuthService from 'service/auth.service';

// @TODO: Finish it.
// export const usePermissions = () => {
//     return AuthService.hasPerm;
// };

import { useSelector } from 'react-redux'

import { data } from 'consts/permissions'
import { ADMIN, PRICEBEE } from '../views/Pages/Setup/UserSetup/Users/const'

export const usePermissions = () => {
    const permissions = new Proxy(data, {
        get: (target, property, receiver) => {
            for (let k in target) if (new RegExp(k).test(property)) return target[k]

            return null
        }
    })
    const lsRoles = localStorage.getItem('roles')
    const user = useSelector(store => store.auth.user)

    let hasPerm = false

    const getPermFunc = (field, actions) => {
        if (user?.email === process.env.SUPER_ADMIN_EMAIL || user?.userType === ADMIN || user?.userType === PRICEBEE) {
            return true
        }

        if (actions) {
            // Actions permissions checking
            if (actions.action) {
                const key = permissions[actions.action]
                const userRolePermissions = (user?.userRoles || JSON.parse(lsRoles) || [])[0]?.permissions
                if (!userRolePermissions) return (hasPerm = false)
                const obj = userRolePermissions[key]
                for (let k in obj) {
                    if (obj[k].value === field) {
                        hasPerm = obj[k].allow.includes(actions.action)
                        break
                    }
                }
            }

            // Fields permissions checking
            if (!!actions.fields?.length) {
                // !!IMPORTANT static only for basicAccess
                const permission = user?.userRoles[0]?.permissions?.basicAccess.find(o => o.value === field)
                hasPerm = true

                if (Array.isArray(permission?.fields)) {
                    for (let f in actions.fields) {
                        if (permission.fields?.includes(actions.fields[f])) {
                            hasPerm = false
                            break
                        }
                    }
                }
            }
        }

        return hasPerm
    }

    return getPermFunc
}
