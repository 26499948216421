import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query'
import { DateTime } from 'luxon'
import { DateOperator, FieldFilters, FilterType, GridOperator, NumericOperator, OptionsOperator, QueryOperator, StringOperator } from './filter.types'
import { fromKendoUIDateFilterAdapter } from './kendo-date-filter'

export const fromKendoUIOperatorMap: Record<GridOperator, QueryOperator> = {
    // Common operator
    eq: NumericOperator.EQUAL,
    neq: NumericOperator.NOT_EQUAL,
    // String operators
    contains: StringOperator.CONTAINS,
    doesnotcontain: StringOperator.DOESNT_CONTAIN,
    startswith: StringOperator.STARTS_WITH,
    endswith: StringOperator.ENDS_WITH,
    isempty: StringOperator.IS_EMPTY,
    isnotempty: StringOperator.IS_NOT_EMPTY,
    // Numeric and date operators
    gt: NumericOperator.GREATER_THAN,
    gte: NumericOperator.GREATER_THAN_OR_EQUAL,
    lt: NumericOperator.LESS_THAN,
    lte: NumericOperator.LESS_THAN_OR_EQUAL,
    // Options operators
    in: OptionsOperator.IN,
    notIn: OptionsOperator.NOT_IN
    // Boolean operators
}

export const toKendoUIOperatorMap: Record<QueryOperator, GridOperator> = {
    // Common operator
    [NumericOperator.EQUAL]: 'eq',
    [NumericOperator.NOT_EQUAL]: 'neq',
    // String operators
    [StringOperator.CONTAINS]: 'contains',
    [StringOperator.DOESNT_CONTAIN]: 'doesnotcontain',
    [StringOperator.STARTS_WITH]: 'startswith',
    [StringOperator.ENDS_WITH]: 'endswith',
    [StringOperator.IS_EMPTY]: 'isempty',
    [StringOperator.IS_NOT_EMPTY]: 'isnotempty',
    // Numeric and date operators
    [NumericOperator.GREATER_THAN]: 'gt',
    [NumericOperator.GREATER_THAN_OR_EQUAL]: 'gte',
    [NumericOperator.LESS_THAN]: 'lt',
    [NumericOperator.LESS_THAN_OR_EQUAL]: 'lte',
    // Options operators
    [OptionsOperator.IN]: 'in',
    [OptionsOperator.NOT_IN]: 'notIn'
}

const isDate = (value: unknown): boolean => value instanceof Date

const isPrimitive = (value: unknown): boolean => {
    return typeof value === 'number' || typeof value === 'string' || value instanceof Date
}

const validOperation = (operator: string | Function, value: unknown): boolean => {
    if (typeof operator === 'function') return false
    if (operator === 'isempty' || operator === 'isnotempty') return true
    if (operator === 'in' || operator === 'notIn') {
        return Array.isArray(value) && value.length > 0
    }
    return isPrimitive(value) && value !== ''
}

type KendoReturnType = FieldFilters<{ _id: string }> | {}

export const fromKendoUIFilterAdapter = <T extends { _id: string }>({ field, operator, value }: FilterDescriptor): KendoReturnType => {
    const queryOperator = fromKendoUIOperatorMap[operator as GridOperator]

    if (!validOperation(operator, value)) return {}

    if (isDate(value)) {
        return fromKendoUIDateFilterAdapter({ field, operator, value })
    }
    return {
        [field as keyof T]: {
            operations: {
                [queryOperator]: value
            }
        }
    } as FieldFilters<T>
}

export const mergeFilters = <T extends { _id: string }>(filter: FilterType<T>, gridFilter?: CompositeFilterDescriptor | undefined): FilterType<T> => {
    const filters = (gridFilter?.filters || []) as FilterDescriptor[]
    return {
        ...filter,
        ...filters.reduce((acc, filter) => {
            return {
                ...acc,
                ...fromKendoUIFilterAdapter(filter)
            }
        }, {})
    }
}
