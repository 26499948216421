import * as types from './types';

const initialState = {
    contacts: [],
    details: { count: 0, filter: '', limit: 0, page: 0, pages: 0 },
    sending: false,
    editting : false,
    editFields : {
        row : -1,
        col :[]
    },
    adding : false,
    deleting : false,
    deletingFields : [],
    error: ''
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DELETE_CONTACT:
            return {
                ...state,
                deleting: true,
                deletingFields : action.payload
            };
        case types.DELETE_CONTACT_FAIL:
            return {
                ...state,
                deleting: false
            };
        case types.DELETE_CONTACT_SUCCESS:
            const temp =[...state.contacts];
            action.payload.map(v =>{
                const i = temp.findIndex(t => t._id === v)
                temp.splice(i, 1)
            })
            return {
                ...state,
                contacts : temp,
                deleting: false
            };
        case types.EDIT_CONTACT :
            return {
                ...state,
                editting : true,
                editFields : {
                    row : action.payload.id,
                    col : Object.keys(action.payload.params)
                }
            }
        case types.EDIT_CONTACT_FAIL :
            return {
                ...state,
                editting : false
            }
        case types.EDIT_CONTACT_SUCCESS:
            const list = [...state.contacts]
            const index = list.findIndex(e => e._id === action.payload._id)
            list[index] = action.payload;
            return {
                ...state,
                contacts : list,
                editting : false
            }
        case types.ADD_CONTACT :
            return {
                ...state,
                adding : true
            }
        case types.ADD_CONTACT_FAIL :
            return {
                ...state,
                adding : false
            }
        case types.ADD_CONTACT_SUCCESS:
            return {
                ...state,
                contacts : [...state.contacts, action.payload],
                adding : false
            }
        case types.FETCH_LIST_CONTACTS:
            return {
                ...state,
                sending: true
            };
        case types.FETCH_LIST_CONTACTS_SUCCESS:
            return {
                ...state,
                contacts: action.payload.items,
                details: action.payload.details,
                sending: false
            };
        case types.FETCH_LIST_CONTACTS_FAIL:
            return {
                ...state,
                sending: false,
                error: action.payload
            };
        case types.CLEAR_ERROR:
                return {
                  ...state,
                  error: ''
                }
        default:
            return state;
    }
};

export default reducer;
