import React from 'react';

import { Feather } from '@components';

import '@scss/components/_statisticWidget.scss';

export default function StatisticWidget({ children, icon = 'Package', title }) {
    return (
        <div className='StatisticWidget'>
            <div className='StatisticWidget__Header'>
                <div className='StatisticWidget__Header--icon'>
                    <Feather name={icon} size={30} color='#0E3F66' />
                </div>
                <div className='StatisticWidget__Header--title'>{title}</div>
            </div>
            <div className='StatisticWidget__Content'>{children}</div>
        </div>
    );
}
