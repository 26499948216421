export const NOTIFICATION_DURATION = 5000;

export const NOTIFICATION_LIST = {
    ERROR: {
        title: 'Error',
        description: 'Something went wrong.',
        duration: NOTIFICATION_DURATION,
        type: 'error'
    },
    WARNING: {
        title: 'Warning',
        description: '',
        duration: NOTIFICATION_DURATION,
        type: 'warning'
    },
    SUCCESS: {
        title: 'Success',
        description: 'Done.',
        duration: 5000,
        type: 'success'
    },
    INFO: {
        title: 'Info',
        description: '',
        duration: 5000,
        type: 'info'
    },
};
