export const uuid = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
};

export const objectId = () => {
    var timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
    return (
        timestamp +
        'xxxxxxxxxxxxxxxx'
            .replace(/[x]/g, function () {
                return ((Math.random() * 16) | 0).toString(16);
            })
            .toLowerCase()
    );
};

export const randomColor = () => {
    var letters = 'BCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
};

export const hexToRgbA = (hex, alpha) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ') ';
    }
    throw new Error('Bad Hex');
};

export const percent = x => {
    return Math.round(x || 0);
};

export const roundScale = x => {
    let round = x;
    if (x > 10000000) {
        round = 1000000;
    } else if (x > 1000000) {
        round = 50000;
    } else if (x > 100000) {
        round = 20000;
    } else if (x > 10000) {
        round = 500;
    } else if (x > 1000) {
        round = 50;
    }
    return Math.ceil(x / round) * round;
};

export const formatNumber = val => {
    if (val == null || isNaN(val)) {
        return '';
    }
    if (val == 0) {
        return '0';
    }
    if (val > 1000000) {
        return (val / 1000000).toFixed(1) + 'm';
    } else if (val > 100000) {
        return '0.' + (val / 10000).toFixed(0) + 'm';
    } else if (val > 10000) {
        return (val / 1000).toFixed(1) + 'k';
    } else if (val > 1000) {
        return (val / 1000).toFixed(0) + 'k';
    } else {
        return (val / 1000).toFixed(2) + 'k';
    }
};

export const numberWithCommas = x => {
    return x ? x.toLocaleString('en-US') : 0;
};

export const numberNoDecimals = x => {
    return x ? Math.round(x).toLocaleString('en') : 0;
};

export const formatTwoDecimals = number => {
    return number ? (Math.round(number * 100) / 100).toFixed(2) : 0;
};

export const formatTwoDecimalsWithComas = number => {
    const numberTwoDecimals = formatTwoDecimals(number);
    return numberWithCommas(numberTwoDecimals);
};

const DIGIT_REGEX = /^0*[1-9]\d*$/;

export const parseFloat = str => {
    if (!str || !DIGIT_REGEX.test(str) || str === '') {
        return 0;
    }

    return Number.parseFloat(str);
};
