import { TracerType } from '@utils/tracer'
import service from './service'

export const fetchTraces = async (params = {}) => {
    const res = await service.get(`/traces`, { params })
    return res.data
}

export const fetchTrace = async (id: string) => {
    const res = await service.get(`/traces/${id}`)
    return res.data
}

export const trace = async (tracer: TracerType, source: string) => {
    const res = await service.post(`/traces/${tracer.id}`, { events: tracer.events, entity: tracer.entity, source })
    return res.data
}

export const saveTraceById = async (id: string, source: string) => {
    // const tracer: TracerType = ((window as any).tracers || []).find((t: TracerType)=>t.id === id)
    // if(!tracer) return
    // const res: any = await service.post(`/traces/${tracer.id}`, { events: tracer.events, entity: tracer.entity, source }).catch(e=>console.log(`Saving tracer ${tracer.id} error!`, e))
    // return res?.data
}

export function saveAllTracers(source: string) {
    // const tracers = (window as any).tracers
    // if(Array.isArray(tracers) && tracers.length > 0) {
    //     const promises = tracers.map((tracer: TracerType)=>(trace(tracer, source)))
    //     Promise.all(promises).catch(e=>console.log("Saving tracers error!", e))
    // }
}