import axios from 'axios'

import { Document } from '@customTypes/document'

import Repository from './Repository'
import { FilterType } from 'filters/filter.types'

export interface UploadSignedUrl {
    name: string
    url: string
}

class DocumentsRepository extends Repository<Document> {
    constructor() {
        super('documents')
    }

    getSignedUrl = async (): Promise<UploadSignedUrl> => {
        return (await this.api.get<UploadSignedUrl>(`${this.route}/signed-url`)).data
    }

    getFileLink = async (documentId: string): Promise<string> => {
        return (await this.api.get<{ url: string }>(`${this.route}/${documentId}/file`)).data.url
    }

    register = async (document: Document): Promise<Document> => {
        return (await this.api.post<Document>(`${this.route}/register`, document)).data
    }

    upload = async (signedUrl: string, file: File): Promise<void> => {
        await axios.put<void>(signedUrl, file, { headers: { 'Content-Type': 'application/pdf' } })
    }

    getNewPaginatedList = async (query?: FilterType<any>) => {
        const params = new URLSearchParams()
        params.append('data', JSON.stringify(query))
        return (await this.api.get(`${this.route}/pagination`, { params })).data
    }
}

export default new DocumentsRepository()
