import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { loadUser } from '@reducers/auth/actions';

export const useAuth = () => {
    const dispatch = useDispatch();
    const auth = useSelector(store => store.auth);

    useEffect(() => {
        if (!auth?.user) {
            return;
        }

        dispatch(loadUser());
    }, []);

    return {
        user: auth?.user,
        loading: auth.loading
    };
};
