import { useCurrency } from '@hooks/useCurrency'
import { isNull } from 'utils/validators'

const Currency = props => {
    const { value, suffix = null, fontWeight = 400, decimals = 2 } = props
    const { currencyFormat } = useCurrency()

    if (isNull(value)) {
        return <span className='d-flex'>-</span>
    }

    return (
        <span className='d-flex'>
            <span style={{ fontWeight }}>{currencyFormat(value, decimals)}</span>
            {suffix && <>{suffix}</>}
        </span>
    )
}

export default Currency
