import React from 'react';

import { Dropdown, LabeledTextArea } from '@components'

import { DEV_STATUSES } from '@panels/QuotesHeader/const';
import '@scss/modals/_quote-progress-modal.scss';

const QuoteProgressModal = (props) => {
    const {
        progress = { value: 'draft', note: '' },
        onProgressChange = () => { }
    } = props;

    return (
        <div className='wrapper'>
            <Dropdown
                name='value'
                label='New Stage'
                value={progress.value}
                options={DEV_STATUSES.map(x => ({ label: x.label, value: x.value }))}
                customClass='mw-200 mb-20'
                onChange={e => onProgressChange({ ...progress, value: e.value })}
            />
            <LabeledTextArea
                name='note'
                label="Note"
                value={progress.note}
                onChange={e => onProgressChange({ ...progress, note: e.target.value })}
            />
        </div>
    )
}

export default QuoteProgressModal;