import React from 'react';

import '@scss/components/_textarea.scss';

const TextArea = props => {
    const {
        name = null,
        value = '',
        placeholder = '',
        cols = 20,
        rows = 5,
        customClass = '',
        noBorder = false,
        disabled = false,
        readOnly = false,
        onChange = () => { },
        autoFill = false,
        style = {},
        error = ''
    } = props;
    return (
        <>
            <textarea
                name={name}
                cols={cols}
                rows={rows}
                style={style}
                disabled={readOnly}
                placeholder={placeholder}
                className={`text textarea ${error ? 'text--error' : ''} ${noBorder ? 'text--no-border' : ''} ${customClass} ${readOnly ? 'text--disabled' : 'text--enabled'} ${autoFill ? 'text--auto-fill' : ''
                    }`}
                onChange={onChange}
                value={value ? value : autoFill ? '-' : ''}
            />
        </>
    );
};

export default TextArea;
