// browser.js
import appVersion from '../appVersion.json';

export const checkVersionAndUpdate = () => {
    const currentVersion = localStorage.getItem('version');

    if (currentVersion !== appVersion.version) {
        if (currentVersion) {
            alert('A new version of the app is available. The app will now update to the latest version.');
        }
        hardReload();
    }
};

export const hardReload = () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(registrations => {
            for (let registration of registrations) {
                registration.unregister();
            }
        });
    }

    localStorage.setItem('version', appVersion.version);
    window.location.reload(true);
};


export const getBrowserCache = () => {
    const currentVersion = localStorage.getItem('version');
    return {
        isVersionUpdated: currentVersion !== appVersion.version,
        appVersion: appVersion.version,
        oldVersion: currentVersion
    };
};
