import React from 'react';
import { Item } from './style';

const WYS = 10;
const WYE = 200;
const SCALE_BASE = (WYE - WYS - 35) / 100;

const COLORS = [
    '#f5faff',
    '#e0f1ff',
    '#cce8ff',
    '#b8dfff',
    '#a3d6ff'
]

const TierCustomAnalysisSvg = ({ data }) => (
    <div className='TierCustomerAnalysisSvg'>
        <div className='stat'>
            {data.map((zone, i) => (
                <div className='block' key={`tier-analysis-${i}`} style={{ backgroundColor: COLORS[i] }}>
                    <div className='tier-number'>Tier {i + 1}</div>
                    <div className='bars-graph'>
                        <div className='cell-item'>
                            <div className='percentage'>{zone.customers}%</div>
                            <Item className='item green' height={zone.customers}></Item>
                        </div>
                        <div className='cell-item'>
                            <div className='percentage'>{zone.revenue}%</div>
                            <Item className='item red' height={zone.revenue}></Item>
                        </div>
                        <div className='cell-item'>
                            <div className='percentage'>{zone.volume}%</div>
                            <Item className='item blue' height={zone.volume}></Item>
                        </div>
                    </div>
                </div>
            ))}
        </div>

        <div className='legend'>
            <div className='entity'>
                <div className='circle green'></div>
                <label>Share Of Customers</label>
            </div>
            <div className='entity'>
                <div className='circle red'></div>
                <label>Share Of Revenue</label>
            </div>
            <div className='entity'>
                <div className='circle blue'></div>
                <label>Share Of Volume</label>
            </div>
        </div>
    </div>
);

export default TierCustomAnalysisSvg;
