import { Show } from '@common'
import styled from 'styled-components'

const CommentsButton = ({comments = [], user = {}, onClick, style = {}, hideNew = false}: any) => {
    const newMessages = comments.filter((comment: any)=>(
        !comment.usersViewed?.includes(user._id) 
        && comment.user?._id!==user._id
    ))
    return (
        <CommentsButtonWrapper onClick={ onClick } style={ style }>
            <Show condition={(newMessages.length > 0) && !hideNew}>
                <CommentsButtonNewMessages>
                    { newMessages.length }
                </CommentsButtonNewMessages>
            </Show>
            { comments.length }
        </CommentsButtonWrapper>
    )
}

const CommentsButtonWrapper = styled.div`
    position: relative;
    width: 50px;
    height: 40px;
    border: 2px solid #1e74d2;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    color: #0e3f66;
    :after {
        content:"";
        width: 0;
        height: 0;
        border-bottom: solid 7px transparent;
        border-top: solid 7px white;
        border-left: solid 7px transparent;
        border-right: solid 7px transparent;
        position: absolute;
        bottom: -13px;
        left: 10px;
    }
    :before {
        content:"";
        width: 0;
        height: 0;
        border-bottom: solid 8px transparent;
        border-top: solid 8px #1e74d2;
        border-left: solid 8px transparent;
        border-right: solid 8px transparent;
        position: absolute;
        bottom: -16px;
        left: 9px;
    }
`

const CommentsButtonNewMessages = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ff8939;
    position: absolute;
    right: -10px;
    top: -10px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
`

export default CommentsButton
