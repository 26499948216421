import { Feather } from '@components';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Icon } from '@components';
import { Icon as CommonIcon } from '@common'
import styles from './Documents.module.scss';

let tmp;

const columns = [
    {
        id: 1,
        title: 'Document No.',
        dataIndex: 'documentNo',
        sort: true,
        hidden: false,
        filter: true,
        width: 150,
        render: (tmp = ({ row }) => (
            <div className='d-flex justify-content-between align-items-center'>
                {row?.documentNo || ''}
                {row?.imported && <Feather size={20} name='Link2' color='#0E3F66' />}
            </div>
        )),
        sortValue: (tmp = ({ row }) => row?.documentNo),
        filterValue: tmp
    },
    {
        id: 2,
        title: 'Document name',
        dataIndex: 'name',
        sort: true,
        hidden: false,
        filter: true,
        width: 200,
        render: ({ row }) =>
            row?.name && (
                <Link className='d-flex justify-content-between align-items-center' to={`/document-details/preview/${row?._id}`} target='_blank'>
                    <span className='text-underline'>{row?.name}</span>
                    <Icon name='document' className={styles.fileIcon} color='#2ed47a' />
                </Link>
            ),
        csv: (tmp = ({ row }) => row?.name),
        filterValue: tmp
    },
    {
        id: 3,
        title: 'Type 1',
        dataIndex: 'docTypeOne',
        sort: true,
        hidden: false,
        filter: true,
        width: 200,
        render: (tmp = ({ row }) => row?.docTypeOne || ''),
        filterValue: tmp
    },
    {
        id: 4,
        title: 'Type 2',
        dataIndex: 'docTypeTwo',
        sort: true,
        hidden: false,
        filter: true,
        width: 200,
        render: (tmp = ({ row }) => row?.docTypeTwo || ''),
        filterValue: tmp
    },
    {
        id: 5,
        title: 'Quote name & number',
        dataIndex: 'quote',
        sort: true,
        filter: true,
        width: 200,
        align: 'left',
        render: ({ row }) =>
            row.quote?.name ? (
                <Link to={`/Quote/${row.quote?._id}?quoteTab=3`}>
                    <span className='text-underline'>{row.quote?.name}</span>
                    <p className='text-underline text-gray-light'>{row.quote?.quoteNo}</p>
                </Link>
            ) : (
                <span> - </span>
            ),
        csv: (tmp = ({ row }) => (row.quote?.name ? row.quote?.name + ' ' + row.quote?.quoteNo : '')),
        sortValue: tmp
    },
    {
        id: 6,
        title: 'Customer name & number',
        dataIndex: 'customer',
        sort: true,
        filter: true,
        width: 250,
        align: 'left',
        render: ({ row }) =>
            row.customer?.name ? (
                <Link to={`/MasterData/customer/${row.customer?._id}`}>
                    <span className='text-underline'>{row.customer?.name}</span>
                    <p className='text-underline text-gray-light'>{row.customer?.customerNo}</p>
                </Link>
            ) : (
                <span> - </span>
            ),
        csv: (tmp = ({ row }) => (row.customer?.name ? row.customer?.name + ' ' + row.customer?.customerNo : '')),
        sortValue: tmp
    },
    {
        id: 7,
        title: 'Project name & number',
        dataIndex: 'project',
        sort: true,
        filter: true,
        width: 200,
        align: 'left',
        render: ({ row }) =>
            row.project?.name ? (
                <Link to={`/Project/${row.project?._id}`}>
                    <span className='text-underline'>{row.project?.name}</span>
                    <p className='text-underline text-gray-light'>{row.project?.projectNo}</p>
                </Link>
            ) : (
                <span> - </span>
            ),
        csv: (tmp = ({ row }) => (row.project?.name ? row.project?.name + ' ' + row.project?.projectNo : '')),
        sortValue: tmp
    },
    {
        id: 8,
        title: 'PO Number',
        dataIndex: 'purchaseOrder',
        sort: true,
        hidden: false,
        filter: true,
        width: 150,
        render: (tmp = ({ row }) => row?.purchaseOrder || ''),
        filterValue: tmp
    },
    {
        id: 9,
        title: 'Date Created',
        dataIndex: 'created',
        sort: true,
        hidden: false,
        filter: true,
        type: 'date',
        width: 125,
        render: (tmp = ({ row, dataProps }) => moment(row.created).format(dataProps.dateFormat)),
        csv: tmp,
        filterValue: tmp,
        sortValue: ({ row }) => row?.created
    },
    {
        id: 10,
        title: 'Date Updated',
        dataIndex: 'updated',
        sort: true,
        hidden: false,
        filter: true,
        type: 'date',
        width: 125,
        render: (tmp = ({ row, dataProps }) => moment(row.updated).format(dataProps.dateFormat)),
        csv: tmp,
        filterValue: tmp,
        sortValue: ({ row }) => row?.updated
    },
    {
        id: 11,
        title: 'Notes',
        dataIndex: 'note',
        hidden: false,
        filter: false,
        sort: false,
        width: 100,
        render: ({ row, dataProps }) => {
            return row?.notes?.length > 0 ? (
                <span className={styles.noteIconBox} onClick={() => dataProps.openNotes(row)}>
                    {row?.notes?.[0]?.readBy?.includes(dataProps?.user?._id) ? <CommonIcon name='comments' style={{ width: '23px', height: '23px' }} /> : <CommonIcon name='newComments' style={{ width: '23px', height: '23px' }} />}
                </span>
            ) : (
                // <Icon name='comment' className={styles.noteIcon} />
                <> - </>
            );
        },
        sortValue: ({ row }) => row?.notes
    }
    // {
    //     id: 12,
    //     title: '',
    //     dataIndex: '',
    //     filter: true,
    //     width: 10,
    //     editable: false,
    //     hideOnReadOnly: true,
    //     customStyle: { padding: '0px', width: '20px' },
    //     render: ({ row, dataProps }) => {
    //         const options = [];

    //         if (dataProps.onEdit) {
    //             options.push({
    //                 label: 'Edit',
    //                 action: () => dataProps.onEdit(row)
    //             });
    //         }
    //         if (dataProps.onDelete) {
    //             options.push({
    //                 label: 'Delete',
    //                 action: () => dataProps.onDelete(row)
    //             });
    //         }

    //         return row && options.length > 0 ? <ActionMenu parentComponent={document.getElementsByClassName('react-tabs__tab-panel--selected')?.[0]} options={options} /> : null;
    //     }
    // },
    // {
    //     id: 13,
    //     title: '',
    //     dataIndex: 'dummyColumn',
    //     editable: false,
    //     hidden: false,
    //     width: 100,
    //     render: () => <div></div>
    // }
];

export default columns;
