import { ImportedLabel, Icon } from '@components/index';

import mapping from '@panels/MasterDataHeader/mapping';
import { Product_Other } from '@assets/index';

const getIcon = (productType: any) => {
    return mapping.find(el => el.type === productType?.key);
};

const columns = [
    {
        id: 1,
        title: 'Product No',
        dataIndex: 'productNo',
        sort: true,
        hidden: false,
        width: 110,
        filter: true,
        required: true,
        render: ({ row }: any) => row.object.productNo
    },
    {
        id: 2,
        title: 'Product name',
        dataIndex: 'name',
        sort: true,
        hidden: false,
        width: 150,
        filter: true,
        render: ({ row }: any) => (
            <div className='flex-start flex-v-center'>
                {row.object?.name}
                <ImportedLabel imported={row.object?.imported} />
            </div>
        )
    },
    {
        id: 3,
        title: 'Plant No.',
        dataIndex: 'plantsInfo.plant.plantNo',
        sort: true,
        hidden: false,
        width: 100,
        filter: true,
        required: true,
        render: ({ row }: any) => row.object?.plant?.plantNo || ''
    },
    {
        id: 4,
        title: 'Plant Name',
        dataIndex: 'plantsInfo.plant.name',
        sort: true,
        hidden: false,
        width: 150,
        filter: true,
        required: true,
        render: ({ row }: any) => row.object?.plant?.name || ''
    },
    {
        id: 5,
        title: 'UOM',
        dataIndex: 'uom.value',
        sort: true,
        hidden: false,
        width: 80,
        filter: true,
        required: true,
        render: ({ row }: any) => row.object?.uom?.value || ''
    },
    {
        id: 6,
        title: 'Usage',
        dataIndex: 'usage',
        sort: true,
        hidden: false,
        width: 80,
        filter: true,
        required: true,
        render: ({ row }: any) => row?.object?.usage || ''
    },
    {
        id: 7,
        title: 'Product line',
        dataIndex: 'productType.name',
        sort: true,
        hidden: false,
        width: 150,
        filter: true,
        render: ({ row, dataProps }: any) => { 
            const icon = getIcon(row.object?.productType)?.icon
            const exist = dataProps.addedItems?.find(({_id}: any)=>(row._id===_id))
            return (
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '110px', display: 'flex' }}>
                        { icon && <img style={{ width: '20px', height: '20px', marginRight: '10px', filter: exist?'opacity(0.3)':'' }} src={icon} alt='icon' /> }
                        <span style={{ marginRight: '10px' }}>{row.object?.productType?.name|| ''}</span>
                    </div>
                    {/* <Icon name={`Status-${row.object.status === 'active' ? 'Active' : 'Inactive'}`} color='#2ed47a' fontSize="20px"/> */}
                </div>
            )
        }
    },
];

export default columns;
