import { Document, Image, Page, pdf, Text, View } from '@react-pdf/renderer';
import moment from 'moment';

import { LogoPeckham } from '@assets';
import { Quote } from '@customTypes/quote'
import getCostPerTon from '@utils/getCostPerTon';
import { TCT, dateFormatT, defaultIndexTypeT, displayFieldsT } from './types'
import Entry from '../Pages/Setup/TCSettings/PDFPreview/Entry';
import getTotal from 'views/Pages/Project/utils/getTotal'

import { DISPLAY_FIELDS } from './columns';

import styles from './peckham-style';

type transportT = {
    transport: any
}

interface QuotePeckhamI {
    data: Quote & displayFieldsT & dateFormatT & defaultIndexTypeT & TCT & transportT
    options: any
    entityGroupByKey: any
    showTECAlert?: boolean
    currencyFormat: any
}

export const QuotePeckham = (props: QuotePeckhamI) => {
    const { data, options, showTECAlert, entityGroupByKey, currencyFormat } = props;

    const displayFields = data.displayFields || DISPLAY_FIELDS;

    const contactInfo = data?.project?.contacts?.find(cont => cont?.type === 'primary') || data?.generalContact;

    return (
        <Document title='Quote'>
            <Page size='A4' style={{ ...styles.page_container, paddingHorizontal: 30 }}>
                <View style={{ flexGrow: 0 }} fixed>
                    <View style={{ position: 'absolute' }}>
                        <Image src={LogoPeckham} style={{ width: '100px', height: 'auto' }} />
                    </View>
                    <View style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 5 }}>
                        <View style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={styles.h3_black}>Peckham Industries, Inc.</Text>
                            <Text style={styles.h3_black}>172 Prospect Hill Rd, Brewster, NY 10509</Text>
                            <Text style={styles.h3_black}>(914) 949-2000</Text>
                        </View>
                    </View>
                    <View style={{ border: '2px solid black', borderBottom: 'none', paddingHorizontal: 5, paddingTop: 10 }}>
                        <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: 5 }}>
                            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: 30 }}>
                                <Text style={{ ...styles.h3_black }}>Job Description:</Text>
                                <Text style={styles.h3_black}>{data?.name}</Text>
                            </View>
                            <View style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Text style={styles.h3_black}>* * * PRICING IS ONLY VALID FOR 30 DAYS FROM QUOTE DATE * * *</Text>
                            </View>
                        </View>
                    </View>
                    <View>
                        <View style={{ border: '2px solid black', display: 'flex', flexDirection: 'row' }}>
                            <View style={{ width: '50%', paddingVertical: 10, paddingHorizontal: 5 }}>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={styles.h3_black}>Quote Date:</Text>
                                    <Text style={styles.h5_black}>{moment().format(data.dateFormat)}</Text>
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={styles.h3_black}>Job Completion Date:</Text>
                                    <Text style={styles.h5_black}></Text>
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={styles.h3_black}>Quote Expiration:</Text>
                                    <Text style={styles.h5_black}>{moment(data.expirationDate).format(data.dateFormat)}</Text>
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={styles.h3_black}>Project Location:</Text>
                                    <Text style={styles.h5_black}>{data?.address?.city}</Text>
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={styles.h3_black}>Asphalt Index Type:</Text>
                                    {data?.priceEscalation?.basePrice !== null &&
                                        data?.priceEscalation?.basePrice !== undefined &&
                                        data?.quoteEntries?.some(el => el?.priceEscalationApplied) ? (
                                        <Text style={styles.h5_black}>
                                            {data?.priceEscalation?.asphaltIndexTypeName
                                                ? `${data?.priceEscalation?.asphaltIndexTypeName}`
                                                : data?.defaultIndexType?.name
                                                    ? `${data?.defaultIndexType?.name}`
                                                    : ''}
                                        </Text>
                                    ) : null}
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={styles.h3_black}>Asphalt Base Price:</Text>
                                    <Text style={styles.h5_black}>
                                        {data?.priceEscalation?.basePrice && data?.quoteEntries?.some(el => el?.priceEscalationApplied)
                                            ? `$${data?.priceEscalation?.basePrice}`
                                            : ''}
                                    </Text>
                                </View>
                                <View>
                                    <Text style={{ ...styles.h3_black, textDecoration: 'underline' }}>Salesman Information</Text>
                                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: '20px' }}>
                                        <Text style={styles.h5_black}>Name:</Text>
                                        <Text style={styles.h5_black}>{`${data?.userResponsible?.firstName} ${data?.userResponsible?.lastName}`}</Text>
                                    </View>
                                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: '20px' }}>
                                        <Text style={styles.h5_black}>Phone:</Text>
                                        <Text style={styles.h5_black}>{data?.userResponsible?.directPhone}</Text>
                                    </View>
                                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: '20px' }}>
                                        <Text style={styles.h5_black}>Mobile:</Text>
                                        <Text style={styles.h5_black}>{data?.userResponsible?.mobilePhone}</Text>
                                    </View>
                                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: '20px' }}>
                                        <Text style={styles.h5_black}>Email:</Text>
                                        <Text style={styles.h5_black}>{data?.userResponsible?.email}</Text>
                                    </View>
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={styles.h3_black}>Quote ID:</Text>
                                    <Text style={styles.h5_black}>{data.quoteNo}</Text>
                                </View>
                                {showTECAlert && (
                                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Text style={styles.alert}>
                                            Tax Exemption Certificate is required for this job. Forward to <Text style={styles.alertLink}>{data?.userResponsible?.email}</Text>
                                        </Text>
                                    </View>
                                )}
                            </View>
                            <View style={{ width: '50%', paddingVertical: 10, paddingHorizontal: 5 }}>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={styles.h3_black}>Customer:</Text>
                                    <View
                                        // @ts-ignore
                                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', textAlign: 'start' }}>
                                        <Text style={{ ...styles.h5_black, display: 'flex' }}>{data?.customer?.name || ''}</Text>
                                        <Text style={{ ...styles.h5_black, justifyContent: 'flex-end' }}>{data?.customer?.addresses?.[0]?.line || ''}</Text>
                                        <Text style={{ ...styles.h5_black, justifyContent: 'flex-end' }}>{[data.customer?.addresses?.[0]?.city || '', data.customer?.addresses?.[0]?.state || '', data.customer?.addresses?.[0]?.zip || ''].filter(el => el != '').join(', ')}</Text>
                                    </View>
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={styles.h3_black}>Project Contact Name:</Text>
                                    <Text style={{ ...styles.h5_black, justifyContent: 'flex-start' }}>{contactInfo?.firstName} {contactInfo?.lastName}</Text>
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={styles.h3_black}>Project Phone No:</Text>
                                    <Text style={styles.h5_black}>{contactInfo?.phone1}</Text>
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={styles.h3_black}>Project Fax No:</Text>
                                    <Text style={styles.h5_black}>{contactInfo?.fax}</Text>
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={styles.h3_black}>Project Contact Email:</Text>
                                    <Text style={styles.h5_black}>{contactInfo?.email1}</Text>
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={styles.h3_black}>Job No:</Text>
                                    <Text style={styles.h5_black}></Text>
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={styles.h3_black}>P.O. No:</Text>
                                    <Text style={styles.h5_black}>{data?.purchaseOrder}</Text>
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={styles.h3_black}>Property Owner</Text>
                                    <Text style={styles.h5_black}></Text>
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={styles.h3_black}>Name:</Text>
                                    <Text style={styles.h5_black}></Text>
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={styles.h3_black}>Street:</Text>
                                    <Text style={styles.h5_black}></Text>
                                </View>
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={styles.h3_black}>City, State Zip:</Text>
                                    <Text style={styles.h5_black}></Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={{ borderTop: '2px solid black', borderBottom: '2px solid black', marginVertical: 5, flexGrow: 1 }}>
                    <View style={{ flexDirection: 'row', width: '100%', borderBottom: '1px solid black', borderLeft: '2px solid black', borderRight: '2px solid black', }}>
                        {displayFields.qty && <Text style={{ ...styles.table_th, flex: 1 }}>QTY</Text>}
                        {displayFields.uom && <Text style={{ ...styles.table_th, flex: 1 }}>UOM</Text>}
                        {displayFields.product && <Text style={{ ...styles.table_th, flex: 5 }}>Product {'\n'} Description / DOT Item Code</Text>}
                        {displayFields.qty && (
                            <View style={{ ...styles.table_th, flex: 2.75, alignItems: 'center', padding: 0 }}>
                                <Text>Unit Price</Text>
                                <View
                                    style={{
                                        ...styles.table_th,
                                        flexDirection: 'row',
                                        borderRight: 'none',
                                        borderTop: '1px solid black',
                                        display: 'flex',
                                        padding: 0,
                                        alignItems: 'stretch'
                                    }}>
                                    <View
                                        style={{
                                            flex: 1,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            borderRight: data?.transport ? '1px solid black' : 'none'
                                        }}>
                                        <Text>FOB</Text>
                                    </View>
                                    {displayFields?.deliveredUnitPrice && (
                                        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                            <Text>Delivered</Text>
                                        </View>
                                    )}
                                </View>
                            </View>
                        )}

                        <View style={{ ...styles.table_th, flex: 2.75, borderRight: 'none', alignItems: 'center', padding: 0 }}>
                            <Text>Extended Total</Text>
                            <View
                                style={{
                                    ...styles.table_th,
                                    flexDirection: 'row',
                                    borderRight: 'none',
                                    borderTop: '1px solid black',
                                    display: 'flex',
                                    padding: 0,
                                    alignItems: 'stretch'
                                }}>
                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                        borderRight: data?.transport ? '1px solid black' : 'none'
                                    }}>
                                    <Text>FOB</Text>
                                </View>
                                {displayFields?.totalDelivered && (
                                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                        <Text style={{ marginLeft: 2 }}>Delivered</Text>
                                    </View>
                                )}
                            </View>
                        </View>
                    </View>
                    <View style={{ flexGrow: 1 }}>
                        {data.quoteEntries.map((x, i) => {
                            const costPerTon = getCostPerTon(x, { plantsInfo: data.plantsInfo }) || 0;
                            const isDelivered = Boolean(costPerTon) && Boolean(x.delivered);
                            const price = x.price || 0;
                            const totalDelivered = price ? (price + costPerTon) * (x?.qty || 0) : 0;
                            const qty = typeof x.qty === 'undefined' ? '-' : x?.qty.toLocaleString();

                            return (
                                <View
                                    wrap={false}
                                    key={`qe-${i}`}
                                    style={{
                                        flexDirection: 'row',
                                        width: '100%',
                                        borderLeft: '2px solid black',
                                        borderRight: '2px solid black',
                                        borderBottom: '1px solid black',
                                        ...(data.quoteEntries.length === i + 1 ? { flexGrow: 1, borderBottom: 'none' } : {})
                                    }}>
                                    {displayFields.qty && <Text style={{ ...styles.table_td, flex: 1, alignItems: 'center' }}>{qty}</Text>}
                                    {displayFields.uom && <Text style={{ ...styles.table_td, flex: 1 }}>{x?.uomValue || x?.uom || x?.product?.uom?.value || ''}</Text>}
                                    {displayFields.product && (
                                        <View style={{ ...styles.table_td, flex: 5 }}>
                                            <Text style={styles.h5_black}>
                                                {x.product?.productNo} - {x.placedName || x.product.name}
                                            </Text>
                                            <Text style={{ color: '#6d6d6d' }}>{x?.plantInfo?.name}</Text>
                                        </View>
                                    )}
                                    <View style={{ ...styles.table_td, flex: '2.75 1', display: 'flex', flexDirection: 'row', padding: 0 }}>
                                        <View style={{ ...styles.table_td, flex: 1 }}>
                                            <Text>{currencyFormat(x.price)}</Text>
                                        </View>
                                        {displayFields?.deliveredUnitPrice && (
                                            <View style={{ ...styles.table_td, borderRight: 'none', flex: 1 }}>
                                                <Text>{x?.delivered ? currencyFormat(x.price + costPerTon) : '-'}</Text>
                                            </View>
                                        )}
                                    </View>
                                    <View style={{ ...styles.table_td, flex: '2.75 1', display: 'flex', flexDirection: 'row', borderRight: 'none', padding: 0 }}>
                                        <View style={{ ...styles.table_td, flex: 1 }}>
                                            <Text>{currencyFormat((x?.price || 0) * (x?.qty || 0))}</Text>
                                        </View>
                                        {displayFields?.totalDelivered && (
                                            <View style={{ ...styles.table_td, borderRight: 'none', flex: 1 }}>
                                                <Text>{isDelivered ? currencyFormat(totalDelivered) : '-'}</Text>
                                            </View>
                                        )}
                                    </View>
                                </View>
                            );
                        })}
                        {data.quoteEntries.length && (
                            <View
                                style={{
                                    flexDirection: 'row',
                                    width: '100%',
                                    borderTop: '1px solid black',
                                    borderLeft: '2px solid black', borderRight: '2px solid black',
                                }}>
                                {displayFields.qty && <Text style={{ ...styles.table_td, flex: 1, alignItems: 'center' }}></Text>}
                                {displayFields.uom && <Text style={{ ...styles.table_td, flex: 1, alignItems: 'center' }}></Text>}
                                {displayFields.product && <View style={{ ...styles.table_td, flex: 5 }}></View>}
                                <View style={{ ...styles.table_td, flex: 2.75, display: 'flex', flexDirection: 'row', padding: 0, alignItems: 'center' }}>
                                    <Text style={{ ...styles.h3_black, marginLeft: '5px' }}>Total</Text>
                                </View>
                                <View style={{ ...styles.table_td, flex: 2.75, display: 'flex', flexDirection: 'row', padding: 0, borderRight: 'none' }}>
                                    <View style={{ ...styles.table_td, flex: 1, ...(data?.delivered ? {} : { border: 'none' }) }}>
                                        <Text>
                                            {currencyFormat(
                                                data.quoteEntries.reduce((acc, curr) => {
                                                    if (!curr.active) return acc;
                                                    const price = curr.price || 0;
                                                    const qty = curr.qty || 0;
                                                    return acc + price * qty;
                                                }, 0)
                                            )}
                                        </Text>
                                    </View>
                                    {data?.delivered && (
                                        <View style={{ ...styles.table_td, borderRight: 'none', flex: 1 }}>
                                            <Text>
                                                {currencyFormat(
                                                    data.quoteEntries.reduce((acc, curr) => {
                                                        if (!curr?.delivered || !curr.active) return acc;
                                                        const costPerTon = getCostPerTon(curr, { plantsInfo: data.plantsInfo }) || 0;
                                                        const price = curr.price || 0;
                                                        const qty = curr.qty || 0;
                                                        const totalDelivered = price ? (price + costPerTon) * qty : 0;
                                                        return acc + totalDelivered;
                                                    }, 0)
                                                )}
                                            </Text>
                                        </View>
                                    )}
                                </View>
                            </View>
                        )}
                        {data.quoteEntries.length && (
                            <View
                                style={{
                                    flexDirection: 'row',
                                    width: '100%',
                                    borderTop: '1px solid black',
                                    borderLeft: '2px solid black', borderRight: '2px solid black',
                                }}>
                                {displayFields.qty && <Text style={{ ...styles.table_td, flex: 1, alignItems: 'center' }}></Text>}
                                {displayFields.uom && <Text style={{ ...styles.table_td, flex: 1, alignItems: 'center' }}></Text>}
                                {displayFields.product && <View style={{ ...styles.table_td, flex: 5 }}></View>}
                                <View style={{ ...styles.table_td, flex: 2.75, display: 'flex', flexDirection: 'row', padding: 0, alignItems: 'center' }}>
                                    <Text style={{ ...styles.h3_black, marginLeft: '5px' }}>Total including tax</Text>
                                </View>
                                <View style={{ ...styles.table_td, flex: 2.75, display: 'flex', flexDirection: 'row', padding: 0, borderRight: 'none' }}>
                                    <View style={{ ...styles.table_td, flex: 1, ...(data?.delivered ? {} : { border: 'none' }) }}>
                                        <Text>
                                            {currencyFormat(getTotal(data.quoteEntries, data).pickedUpPriceIncludingTax)}
                                        </Text>
                                    </View>
                                    {data?.delivered && (
                                        <View style={{ ...styles.table_td, borderRight: 'none', flex: 1 }}>
                                            <Text>
                                                {currencyFormat(getTotal(data.quoteEntries, data).deliveredIncludingTax)}
                                            </Text>
                                        </View>
                                    )}
                                </View>
                            </View>
                        )}
                    </View>
                </View>
                <View wrap>
                    <View>
                        <View style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5px', marginBottom: '10px' }}>
                            <Text style={styles.h3_black}>***special note*** </Text>
                        </View>
                        <View style={{ paddingHorizontal: 5, border: '2px solid black', flexGrow: 0 }}>
                            <Text style={{ ...styles.h5_black }}>{data.tc}</Text>
                        </View>
                    </View>
                    <View style={{ paddingHorizontal: 5, paddingVertical: 5, border: '2px solid black', borderTop: 'none', flexGrow: 0 }}>
                        <Entry entry={options.templateText} />
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexGrow: 0 }}>
                        <View style={{ flex: 1 }}>
                            <View
                                style={{
                                    paddingHorizontal: 5,
                                    paddingVertical: 5,
                                    border: '2px solid black',
                                    borderRight: 'none',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    gap: 10,
                                    flex: 1
                                }}>
                                <Text style={{ ...styles.h5_black }}>Salesperson Signature: ________________________________</Text>
                                <Text style={{ ...styles.h5_black }}>Name: ________________</Text>
                                <Text style={{ ...styles.h5_black }}>Date: ________________________</Text>
                            </View>
                        </View>
                        <View style={{ flex: 1 }}>
                            <View style={{ paddingHorizontal: 5, paddingVertical: 5, border: '2px solid black', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 10 }}>
                                <Text style={styles.h5_black}>Customer Signature: _________________________________</Text>
                                <Text style={styles.h5_black}>Name: __________________</Text>
                                <Text style={styles.h5_black}>Date: _____________</Text>
                            </View>
                        </View>
                    </View>
                    <Text
                        style={{ position: 'absolute', bottom: '5px', right: '10px', fontSize: 8 }}
                        render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
        </Document>
    );
};

export const QuotePeckhamBlob = async (props: QuotePeckhamI) => {
    return await pdf(QuotePeckham(props)).toBlob();
};
