import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { SearchIcon, ValidateError, ValidateWarning, Pencil } from '@assets'
import inforIcon from '@assets/icons/information.svg'

import { Button, DatePicker, Dropdown, Feather, Spinner, Tooltip, Image } from '@components';
import { Checkbox } from '@common'
import { getMyViews, loadMyViews, loadVisibleFields, restoreDefaultView, setMyView } from '@reducers/table/actions'

import { _cloneDeep } from 'utils/jsonUtil'
import { useOnClickOutside } from 'utils/onClickOutside'
import { checkValidFloat } from 'utils/regexUtils'

import TextField from '../TextField/index'

import { Controls, Pagination } from './components'

import '@scss/components/_control.scss'
import '@scss/components/_search.scss'
import '@scss/components/_table.scss'
import { TableProvider } from './Table.provider'

const Th = styled.th`
    background-color: ${p => p.bg} !important;
    color: ${p => p.color} !important;
    border-right-color: ${p => p.bg} !important;
`

const Table = props => {
    const {
        scrollToSelected,
        paddingRowNo = 10,
        visibleColumn = false,
        onlyOneSelectionEnabled = false,
        tableKey = '',
        columns = [],
        data = [],
        showBlankRow = false,
        validate_mod = false,
        pagination = false,
        isSelectView = false,
        havePriceValidityFilter = false,
        action = false,
        isFilter = false,
        controlPanel = {
            nonSelected: null,
            selected: null
        },
        details = {
            count: 0,
            filter: '',
            limit: 0,
            page: 0,
            pages: 0
        },
        checkBox = false,
        sort = {
            sort: '',
            order: 'asc'
        },
        limit = 20,
        loadingData = false,
        editting = false,
        expandable = null,
        autoSaveView = null,
        section = null,
        adding = false,
        highlighted = [],
        deleting = false,
        deletingFields = [],
        resizing = true,
        readOnly = false,
        dataProps = null,
        hideCheckBoxOnReadOnly = false,
        handlerDispatch = null,
        editFields = {
            id: -1,
            col: []
        },
        reorder = false,
        showControls = true,
        newRow = null,
        newEntry = {},
        footer = false,
        customStyle = {},
        className = '',
        parentComponent = null,
        overrideMinWidthRule = false,
        showWidthInfo = false,
        selected = [],
        onSort = () => {},
        onChangePage = () => {},
        onSelected = () => {},
        onChangeLimit = () => {},
        onFilter = () => {},
        onAddNewClick = () => {},
        onEditClick = () => {},
        onAddNewEntry = () => {},
        onChangeNewEntry = () => {},
        onDoubleClickRow = () => {},
        onCancelAddClick = () => {},
        onClickRow = () => {},
        onUpdateDisplayFields = () => {},
        onRowEnterKey = () => {},
        widgetCallback = () => {},
        controlsRender = null,
        csvExport = false,
        csvFilename,
        csvExportAll,
        csvExportAllParam,
        csvExportAllFileName,
        onEditCheckbox = () => true,
        isRowInactive = () => false,
        onQuery = () => {},
        onlySearch = false,
        placeholder,
        searchAsFilter = false,
        isDataLoading = false,
        entityGroupByKey,
        blankRowData = 'No data to show',
        isEditable = false,
        onEditHandler = () => {}
    } = props

    if (!columns || !columns.length) {
        return null
    }

    if (!tableKey || tableKey == '') {
        console.log('TABLE NEEDS TO HAVE TABEL KEY SET!!!!')
        return null
    }

    const [tableData, setTableData] = useState(data)
    const lastShown = useSelector(store => store.table.lastShown)
    const [lastShownTable, setLastShownTable] = useState(lastShown[tableKey])
    const [filter, setFilter] = useState([])
    const [openReorder, setOpenReorder] = useState(false)
    const [lastSelectedView, setLastSelectedView] = useState(null)
    const [tableWidth, setTableWidth] = useState()
    const [sortingVisible, setSortingVisible] = useState(false)
    const [isAllToggled, setIsAllToggled] = useState(false)

    const [isResizing, setResizing] = useState(null)
    const [editVal, setEditVal] = useState({})
    const [expandedRows, setExpandedRows] = useState({})
    const newRow_ref = useRef()
    const resize_ref = useRef(null)
    const tr_data_ref = useRef(null)
    const table_ref = useRef(null)
    const [tableParentOffset, setTableParentOffset] = useState()

    const textFieldRef = React.createRef()
    const scrollContainerRef = React.createRef()

    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (isAllToggled && selected.length > 0) {
            const hasCommonItem = data.some(item => selected.some(element => element._id === item._id))
            if (!hasCommonItem) {
                onSelected([])
            }
        }
    })

    useEffect(() => {
        let updated = {}
        data.map(x => {
            if (Boolean(expandedRows[x._oldId])) {
                updated[x._id] = true
            }
        })
        setExpandedRows(prev => ({ ...prev, ...updated }))
        setTableData(data)
    }, [data])

    useEffect(() => {
        if (isAllToggled && selected.length !== data.length) {
            onSelected([...data])
        }
    }, [data, selected])

    useEffect(() => {
        setLastShownTable(lastShown[tableKey])
    }, [lastShown])

    useEffect(() => {
        tableData.map(element => {
            return {
                ...element,
                isCheck: false
            }
        })
        dispatch(loadVisibleFields(tableKey, columns))
        dispatch(getMyViews(tableKey, columns, section))
    }, [columns])

    useEffect(() => {
        const handleEsc = event => {
            if (event.keyCode === 27) {
                setEditVal({})
            }
        }
        window.addEventListener('keydown', handleEsc)
        return () => {
            window.removeEventListener('keydown', handleEsc)
        }
    }, [])

    useEffect(() => {
        const handleEnterKey = event => {
            if (event.keyCode === 13) {
                onRowEnterKey(highlighted[0])
            }
        }
        window.addEventListener('keydown', handleEnterKey)
        return () => {
            window.removeEventListener('keydown', handleEnterKey)
        }
    }, [highlighted])

    const numberOfFields = () => {
        return displayFields.length + (Boolean(expandable) ? 1 : 0) + (checkBox ? 1 : 0)
    }

    useEffect(() => {
        if (scrollContainerRef?.current) {
            setTableParentOffset(scrollContainerRef.current.getBoundingClientRect().x)
        }
    }, [scrollContainerRef?.current])

    useEffect(() => {
        setTableWidth(table_ref.current ? table_ref.current.offsetWidth : 0)
    }, [table_ref.current])

    useEffect(() => {
        if (!autoSaveView) return
        const updateTableViews = [...tableViews]
        const existViewIndex = updateTableViews.findIndex(t => t.name === autoSaveView?.trim())

        if (existViewIndex >= 0) {
            updateTableViews[existViewIndex].limit = details.limit
            dispatch(
                setMyView(
                    columns,
                    tableKey,
                    {
                        lastShown: autoSaveView,
                        views: updateTableViews
                    },
                    true
                )
            )
        }
    }, [details.limit, autoSaveView])

    const { fields, views, loading } = useSelector(store => store.table)
    const tableFields = !!fields[tableKey] ? fields[tableKey] : columns
    const tableViews = !!views[tableKey] ? views[tableKey] : []
    const tableColumns = JSON.parse(JSON.stringify(columns))
    const [displayFields, setDisplayFields] = useState(tableFields.filter(e => !e.hidden))

    const handleMousemove = e => {
        e.preventDefault()
        const resize = resize_ref.current.children[isResizing]
        const item = tableColumns[isResizing - (checkBox ? 1 : 0) - (expandable ? 1 : 0)]

        let offsetRight = e.clientX + resize.parentNode.parentNode.parentNode.parentNode.scrollLeft - resize.offsetLeft - tableParentOffset + 2

        if (!overrideMinWidthRule && offsetRight < item.width) return

        displayFields[isResizing - (checkBox ? 1 : 0) - (expandable ? 1 : 0)].width = offsetRight
        onChangeColumns(tableFields)
    }

    const formattedSelected = []
    selected?.forEach(item => {
        item?.listPrices?.forEach(price => formattedSelected.push({ ...item, listPrice: price }))
    })

    useEffect(() => {
        if (!tableViews?.length) {
            return
        }

        if (lastShownTable) {
            const lastShownView = tableViews.filter(view => view.name === lastShownTable)[0]
            if (!lastShownView) {
                return
            }

            setLastSelectedView(lastShownView)
            dispatch(loadMyViews(tableKey, lastShownView))
            if (lastShownView?.sort?.field?.length && lastShownView?.sort?.order) {
                onSort({
                    sort: lastShownView.sort.field,
                    order: lastShownView.sort.order,
                    autoSaveView: true
                })
            }
        }

        if (!autoSaveView) {
            return
        }

        const shownView = tableViews.find(v => v.name === autoSaveView)

        if (!shownView) {
            return
        }

        if (shownView?.sort?.field?.length && shownView?.sort?.order) {
            onSort({
                sort: shownView.sort.field,
                order: shownView.sort.order,
                autoSaveView
            })
        }

        if (shownView?.limit) {
            onChangeLimit(shownView.limit)
        }

        dispatch(loadMyViews(tableKey, shownView))
    }, [lastShownTable, tableViews])

    const handleDrag = React.useCallback(
        e => {
            if (isResizing !== null) return handleMousemove(e)
            else return
        },
        [isResizing]
    )

    const drop = () => {
        setResizing(null)
    }

    useEffect(() => {
        window.addEventListener('mouseup', drop)
        return () => window.removeEventListener('mouseup', drop)
    }, [drop])

    useEffect(() => {
        window.addEventListener('mousemove', handleDrag)
        return () => window.removeEventListener('mousemove', handleDrag)
    }, [handleDrag])

    const formatColumns = {}

    if (tableFields && tableFields.length > 0) {
        tableFields.forEach(col => {
            formatColumns[col.dataIndex] = col
            const index = columns.findIndex(e => e.id === col.id)
            if (index !== -1) {
                if (columns[index].render) {
                    formatColumns[col.dataIndex] = {
                        ...formatColumns[col.dataIndex],
                        render: columns[index].render
                    }
                }
                if (columns[index].newRowRender)
                    formatColumns[col.dataIndex] = {
                        ...formatColumns[col.dataIndex],
                        newRowRender: columns[index].newRowRender
                    }

                if (columns[index].csv) {
                    formatColumns[col.dataIndex] = {
                        ...formatColumns[col.dataIndex],
                        csv: columns[index].csv
                    }
                }

                if (columns[index].filterValue) {
                    formatColumns[col.dataIndex] = {
                        ...formatColumns[col.dataIndex],
                        filterValue: columns[index].filterValue
                    }
                }

                if (columns[index]?.getStyle) {
                    formatColumns[col.dataIndex] = {
                        ...formatColumns[col.dataIndex],
                        getStyle: columns[index].getStyle
                    }
                }

                if (columns[index].getHeaderStyle) {
                    formatColumns[col.dataIndex] = {
                        ...formatColumns[col.dataIndex],
                        getHeaderStyle: columns[index].getHeaderStyle
                    }
                }

                if (columns[index].headerRender) {
                    formatColumns[col.dataIndex] = {
                        ...formatColumns[col.dataIndex],
                        headerRender: columns[index].headerRender
                    }
                }
            }
        })
    }

    const onChangeSingleCheckBox = (element, value) => {
        let list = [...selected]
        if (value) {
            if (onlyOneSelectionEnabled) {
                list = []
            }
            list.push(element)
        } else {
            list.splice(
                list.findIndex(x => (element.key ? x.key == element.key : x._id == element._id)),
                1
            )
            setIsAllToggled(false)
        }
        onSelected([...list])
    }

    const onToggleAllCheckBox = () => {
        if (selected.length > 0) {
            onSelected([])
            setIsAllToggled(false)
        } else {
            onSelected([...tableData].filter(tD => !isRowInactive(tD)))
            setIsAllToggled(true)
        }
    }

    const onChangeColumns = newFields => {
        dispatch(loadVisibleFields(tableKey, newFields))
    }

    useOnClickOutside(newRow_ref, () => {
        onAddNewEntry({
            ...newEntry
        })
    })

    useOnClickOutside(textFieldRef, () => {
        const typ = columns.find(x => x.dataIndex === editVal.col)
        const tmp = { ...editVal }
        if (typ.type === 'number') {
            tmp.value = parseFloat(editVal.value) || 0
        }
        onEditClick(editVal.id, tmp)
        setEditVal({})
    })

    const validate_popup = validated_row => {
        const validated = validated_row.error || validated_row.warning || validated_row.info
        return (
            <Tooltip
                content={validated}
                style={{
                    position: 'absolute',
                    top: 16,
                    right: 10,
                    width: '20px',
                    height: '20px'
                }}>
                <img className='cross_icon' src={validated_row?.error ? ValidateError : validated_row?.warning ? ValidateWarning : inforIcon} width='20px' height='20px' alt='' />
            </Tooltip>
        )
    }

    useEffect(() => {
        setDisplayFields(tableFields.filter(e => !e.hidden && !e.virtual))

        if (tableFields.length !== columns) {
            const _newViewColumns = []
            columns.forEach(c => {
                const column = _cloneDeep(c)

                delete column.render
                delete column.csv

                _newViewColumns.push(column)
            })

            dispatch(
                setMyView(
                    columns,
                    tableKey,
                    {
                        lastShown: autoSaveView,
                        views: _newViewColumns
                    },
                    true
                )
            )
        }
    }, [tableFields, columns])

    useEffect(() => {
        onUpdateDisplayFields(displayFields)
    }, [displayFields])

    // -> Lowest to hights (product number) for Products across the app
    // -> Default position for Win&Lost
    // -> Quotes Icon -> Project Table -> Open quotes

    const onAddView = name => {
        const updateTableViews = [...tableViews]
        const existViewIndex = updateTableViews.findIndex(t => t.name === name?.trim())
        if (existViewIndex !== -1) {
            const newColumns = updateTableViews[existViewIndex].columns.filter(e => !e.hidden)
            const newSort = updateTableViews[existViewIndex]?.sort
            const currentColumns = tableFields.filter(e => !e.hidden)
            if (
                autoSaveView &&
                sort?.autoSaveView &&
                newSort?.field === sort?.sort &&
                newSort?.order === sort?.order &&
                tableFields.length &&
                newColumns.length === currentColumns.length
            ) {
                return
            }

            updateTableViews[existViewIndex].columns = tableFields
            updateTableViews[existViewIndex].sort = {
                field: sort?.sort,
                order: sort?.order
            }
            updateTableViews[existViewIndex].limit = details.limit

            dispatch(
                setMyView(
                    columns,
                    tableKey,
                    {
                        lastShown: name,
                        views: updateTableViews
                    },
                    true
                )
            )

            return
        }

        dispatch(
            setMyView(columns, tableKey, {
                lastShown: name,
                views: [
                    ...tableViews,
                    {
                        columns: tableFields,
                        sort: {
                            field: sort?.sort,
                            order: sort?.order
                        },
                        limit: details.limit,
                        name
                    }
                ]
            })
        )
    }

    const onKeyUp = e => {
        if (e.keyCode === 13) {
            onAddNewEntry({
                ...newEntry
            })
        }
    }

    const onUpdateView = name => {
        const temp = [...tableViews]
        const index = tableViews.findIndex(v => v.name === name)
        if (index !== -1)
            temp[index] = {
                name,
                columns: tableFields
            }
        dispatch(
            setMyView(columns, tableKey, {
                views: temp
            })
        )
    }

    const onRemoveView = view => {
        const list = [...tableViews]
        view.map(v => {
            const index = list.findIndex(e => e === v)
            if (index !== -1) list.splice(index, 1)
        })

        dispatch(
            setMyView(columns, tableKey, {
                lastShown: lastShownTable === view.nam && list.length ? list[list.length - 1]?.name : null,
                views: list
            })
        )
    }

    const onLoadView = view => {
        dispatch(
            setMyView(columns, tableKey, {
                lastShown: view.name,
                views: [...tableViews]
            })
        )
        dispatch(loadMyViews(tableKey, view))
    }

    const onRestoreDefaultView = () => {
        dispatch(restoreDefaultView(tableKey, columns))
    }

    useEffect(() => {
        if (!scrollToSelected) {
            return
        }

        const rowHeight = tr_data_ref.current ? tr_data_ref.current.getBoundingClientRect().height : 35
        const VIEWPORT_HEIGHT = scrollContainerRef.current.clientHeight - rowHeight

        const lastHighlightedRow = highlighted[highlighted.length - 1]
        if (lastHighlightedRow) {
            const index = tableData.findIndex(x => x._id === lastHighlightedRow)
            const minY = (index + 1) * rowHeight
            if (minY > VIEWPORT_HEIGHT + scrollContainerRef.current.scrollTop) {
                scrollContainerRef.current.scrollTop += minY - scrollContainerRef.current.scrollTop - VIEWPORT_HEIGHT
            } else if (minY - rowHeight < scrollContainerRef.current.scrollTop) {
                scrollContainerRef.current.scrollTop += minY - rowHeight - scrollContainerRef.current.scrollTop
            }
        }
    }, [highlighted])

    useEffect(() => {
        textFieldRef.current && textFieldRef.current.focus()
    }, [textFieldRef])

    const renderColumnTitle = (col, row, _entityGroupByKey) => {
        let colTitle = col?.defaultTitle
        if (typeof col?.title === 'function' && _entityGroupByKey) {
            colTitle = col?.title({
                dataProps,
                row,
                ...(_entityGroupByKey
                    ? {
                          entityGroupByKey
                      }
                    : {})
            })
        }
        if (typeof col?.title === 'string' && col?.title !== '') {
            colTitle = col?.title + ' '
        }

        if (col?.title === undefined && col?.dataIndex && _entityGroupByKey && _entityGroupByKey[col?.dataIndex]) {
            colTitle = _entityGroupByKey[col?.dataIndex].fieldName
        }

        if (React.isValidElement(col?.title)) {
            colTitle = col?.title
        }
        if (typeof colTitle === 'undefined') colTitle = col.defaultTitle
        return colTitle
    }

    const eventDelegationHandler = e => {
        const dataColAttroffsetParent = JSON.parse(e?.target?.offsetParent?.getAttribute('data-col') || '{}') || {}
        const dataColAttr = JSON.parse(e?.target?.getAttribute('data-col') || '{}') || {}
        const dataCol = Object.assign({}, dataColAttroffsetParent, dataColAttr)

        const dataRowAttroffsetParent = JSON.parse(e.target?.offsetParent?.getAttribute('data-row') || '{}') || {}
        const dataRowAttr = JSON.parse(e.target?.getAttribute('data-row') || '{}') || {}
        const dataRow = Object.assign({}, dataRowAttroffsetParent, dataRowAttr)

        if (dataCol?.widget || dataRow?.widget) {
            widgetCallback(dataRow, dataCol, e)
        }
    }

    return (
        <TableProvider tableFields={tableFields} displayFields={displayFields}>
            <div
                className={`table-comp relative w-full ${className}`}
                style={
                    isResizing
                        ? {
                              cursor: 'col-resize'
                          }
                        : {}
                }>
                {action && (
                    <div className={`table__actions ${!showControls ? 'hide' : ''}`}>
                        <Controls
                            selected={havePriceValidityFilter ? formattedSelected : selected}
                            isFilter={isFilter}
                            setFilter={setFilter}
                            filter={filter}
                            sort={sort}
                            isSelectView={isSelectView}
                            havePriceValidityFilter={havePriceValidityFilter}
                            tableViews={tableViews}
                            displayFields={displayFields}
                            visibleColumn={visibleColumn}
                            autoSaveView={autoSaveView}
                            tableFields={tableFields}
                            tableData={tableData}
                            dataProps={dataProps}
                            data={data}
                            setTableData={setTableData}
                            reorder={reorder}
                            openReorder={openReorder}
                            setOpenReorder={setOpenReorder}
                            onUpdateView={onUpdateView}
                            onAddView={onAddView}
                            onLoadView={onLoadView}
                            onRemoveView={onRemoveView}
                            formatColumns={formatColumns}
                            tableKey={tableKey}
                            columns={columns}
                            show={showControls}
                            controlsRender={controlsRender}
                            lastSelectedView={lastSelectedView}
                            csvExport={csvExport}
                            csvExportAll={csvExportAll}
                            csvExportAllFileName={csvExportAllFileName}
                            csvExportAllParam={csvExportAllParam}
                            parentComponent={parentComponent}
                            csvFilename={csvFilename}
                            readOnly={readOnly}
                            onRestoreDefaultView={onRestoreDefaultView}
                            onQuery={onQuery}
                            onlySearch={onlySearch}
                            placeholder={placeholder}
                            searchAsFilter={searchAsFilter}
                            count={details.count}
                            loading={isDataLoading}
                            entityGroupByKey={entityGroupByKey || null}
                            plceholder={placeholder}
                            isEditable={isEditable}
                            onEditHandler={onEditHandler}
                        />
                        {showControls &&
                            filter.map((fil, index) => (
                                <div key={index} className='table__filter'>
                                    <Feather
                                        name='X'
                                        className='cursor_pointer'
                                        onClick={() => {
                                            const list = [...filter]
                                            const element = list[index]
                                            list.splice(index, 1)
                                            if (element.dataIndex && element.text !== '') {
                                                onFilter(list)
                                            }
                                            setFilter(list)
                                        }}
                                    />
                                    <span>WHERE</span>
                                    <Dropdown
                                        parentComponent={parentComponent}
                                        options={displayFields
                                            .filter(e => !e.hidden && e.filter)
                                            .map(e => {
                                                return {
                                                    label: e.defaultTitle || e.title?.toString(),
                                                    value: e.dataIndex,
                                                    type: e.type
                                                }
                                            })}
                                        placeholder={'FIELDS'}
                                        value={fil?.dataIndex?.toString()}
                                        onChange={e => {
                                            const list = [...filter]
                                            list[index] = {
                                                ...list[index],
                                                dataIndex: e.value,
                                                column: e.label,
                                                type: e.type,
                                                text: list[index].text ? list[index].text : ''
                                            }
                                            setFilter(list)
                                        }}
                                    />
                                    <span>IS</span>
                                    <TextField
                                        type='search'
                                        name='search'
                                        placeholder='Add search'
                                        value={fil.text || ''}
                                        onChange={e => {
                                            const list = [...filter]
                                            list[index].text = e.target.value
                                            setFilter(list)
                                        }}
                                        onKeyPress={() => {
                                            if (event.keyCode === 13) {
                                                onFilter(filter)
                                            }
                                        }}
                                        iconProps={[{ src: SearchIcon, alignment: 'left', onClick: () => onFilter(filter) }]}
                                    />
                                </div>
                            ))}
                        {showControls && filter.length > 0 && (
                            <div className='flex-start'>
                                <div className='ml-20'>
                                    <Button
                                        style={{
                                            width: '120px'
                                        }}
                                        customClass='btnold--secondary center'
                                        ripple
                                        onClick={() => onFilter(filter)}>
                                        <span>Apply</span>
                                    </Button>
                                </div>
                                <div className='ml-20'>
                                    <Button
                                        style={{
                                            width: '120px'
                                        }}
                                        customClass='btnold--secondary center'
                                        ripple
                                        onClick={() => {
                                            setFilter([])
                                            onFilter([])
                                        }}>
                                        <span>Clear all</span>
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                <div ref={table_ref} className={`TableComponent ${tableData.length > 0 ? '' : 'overflow-hidden'} ${showBlankRow ? `blank-row${footer ? '-footer' : ''}` : ''}`}>
                    {displayFields.length > 0 && (
                        <table ref={scrollContainerRef} style={{ '--is-sticky': dataProps?.threeDotsSticky ? 'sticky' : 'relative' }}>
                            {!dataProps?.collapsedTable && (
                                <thead>
                                    <tr ref={resize_ref} onMouseEnter={() => setSortingVisible(true)} onMouseLeave={() => setSortingVisible(false)}>
                                        {/*  Empty column for expandable tables     */}
                                        {Boolean(expandable) && <th />}

                                        {/*  Multiple select checkbox column    */}
                                        {checkBox && !(readOnly && Boolean(hideCheckBoxOnReadOnly)) && (
                                            <th
                                                className={`text_left ${openReorder ? 'vertical-lines' : ''}`}
                                                style={{
                                                    width: '75px'
                                                }}>
                                                {!onlyOneSelectionEnabled && (
                                                    <Checkbox id='toggle-all' className='mt-1' checkall={true} checked={isAllToggled} onChange={onToggleAllCheckBox} />
                                                )}
                                            </th>
                                        )}

                                        {displayFields.map((dF, col_index) => {
                                            const foundColumn = columns.find(c => c.dataIndex === dF.dataIndex) || {}
                                            const col = { ...foundColumn, ...dF }
                                            if (!(readOnly && Boolean(col.hideOnReadOnly)))
                                                return (
                                                    <Th
                                                        key={col.id}
                                                        id={col.id}
                                                        bg={col.bg}
                                                        color={col.color}
                                                        style={{
                                                            ...(formatColumns[col.dataIndex]?.getHeaderStyle &&
                                                            formatColumns[col.dataIndex].getHeaderStyle({
                                                                ...(dataProps
                                                                    ? {
                                                                          dataProps
                                                                      }
                                                                    : {}),
                                                                readOnly
                                                            }) != null
                                                                ? formatColumns[col.dataIndex].getHeaderStyle({
                                                                      ...(dataProps
                                                                          ? {
                                                                                dataProps
                                                                            }
                                                                          : {}),
                                                                      readOnly
                                                                  })
                                                                : {}),
                                                            ...(displayFields.findIndex(e => e.id === col.id) === displayFields.length - 1
                                                                ? {
                                                                      minWidth: `${col.width}px`
                                                                  }
                                                                : {
                                                                      minWidth: `${col.width}px`,
                                                                      width: `${col.width}px`
                                                                  })
                                                        }}
                                                        className={`text_${formatColumns[col.dataIndex]?.headAlign || 'left'} ${openReorder ? 'vertical-lines' : ''}`}>
                                                        {formatColumns[col.dataIndex]?.headerRender &&
                                                        formatColumns[col.dataIndex].headerRender({
                                                            ...(dataProps
                                                                ? {
                                                                      dataProps
                                                                  }
                                                                : {})
                                                        }) != null ? (
                                                            formatColumns[col.dataIndex].headerRender({
                                                                ...(dataProps
                                                                    ? {
                                                                          dataProps
                                                                      }
                                                                    : {})
                                                            })
                                                        ) : col.title !== '' ? (
                                                            <div className='header-with-icon'>
                                                                {reorder && openReorder ? (
                                                                    <div className='reorder'>
                                                                        {openReorder && tableFields.findIndex(e => e.id === col.id) > 0 && (
                                                                            <Feather
                                                                                name='ChevronLeft'
                                                                                onClick={() => {
                                                                                    const list = _cloneDeep(tableFields)
                                                                                    const index = list.findIndex(e => e.id === col.id)
                                                                                    if (index !== -1) {
                                                                                        const temp = list[index]
                                                                                        list[index] = list[index - 1]
                                                                                        list[index - 1] = temp
                                                                                    }
                                                                                    onChangeColumns(list)
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                {col?.widget && (
                                                                    <div
                                                                        style={{ marginRight: '7px', cursor: 'pointer' }}
                                                                        onClick={e => widgetCallback({}, { ...col, widget: `bulk-${col.widget}` }, e)}>
                                                                        <Image src={Pencil} width={12} height={12} />
                                                                    </div>
                                                                )}
                                                                {col?.title !== '' ? (
                                                                    <div
                                                                        className='full-flex'
                                                                        //style={{ width: `${col.width - 50}px` }}
                                                                    >
                                                                        <div
                                                                            className={`column_title ${col.sort ? ' cursor_pointer' : ''}`}
                                                                            onClick={() => {
                                                                                if ((!reorder || !openReorder) && col.sort)
                                                                                    onSort({
                                                                                        sort: col.dataIndex,
                                                                                        order: sort.order === 'asc' ? 'desc' : 'asc',
                                                                                        sortValue: col.sortValue,
                                                                                        type: col.type
                                                                                    })
                                                                            }}>
                                                                            {renderColumnTitle(col, tableData[col_index], entityGroupByKey)}
                                                                            {!readOnly && (
                                                                                <b
                                                                                    style={{
                                                                                        color: 'red'
                                                                                    }}>
                                                                                    {col.required && '*'}
                                                                                </b>
                                                                            )}
                                                                        </div>

                                                                        {(!reorder || !openReorder) && col.sort && sortingVisible && (
                                                                            <div className='column_sort'>
                                                                                <Feather
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            (!reorder || !openReorder) &&
                                                                                            col.sort &&
                                                                                            (sort.order != 'asc' || sort.sort != col.dataIndex)
                                                                                        )
                                                                                            onSort({
                                                                                                sort: col.dataIndex,
                                                                                                order: 'asc',
                                                                                                sortValue: col.sortValue,
                                                                                                type: col.type
                                                                                            })
                                                                                    }}
                                                                                    name='ChevronUp'
                                                                                    className={`column_sort--arrow up ${
                                                                                        sort.sort === col.dataIndex && sort.order === 'asc' ? 'active' : ''
                                                                                    }`}
                                                                                />
                                                                                <Feather
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            (!reorder || !openReorder) &&
                                                                                            col.sort &&
                                                                                            (sort.order != 'desc' || sort.sort != col.dataIndex)
                                                                                        )
                                                                                            onSort({
                                                                                                sort: col.dataIndex,
                                                                                                order: 'desc',
                                                                                                sortValue: col.sortValue,
                                                                                                type: col.type
                                                                                            })
                                                                                    }}
                                                                                    name='ChevronDown'
                                                                                    className={`column_sort--arrow down ${
                                                                                        sort.sort === col.dataIndex && sort.order === 'desc' ? 'active' : ''
                                                                                    }`}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    ''
                                                                )}

                                                                {reorder && openReorder ? (
                                                                    <div className='reorder'>
                                                                        {openReorder && tableFields.findIndex(e => e.id === col.id) < tableFields.length - 1 && (
                                                                            <Feather
                                                                                name='ChevronRight'
                                                                                onClick={() => {
                                                                                    const list = _cloneDeep(tableFields)
                                                                                    const index = list.findIndex(e => e.id === col.id)
                                                                                    if (index !== -1) {
                                                                                        const temp = list[index]
                                                                                        list[index] = list[index + 1]
                                                                                        list[index + 1] = temp
                                                                                    }
                                                                                    onChangeColumns(list)
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {resizing && (
                                                            <div
                                                                className='dragbar dragbar--left'
                                                                onMouseDown={() => {
                                                                    setResizing(col_index + (checkBox ? 1 : 0) + (expandable != null ? 1 : 0))
                                                                }}></div>
                                                        )}
                                                    </Th>
                                                )
                                        })}
                                    </tr>
                                </thead>
                            )}
                            <tbody>
                                {newRow && (
                                    <tr ref={newRow_ref} data-col={col?.id}>
                                        <td data-col={col?.id}>
                                            <Feather name='X' className='absolute-center' onClick={onCancelAddClick} />
                                        </td>
                                        {checkBox && Boolean(expandable) && (
                                            <td className='noselect' data-col={col?.id}>
                                                <Checkbox id='expandable' checked={false} />
                                            </td>
                                        )}
                                        {displayFields.map((col, index) => {
                                            return adding ? (
                                                <td
                                                    key={col.id || index}
                                                    style={{
                                                        borderColor: '#FFF'
                                                    }}
                                                    className='td-anim'
                                                    data-col={col?.id}>
                                                    <span></span>
                                                </td>
                                            ) : (
                                                !col.hidden && (
                                                    <td key={col.id || index} data-col={col?.id}>
                                                        {formatColumns[col.dataIndex]?.newRowRender ? (
                                                            formatColumns[col.dataIndex].newRowRender({
                                                                col,
                                                                row: newEntry,
                                                                ...(dataProps
                                                                    ? {
                                                                          dataProps
                                                                      }
                                                                    : {}),
                                                                ...(handlerDispatch
                                                                    ? {
                                                                          handlerDispatch
                                                                      }
                                                                    : {}),
                                                                className: 'type-text',
                                                                style:
                                                                    displayFields.findIndex(e => e.id === col.id) === displayFields.length - 1
                                                                        ? {
                                                                              minWidth: `${col.width}px`
                                                                          }
                                                                        : {
                                                                              minWidth: `${col.width}px`
                                                                              // width: `${col.width}px`
                                                                          }
                                                            })
                                                        ) : ['string', 'number'].includes(col.type) ? (
                                                            <TextField
                                                                disabled={false}
                                                                readOnly={readOnly}
                                                                placeholder={`${col.title} ${col.required ? '*' : ''}`}
                                                                type={col.type === 'string' ? 'text' : 'number'}
                                                                value={newEntry[col.dataIndex] || ''}
                                                                onChange={e => {
                                                                    const list = _cloneDeep(newEntry)
                                                                    list[col.dataIndex] = col.type === 'string' ? e.target.value : parseFloat(e.target.value)
                                                                    onChangeNewEntry(list, col.dataIndex)
                                                                }}
                                                                note={col.note ? col.note : null}
                                                                onKeyUp={onKeyUp}
                                                            />
                                                        ) : col.type === 'enum' ? (
                                                            <Dropdown
                                                                parentComponent={parentComponent}
                                                                options={col.values?.map(e => {
                                                                    return {
                                                                        label: e.label.toString(),
                                                                        value: e.value
                                                                    }
                                                                })}
                                                                placeholder={'Add select item'}
                                                                value={newEntry[col.dataIndex]}
                                                                onChange={e => {
                                                                    const list = _cloneDeep(newEntry)
                                                                    list[col.dataIndex] = e.value
                                                                    onChangeNewEntry(list, col.dataIndex)
                                                                }}
                                                            />
                                                        ) : col.type === 'datepicker' ? (
                                                            <DatePicker
                                                                value={newEntry[col.dataIndex]}
                                                                onChangeDate={date => {
                                                                    const list = _cloneDeep(newEntry)
                                                                    list[col.dataIndex] = date
                                                                    onChangeNewEntry(list, col.dataIndex)
                                                                }}
                                                            />
                                                        ) : col.type === 'checkbox' ? (
                                                            <Checkbox
                                                                id={`checkbox-dataIndex-${col.id || index}`}
                                                                checked={newEntry[col.dataIndex]}
                                                                onChange={() => {
                                                                    const list = _cloneDeep(newEntry)
                                                                    list[col.dataIndex] = !list[col.dataIndex]
                                                                    onChangeNewEntry(list, col.dataIndex)
                                                                }}
                                                            />
                                                        ) : (
                                                            newEntry[col.dataIndex]
                                                        )}
                                                    </td>
                                                )
                                            )
                                        })}
                                    </tr>
                                )}

                                {loadingData ? (
                                    tableData.length > 0 ? (
                                        tableData.map((row, index) => (
                                            <tr key={row.key ? row.key : row._id || index}>
                                                {Boolean(expandable) && (
                                                    <td
                                                        key={index}
                                                        style={{
                                                            borderColor: '#FFF'
                                                        }}
                                                        className='td-anim'
                                                    />
                                                )}
                                                {checkBox && (
                                                    <td
                                                        key={index}
                                                        style={{
                                                            borderColor: '#FFF'
                                                        }}
                                                        className='td-anim'>
                                                        <span></span>
                                                    </td>
                                                )}
                                                {[...displayFields].map((e, index) => (
                                                    <td
                                                        key={index}
                                                        style={{
                                                            borderColor: '#FFF'
                                                        }}
                                                        className='td-anim'>
                                                        <span></span>
                                                    </td>
                                                ))}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            {Boolean(expandable) && (
                                                <td
                                                    style={{
                                                        borderColor: '#FFF'
                                                    }}
                                                    className='td-anim'
                                                />
                                            )}
                                            {checkBox && (
                                                <td
                                                    style={{
                                                        borderColor: '#FFF'
                                                    }}
                                                    className='td-anim'>
                                                    <span></span>
                                                </td>
                                            )}
                                            {[...displayFields].map((e, index) => (
                                                <td
                                                    key={index}
                                                    style={{
                                                        borderColor: '#FFF'
                                                    }}
                                                    className='td-anim'>
                                                    <span></span>
                                                </td>
                                            ))}
                                        </tr>
                                    )
                                ) : (
                                    tableData.map((row, index) => {
                                        const validated_rows = validate_mod ? validate_mod.filter(vm => vm._id === row._id) : null
                                        const key = row.key ? `${row.key}-${index}` : row.id || row._id || index
                                        const numberOfDisplayField = displayFields.length + (Boolean(expandable) ? 1 : 0) + (checkBox ? 1 : 0)
                                        const isCheckboxSelected = isRowInactive(row)
                                            ? false
                                            : (selected || []).findIndex(item => (row.key ? item.key == row.key : item._id == row._id)) !== -1
                                        return (
                                            <React.Fragment key={key}>
                                                <tr
                                                    ref={tr_data_ref}
                                                    onDoubleClick={() => {
                                                        onDoubleClickRow(row)
                                                    }}
                                                    onClick={() => onClickRow(row)}
                                                    className={classNames({
                                                        blink: deleting && deletingFields.includes(row._id),
                                                        active: highlighted.includes(row._id),
                                                        inActive: isRowInactive(row),
                                                        expanded: !Boolean(expandedRows[key])
                                                    })}
                                                    style={{ ...row.trstyle }}>
                                                    {/* COL0: Expanding row arrow  */}
                                                    {(Boolean(expandable) || (!Boolean(expandable) && dataProps?.collapsedTable)) && (
                                                        <td
                                                            style={{
                                                                padding: '0px',
                                                                width: '20px'
                                                            }}
                                                            className={classNames('TableComponent__expandable__td')}
                                                            onClick={() =>
                                                                setExpandedRows(prev => ({
                                                                    ...prev,
                                                                    [key]: !prev[key]
                                                                }))
                                                            }>
                                                            {Boolean(expandable) && (
                                                                <Feather
                                                                    name={expandedRows[key] ? 'ChevronRight' : 'ChevronDown'}
                                                                    style={{
                                                                        visibility:
                                                                            (row.accordionPricesList?.length && havePriceValidityFilter) ||
                                                                            (!havePriceValidityFilter && !dataProps.isPricingTab) ||
                                                                            (dataProps.isPricingTab && row.accordionPricesList?.length)
                                                                                ? 'visible'
                                                                                : 'hidden'
                                                                    }}
                                                                />
                                                            )}
                                                        </td>
                                                    )}

                                                    {/* COL1: Multi row checkbox  */}
                                                    {checkBox && !(readOnly && Boolean(hideCheckBoxOnReadOnly)) && !dataProps?.collapsedTable && (
                                                        <td className='noselect'>
                                                            <Checkbox
                                                                id={`checkbox-readonly-${key}`}
                                                                checked={!!isCheckboxSelected}
                                                                disabled={isRowInactive(row)}
                                                                onChange={e => onChangeSingleCheckBox(row, e.target.checked)}
                                                            />
                                                        </td>
                                                    )}
                                                    {checkBox && dataProps?.collapsedTable && <td className='noselect' />}

                                                    {/* The rest of the data fields  */}
                                                    {
                                                        displayFields.map((col, index) => {
                                                            const validated_row = validated_rows ? validated_rows.find(vm => vm.col === col.dataIndex) : {}

                                                            if (!(readOnly && Boolean(col.hideOnReadOnly)))
                                                                return editting && row._id === editFields.row && editFields.col.includes(col.dataIndex) ? (
                                                                    /*  Single field editing animation! NOTE: It will not be used     */
                                                                    <td key={col.id || index} className='td-anim'>
                                                                        <span></span>
                                                                    </td>
                                                                ) : (
                                                                    /*  Start of TD section    */
                                                                    <td
                                                                        key={col.key || col.id || index}
                                                                        onDoubleClick={() => {
                                                                            if (!readOnly && col.editable && col.type !== 'checkbox') {
                                                                                setEditVal({
                                                                                    id: row.key || row._id,
                                                                                    col: col.dataIndex,
                                                                                    value: row[col.dataIndex],
                                                                                    obj: row
                                                                                })
                                                                            }
                                                                        }}
                                                                        style={{
                                                                            ...col.customStyle,
                                                                            ...(formatColumns[col.dataIndex]?.getStyle
                                                                                ? formatColumns[col.dataIndex].getStyle({
                                                                                      row,
                                                                                      ...(dataProps
                                                                                          ? {
                                                                                                dataProps
                                                                                            }
                                                                                          : {}),
                                                                                      readOnly
                                                                                  })
                                                                                : {})
                                                                        }}
                                                                        className={`noselect text_${formatColumns[col.dataIndex]?.align || 'left'} ${
                                                                            validated_row?.error ? 'validate-error' : validated_row?.warning ? 'validate-warning' : ''
                                                                        }`}>
                                                                        {/*     EDITOR components for row editing   */}
                                                                        {editVal.id === (row.key ? row.key : row._id) && editVal.col === col.dataIndex ? (
                                                                            <div className='validated_text'>
                                                                                {['string', 'text', 'number'].includes(col.type) ? (
                                                                                    <TextField
                                                                                        onFocus={e => {
                                                                                            e.target.select()
                                                                                        }}
                                                                                        ref={textFieldRef}
                                                                                        autoFocus
                                                                                        placeholder={`${typeof col.title === 'string' ? col.title : ''} ${col.required ? '*' : ''}`}
                                                                                        type='text'
                                                                                        value={editVal.value}
                                                                                        onChange={e => {
                                                                                            const temp = {
                                                                                                ...editVal
                                                                                            }
                                                                                            if (checkValidFloat(e.target.value)) {
                                                                                                temp.value = e.target.value
                                                                                            }
                                                                                            setEditVal(temp)
                                                                                        }}
                                                                                        onKeyPress={() => {
                                                                                            if (event.keyCode === 13) {
                                                                                                const tmp = { ...editVal }
                                                                                                if (col.type === 'number') {
                                                                                                    tmp.value = parseFloat(editVal.value)
                                                                                                }
                                                                                                onEditClick(row.key || row._id, tmp)
                                                                                                setEditVal({})
                                                                                            }
                                                                                        }}
                                                                                        onBlur={() => setEditVal({})}
                                                                                        note={col.note ? col.note : null}
                                                                                    />
                                                                                ) : col.type === 'datepicker' ? (
                                                                                    <DatePicker
                                                                                        value={editVal.value}
                                                                                        onChangeDate={date => {
                                                                                            const temp = {
                                                                                                ...editVal
                                                                                            }
                                                                                            temp.value = date
                                                                                            onEditClick(row.key || row._id, temp)
                                                                                            setEditVal({})
                                                                                        }}
                                                                                        onBlur={() => setEditVal({})}
                                                                                    />
                                                                                ) : null}
                                                                            </div>
                                                                        ) : /*  RENDER components    */
                                                                        formatColumns[col.dataIndex]?.render ? (
                                                                            /*  Custom render (provided with custom render function)  */
                                                                            formatColumns[col.dataIndex].render({
                                                                                row,
                                                                                ...(dataProps
                                                                                    ? {
                                                                                          dataProps
                                                                                      }
                                                                                    : {}),
                                                                                readOnly,
                                                                                ...(handlerDispatch
                                                                                    ? {
                                                                                          handlerDispatch
                                                                                      }
                                                                                    : {}),
                                                                                className: 'type-text',
                                                                                history,
                                                                                style:
                                                                                    displayFields.findIndex(e => e.id === col.id) === displayFields.length - 1
                                                                                        ? {
                                                                                              minWidth: `${col.width}px`
                                                                                          }
                                                                                        : {
                                                                                              minWidth: `${col.width}px`,
                                                                                              width: `${col.width}px`
                                                                                          }
                                                                            })
                                                                        ) : (
                                                                            <div className='validated_text' data-cy='validatedText'>
                                                                                {/*      ENUMERATION         */}
                                                                                {col.type === 'enum' ? (
                                                                                    <Dropdown
                                                                                        parentComponent={parentComponent}
                                                                                        options={col.values?.map(e => {
                                                                                            return {
                                                                                                label: e.label.toString(),
                                                                                                value: e.value,
                                                                                                bg: e.bg,
                                                                                                color: e.color
                                                                                            }
                                                                                        })}
                                                                                        placeholder={`Add select item`}
                                                                                        value={row[col.dataIndex]}
                                                                                        readOnly={!col.editable}
                                                                                        onChange={e => {
                                                                                            const temp = {
                                                                                                id: row._id,
                                                                                                col: col.dataIndex,
                                                                                                value: e.value
                                                                                            }
                                                                                            onEditClick(row.key || row._id, temp, col.dataIndex)
                                                                                            setEditVal({})
                                                                                        }}
                                                                                    />
                                                                                ) : col.type === 'checkbox' ? (
                                                                                    <Checkbox
                                                                                        id={`checkbox-edit-${col.id || index}`}
                                                                                        checked={row[col.dataIndex]}
                                                                                        onChange={e => {
                                                                                            if (!readOnly && col.editable) {
                                                                                                onEditClick(row._id, {
                                                                                                    id: row._id,
                                                                                                    col: col.dataIndex,
                                                                                                    value: e.target.checked,
                                                                                                    obj: row
                                                                                                })
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                ) : typeof row[col.dataIndex] === 'object' ? (
                                                                                    <span
                                                                                        className='type-text'
                                                                                        style={
                                                                                            displayFields.findIndex(e => e.id === col.id) === displayFields.length - 1
                                                                                                ? {
                                                                                                      minWidth: `${col.width}px`
                                                                                                  }
                                                                                                : {
                                                                                                      minWidth: `${col.width}px`,
                                                                                                      width: `${col.width}px`
                                                                                                  }
                                                                                        }>
                                                                                        {row[col.dataIndex]?.name && 'NOT DEFINED'}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className='type-text' data-cy='typeText'>
                                                                                        {row[col.dataIndex]} {row[col.dataIndex] && col.note ? col.note : null}
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        )}

                                                                        {/* Validation  popup   */}
                                                                        {validated_row && validated_row.col === col.dataIndex && validate_popup(validated_row)}
                                                                    </td>
                                                                ) //  End of td section
                                                        }) //  End of all columns for a single row section
                                                    }
                                                </tr>

                                                {/* Show expandable row */}
                                                {Boolean(expandable) && Boolean(expandedRows[key]) && (
                                                    <tr
                                                        className={classNames('TableComponent__expandable__tr', {
                                                            '--expanded': Boolean(expandedRows[key])
                                                        })}>
                                                        <td colSpan={numberOfDisplayField}>
                                                            <div className='expandable__inner'>{expandable(row)}</div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        )
                                    })
                                )}
                                {tableData.length < paddingRowNo && (
                                    <tr className='empty-row'>
                                        <td
                                            style={{
                                                height: `${(paddingRowNo - tableData.length - (loadingData && tableData.length === 0 ? 1 : 0)) * 50}px`
                                            }}
                                            colSpan={numberOfFields()}>
                                            {tableData.length === 0 && !newRow ? (
                                                <span className='no-data-text' style={{ top: footer ? 'calc(50% - 25px)' : '50%' }}>
                                                    {' '}
                                                    {blankRowData}
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </tr>
                                )}
                            </tbody>

                            {footer && (
                                <tfoot>
                                    <tr className={`table__footer ${tableData.length === 0 ? 'no-data' : ''}`}>
                                        {Boolean(expandable) && <td />}
                                        {checkBox && <td />}
                                        {[...tableFields]
                                            .filter(e => !e.hidden)
                                            .map((col, index) => (
                                                <td key={col.id || index} className={`text-${col.align || 'left'}`}>
                                                    {Boolean(col.currency) ? <Currency value={footer[col.dataIndex]} /> : footer[col.dataIndex]}
                                                </td>
                                            ))}
                                    </tr>
                                </tfoot>
                            )}
                        </table>
                    )}
                </div>

                {controlPanel && pagination && (
                    <Pagination
                        customStyle={customStyle}
                        tableData={tableData}
                        pagination={pagination}
                        parentComponent={parentComponent}
                        limit={limit}
                        data={data}
                        details={details}
                        controlPanel={controlPanel}
                        onChangeLimit={onChangeLimit}
                        onChangePage={onChangePage}
                        onToggleAllCheckBox={onToggleAllCheckBox}
                        onSelected={onSelected}
                    />
                )}
                {loading && <Spinner />}
            </div>
        </TableProvider>
    )
}

export default Table
