import { useState } from 'react';
import { Prompt } from 'react-router-dom';

import { Lock_gray, Product_Other } from '@assets';

import { Show } from '@common'
import { Button, Feather, Image, Popover, StatusBadge, useNotify } from '@components';
import { UNDEFINED } from '@constants/status';

import { ADDING, EDITING, NORMAL } from './const';

import Mapping from './mapping';

import '@scss/components/_master-header.scss';

const MasterDataHeader = props => {
	const {
		promptEnabled = true,
		entity,
		entityPrefix = '',
		entityName = '',
		entityNo = '',
		displayInfo = true,
		render,
		controlRender,
		buttonRender,
		documentStatus = UNDEFINED,
		documentStatusCustomText = null,
		headerStatus = NORMAL,
		actions = null,
		loading = false,
		hideActions = false,
		hideCloseButton = false,
		popupWidth = 180,
		onEditHandler = () => { },
		onSaveHandler = () => { },
		onDeleteHandler = () => { },
		onCancelHandler = () => { },
		onCloseHandler = () => { },
		onChangeStatusHandler = () => { }
	} = props;

	let title, icon;

	if (typeof entity === 'object' && entity !== null) {
		const exist = Mapping.find(val => val.type === entity.key?.trim()?.toLowerCase());
		const MappingEntity = exist || { title: entity?.name || entity?.key, icon: Product_Other };
		icon = MappingEntity?.icon;
		title = MappingEntity?.title;
	} else {
		const exist = Mapping.find(val => val.type === entity?.trim()?.toLowerCase());
		const MappingEntity = exist || { title: entity || '', icon: Product_Other };
		title = MappingEntity?.title;
		icon = MappingEntity?.icon;
	}

	const [onProjectHover, setProjectHover] = useState(false);

	const { notify } = useNotify();

	const onCopyHandler = entityName => {
		notify({
			title: 'Warning',
			description: `Name ${entityName} is copied to clipboard.`,
			duration: 5000,
			type: 'warning'
		});
		navigator.clipboard.writeText(entityName);
	};

	return (
		<div className='MasterData_Header'>
			{promptEnabled && <Prompt when={headerStatus === EDITING} message='Please save changes before leaving the page. Thank You!' />}
			<div className='MasterData_Header__MainContainer'>
				<Show condition={displayInfo}>
					<div className='info-wrapper'>
						<div className='flex flex-fix'>
							<div className='profile'>
								<div className='profile'>
									<div className='profile-icon'>{icon ? <img className='user-icon' src={icon} alt='icon' /> : ''}</div>
								</div>
								<div className='status'>
									<p className='entity'>{entityPrefix + (title || '')}</p>
									<div className='status__withLock'>
										<StatusBadge status={documentStatus} customText={documentStatusCustomText} />
										{headerStatus === NORMAL && (
											<div className='status__withLock--icon'>
												<Image src={Lock_gray} size={13} />
											</div>
										)}
										{controlRender && <div>{controlRender}</div>}
									</div>
								</div>
							</div>
							<div className='entity-div'>
								<div className='entity-no'>
									<h2>{entityNo}</h2>
								</div>
								<div className='entity-name noselect' onMouseEnter={() => setProjectHover(true)} onMouseLeave={() => setProjectHover(false)}>
									<h1 data-cy='entity-name'>{entityName}</h1>
									{onProjectHover && <Feather className='copy-name-icon' size={23} name='Copy' color='#0e3f66' onClick={() => onCopyHandler(entityName)} />}
								</div>
							</div>
						</div>
					</div>
				</Show>
				{render && <div className='MasterData_Header__render'>{render}</div>}
			</div>
			{buttonRender
				? buttonRender
				: !loading && (
					<div className='btn-wrapper'>
						<div className='row justify-flex-end'>
							{!hideCloseButton && (
								<Button onClick={onCloseHandler} customClass={'btnold--borderless-view p-0'} style={{ width: '100px', margin: '5px 10px 0 0' }}>
									<Feather name='X' />
									<span>Close</span>
								</Button>
							)}
						</div>
						<div className='row justify-flex-end' style={{ position: 'absolute', bottom: '10px', right: '25px' }}>
							{!hideActions &&
								(headerStatus === NORMAL ? (
									<Popover
										extraStyle={{ marginRight: '10px', width: `${popupWidth}px` }}
										element={
											<Button customClass='btnold--primary center Popover__btn' style={{ width: `${popupWidth}px` }}>
												<span className='m-auto'>Actions</span>
												<Feather name='ChevronDown' />
											</Button>
										}
										options={
											actions || [
												{ title: 'Change status', action: onChangeStatusHandler },
												{ title: 'Edit', action: onEditHandler },
												{ title: 'Delete', action: onDeleteHandler }
											]
										}
										popupWidth={popupWidth}
									/>
								) : headerStatus === EDITING || headerStatus === ADDING ? (
									<div style={{ display: 'inline-flex' }}>
										<Button
											customClass='btnold--secondary w-small center'
											onClick={() => {
												onSaveHandler();
											}}>
											Save
										</Button>
										{onCancelHandler && (
											<Button
												customClass='btnold--information w-small center ml-20'
												onClick={() => {
													onCancelHandler();
												}}>
												Cancel
											</Button>
										)}
									</div>
								) : (
									''
								))}
						</div>
					</div>
				)}
		</div>
	);
};

export default MasterDataHeader;
