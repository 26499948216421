import service from './service'

export const checkIfNameExists = async name => {
    const res = await service.get(`/customers/name-exist/${name}`)
    return res.data
}

export const checkIfEmailExists = async email => {
    const res = await service.get(`/customers/email-exist/${email}`)
    return res.data
}

export const checkIfPhoneExists = async phone => {
    const res = await service.get(`/customers/phone-exist/${phone}`)
    return res.data
}

export const fetchCustomers = async (params = {}) => {
    const res = await service.get(`/customers`, { params })
    return res.data
}

export const fetchCustomer = async id => {
    const res = await service.get(`/customers/${id}`)
    return res.data
}

export const fetchCustomerContacts = async (id, params = {}) => {
    const res = await service.get(`/customers/${id}/contacts`, { params })
    return res.data
}

export const fetchCustomerPrimaryContact = async id => {
    const res = await service.get(`/customers/${id}/contacts`, {
        params: { filterColumns: 'status,type', filterValues: 'active,primary', limit: 1, sort: 'created', order: 'asc', page: 1 }
    })
    const paginatedData = res.data
    if (!paginatedData?.items?.length) {
        return null
    }

    return paginatedData.items[0]
}

export const fetchNextCustomerNo = async () => {
    const res = await service.get('/customers/no')
    return res.data.customerNo
}

export const updateCustomer = async customer => {
    const res = await service.put(`/customers/${customer._id}`, customer)
    return res.data
}

export const saveCustomer = async customer => {
    const res = await service.post('/customers/', customer)
    return res.data
}

export const deleteCustomer = async id => {
    await service.delete(`/customers/${id}`)
}

export const bulkDeleteCustomers = async ids => {
    const res = await service.post(`/customers/delete`, ids)
    return res.data
}

export const changeStatus = async (id, status, errorCallback = () => {}) => {
    const res = await service.put(`/customers/status/${id}`, { status })
    return res.data
}

export const changeTier = async (id, tier, isSingleTierEnabled) => {
    const res = await service.post(`/customers/tier/${id}`, { tier, isSingleTierEnabled })
    return res.data
}

export const changeMultiplant = async (id, multiplant) => {
    const res = await service.put(`/customers/multiplant/${id}`, { multiplant })
    return res.data
}

export const fetchPriceBeeCustomers = async (params = {}) => {
    const res = await service.get('/customers/price-bee', { params })
    return res.data
}

export const mergeCustomers = async ({ from, to }) => {
    const res = await service.post(`/customers/merge`, { from, to })
    return res.data
}

export const addBatchContactToCustomer = async (id, contacts) => {
    const res = await service.post(`/customers/${id}/contacts`, contacts)
    return res.data
}

export const removeBatchContactToCustomer = async (id, contacts) => {
    const res = await service.post(`/customers/${id}/contacts/delete`, contacts)
    return res.data
}

export const updateCustomerContact = async (id, contactId, data) => {
    const res = await service.put(`/customers/${id}/contacts/${contactId}`, data)
    return res.data
}

export const removeCustomerPrices = async (customerId, prices) => {
    return (await service.post(`/customers/${customerId}/prices/delete`, { prices })).data
}

export const updateCustomerTier = async ({ row, tier }) => {
    const customerId = row?.customer
    const tierId = row?._id
    return (await service.put(`/customers/${customerId}/tiers/${tierId}`, { tier })).data
}
