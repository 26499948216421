import React, { useEffect, useRef, useState } from 'react'

import { Divider, Feather } from '@components'

import { BTN_ADD, BTN_APPLY, BTN_CANCEL, BTN_CLOSE, BTN_CONTINUE, BTN_OK, BTN_SAVE, BTN_TEXT_LIST, BTN_DELETE, BTN_MERGE } from './config'

import '@scss/components/_optionsModal.scss'

const OptionsModal = props => {
    const {
        type = '',
        isOpen,
        buttons = [],
        title,
        icon,
        children,
        width = 480,
        className = '',
        btnTextList = BTN_TEXT_LIST,
        noButtons = false,
        showHeader = true,
        padding = true,
        disabledButtons = {},
        disabledClose = false,
        contentStyle = {},

        onContinueHandler = () => {},
        onCloseHandler = () => {},
        onSaveHandler = () => {},
        onCancelHandler = () => {},
        onApplyHandler = () => {},
        onAddHandler = () => {}
    } = props

    const [changedDomElements, setChangedDomElements] = useState([])
    const optionsModalRef = useRef(null)

    useEffect(() => {
        if (isOpen) {
            const tmp = document.getElementsByClassName('overflow-overlay')
            if (tmp && HTMLCollection.prototype.isPrototypeOf(tmp)) {
                let changed = []
                Array.from(tmp).forEach(element => {
                    if (!element.classList.contains('overflow-hidden')) {
                        changed.push(element)
                        element.classList.add('overflow-hidden')
                    }
                })
                setChangedDomElements(changed)
            }
        } else {
            changedDomElements.map(element => {
                element.classList.remove('overflow-hidden')
            })
        }
        return () => {
            const allElements = document.querySelectorAll('.overflow-hidden')
            allElements.forEach(el => el.classList.remove('overflow-hidden'))
        }
    }, [isOpen])

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { optionsmodalref: optionsModalRef })
        }
        return child
    })

    return (
        <div>
            <div className={`OptionsModal OptionsModal--${isOpen ? 'visible' : 'hidden'} ${className}`} ref={optionsModalRef}>
                <div className='OptionsModal__wrapper' style={{ maxWidth: `${width}px` }}>
                    {showHeader && (
                        <>
                            <div className='OptionsModal__title'>
                                <div className='flex-start flex-v-center'>
                                    {icon}
                                    <h4>{title}</h4>
                                </div>
                                {!disabledClose && (
                                    <button onClick={() => onCloseHandler(type)}>
                                        <Feather className='hover-pointer' name='X' />
                                    </button>
                                )}
                            </div>
                            <Divider />
                        </>
                    )}
                    <div style={contentStyle} className={`OptionsModal__content ${padding ? 'OptionsModal__content--padding' : ''}`}>
                        {childrenWithProps}
                    </div>
                    {!noButtons && (
                        <div className='OptionsModal__footer'>
                            <Divider full />
                            <div className='OptionsModal__buttons buttonModal'>
                                <div className='footer-controls'>
                                    {buttons.includes(BTN_CLOSE) && (
                                        <button className='buttonModal__transparent' onClick={() => onCloseHandler(type)} disabled={disabledButtons.close}>
                                            {btnTextList[BTN_CLOSE]}
                                        </button>
                                    )}
                                    {buttons.includes(BTN_CANCEL) && (
                                        <button className='buttonModal__transparent' onClick={() => onCancelHandler(type)} disabled={disabledButtons.cancel}>
                                            {btnTextList[BTN_CANCEL]}
                                        </button>
                                    )}
                                    {buttons.includes(BTN_APPLY) && (
                                        <button className='buttonModal__blue' onClick={() => onApplyHandler(type)} disabled={disabledButtons.apply}>
                                            {btnTextList[BTN_APPLY]}
                                        </button>
                                    )}
                                    {buttons.includes(BTN_ADD) && (
                                        <button className='buttonModal__blue' onClick={() => onAddHandler(type)}>
                                            {btnTextList[BTN_ADD]}
                                        </button>
                                    )}
                                    {buttons.includes(BTN_CONTINUE) && (
                                        <button className='buttonModal__blue' onClick={() => onContinueHandler(type)} disabled={disabledButtons.continue}>
                                            {btnTextList[BTN_CONTINUE]}
                                        </button>
                                    )}
                                    {buttons.includes(BTN_SAVE) && (
                                        <button className='buttonModal__blue' onClick={() => onSaveHandler(type)} disabled={disabledButtons.save}>
                                            {btnTextList[BTN_SAVE]}
                                        </button>
                                    )}
                                    {buttons.includes(BTN_OK) && (
                                        <button className='buttonModal__blue' onClick={() => onContinueHandler(type)} disabled={disabledButtons.ok}>
                                            {btnTextList[BTN_OK]}
                                        </button>
                                    )}
                                    {buttons.includes(BTN_DELETE) && (
                                        <button className='buttonModal__blue' onClick={() => onContinueHandler(type)} disabled={disabledButtons.continue}>
                                            {btnTextList[BTN_DELETE]}
                                        </button>
                                    )}
                                    {buttons.includes(BTN_MERGE) && (
                                        <button className='buttonModal__blue' onClick={() => onContinueHandler(type)} disabled={disabledButtons.merge}>
                                            {btnTextList[BTN_MERGE]}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default OptionsModal
