import { CSSProperties, PropsWithChildren, ReactElement, useCallback, useState } from 'react'
import { useNotify } from '@components/index'
import Show from '@components/UI/Show'
import { useInfiniteQuery } from '@tanstack/react-query'
import { Spin } from 'antd'
import { Table } from '..'
import { TableColumn } from '@customTypes/table'
import { GridEvent, GridFilterChangeEvent } from '@progress/kendo-react-grid'
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query'
import useTableSettings from '@hooks/useTableSettings'
import { CustomCellProps } from '@common/Table/CustomCell'
import { useDebounceValue } from '@hooks/useDebounceValue'
import { FieldFilter, mergeFilters } from 'filters/filter'
import TableSummary from '@components/UI/Table/table-summary'

type SelectEntityProps = {
    columns: Array<TableColumn>
    selected?: Array<unknown>
    setSelected?: Function
    style?: CSSProperties
    tableKey?: string
    onSelect?: Function
    entityName: string
    fetch: Function
    multiselect?: Boolean
    autosave?: Boolean
    extraFilter?: {
        [key: string]: {
            operations: FieldFilter
        }
    }
    refreshKey?: string
    disableSearch?: boolean
    topContent?: string | ReactElement
    height?: number | string
    filterable?: boolean
    dataProps?: object
    selectable?: boolean
    ready?: boolean
}

const SelectEntity = ({
    columns,
    selected = [],
    setSelected,
    style = {},
    tableKey = '',
    entityName,
    fetch,
    multiselect = true,
    extraFilter = {},
    refreshKey,
    disableSearch = false,
    topContent,
    height,
    filterable = false,
    dataProps = {},
    selectable = true,
    ready = true
}: PropsWithChildren<SelectEntityProps>) => {
    const pageSize = 20
    const tableHeight = typeof height === 'undefined' ? 440 : height
    const rowHeight = 50

    const DEFAULT_FILTER = {
        orderBy: '_id',
        orderDirection: 'asc',
        page: 1,
        pageSize: 20,
        ...extraFilter
    }

    const [filter, setFilter] = useState(DEFAULT_FILTER)
    const [searchQuery, setSearchQuery] = useState('')
    const { notifyError } = useNotify()

    const { sort, onSort, isTableSettingsLoading } = useTableSettings({ tableKey, columns, limit: filter.pageSize })

    const [gridFilter, setGridFilter] = useState<CompositeFilterDescriptor>()
    const debouncedFilter = useDebounceValue(gridFilter, 500)

    const onFilterChange = useCallback((event: GridFilterChangeEvent) => {
        const { filter } = event
        const filters = filter?.filters.filter(f => typeof (f as FilterDescriptor).operator === 'string') || []
        setGridFilter({
            ...filter,
            filters
        })
    }, [])

    const queryKey = [entityName, debouncedFilter, sort, extraFilter]
    if (refreshKey) queryKey.push(refreshKey)

    const {
        fetchNextPage,
        isFetchingNextPage,
        isLoading,
        data: fetchData
    } = useInfiniteQuery<any, any, any>({
        queryKey,
        queryFn: ({ pageParam = 1 }) => {
            const query = mergeFilters({ ...filter, ...extraFilter, page: pageParam, orderBy: sort?.field, orderDirection: sort?.order || 'asc' }, gridFilter)
            return fetch(query)
        },
        getNextPageParam: (lastPage: any) => {
            const nextPage = lastPage?.details.pages - lastPage?.details.page === 0 ? undefined : lastPage?.details.page + 1
            return nextPage
        },
        onError: error => {
            console.log(error)
            notifyError('Something went wrong...')
        },
        enabled: !isTableSettingsLoading && ready
    })
    const data: any = fetchData?.pages?.reduce((acc: any, page: any) => [...acc, ...page.items], [])

    const count = fetchData?.pages[0]?.details?.count || 0

    const scrollHandler = (event: GridEvent) => {
        const e = event.nativeEvent
        if (e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight) {
            if (!isFetchingNextPage) fetchNextPage()
        }
    }

    function handleSelect(newSelected: any) {
        if (!multiselect) {
            if (Object.keys(newSelected).length > 2) return //ignore select all checkbox
            const result = Object.keys(newSelected)
                .filter(id => newSelected[id] === true)
                .filter(id => !selected.includes(id))
            return setSelected && setSelected(result)
        }
        const result = Object.keys(newSelected).filter(id => newSelected[id] === true)
        setSelected && setSelected(result)
    }

    return (
        <div style={{ position: 'relative', ...style }}>
            <Show condition={Boolean(topContent)}>{topContent}</Show>
            <Show condition={!disableSearch}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', justifyContent: 'space-between' }}>
                    <TableSummary title={`All ${entityName}`} total={count} selected={Array.isArray(selected) ? selected.length : selected ? 1 : 0} selectable={selectable} />
                </div>
            </Show>
            <Table
                filterable={filterable}
                filter={gridFilter}
                onFilterChange={onFilterChange}
                data={data}
                // data={data?.slice(skip, skip + pageSize)}
                columns={columns}
                dataProps={{
                    handleCellClick: (props: CustomCellProps) => {
                        const result = ((selected as Array<string>) || []).reduce((prev: { [key: string]: boolean }, value) => {
                            prev[value] = true
                            return prev
                        }, {})
                        result[props.dataItem._id] = !result[props.dataItem._id]
                        handleSelect(result)
                    },
                    ...dataProps
                }}
                style={{ height: tableHeight }}
                rowHeight={rowHeight}
                onScroll={scrollHandler}
                fixedScroll={true}
                sortable={true}
                sort={sort}
                onSort={onSort}
                selected={((selected as Array<string>) || []).reduce((prev: { [key: string]: boolean }, value) => {
                    prev[value] = true
                    return prev
                }, {})}
                setSelected={handleSelect}
                selectable={{ enabled: selectable }}
            />
            <Show condition={isFetchingNextPage || isLoading}>
                <div style={{ position: 'absolute', top: 0, left: 0, background: 'rgba(255,255,255,0.7)', width: '100%', height: '100%', zIndex: 2 }}>
                    <Spin style={{ position: 'absolute', left: '48%', top: '48%' }} />
                </div>
            </Show>
        </div>
    )
}

export default SelectEntity
