import { useMemo, useState } from 'react';

import { Dropdown } from '@components';
import { documentStatuses } from '@constants/status';

const ChangeStatusModal = props => {
    const { customStatuses, onStatusChange = () => {} } = props;
    const [status, setStatus] = useState(props.status);

    const handleOnStatusChange = e => {
        const newStatus = e.value;
        setStatus(newStatus);
        onStatusChange(newStatus);
    };

    const statusOptions = useMemo(() => {
        if (customStatuses?.length) {
            return customStatuses;
        }

        return documentStatuses.map(x => ({
            label: x.label,
            value: x.value
        }));
    }, [customStatuses?.length]);

    return <Dropdown label='New Status' value={status} options={statusOptions} customClass='ml-28 mw-200 dropdown--elegant' onChange={handleOnStatusChange} />;
};

export default ChangeStatusModal;
