import React from 'react'
import { Popover } from '@nextui-org/react'
import Feather from '@components/Feather'
import styled from 'styled-components'

const Popup = ({ content, trigger, placement = 'top', style = {}, closeOnLeave = true, disableTriangle = false }) => {
    const [isOpen, setIsOpen] = React.useState(false)
    return (
        <Popover disableAnimation placement={placement} isOpen={isOpen} onOpenChange={setIsOpen}>
            <Popover.Trigger onMouseEnter={() => setIsOpen(true)} onMouseLeave={closeOnLeave ? () => setIsOpen(false) : null}>
                {trigger}
            </Popover.Trigger>
            <Popover.Content css={{ overflow: 'visible', marginBottom: '10px' }}>
                <PopupContent style={style} placement={placement} disableTriangle={disableTriangle}>
                    {content}
                </PopupContent>
            </Popover.Content>
        </Popover>
    )
}

const bottomTriangle = `
    content:"";
    border-radius: 0;
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent #0E3F66 transparent transparent;
    position: absolute;
    bottom: -16px;
    left: calc(50% - 7px);
    overflow: visible;
    transform: rotate(-90deg);
    border-radius: 5px;
`

const rightTriangle = `
    content:"";
    border-radius: 0;
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent #0E3F66 transparent transparent;
    position: absolute;
    bottom: calc(50% - 12px);
    right: -8px;
    overflow: visible;
    transform: rotate(-180deg);
    border-radius: 5px;
`

const leftTriangle = `
    content:"";
    border-radius: 0;
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent #0E3F66 transparent transparent;
    position: absolute;
    bottom: calc(50% - 12px);
    left: -8px;
    overflow: visible;
    transform: rotate(-120deg);
    border-radius: 5px;
`

const triangle = {
    top: bottomTriangle,
    left: rightTriangle,
    right: leftTriangle
}

const PopupContent = styled.ul`
    padding: 10px;
    background-color: #0e3f66;
    color: white;
    overflow: visible;
    border-radius: 4px;
    :before {
        ${({ placement, disableTriangle }) => (disableTriangle ? '' : triangle[placement])}
    }
`

export default Popup
