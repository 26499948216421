import Arrow from '@assets/icons/Arrow.svg';

import { Feather, Image } from '@components';
import { Checkbox } from '@common'
import Select from '@components/Select/index';

import drcheckboxStyles from '@scss/components/_drcheckbox.module.scss';

import styles from './styles.module.scss';

const SelectItem = ({ opt, onClick, checked }) => {
    const userStatus = opt?.userRoles && opt?.userRoles.length > 0 ? opt.userRoles[0].name : opt.position;
    const userEmail = opt?.email ? '(' + opt?.email + ')' : '';

    const onItemClick = () => {
        onClick(opt);
    };

    return (
        <div className={`${styles['dropdown-item']} ${styles['option']}`} onClick={onItemClick}>
            <Checkbox checked={checked} />
            <span className={styles['opt-label']}>{opt.label}</span>
            <div className={styles['opt-role']}>
                <span>{userStatus}</span>
            </div>
        </div>
    );
};

const ToggleMenu = ({ value, placeholder, show, setShow, onDelete, readOnly }) => (
    readOnly ? (<div className={drcheckboxStyles['selected-items']}>
        {value?.map(val => (
            <div className={drcheckboxStyles['item']} key={val?.value}>
                <span>{val?.label}</span>
            </div>
        ))}
    </div>) :
        (<div
            className={`${drcheckboxStyles['idle']} ${drcheckboxStyles['idle--normal']} ${!show ? drcheckboxStyles['idle--focus'] : ''}`}
            onClick={e => {
                setShow();
                e.preventDefault();
            }}>
            <div className={drcheckboxStyles['selected-items']}>
                {value?.map(val => (
                    <div className={drcheckboxStyles['item']} key={val?.value}>
                        <span>{val?.label}</span>
                        <Feather name='X' onClick={() => onDelete(val)} />
                    </div>
                ))}
            </div>
            {!value?.length && <span className={drcheckboxStyles['checkbox-placeholder']}>{placeholder}</span>}
            <div className={drcheckboxStyles['icon-wrapper']}>
                <Image src={Arrow} className={`${drcheckboxStyles['arrow_icon']} ${!show ? drcheckboxStyles['arrow_icon--rotate'] : ''}`} />
            </div>
        </div>)
);

export const SelectRecipient = ({ options, value, readOnly, label, onChange, multiple }) => {
    // const onCreateContact = () => {
    //     onLinkNewGeneralContactHandler({ path: path });
    // };

    return (
        <Select
            label={label}
            value={value}
            readOnly={readOnly}
            multiple={multiple}
            dropdownMenuClassName={styles['dropdown-menu']}
            showClassName={styles['show']}
            toggleMenu={<ToggleMenu />}
            onChange={onChange}
            options={options}>
            {options.map((opt, i) => (
                <SelectItem value={opt.value} opt={opt} key={opt.value} />
            ))}
            {/* <div
                className={styles['contact-wrapper']}
                onClick={onCreateContact}
            >
                <span>+ Create a new contact</span>
            </div> */}
        </Select>
    );
};
