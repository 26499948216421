import '@scss/components/_label.scss';

const Label = props => {
    const { label = 'unset', customClass = '', className = '', style = {} } = props;

    const htmlFor = props.htmlFor || label;

    return (
        <div className={`Label ${className}`} style={{ ...style }}>
            <label htmlFor={htmlFor} className={`noselect ${customClass}`}>
                {label}
            </label>
        </div>
    );
};

export default Label;
