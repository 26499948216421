export const calculateTransportPrice = (
    truck: { pricePerHour: number } | null,
    transport: { tonage: number; loadingTime: number; unloadingTime: number; roundTrip: number },
    roundtrip: number,
    pricePerHour?: number
) => {
    if (!truck) {
        return;
    }
    const price = (pricePerHour ? pricePerHour : truck.pricePerHour) / 60 / transport?.tonage;
    const tt = transport?.loadingTime + transport?.unloadingTime + (roundtrip ? roundtrip : transport?.roundTrip);
    const cp = Math.round(100 * price * tt) / 100;
    return Number.isNaN(cp) ? 0.0 : cp;
};

export const calculateRoundTripTime = (duration: number) => {
    const calculatedTripTime = Math.round(duration / 60) * 2;
    return calculatedTripTime;
};

export const calculateTripDuration = (route: google.maps.DirectionsRoute) => {
    if (!route || !route.legs) return;
    const duration = route.legs.reduce((total, leg) => {
        return total + (leg.duration_in_traffic?.value ?? 0);
    }, 0);
    return Math.round(duration / 60);
};

export const calculateTripDistance = (route: google.maps.DirectionsRoute) => {
    if (!route || !route.legs) return;
    const distance = route.legs.reduce((total, leg) => {
        return total + (leg.distance?.value ?? 0);
    }, 0);
    return Math.round((distance / 1609) * 10) / 10;
};
