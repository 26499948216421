import { combineReducers } from 'redux';
import competitors from './competitors';
import contacts from './contacts';
import contracts from './contracts';
import customerGroups from './customerGroups';
import customers from './customers';
import financialYears from './financialYears';
import geoZones from './geoZones';
import invoices from './invoices';
import notificationSettings from './notificationSettings';
import plants from './plants';
import plantTierSettings from './plantTierSettings';
import pricePlanning from './price_planning';
import productCategories from './productCategories';
import products from './products';
import projects from './projects';
import quotes from './quotes';
import salesPersons from './salesPerson';
import tc from './tc';
import trucks from './trucks';
import userGroups from './userGroups';
import userRoles from './userRoles';
import users from './users';

export default combineReducers({
    customers,
    projects,
    contacts,
    salesPersons,
    plants,
    quotes,
    products,
    contracts,
    pricePlanning,
    customerGroups,
    competitors,
    financialYears,
    plantTierSettings,
    userRoles,
    users,
    productCategories,
    invoices,
    trucks,
    tc,
    geoZones,
    userGroups,
    notificationSettings
});
