import React from 'react';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import { StyleSheet, View, Text, Link } from '@react-pdf/renderer';
import redraft from 'redraft';
import htmlToDraft from 'html-to-draftjs';

const styles = StyleSheet.create({
    text: {
        color: 'black',
        display: 'block',
        fontSize: 8,
        lineHeight: 1.2
    },
    list: {
        // marginBottom: 1.2,
        marginTop: 1.2
    },
    listItem: {
        marginBottom: 1.2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    listItemText: {
        color: 'black',
        fontSize: 8,
        lineHeight: 1.2
    },
    italicText: {
        fontFamily: 'Helvetica-Oblique'
    },
    boldText: {
        fontFamily: 'Helvetica-Bold'
    }
})

const Heading = ({ type, data, children, style }) => {
    const st = {}
    if (data.length && Array.isArray(data) && Object.keys(data[0]).length) {
        const [key, value] = Object.entries(data[0])[0]
        const [a, b] = key.split('-')
        const res = a + b.charAt(0).toUpperCase() + b.slice(1)
        st[res] = value
    }
    return (
        <View>
            <Text style={{ ...styles[type], ...st, ...style }}>{children}</Text>
        </View>
    )
}

const UnorderedList = ({ children, depth, style }) => {
    return <View style={{ ...styles.list, ...style }}>{children}</View>
}

const UnorderedListItem = ({ children, style }) => {
    return (
        <View style={{ ...styles.listItem, ...style }}>
            <Text style={{ ...styles.listItemText, marginRight: 6, ...style }}>•</Text>
            <Text style={{ ...styles.listItemText, ...style }}>{children}</Text>
        </View>
    )
}

const OrderedList = ({ children, depth, style }) => {
    return <View style={{ ...styles.list, ...style }}>{children}</View>
}

const OrderedListItem = ({ children, index, style }) => {
    return (
        <View style={{ ...styles.listItem, ...style }}>
            <Text style={{ ...styles.listItemText, ...style }}>
                {index + 1}. &nbsp;<Text>{children}</Text>
            </Text>
        </View>
    )
}

// const renderers = {
//     inline: {
//         // The key passed here is just an index based on rendering order inside a block
//         BOLD: (children, { key }) => {
//             return (
//                 <Text key={`bold-${key}`} style={styles.boldText}>
//                     {children}
//                 </Text>
//             );
//         },
//         ITALIC: (children, { key }) => {
//             return (
//                 <Text key={`italic-${key}`} style={styles.italicText}>
//                     {children}
//                 </Text>
//             );
//         },
//         UNDERLINE: (children, { key }) => {
//             return (
//                 <Text key={`underline-${key}`} style={{ textDecoration: 'underline' }}>
//                     {children}
//                 </Text>
//             );
//         }
//     },
//     /**
//      * Blocks receive children and depth
//      * Note that children are an array of blocks with same styling,
//      */
//     blocks: {
//         unstyled: (children, { keys, data }) => {
//             const st = {};
//             if (data.length && Array.isArray(data) && Object.keys(data[0]).length) {
//                 const [key, value] = Object.entries(data[0])[0];
//                 const [a, b] = key.split('-');
//                 const res = a + b.charAt(0).toUpperCase() + b.slice(1);
//                 st[res] = value;
//             }

//             return children.map((child, index) => {
//                 return (
//                     <View key={keys[index]} style={{ display: 'block', marginTop: 5, marginBottom: 5, marginLeft: 0, marginRight: 0 }}>
//                         <Text style={{ ...styles.text, ...st }}>{child}</Text>
//                     </View>
//                 );
//             });
//         },
//         'header-one': (children, { keys, data }) => {
//             return children.map((child, index) => {
//                 return (
//                     <Heading key={keys[index]} data={data} type='headingOne'>
//                         {child}
//                     </Heading>
//                 );
//             });
//         },
//         'header-two': (children, { keys, data }) => {
//             return children.map((child, index) => {
//                 return (
//                     <Heading key={keys[index]} data={data} type='headingTwo'>
//                         {child}
//                     </Heading>
//                 );
//             });
//         },
//         'header-three': (children, { keys, data }) => {
//             return children.map((child, index) => {
//                 return (
//                     <Heading key={keys[index]} data={data} type='headingThree'>
//                         {child}
//                     </Heading>
//                 );
//             });
//         },
//         'header-four': (children, { keys, data }) => {
//             return children.map((child, index) => {
//                 return (
//                     <Heading key={keys[index]} data={data} type='headingFour'>
//                         {child}
//                     </Heading>
//                 );
//             });
//         },
//         'header-five': (children, { keys, data }) => {
//             return children.map((child, index) => {
//                 return (
//                     <Heading key={keys[index]} data={data} type='headingFive'>
//                         {child}
//                     </Heading>
//                 );
//             });
//         },
//         'header-six': (children, { keys, data }) => {
//             return children.map((child, index) => {
//                 return (
//                     <Heading key={keys[index]} data={data} type='headingSix'>
//                         {child}
//                     </Heading>
//                 );
//             });
//         },
//         'unordered-list-item': (children, { depth, keys }) => {
//             return (
//                 <UnorderedList key={keys[keys.length - 1]} depth={depth}>
//                     {children.map((child, index) => (
//                         <UnorderedListItem key={keys[index]}>{child}</UnorderedListItem>
//                     ))}
//                 </UnorderedList>
//             );
//         },
//         'ordered-list-item': (children, { depth, keys }) => {
//             return (
//                 <OrderedList key={keys.join('|')} depth={depth}>
//                     {children.map((child, index) => (
//                         <OrderedListItem key={keys[index]} index={index}>
//                             {child}
//                         </OrderedListItem>
//                     ))}
//                 </OrderedList>
//             );
//         }
//     },
//     /**
//      * Entities receive children and the entity data
//      */
//     entities: {
//         // key is the entity key value from raw
//         LINK: (children, data, { key }) => (
//             <Link key={key} src={data.url}>
//                 {children}
//             </Link>
//         )
//     }
// };

const updateRenders = (style) => {
    return {
        inline: {
            // The key passed here is just an index based on rendering order inside a block
            BOLD: (children, { key }) => {
                return (
                    <Text key={`bold-${key}`} style={{ ...styles.boldText, ...style }}>
                        {children}
                    </Text>
                );
            },
            ITALIC: (children, { key }) => {
                return (
                    <Text key={`italic-${key}`} style={{ ...styles.italicText, ...style }}>
                        {children}
                    </Text>
                );
            },
            UNDERLINE: (children, { key }) => {
                return (
                    <Text key={`underline-${key}`} style={{ textDecoration: 'underline', ...style }}>
                        {children}
                    </Text>
                );
            }
        },
        /**
         * Blocks receive children and depth
         * Note that children are an array of blocks with same styling,
         */
        blocks: {
            unstyled: (children, { keys, data }) => {
                const st = {};
                if (data.length && Array.isArray(data) && Object.keys(data[0]).length) {
                    const [key, value] = Object.entries(data[0])[0];
                    const [a, b] = key.split('-');
                    const res = a + b.charAt(0).toUpperCase() + b.slice(1);
                    st[res] = value;
                }
    
                return children.map((child, index) => {
                    return (
                        <View key={keys[index]} style={{ display: 'block', marginTop: 5, marginBottom: 5, marginLeft: 0, marginRight: 0, ...style }}>
                            <Text style={{ ...styles.text, ...st, ...style }}>{child}</Text>
                        </View>
                    );
                });
            },
            'header-one': (children, { keys, data }) => {
                return children.map((child, index) => {
                    return (
                        <Heading key={keys[index]} data={data} style={style} type='headingOne'>
                            {child}
                        </Heading>
                    );
                });
            },
            'header-two': (children, { keys, data }) => {
                return children.map((child, index) => {
                    return (
                        <Heading key={keys[index]} data={data} style={style} type='headingTwo'>
                            {child}
                        </Heading>
                    );
                });
            },
            'header-three': (children, { keys, data }) => {
                return children.map((child, index) => {
                    return (
                        <Heading key={keys[index]} data={data} style={style} type='headingThree'>
                            {child}
                        </Heading>
                    );
                });
            },
            'header-four': (children, { keys, data }) => {
                return children.map((child, index) => {
                    return (
                        <Heading key={keys[index]} data={data} style={style} type='headingFour'>
                            {child}
                        </Heading>
                    );
                });
            },
            'header-five': (children, { keys, data }) => {
                return children.map((child, index) => {
                    return (
                        <Heading key={keys[index]} data={data} style={style} type='headingFive'>
                            {child}
                        </Heading>
                    );
                });
            },
            'header-six': (children, { keys, data }) => {
                return children.map((child, index) => {
                    return (
                        <Heading key={keys[index]} data={data} style={style} type='headingSix'>
                            {child}
                        </Heading>
                    );
                });
            },
            'unordered-list-item': (children, { depth, keys }) => {
                return (
                    <UnorderedList key={keys[keys.length - 1]} style={style}  depth={depth}>
                        {children.map((child, index) => (
                            <UnorderedListItem key={keys[index]} style={style}>{child}</UnorderedListItem>
                        ))}
                    </UnorderedList>
                );
            },
            'ordered-list-item': (children, { depth, keys }) => {
                return (
                    <OrderedList key={keys.join('|')} style={style} depth={depth}>
                        {children.map((child, index) => (
                            <OrderedListItem style={style} key={keys[index]} index={index}>
                                {child}
                            </OrderedListItem>
                        ))}
                    </OrderedList>
                );
            }
        },
        /**
         * Entities receive children and the entity data
         */
        entities: {
            // key is the entity key value from raw
            LINK: (children, data, { key }) => (
                <Link key={key} src={data.url}>
                    {children}
                </Link>
            )
        }
    };
}

const RichText = ({ note, style }) => {
    // const blocksFromHTML = convertFromHTML(note);
    // const initialState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

    const contentBlock = htmlToDraft(note);

    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const templateText = EditorState.createWithContent(contentState);

    // const editorState = EditorState.createWithContent(initialState);
    const renderers = updateRenders(style);
    const rawContent = convertToRaw(templateText.getCurrentContent());
    return redraft(rawContent, renderers, { blockFallback: 'unstyled' });
};

export default RichText;
