import React from 'react'

import '@scss/pages/_masterData.scss'
const MasterDataMain = (props) => {
    return (
        <div className='MasterDataGrid__Main'>
            {props.children}
        </div>
    )
}

export default MasterDataMain;