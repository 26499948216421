import { useThousandSeparator } from '@hooks/useThousandSeparator'

import '@scss/components/_widgetStats.scss'

interface WidgetStatsMultipleProps {
    title: string
    data: Array<{ label: string; volumeQuoted: number; estimatedAnnualProductionVolume: number; uom: string }>
    isAnnualVolume?: boolean
}

const WidgetStatsMultiple = ({ title, data, isAnnualVolume = false }: WidgetStatsMultipleProps) => {
    const { formatNumber } = useThousandSeparator()
    return (
        <div className='WidgetStats'>
            <h4 className='WidgetStats__Title'>{title}</h4>
            {data?.map(({ label, volumeQuoted, estimatedAnnualProductionVolume, uom }) => (
                <p key={`volume-quoted-${label}-${uom}`} className={`WidgetStats__Value`} style={{ fontSize: '14px' }}>
                    {label}: {isAnnualVolume ? formatNumber(estimatedAnnualProductionVolume) : formatNumber(volumeQuoted)} {uom[0]}
                </p>
            ))}
        </div>
    )
}

export default WidgetStatsMultiple
