import React from 'react'
import { Drawer as AntDrawer, DrawerProps } from 'antd'
import styled from 'styled-components'
import { Button, Icon } from '@common'

const StyledDrawer = styled(AntDrawer)`
    margin-top: 60px;
    height: calc(100% - 60px);
    padding-bottom: 60px;
    border-top: 4px solid #ff8939;

    .ant-drawer-wrapper-body {
        position: relative;
    }

    .ant-drawer-header {
        padding: 30px 0 10px;
        margin: 0 30px;
        border-bottom: 1px solid #e0e7ff;

        svg {
            color: #0e3f66;
            width: 16px;
            height: 16px;
            display: block;
        }

        .ant-drawer-header-title {
            display: flex;
            flex-direction: row-reverse;
        }

        .ant-drawer-title {
            color: #0e3f66;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: 'Rubik';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 1.213px;
            text-transform: uppercase;
        }
    }

    .ant-drawer-body {
        padding: 15px 30px 57px;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 15px;
        }
    }

    .ant-drawer-footer {
        display: flex;
        gap: 20px;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 8px 30px;
        height: 56px;
        padding-left: 20px;
        /* box-shadow: 0px -6px 27px 0px #a6abbd4d; */
        border-top: 1px solid #dddbda;
        background: linear-gradient(0deg, #f9faff, #f9faff);
        z-index: 3;

        & > .default {
            display: flex;
            gap: 20px;
            justify-content: flex-end;
            align-items: center;
            position: absolute;
        }
    }
`

const StyledDrawerBody = styled.div`
    //
`

export const DrawerFooter: React.FC<{ className?: string; handleSave: any; okLabel?: any; extraButtons?: any; okDisabled?: boolean }> = props => {
    const { className = 'ant-drawer-footer', handleSave, okLabel = 'Save', extraButtons = null, okDisabled = false } = props
    return (
        <div className={className} style={{ display: 'flex', gap: 8 }}>
            <Button style={{ width: '145px', justifyContent: 'center' }} onClick={handleSave} disabled={okDisabled}>
                {okLabel}
            </Button>
            {extraButtons}
        </div>
    )
}

const Drawer = (props: DrawerProps & { handleClose?: any; handleSave?: any; okLabel?: any; extraButtons?: any; okDisabled?: boolean }) => {
    const AntDrawerProps = { ...props }
    delete AntDrawerProps.handleClose
    delete AntDrawerProps.handleSave
    delete AntDrawerProps.okLabel
    delete AntDrawerProps.extraButtons
    delete AntDrawerProps.okDisabled
    return (
        <StyledDrawer
            closeIcon={<Icon name='close' />}
            footer={<DrawerFooter okLabel={props.okLabel} className='default' handleSave={props.handleSave} extraButtons={props.extraButtons} okDisabled={props.okDisabled} />}
            {...props}>
            <StyledDrawerBody>{props.children}</StyledDrawerBody>
        </StyledDrawer>
    )
}

export default Drawer
