import React from 'react'
import styled from 'styled-components'
import TextArea from 'antd/es/input/TextArea'
import { StyledInputProps } from '../Pages/Project/types'
import { baseInputStyles } from './Input'

const StyleTextArea = styled(TextArea)<StyledInputProps>`
    ${({style})=>(style?.lineHeight?`line-height: ${style.lineHeight} !important;`: '')}
    ${baseInputStyles}
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
`
const StyledTextArea = (props: any) => {
    if (props.autoFill) return <div>{props.value || '-'}</div>
    else {
        const withoutAutoFill =  {...props}
        delete withoutAutoFill.autoFill
        return <StyleTextArea {...withoutAutoFill} />
    }
}

export default StyledTextArea