import { ArrowDownCircle, MinusInCircle } from '@assets';

export const VALID = 'valid';
export const GAP = 'gap';
export const ACTIVE = 'active';
export const PUBLISHED = 'published';
export const MODIFIED = 'modified';
export const FUTURE = 'future';
export const EXPIRED = 'expired';
export const ARCHIVED = 'archived';
export const BLOCKED = 'blocked';
export const UNDEFINED = 'undefined';
export const INACTIVE = 'inactive';
export const TIERS = 'tiers';
export const COMPLETED = 'completed';
export const COMPLETE = 'complete';
export const IN_PROGRESS = 'in-progress';
export const ROLLED_OUT = 'rolled-out';
export const QUOTE_PROGRESS_ACTIVE = 'quote-progress-active';
export const QUOTE_PROGRESS_INACTIVE = 'quote-progress-inactive';
export const IN_APPROVAL = 'in-approval';
export const PREVIEW = 'preview';
export const APPROVED = 'approved';
export const REJECTED = 'rejected';
export const REVOKED = 'revoked';
export const SENT = 'sent';
export const DRAFT = 'draft';
export const NEGOTIATION = 'negotiation';
export const CANCELLED = 'cancelled';
export const ANNOUNCED = 'announced';
export const BID = 'bid';
export const STARTED = 'started';
export const ENDED = 'ended';
export const SCHEDULED = 'scheduled';
export const WON = 'won';
export const LOST = 'lost';
export const PENDING = 'pending';
export const PRIMARY = 'primary';
export const SECONDARY = 'secondary';
export const SIGNED = 'signed';
export const PROGRESS_STATUS = 'progress-status';
export const FINISHED = 'finished';
export const NEGOTIATING = 'negotiating';
export const BIDDING = 'bidding';
export const REVISING = 'revising';
export const CLOSED_LOST = 'closed lost';
export const CLOSED_WON = 'closed won';

export const HDR_STATUSES = [ACTIVE, BLOCKED, INACTIVE];

export const allStatuses = [
    { value: UNDEFINED, label: 'Undefined', cls: 'StatusBadge__general--undefined' },
    { value: VALID, label: 'Valid', cls: 'StatusBadge__general--valid' },
    { value: GAP, label: 'Gap', cls: 'StatusBadge__general--gap' },
    { value: ACTIVE, label: 'Active', cls: 'StatusBadge__general--active' },
    { value: PUBLISHED, label: 'Published', cls: 'StatusBadge__general--published' },
    { value: MODIFIED, label: 'Modified', cls: 'StatusBadge__general--modified' },
    { value: FUTURE, label: 'Future', cls: 'StatusBadge__general--future' },
    { value: EXPIRED, label: 'Expired', cls: 'StatusBadge__general--expired' },
    { value: ARCHIVED, label: 'Deleted', cls: 'StatusBadge__general--archived' },
    { value: PREVIEW, label: 'PREVIEW', cls: 'StatusBadge__general--preview' },
    { value: BLOCKED, label: 'Blocked', cls: 'StatusBadge__general--blocked' },
    { value: INACTIVE, label: 'Inactive', cls: 'StatusBadge__general--inactive' },
    { value: TIERS, label: 'Tier-', cls: 'StatusBadge__general--tiers' },
    { value: COMPLETED, label: 'Completed', cls: 'StatusBadge__general--completed' },
    { value: FINISHED, label: 'Finished', cls: 'StatusBadge__general--completed' },
    { value: COMPLETE, label: 'Completed', cls: 'StatusBadge__general--completed' },
    { value: IN_PROGRESS, label: 'In progress', cls: 'StatusBadge__general--in-progress' },
    { value: ROLLED_OUT, label: 'Rolled-out', cls: 'StatusBadge__general--rolled-out' },
    { value: IN_APPROVAL, label: 'In Approval', cls: 'StatusBadge__general--in-approval' },
    { value: APPROVED, label: 'Approved', cls: 'StatusBadge__general--approved' },
    { value: SIGNED, label: 'Signed', cls: 'StatusBadge__general--signed' },
    { value: SENT, label: 'Sent', cls: 'StatusBadge__general--sent' },
    { value: PRIMARY, label: 'Primary', cls: 'StatusBadge__general--primary' },
    { value: ANNOUNCED, label: 'Announced', cls: 'StatusBadge__general--primary' },
    { value: BID, label: 'Bid', cls: 'StatusBadge__general--primary' },
    { value: BIDDING, label: 'Bidding', cls: 'StatusBadge__general--primary' },
    { value: REVISING, label: 'Revising', cls: 'StatusBadge__general--primary' },
    { value: STARTED, label: 'Started', cls: 'StatusBadge__general--primary' },
    { value: ENDED, label: 'Ended', cls: 'StatusBadge__general--primary' },
    { value: SECONDARY, label: 'Secondary', cls: 'StatusBadge__general--secondary' },
    { value: DRAFT, label: 'Draft', cls: 'StatusBadge__general--draft' },
    { value: NEGOTIATION, label: 'Negotiation', cls: 'StatusBadge__general--negotiation' },
    { value: NEGOTIATING, label: 'Negotiating', cls: 'StatusBadge__general--negotiation' },
    { value: REJECTED, label: 'Rejected', cls: 'StatusBadge__general--rejected' },
    { value: CANCELLED, label: 'Cancelled', cls: 'StatusBadge__general--cancelled' },
    { value: SCHEDULED, label: 'Scheduled', cls: 'StatusBadge__general--scheduled' },
    { value: QUOTE_PROGRESS_ACTIVE, label: 'Q-', cls: 'StatusBadge__general--quote-progress--active' },
    { value: QUOTE_PROGRESS_INACTIVE, label: 'Q-', cls: 'StatusBadge__general--quote-progress--inactive' },
    { value: REVOKED, label: 'Revoked', cls: 'StatusBadge__general--revoked' },
    { value: WON, label: 'Won', cls: 'StatusBadge__general--won' },
    { value: CLOSED_WON, label: 'Closed Won', cls: 'StatusBadge__general--won' },
    { value: PROGRESS_STATUS, label: '', cls: 'StatusBadge__general--progress-status' },
    { value: LOST, label: 'Lost', cls: 'StatusBadge__general--lost', icon: ArrowDownCircle },
    { value: CLOSED_LOST, label: 'Closed Lost', cls: 'StatusBadge__general--lost', icon: ArrowDownCircle },
    { value: PENDING, label: 'Pending', cls: 'StatusBadge__general--pending', icon: MinusInCircle }
];

export const documentStatuses = [
    { value: ACTIVE, label: 'Active' },
    { value: INACTIVE, label: 'Inactive' }
];
