import React, { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useDispatch } from 'react-redux'

import { Button, Feather, Popover, SearchForm, TextField, useNotify } from '@components'
import { Checkbox } from '@common'
import { useDebounceHandler } from '@hooks/useDebounceHandler'

import ChevronUnionIcon from '@panels/ChevronUnionIcon'

import { hideAllFields, showAllFields, toggleVisibleFields } from '@reducers/table/actions'

import { exportAll } from 'service/csvExportApi'

import { useOnClickOutside } from 'utils/onClickOutside'

import '@scss/components/_table.scss'

const Controls = props => {
    const {
        isFilter,
        isSelectView,
        havePriceValidityFilter,
        tableViews,
        autoSaveView,
        visibleColumn,
        tableKey,
        tableFields,
        displayFields,
        formatColumns,
        reorder,
        openReorder,
        setOpenReorder,
        setFilter,
        filter,
        sort,
        show = true,
        dataProps,
        onUpdateView,
        onAddView,
        onLoadView,
        onRemoveView,
        controlsRender,
        lastSelectedView,
        onRestoreDefaultView,
        data,
        columns,
        setTableData,
        csvExport,
        parentComponent,
        csvFilename = 'filename_not_defined.csv',
        csvExportAll,
        csvExportAllParam,
        csvExportAllFileName,
        entityGroupByKey,
        readOnly,
        selected,
        onlySearch,
        searchAsFilter,
        loading,
        onQuery = () => {},
        placeholder,
        isEditable = false,
        onEditHandler = () => {}
    } = props

    const [selectedView, setSelectedView] = useState([])
    const [openAddMyView, setOpenAddMyView] = useState(false)
    const [openMyViewColumn, setOpenMyViewColumn] = useState(false)
    const [openVisibleColumn, setOpenVisibleColumn] = useState(false)
    const [searchAsFilterValue, setSearchAsFilterValue] = useState('')
    const [newView, setNewView] = useState('')
    const timeout = useRef()

    const visibleRef = useRef()
    const myviewRef = useRef()
    const addviewRef = useRef()

    const { notify } = useNotify()

    const onChangeViewName = e => {
        setNewView(e.target.value)
    }

    const switchMyViewColumnVisibility = () => {
        setOpenMyViewColumn(prevOpenMyViewColumn => !prevOpenMyViewColumn)
    }

    const switchAddMyViewVisibility = () => {
        setOpenAddMyView(preOpenAddMyView => !preOpenAddMyView)
    }

    useEffect(() => {
        if (lastSelectedView) {
            setSelectedView([lastSelectedView])
        }
    }, [lastSelectedView])

    useEffect(() => {
        if (!autoSaveView) {
            return
        }

        saveViewDebounce(autoSaveView)
    }, [displayFields.length, sort?.sort, sort?.order])

    const dispatch = useDispatch()

    useOnClickOutside(visibleRef, () => {
        setOpenVisibleColumn(false)
    })

    useOnClickOutside(myviewRef, () => {
        setOpenMyViewColumn(false)
    })

    useOnClickOutside(addviewRef, () => {
        setOpenAddMyView(false)
    })

    const minOneVisibleColumn = () => {
        return tableFields.some(col => !col.hidden && !col.alwaysVisible)
    }

    const saveViewDebounce = viewName => {
        clearTimeout(timeout.current)

        timeout.current = setTimeout(() => saveView(null, viewName), 2000)
    }

    const saveView = (e, viewName) => {
        const dupView = tableViews.find(t => t.name === newView?.trim())
        if (dupView && !viewName) {
            notify({
                title: 'Duplicate',
                description: 'View with the same title is already saved',
                duration: 5000,
                type: 'warning'
            })

            return
        }

        const finalView = !newView && selectedView.length === 1 ? selectedView[0].name : null
        onAddView(finalView || viewName || newView)
        setNewView('')
        setSelectedView([])
        setOpenMyViewColumn(false)
    }

    const defaultView = () => {
        onRestoreDefaultView()
        setSelectedView([])
        setOpenMyViewColumn(false)
    }

    const deleteView = () => {
        onRemoveView(selectedView)
        setSelectedView([])
        setOpenMyViewColumn(false)
    }

    const loadView = () => {
        onLoadView(selectedView[0])
        setSelectedView([])
        setOpenMyViewColumn(false)
    }

    const onExportAllHandler = async () => {
        try {
            const res = await exportAll(csvExportAll, csvExportAllParam, csvExportAllFileName)
        } catch (err) {
            console.log(err)
        }
    }

    const debouncedOnQuery = useDebounceHandler(newSearchValue => {
        onQuery(newSearchValue)
    }, 700)

    const handleSearchAsFilterChange = e => {
        const inputSearchValue = e.target.value
        setSearchAsFilterValue(inputSearchValue)
        debouncedOnQuery(inputSearchValue)
    }

    if (!show) {
        return <></>
    }

    const renderColumnTitle = col => {
        let colTitle = col?.defaultTitle
        if (typeof col?.title === 'function' && entityGroupByKey) {
            colTitle = col?.title({
                dataProps,
                row: col,
                ...(entityGroupByKey
                    ? {
                        entityGroupByKey
                    }
                    : {})
            })
        }
        if (typeof col?.title === 'string' && col?.title !== '') {
            colTitle = col?.title + ' '
        }

        if (col?.title === undefined && col?.dataIndex && entityGroupByKey && entityGroupByKey[col?.dataIndex]) {
            colTitle = entityGroupByKey[col?.dataIndex].fieldName
        }

        if (React.isValidElement(col?.title)) {
            colTitle = col?.title
        }
        if (typeof colTitle === 'undefined') colTitle = col.defaultTitle
        return colTitle
    }

    return (
        <div className='mb-5 d-flex justify-content-between'>
            <div className='d-flex relative table-controls'>
                {onlySearch && (
                    <SearchForm searchFieldStyle={{ minWidth: '364px' }} className='mb-2' onChange={onQuery} count={props.count} loading={loading} placeholder={placeholder} />
                )}
                {isFilter && (
                    <Button customClass='btnold--action-view' onClick={() => setFilter([...filter, {}])}>
                        <Feather name='Plus' />
                        <span>ADD FILTERS</span>
                    </Button>
                )}
                <div className={`custom-controls ${isFilter ? 'ml-20' : ''}`}>{controlsRender}</div>
                <div className={isFilter || controlsRender ? 'ml-20' : ''}>
                    {csvExport && selected.length > 0 && (
                        <Popover
                            parentComponent={parentComponent}
                            element={
                                <Button ripple customClass='btnold--secondary'>
                                    <span>EXPORT</span>
                                    <Feather name='ChevronDown' />
                                </Button>
                            }
                            options={[
                                {
                                    title: 'Selected',
                                    customRenderer: () => {
                                        const data = []
                                        selected.forEach(row => {
                                            // /**
                                            //  * Format price data for the CSV file
                                            //  */
                                            // let obj = {};
                                            // tableFields.forEach(({ dataIndex }) => {
                                            //     obj[dataIndex] = formatColumns[dataIndex].csv({ row, dataProps });
                                            // });
                                            // data.push(obj);

                                            // /**
                                            //  * Format accordion prices data for the CSV file
                                            //  */
                                            // const accordion = row.accordionPricesList;
                                            // accordion.forEach(price => {
                                            //     let obj = {};
                                            //     tableFields.forEach(({ dataIndex }) => {
                                            //         obj[dataIndex] = formatColumns[dataIndex].csv({ row: { ...row, listPrice: price }, dataProps });
                                            //     });
                                            //     data.push(obj);
                                            // });

                                            /**
                                             * Format all prices (accordion included) for the CSV file
                                             */
                                            row?.listPrices?.forEach(price => {
                                                const obj = {}
                                                tableFields.forEach(({ dataIndex }) => {
                                                    obj[dataIndex] = formatColumns[dataIndex].csv({ row: { ...row, listPrice: price }, dataProps })
                                                })
                                                data.push(obj)
                                            })
                                        })
                                        return (
                                            <CSVLink
                                                key='csv-link-3ghbp8'
                                                filename={csvFilename}
                                                data={selected.map(row => {
                                                    let obj = {}
                                                    tableFields.forEach(({ dataIndex }) => {
                                                        if (formatColumns[dataIndex].csv) {
                                                            obj[dataIndex] = formatColumns[dataIndex].csv({ row, dataProps })
                                                        }
                                                    })
                                                    return obj
                                                })}
                                                headers={tableFields.map(col => {
                                                    return {
                                                        label: col.title,
                                                        key: col.dataIndex
                                                    }
                                                })}>
                                                <Button customClass='Popover__item btnold--norounded w-large' ripple>
                                                    <span className='title'>Export selected</span>
                                                </Button>
                                            </CSVLink>
                                        )
                                    }
                                },
                                { title: 'Export all', action: onExportAllHandler }
                            ]}
                            popupWidth={180}
                        />
                    )}
                </div>
                {isEditable && (
                    <div>
                        <Button customClass='btnold--secondary' onClick={onEditHandler}>
                            <span>EDIT</span>
                        </Button>
                    </div>
                )}
            </div>
            <div className='d-flex'>
                {searchAsFilter && (
                    <div ref={visibleRef}>
                        <div className='visible-fields'>
                            <TextField type='search' name='search' placeholder='Search' value={searchAsFilterValue} onChange={handleSearchAsFilterChange} />
                        </div>
                    </div>
                )}
                {isSelectView && (
                    <div ref={myviewRef}>
                        <div className='myview'>
                            <Button customClass='btnold--action-view' onClick={switchMyViewColumnVisibility}>
                                <Feather name='Settings' />
                                <span>My views</span>
                                <Feather name={`${openMyViewColumn ? 'ChevronUp' : 'ChevronDown'}`} />
                            </Button>
                            <div className={`action-view overflow-auto ${openMyViewColumn ? 'action-view--show' : 'action-view--hide'}`}>
                                <ul>
                                    <li className='default-view'>
                                        <span onClick={defaultView}>Default view</span>
                                    </li>
                                    {tableViews.map((view, i) => {
                                        return (
                                            <li key={view.name + '' + i} className={`checkbox-row ${selectedView.findIndex(e => e.name === view.name) !== -1 ? 'selected' : ''}`}>
                                                <Checkbox
                                                    id={`checkbox-${view.name}`}
                                                    className='control-checkbox'
                                                    value={selectedView.findIndex(e => e.name === view.name) !== -1}
                                                    onChange={() => {
                                                        const index = selectedView.findIndex(e => e.name === view.name)
                                                        if (index === -1) {
                                                            setSelectedView([...selectedView, view])
                                                        } else {
                                                            const list = [...selectedView]
                                                            list.splice(index, 1)
                                                            setSelectedView(list)
                                                        }
                                                    }}
                                                />
                                                <label htmlFor={`checkbox-${view.name}`}>{view.name}</label>
                                            </li>
                                        )
                                    })}
                                </ul>
                                <div className='myview__action'>
                                    <div ref={addviewRef} className={`myview__action__add ${tableViews.length === 0 ? 'mt-2' : ''}`} onClick={switchAddMyViewVisibility}>
                                        {openAddMyView ? (
                                            <TextField autoFocus type='text' placeholder='Name...' value={newView} onChange={onChangeViewName} />
                                        ) : (
                                            <span>
                                                New <Feather name='Plus' />
                                            </span>
                                        )}
                                    </div>
                                    <div className='myview__action__btn'>
                                        <Button disabled={selectedView.length === 0} customClass='btnold--secondary center' onClick={deleteView}>
                                            Delete
                                        </Button>
                                        <Button disabled={selectedView.length !== 1} customClass='btnold--secondary center' onClick={loadView}>
                                            Load
                                        </Button>
                                        <Button disabled={!newView ? selectedView.length !== 1 : !newView?.trim()} customClass='btnold--primary center' onClick={saveView}>
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {visibleColumn && (
                    <div ref={visibleRef}>
                        <div className='visible-fields'>
                            <Button customClass='btnold--action-view' onClick={() => setOpenVisibleColumn(!openVisibleColumn)}>
                                <Feather name='Plus' />
                                <span>{autoSaveView ? 'Columns' : 'Fields'}</span>
                            </Button>
                            <div className={`action-view overflow-auto ${openVisibleColumn ? 'action-view--show' : 'action-view--hide'}`}>
                                <ul>
                                    <li key='all' className={`${minOneVisibleColumn() ? 'selected' : ''}`}>
                                        <Checkbox
                                            id='checkbox-all-field'
                                            className='control-checkbox'
                                            checked={minOneVisibleColumn()}
                                            onChange={() => {
                                                if (minOneVisibleColumn()) {
                                                    dispatch(hideAllFields(tableKey))
                                                } else {
                                                    dispatch(showAllFields(tableKey))
                                                }
                                            }}
                                        />
                                        {minOneVisibleColumn() ? 'Unselect all' : 'Select all'}
                                    </li>
                                    {tableFields
                                        .filter(x => x.dataIndex != '' && !Boolean(x.virtual) && !x.alwaysVisible)
                                        .map((col, i) => {
                                            const foundColumn = columns.find(c => c.dataIndex === col.dataIndex) || {}
                                            const _col = { ...foundColumn, ...col }

                                            return (
                                                <li key={i + '' + (col.id || col._id || '1')} className={`${!col.hidden ? 'selected' : ''}`}>
                                                    <Checkbox
                                                        id={`checkbox-toggle-${col.id || col._id}`}
                                                        className='control-checkbox'
                                                        checked={!col.hidden}
                                                        onChange={() => dispatch(toggleVisibleFields(tableKey, col))}
                                                    >{renderColumnTitle(_col, dataProps)}</Checkbox>
                                                </li>
                                            )
                                        })}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
                {reorder && (
                    <div className='visible-fields'>
                        <Button
                            ripple={{ color: openReorder ? '#FFFFFF' : '#FF8939', duration: 750 }}
                            customClass={`btnold--action-view ${openReorder ? 'btnold--selected' : ''}`}
                            onClick={() => setOpenReorder(!openReorder)}>
                            <ChevronUnionIcon />
                            <span>Reorder</span>
                        </Button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Controls
