import styled from 'styled-components'
import { ExtraFiltersProps } from '../TableHeader'
import { useMemo } from 'react'

interface SelectedStatus {
    active: boolean
    inactive: boolean
}

interface KendoTableHeaderProps {
    title: React.ReactNode
    selectedStatus: SelectedStatus
    onUpdateStatus: (key: keyof SelectedStatus, value: boolean) => void
}

export const KendoTableHeader: React.FC<KendoTableHeaderProps> = ({ title, selectedStatus, onUpdateStatus }) => {
    return (
        <HeaderWrapper>
            <HeaderItem>{title}</HeaderItem>
        </HeaderWrapper>
    )
}

const HeaderWrapper = styled.div`
    width: 100%;
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    color: #0e3f66;
`

const HeaderItem = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

const Status = styled.div<{ selected?: boolean; active?: boolean }>`
    background-color: ${({ active }) => (active ? '#2ed47a' : 'rgb(145, 150, 153)')};
    width: 25px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    ::after {
        content: '';
        border-bottom: ${({ selected }: any) => (selected ? '2px solid rgb(255, 137, 58);' : 'none')};
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
    }
`
