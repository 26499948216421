import { ReactNode } from 'react'

import { Tooltip } from 'antd'

import { TooltipPlacement } from 'antd/es/tooltip'

const AntdTooltip = ({ tooltipContent, placement, children }: { tooltipContent: string; placement: TooltipPlacement; children: ReactNode }) => {
    return (
        <Tooltip placement={placement} title={tooltipContent} color='#0E3F66'>
            {children}
        </Tooltip>
    )
}

export default AntdTooltip
