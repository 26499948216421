import { useEffect, useState } from 'react';
import { PDFViewer } from '@progress/kendo-react-pdf-viewer';

import DocumentsRepository from 'service/DocumentsRepository';

import { setAuthToken } from '@service/service';

import { Spinner } from '@components'

import '@progress/kendo-theme-default/dist/all.css';

const tools = [
    'pager',
    'spacer',
    'zoomInOut',
    'zoom',
    'selection',
    'spacer',
    'search',
    'download',
    'print'
];

const PreviewDocument = props => {
    const { id } = props.match.params;

    const [isLoading, setIsLoading] = useState(false);
    const [pdf, setPdf] = useState('');

    const getDocumentFile = async id => {
        try {
            setIsLoading(true);
            const fileUrl = await DocumentsRepository.getFileLink(id);
            setPdf(fileUrl)
            setIsLoading(false);
        } catch (error) {
            console.error(`=GetPdf_Error=`, error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setAuthToken(localStorage.getItem('token'));
        getDocumentFile(id);
    }, []);

    if (!pdf || isLoading) {
        return <Spinner />;
    }

    return (
        <PDFViewer url={pdf} tools={tools} onError={(e) => console.error('PDFViewer Error:', e.error)} />
    );
};

export default PreviewDocument;
