import styled from 'styled-components';

const LinkButton = styled.button`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6e8ca3;
    cursor: pointer;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid transparent;
    transition: all ease-in-out 0.2s;

    &:hover {
        border-color: #6e8ca3;
    }

    &:active {
        transform: scale(.96);
    }
`;

export default LinkButton;
