export const parseAddressComponent = (address_components, formatted_address) => {
    const addressObj = { zip: '', line: '', county: '', country: '', city: '', state: '', region: '' };
    for (let i = 0; i < address_components.length; i++) {
        for (let j = 0; j < address_components[i].types.length; j++) {
            switch (address_components[i].types[j]) {
                case 'street_number':
                    addressObj.line = (addressObj.line?.length ? `${addressObj.line} ` : '') + address_components[i].long_name;
                    break;
                case 'administrative_area_level_3':
                    break;
                case 'locality':
                    addressObj.city = address_components[i].long_name;
                    break;
                case 'administrative_area_level_2':
                    addressObj.county = address_components[i].long_name;
                    break;
                case 'postal_code':
                    addressObj.zip = address_components[i].long_name;
                    break;
                case 'route':
                case 'intersection':
                    const addrLine = formatted_address?.includes(address_components[i].short_name) ? address_components[i].short_name : address_components[i].long_name;
                    addressObj.line = (addressObj.line?.length ? `${addressObj.line} ` : '') + addrLine;
                    break;
                case 'country':
                    addressObj.country = address_components[i].long_name;
                    addressObj.state = address_components[i].short_name + '-' + addressObj.state;
                    break;
                case 'administrative_area_level_1':
                    addressObj.region = address_components[i].long_name;
                    addressObj.state = addressObj.state + address_components[i].short_name;
                    break;
            }
        }
    }
    return addressObj;
};

export const findCenterOfPolygon = polygon => {
    if (!window.google) {
        console.error('Google API is not defined');
        return;
    }

    const bounds = new google.maps.LatLngBounds();
    polygon.getPath().forEach(p => bounds.extend(p));
    const center = bounds.getCenter();

    return [center.lat(), center.lng()];
};

export const polygonToArray = polygon => {
    const mapPaths = polygon.getPaths().getArray();
    const coordinates = mapPaths.map(mP => mP.getArray().map(c => [c.lng(), c.lat()]));
    coordinates.forEach(c => {
        if (c[0][0] === c[c.length - 1][0] && c[0][1] === c[c.length - 1][1]) {
            return;
        }
        c.push(c[0]); // MongoDB [GeoJSON]: Requires to close the loop with the same point that you opened it.
    });
    return coordinates;
};

export const coordinatesToLatLng = coordinates => {
    if (!window.google) {
        console.error('Google API is not defined');
        return;
    }

    let coords = [...coordinates];
    coords.forEach(c => {
        if (
            c[0][0] !== c[c.length - 1][0] && // lng
            c[0][1] !== c[c.length - 1][1] // lat
        ) {
            return;
        }
        c.splice(-1); // Google Map: Requires to draw polygon without loop last point.
    });
    coords = coords.map(c => c.map(lnglat => new google.maps.LatLng(lnglat[1], lnglat[0])));

    return coords;
};
