import React, { createContext, useCallback, useEffect, useState } from 'react'

import { createPortal } from 'react-dom'

import styled from 'styled-components'

import { AnimatePresence } from 'framer-motion'

import { uuid } from 'utils/mathUtil'

import Notification from './Notification'

export interface NotificationContextType {
    title: string
    description: string
    type: string
    duration: number
}

export const NotifyContext = createContext<(notificationPayload: NotificationContextType) => void>(() => {})

function useCreateDomElement(): HTMLDivElement | null {
    const [domElement, setDomElement] = useState<HTMLDivElement | null>(null)

    useEffect(() => {
        const element = document.createElement('div')
        document.body.appendChild(element)
        setDomElement(element)

        return () => {
            if (element.parentElement) {
                element.parentElement.removeChild(element)
            }
        }
    }, [])

    return domElement
}

interface NotificationItem extends NotificationContextType {
    id: string
    onClose: () => void
}

function useNotifications() {
    const [notifications, setNotifications] = useState<NotificationItem[]>([])

    const notify = useCallback((notificationPayload: NotificationContextType) => {
        const id = uuid()

        function removeNotification() {
            setNotifications(prevNotifications => prevNotifications.filter(n => n.id !== id))
        }

        setNotifications(prevNotifications => [...prevNotifications, { id, onClose: removeNotification, ...notificationPayload }])

        setTimeout(removeNotification, notificationPayload.duration || 2000)
    }, [])

    return { notify, notifications }
}

interface NotificationProviderProps {
    children: React.ReactNode
}

function NotificationProvider({ children }: NotificationProviderProps) {
    const notificationRoot = useCreateDomElement()

    const { notify, notifications } = useNotifications()
    return (
        <>
            <NotifyContext.Provider value={notify as (notificationPayload: NotificationContextType) => void}>{children}</NotifyContext.Provider>
            {notificationRoot &&
                createPortal(
                    <NotificationsContainer>
                        <AnimatePresence />
                        {notifications.map(notification => (
                            <Notification key={notification.id} {...notification} />
                        ))}
                    </NotificationsContainer>,
                    notificationRoot
                )}
        </>
    )
}

export default NotificationProvider

const NotificationsContainer = styled.div`
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 99999;
    pointer-events: none;

    .notification-success {
        background-color: #35b470;
    }
    .notification-info {
        background-color: #29b7e6;
    }
    .notification-warning {
        background-color: #f0a224;
    }
    .notification-error {
        background-color: #be3434;
    }
`
