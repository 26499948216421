import { Pagination, PaginationProps } from 'antd'
import React, { useEffect, useRef } from 'react'

interface PagerProps {
    page?: number
    limit?: number
    total?: number
    onPageChange?: (params: { page: number; limit: number }) => void
    pageSizeOptions?: string[]
}

export const KendoPager: React.FC<PagerProps> = props => {
    const handleChange: PaginationProps['onChange'] = (page, pageSize) => {
        if (props.onPageChange && page !== props.page) {
            props.onPageChange({
                page,
                limit: pageSize
            })
        }
    }

    const handleChangeLimit: PaginationProps['onShowSizeChange'] = (page, pageSize) => {
        if (props.onPageChange) {
            props.onPageChange({
                page,
                limit: pageSize
            })
        }
    }

    return (
        <Pagination
            style={{
                marginTop: '20px',
                background: 'white',
                padding: '8px'
            }}
            total={props.total}
            current={props.page}
            onShowSizeChange={handleChangeLimit}
            onChange={handleChange}
            pageSize={props.limit}
            pageSizeOptions={props.pageSizeOptions}
            showSizeChanger
        />
    )
}
