export const FETCH_LIST_GEO_ZONES = 'FETCH_LIST_GEO_ZONES';
export const FETCH_LIST_GEO_ZONES_SUCCESS = 'FETCH_LIST_GEO_ZONES_SUCCESS';
export const FETCH_LIST_GEO_ZONES_FAIL = 'FETCH_LIST_GEO_ZONES_FAIL';

export const ADD_GEO_ZONE = 'ADD_GEO_ZONE';
export const ADD_GEO_ZONE_SUCCESS = 'ADD_GEO_ZONE_SUCCESS';
export const ADD_GEO_ZONE_FAIL = 'ADD_GEO_ZONE_FAIL';

export const EDIT_GEO_ZONE = 'EDIT_GEO_ZONE';
export const EDIT_GEO_ZONE_SUCCESS = 'EDIT_GEO_ZONE_SUCCESS';
export const EDIT_GEO_ZONE_FAIL = 'EDIT_GEO_ZONE_FAIL';

export const DELETE_GEO_ZONE = 'DELETE_GEO_ZONE';
export const DELETE_GEO_ZONE_SUCCESS = 'DELETE_GEO_ZONE_SUCCESS';
export const DELETE_GEO_ZONE_FAIL = 'DELETE_GEO_ZONE_FAIL';

export const CLEAR_ERROR = 'CLEAR_ERROR';