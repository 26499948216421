import { useEffect, useState } from 'react';

import { tableFilterAndSort } from 'utils/tableFilter';

const DETAILS_INITIAL_STATE = {
    count: 0,
    page: 0,
    limit: 0,
    pages: 0
};

const SORT_INITIAL_STATE = {
    sort: '',
    order: 'asc'
};

export const useLocalTable = props => {
    const { data, tableColumns, filterData } = props;

    const [details, setDetails] = useState(DETAILS_INITIAL_STATE);
    const [sort, setSort] = useState(SORT_INITIAL_STATE);
    const [limit, setLimit] = useState(20);
    const [filter, setFilter] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [tableData, setTableData] = useState([]);

    const onChangePageHandler = data => {
        setDetails({ ...details, page: data });
        setTableDataPage((data - 1) * limit, data * limit);
    };

    const setTableDataPage = (from, to) => {
        if (!Array.isArray(filteredData)) {
            return;
        }
        let result = [...filteredData];
        const pageData = result.slice(from, to);
        setTableData(pageData);
    };

    useEffect(() => {
        setFilteredData(tableFilterAndSort(data || [], filter, tableColumns, filterData, sort));
    }, [data, filter, sort.order, sort.sort, tableColumns]);

    useEffect(() => {
        const length = filteredData.length || 0;
        setDetails({
            count: length,
            page: 1,
            limit,
            pages: Math.ceil(length / limit)
        });
        setTableDataPage(0, limit);
    }, [filteredData, limit]);

    return {
        details,
        sort,
        limit,
        filter,
        tableData,

        setSort,
        setLimit,
        setFilter,
        onChangePageHandler,
        setTableData
    };
};
