import { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import styled from 'styled-components';
import { baseInputStyles } from './Input';
import InputIcon from './InputIcon';
import { useDateFormat } from '@hooks/useDateFormat';
import { DATE_FORMAT_DEFAULT } from '@constants/settings';

const DatePicker = (props: any) => {
    const [focused, setFocused] = useState(false);
    const datePickerProps = { ...props };
    delete datePickerProps.error;
    const { dateFormat }: any = useDateFormat();
    const displayFormat = dateFormat || DATE_FORMAT_DEFAULT;
    return (
        <StyledSingleDatePicker error={props.error} disabled={props.disabled}>
            <SingleDatePicker
                displayFormat={displayFormat}
                inputIconPosition='after'
                focused={focused}
                onFocusChange={({ focused }: { focused: boolean }) => setFocused(focused)}
                transitionDuration={0}
                customInputIcon={
                    <InputIcon data-cy={props.dataCy}>
                        <img className=' ' src='/static/media/ncalendar.c79d343a0fe1f16933c54d21cca386a1.svg' alt='icon' />
                    </InputIcon>
                }
                {...datePickerProps}
            />
        </StyledSingleDatePicker>
    );
};

export default DatePicker;

const StyledSingleDatePicker = styled.div<{ error: boolean; disabled: boolean }>`
    .DayPickerKeyboardShortcuts_show__bottomRight {
        :before {
            border-right-color: ${({ theme }) => theme.colors.orange};
        }
    }
    .SingleDatePickerInput_calendarIcon {
        position: relative;
    }
    .CalendarDay__selected {
        background: ${({ theme }) => theme?.colors?.orange};
        border: 1px solid ${({ theme }) => theme?.colors?.orange};
        color: white;
    }
    .DayPicker_transitionContainer {
        transition: none;
        /* height: 351px !important; */
    }
    .SingleDatePicker {
        width: 100%;
    }
    .SingleDatePickerInput {
        border: none;
        width: 100%;
        ${baseInputStyles}
        ${({ disabled }) =>
            disabled
                ? `
        background: #f7f7f7;
        border: 1px solid #f4f5f7;
        color: #81878e;
        :hover{
            border: 1px solid #f4f5f7;
        }
        `
                : ''}
    }
    .DateInput {
        border: none;
        width: 100%;
        background-color: inherit;
        border-radius: 5px;
    }
    button {
        padding: 0;
        margin: 0;
    }
    input {
        padding: 0 15px;
        width: 100%;
        border: none;
        background-color: inherit;
        border-radius: 5px;
        height: 40px;
        font-size: 15px;
    }
`;
