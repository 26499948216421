import React, { useMemo } from 'react';

import { Image } from '@components';

import EyeHideIcon from '@assets/icons/EyeHideIcon.svg';
import EyeShowIcon from '@assets/icons/EyeShowIcon.svg';

const EyeIcon = (props) => {
    const {
        originalType = 'text',
        inputType = 'text',
        onSwitchPasswordVisibility = () => { }
    } = props;

    const EyeVisibilityIcon = useMemo(() => inputType === originalType ? EyeHideIcon : EyeShowIcon, [inputType]);

    if (originalType !== 'password') {
        return null;
    }

    return (
        <Image
            className='eye-icon'
            onClick={onSwitchPasswordVisibility}
            src={EyeVisibilityIcon}
        />
    );
};

export default EyeIcon;
