import styled from 'styled-components';

const Box = styled.div`
    font-family: 'Rubik';
    background-color: white;
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
`

export default Box