import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { useNotify } from '@components';

import Repository from 'service/Repository';

const IMPORT_TYPE = {
    API: 'API',
    JWS: 'JWS',
    CSV: 'CSV'
};

export const GET_ALL_DEFAULT_PARAMS = {
    filterColumns: 'status',
    filterValues: 'active'
};

export const useEntity = (entity, options) => {
    const { notifyError, notifySuccess } = useNotify();
    const apiService = options?.apiService || new Repository(entity);
    const entityKey = options?.key || entity;
    const queryClient = useQueryClient();

    const getPaginatedList = (params, select) => {
        const queryKeyParams = { params: params || GET_ALL_DEFAULT_PARAMS };
        const { data, isLoading, isError, status, error } = useQuery([entityKey, queryKeyParams], () => apiService.getPaginatedList({ ...GET_ALL_DEFAULT_PARAMS, ...params }), {
            select: data => (!select ? data : select(data)),
        });

        return { isLoading, isError, error, status, data };
    };

    const getAll = (params = GET_ALL_DEFAULT_PARAMS) => {
        const { data, isLoading, isError, status, error } = useQuery([entityKey, { params }], () => apiService.getAll(params));

        return { isLoading, isError, error, status, data };
    };

    const getById = (id, cb, params) => {
        const { data, isLoading, isError, status, error, refetch } = useQuery([`${entityKey}/${id}`], () => (!id || id !== 'new' ? apiService.getById(id, params) : apiService.nextNo()));

        if (cb) {
            cb(data);
        }

        return { data, error, isLoading, status, isError, refetch };
    };

    const getRestrictedFields = (importType, cb) => {
        if (!importType) {
            return { isImported: false, restrictedFields: [], readOnlyItems: {} };
        };

        const { data } = useQuery([entityKey, 'restricted-fields'], apiService.getRestrictedFields, {
            select: res => {
                if (res?.data?.length) {
                    return { isImported: false, restrictedFields: [], readOnlyItems: {} }
                }

                const { data: restrictedFields } = res;
                const readOnlyItems = {};
                const isImported = importType === IMPORT_TYPE.API || importType === IMPORT_TYPE.JWS;

                for (const key of restrictedFields) {
                    readOnlyItems[key] = { readOnly }
                }

                const result = { isImported, restrictedFields, readOnlyItems };
                if (cb) {
                    cb(result);
                }

                return result;
            },
        });

        return data;
    };

    const nextNo = () => {
        const { data, isLoading, isError, error } = useQuery([entityKey], () => apiService.nextNo(), {
            select: res => res.data
        });

        return { data, error, isLoading, isError };
    };

    const {
        isSuccess: isSuccessCreate,
        isLoading: isLoadingCreate,
        mutate: create
    } = useMutation(data => apiService.create(data), {
        select: res => res.data,
        onSuccess: (data, variables) => {
            options.mutateCallback && options.mutateCallback(data);
            queryClient.invalidateQueries([entityKey]);
            notifySuccess('Created successfully');
        },
        onError: error => {
            notifyError(error?.response?.data);
        }
    });

    const {
        isSuccess: isSuccessActivate,
        isLoading: isLoadingActivate,
        mutate: activate
    } = useMutation(({ _id, ...data }) => apiService.activate(_id, data), {
        select: res => res.data,
        onSuccess: () => {
            queryClient.invalidateQueries([entityKey]);
            notifySuccess('Activated successfully');
        },
        onError: error => {
            notifyError(error?.response?.data);
        }
    });

    const {
        isSuccess: isSuccessChangeStatus,
        isLoading: isLoadingChangeStatus,
        mutate: changeStatus
    } = useMutation(({ _id, ...data }) => apiService.changeStatus(_id, data), {
        select: res => res.data,
        onSuccess: (res, data) => {
            queryClient.invalidateQueries([entityKey]);
            queryClient.setQueryData([`${entityKey}/${data?._id}`], res);
            notifySuccess('Activated successfully');
        },
        onError: error => {
            notifyError(error?.response?.data);
        }
    });

    const {
        isSuccess: isSuccessUpdate,
        isLoading: isLoadingUpdate,
        mutate: update
    } = useMutation(({ _id, ...data }) => apiService.update(_id, data), {
        select: res => res.data,
        onError: error => {
            notifyError(error?.response?.data);
        },
        onSuccess: (res, data) => {
            options?.mutateCallback && options?.mutateCallback(data);
            queryClient.setQueryData([`${entityKey}/${data?._id}`], res);
            queryClient.invalidateQueries([entityKey]);
            notifySuccess('Updated successfully');
        },
        onSettled: () => {
            queryClient.invalidateQueries([entityKey]);
        }
    });

    const {
        isSuccess: isSuccessDelete,
        isLoading: isLoadingDelete,
        mutate: remove
    } = useMutation(id => apiService.remove(id), {
        select: res => res.data,
        onSuccess() {
            queryClient.invalidateQueries([entityKey]);
            notifySuccess('Deleted successfully');
        },
        onError(error) {
            notifyError(error?.response?.data);
        }
    });

    return {
        isLoading: isLoadingDelete || isLoadingUpdate || isLoadingCreate || isLoadingActivate || isLoadingChangeStatus,
        isSuccess: isSuccessDelete || isSuccessUpdate || isSuccessCreate || isSuccessActivate || isSuccessChangeStatus,

        getPaginatedList,
        getAll,
        getById,
        nextNo,
        create,
        activate,
        changeStatus,
        update,
        remove,
        getRestrictedFields
    };
};
