export const LOAD_VISIBLE_FIELDS = 'LOAD_VISIBLE_FIELDS';
export const TOGGLE_VISIBLE_CUSTOMER_FIELD = 'TOGGLE_VISIBLE_CUSTOMER_FIELD';
export const HIDE_ALL_FIELDS = 'HIDE_ALL_FIELDS'
export const SHOW_ALL_FIELDS = 'SHOW_ALL_FIELDS'
export const GET_MY_VIEWS = 'GET_MY_VIEWS';
export const GET_MY_VIEWS_SUCCESS = 'GET_MY_VIEWS_SUCCESS';
export const GET_MY_VIEWS_FAIL = 'GET_MY_VIEWS_FAIL';
export const DELETE_MY_VIEW = 'DELETE_MY_VIEW';
export const SET_MY_VIEW = 'SET_MY_VIEW';
export const SET_MY_VIEW_SUCCESS = 'SET_MY_VIEW_SUCCESS';
export const SET_MY_VIEW_FAIL = 'SET_MY_VIEW_FAIL';
export const RESTORE_DEFAULT_VIEW = 'RESTORE_DEFAULT_VIEW';

export const LOAD_MY_VIEWS = 'LOAD_MY_VIEWS';
