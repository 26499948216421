import * as types from './types';

const initialState = {
    userGroups: [],
    details: { count: 0, filter: '', limit: 0, page: 0, pages: 0 },
    sending: false,
    editting: false,
    adding: false,
    deleting: false,
    deletingFields: []
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DELETE_USER_GROUPS:
            return {
                ...state,
                deleting: true,
                deletingFields: action.payload,
                sending: true
            };
        case types.DELETE_USER_GROUPS_FAIL:
            return {
                ...state,
                deleting: false,
                sending: false
            };
        case types.DELETE_USER_GROUPS_SUCCESS:
            const temp = [...state.userGroups].filter(el => el._id !== action.payload);

            return {
                ...state,
                userGroups: temp,
                deleting: false,
                sending: false
            };
        case types.EDIT_USER_GROUPS:
            return {
                ...state,
                editting: true,
                sending: true
            };
        case types.EDIT_USER_GROUPS_FAIL:
            return {
                ...state,
                editting: false,
                sending: false
            };
        case types.EDIT_USER_GROUPS_SUCCESS:
            const list = [...state.userGroups];
            const index = list.findIndex(e => e._id === action.payload._id);
            list[index] = action.payload;
            return {
                ...state,
                userGroups: list,
                editting: false,
                sending: false
            };
        case types.ADD_USER_GROUPS:
            return {
                ...state,
                adding: true,
                sending: true
            };
        case types.ADD_USER_GROUPS_FAIL:
            return {
                ...state,
                adding: false,
                sending: false
            };
        case types.ADD_USER_GROUPS_SUCCESS:
            return {
                ...state,
                userGroups: [...state.userGroups, action.payload],
                adding: false,
                sending: false
            };
        case types.FETCH_LIST_USER_GROUPS:
            return {
                ...state,
                sending: true
            };
        case types.FETCH_LIST_USER_GROUPS_SUCCESS:
            return {
                ...state,
                userGroups: action.payload.items,
                details: action.payload.details,
                sending: false
            };
        case types.FETCH_LIST_USER_GROUPS_FAIL:
            return {
                ...state,
                sending: false,
                error: action.payload
            };
        case types.DELETE_USER_GROUPS:
            return {
                ...state,
                sending: true
            };
        case types.DELETE_USER_GROUPS_SUCCESS:
            return {
                ...state,
                sending: false
            };
        case types.CLEAR_ERROR:
            return {
                ...state,
                error: ''
            };
        default:
            return state;
    }
};

export default reducer;
