import React from 'react'

const req = require.context('../../icons', false, /^\.\/.*\.svg$/);
let GLYPHS = (req.keys()).reduce((glyphs: any, key: any) => {
    const filename = key.match(new RegExp(/[^/]+(?=\.sprite.svg$)/));
    if(!filename) return glyphs
    const firstWord = filename[0]
    return Object.assign({}, glyphs, { [firstWord]: req(key) });
}, {})

type IconProps = {
    name: string;
    style?: any;
    className?: string;
    width?: number | string;
}

const Icon: React.FC<IconProps> = ({ name = '', style = {}, className = '', width }) => {
    return (
        <svg className={'svg-sprite-icon ' + className} width={width} style={style} viewBox={`${GLYPHS[name]?.default?.viewBox}`}>
            <use xlinkHref={'#'+GLYPHS[name]?.default?.id}/>
        </svg>
    );
}

export default Icon
