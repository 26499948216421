const EscalationRuleIcon = () => (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M17.9999 22.5004H5.99988C5.60206 22.5004 5.22053 22.3424 4.93922 22.0611C4.65792 21.7798 4.49988 21.3982 4.49988 21.0004V12.8094C4.50036 12.684 4.45369 12.563 4.36914 12.4704C4.28458 12.3778 4.16831 12.3203 4.04338 12.3094L2.72738 12.1949C2.43753 12.1693 2.16138 12.06 1.93259 11.8802C1.70379 11.7004 1.53223 11.4579 1.4388 11.1824C1.34537 10.9068 1.33411 10.61 1.40639 10.3281C1.47867 10.0463 1.63137 9.79152 1.84588 9.59492L9.63988 2.47842C10.2829 1.8857 11.1254 1.55664 11.9999 1.55664C12.8744 1.55664 13.7169 1.8857 14.3599 2.47842L22.1529 9.59392C22.3674 9.79053 22.5201 10.0453 22.5924 10.3271C22.6647 10.609 22.6534 10.9058 22.56 11.1814C22.4665 11.4569 22.295 11.6994 22.0662 11.8792C21.8374 12.059 21.5612 12.1683 21.2714 12.1939L19.9569 12.3079C19.8316 12.318 19.7148 12.3752 19.63 12.468C19.5452 12.5608 19.4987 12.6822 19.4999 12.8079V21.0004C19.4999 21.3982 19.3418 21.7798 19.0605 22.0611C18.7792 22.3424 18.3977 22.5004 17.9999 22.5004ZM11.9999 2.55842C11.3755 2.55903 10.774 2.79383 10.3144 3.21642L2.52038 10.3329C2.44862 10.3984 2.39748 10.4833 2.37321 10.5774C2.34894 10.6715 2.3526 10.7705 2.38373 10.8626C2.41486 10.9546 2.47212 11.0355 2.54851 11.0955C2.6249 11.1555 2.71711 11.192 2.81388 11.2004L4.13038 11.3154C4.50487 11.3462 4.85397 11.5171 5.10787 11.7941C5.36177 12.0711 5.50177 12.4337 5.49988 12.8094V21.0004C5.49988 21.133 5.55256 21.2602 5.64633 21.354C5.7401 21.4477 5.86728 21.5004 5.99988 21.5004H17.9999C18.1325 21.5004 18.2597 21.4477 18.3534 21.354C18.4472 21.2602 18.4999 21.133 18.4999 21.0004V12.8079C18.498 12.432 18.6381 12.0692 18.8922 11.7921C19.1463 11.515 19.4957 11.344 19.8704 11.3134L21.1849 11.2004C21.2816 11.192 21.3737 11.1556 21.4501 11.0956C21.5265 11.0357 21.5837 10.9548 21.6149 10.8629C21.6461 10.771 21.6498 10.6719 21.6256 10.5779C21.6015 10.4839 21.5505 10.3989 21.4789 10.3334L13.6859 3.21642C13.226 2.7938 12.6244 2.559 11.9999 2.55842Z'
            fill='#0E3F66'
        />
        <path
            d='M12 11C11.8674 11 11.7402 10.9473 11.6464 10.8536C11.5527 10.7598 11.5 10.6326 11.5 10.5V9C11.5 8.86739 11.5527 8.74021 11.6464 8.64645C11.7402 8.55268 11.8674 8.5 12 8.5C12.1326 8.5 12.2598 8.55268 12.3536 8.64645C12.4473 8.74021 12.5 8.86739 12.5 9V10.5C12.5 10.6326 12.4473 10.7598 12.3536 10.8536C12.2598 10.9473 12.1326 11 12 11Z'
            fill='#0E3F66'
        />
        <path
            d='M12 18.5C11.8674 18.5 11.7402 18.4473 11.6464 18.3536C11.5527 18.2598 11.5 18.1326 11.5 18V16.5C11.5 16.3674 11.5527 16.2402 11.6464 16.1464C11.7402 16.0527 11.8674 16 12 16C12.1326 16 12.2598 16.0527 12.3536 16.1464C12.4473 16.2402 12.5 16.3674 12.5 16.5V18C12.5 18.1326 12.4473 18.2598 12.3536 18.3536C12.2598 18.4473 12.1326 18.5 12 18.5Z'
            fill='#0E3F66'
        />
        <path
            d='M12.5 17H10C9.86739 17 9.74021 16.9473 9.64645 16.8536C9.55268 16.7598 9.5 16.6326 9.5 16.5C9.5 16.3674 9.55268 16.2402 9.64645 16.1464C9.74021 16.0527 9.86739 16 10 16H12.5C12.7652 16 13.0196 15.8946 13.2071 15.7071C13.3946 15.5196 13.5 15.2652 13.5 15C13.5 14.7348 13.3946 14.4804 13.2071 14.2929C13.0196 14.1054 12.7652 14 12.5 14H11.5C10.9696 14 10.4609 13.7893 10.0858 13.4142C9.71071 13.0391 9.5 12.5304 9.5 12C9.5 11.4696 9.71071 10.9609 10.0858 10.5858C10.4609 10.2107 10.9696 10 11.5 10H14C14.1326 10 14.2598 10.0527 14.3536 10.1464C14.4473 10.2402 14.5 10.3674 14.5 10.5C14.5 10.6326 14.4473 10.7598 14.3536 10.8536C14.2598 10.9473 14.1326 11 14 11H11.5C11.2348 11 10.9804 11.1054 10.7929 11.2929C10.6054 11.4804 10.5 11.7348 10.5 12C10.5 12.2652 10.6054 12.5196 10.7929 12.7071C10.9804 12.8946 11.2348 13 11.5 13H12.5C13.0304 13 13.5391 13.2107 13.9142 13.5858C14.2893 13.9609 14.5 14.4696 14.5 15C14.5 15.5304 14.2893 16.0391 13.9142 16.4142C13.5391 16.7893 13.0304 17 12.5 17Z'
            fill='#0E3F66'
        />
    </svg>
)

export default EscalationRuleIcon
