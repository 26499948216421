import React, { useState } from 'react'
import { Popover } from '@nextui-org/react'
import { Space } from 'antd'
import styled, { css } from 'styled-components'

import { QUOTE_STATUSES, STAGE_CHANGE_VALIDATION } from '@constants/quoteStatuses'

import { Icon, Button, Show } from '@common'
import { Stage } from '@customTypes/stage'
import { stageColors } from '@constants/stages'

const CLOSED_STATUSES = ['won', 'lost', 'cancelled']

type QuoteStatusProps = {
    status: string
    canEdit: boolean
    onChange: (status: string) => void
    stages: Stage[]
}

const POPOVER_STYLES = {
    borderRadius: '5px',
    maxHeight: 400,
    boxShadow: '0px 5px 25px -3px rgba(166, 171, 189, 0.80)'
}

const QuoteStatus: React.FC<QuoteStatusProps> = props => {
    const {
        status,
        canEdit,
        onChange,
        stages = []
    } = props

    const activeStatus = stages.find(s => s.key === status)

    const [statusValue, setStatusValue] = useState(activeStatus?.key)
    const disabled = !canEdit

    const notSelected = status === statusValue

    const [isOpen, setIsOpen] = useState(false)

    const closeDropdown = () => {
        !notSelected && setStatusValue(activeStatus?.key)
        setIsOpen(false)
    }

    const handleOnClose = () => {
        setStatusValue(activeStatus?.key)
    }

    const handleOnSelect = (value: any) => {
        setStatusValue(value)
    }

    const handleOnSave = () => {
        if(!statusValue) return
        onChange(statusValue)
        setIsOpen(false)
    }

    return (
        <Popover
            data-cy='quote-status-dropdown'
            disableAnimation
            placement='bottom'
            offset={4}
            shouldFlip
            isOpen={isOpen}
            onOpenChange={e => !disabled && setIsOpen(e)}
            onClose={handleOnClose}>
            <Popover.Trigger>
                <DropdownTrigger color='white' background={activeStatus?.color || stageColors.default} disabled={disabled} data-cy='dropdown-trigger'>
                    {activeStatus?.name}
                    <Show condition={!disabled}>
                        <StyledIcon rotate={isOpen} name='square-arrow-down' />
                    </Show>
                </DropdownTrigger>
            </Popover.Trigger>
            <Popover.Content css={POPOVER_STYLES}>
                <DropdownMenu>
                    <ul>
                        {stages.map(s => {
                            const isValueEqActiveStage = activeStatus?.key === s.key
                            const isStageValid = activeStatus?.allowedNextStages.includes(s.key)
                            const isDisabled = isValueEqActiveStage || !isStageValid
                            return (
                                <DropdownItem key={s.key} selected={statusValue === s.key} onClick={() => !isDisabled && handleOnSelect(s.key)} disabled={isDisabled}>
                                    <Space size={10}>
                                        <CubeColor color={s.color} />
                                        {s.name}
                                    </Space>
                                </DropdownItem>
                            )
                        })}
                    </ul>
                    <Divider />
                    <Controls>
                        <Space size={25}>
                            <CancelButton onClick={closeDropdown}>Cancel</CancelButton>
                            <Button disabled={notSelected} onClick={handleOnSave}>
                                Save
                            </Button>
                        </Space>
                    </Controls>
                </DropdownMenu>
            </Popover.Content>
        </Popover>
    )
}

export const StyledIcon = styled(Icon)<{ rotate?: boolean }>`
    width: 10px;
    height: 5px;
    position: absolute;
    right: 10px;
    transition: all 0.2s ease;

    ${({ rotate }) =>
        rotate
            ? css`
                  transform: rotate(180deg);
              `
            : ''}
`

const DropdownTrigger = styled.div<{ disabled?: boolean; background: string; color: string }>`
    position: relative;
		font-family: Rubik;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    padding: 7px 15px;
    background: ${({ background }) => background};
    color: ${({ color }) => color};

    ${({ disabled }) =>
        disabled
            ? css`
                  cursor: default;
              `
            : css`
                  cursor: pointer;
              `}
`

const DropdownMenu = styled.div`
    width: 217px;
    & > ul {
        padding: 5px;
    }
`

const CubeColor = styled.div<{ color: string }>`
    background: ${({ color }) => color};
    border: 1px solid #212121;
    border-radius: 2px;
    width: 13px;
    height: 13px;
`

const Divider = styled.div`
    background: #dddbda;
    height: 1px;
    width: 100%;
`

const CancelButton = styled(Button)`
    background: #979797;
`

const Controls = styled.div`
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, rgba(224, 231, 255, 0.2) 0%, rgba(224, 231, 255, 0.2) 100%), #fff;

    button {
        width: 70px;
        padding: 13px 0;
        font-size: 14px;
        font-weight: 500;
        justify-content: center;
				font-family: Rubik;
        align-items: center;
        text-transform: uppercase;
    }
`

const DropdownItem = styled.li<{ disabled: boolean; selected?: boolean }>`
    align-items: center;
    display: flex;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #212121;
		font-family: Rubik;
    text-transform: uppercase;
    padding: 8px 10px;
    background: ${({ selected }) => (selected ? '#EDF6FF' : 'transparent')};
    width: 100%;
    border-radius: 5px;
    transition: all 0.1s ease-in-out;

    ${({ disabled }) =>
        disabled
            ? css`
                  color: lightgray;
              `
            : css`
                  cursor: pointer;
                  :hover {
                      background: #dbedff;
                  }
              `}
`

export default QuoteStatus
