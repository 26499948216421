import BSAlert from 'react-bootstrap/Alert';
import Icon from '../../Icon';
import styles from './styles.module.scss';

export const Alert = props => {
    const { children, variant = 'success', className = '', withIcon = false, ...restProps } = props;

    let iconName = 'warning';
    let alertClassName = styles.alert;

    if (className !== '') {
        alertClassName += ' ' + className;
    }
    switch (variant) {
        case 'success':
            break;
        case 'danger':
            iconName = 'warning';
            alertClassName += ' ' + styles.alertDanger;
            break;
        default:
            break;
    }

    return (
        <BSAlert variant={variant} className={alertClassName} {...restProps}>
            {withIcon && <Icon name={iconName} className={styles.alertIcon} />}
            {children}
        </BSAlert>
    );
};
