import instance from '@service/service';

import { LOGIN_PERMISSION } from '@reducers/auth/types';

export default {
    setupInterceptors: (store) => {
        instance.interceptors.response.use(response => response, (error) => {
            if (error.response?.status === 403 && error.response?.data?.error === 'Access forbidden') {
                store.dispatch({ type: LOGIN_PERMISSION, payload: { permission: false } });
            }

            return Promise.reject(error);
        });
    }
};
