import { useMemo, useState } from 'react';

import { ACTIVE, ARCHIVED, INACTIVE } from '@constants/status';

import '@scss/panels/_status-selector.scss';

export const STATUSES = [
    { value: ACTIVE, label: 'A', base: true },
    { value: INACTIVE, label: 'I', base: true },
    { value: ARCHIVED, label: 'D', base: false }
];

export const INITIAL_STATUSES = [ACTIVE];

const StatusSelector = props => {
    const { initialStatuses = INITIAL_STATUSES, base = true, hideStatus = [], onFilterChange = () => {} } = props;

    const [selectedStatuses, setSelectedStatuses] = useState(initialStatuses);

    const statusList = useMemo(() => {
        if (base === true) {
            return STATUSES.filter(s => {
                const isHidden = hideStatus.indexOf(s.value) >= 0;
                return !isHidden && s.base === true;
            });
        }

        return STATUSES.filter(s => {
            return !(hideStatus.indexOf(s.value) >= 0);
        });
    }, [base]);

    const onStatusSelect = clickedStatus => {
        let modifyingStatus = [...selectedStatuses];
        if (selectedStatuses.includes(clickedStatus)) {
            modifyingStatus = modifyingStatus.filter(s => s !== clickedStatus);
        } else {
            modifyingStatus.push(clickedStatus);
        }
        setSelectedStatuses(modifyingStatus);
        onFilterChange(modifyingStatus);
    };

    return (
        <div className='StatusSelector'>
            <ul>
                {statusList.map(status => (
                    <li key={status.value} className={selectedStatuses.includes(status.value) ? `selected` : ''} onClick={() => onStatusSelect(status.value)}>
                        <div className={status.value}>{status.label}</div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default StatusSelector;
