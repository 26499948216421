import { useMemo } from 'react';
import styled from 'styled-components';

import { TableFilterType } from '@customTypes/table';

export type ExtraFiltersProps = {
    filter: TableFilterType;
    updateFilter: (data: Partial<TableFilterType>) => void;
};

export type TableHeaderProps = {
    title: React.ReactNode;
    filter: TableFilterType;
    ExtraFilters?: React.FunctionComponent<ExtraFiltersProps>;
    updateFilter: (data: Partial<TableFilterType>) => void;
};

const TableHeader = ({ title, filter, ExtraFilters, updateFilter }: TableHeaderProps) => {
    const status = useMemo(() => {
        const statusIndex = filter.filterColumns.findIndex(fc => fc === 'status');
        if (statusIndex === -1) return [];
        const statusValues = filter.filterValues[statusIndex];
        if (!statusValues) return [];
        return statusValues.split(';');
    }, [filter]);

    function toggleStatus(item: string) {
        const statusIndex = filter.filterColumns.findIndex(fc => fc === 'status');
        if (statusIndex === -1) return;
        const statusValues = filter.filterValues[statusIndex];
        if (!statusValues && statusValues !== '') return;
        const values = statusValues.split(';');
        const itemIndex = values.findIndex(v => v === item);
        if (itemIndex === -1) {
            const newStatusValues = [...values, [item]].join(';');
            const newFilterValues = [...filter.filterValues];
            newFilterValues[statusIndex] = newStatusValues;
            updateFilter({ filterValues: newFilterValues });
        } else {
            const newValues = [...values];
            newValues.splice(itemIndex, 1);
            const newStatusValues = [...newValues].join(';');
            const newFilterValues = [...filter.filterValues];
            newFilterValues[statusIndex] = newStatusValues;
            updateFilter({ filterValues: newFilterValues });
        }
    }

    return (
        <HeaderWrapper>
            <HeaderItem>{title}</HeaderItem>
            <HeaderItem>
                {ExtraFilters && (
                    <FiltersWrapper>
                        <ExtraFilters filter={filter} updateFilter={updateFilter} />
                    </FiltersWrapper>
                )}
                <Status selected={status.includes('active')} active onClick={() => toggleStatus('active')}>
                    A
                </Status>
                <Status selected={status.includes('inactive')} onClick={() => toggleStatus('inactive')}>
                    I
                </Status>
            </HeaderItem>
        </HeaderWrapper>
    );
};

export default TableHeader;

const HeaderWrapper = styled.div`
    width: 100%;
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    color: #0e3f66;
`;

const HeaderItem = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const FiltersWrapper = styled.div`
    margin-right: 10px;
`;

const Status = styled.div<{ selected?: boolean; active?: boolean }>`
    background-color: ${({ active }) => (active ? '#2ed47a' : 'rgb(145, 150, 153)')};
    width: 25px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    ::after {
        content: '';
        border-bottom: ${({ selected }: any) => (selected ? '2px solid rgb(255, 137, 58);' : 'none')};
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
    }
`;
