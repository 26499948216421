import * as types from './types';

const initialState = {
    customerGroups: [],
    details: { count: 0, filter: '', limit: 0, page: 0, pages: 0 },
    sending: false,
    adding : false,
    deleting : false,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
       
        case types.FETCH_CUSTOMER_GROUPS:
            return {
                ...state,
                sending: true
            };
        case types.FETCH_CUSTOMER_GROUPS_SUCCESS:
            return {
                ...state,
                customerGroups: action.payload.items,
                details: action.payload.details,
                sending: false
            };
        case types.FETCH_CUSTOMER_GROUPS_FAIL:
            return {
                ...state,
                sending: false
            };
        default:
            return state;
    }
};

export default reducer;
