import { useMemo } from 'react'

import moment from 'moment'

import { InfoItalic } from '@assets'

import { CollapsiblePanel, DataPair } from '@components'

import { useDateFormat } from '@hooks/useDateFormat'

const SystemInformation = props => {
    const { created, createdBy, updated, updatedBy, exported, imported, userGroups, merged } = props

    const { dateFormat } = useDateFormat()

    const importedDate = useMemo(() => imported?.updated || imported?.created || null, [imported])

    return (
        <CollapsiblePanel icon={InfoItalic} title='System Information' fixed={false}>
            <DataPair
                titleOne='Date Created'
                valueOne={created ? moment(created).format(dateFormat) : ''}
                titleTwo='Created by'
                valueTwo={createdBy ? `${createdBy?.firstName}, ${createdBy?.lastName}` : ''}
            />
            <DataPair
                titleOne='Date updated'
                valueOne={updated ? moment(updated).format(dateFormat) : ''}
                titleTwo='Updated by'
                valueTwo={updatedBy ? `${updatedBy?.firstName}, ${updatedBy?.lastName}` : ''}
            />
            {exported && (
                <>
                    <DataPair
                        titleOne='Exported to'
                        valueOne={exported.type}
                        titleTwo='Export allowed'
                        valueTwo={exported.allowDate ? moment(exported.allowDate).format(dateFormat) : '–'}
                    />
                    <DataPair
                        titleOne='Export created'
                        valueOne={exported.created ? moment(exported.created).format(dateFormat) : ''}
                        titleTwo='Export updated'
                        valueTwo={exported.updated ? moment(exported.updated).format(dateFormat) : ''}
                    />
                </>
            )}
            {imported && (
                <DataPair titleOne='Imported from' valueOne={imported.type} titleTwo='Imported date' valueTwo={importedDate ? moment(importedDate).format(dateFormat) : ''} />
            )}
            {userGroups?.length && <DataPair titleOne='User Groups' valueOne={userGroups.map(uG => uG.name).join(', ')} />}
            {merged && (
                <>
                    {merged.map((el, index) => (
                        <DataPair
                            key={`${el.previousName}-${el._id}`}
                            titleOne={!index ? 'Previous Customer record(s)' : ''}
                            valueOne={merged[index].previousName}
                            titleTwo={!index ? 'Merged date' : ''}
                            valueTwo={merged[index].mergedAt ? moment(merged[index].mergedAt).format(dateFormat) : ''}
                            className={!index ? '' : 'no-margin-top'}
                        />
                    ))}
                </>
            )}
        </CollapsiblePanel>
    )
}

export default SystemInformation
