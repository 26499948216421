import React, { useState } from 'react'
import styled from 'styled-components';
import { Checkbox as AntCheckbox } from 'antd'

const Checkbox = (props: any) => {
    const [indeterminate, setIndeterminate] = useState(false);
    return <CheckboxStyles
        {...props}
        indeterminate={!props.disabled && indeterminate}
        onMouseEnter={() => setIndeterminate(true)}
        onMouseLeave={() => setIndeterminate(false)}
    />
}

const CheckboxStyles = styled(AntCheckbox)`
    :hover {
        .ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
            background-color: #ff8939;
            border-color: transparent;
            box-shadow: 0px 0px 3px 0px #FF8939;
        }
        .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
            background-color: #ff8939;
            box-shadow: 0px 0px 3px 0px #FF8939;
            border-color: transparent;
        }
        .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
            border-color: #ff8939;
            box-shadow: 0px 0px 3px 0px #FF8939;
        }

        .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
            border-color: #ff8939;
            box-shadow: 0px 0px 3px 0px #FF8939;
        }
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner:after {
        height: 2px;
        width: 10px;
        background-color: #ffffff;
    }

    .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
        background-color: #ff8939;
        border-color: #ff8939;
    }
    .ant-checkbox-inner {
        width: 18px;
        height: 18px;
    }

    width: 18px !important;
    width: auto !important;
    align-items: center;
`

export { Checkbox }
