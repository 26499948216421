import '@scss/components/_formrow.scss';

export default function FormRow({ title, className='', children, renderer, style }) {
    return (
        <div className={`FormRow ${className}`} style={style}>
            {title !== undefined && (
                <div className='FormRow__title noselect'>
                    {title} <div>{renderer}</div>
                </div>
            )}
            <div className='FormRow__content'>{children}</div>
        </div>
    );
}
