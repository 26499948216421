import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchDefaultPlant } from '@reducers/data/plants/actions';

export const useDefaultPlant = () => {
    const { defaultPlant, loading } = useSelector(store => store.data.plants);

    const dispatch = useDispatch();

    useEffect(() => {
        if (loading || defaultPlant) {
            return;
        }

        dispatch(fetchDefaultPlant());
    }, []);

    const getDefaultPlant = () => {
        return defaultPlant;
    }

    return { getDefaultPlant };
};
