import { useState, useEffect } from 'react'

import Arrow from '@assets/icons/Arrow.svg'

import { Image } from '@components'
import { Checkbox } from '@common'

export const Selectitem = (props: {
    groupName: string
    option: { value: string; label: string }[]
    values: { [key: string]: { value: string; label: string }[] }
    onUpdateSelectItem?: (value: { [key: string]: { value: string; label: string }[] }) => void
}) => {
    const {
        groupName,
        option,
        values,

        onUpdateSelectItem = () => { }
    } = props

    const [isActive, setIsActive] = useState(false)
    const [checkAllGroup, setCheckAllGroup] = useState(false)

    const handleGroupOptions = () => {
        if (checkAllGroup) {
            onUpdateSelectItem({ ...values, [groupName]: [...option] })
        } else {
            onUpdateSelectItem({ ...values, [groupName]: [] })
        }
        setCheckAllGroup(checkAllGroup)
    }

    useEffect(() => {
        const valuesArr = values[groupName] ?? []
        const filterValuesArr = valuesArr?.filter((n: { value: string }) => option.find(o => o.value === n.value))
        const isAllInGroupSelected = filterValuesArr.length !== option.length
        setCheckAllGroup(isAllInGroupSelected)
    }, [values])

    return (
        <div className={`options-group ${isActive ? 'group-active' : ''}`}>
            <div className='group-header'>
                <Checkbox checked={checkAllGroup} onClick={() => handleGroupOptions()} />
                <div className='option' onClick={() => setIsActive(!isActive)}>
                    <span>{groupName}</span>
                    <Image src={Arrow} className={isActive ? 'icon-top' : 'icon-bottom'} />
                </div>
            </div>
            <div className={`options-wrapper ${isActive ? 'wrapper-active' : ''}`}>
                {option.map((el, i) => {
                    const f = values[groupName]?.find((val: { value: string }) => val.value === el.value)
                    return (
                        <div
                            key={el.toString() + i}
                            className='option'
                            onClick={() => {
                                const valuesArr = values[groupName] ?? []
                                if (!f) {
                                    onUpdateSelectItem({ ...values, [groupName]: [...valuesArr, el] })
                                } else {
                                    const groupSelectedOptions = [...valuesArr?.filter(val => val.value !== el.value)]
                                    const selectedOptions = { ...values }
                                    if (groupSelectedOptions.length) {
                                        selectedOptions[groupName] = groupSelectedOptions
                                    } else {
                                        delete selectedOptions[groupName]
                                    }
                                    onUpdateSelectItem({ ...selectedOptions })
                                }
                            }}>
                            <Checkbox checked={!f} />
                            <span>{el.label}</span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Selectitem
