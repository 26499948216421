import React from 'react';
import styled from 'styled-components';

import '@scss/components/_progressbar.scss';

const Bar = styled.div`
    width: ${p => p.percent}%;
    background-color: ${p => p.bgcolor};
    height: ${p => p.height}px;
    border-radius: 0px 8px 8px 0px;
`;

const ColoredProgressBar = ({ value = 0, color, label = '' }) => {
    return (
        <div className='Coloured-ProgressBar'>
            <Bar percent={value} bgcolor={color} height={20} />
            {label && <span> {label} </span>}
        </div>
    );
};

export default ColoredProgressBar;
