export interface IBasicFilter<T extends {}> {
    orderBy: string | keyof T | '_id'
    orderDirection: 'asc' | 'desc'
    page: number
    pageSize: number
}

export enum NumericOperator {
    EQUAL = 'eq',
    NOT_EQUAL = 'ne',
    GREATER_THAN = 'gt',
    GREATER_THAN_OR_EQUAL = 'gte',
    LESS_THAN = 'lt',
    LESS_THAN_OR_EQUAL = 'lte'
}

export enum StringOperator {
    EQUAL = 'eq',
    NOT_EQUAL = 'ne',
    STARTS_WITH = 'sw',
    CONTAINS = 'co',
    DOESNT_CONTAIN = 'dn',
    ENDS_WITH = 'ew',
    IS_EMPTY = 'ie',
    IS_NOT_EMPTY = 'ine'
}

export enum DateOperator {
    EQUAL = 'eq',
    NOT_EQUAL = 'ne',
    GREATER_THAN = 'gt',
    GREATER_THAN_OR_EQUAL = 'gte',
    LESS_THAN = 'lt',
    LESS_THAN_OR_EQUAL = 'lte'
}

export enum OptionsOperator {
    IN = 'in',
    NOT_IN = 'notIn'
}

export enum BooleanOperator {
    IN = 'in'
}

export enum Status {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    ARCHIVED = 'archived',
    BLOCKED = 'blocked'
}

export type StatusFilter = {
    [K in OptionsOperator]?: Status[]
}

export type NumericFilter = {
    [K in NumericOperator]?: number
}

export type StringFilter = {
    [K in StringOperator]?: string
}

export type DateFilter = {
    [K in DateOperator]?: Date
}

export type OptionsFilter = {
    [K in OptionsOperator]?: string[] | boolean[]
}

export type BooleanFilter = {
    [K in BooleanOperator]?: [boolean]
}

export type GridOperator = 'eq' | 'neq' | 'contains' | 'doesnotcontain' | 'startswith' | 'endswith' | 'isempty' | 'isnotempty' | 'gt' | 'gte' | 'lt' | 'lte' | 'in' | 'notIn'
export type QueryOperator = OptionsOperator | NumericOperator | StringOperator | DateOperator | BooleanOperator

export type FieldFilter = NumericFilter | StringFilter | DateFilter | OptionsFilter | StatusFilter | BooleanFilter

export type FieldFilters<T extends {}> = {
    [K in keyof T]?: {
        operations: FieldFilter
    }
}

export type FilterType<T extends {}> = Partial<IBasicFilter<T> & FieldFilters<T>>

export type FilterObject = {
    key: string
    operations: FieldFilter
}

export type FiltersArray = FilterObject[]
