import { IFilterDropdownItem } from './FilterDropdown/SelectDropdown'

export const numericFilterOptions: IFilterDropdownItem[] = [
    { label: 'Equal', value: 'eq' },
    { label: 'Not Equal', value: 'neq' },
    { label: 'Greater Than', value: 'gt' },
    { label: 'Greater Than or Equal', value: 'gte' },
    { label: 'Less Than', value: 'lt' },
    { label: 'Less Than or Equal', value: 'lte' }
]

export const stringFilterOptions: IFilterDropdownItem[] = [
    { label: 'Equal', value: 'eq' },
    { label: 'Not Equal', value: 'neq' },
    { label: 'Starts With', value: 'startswith' },
    { label: 'Contains', value: 'contains' },
    { label: 'Does Not Contain', value: 'doesnotcontain' },
    { label: 'Ends With', value: 'endswith' },
    { label: 'Is Empty', value: 'isempty' },
    { label: 'Is Not Empty', value: 'isnotempty' }
]

export const dateFilterOptions: IFilterDropdownItem[] = [
    { label: 'Equal', value: 'eq' },
    { label: 'Not Equal', value: 'neq' },
    { label: 'After', value: 'gt' },
    { label: 'After or Equal', value: 'gte' },
    { label: 'Before', value: 'lt' },
    { label: 'Before or Equal', value: 'lte' }
]

export const booleanFilterOptions: IFilterDropdownItem[] = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' }
]

export const statusFilterOptions: IFilterDropdownItem[] = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
    { label: 'Archived', value: 'archived' },
    { label: 'Blocked', value: 'blocked' }
]
