import { combineReducers } from 'redux';

import { auth } from './reducers/auth';
import { user } from './reducers/user';
import { table } from './reducers/table';
import { settings } from './reducers/settings';
import { globalModal } from './reducers/globalModal';

import data from './reducers/data';

export default combineReducers({
    auth,
    user,
    table,
    data,
    settings,
    globalModal,
});
