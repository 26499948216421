import { GridFilterCellProps } from '@progress/kendo-react-grid'
import { DatePicker, Input, InputNumber } from 'antd'
import styled, { css } from 'styled-components'
import { booleanFilterOptions, dateFilterOptions, numericFilterOptions, stringFilterOptions } from './filter.constants'
import React, { useCallback, useMemo } from 'react'
import { IFilterDropdownItem, SelectDropdown } from './FilterDropdown/SelectDropdown'
import dayjs from 'dayjs'
import { GridOperator } from 'filters/filter.types'

type PrimitiveFilterType = 'boolean' | 'text' | 'numeric' | 'date'

const optionsRecord: Record<PrimitiveFilterType, IFilterDropdownItem[]> = {
    numeric: numericFilterOptions,
    text: stringFilterOptions,
    date: dateFilterOptions,
    boolean: booleanFilterOptions
}

const castValue = (value: any, filterType: PrimitiveFilterType) => {
    if (filterType === 'numeric') {
        if (value === '' || value === null || value === undefined) return null
        return Number(value)
    }
    if (filterType === 'boolean') {
        return value === 'true'
    }
    if (filterType === 'date') {
        return value && new Date(value)
    }
    return value
}

const getDefaultOperator = (filterType: PrimitiveFilterType): GridOperator => {
    if (filterType === 'text') return 'contains'
    return 'eq'
}

export const PrimitiveFilter: React.FC<GridFilterCellProps> = ({ onChange, value, operator, filterType }) => {
    const usedOperator = operator || getDefaultOperator(filterType)
    const handleChange: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
        event => {
            event.stopPropagation()
            onChange({ value, operator: event.target.value, syntheticEvent: event })
        },
        [onChange]
    )

    const handleInputChange = useCallback(
        (v: any, event: React.SyntheticEvent<any, Event>) => {
            event.stopPropagation()
            onChange({ value: castValue(v, filterType), operator: usedOperator, syntheticEvent: event })
        },
        [onChange, usedOperator, filterType]
    )

    const options = useMemo(() => optionsRecord[filterType], [filterType])

    return (
        <Container>
            <InputRenderer value={value} onChange={handleInputChange} type={filterType} />
            <SelectDropdown options={options} onChange={handleChange} value={usedOperator} />
        </Container>
    )
}

interface IInputRendererProps {
    value: any
    onChange: (value: any, event: React.SyntheticEvent<any, Event>) => void
    type: PrimitiveFilterType
}

const fakeEvent = (): React.SyntheticEvent<any, Event> => {
    const event = new Event('change', { bubbles: true })
    return {
        ...event,
        isDefaultPrevented: () => false,
        preventDefault: () => {},
        isPropagationStopped: () => false,
        stopPropagation: () => {},
        nativeEvent: event,
        persist: () => {},
        target: {
            addEventListener: () => {},
            removeEventListener: () => {},
            dispatchEvent: () => false
        }
    }
}

const InputRenderer: React.FC<IInputRendererProps> = ({ type, onChange, value }) => {
    const handleChange = useCallback(
        (value: any) => {
            const event = fakeEvent()
            onChange(value, event)
        },
        [onChange, type]
    )

    const normalChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        onChange(event.target.value, event)
    }

    const dateChange = useCallback((dayjs: unknown, dateString: string) => {
        onChange(dateString, fakeEvent())
    }, [])

    switch (type) {
        case 'numeric':
            return <NumberInput value={value} onChange={handleChange} />
        case 'date':
            return <DateInput value={value && dayjs(value)} onChange={dateChange} />
        case 'boolean':
            return <></>
        default:
            return <NormalInput value={value} onChange={normalChange} />
    }
}

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
`

const InputStyle = css`
    background-color: rgba(224, 231, 255, 0.2);
    border-radius: 5px;
    border: 1px solid #e0e7ff;

    &:focus {
        border-color: #b4c5ff;
    }
`

const NormalInput = styled(Input)`
    ${InputStyle}
`

const NumberInput = styled(InputNumber)`
    ${InputStyle}
`

const DateInput = styled(DatePicker)`
    ${InputStyle}
`
