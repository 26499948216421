import { useState } from 'react';
import service from '@service/service';

const useRequest = ({ url, method, body, onSuccess, onFail, config, onFinally }) => {
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const doRequest = async (props = {}) => {
        try {
            setLoading(true);
            setErrors(null);
            let query = '';
            if (typeof props.query === 'string') {
                query = props.query;
            }
            if (typeof props.query === 'object') {
                let objKeys = Object.keys(props.query);
                objKeys.forEach(objKey => {
                    if (query.length > 1) query += '&';
                    if (query.length === 0) query = '?';
                    query += `${objKey}=${props.query[objKey]}`;
                });
            }

            let response;
            if (method === 'get') {
                response = await service[method]((props.url || url) + (props.params || '') + query, props.config || config);
            } else {
                response = await service[method]((props.url || url) + (props.params || '') + query, props.body || body, props.config || config);
            }

            if (props.onSuccess) {
                props.onSuccess(response.data);
                return response.data;
            }
            if (onSuccess) {
                onSuccess(response.data, response.headers);
            }
            return response.data;
        } catch (e) {
            const error = e;
            if (!error.response || !error.response.data) {
                setErrors([{ message: 'Something went wrong...' }]);
                if (onFail) {
                    onFail(error);
                }
                return;
            }
            setErrors(error.response.data);
            if (onFail) {
                onFail(error.response.data);
            }
        } finally {
            if (onFinally) {
                onFinally();
            }
            setLoading(false);
        }
    };

    return [doRequest, errors, loading];
};

export default useRequest;
