import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';

import { useDateFormat } from '@hooks/useDateFormat';

import { TIME_ZONE } from 'consts/settings';

import { fallbackTimeZonesList } from './const';

// From Moment to Luxon.
export const LUXON_DATES_FORMAT = {
    'DD/MM/YYYY': 'dd/LL/yyyy',
    'DD-MM-YYYY': 'dd-LL-yyyy',
    'MM/DD/YYYY': 'LL/dd/yyyy',
    'MM-DD-YYYY': 'LL/dd/yyyy',
    'MMM Do, YYYY': 'LLL DD, yyyy',
    'Do MMM, YYYY': 'DD LLL, yyyy',
}

export const useTimeZone = () => {
    const [timeZoneList, setTimeZoneList] = useState([]);
    const { settings } = useSelector(store => store.settings);
    const { dateFormat } = useDateFormat();
    const timeZone = settings.find(x => x.key === TIME_ZONE)?.value || 'America/New_York';

    useEffect(() => {
        if (!Intl.supportedValuesOf) {
            setTimeZoneList(fallbackTimeZonesList);
        } else {
            const tz = Intl.supportedValuesOf('timeZone');
            setTimeZoneList(tz);
        }
    }, []);

    const UTCToLocalTime = (dt) => {
        if (!dt) {
            return null;
        }

        return DateTime.fromISO(dt, { zone: timeZone }).toFormat(LUXON_DATES_FORMAT[dateFormat]);
    };

    return {
        timeZone,
        timeZoneList,
        UTCToLocalTime
    };
};
