import { Document, Image, Page, pdf, Text, View } from '@react-pdf/renderer';
import moment from 'moment';

import { LogoWDC } from '@assets';

import { Quote } from '@customTypes/quote'

import { STANDARD, OTHER, ASSOCIATED } from '@constants/productLines';

import Entry from '../Pages/Setup/TCSettings/PDFPreview/Entry';

import { TCT, dateFormatT, defaultIndexTypeT, displayFieldsT } from './types'

import { DISPLAY_FIELDS } from './columns';

import styles from './wdc-style';

type contactDataT = {
    contactData: {
        firstName?: string
        lastName?: string
        phone?: string
        email?: string
    }
}

interface QuoteWDCI {
    data: Quote & displayFieldsT & dateFormatT & defaultIndexTypeT & TCT & contactDataT
    options: any
    entityGroupByKey: any
    currencyFormat: any
}

export const QuoteWDC = (props: QuoteWDCI) => {
    const { data, options, entityGroupByKey, currencyFormat } = props;
    const displayFields = data.displayFields || DISPLAY_FIELDS;

    const nonStandardProducts = data?.quoteEntries?.filter((row) =>
        row?.product?.productType?.key !== STANDARD &&
        row?.product?.productType?.key !== OTHER &&
        row?.product?.productType?.key !== ASSOCIATED)
    const standardProducts = data?.quoteEntries?.filter((row) => row?.product?.productType?.key === STANDARD) || []
    const surchargesProducts = data?.quoteEntries?.filter((row) => row?.product?.productType?.key === OTHER)
    const associatedProducts = data?.quoteEntries?.filter((row) => row?.product?.productType?.key === ASSOCIATED) || []

    const lineAddress = data?.address?.line || '';
    const cityAddress = data?.address?.city || '';
    const stateAddress = data?.address?.state || '';
    const zipAddress = data?.address?.zip || '';

    return (
        <Document title='Quote'>
            <Page size='A4' style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>

                <View style={{ paddingHorizontal: 35, marginTop: '20px', marginBottom: '20px', justifyContent: 'space-between', flexDirection: 'row' }} fixed>
                    <View>
                        <Image src={LogoWDC} style={{ width: '111px' }} />
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <Text style={styles.primary_light}>10 Wayne Davis Drive, Tallapoosa, GA 30176</Text>
                        <Text style={styles.primary_light}>{`Phone: (770) 574 2326`}</Text>
                        <Text style={styles.primary_light}>{`FAX: (678) 573 3303`}</Text>
                    </View>
                </View>
                <View style={{ paddingHorizontal: 35, flexDirection: 'column', marginBottom: 10 }}>
                    <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <View style={{ lineHeight: 1.5, flex: 2 }}>
                            <View style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                                <Text style={styles.h3_black}>Date:</Text>
                                <Text style={{ ...styles.h3_red, textTransform: 'uppercase' }}>{moment(data?.currentProgressDateSet).format(data.dateFormat)}</Text>
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                                <Text style={styles.h3_black}>Customer:</Text>
                                <Text style={{ ...styles.h3_red, flexWrap: 'wrap' }}>{data?.customer?.name}</Text>
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                                <Text style={styles.h3_black}>Customer ID:</Text>
                                <Text style={styles.primary_bold}>{data?.customer?.customerNo}</Text>
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                                <Text style={styles.h3_black}>Attn:</Text>
                                <Text style={styles.primary_bold}>{data.contactData?.firstName || ''} {data.contactData?.lastName || ''}</Text>
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                                <Text style={styles.h3_black}>Contact Email:</Text>
                                <Text style={styles.primary_bold}>{data.contactData?.email || ''}</Text>
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                                <Text style={styles.h3_black}>Phone:</Text>
                                <Text style={styles.primary_bold}>{data.contactData?.phone || ''}</Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'column', lineHeight: 1.5, flex: 1 }}>
                            <View style={{ display: 'flex', flexDirection: 'row', gap: '0 2px', flexWrap: 'wrap' }}>
                                <Text style={{ ...styles.h3_black, marginRight: '3px' }}>Project name:</Text>
                                {(data?.project?.name || data?.name)?.split(' ').map((t, i) => <Text key={i} style={{ ...styles.h3_red, textTransform: 'uppercase' }}>{t}</Text>)}
                            </View>
                            <View style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                                <Text style={styles.h3_black}>Quote ID:</Text>
                                <Text style={{ ...styles.h3_red, textTransform: 'uppercase' }}>{data.quoteNo}<Text style={{ ...styles.h3_red, textTransform: 'uppercase' }}> - {data.version}</Text></Text>
                            </View>
                            {(data?.project?.potentialStartDate && data?.project?.potentialEndDate) && (
                                <View>
                                    <View style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                                        <Text style={styles.h3_black}>Project Start Date:</Text>
                                        <Text style={{ ...styles.h5_black, textTransform: 'uppercase' }}>{moment(data.project.potentialStartDate).format(data.dateFormat)}</Text>
                                    </View>
                                    <View style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                                        <Text style={styles.h3_black}>Project End Date:</Text>
                                        <Text style={{ ...styles.h5_black, textTransform: 'uppercase' }}>{moment(data.project.potentialEndDate).format(data.dateFormat)}</Text>
                                    </View>
                                </View>
                            )}
                            {data?.priceValidTo && <View style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                                <Text style={styles.h3_black}>Price Good Through:</Text>
                                <Text style={styles.h5_black}>{moment(data?.priceValidTo).format(data.dateFormat)}</Text>
                            </View>}
                            <View style={{ display: 'flex', flexDirection: 'row', gap: '0 5px', flexWrap: 'wrap' }}>
                                <Text style={styles.h3_black}>Delivery Add:</Text>
                                <Text style={styles.h5_black}>{lineAddress}{(lineAddress) && ', '}</Text>
                                <Text style={styles.h5_black}>{cityAddress}{(cityAddress) && ', '}</Text>
                                <Text style={styles.h5_black}>{stateAddress}{(stateAddress) && ', '}</Text>
                                <Text style={styles.h5_black}>{zipAddress}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={{ paddingHorizontal: 35, marginBottom: '5px' }}>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <View style={{ minWidth: '316px' }}></View>
                        {data?.customer && <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <Text style={styles.h3_black}>Terms: </Text>
                            {data?.termCode?.terms?.split(' ').map(text => <Text style={styles.h5_black}>{text} </Text>)}
                        </View>}
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        {displayFields.product && <Text style={{ ...styles.table_th, ...styles.productColumn }}>{entityGroupByKey?.product?.fieldName || 'PRODUCT DESCRIPTION'}</Text>}
                        {displayFields.qty && <Text style={{ ...styles.table_th, ...styles.quantityColumn }}>{entityGroupByKey?.uom?.fieldName || 'UOM'}</Text>}
                        {displayFields.qty && <Text style={{ ...styles.table_th, ...styles.quantityColumn }}>{entityGroupByKey?.qty?.fieldName || 'QUANTITY'}</Text>}
                        {displayFields.price && options.pickedUp && (
                            <Text style={{ ...styles.table_th, ...styles.priceColumn }}>{entityGroupByKey?.price?.fieldName || 'UNIT PRICE'}</Text>
                        )}
                    </View>
                    {nonStandardProducts.map((x, i) => (
                        <View key={`qe-${i}`} style={{ flexDirection: 'row', width: '100%', ...(data.quoteEntries.length - 1 === i ? {} : { borderBottom: '1px solid #898989' }) }}>
                            {displayFields.product && (
                                <Text style={{ ...styles.table_td, ...styles.productColumn, fontSize: '9px' }}>
                                    {x.product?.productNo} - {x.placedName || x.product?.name}
                                </Text>
                            )}
                            {displayFields.uom && (
                                <Text style={{ ...styles.table_td, ...styles.quantityColumn, fontSize: '9px' }}>
                                    {x?.uomValue || x?.uom || x?.product?.uom?.value || ''}
                                </Text>
                            )}
                            {displayFields.qty && (
                                <Text style={{ ...styles.table_td, ...styles.quantityColumn, fontSize: '9px' }}>
                                    {x.qty ? x.qty.toLocaleString() : ''}
                                </Text>
                            )}
                            {displayFields.price && options.pickedUp && (
                                <Text style={{ ...styles.table_td, ...styles.priceColumn, fontSize: '9px' }}>{currencyFormat(x.price)}</Text>
                            )}
                        </View>
                    ))}
                    <View wrap={false}>
                        {(standardProducts.length > 0 || associatedProducts.length > 0) && <Text style={{ ...styles.secondary_light, fontSize: '7px', margin: '5px 0 3px 10px' }}>STANDARD PRODUCTS</Text>}
                        <View>
                            {[...standardProducts, ...associatedProducts].map((x, i) => (
                                <View key={`sqe-${i}`} style={{ flexDirection: 'row', width: '100%', display: 'flex' }}>
                                    <Text style={{ ...styles.table_td, ...styles.productColumn, fontSize: '7px', color: '#383938', padding: '0 10px', height: '13px' }}>
                                        {x.product?.productNo} - {x.placedName || x.product?.name}
                                    </Text>
                                    <Text style={{ ...styles.table_td, ...styles.quantityColumn, fontSize: '7px', color: '#383938', padding: '0 10px', height: '13px' }}>
                                        {x?.uomValue || x?.uom || x?.product?.uom?.value || ''}
                                    </Text>
                                    <Text style={{ ...styles.table_td, ...styles.quantityColumn, fontSize: '7px', color: '#383938', padding: '0 10px', height: '13px' }}>
                                        {x.qty ? x.qty.toLocaleString() : ''}
                                    </Text>
                                    <Text style={{ ...styles.table_td, ...styles.priceColumn, fontSize: '7px', color: '#383938', padding: '0 10px', height: '13px' }}>{currencyFormat(x.price)}</Text>
                                </View>
                            ))}
                        </View>
                    </View>
                    <View wrap={false} style={{ borderTop: '1px solid #898989', marginTop: '5px' }}>
                        {surchargesProducts.length > 0 && <Text style={{ ...styles.secondary_light, fontSize: '7px', margin: '5 0 3px 10px' }}>SURCHARGES PRODUCTS</Text>}
                        <View>
                            {surchargesProducts.map((x, i) => (
                                <View key={`sqe-${i}`} style={{ flexDirection: 'row', width: '100%', display: 'flex' }}>
                                    <Text style={{ ...styles.table_td, ...styles.productColumn, fontSize: '7px', color: '#383938', padding: '0 10px', height: '13px' }}>
                                        {x.product?.productNo} - {x.placedName || x.product?.name}
                                    </Text>
                                    <Text style={{ ...styles.table_td, ...styles.quantityColumn, fontSize: '7px', color: '#383938', padding: '0 10px', height: '13px' }}>
                                        {x?.uomValue || x?.uom || x?.product?.uom?.value || ''}
                                    </Text>
                                    <Text style={{ ...styles.table_td, ...styles.quantityColumn, fontSize: '7px', color: '#383938', padding: '0 10px', height: '13px' }}>
                                        {x.qty ? x.qty.toLocaleString() : ''}
                                    </Text>
                                    <Text style={{ ...styles.table_td, ...styles.priceColumn, fontSize: '7px', color: '#383938', padding: '0 10px', height: '13px' }}>{currencyFormat(x.price)}</Text>
                                </View>
                            ))}
                        </View>
                    </View>
                </View>
                {data?.priceEscalationRules?.length > 0 && <View style={{ paddingHorizontal: 35 }}>
                    <View style={{ display: 'flex', flexDirection: 'row', padding: '5px 10px', borderTop: '1px solid #898989', borderBottom: '1px solid #898989', gap: 30 }}>
                        <View style={{ flex: 1 }}>
                            {data.priceEscalationRules.map(priceEscalationRule => {
                                let value = currencyFormat(priceEscalationRule.value);
                                return (
                                    <View key={priceEscalationRule.value} style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Text style={{ ...styles.primary_light, fontSize: '7px', flex: 1 }}>
                                            {`*Escalator per yard = ${value} Effective ${moment(priceEscalationRule.dateStart).format(data.dateFormat)}-${moment(priceEscalationRule.dateEnd).format(data.dateFormat)}`}
                                        </Text>
                                    </View>
                                )
                            })}
                        </View>
                    </View>
                </View>}

                <Text style={{ ...styles.pageNumber, zIndex: 10000 }} render={({ pageNumber, totalPages }) => (totalPages > 1 ? `${pageNumber}` : '')} fixed />
                <View wrap={options?.isBreake} style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}>
                    <View>
                        {data?.tc && (
                            <View style={{ paddingHorizontal: 35, marginBottom: 20 }} wrap={options?.isBreake}>
                                <View style={{ marginTop: 20 }}>
                                    <Text style={{ ...styles.secondary_bold, fontSize: '8px' }}>NOTE</Text>
                                    <View style={{ marginTop: 5 }}>
                                        <Text style={styles.secondary_light_additional_terms}>{data?.tc}</Text>
                                    </View>
                                </View>
                            </View>
                        )}
                        <View style={{ paddingHorizontal: 35, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: 30 }} wrap={false}>
                            {options.templateText && (
                                <View style={{ marginTop: 20 }}>
                                    <Text style={{ ...styles.secondary_bold, fontSize: '8px' }}>Additional Terms</Text>
                                    <Entry style={{ ...styles.secondary_light_additional_terms }} entry={options.templateText} />
                                </View>
                            )}
                        </View>
                    </View>
                    <View style={{ flexDirection: 'column', paddingHorizontal: 35 }}>
                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingVertical: 20, paddingBottom: 35 }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 5 }}>
                                <Text style={{ ...styles.h3_red, fontSize: '7px' }}>Customer name:</Text>
                                <View style={{ width: '100', borderBottom: '1 solid #F95C00' }}></View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 5 }}>
                                <Text style={{ ...styles.h3_red, fontSize: '7px' }}>Customer signature:</Text>
                                <View style={{ width: '100', borderBottom: '1 solid #F95C00' }}></View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 5 }}>
                                <Text style={{ ...styles.h3_red, fontSize: '7px' }}>Date:</Text>
                                <View style={{ width: '100', borderBottom: '1 solid #F95C00' }}></View>
                            </View>
                        </View>
                    </View>
                </View>
                <View fixed style={{ paddingHorizontal: 35 }}>
                    <View style={{ display: 'flex', flexDirection: 'row', borderTop: '1px solid #F95C00', paddingVertical: 20, justifyContent: 'space-between' }}>
                        <View style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                            <Text style={{ ...styles.h3_black, fontSize: '8px' }}>Salesperson:</Text>
                            <Text style={{ ...styles.primary_light, fontSize: '8px' }}>{`${data?.userResponsible?.firstName} ${data?.userResponsible?.lastName}`}</Text>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                            <Text style={{ ...styles.h3_black, fontSize: '8px' }}>Phone:</Text>
                            <Text style={{ ...styles.primary_light, fontSize: '8px' }}>
                                {data?.userResponsible?.directPhone}
                            </Text>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                            <Text style={{ ...styles.h3_black, fontSize: '8px' }}>Email:</Text>
                            <Text style={{ ...styles.primary_light, fontSize: '8px' }}>{data?.userResponsible?.email}</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document >
    );
};

export const QuoteWDCBlob = async (props: QuoteWDCI) => {
    return await pdf(QuoteWDC(props)).toBlob();
};
