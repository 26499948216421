import React from 'react'

const TooltipContent = ({ itemData }) => {
    return (
        <div>
            <p style={{ marginBottom: 5 }}>Volume (t) {itemData.volume}</p>
            <p style={{ marginBottom: 5 }}>Revenue ($) {itemData.value}</p>
            <p style={{ marginBottom: 5 }}>Customer: {itemData.customer}</p>
            <p style={{ marginBottom: 0 }}>Product: {itemData.product}</p>
        </div>
    );
};

export default TooltipContent;