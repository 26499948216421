import React from 'react';
import { pdf, Page, Text, View, Document, Image, StyleSheet } from '@react-pdf/renderer';

import { TKBLogo } from '@assets';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFF'
    },
    container: {
        flexDirection: 'row',
        alignItems: 'stretch',
        backgroundColor: '#0E3F66',
        height: 56
    },
    logo: {
        paddingVertical: 10,
        paddingHorizontal: 12
    }
});

export const QuoteDefault = () => {
    return (
        <Document title='Quote'>
            <Page size='A4' style={styles.page}>
                <View style={styles.container}>
                    <Image src={TKBLogo} style={styles.logo} />
                </View>
                <Text>No template defined</Text>
            </Page>
        </Document>
    );
};

export const QuoteBlob = async (props) => {
    return await pdf(QuoteDefault(props)).toBlob();
};
