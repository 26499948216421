import React, { SyntheticEvent } from 'react'
import { CheckboxFilter } from './CheckboxFilter'
import { GridFilterCellProps } from '@progress/kendo-react-grid'
import { IFilterDropdownItem } from './FilterDropdown/SelectDropdown'

interface CustomCheckboxFilterProps extends GridFilterCellProps {
    options?: IFilterDropdownItem[]
}

export const CustomCheckboxFilter: React.FC<CustomCheckboxFilterProps> = ({ onChange, value, options = [] }) => {
    const handleChange = (value: string[], event: React.ChangeEvent<HTMLSelectElement>) => {
        onChange({ value, operator: 'in', syntheticEvent: event })
    }

    return <CheckboxFilter options={options} onSubmit={handleChange} value={value} />
}

export const withCustomOptions = (options?: IFilterDropdownItem[]) => {
    return (props: GridFilterCellProps) => <CustomCheckboxFilter {...props} options={options} />
}
