import { Product_Other } from '@assets';

import { StatusBadge } from '@components';

import { ACTIVE } from '@constants/status';

import Mapping from '../MasterDataHeader/mapping';

import '@scss/components/_compact-form-header.scss';

const CompactFormHeader = ({ entity, entityPrefix = '', documentStatus = ACTIVE }) => {
    let title, icon;

    if (typeof entity === 'object' && entity !== null) {
        const MappingEntity = Mapping.find(val => val.type === entity.key) ? Mapping.find(val => val.type === entity.key) : { title: entity, icon: Product_Other };
        icon = MappingEntity?.icon;
        title = entity?.name;
    } else {
        const MappingEntity = entity ? Mapping.find(val => val.type === entity?.trim()?.toLowerCase()) : { title: '', icon: null };
        title = MappingEntity?.title;
        icon = MappingEntity?.icon;
    }

    return (
        <div className='CompactFormHeader'>
            <div className='profile'>
                <div className='profile-icon'>{icon ? <img className='user-icon' src={icon} alt='icon' /> : ''}</div>
            </div>
            <div className='status'>
                <p className='entity'>{entityPrefix + (title || '')}</p>
                <div className='status__withLock'>
                    <StatusBadge status={documentStatus} />
                </div>
            </div>
        </div>
    );
};

export default CompactFormHeader;
