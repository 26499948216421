import { useEntity } from '@hooks/useEntity';

import service from './service';

export const fetchPlants = async (params = {}) => {
    const res = await service.get(`/plants`, { params });
    return res.data;
}

export const fetchPlantsNew = async (params = {}) => {
    const res = await service.get(`/plants/pagination`, { params });
    return res.data;
}


export const fetchPlant = async (id, errorCallback = () => { }) => {
    const res = await service.get(`/plants/${id}`);
    return res.data;
}

export const fetchDefaultPlant = async (errorCallback = () => { }) => {
    const res = await service.get(`/plants/default-plant`);
    return res.data;
}

export const fetchNextPlantNo = async (isCompetitorPlant) => {
    const params = new URLSearchParams([['isCompetitorPlant', isCompetitorPlant || false]]);
    const res = await service.get('/plants/no', { params });
    return res.data.plantNo;
}

export const updatePlant = async (plant, errorCallback = () => { }) => {
    const res = await service.put(`/plants/${plant._id}?isCompetitorPlant=${plant.isCompetitorPlant || false}`, plant);
    return res.data;
};

export const savePlant = async (plant, errorCallback = () => { }) => {
    const res = await service.post('/plants/', plant);
    return res.data;
}

export const attemptPlantLock = async (id, errorCallback = () => { }) => {
    try {
        const res = await service.post(`/plants/lock/${id}`);
        return res.data.success === true;
    } catch (error) {
        console.log(error)
        errorCallback(error)
    }
    return false
}

export const unlockPlant = async (id, errorCallback = () => { }) => {
    try {
        const res = await service.post(`/plants/unlock/${id}`);
        return res.data.success === true;
    } catch (error) {
        console.log(error)
        errorCallback(error)
    }
    return false
}

export const changeStatus = async (id, status, errorCallback = () => { }) => {
    try {
        const res = await service.put(`/plants/status/${id}`, { status });
        return res.data;
    } catch (error) {
        console.log(error)
        errorCallback(error)
    }
    return {}
}

export const deletePlant = async (id, isCompetitorPlant = false) => {
    await service.delete(`/plants/${id}?isCompetitorPlant=${isCompetitorPlant}`);
}

export const bulkDeletePlants = async (ids) => {
    const res = await service.post(`/plants/delete`, ids);
    return res.data;
}

export const usePlantsApi = () => useEntity('plants');
