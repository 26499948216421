import moment from 'moment';
import React, { Component } from 'react';
import styled from 'styled-components';

import CalendarIcon from '@assets/icons/ncalendar.svg';

import { Image } from '../';

import Calendar from './Calendar';
import DateInput from './DateInput';

const Picker = styled.div`
    .calendar {
        top: ${p => p.top}px;
        left: ${p => p.left}px;
    }
`;

class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.calendarRef = React.createRef(null);
        this.dateInputRef = React.createRef(null);

        // console.log('this.props.value: ', this.props.value);
        // console.log('moment(this.props.value): ', moment(this.props.value));
        this.state = {
            date: moment(this.props.value),
            isCalendarOpen: false,
            coords: null,
            readOnly: this.props.readOnly,
            autoFill: this.props.autoFill
        };
    }

    componentWillReceiveProps(newProps) {
        const oldProps = this.props;
        if (oldProps !== newProps) {
            this.setState({ date: moment(newProps.value) });
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            document.addEventListener('mousedown', this.onClickOutside.bind(this));
            document.addEventListener('touchstart', this.onClickOutside.bind(this));
        }
    }

    componentWillUnmount() {
        if (typeof window !== 'undefined') {
            document.removeEventListener('mousedown', this.onClickOutside.bind(this));
            document.removeEventListener('touchstart', this.onClickOutside.bind(this));
        }
    }

    onClickOutside(e) {
        if (!this.calendarRef.current) {
            return;
        }

        if (!this.calendarRef.current.contains(e.target) && !this.dateInputRef.current.contains(e.target)) {
            this.setState({
                isCalendarOpen: false
            });
            // document.getElementsByClassName('overflow-overlay')?.forEach(element => {
            //     element.classList.remove('overflow-hidden')
            // })
        }
    }

    toggleCalendar() {
        this.setState({
            isCalendarOpen: !this.state.isCalendarOpen
        });
        let coords = this.dateInputRef.current.getBoundingClientRect();
        const tab_component = this.props.parentComponent || document.getElementsByClassName('react-tabs__tab-panel--selected')?.[0];
        const model_component = document.getElementsByClassName('table_modal')[0];

        let y = coords?.y + coords?.height + 5 - (tab_component ? tab_component.getBoundingClientRect().y : 0) - (model_component ? model_component.getBoundingClientRect().y : 0);
        let x = coords?.x - (tab_component ? tab_component.getBoundingClientRect().x : 0) - (model_component ? model_component.getBoundingClientRect().x : 0);
        if (coords?.height + coords?.y + 275 > window.innerHeight) {
            y = y - 285 - coords?.height;
        }
        if (coords.x + 310 > window.innerWidth) {
            x = window.innerWidth - 340;
        }
        this.setState({
            coords: { x, y }
        });
        // if (!this.state.isCalendarOpen) {
        //     document.getElementsByClassName('overflow-overlay')?.forEach(element => {
        //         element.classList.add('overflow-hidden')
        //     })
        // }
        // else {
        //     document.getElementsByClassName('overflow-overlay')?.forEach(element => {
        //         element.classList.remove('overflow-hidden')
        //     })
        // }
    }

    renderCalendar() {
        const {
            minDate,
            maxDate,
            calendarClassName,
            dayClassName,
            dayActiveClassName,
            dayDisabledClassName,
            dayFromOtherMonthClassName,
            monthClassName,
            prevMonthClassName,
            nextMonthClassName
        } = this.props;

        if (!this.state.isCalendarOpen) {
            return null;
        }

        return (
            <Calendar
                calendarRef={this.calendarRef}
                date={this.state.date}
                minDate={minDate}
                maxDate={maxDate}
                selectDay={this.selectDay.bind(this)}
                calendarClassName={calendarClassName}
                dayClassName={dayClassName}
                dayActiveClassName={dayActiveClassName}
                dayDisabledClassName={dayDisabledClassName}
                dayFromOtherMonthClassName={dayFromOtherMonthClassName}
                monthClassName={monthClassName}
                prevMonthClassName={prevMonthClassName}
                nextMonthClassName={nextMonthClassName}
            />
        );
    }

    selectDay(date) {
        const { clickOnDate, name } = this.props;

        // console.log('selectDay date: ', date);

        this.props.onChangeDate(moment(date).startOf('day'));
        this.setState({
            isCalendarOpen: false,
            date: date
        });
        // document.getElementsByClassName('overflow-overlay')?.forEach(element => {
        //     element.classList.remove('overflow-hidden')
        // })
        if (clickOnDate) {
            return clickOnDate(date, name);
        }
    }

    render() {
        const { datepickerClassName, inputClassName } = this.props;
        const { isCalendarOpen } = this.state;

        return (
            <Picker
                className={`${datepickerClassName} ${this.props.formErrors && this.props?.formErrors?.findIndex(x => x.id === this.props.id) != -1 ? 'datepicker--error' : ''} ${
                    isCalendarOpen ? 'datepicker--open' : ''
                } ${this.props?.autoFill ? 'datepicker--autoFill' : ''}`}
                top={this.state.coords?.y}
                left={this.state.coords?.x}
                data-disabled={this.props.readOnly}>
                <DateInput
                    disabled={this.props.readOnly}
                    dateInputRef={this.dateInputRef}
                    inputValue={!this.props.value && this.props.hideDefaultDate ? '' : this.state.date}
                    inputOnClick={this.toggleCalendar.bind(this)}
                    inputClassName={`datepicker__input ${!this.props.readOnly ? 'datepicker__input--active' : ''}`}
                />
                {this.renderCalendar()}
                <div className='calendar-icon' onClick={this.props.readOnly ? null : this.toggleCalendar.bind(this)}>
                    <Image src={CalendarIcon} />
                </div>
                {this.props?.formErrors?.findIndex(x => x.id === this.props.id) != -1 && (
                    <span className='datepicker__error-message'>{this.props?.formErrors?.find(x => x.id === this.props.id)?.message}</span>
                )}
            </Picker>
        );
    }
}

DatePicker.defaultProps = {
    date: new Date(),
    datepickerClassName: 'datepicker'
};

export default DatePicker;
