import { RobotoBold, RobotoLightItalic, RobotoRegular } from '@assets';
import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
    family: 'Roboto',
    fonts: [
        {
            src: RobotoBold,
            fontWeight: 'bold'
        },
        {
            src: RobotoRegular,
            fontWeight: 'normal'
        },
        {
            src: RobotoLightItalic,
            fontStyle: 'italic'
        }
    ]
});

export default StyleSheet.create({
    h1_white: {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 14,
        textTransform: 'uppercase',
        color: '#fff'
    },
    total_red: {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 12,
        color: '#E25D5B'
    },
    total_black: {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 12,
        color: '#383938'
    },
    h2: {
        color: '#383938',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 12,
        textTransform: 'uppercase',
        marginBottom: 9
    },
    h3_red: {
        color: '#383938',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 10,
        textTransform: 'uppercase'
    },
    h3_black: {
        color: '#383938',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 8,
    },
    h5_black: {
        color: '#383938',
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        fontSize: 8
    },
    page_container: {
        position: 'relative',
        paddingVertical: '20px',
        display: 'flex',
        flexDirection: 'column'
    },
    line_continer: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 46
    },
    table_th: {
        color: '#383938',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: 8,
        padding: 5,
        flex: 1,
        textAlign: 'center',
        borderRight: '1px solid black',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%'
    },
    table_td: {
        color: '#383938',
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        fontSize: 8,
        // width: '100%',
        borderRight: '1px solid black',
        padding: 5,
        flex: 1,
        textAlign: 'left',
        flex: '1 1 auto'
    },
    table_td_last: {
        width: 0,
        flex: 0
    },
    alert: {
        marginTop: 10,
        marginBottom: 10,
        color: '#f7685b',
        backgroundColor: '#fde1de',
        borderColor: '#f7685b',
        padding: '5px 10px',
        fontSize: 8,
        borderLeft: '1px solid #f7685b'
    },
    alertLink: {
        textDecoration: 'underline',
        color: '#414141'
    }
});
