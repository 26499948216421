import { shouldShowTECValidationAlert } from 'utils/validators';
import { Icon } from '@components';

const ValidationTaxCertificate = ({ quote }) => {
    const showTECAlert = shouldShowTECValidationAlert(quote);

    if (quote?.taxable === true || showTECAlert === null) {
        return <span className='d-inline-block'>–</span>;
    }

    return (
        <span>
            {showTECAlert === true ? (
                <>
                    No <Icon name='warning' className='ml-1' />{' '}
                </>
            ) : (
                'Yes'
            )}
        </span>
    );
};

export default ValidationTaxCertificate;
