import * as types from './types';

const initialState = {
    invoices: [],
    details: { count: 0, filter: '', limit: 0, page: 0, pages: 0 },
    sending: false,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_LIST_INVOICES_SUCCESS:
            return {
                ...state,
                invoices: action.payload,
                sending: false
            };
        case types.FETCH_LIST_INVOICES_FAIL:
            return {
                ...state,
                sending: false
            };
        default:
            return state;
    }
};

export default reducer;
