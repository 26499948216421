import React from 'react'

import { useFinancialSettings } from '@hooks/useFinancialSettings';

import { DATE_FORMAT, DATE_FORMAT_DEFAULT } from 'consts/settings'

export const useDateFormat = () => {
    const dateFormat = useFinancialSettings(DATE_FORMAT);
    return { dateFormat: dateFormat || DATE_FORMAT_DEFAULT }
}
