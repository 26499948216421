import { BTN_ADD, BTN_APPLY, BTN_CANCEL, BTN_CLOSE, BTN_CONTINUE, BTN_OK, BTN_SAVE, BTN_TEXT_LIST, BTN_DELETE, BTN_MERGE } from '@components/OptionsModal/config'

import { E_DIALOG } from 'consts/errors'
import Feather from '../Components/Feather'

export const MODAL_TYPE_CHANGE_STATUS = 'mChangeStatus'
export const MODAL_TYPE_DELETE_CONFIRM = 'mDeleteConfirm'
export const MODAL_TYPE_SELECT_COMPETITOR = 'mSelectCompetitor'
export const MODAL_TYPE_QUOTE_PROGRESS = 'mQuoteProgress'
export const MODAL_TYPE_COMPETITOR_SELECT = 'mCompetitorSelect'
export const MODAL_TYPE_MAP_VIEW = 'mMapView'
export const MODAL_TYPE_DISCOUNT = 'mDiscount'
export const MODAL_TYPE_TRANSPORT_DATA = 'mTransportData'
export const MODAL_TYPE_QUOTE_PREVIEW_SELECTOR = 'mQuotePreviewSelector'
export const MODAL_TYPE_QUOTE_DISCOUNT_APPROVAL_INFO = 'mQuoteDiscountApproval'
export const MODAL_TYPE_QUOTE_CLOSE_CONFIRM = 'mQuoteCloseConfirm'
export const MODAL_TYPE_QUOTE_SEND_FOR_APPROVAL = 'mQuoteApproval'
export const MODAL_TYPE_QUOTE_REJECT = 'mQuoteReject'
export const MODAL_TYPE_QUOTE_APPROVE = 'mQuoteApprove'
export const MODAL_TYPE_QUOTE_CANCEL_CONFIRM = 'mQuoteCancel'
export const MODAL_TYPE_CHOOSE_ADDRESS = 'mChooseAddress'
export const MODAL_TYPE_NO_NUMBER_SERIES = 'mNoNumberSeries'
export const MODAL_TYPE_NO_PLANTS = 'mNoPlants'
export const MODAL_TYPE_IMPORT_VALIDATION = 'mImportValidation'
export const MODAL_TYPE_IMPORT_FINISH = 'mImportFinish'
export const MODAL_TYPE_ENABLE_SINGLE_TIER = 'mEnableSingleTier'
export const MODAL_TYPE_DISABLE_SINGLE_TIER = 'mDisableCustomerTier'
export const MODAL_TYPE_EDIT_SINGLE_TIER = 'mEditSingleTier'
export const MODAL_TYPE_PROJECT_CHANGE_PROGRESS = 'mProjectChangeProgress'
export const MODAL_TYPE_MULTIPLANT = 'mMultiplant'
export const MODAL_TYPE_PTS_SAVE_BEFORE_APPLY = 'mPtsSaveBeforeApply'
export const MODAL_TYPE_PTS_NO_CATEGORY_PRICES = 'mPtsNoCategoryPrices'
export const MODAL_TYPE_ADD_FROM_EXISTING_CONTACTS = 'mAddFromExistingContacts'
export const MODAL_TYPE_UPDATING_OLD_DATA = 'mUpdatingOldData'
export const MODAL_TYPE_PLANT_SELECTION = 'mPlantSelection'
export const MODAL_TYPE_CUSTOMER_SELECTION = 'mCustomerSelection'
export const MODAL_TYPE_SELECT_USERS_TO_USER_GROUP = 'mCustomerSelection'
export const MODAL_TYPE_CONTACTS_SELECTION = 'mContactSelection'
export const MODAL_TYPE_PRODUCT_SELECTION = 'mProductSelection'
export const MODAL_TYPE_PRODUCT_CATEGORY_SELECTION = 'mPricePlanningTimeRange'
export const MODAL_TYPE_PRICE_PLANNING_TIME_RANGE = 'mProductCategorySelection'
export const MODAL_TYPE_PROJECT_SELECTION = 'mProjectSelection'
export const MODAL_TYPE_PRODUCT_TYPE_SELECT = 'mProductTypeSelection'
export const MODAL_TYPE_CANNOT_DELETE_ERROR = 'mCannotDeleteError'
export const MODAL_TYPE_SELECT_USER = 'mSelectUser'
export const MODAL_TYPE_SELECT_USER_TO_MERGE = 'mSelectUserToMerge'
export const MODAL_TYPE_ADD_ALTERNATIVE_NAME = 'mAddAlternativeName'
export const MODAL_TYPE_SELECT_CUSTOMER_GROUP = 'mSelectCustomerGroup'
export const MODAL_TYPE_PLANT_TYPE_SELECT = 'mPlantTypeSelection'
export const MODAL_TYPE_EDIT_PRICE = 'mEditPrice'
export const MODAL_TYPE_QUOTE_SEND_CONFIRM = 'mQuoteSendConfirm'
export const MODAL_TYPE_SHOW_QUOTE_VIOLATION = 'mShowQuoteViolation'
export const MODAL_TYPE_CONFIRM_ROLLOUT = 'mConfirmRollout'
export const MODAL_TYPE_ROLLOUT_WARNING = 'mRolloutWarning'
export const MODAL_TYPE_SELECT_DATE_RANGE = 'mSelectDateRange'
export const MODAL_TYPE_TAX_EXEMPTION = 'mTaxExemption'
export const MODAL_TYPE_TRUCK_DEFAULT_CHANGED = 'mTruckDefaultChanged'
export const MODAL_TYPE_TRUCK_NO_DEFAULT = 'mTruckNoDefault'
export const MODAL_TYPE_QUOTE_WIN_CONFIRM = 'mQuoteWinConfirm'
export const MODAL_TYPE_QUOTE_LOSE_CONFIRM = 'mQuoteLoseConfirm'
export const MODAL_TYPE_QUOTE_NO_WON_SELECTED = 'mQuoteNoWonSelected'
export const MODAL_TYPE_QUOTE_WON_SELECTED = 'mQuoteWonSelected'
export const MODAL_TYPE_QUOTE_COMMENTS = 'mQuoteComments'
export const MODAL_TYPE_JWS_CUSTOMER_SELECT = 'mJWSCustomerSelect'
export const MODAL_TYPE_JWS_PLANT_SELECT = 'mJWSPlantSelect'
export const MODAL_TYPE_JWS_PRODUCT_SELECT = 'mJWSProductSelect'
export const MODAL_TYPE_PRICE_PLANNING_IMPORT_ERROR = 'mPricePlanningImportError'
export const MODAL_TYPE_JWS_LINK_ERROR = 'mJwsLinkError'
export const MODAL_TYPE_COMMAND_ALKON_QUOTE_PUSH_ERROR = 'mCommandAlkonQuotePushError'
export const MODAL_TYPE_FIELD_MANAGEMENT_CUSTOMERS_ADD_SEGMENT = 'mFieldManagementCustomersAddSegment'
export const MODAL_TYPE_FIELD_MANAGEMENT_CUSTOMERS_EDIT_SEGMENT = 'mFieldManagementCustomersEditSegment'
export const MODAL_TYPE_FIELD_MANAGEMENT_PRODUCT_TYPE_ONE = 'mFieldManagementProductTypeOne'
export const MODAL_TYPE_FIELD_MANAGEMENT_PRODUCT_TYPE_TWO = 'mFieldManagementProductTypeTwo'
export const MODAL_TYPE_FIELD_MANAGEMENT_CUSTOMERS_EDIT_TYPE = 'mFieldManagementCustomersEditType'
export const MODAL_TYPE_FIELD_MANAGEMENT_CUSTOMERS_ADD_TYPE = 'mFieldManagementCustomersAddType'
export const MODAL_TYPE_FIELD_MANAGEMENT_PRODUCT_LINE_ADD_TYPE = 'mFieldManagementProductLineAddType'
export const MODAL_TYPE_FIELD_MANAGEMENT_PRODUCT_LINE_EDIT_TYPE = 'mFieldManagementProductLineEditType'
export const MODAL_TYPE_FIELD_MANAGEMENT_PRODUCTS_ADD_TYPE_1 = 'mFieldManagementProductsAddType1'
export const MODAL_TYPE_FIELD_MANAGEMENT_PRODUCTS_ADD_TYPE_2 = 'mFieldManagementProductsAddType2'
export const MODAL_TYPE_PICKLIST_ADD_NEW_UOM = 'mPickListAddNewUOM'
export const MODAL_TYPE_PICKLIST_ADD_NEW_UNIT = 'mPickListAddNewUnit'
export const MODAL_TYPE_FIELD_MANAGEMENT_PRODUCTS_ADD_CATEGORY = 'mFieldManagementProductsAddCategory'
export const MODAL_TYPE_EMAIL_DUPLICATE_ENTRY = 'mDuplicateEmailEntry'
export const MODAL_TYPE_SAVE_DEFAULT_TEMPLATE = 'mSaveDefaultTemplate'
export const MODAL_TYPE_CONFIRM_SAVE_DEFAULT_TEMPLATE = 'mConfirmSaveDefaultTemplate'
export const MODAL_TYPE_TC_TEMPLATE = 'mModalTypeTcTemplate'
export const MODAL_TYPE_PROJECT_CANCELLED_OUTSIDE = 'mProjectCancelledOutside'
export const MODAL_TYPE_PROJECT_WON_OUTSIDE = 'mProjectWonOutside'
export const MODAL_TYPE_TEMPLATE_PREVIEW = 'mTemplatePreview'
export const MODAL_TYPE_WARNING = 'mWarning'
export const MODAL_TYPE_PRICE_PLANNING_OVERWRITE = 'mPricePlanningOverwrite'
export const MODAL_TYPE_PP_SELECT_OPTION = 'mPPSelectOption'
export const MODAL_TYPE_FIELD_MANAGEMENT_ADD_REASONS_FOR_A_LOSS = 'mFieldManagementAddReasonsForALoss'
export const MODAL_TYPE_FIELD_MANAGEMENT_EDIT_REASONS_FOR_A_LOSS = 'mFieldManagementEditReasonsForALoss'
export const MODAL_TYPE_FIELD_MANAGEMENT_DOCUMENTS_ADD_TYPE_1 = 'mFieldManagementDocumentsAddType1'
export const MODAL_TYPE_FIELD_MANAGEMENT_DOCUMENTS_ADD_TYPE_2 = 'mFieldManagementDocumentsAddType2'
export const MODAL_TYPE_FIELD_MANAGEMENT_DOCUMENTS_EDIT_TYPE_1 = 'mFieldManagementDocumentsEditType1'
export const MODAL_TYPE_FIELD_MANAGEMENT_DOCUMENTS_EDIT_TYPE_2 = 'mFieldManagementDocumentsEditType2'
export const MODAL_TYPE_ENTITY_MANAGEMENT_QUOTE_ENTITIES = 'mQuoteAddNewEntity'
export const MODAL_TYPE_NOTES = 'mTypeNotes'
export const MODAL_TYPE_APP_VERSION = 'appVersion'
export const MODAL_TYPE_FIELD_MANAGEMENT_PROJECT_ADD_PROJECT_TYPE = 'mFieldManagementProjectProjectType'
export const MODAL_TYPE_FIELD_MANAGEMENT_PROJECT_ADD_BID_SOURCE = 'mFieldManagementProjectBidSource'
export const MODAL_TYPE_FIELD_MANAGEMENT_PROJECT_EDIT_BID_SOURCE = 'mFieldManagementProjectEditBidSource'
export const MODAL_TYPE_FIELD_MANAGEMENT_PROJECT_EDIT_PROJECT_TYPE = 'mFieldManagementProjectEditProjectType'
export const MODAL_TYPE_FIELD_MANAGEMENT_ADD_REASONS_FOR_A_CANCEL = 'mModalConfigFieldManagementAddReasonsForACancel'
export const MODAL_TYPE_FIELD_MANAGEMENT_EDIT_REASONS_FOR_A_CANCEL = 'mModalConfigFieldManagementEditReasonsForACancel'

export const MODAL_TEMPLATE_PREVIEW = {
    buttons: [BTN_CLOSE],
    isOpen: false,
    type: MODAL_TYPE_TEMPLATE_PREVIEW,
    title: 'Template preview'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_DOCUMENTS_EDIT_TYPE_1 = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_DOCUMENTS_EDIT_TYPE_1,
    title: 'Edit document name'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_DOCUMENTS_EDIT_TYPE_2 = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_DOCUMENTS_EDIT_TYPE_2,
    title: 'Edit document name'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_PROJECT_EDIT_BID_SOURCE = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_PROJECT_EDIT_BID_SOURCE,
    title: 'Edit the bid source'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_PROJECT_EDIT_PROJECT_TYPE = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_PROJECT_EDIT_PROJECT_TYPE,
    title: 'Edit the project type'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_EDIT_REASONS_FOR_A_CANCEL = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_EDIT_REASONS_FOR_A_CANCEL,
    title: 'Edit the cancel reasons'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_ADD_REASONS_FOR_A_CANCEL = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_ADD_REASONS_FOR_A_CANCEL,
    title: 'Enter new reason for cancel'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_DOCUMENTS_ADD_TYPE_1 = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_DOCUMENTS_ADD_TYPE_1,
    title: 'Enter new document type 1'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_DOCUMENTS_ADD_TYPE_2 = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_DOCUMENTS_ADD_TYPE_2,
    title: 'Enter new document type 2'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_ADD_REASONS_FOR_A_LOSS = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_ADD_REASONS_FOR_A_LOSS,
    title: 'Enter new reason for a loss'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_EDIT_REASONS_FOR_A_LOSS = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_EDIT_REASONS_FOR_A_LOSS,
    title: 'Edit reason for a loss'
}

export const MODAL_CONFIG_PRICE_PLANNING_OVERWRITE = {
    buttons: [BTN_CONTINUE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_PRICE_PLANNING_OVERWRITE,
    title: 'Price planning overwrite'
}

export const MODAL_CONFIG_PICKLIST_ADD_NEW_UOM = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_PICKLIST_ADD_NEW_UOM,
    title: 'Add new UOM'
}

export const MODAL_CONFIG_PICKLIST_ADD_NEW_UNIT = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_PICKLIST_ADD_NEW_UNIT,
    title: 'Unit Settings flow'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_PRODUCTS_ADD_TYPE_1 = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_PRODUCTS_ADD_TYPE_1,
    title: 'Enter new type'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_PRODUCTS_ADD_TYPE_2 = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_PRODUCTS_ADD_TYPE_2,
    title: 'Enter new type'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_PROJECT_ADD_BID_SOURCE = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_PROJECT_ADD_BID_SOURCE,
    title: 'Enter new bid source'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_PROJECT_ADD_PROJECT_TYPE = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_PROJECT_ADD_PROJECT_TYPE,
    title: 'Enter new project type'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_PRODUCTS_ADD_CATEGORY = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_PRODUCTS_ADD_CATEGORY,
    title: 'Enter new category'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_CUSTOMERS_ADD_SEGMENT = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_CUSTOMERS_ADD_SEGMENT,
    title: 'Enter new segment'
}

export const MODAL_CONFIG_ENTITY_MANAGEMENT_QUOTE_ENTITIES = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_ENTITY_MANAGEMENT_QUOTE_ENTITIES,
    title: 'Add new entity'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_CUSTOMERS_EDIT_SEGMENT = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_CUSTOMERS_EDIT_SEGMENT,
    title: 'Edit the segment'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_PRODUCT_TYPE_TWO = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_PRODUCT_TYPE_TWO,
    title: 'Edit the type'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_PRODUCT_TYPE_ONE = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_PRODUCT_TYPE_ONE,
    title: 'Edit the type'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_CUSTOMERS_EDIT_TYPE = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_CUSTOMERS_EDIT_TYPE,
    title: 'Edit the type'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_CUSTOMERS_ADD_TYPE = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_CUSTOMERS_ADD_TYPE,
    title: 'Enter new type'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_PRODUCT_LINE_ADD_TYPE = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_PRODUCT_LINE_ADD_TYPE,
    title: 'Enter new product line'
}

export const MODAL_CONFIG_FIELD_MANAGEMENT_PRODUCT_LINE_EDIT_TYPE = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_FIELD_MANAGEMENT_PRODUCT_LINE_EDIT_TYPE,
    title: 'Edit the product line'
}

export const MODAL_CONFIG_CHANGE_STATUS = {
    buttons: [BTN_SAVE, BTN_CLOSE],
    isOpen: true,
    width: 480,
    type: MODAL_TYPE_CHANGE_STATUS,
    title: 'Confirm'
}

export const MODAL_CONFIG_DELETE_CONFIRM = {
    buttons: [BTN_DELETE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_DELETE_CONFIRM,
    title: 'Confirm',
    width: 480
}

export const MODAL_CONFIG_NOTES = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    type: MODAL_TYPE_NOTES,
    title: 'Notes',
    width: 480
}

export const MODAL_CONFIG_SELECT_COMPETITOR = {
    buttons: [BTN_SAVE, BTN_CLOSE],
    isOpen: true,
    width: 600,
    type: MODAL_TYPE_SELECT_COMPETITOR,
    title: 'Competitor information'
}

export const MODAL_CONFIG_QUOTE_PROGRESS = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_QUOTE_PROGRESS,
    title: 'Select new quote stage'
}

export const MODAL_CONFIG_COMPETITOR_SELECT = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_COMPETITOR_SELECT,
    title: 'Select competitor plants'
}

export const MODAL_CONFIG_MAP_VIEW = {
    width: 720,
    buttons: [BTN_SAVE, BTN_CLOSE],
    isOpen: true,
    type: MODAL_TYPE_MAP_VIEW,
    title: 'Job Location'
}

export const MODAL_CONFIG_DISCOUNT = {
    width: 420,
    buttons: [BTN_SAVE, BTN_CLOSE],
    isOpen: true,
    type: MODAL_TYPE_DISCOUNT,
    title: 'Discount'
}

export const MODAL_CONFIG_TRANSPORT_DATA = {
    width: 420,
    buttons: [BTN_SAVE, BTN_CLOSE],
    isOpen: true,
    type: MODAL_TYPE_TRANSPORT_DATA,
    title: 'Transport Data'
}

export const MODAL_CONFIG_QUOTE_PREVIEW_SELECTOR = {
    buttons: [BTN_SAVE, BTN_CLOSE],
    isOpen: true,
    type: MODAL_TYPE_QUOTE_PREVIEW_SELECTOR,
    title: 'PDF Preview'
}

export const MODAL_CONFIG_QUOTE_DISCOUNT_APPROVAL_INFO = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    type: MODAL_TYPE_QUOTE_DISCOUNT_APPROVAL_INFO,
    title: 'Approval required'
}

export const MODAL_CONFIG_QUOTE_CLOSE_CONFIRM = {
    buttons: [BTN_SAVE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_QUOTE_CLOSE_CONFIRM,
    title: 'Quote close'
}

export const MODAL_CONFIG_QUOTE_SEND_FOR_APPROVAL = {
    buttons: [BTN_CONTINUE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_QUOTE_SEND_FOR_APPROVAL,
    title: 'Approve Quote'
}

export const MODAL_CONFIG_QUOTE_APPROVE = {
    buttons: [BTN_CONTINUE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_QUOTE_APPROVE,
    title: 'Approve Quote'
}

export const MODAL_CONFIG_QUOTE_REJECT = {
    buttons: [BTN_CONTINUE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_QUOTE_REJECT,
    title: 'Reject Quote'
}

export const MODAL_CONFIG_QUOTE_CANCEL_CONFIRM = {
    buttons: [BTN_CONTINUE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_QUOTE_CANCEL_CONFIRM,
    title: 'Cancel Quote'
}

export const MODAL_CONFIG_CHOOSE_ADDRESS = {
    buttons: [BTN_CONTINUE, BTN_CANCEL],
    isOpen: true,
    width: 1024,
    type: MODAL_TYPE_CHOOSE_ADDRESS,
    title: 'Select Delivery Address'
}

export const MODAL_CONFIG_NO_NUMBER_SERIES = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    width: 600,
    type: MODAL_TYPE_NO_NUMBER_SERIES,
    title: 'Number series configuration error'
}

export const MODAL_CONFIG_NO_PLANTS = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    width: 600,
    type: MODAL_TYPE_NO_PLANTS,
    title: `Configuration error`,
    message: `You need to have at least one active plant.`
}

export const MODAL_CONFIG_IMPORT_VALIDATION = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    width: 600,
    type: MODAL_TYPE_IMPORT_VALIDATION,
    title: 'Data validation result'
}

export const MODAL_CONFIG_IMPORT_FINISH = {
    buttons: [BTN_CANCEL, BTN_CONTINUE],
    isOpen: true,
    width: 600,
    type: MODAL_TYPE_IMPORT_FINISH,
    title: 'Data import confirm'
}

export const MODAL_CONFIG_EDIT_SINGLE_TIER = {
    buttons: [BTN_CANCEL, BTN_SAVE],
    isOpen: true,
    width: 330,
    type: MODAL_TYPE_EDIT_SINGLE_TIER,
    title: 'Choose Tier'
}

export const MODAL_CONFIG_ENABLE_SINGLE_TIER = {
    buttons: [BTN_CANCEL, BTN_CONTINUE],
    isOpen: true,
    width: 500,
    type: MODAL_TYPE_ENABLE_SINGLE_TIER,
    title: 'Enable Single Tier'
}

export const MODAL_CONFIG_DISABLE_SINGLE_TIER = {
    buttons: [BTN_CANCEL, BTN_CONTINUE],
    isOpen: true,
    width: 500,
    type: MODAL_TYPE_DISABLE_SINGLE_TIER,
    title: 'Disable Single Tier'
}

export const MODAL_CONFIG_PROJECT_CHANGE_PROGRESS = {
    buttons: [BTN_CANCEL, BTN_SAVE],
    isOpen: true,
    width: 600,
    type: MODAL_TYPE_PROJECT_CHANGE_PROGRESS,
    title: 'Change progress'
}

export const MODAL_CONFIG_MULTIPLANT = {
    buttons: [BTN_CANCEL, BTN_CONTINUE],
    isOpen: true,
    type: MODAL_TYPE_MULTIPLANT,
    title: 'Change multiplant feature'
}

export const MODAL_CONFIG_PTS_SAVE_BEFORE_APPLY = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    type: MODAL_TYPE_PTS_SAVE_BEFORE_APPLY,
    title: 'Warning'
}

export const MODAL_CONFIG_PTS_NO_CATEGORY_PRICES = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    type: MODAL_TYPE_PTS_NO_CATEGORY_PRICES,
    title: 'Warning'
}

export const MODAL_CONFIG_ADD_FROM_EXISTING_CONTACTS = {
    buttons: [BTN_CANCEL, BTN_SAVE],
    isOpen: true,
    width: 600,
    type: MODAL_TYPE_ADD_FROM_EXISTING_CONTACTS,
    title: 'Add from existing contacts'
}

export const MODAL_CONFIG_UPDATING_OLD_DATA = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    type: MODAL_TYPE_UPDATING_OLD_DATA,
    title: 'Error updating the document',
    message:
        'You are trying to update an old version of this document. It appears that someone has edited this document moments before you. To prevent loosing any previously added data, you need to refresh the page before saving any new changes.'
}

export const MODAL_CONFIG_PLANT_SELECTION = {
    buttons: [BTN_CANCEL, BTN_SAVE],
    isOpen: true,
    width: 800,
    type: MODAL_TYPE_PLANT_SELECTION,
    title: 'Select Plant'
}

export const MODAL_CONFIG_CUSTOMER_SELECTION = {
    buttons: [BTN_CANCEL, BTN_SAVE],
    isOpen: true,
    width: 1300,
    type: MODAL_TYPE_CUSTOMER_SELECTION,
    title: 'Select Customer'
}

export const MODAL_CONFIG_SELECT_USERS_TO_USER_GROUP = {
    buttons: [BTN_CANCEL, BTN_ADD],
    isOpen: true,
    width: 1100,
    type: MODAL_TYPE_SELECT_USERS_TO_USER_GROUP,
    title: 'Select members'
}

export const MODAL_CONFIG_CONTACTS_SELECTION = {
    buttons: [BTN_CANCEL, BTN_SAVE],
    isOpen: true,
    width: 800,
    type: MODAL_TYPE_CONTACTS_SELECTION,
    title: 'Select Contact'
}

export const MODAL_CONFIG_PRODUCT_SELECTION = {
    buttons: [BTN_CANCEL, BTN_SAVE],
    isOpen: true,
    width: 800,
    type: MODAL_TYPE_PRODUCT_SELECTION,
    title: 'Select Product'
}

export const MODAL_CONFIG_PRODUCT_CATEGORY_SELECTION = {
    buttons: [BTN_CANCEL, BTN_SAVE],
    isOpen: true,
    width: 800,
    type: MODAL_TYPE_PRODUCT_CATEGORY_SELECTION,
    title: 'Select product category'
}

export const MODAL_CONFIG_PROJECT_SELECTION = {
    buttons: [BTN_CANCEL, BTN_SAVE],
    isOpen: true,
    width: 800,
    type: MODAL_TYPE_PROJECT_SELECTION,
    title: 'Select project'
}

export const MODAL_CONFIG_PRICE_PLANNING_TIME_RANGE = {
    buttons: [BTN_CANCEL, BTN_CONTINUE],
    isOpen: true,
    width: 550,
    type: MODAL_TYPE_PRICE_PLANNING_TIME_RANGE,
    title: 'Select template options'
}

export const MODAL_CONFIG_PRODUCT_TYPE_SELECT = {
    buttons: [BTN_CANCEL, BTN_CONTINUE],
    isOpen: true,
    type: MODAL_TYPE_PRODUCT_TYPE_SELECT,
    title: 'Select product line'
}

export const MODAL_CONFIG_PLANT_TYPE_SELECT = {
    buttons: [BTN_CANCEL, BTN_CONTINUE],
    isOpen: true,
    type: MODAL_TYPE_PLANT_TYPE_SELECT,
    title: 'Select product line'
}

export const MODAL_CONFIG_CANNOT_DELETE_ERROR = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    width: 500,
    type: MODAL_TYPE_CANNOT_DELETE_ERROR,
    title: 'Cannot delete selected record(s)'
}

export const MODAL_CONFIG_ADD_ALTERNATIVE_NAME = {
    buttons: [BTN_CANCEL, BTN_SAVE],
    isOpen: true,
    width: 800,
    type: MODAL_TYPE_ADD_ALTERNATIVE_NAME,
    title: 'Create new alternative name'
}

export const MODAL_CONFIG_SELECT_USER = {
    buttons: [BTN_CANCEL, BTN_SAVE],
    isOpen: true,
    width: 800,
    type: MODAL_TYPE_SELECT_USER,
    title: 'Select user'
}

export const MODAL_CONFIG_SELECT_USER_TO_MERGE = {
    buttons: [BTN_CANCEL, BTN_MERGE],
    isOpen: true,
    width: 600,
    type: MODAL_TYPE_SELECT_USER_TO_MERGE,
    title: 'Reassign user'
}

export const MODAL_CONFIG_SELECT_CUSTOMER_GROUP = {
    buttons: [BTN_CANCEL, BTN_SAVE],
    isOpen: true,
    width: 800,
    type: MODAL_TYPE_SELECT_CUSTOMER_GROUP,
    title: 'Select parent company from existing customers'
}

export const MODAL_CONFIG_EDIT_PRICE = {
    buttons: [BTN_CANCEL, BTN_SAVE],
    isOpen: true,
    width: 500,
    type: MODAL_TYPE_EDIT_PRICE,
    title: 'Edit price'
}

export const MODAL_CONFIG_TAX_EXEMPTION = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    type: MODAL_TYPE_TAX_EXEMPTION,
    title: 'Quote cannot be taxable'
}

export const MODAL_CONFIG_QUOTE_SEND_CONFIRM = {
    buttons: [BTN_CONTINUE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_QUOTE_SEND_CONFIRM,
    title: 'Send confirm'
}

export const MODAL_TC_TEMPLATE = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    type: MODAL_TYPE_TC_TEMPLATE,
    title: 'There are no T&C template yet'
}

export const MODAL_CONFIG_SHOW_QUOTE_VIOLATION = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    type: MODAL_TYPE_SHOW_QUOTE_VIOLATION,
    title: 'Quote violation details'
}

export const MODAL_CONFIG_CONFIRM_ROLLOUT = {
    buttons: [BTN_CONTINUE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_CONFIRM_ROLLOUT,
    title: 'Confirm rollout',
    message: 'Are you sure you want to rollout prices?'
}

export const MODAL_CONFIG_PRICE_PLANNING_IMPORT_ERROR = {
    buttons: [BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_PRICE_PLANNING_IMPORT_ERROR,
    title: 'Active prices overlap'
}

export const MODAL_CONFIG_TRUCK_DEFAULT_CHANGED = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    type: MODAL_TYPE_TRUCK_DEFAULT_CHANGED,
    title: 'Default truck changed',
    message: ''
}

export const MODAL_CONFIG_SELECT_DATE_RANGE = {
    buttons: [BTN_APPLY, BTN_CANCEL],
    isOpen: true,
    width: 600,
    type: MODAL_TYPE_SELECT_DATE_RANGE,
    title: 'Select custom date range'
}

export const MODAL_CONFIG_QUOTE_WIN_CONFIRM = {
    buttons: [BTN_CONTINUE, BTN_CANCEL],
    isOpen: true,
    width: 600,
    type: MODAL_TYPE_QUOTE_WIN_CONFIRM,
    title: 'Confirm quote win'
}

export const MODAL_CONFIG_APP_VERSION = {
    buttons: [BTN_OK],
    isOpen: true,
    width: 600,
    type: MODAL_TYPE_APP_VERSION,
    title: 'App version'
}

export const MODAL_CONFIG_JWS_LINK_ERROR = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    width: 600,
    type: MODAL_TYPE_JWS_LINK_ERROR,
    title: 'JWS link error'
}

export const MODAL_CONFIG_COMMAND_ALKON_QUOTE_PUSH_ERROR = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    width: 600,
    type: MODAL_TYPE_COMMAND_ALKON_QUOTE_PUSH_ERROR,
    title: 'Command Alkon Quote Push Error'
}

export const MODAL_CONFIG_QUOTE_NO_WON_SELECTED = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    width: 600,
    type: MODAL_TYPE_QUOTE_NO_WON_SELECTED,
    title: 'Quote cannot be set as lost',
    message: `You need to select at least one product as loss.`
}

export const MODAL_CONFIG_QUOTE_WON_SELECTED = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    width: 600,
    type: MODAL_TYPE_QUOTE_WON_SELECTED,
    title: 'Quote cannot be set as won',
    message: `You need to select at least one product as won.`
}

export const MODAL_CONFIG_QUOTE_COMMENTS = {
    isOpen: true,
    width: 600,
    icon: <Feather style={{ marginRight: '7px', transform: 'translateY(2px)' }} name='MessageSquare' color='#0E3F66' size={18} />,
    type: MODAL_TYPE_QUOTE_COMMENTS,
    padding: false,
    noButtons: true,
    title: 'Comments'
}
export const MODAL_CONFIG_JWS_CUSTOMER_SELECT = {
    buttons: [BTN_CONTINUE, BTN_CANCEL],
    isOpen: true,
    width: 1000,
    type: MODAL_TYPE_JWS_CUSTOMER_SELECT,
    title: 'Select customer to import from JWS database'
}
export const MODAL_CONFIG_JWS_PLANT_SELECT = {
    buttons: [BTN_CONTINUE, BTN_CANCEL],
    isOpen: true,
    width: 1000,
    type: MODAL_TYPE_JWS_PLANT_SELECT,
    title: 'Select plant to import from JWS database'
}

export const MODAL_CONFIG_JWS_PRODUCT_SELECT = {
    buttons: [BTN_CONTINUE, BTN_CANCEL],
    isOpen: true,
    width: 1000,
    type: MODAL_TYPE_JWS_PRODUCT_SELECT,
    title: 'Select product to import from JWS database'
}

export const MODAL_CONFIG_QUOTE_LOSE_CONFIRM = {
    buttons: [BTN_CONTINUE, BTN_CANCEL],
    isOpen: true,
    width: 600,
    type: MODAL_TYPE_QUOTE_LOSE_CONFIRM,
    title: 'Confirm quote lose'
}

export const MODAL_CONFIG_TRUCK_NO_DEFAULT = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    width: 600,
    type: MODAL_TYPE_TRUCK_NO_DEFAULT,
    title: 'No default truck defined',
    message: 'Please setup trucks in master data to continue.'
}

export const MODAL_CONFIG_PROJECT_CANCELLED_OUTSIDE = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    width: 600,
    type: MODAL_TYPE_PROJECT_CANCELLED_OUTSIDE,
    title: 'Project Cancelled',
    message: "Projects can't be lost because there is at least 1 quote outside of Cancel status."
}

export const MODAL_CONFIG_PROJECT_WON_OUTSIDE = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    width: 600,
    type: MODAL_TYPE_PROJECT_WON_OUTSIDE,
    title: 'Project Lost',
    message: "Projects can't be lost because there is at least 1 quote won."
}

export const MODAL_CONFIG_ROLLOUT_WARNING = {
    buttons: [BTN_CONTINUE, BTN_CANCEL],
    isOpen: true,
    type: MODAL_TYPE_ROLLOUT_WARNING,
    title: 'Rollout warning',
    message:
        'Active prices already exist in specified time range. If you continue, any all active prices overlapping with new one will be archived. Are you sure you want to continue?'
}

export const MODAL_CONFIG_EMAIL_DUPLICATE_ENTRY = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    type: MODAL_TYPE_EMAIL_DUPLICATE_ENTRY,
    title: 'Error sending emails'
}

export const MODAL_SAVE_DEFAULT_TEMPLATE = {
    buttons: [BTN_CLOSE],
    isOpen: true,
    type: MODAL_TYPE_SAVE_DEFAULT_TEMPLATE,
    title: 'Default template confirm'
}

export const MODAL_CONFIG_PP_SELECT_OPTION = {
    buttons: [BTN_CONTINUE, BTN_CLOSE],
    isOpen: true,
    width: 600,
    type: MODAL_TYPE_PP_SELECT_OPTION,
    title: 'Time period overlaps with existing active price'
}

export const CONFIRM_SAVE_MODAL_DEFAULT_TEMPLATE = {
    buttons: [BTN_CONTINUE, BTN_CLOSE],
    isOpen: true,
    type: MODAL_TYPE_CONFIRM_SAVE_DEFAULT_TEMPLATE,
    title: 'Default template confirm'
}

export const MODAL_CONFIG_WARNING = {
    buttons: [BTN_CONTINUE, BTN_CANCEL],
    className: 'WarningModal',
    showHeader: false,
    btnTextList: {
        ...BTN_TEXT_LIST,
        [BTN_CONTINUE]: 'Yes, continue'
    },
    isOpen: true,
    width: 375,
    type: MODAL_TYPE_WARNING
}

export const ALL_ERRORS = [
    { type: MODAL_TYPE_UPDATING_OLD_DATA, config: MODAL_CONFIG_UPDATING_OLD_DATA },
    { type: MODAL_TYPE_NO_NUMBER_SERIES, config: MODAL_CONFIG_NO_NUMBER_SERIES },
    { type: MODAL_TYPE_EMAIL_DUPLICATE_ENTRY, config: MODAL_CONFIG_EMAIL_DUPLICATE_ENTRY }
]

export const errorConfig = type => {
    return ALL_ERRORS.find(x => x.type === type)?.config || {}
}

export const createErrorModal = error => {
    if (error.display === E_DIALOG) {
        return { ...errorConfig(error.code), message: error.message }
    }
    console.log('NOT MODAL ERROR')
    return null
}
