export const DISPLAY_FIELDS = {
    deliveredUnitPrice: true,
    deliveredUnitPriceInclTax: true,
    price: true,
    product: true,
    description: true,
    qty: true,
    taxApplied: true,
    totalDelivered: true,
    tax: true,
    totalDeliveredInclTax: true,
    totalPickedUpPrice: true,
    totalPickedUpPriceInclTax: true,
    totalTransport: true,
    transportCostPerUnit: true
};
