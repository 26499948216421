import { useAuth } from '@hooks/useAuth';

import { REPORT_TEMPLATE_LIST } from '@templates';

export const useQuoteTemplate = () => {
    const { user } = useAuth();

    if (!user) {
        return [null, null];
    }

    return REPORT_TEMPLATE_LIST[user?.tenant?.quoteTemplate] || REPORT_TEMPLATE_LIST['tkb-quote'];
};
