import { Pagination, PaginationProps } from 'antd';
import { useEffect, useRef } from 'react';

const Pager = (props: any) => {
    const total = useRef()
    useEffect(() => { if (props.total) total.current = props.total }, [props.total])

    const handleChange: PaginationProps['onChange'] = (page, pageSize) => {
        if (props.onPageChange && page !== props.page) {
            props.onPageChange({
                page,
                limit: pageSize,
            })
        }
    }

    const handleChangeLimit: PaginationProps['onShowSizeChange'] = (page, pageSize) => {
        if (props.onPageChange) {
            props.onPageChange({
                page,
                limit: pageSize,
            })
        }
    }

    return (
        <Pagination
            style={{
                marginTop: '20px',
                background: 'white',
                padding: '8px',
            }}
            total={props.total || total.current}
            current={props.page}
            onShowSizeChange={handleChangeLimit}
            onChange={handleChange}
            pageSize={props.limit}
            pageSizeOptions={props.pageSizeOptions}
            showSizeChanger
        />
    )
}

export default Pager
