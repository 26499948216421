import React from 'react';

import WarningIcon from '@assets/icons/WarningIcon.svg';

import { Image } from '@components';

import '@scss/modals/_warning-modal.scss';

const WarningModal = (props) => {
    const {
        title = '',
        subtitle = ''
    } = props;

    return (
        <div className='WarningModalBody'>
            <Image src={WarningIcon} />
            <h2>{title}</h2>
            <h3>{subtitle}</h3>
        </div>
    );
};

export default WarningModal;
