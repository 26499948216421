import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useOnClickOutside } from 'utils/onClickOutside';

import drcheckboxStyles from '@scss/components/_drcheckbox.module.scss';
import selectStyles from '@scss/components/_select.module.scss';

const Select = props => {
    const { children, options, toggleMenu, label, multiple, value = [], style, readOnly, dropdownMenuClassName = '', showClassName = '', disabled, onChange } = props;

    const [viewMode, setViewMode] = useState(true);
    const [valueObject, setValueObject] = useState([]);
    const selectRef = React.createRef();
    const onRef = React.createRef();

    if (onRef) {
        useOnClickOutside(onRef, () => {
            setViewMode(true);
        });
    }

    useEffect(() => {
        const newValues = [];
        React.Children.map(children, child => {
            if (value.includes(child.props.value)) {
                newValues.push(child.props.opt);
            }
        });

        setValueObject(newValues);
    }, [value, options]);

    const handleChange = target => {
        const newValues = [];
        if (multiple) {
            const ifSelected = value.includes(target.value);
            if (ifSelected) {
                React.Children.map(children, child => {
                    const temp = child?.props?.opt?.value;
                    if (temp && value.includes(temp) && temp !== target.value) {
                        newValues.push(child.props.opt);
                    }
                });
                onChange([...newValues]);
                return;
            }
            onChange([...valueObject, target]);
            return;
        }
        onChange(target);
    };

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            const val = child?.props?.opt?.value;
            if (val) {
                const checked = value.includes(val);
                return React.cloneElement(child, { onClick: handleChange, checked });
            }
            return child;
        }
        return child;
    });

    return (
        <div className={`dropdown ${drcheckboxStyles['drop-checkbox']} ${readOnly || disabled ? drcheckboxStyles['drop--disabled'] : ''} `} style={{ ...style }} ref={onRef}>
            {label != 'UNSET' && <p className={drcheckboxStyles['drop-checkbox__label']}>{label}</p>}
            {React.cloneElement(toggleMenu, {
                value: valueObject,
                setShow: () =>
                    setViewMode(viewMode => {
                        return !viewMode;
                    }),
                show: viewMode,
                onDelete: handleChange,
                readOnly: readOnly
            })}
            <div className={`${selectStyles['dropdown-menu']} mt-1 ${(!viewMode && !readOnly) ? `${selectStyles['show']} ${showClassName}` : ''} ${dropdownMenuClassName}`} ref={selectRef}>
                {childrenWithProps}
            </div>
        </div>
    );
};

Select.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),
    id: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.object
};

export default Select;

