import React, { useRef, useState } from 'react'
import { Spin, Modal } from 'antd'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { SortDescriptor } from '@progress/kendo-data-query'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { GridColumnReorderEvent, GridColumnResizeEvent } from '@progress/kendo-react-grid'

import AuthService from 'service/auth.service'

import useTableSettings from '@hooks/useTableSettings'
import { usePermissions } from '@hooks/usePermissions'
import { useIsCustomerImported } from '@hooks/useIsCustomerImported'
import { useDateFormat } from '@hooks/useDateFormat'

import { Button, Pager, Dropdown, ColumnsControl, MyViews, TableFilters, TableHeader, Show, Table } from '@common'

import { useNotify } from '@components/index'
import MergeCustomers from '@components/MergeCustomers/MergeCustomers'

import { TableHeaderProps } from './TableHeader'

import { DATE_FILTERS } from '@constants/dateFilters'

import { TableColumn } from '@customTypes/table'
import { Quote } from '@customTypes/quote'
import useStages from '@hooks/useStage'
import { Project } from '@customTypes/project'

const DEFAULT_FETCH_PARAMS = {
    filterColumns: ['status'],
    filterValues: ['active;draft'],
    limit: 20,
    page: 1
}

type MasterDataWrapperProps = Pick<TableHeaderProps, 'ExtraFilters'> & {
    columns: TableColumn[]
    title: React.ReactNode | string
    tableKey: string
    name: string
    dataProps: any
    addNewLink?: string
    editLink?: string
    section?: string
    style?: any
    showDelete?: boolean
    showExport?: boolean
    initialFilter?: Partial<typeof DEFAULT_FETCH_PARAMS>

    fetch: (params: any) => any
    remove: (params: any) => any
    cleanUp?: () => any
    duplicate?: (params: any) => any
    onSelect?: (params: any) => any
    update?: (_id: any, update: any) => any
}

const MasterDataWrapper = ({
    columns,
    title,
    tableKey,
    name,
    dataProps,
    addNewLink,
    editLink,
    showDelete = true,
    showExport = true,
    style,
    initialFilter = DEFAULT_FETCH_PARAMS,
    ExtraFilters,
    fetch,
    remove,
    cleanUp,
    duplicate,
    onSelect,
    update
}: MasterDataWrapperProps) => {
    const history = useHistory()
    const [filter, setFilter] = useState(initialFilter)
    const [exportLoading, setExportLoading] = useState(false)
    const [operationLoading, setOperationLoading] = useState(false)
    const [removeErrors, setRemoveErrors] = useState<string[]>([])
    const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false)
    const [isCustomersModalOpened, setIsCustomersModalOpened] = useState(false)
    const [selected, setSelected] = useState<{ [id: string]: boolean | number[] }>({})
    const [selectedLgth, setSelectedLgth] = useState<number>(0)
    const [needRefetch, setNeedRefetch] = useState(false)
    const { view, updateView, settings, defaultView } = useTableSettings({ tableKey, columns, autoSaveView: true, limit: filter.limit })
    const { sort } = view || {}
    const [filterList, setFilterList] = useState<{ value: string; dataIndex: string }[]>([])
    const { notifyError, notifySuccess } = useNotify()
    const permissions = usePermissions()
    const queryClient = useQueryClient()
    const { data, isLoading, refetch } = useQuery([name, filter, sort, needRefetch], () => fetch({ ...serializeFilter(filter), sort: sort?.field, order: sort?.order }), {
        select: res => (res?.items?.length ? res : { items: [], details: { count: 0 } }),
        enabled: Boolean(view),
        onSettled: () => setOperationLoading(false)
    })
    const { mutate: removeEntity } = useMutation((args: { _id: string }[]) => remove(args))
    const { mutate: duplicateEntity } = useMutation((id: string) => duplicate && duplicate(id))
    const _grid = useRef<any>()
    const _export = useRef<ExcelExport | null>(null)

    const [isSelectedCustomerImported, importedCustomer] = useIsCustomerImported(data?.items, selected)

    const { dateFormat } = useDateFormat()
    const { getName } = useStages(name === 'projects' ? 'project' : 'quote')

    const exportData = (items: any[]) => {
        const exceptColumns = ['selected']
        const columns = _grid.current?.columns.filter((c: any) => !exceptColumns.includes(c.field))
        const sortableProgressColumn = columns.find((col: { field: string }) => col.field === 'sortableProgress')
        if (sortableProgressColumn) sortableProgressColumn.field = 'currentProgress'

        if (['projects', 'quotes'].includes(name)) {
            items = items.map((el: Project | Quote) => ({ ...el, currentProgress: getName(el.currentProgress) }))
        }

        if (_export.current !== null) _export.current.save(items, columns)
    }

    async function excelExport() {
        try {
            setExportLoading(true)
            const ids = Object.keys(selected).filter(key => selected[key] === true)
            const data = await fetch({ filterColumns: '_id', filterValues: ids.join(';') })
            exportData(data?.items || [])
        } catch (e) {
            notifyError('Error while exporting selected data')
        } finally {
            setExportLoading(false)
        }
    }

    async function excelExportAll() {
        try {
            setExportLoading(true)
            const data = await fetch({ ...serializeFilter(filter), sort: sort?.field, order: sort?.order, limit: 10000 })
            exportData(data?.items || [])
        } catch (e) {
            notifyError('Error while exporting all data')
        } finally {
            setExportLoading(false)
        }
    }

    function updateFilter(data: Partial<typeof DEFAULT_FETCH_PARAMS>) {
        setFilter({ ...filter, ...data })
        if (data.filterColumns || data.filterValues || data.page || data.limit) {
            handleOnSelect({})
        }
    }

    function serializeFilter(filter: typeof DEFAULT_FETCH_PARAMS & { dateFormat?: string }) {
        let filters = {
            ...filter,
            filterColumns: filter.filterColumns.join(','),
            filterValues: filter.filterValues.join(',')
        }
        if (filter.filterColumns.some(column => DATE_FILTERS.includes(column))) filters = { ...filters, dateFormat }

        return filters
    }

    function onSort(sort: SortDescriptor) {
        updateView({ sort: { field: sort?.field, order: sort?.dir }, name: 'autosave' }, { lastShown: 'autosave' })
        handleOnSelect({})
    }

    function onColumnReorder(event: GridColumnReorderEvent) {
        const newColumns = (view?.columns || []).map(c => ({ ...c }))
        newColumns.forEach(column => {
            const tableColumn = event.columns.find(c => c.field === column.dataIndex)
            if (tableColumn) (column as TableColumn).order = tableColumn.orderIndex
        })
        updateView({ columns: newColumns, name: 'autosave' }, { lastShown: 'autosave' })
    }

    function onColumnResize(event: GridColumnResizeEvent) {
        const gridColumn = event.columns.find((c: any) => c.ariaColumnIndex === event.index + 1)
        if (!gridColumn) return
        const newColumns = (view?.columns || []).map(c => ({ ...c }))
        const tableColumn = newColumns.find(c => c.dataIndex === gridColumn.field)
        if (!tableColumn) return
        tableColumn.width = event.newWidth
        if (event.end) updateView({ columns: newColumns, name: 'autosave' }, { lastShown: 'autosave' })
    }

    function addFilter() {
        setFilterList([...filterList, { value: '', dataIndex: '' }])
    }

    function addNew() {
        addNewLink?.length && history.push(addNewLink)
    }

    function edit() {
        editLink?.length && history.push(editLink)
    }

    const onCleanUp = async () => {
        if (!cleanUp) return
        setOperationLoading(true)
        await cleanUp()
        queryClient.invalidateQueries({ queryKey: [name] })
        setOperationLoading(false)
    }

    const handleOnSelect = (rows: { [id: string]: boolean }) => {
        setSelected(rows)
        const selectedKeys = Object.keys(rows).filter(key => rows[key] === true)
        setSelectedLgth(selectedKeys.length)
        if (!onSelect) {
            return
        }

        const selectedItems = data?.items?.filter((t: any) => selectedKeys.includes(t._id)) || []
        onSelect(selectedItems)
    }

    function applyFilterList(data: typeof filterList) {
        const newFilterMap = new Map()
        const filterListColumns = data.map(f => f.dataIndex)
        const filterListValues = data.map(f => f.value)
        const allColumns = DEFAULT_FETCH_PARAMS.filterColumns.concat(filterListColumns)
        const defaultParamsValues = DEFAULT_FETCH_PARAMS.filterColumns.reduce((values: string[], currentColumn) => {
            const index = filter.filterColumns.findIndex(column => column === currentColumn)
            if (index === -1) return values
            const value = filter.filterValues[index]
            return [...values, value]
        }, [])
        const allValues = defaultParamsValues.concat(filterListValues)
        allColumns.forEach((column, i) => {
            newFilterMap.set(column, allValues[i])
        })
        const newFilterColumns = Array.from(newFilterMap.keys())
        const newFilterValues = Array.from(newFilterMap.values())
        updateFilter({ filterColumns: newFilterColumns, filterValues: newFilterValues })
    }

    function clearAllFilterList() {
        const newFilterColumns: (typeof filter)['filterColumns'] = []
        const newFilterValues: (typeof filter)['filterValues'] = []
        filter.filterColumns.forEach((column, i) => {
            if (filterList.some(c => c.dataIndex === column)) return
            newFilterColumns.push(column)
            newFilterValues.push(filter.filterValues[i])
        })
        setFilterList([])
        updateFilter({ filterColumns: newFilterColumns, filterValues: newFilterValues })
    }

    function removeFilterFromList(index: number) {
        const newFilterList = filterList.map(f => ({ ...f }))
        const newFilterColumns: (typeof filter)['filterColumns'] = []
        const newFilterValues: (typeof filter)['filterValues'] = []
        filter.filterColumns.forEach((column, i) => {
            if (newFilterList[index].dataIndex === column) return
            newFilterColumns.push(column)
            newFilterValues.push(filter.filterValues[i])
        })
        newFilterList.splice(index, 1)
        setFilterList(newFilterList)
        updateFilter({ filterColumns: newFilterColumns, filterValues: newFilterValues })
    }

    const visibleColumns =
        columns.map((c: any, i: number) => ({
            ...c,
            width: view?.columns[i]?.width || c.width,
            hidden: view?.columns[i]?.hidden,
            order: view?.columns[i]?.order,
            title: typeof c.title === 'string' ? c.title : c.title(dataProps)
        })) || []

    function handleRemove() {
        const ids = Object.keys(selected)
            .filter(key => selected[key] === true)
            .map(key => ({ _id: key }))

        if (!ids?.length) {
            return notifySuccess('Selected entities are not found')
        }

        setOperationLoading(true)
        handleOnSelect({})
        removeEntity(ids, {
            onSuccess: data => {
                if (typeof data === null)
                    if (typeof data === 'string') notifySuccess(data)
                    else notifySuccess('Removed successfully')
                setShowConfirmRemoveModal(false)
                queryClient.invalidateQueries({ queryKey: [name] })
            },
            onError: (error: any) => {
                setOperationLoading(false)
                console.log('DEBUG ', error)
                const errors = error.response?.data
                setShowConfirmRemoveModal(false)
                if (Array.isArray(errors)) setRemoveErrors(errors)
                else notifyError('Server error...')
            }
        })
    }

    function handleDuplicate() {
        const id = Object.keys(selected).find(key => selected[key] === true)
        if (!id) {
            return notifySuccess('Selected entity is not found')
        }

        setOperationLoading(true)
        handleOnSelect({})
        duplicateEntity(id, {
            onSuccess: data => {
                if (typeof data === 'string') notifySuccess(data)
                else notifySuccess('Duplicated successfully')
                queryClient.invalidateQueries({ queryKey: [name] })
            },
            onError: (error: any) => {
                setOperationLoading(false)
                console.log('DEBUG ', error)
                const errors = error.response?.data
                if (Array.isArray(errors)) setRemoveErrors(errors)
                else notifyError('Server error...')
            }
        })
    }

    const itemsSelected = (data?.items || []).filter((item: any) => selected[item._id] === true)
    const activeSelected = itemsSelected.filter((item: any) => item.status === 'active')
    const inactiveSelected = itemsSelected.filter((item: any) => item.status === 'inactive')

    async function handleActivate() {
        try {
            if(!update) return
            setOperationLoading(true)
            const res = await update(
                inactiveSelected.map((item: any) => item._id),
                { status: 'active' }
            )
            queryClient.invalidateQueries({ queryKey: [name] })
            notifySuccess(res)
            setSelected({})
        } catch(e) {
            notifyError('Error updating')
            console.log(e)
        } finally {
            setOperationLoading(false)
        }
    }

    async function handleDeactivate() {
        try {
            if(!update) return
            setOperationLoading(true)
            const res = await update(
                activeSelected.map((item: any) => item._id),
                { status: 'inactive' }
            )
            queryClient.invalidateQueries({ queryKey: [name] })
            notifySuccess(res)
            setSelected({})
        } catch(e) {
            notifyError('Error updating')
            console.log(e)
        } finally {
            setOperationLoading(false)
        }
    }

    return (
        <>
            {isCustomersModalOpened && <MergeCustomers importedCustomer={importedCustomer} setIsCustomersModalOpened={setIsCustomersModalOpened} setNeedRefetch={setNeedRefetch} />}
            <Modal
                title='Cannot delete selected record(s)'
                open={removeErrors.length > 0}
                onCancel={() => setRemoveErrors([])}
                footer={
                    <Button
                        onClick={() => {
                            setRemoveErrors([])
                            setShowConfirmRemoveModal(false)
                        }}>
                        Close
                    </Button>
                }>
                {removeErrors.map(error => (typeof error === 'string' ? <div key={error}>{error}</div> : ''))}
            </Modal>
            <Modal
                title='Confirm'
                open={showConfirmRemoveModal}
                onCancel={() => setShowConfirmRemoveModal(false)}
                footer={
                    <div style={{ display: 'flex', gap: '10px', marginTop: '15px' }}>
                        <Button onClick={() => setShowConfirmRemoveModal(false)}>Cancel</Button>
                        <Button secondary onClick={handleRemove}>
                            Continue
                        </Button>
                    </div>
                }>
                Do you really want to delete selected {name}(s)?
            </Modal>
            <TableHeader title={title} filter={filter} ExtraFilters={ExtraFilters} updateFilter={updateFilter} />
            <TableControls>
                <Show condition={exportLoading}>
                    <div style={{ position: 'fixed', top: 0, left: 0, background: 'rgba(255,255,255,0.7)', width: '100%', height: '100%', zIndex: 100 }}>
                        <Spin style={{ position: 'absolute', left: '48%', top: '48%' }} />
                    </div>
                </Show>
                <TableControlsItem>
                    <Button bordered onClick={addFilter}>
                        ADD FILTERS
                    </Button>
                    <Show condition={!!addNewLink?.length && permissions(name, { action: 'create' })}>
                        <Button onClick={addNew} secondary>
                            ADD NEW
                        </Button>
                    </Show>
                    <Show condition={Boolean(cleanUp) && AuthService.isSuperAdmin}>
                        <Button onClick={onCleanUp}>CLEAN UP</Button>
                    </Show>
                    <Show condition={!!editLink?.length && permissions(name, { action: 'edit' }) && selectedLgth === 1}>
                        <Button onClick={edit}>EDIT</Button>
                    </Show>
                    <Show condition={permissions(name, { action: 'create' }) && Boolean(duplicate) && selectedLgth === 1}>
                        <Button onClick={handleDuplicate}>DUPLICATE</Button>
                    </Show>
                    <Show condition={permissions(name, { action: 'delete' }) && showDelete && selectedLgth > 0}>
                        <Button onClick={() => setShowConfirmRemoveModal(true)}>DELETE</Button>
                    </Show>
                    <Show condition={permissions(name, { action: 'edit' }) && Boolean(update) && activeSelected.length > 0}>
                        <Button onClick={handleDeactivate}>DEACTIVATE</Button>
                    </Show>
                    <Show condition={permissions(name, { action: 'edit' }) && Boolean(update) && inactiveSelected.length > 0}>
                        <Button onClick={handleActivate}>ACTIVATE</Button>
                    </Show>
                    <Show condition={showExport && selectedLgth > 0}>
                        <Dropdown
                            renderTrigger={<Button>EXPORT</Button>}
                            options={[
                                { label: 'Export selected', action: excelExport },
                                { label: 'Export all', action: excelExportAll }
                            ]}
                        />
                    </Show>
                    <Show condition={permissions(name, { action: 'merge' }) && isSelectedCustomerImported}>
                        <Button onClick={() => setIsCustomersModalOpened(true)}>MERGE CLIENTS RECORDS</Button>
                    </Show>
                </TableControlsItem>
                <TableControlsItem>
                    <MyViews views={settings?.views} updateView={updateView} view={view} defaultView={defaultView} />
                    <ColumnsControl dataProps={dataProps} view={view} columns={columns || []} updateView={updateView} />
                </TableControlsItem>
            </TableControls>
            <TableFilters
                filterList={filterList}
                setFilterList={setFilterList}
                columns={columns}
                dataProps={dataProps}
                clearAllFilterList={clearAllFilterList}
                applyFilterList={applyFilterList}
                removeFilterFromList={removeFilterFromList}
            />
            <ExcelExport ref={_export}>
                <Table
                    columns={visibleColumns}
                    data={data?.items}
                    dataProps={dataProps}
                    onSort={onSort}
                    sortable={true}
                    sort={sort}
                    style={style}
                    isLoading={isLoading || operationLoading}
                    onColumnReorder={onColumnReorder}
                    _grid={_grid}
                    selected={selected}
                    setSelected={handleOnSelect}
                    onColumnResize={onColumnResize}
                />
            </ExcelExport>
            <Pager total={data?.details?.count} page={filter.page} limit={filter.limit} onPageChange={updateFilter} />
        </>
    )
}

export default MasterDataWrapper

const TableControls = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
`

const TableControlsItem = styled.div`
    display: grid;
    grid-template-columns: repeat(6, auto);
    gap: 10px;
`
