import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Feather, Breadcrumbs } from '@components';
import { Button } from '@components/bs';

import '../../../scss/components/_side-panel.scss';

const SidePanel = ({ title = '', isOpen, onClose, onSave, width, maxWidth = 9999, hasHeader = false, children, style = {}, breadcrumbsOptions = {}, hasBreadcrumbs = false, setBreadcrumbOptions }) => {
    const [changedDomElements, setChangedDomElements] = useState([]);

    useEffect(() => {
        if (isOpen) {
            const tmp = document.getElementsByClassName('overflow-overlay');
            if (tmp && HTMLCollection.prototype.isPrototypeOf(tmp)) {
                let changed = [];
                Array.from(tmp).forEach(element => {
                    if (!element.classList.contains('sidepanel--content') && !element.classList.contains('overflow-hidden')) {
                        changed.push(element);
                        element.classList.add('overflow-hidden');
                    }
                });
                setChangedDomElements(changed);
            }
        } else {
            changedDomElements.map(element => {
                element.classList.remove('overflow-hidden');
            });
        }

        return () => {
            changedDomElements.map(element => {
                element.classList.remove('overflow-hidden');
            });
        };
    }, [isOpen, children]);

    if (!isOpen) return;

    return (
        <div className={`sidepanel ${isOpen ? 'visible' : 'hidden'}`} style={style}>
            <div className='sidepanel--container' style={{ width: `${width}%`, maxWidth: `${maxWidth}px` }}>
                {/* <div className='sidepanel--header_wrapper'> */}
                <button className='sidepanel--close' type='button' onClick={onClose}>
                    <Feather name='X' />
                </button>
                {hasHeader && (
                    <div className='sidepanel--header'>
                        <h5>{title}</h5>
                    </div>
                )}
                {hasBreadcrumbs && (
                    <div className='sidepanel--header'>
                        <Breadcrumbs steps={breadcrumbsOptions?.steps} setActiveStep={setBreadcrumbOptions} current={breadcrumbsOptions?.current} separator={breadcrumbsOptions?.separator} />
                    </div>
                )}
                {/* </div> */}
                <div className='sidepanel--content overflow-overlay'>{children}</div>
                {/* <div className='sidepanel--footer'>
                    <Button variant='outline-light' onClick={() => onClose()}>
                        Cancel
                    </Button>
                    <Button onClick={() => onSave()}>Save</Button>
                </div> */}
            </div>
        </div>
    );
};

SidePanel.propTypes = {
    title: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool,
    width: PropTypes.number,
    hasHeader: PropTypes.bool,
    hasBreadcrumbs: PropTypes.bool
};

SidePanel.defaultProps = {
    isOpen: false,
    width: 50,
    hasHeader: true
};

export default SidePanel;
