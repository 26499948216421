import { EMPTY_GEO_ZONE } from './const';

export const getEmptyGeoZone = (plant) => {
    return {
        ...EMPTY_GEO_ZONE,
        center: plant?.address ? {
            ...plant?.address,
            location: {
                type: 'Point',
                coordinates: [plant.address.lng, plant.address.lat]
            }
        } : EMPTY_GEO_ZONE.center,
    };
};
