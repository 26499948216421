export const EMPTY_GEO_ZONE = {
    name: '',
    description: '',
    center: null,
    polygon: null
};

export const INITIAL_FETCH_SETTINGS = {
    limit: 20,
    filterColumns: '',
    loading: 0,
    searchQuery: '',
    filterValues: '',
    statusFilters: ['active'],
    page: 1,
    sort: {
        sort: '',
        order: 'asc'
    },
};
