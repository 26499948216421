import service from '@service/service';

import * as types from './types';

const fetchListUserGroup = (params = {}) => async dispatch => {
    dispatch({ type: types.FETCH_LIST_USER_GROUPS });
    try {
        const userGroups = await service.get('/user-groups', { params });
        dispatch({ type: types.FETCH_LIST_USER_GROUPS_SUCCESS, payload: userGroups.data });
    } catch (error) {
        dispatch({ type: types.FETCH_LIST_USER_GROUPS_FAIL, payload: error?.response?.data });
    }
};

const addUserGroup = (params = {}) => async dispatch => {
    dispatch({ type: types.ADD_USER_GROUPS });
    try {
        const group = await service.post('/user-groups', params);
        dispatch({ type: types.ADD_USER_GROUPS_SUCCESS, payload: { ...group.data, ...params } });
    } catch (error) {
        dispatch({ type: types.ADD_USER_GROUPS_FAIL });
    }
};

const updateUserGroup = (params = {}) => async dispatch => {
    dispatch({ type: types.EDIT_USER_GROUPS });
    try {
        const group = await service.put(`/user-groups/${params._id}`, params);
        dispatch({ type: types.EDIT_USER_GROUPS_SUCCESS, payload: params });
    } catch (error) {
        dispatch({ type: types.EDIT_USER_GROUPS_FAIL });
    }
};

const deleteUserGroup = (params = {}) => async dispatch => {
    dispatch({ type: types.DELETE_USER_GROUPS });
    try {
        await service.delete(`/user-groups/${params}`);
        dispatch({ type: types.DELETE_USER_GROUPS_SUCCESS, payload: params });
    } catch (error) {
        dispatch({ type: types.DELETE_USER_GROUPS_FAIL });
    }
};

const clearError = () => dispatch => {
    dispatch({ type: types.CLEAR_ERROR });
};

export { fetchListUserGroup, addUserGroup, clearError, deleteUserGroup, updateUserGroup };
