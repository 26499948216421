import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { useQuery } from '@tanstack/react-query'

import styled from 'styled-components'

import { ClipboardAnalysis } from '@assets'

import { useDefaultMap } from '@hooks/useDefaultMap'
import { useUOM } from '@hooks/useUOM'

import { Currency, Image, Label, LabeledTextField, Spinner, useNotify } from '@components'
import { Dropdown } from '@components/bs'
import { Map } from '@components/Map'
import Popup from '@components/UI/Dropdown/popup'

import { Button, Checkbox, Icon, Show } from '@common'

import Summary from './Summary'

import { geocoding } from '@service/googleMapApi'
import { fetchTruck } from '@service/trucksApi'

import { checkValidFloat } from 'utils/regexUtils'
import { milesToKm } from 'utils/unit'
import { calculateTripDistance, calculateTripDuration } from '@panels/CalculateTransport/utils'

import { CURRENCY, CURRENCY_DEFAULT } from 'consts/settings'
import { DEFAULT_PLANT, SITE } from 'consts/icons'
import { STANDARD } from '@constants/productLines'

import { Truck } from '@customTypes/truck'
import { Entry } from '@customTypes/entry'
import { Quote } from '@customTypes/quote'
import { Project } from '@customTypes/project'
import { Transport } from '@customTypes/transport'

const MAP_OPTIONS = {
    typeControl: false,
    autocomplete: false
}

export function calculateTransportPrice(transport: Transport, truck: Truck, tripDuration: number) {
    if (!truck || !transport) return null
    const pricePerHour = transport?.pricePerHour && transport.pricePerHour >= 0 ? transport.pricePerHour : truck.pricePerHour
    const tonage = transport.tonage
    const loadingTime = transport.loadingTime
    const unloadingTime = transport.unloadingTime
    const roundTrip = transport?.roundTrip && transport.roundTrip >= 0 ? transport.roundTrip : tripDuration * 2

    const price = pricePerHour / 60 / tonage
    const time = loadingTime + unloadingTime + roundTrip
    const total = price * time

    return total.toFixed(2)
}

const CalculateTransport = (props: any) => {
    const {
        id,
        mapRoutes,
        selectedMapRoutes,
        waypoints = [],
        trucks,
        plant,
        originalTransport,
        onSave = () => {},
        onCancel = () => {},
        handleSelectedRouteChanged = () => {},
        onWaypointsChanged = () => {},
        address,
        hideFooter = false,
        store,
        isProductCalculator = false,
        readOnly = false
    } = props
    const [routeHighlight, setRouteHighlight] = useState<any>()
    const [transport, setTransport] = useState<any>(originalTransport)
    const [loading, setLoading] = useState(false)

    const entity = store?.entity

    const filter = (array: any) => array.filter((entry: Entry) => entry.plantInfo?.plant === plant._id && entry?.product?.productType?.key !== STANDARD)

    let entries = store?.id === 'quote' ? filter((entity as Quote)?.quoteEntries) : store?.id === 'project' ? filter((entity as Project)?.projectEntries) : []
    if (isProductCalculator) entries = entries.filter((entry: Entry) => id === entry._id)

    const { settings: mainSettings } = useSelector((store: any) => store.settings)
    const currency = mainSettings.find((x: any) => x.key === CURRENCY)?.value || CURRENCY_DEFAULT

    const { getRouteUnitByMeasure } = useUOM()
    const { notify, notifyError } = useNotify()

    const defaultMapCenter = useDefaultMap()
    const [mapCenter, setMapCenter] = useState(defaultMapCenter)

    const onTransportUpdate = (value: any) => {
        if (value.roundUp) value.roundUp = value.roundUp.target.checked
        setTransport({ ...transport, ...value })
    }

    const mapRef = useRef<any>()
    const mapServicesRef = useRef<any>()

    const { data: truck } = useQuery(['truck', transport?.truck], () => fetchTruck(transport?.truck), { enabled: Boolean(transport?.truck) })
    const defaultUom = truck?.material?.defaultUOM?.value || ''

    const tripDuration = useMemo(() => routeHighlight?.duration || 0, [routeHighlight])

    let jobDuration
    let defaultJobDuration = 1
    if (entity?.potentialEndDate && entity?.potentialStartDate) {
        const potentialEndDate = new Date(entity.potentialEndDate).getTime()
        const potentialStartDate = new Date(entity.potentialStartDate).getTime()
        const jobDurationInMilliseconds = potentialEndDate - potentialStartDate
        jobDuration = Math.floor(jobDurationInMilliseconds / (1000 * 60 * 60 * 24))
        defaultJobDuration = Math.floor(jobDurationInMilliseconds / (1000 * 60 * 60 * 24))
    } else if (entity?.project?.potentialEndDate && entity?.project?.potentialStartDate) {
        const potentialEndDate = new Date(entity.project.potentialEndDate).getTime()
        const potentialStartDate = new Date(entity.project.potentialStartDate).getTime()
        const jobDurationInMilliseconds = potentialEndDate - potentialStartDate
        jobDuration = Math.floor(jobDurationInMilliseconds / (1000 * 60 * 60 * 24))
        defaultJobDuration = Math.floor(jobDurationInMilliseconds / (1000 * 60 * 60 * 24))
    }

    if (transport?.jobDuration) jobDuration = transport.jobDuration
    if (!jobDuration) jobDuration = 1

    const filtered = entries.filter((entry: Entry) => entry?.uom === truck?.material?.defaultUOM?._id && entry.qty && entry?.qty > 0) || []
    let estimatedQty = filtered.reduce((acc: number, el: { qty: number }) => acc + el.qty, 0)
    if (!isProductCalculator) {
        filtered.forEach((entry: Entry) => {
            if (entry?.transport?.costPerTon && entry.qty && entry.qty >= 0) {
                estimatedQty -= entry.qty
            }
        })
    }
    if (!filtered.length) estimatedQty = ''
    if (!estimatedQty) estimatedQty = 1

    let roundTrip = transport?.roundTrip || tripDuration * 2
    if (truck?.roundTripAdjustment >= 0) roundTrip = (transport?.roundTrip || tripDuration * 2) * (1 + truck.roundTripAdjustment / 100)

    let displayedRoundTrip = truck?.roundTripAdjustment >= 0 ? roundTrip : transport.roundTrip >= 0 ? transport.roundTrip : tripDuration * 2
    if (transport?.isManualRoundTrip) displayedRoundTrip = transport.roundTrip
    displayedRoundTrip = Math.round(displayedRoundTrip * 100) / 100

    const qtyPerDay = (transport?.estimatedQty || estimatedQty || 0) / (transport?.jobDuration || jobDuration)

    const totalTripTime = Math.round(((transport?.roundTrip ?? roundTrip) + transport?.loadingTime + transport?.unloadingTime) * 100) / 100

    let nbOfTrucks =
        Math.round(
            ((transport?.estimatedQty || estimatedQty || 0) /
                (transport?.jobDuration || jobDuration) /
                transport.tonage /
                (((transport?.workingHours || plant?.hours || 8) * 60) / totalTripTime)) *
                100
        ) / 100
    if (transport?.roundUp) nbOfTrucks = Math.ceil(nbOfTrucks)

    const summary = {
        totalTripTime: Math.ceil(totalTripTime),
        tripsPerTruck: Math.ceil(((transport?.workingHours || plant?.hours || 8) * 60) / totalTripTime),
        trucksPerDay: Math.ceil(nbOfTrucks / (transport?.jobDuration || jobDuration)),
        totalNbOfTrips: Math.ceil((transport?.estimatedQty || estimatedQty || 0) / transport?.tonage),
        qtyPerDay: Math.ceil(qtyPerDay),
        nbOfTrips: Math.ceil(qtyPerDay / transport?.tonage)
    }

    let transportPrice = 0
    if (transport?.roundUp) {
        transportPrice =
            ((nbOfTrucks || 1) * jobDuration * (transport?.workingHours ?? plant?.hours ?? 8) * (transport?.pricePerHour ?? truck?.pricePerHour ?? 0)) /
            (transport?.estimatedQty || estimatedQty || 1)
    } else {
        transportPrice = (totalTripTime * (transport?.pricePerHour ?? truck?.pricePerHour ?? 0)) / (transport.tonage * 60)
    }

    transportPrice = Math.round(transportPrice * 100) / 100

    const setMapRoute = async (from: any, to: any) => {
        const { results: resultsFrom } = await geocoding({ latlng: `${from.lat},${from.lng}` })
        const addressA = resultsFrom[1]?.formatted_address || resultsFrom[0]?.formatted_address
        const { results: resultsTo } = await geocoding({ latlng: `${to.lat},${to.lng}` })
        const addressB = resultsTo[1]?.formatted_address || resultsTo[0]?.formatted_address
        mapServicesRef.current.buildRoute(addressA, addressB, plant._id)
        mapServicesRef.current.addMarkerToMap([
            { id: 1, lng: from.lng, lat: from.lat, draggable: false, icon: SITE },
            { id: 2, lng: to.lng, lat: to.lat, draggable: false, icon: DEFAULT_PLANT }
        ])
    }

    const setupMapRoute = async (from: any, to: any) => {
        if (!mapRoutes || !selectedMapRoutes) return notifyError('Map routes error!')
        setRouteHighlight({
            duration: calculateTripDuration(mapRoutes[selectedMapRoutes[plant._id]]),
            distance: calculateTripDistance(mapRoutes[selectedMapRoutes[plant._id]])
        })
        setMapRoute(from, to)
        setLoading(false)
    }

    const onMapLoadedHandler = () => {
        const from = address
        const to = plant?.address
        if (!from || !to || !mapRoutes) {
            return
        }
        setupMapRoute(from, to)
    }

    const tonage = () => {
        if (truck) {
            return `${truck?.minTonage || ''} - ${truck?.maxTonage || ''}`
        }
        return '0-0'
    }

    const unit = getRouteUnitByMeasure()?.label || ''

    const handleChangeTruck = (id: string) => {
        const truck = trucks.find((truck: Truck) => truck._id === id)
        onTransportUpdate({
            truck: id,
            loadingTime: truck?.loadingTime,
            unloadingTime: truck?.unloadingTime,
            tonage: truck?.minTonage
        })
    }
    const [manualCostPerTon, setManualCostPerTon] = useState(transport?.costPerTon || undefined)

    const handleChangeCostPerTon = (e: any) => {
        if (!checkValidFloat(e.target.value)) {
            return
        }
        setManualCostPerTon(e.target.value)
    }

    const onCostPerTonBlur = (e: { target: { value: number } }) => {
        setManualCostPerTon(String(Math.round(e.target.value * 100) / 100))
    }

    const handleValueChanged = (e: { target: { value: string } }, key: string) => {
        if (key === 'workingHours') {
            if (!checkValidFloat(e.target.value) && e.target.value !== '') return
            onTransportUpdate({
                [key]: !e.target.value ? 0 : e.target.value
            })
            return
        } else if (!checkValidFloat(e.target.value) && e.target.value !== '') return
        onTransportUpdate({
            [key]: key === 'jobDuration' && (e.target.value === '0' || !e.target.value) ? 1 : parseFloat(e.target.value)
        })
    }

    const handleBlur = (e: { target: { value: string } }) => {
        onTransportUpdate({
            workingHours: parseFloat(e.target.value)
        })
    }

    const handleChangeRoundTripTime = (e: { target: { value: string } }) => {
        const calculatedTripTime = parseFloat(e.target.value)
        onTransportUpdate({
            calculatedTripTime: calculatedTripTime / 2,
            roundTrip: calculatedTripTime,
            isManualRoundTrip: true,
            roundTripAdjustment: Math.round(((calculatedTripTime - tripDuration * 2) * 100) / (tripDuration * 2))
        })
    }

    const handleSave = () => {
        if (!transport?.truck) {
            notify({
                title: 'Warning',
                description: 'Please select default truck to calculate transport costs.',
                duration: 5000,
                type: 'warning'
            })
            return
        }
        if (Number(manualCostPerTon) >= 0) {
            onSave({ ...transport, costPerTon: manualCostPerTon, isManualCostPerTon: true })
        } else {
            onSave({ ...transport, costPerTon: displayedCostPerTon })
        }
    }

    const defaultValueStyle: CSSProperties = { position: 'absolute', right: '67px', top: '26px', lineHeight: '38px', color: '#979797', fontWeight: '500', fontSize: '14px' }

    const onMapMouseDown = (e: any) => {
        setMapCenter({ lat: e.lat, lng: e.lng })
    }

    useEffect(() => {
        if (!mapRef.current) {
            return
        }
        onMapLoadedHandler()
    }, [mapRef.current, selectedMapRoutes])

    let displayedCostPerTon: number | string = transportPrice
    if (typeof manualCostPerTon === 'string' && Number(manualCostPerTon) >= 0) {
        displayedCostPerTon = manualCostPerTon
    } else if (transport?.costPerTon >= 0 && transport?.isManualCostPerTon) {
        displayedCostPerTon = transport.costPerTon
    }

    if (!selectedMapRoutes) return <h1 style={{ marginTop: '20px' }}>Loading...</h1>

    if (!plant) return <h2 style={{ marginTop: '20px' }}>No plant</h2>

    return (
        <div className='QuoteDetails Transport_Panel'>
            <div className='map-container'>
                <div style={{ position: 'relative', height: '560px' }}>
                    <Map
                        ref={mapRef}
                        mapServicesRef={mapServicesRef}
                        mapCenter={mapCenter}
                        onMouseDown={onMapMouseDown}
                        zoom={mapRef.current?.state?.map?.zoom || 11}
                        mapOptions={MAP_OPTIONS}
                        disableGeocoder
                        handleSelectedRouteChanged={handleSelectedRouteChanged}
                        mapRoutes={mapRoutes}
                        selectedRouteIndex={selectedMapRoutes[plant._id]}
                        waypoints={waypoints}
                        onWaypointsChanged={onWaypointsChanged}
                        calculateTransport
                    />
                    <Popup
                        trigger={
                            <div
                                style={{
                                    position: 'absolute',
                                    background: '#FF8939',
                                    height: '30px',
                                    width: '30px',
                                    borderRadius: '50%',
                                    bottom: 8,
                                    left: 8,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <Icon style={{ height: 20, marginBottom: 6 }} name='warning' />
                            </div>
                        }
                        content={
                            <div style={{ width: '450px' }}>
                                <h3 style={{ color: '#FF8939' }}>Warning!</h3>
                                <p style={{ margin: '8px 0 0 0' }}>
                                    Our transport calculation relies on Google Maps API. Although Google Maps data and coordinates are generally quite accurate, PriceBee cannot be
                                    held responsible if the trip time is incorrect due to a map error. We advice you to exercise independent judgment when using this data services.
                                </p>
                            </div>
                        }
                        placement='right'
                        style={{ color: '#404040B2', background: 'white' }}
                        disableTriangle={true}
                    />
                </div>
                <div className='map-container__overview'>
                    <div>
                        <Label label='DELIVERY DISTANCE' />
                        <div>
                            <span className='map-container__overview--value'>{unit === 'km' ? milesToKm(routeHighlight?.distance || 0) : routeHighlight?.distance || 0}</span>
                            <span className='map-container__overview--unit'>{unit}</span>
                        </div>
                    </div>
                    <div className='vert-separator' />
                    <div>
                        <Label label='TRIP TIME' />
                        <div>
                            <span className='map-container__overview--value'>{routeHighlight?.duration || 0}</span>
                            <span className='map-container__overview--unit'>min</span>
                        </div>
                    </div>
                    <div className='vert-separator' />
                    <div>
                        <Label label='ROUND TRIP TIME' />
                        <div>
                            <span className='map-container__overview--value'>{routeHighlight?.duration * 2 || 0}</span>
                            <span className='map-container__overview--unit'>min</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='truck-selector row mt-30'>
                <div className='col-md-5 TransportPanelLarge field'>
                    <Dropdown
                        disabled={readOnly}
                        label='Truck type'
                        placeholder='Truck type'
                        selected={{ label: truck?.name, value: truck?._id }}
                        items={trucks.map((truck: Truck) => ({ _id: truck._id, label: truck.name, value: truck._id }))}
                        onChange={handleChangeTruck}
                    />
                </div>
                <div className='col-md-5 TransportPanel field'>
                    <LabeledTextField
                        disabled={readOnly}
                        label='Price per hour'
                        placeholder='Price per hour'
                        value={transport?.pricePerHour >= 0 ? transport?.pricePerHour : truck?.pricePerHour}
                        onChange={(e: { target: { value: string } }) => handleValueChanged(e, 'pricePerHour')}
                    />
                    <span style={defaultValueStyle}>{truck?.pricePerHour}</span>
                    <span className={`field--unit ${store.readOnly ? 'unit--disabled' : ''}`}>{currency}</span>
                </div>

                <div className='col-md-5 TransportPanel field'>
                    <LabeledTextField
                        disabled={readOnly}
                        label='LOAD SIZE'
                        placeholder='Add Load Size'
                        value={transport?.tonage}
                        onChange={(e: { target: { value: string } }) => handleValueChanged(e, 'tonage')}
                    />
                    <span className={`field--unit ${store.readOnly ? 'unit--disabled' : ''}`}>{defaultUom}</span>
                    <span className='ton-range'>{tonage()}</span>
                </div>
            </div>

            <div className='truck-selector row mt-30'>
                <div className='col-md-5 TransportPanel field'>
                    <LabeledTextField
                        disabled={readOnly}
                        label='Loading time'
                        placeholder='Add loading time'
                        value={transport?.loadingTime}
                        onChange={(e: { target: { value: string } }) => handleValueChanged(e, 'loadingTime')}
                    />
                    <span style={defaultValueStyle}>{truck?.loadingTime}</span>
                    <span className={`field--unit ${store.readOnly ? 'unit--disabled' : ''}`}>min</span>
                </div>
                <div className='col-md-5 TransportPanel field'>
                    <LabeledTextField
                        disabled={readOnly}
                        label='Unloading time'
                        placeholder='Add unloading time'
                        value={transport?.unloadingTime}
                        onChange={(e: { target: { value: string } }) => handleValueChanged(e, 'unloadingTime')}
                    />
                    <span style={defaultValueStyle}>{truck?.unloadingTime}</span>
                    <span className={`field--unit ${store.readOnly ? 'unit--disabled' : ''}`}>min</span>
                </div>
                <div className='col-md-5 TransportPanel field'>
                    <LabeledTextField disabled={readOnly} placeholder='Round trip' label='Round trip' value={Math.ceil(displayedRoundTrip)} onChange={handleChangeRoundTripTime} />
                    <span style={defaultValueStyle}>{tripDuration * 2}</span>
                    <span className={`field--unit ${store.readOnly ? 'unit--disabled' : ''}`}>min</span>
                </div>
                <div className='col-md-5 TransportPanel field'>
                    <LabeledTextField
                        placeholder='Adjustement'
                        label='Adjustement'
                        value={transport?.isManualRoundTrip || transport.roundTripAdjustment >= 0 ? transport.roundTripAdjustment : truck?.roundTripAdjustment || 0}
                        disabled
                    />
                    <span className={`field--unit ${store.readOnly ? 'unit--disabled' : ''}`}>%</span>
                </div>
            </div>

            <div className='truck-selector row mt-30'>
                <div className='col-md-5 TransportPanel field'>
                    <LabeledTextField
                        disabled={readOnly}
                        placeholder='Job duration'
                        label='job duration'
                        value={jobDuration}
                        onChange={(e: { target: { value: string } }) => handleValueChanged(e, 'jobDuration')}
                    />
                    <Show condition={(entity?.potentialStartDate && entity?.potentialEndDate) || (entity?.project?.potentialEndDate && entity?.project?.potentialStartDate)}>
                        <span style={defaultValueStyle}>{defaultJobDuration}</span>
                    </Show>
                    <span className={`field--unit ${store.readOnly ? 'unit--disabled' : ''}`}>days</span>
                </div>
                <div className='col-md-5 TransportPanel field'>
                    <LabeledTextField
                        disabled={readOnly}
                        placeholder='Working hours'
                        label='working hours'
                        value={parseFloat(transport?.workingHours) >= 0 ? transport.workingHours : plant?.hours >= 0 ? plant.hours : 8}
                        onChange={(e: { target: { value: string } }) => handleValueChanged(e, 'workingHours')}
                        onTextBlur={handleBlur}
                    />
                    <span style={defaultValueStyle}>{plant?.hours || 8}</span>
                    <span className={`field--unit ${store.readOnly ? 'unit--disabled' : ''}`}>hours</span>
                </div>
                <div className='col-md-5 TransportPanel field'>
                    <Item>
                        <Title>Number of trucks</Title>
                        <Value>{nbOfTrucks >= 0 ? nbOfTrucks : ''}</Value>
                    </Item>
                </div>
                <div className='col-md-5 TransportPanel TransportPanelCheckbox field'>
                    <Checkbox
                        disabled={readOnly}
                        onChange={(roundUp: boolean) =>
                            onTransportUpdate({
                                roundUp
                            })
                        }
                        checked={transport?.roundUp}
                    />
                    <span className='round-up'>Round up nr of trucks</span>
                </div>
            </div>

            <div className='truck-selector row mt-30'>
                <div className='col-md-5 TransportPanelSmall field'>
                    <LabeledTextField
                        disabled={readOnly}
                        placeholder=''
                        label='estimated qty'
                        value={transport?.estimatedQty || estimatedQty}
                        onChange={(e: { target: { value: string } }) => handleValueChanged(e, 'estimatedQty')}
                        tooltipContent='Sum of all products per plant and truck UOM, except products with validated transport'
                    />
                    <span style={{ ...defaultValueStyle, top: '30px' }}>{estimatedQty}</span>
                    <span className={`field--unit ${store.readOnly ? 'unit--disabled' : ''}`} style={{ top: '30px' }}>
                        {defaultUom}
                    </span>
                </div>
            </div>

            <div className='footer bg-blue-50'>
                <div className='footer--label'>
                    <Image src={ClipboardAnalysis} />
                    <h2 className='font-medium text-blue-600'>
                        <span className='ml-2'>Transport Calculation</span>
                    </h2>
                </div>
                <div className='footer--middle'></div>
                <div className='footer--sum'>
                    <LabeledTextField
                        disabled={readOnly}
                        label='Transport cost per UOM'
                        placeholder='Add transport cost per UOM'
                        value={displayedCostPerTon}
                        onChange={handleChangeCostPerTon}
                        onTextBlur={onCostPerTonBlur}
                    />
                    <span className='calculated'>
                        <Currency value={transportPrice} />
                    </span>
                </div>
            </div>

            <Summary summary={summary} uom={defaultUom} />

            <div className='separator' />
            <div className='row mt-30'>
                <div className='col-md-12'>
                    <Show condition={!hideFooter}>
                        <div className='flex-start'>
                            <Button secondary disabled={store.readOnly} onClick={handleSave} data-cy='saveTransportCalculations'>
                                Save
                            </Button>
                            <div className='ml-20'>
                                <Button onClick={onCancel} data-cy='closeTransportCalculations'>
                                    Close
                                </Button>
                            </div>
                        </div>
                    </Show>
                </div>
            </div>
            {loading && <Spinner />}
        </div>
    )
}

export default CalculateTransport

const Item = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 20px;
`

const Title = styled.span`
    color: #6e8ca3;
    font-family: Rubik;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.213px;
    text-transform: uppercase;
`

const Value = styled.span`
    color: var(--Text-Body, #404040);
    font-family: Rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 120% */
`
