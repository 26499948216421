import styled from 'styled-components'
import { Icon, Dropdown, Button, Show } from '@common'
import Search from '@components/UI/Search'
import { TableColumn } from '@customTypes/table'
import { FormEvent } from 'react'

type TableFiltersProps = {
    filterList: { value: string; dataIndex: string }[]
    setFilterList: (data: { value: string; dataIndex: string }[]) => void
    columns: TableColumn[]
    dataProps?: any
    clearAllFilterList: () => void
    applyFilterList: (data: { value: string; dataIndex: string }[]) => void
    removeFilterFromList: (index: number) => void
}

const TableFilters = ({ filterList, dataProps, columns, setFilterList, applyFilterList, clearAllFilterList, removeFilterFromList }: TableFiltersProps) => {
    const exeptColumns = ['status', 'query']

    function handleChangeFilterColumn(index: number, dataIndex: string) {
        const newFilterList = filterList.map(f => ({ ...f }))
        newFilterList[index] = { ...newFilterList[index], dataIndex }
        setFilterList(newFilterList)
    }

    function handleChangeFilterValue(index: number, value: string) {
        const newFilterList = filterList.map(f => ({ ...f }))
        newFilterList[index] = { ...newFilterList[index], value }
        setFilterList(newFilterList)
    }

    function formOnSubmit(e: FormEvent) {
        e.preventDefault()
        applyFilterList(filterList)
    }

    function getTitle(column: TableColumn) {
        const originalColumn = columns.find(c => c.dataIndex === column.dataIndex)
        if (!originalColumn) return
        if (typeof originalColumn.title === 'string') return originalColumn.title
        if (typeof originalColumn.title === 'function') {
            const title = originalColumn.title({ dataProps })
            if (!title) return originalColumn.defaultTitle || '-'
            return title
        }
    }

    return (
        <form onSubmit={formOnSubmit}>
            <Filters>
                {filterList.map((filter, i: number) => (
                    <FilterItem key={filter.dataIndex + i}>
                        <FilterItemIcon onClick={() => removeFilterFromList(i)}>
                            <Icon name='cross' style={{ color: 'rgb(14, 63, 102)', width: '24px', height: '24px' }} />
                        </FilterItemIcon>
                        <span>WHERE</span>
                        <div style={{ width: '200px' }}>
                            <Dropdown
                                value={filter.dataIndex}
                                onChange={(value: string) => handleChangeFilterColumn(i, value)}
                                options={columns
                                    .filter(column => !exeptColumns.includes(column.dataIndex) && column?.type !== 'actions')
                                    .map(c => ({ label: getTitle(c), value: c.dataIndex }))}
                            />
                        </div>
                        <span>IS</span>
                        <Search
                            value={filter.value}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleChangeFilterValue(i, e.target.value)
                            }}
                            onClear={() => {
                                handleChangeFilterValue(i, '')
                            }}
                        />
                    </FilterItem>
                ))}
            </Filters>
            <Show condition={filterList.length > 0}>
                <FiltersControls>
                    <Button onClick={() => applyFilterList(filterList)}>Apply</Button>
                    <Button onClick={clearAllFilterList}>Clear all</Button>
                </FiltersControls>
            </Show>
        </form>
    )
}

export default TableFilters

const Filters = styled.ul`
    list-style: none;
    color: rgb(14, 63, 102);
`

const FilterItem = styled.li`
    display: flex;
    flex-direction: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    span {
        font-weight: 500;
    }
`

const FilterItemIcon = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 24px;
`

const FiltersControls = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin: 20px 0;
`
