const Day = props => {
    const { day, active, disabled, dayNextMonth, dayPrevMonth, selectDay, dayClassName, dayActiveClassName, dayDisabledClassName, dayFromOtherMonthClassName } = props;

    const handleOnClick = e => {
        if (disabled) return;

        // console.log('e: ', e.target);
        selectDay(e);
    };

    let classes = [dayClassName];

    if (day.isSame(active, 'day')) {
        classes.push(dayActiveClassName);
    }

    if (disabled) {
        classes.push(dayDisabledClassName);
    }

    if (dayPrevMonth || dayNextMonth) {
        classes.push(dayFromOtherMonthClassName);
    }

    return (
        <td className={classes.join(' ')} onClick={handleOnClick}>
            {day.date()}
        </td>
    );
};

Day.defaultProps = {
    dayClassName: 'calendar__day',
    dayActiveClassName: 'calendar__activeDay',
    dayDisabledClassName: 'calendar__disabledDay',
    dayFromOtherMonthClassName: 'calendar__dayFromOtherMonth'
};

export default Day;
